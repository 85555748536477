import React from 'react';
// Libraries
import { useLocation } from 'react-router-dom';
// Pages
import Help from '~/pages/SIGAM/pages/Help';
import { ExistingUser } from '~/utils/UserInformation';
import Auth from './pages/Auth';
import Dashboards from './pages/Dashboards';
import Verification from './pages/Verification';
import PasswordRecovery from './pages/PasswordRecovery';
// Utils

export default function SIGAM() {
  const { pathname } = useLocation();

  if (pathname === '/verificacao') {
    return <Verification />;
  }

  if (pathname === '/ajuda') {
    return <Help />;
  }

  if (pathname.includes('/esqueci-senha')) {
    return <PasswordRecovery />;
  }

  return ExistingUser() ? <Dashboards /> : <Auth />;
}
