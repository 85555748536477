import React from 'react';
// Libraries
import { Switch } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
// Components
import Drawer from '~/components/drawer';
import Button from '~/components/button';
import Text from '~/components/typography/text';
import Space from '~/components/space';
import Select from '~/components/select';
import Input from '~/components/input';

const optionsBy = [
    {
        label: 'Nome do documento',
        value: 'name'
    },
    {
        label: 'Data de criação',
        value: 'created_at'
    },
]

const optionsOrder = [
    {
        label: 'Ordem crescente',
        value: 'ASC'
    },
    {
        label: 'Ordem decrescente',
        value: 'DESC'
    }
]

const optionsGeneric = [
    {
        label: 'Opção 1',
        value: 'Opção 1'
    },
    {
        label: 'Opção 2',
        value: 'Opção 2'
    }
]

export default function FiltersButton({ filters, setFilters }) {

    function ViewFilters() {
        return (
            <Space direction="vertical">
                <Text strong>Visualização</Text>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Switch />
                    <Text type="secondary" style={{ marginLeft: 10 }}>Mostrar documentos onde sou citado</Text>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Switch />
                    <Text type="secondary" style={{ marginLeft: 10 }}>Mostrar documentos da minha unidade ativa</Text>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Switch checked={filters.i_am_signatory} onChange={(checked) => setFilters({ ...filters, i_am_signatory: checked })} />
                    <Text type="secondary" style={{ marginLeft: 10 }}>Mostrar documentos em que sou signatário</Text>
                </div>
                {filters.i_am_signatory &&
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Switch checked={filters.i_signed === false ? true : false} onChange={(checked) => setFilters({ ...filters, i_signed: !checked })} />
                        <Text type="secondary" style={{ marginLeft: 10 }}>Mostrar documentos pendentes da minha assinatura</Text>
                    </div>
                }
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Switch />
                    <Text type="secondary" style={{ marginLeft: 10 }}>Mostrar documentos sigilosos</Text>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Switch />
                    <Text type="secondary" style={{ marginLeft: 10 }}>Mostrar documentos inativos</Text>
                </div>
            </Space>
        )
    }

    function SortFilters() {
        return (
            <Space direction="vertical">
                <Text strong>Ordenação</Text>
                <div>
                    <Text type="secondary" style={{ marginRight: 10 }}>Ordenar por:</Text>
                    <Select value={filters.sort.by} onSelect={(value) => setFilters({ ...filters, sort: { by: value  }})}  options={optionsBy} style={{ width: '200px' }} />
                </div>
                <div>
                    <Text type="secondary" style={{ marginRight: 10 }}>Em ordem</Text>
                    <Select value={filters.sort.order} onSelect={(value) => setFilters({ ...filters, sort: { order: value  }})} options={optionsOrder} style={{ width: '200px' }} />
                </div>
            </Space>
        )
    }

    function SearchFilters() {
        return (
            <Space direction="vertical">
                <Text strong>Busca</Text>
                <div>
                    <Text type="secondary" style={{ marginRight: 10 }}>Número: </Text>
                    <Input style={{ width: '200px' }} />
                </div>
                <div>
                    <Text type="secondary" style={{ marginRight: 10 }}>Assunto:</Text>
                    <Select options={optionsGeneric} style={{ width: '150px' }} />
                </div>
                <div>
                    <Text type="secondary" style={{ marginRight: 10 }}>CPF/ CNPJ dos interessados:</Text>
                    <Input style={{ width: '200px' }} />
                </div>
                <div>
                    <Text type="secondary" style={{ marginRight: 10 }}>Formato:</Text>
                    <Select options={optionsGeneric} style={{ width: '150px' }} />
                </div>
                <div>
                    <Text type="secondary" style={{ marginRight: 10 }}>Ano:</Text>
                    <Select options={optionsGeneric} style={{ width: '150px' }} />
                </div>
            </Space>
        )
    }

    function Filters({ CloseDrawer }) {
        return (
            <Space direction="vertical">
                <ViewFilters />
                <SortFilters />
                <SearchFilters />
            </Space>
        )
    }

    const MainButton = () => {
        return <Button type="primary">Aplicar Filtros</Button>
    }

    return (
        <Drawer
            Trigger={
                <Button icon={<FilterOutlined />} type="dashed">
                    Filtros
                </Button>
            }
            title="Filtros"
            width="50%"
            DrawerContentComponent={Filters}
            footer
            footerCancelButton
            FooterMainButton={MainButton}
        />
    );
}
