import React, { useContext, useState, useEffect } from 'react';
// Libraries
import Animate, { FadeIn } from 'animate-css-styled-components';
//Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
import { ThemeContext } from '~/contexts/ThemeContext';
// Standalone Components
import { Container, Content, BrandContainer, Brand, Card } from './style';
// Utils
// Steps
import Verification from './steps/Verification';
import RegisterPhysicalPerson from './steps/RegisterPhysicalPerson';
import RegisterLegalPerson from './steps/RegisterLegalPerson';
import Login from './steps/Login';
import RecoverPassword from './steps/RecoverPassword';

export default function GeneralLogin() {
  const { translate } = useContext(LanguageContext);
  const { theme } = useContext(ThemeContext);

  const [login, setLogin] = useState('');
  const [person, setPerson] = useState('');
  const [step, setStep] = useState('Verification');
  const [renderStep, setRenderStep] = useState();

  useEffect(() => {
    async function PrepareRender() {
      setRenderStep(step);
    }
    PrepareRender();
  }, [step]);

  const RenderVerification = () => {
    if (renderStep === 'Verification') {
      return (
        <Animate Animation={[FadeIn]} duration={['0.8s']} delay={['0.2s']}>
          <Verification
            setPerson={setPerson}
            login={login}
            setStep={setStep}
            setLogin={setLogin}
          />
        </Animate>
      );
    }
  };
  const RenderLogin = () => {
    if (renderStep === 'Login') {
      return (
        <Animate Animation={[FadeIn]} duration={['0.8s']} delay={['0.2s']}>
          <Login person={person} login={login} setStep={setStep} />
        </Animate>
      );
    }
  };
  const RenderRegisterPhysicalPerson = () => {
    if (renderStep === 'Register Physical Person') {
      return (
        <Animate Animation={[FadeIn]} duration={['0.8s']} delay={['0.2s']}>
          <RegisterPhysicalPerson
            login={login}
            setPerson={setPerson}
            setStep={setStep}
          />
        </Animate>
      );
    }
  };
  const RenderRegisterLegalPerson = () => {
    if (renderStep === 'Register Legal Person') {
      return (
        <Animate Animation={[FadeIn]} duration={['0.8s']} delay={['0.2s']}>
          <RegisterLegalPerson
            login={login}
            setPerson={setPerson}
            setStep={setStep}
          />
        </Animate>
      );
    }
  };
  const RenderRecoverPassword = () => {
    if (renderStep === 'Recover Password') {
      return (
        <Animate Animation={[FadeIn]} duration={['0.8s']} delay={['0.2s']}>
          <RecoverPassword
            login={login}
            setLogin={setLogin}
            person={person}
            setPerson={setPerson}
            setStep={setStep}
          />
        </Animate>
      );
    }
  };

  return (
    <Container style={{ backgroundColor: theme.color.backgroundSecondary }}>
      <Content style={{ backgroundColor: theme.color.backgroundSecondary }}>
        <Card
          style={{
            borderColor: theme.color.borderColor,
            backgroundColor: theme.color.backgroundSecondary,
          }}
        >
          <Animate Animation={[FadeIn]} duration={['0.8s']} delay={['0.2s']}>
            <BrandContainer>
              <Brand
                src={
                  'https://media.milanote.com/p/images/1L5DiY1bzJOVfs/WzD/SIGAM-02.png'
                }
                alt={translate.altOfImages.socialMeLogo.text}
              />
            </BrandContainer>
          </Animate>

          {RenderVerification()}
          {RenderLogin()}
          {RenderRegisterPhysicalPerson()}
          {RenderRegisterLegalPerson()}
          {RenderRecoverPassword()}
        </Card>
      </Content>
    </Container>
  );
}
