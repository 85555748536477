import React, { useState, useEffect } from 'react';
// Libraries
import { Timeline } from 'antd';
import { useParams } from 'react-router-dom';
// Components
import Loading from '~/components/loading';
import Text from '~/components/typography/text';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { CapitalizeString } from '~/utils/Transformations';
import { DateInBrazilianFormat, HourInBrazilianFormat } from '~/utils/DateAndTime';

export default function ProceedDetails({ propertys }) {
    const { institutionId, organizationUnitId } = useParams();
    const [history, setHistory] = useState([]);
    const [loadingContent, setLoadingContent] = useState(true);

    useEffect(() => {
        async function GetHistoric() {
            const params = {
                operation: ['SIGAM', 'Processes', 'GetHistory'],
                urlParams: {
                    institutionId: institutionId,
                    organizationUnitId: organizationUnitId,
                    processId: propertys.process.id
                },
                data: {
                    filter: {
                        action: ['SEND']
                    },
                    pagination: {
                        sort: {
                            by: 'created_at',
                            order: 'DESC'
                        },
                        limit: 0,
                    }
                }
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                setHistory(response.body.timeline.elements);
            }
            setLoadingContent(false);
        }
        GetHistoric();
    }, [institutionId, organizationUnitId, propertys])

    function GetReceiver(item){
        if(item.receiver_person_social_name || item.receiver_person_name){
            return (`Para a pessoa ${CapitalizeString(item.receiver_person_social_name || item.receiver_person_name)}`)
        }
        if(item.receiver_group_name){
            return (`Para o grupo ${CapitalizeString(item.receiver_group_name)}`)
        }
        if(item.receiver_organizational_unity_name){
            return (`Para a unidade ${CapitalizeString(item.receiver_organizational_unity_name)}`)
        }
    }

    if (loadingContent) {
        return <Loading text="Carregando histórico" />
    }

    return (
        <div>
            <Timeline>
                {history.map(item => (
                    <Timeline.Item key={item.id}>
                        <Text>Tramitado em {DateInBrazilianFormat(item.created_at)} às {HourInBrazilianFormat(item.created_at)}</Text>
                        <Text>Por: {CapitalizeString(item.created_by_social_name || item.created_by_name || 'Desconhecido')}</Text>
                        <Text>{GetReceiver(item)}</Text>
                        <Text>Mensagem: {item.receiver_specifications}</Text>
                    </Timeline.Item>
                ))}
            </Timeline>
        </div>
    )
}