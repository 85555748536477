import System from '~/environments/System';

export function GetModuleNameByHostname() {
  const { hostname } = window.location;
  for (const key in System.hostname) {
    if (System.hostname[key] === hostname) return key;
  }
  return hostname;
}

export function getDomain() {
  const hostname = window.location.hostname;
  if (hostname === 'localhost') {
    return hostname;
  }
  return '.sigam.net.br';
}
