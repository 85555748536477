import { useRef, useState, useEffect } from 'react';

export default function useGetHeightInPixelsToApplyScroll() {
  const [height, setHeight] = useState('100%');

  const ref = useRef(null);

  useEffect(() => {
    const idealHeight = window.innerHeight - ref.current?.clientHeight;

    setHeight(`${idealHeight}px`);
  }, [window]);

  return { ref, height };
}
