import React from 'react';
// Libraries
import { Form } from 'antd';
import Animate, { FadeIn } from 'animate-css-styled-components';
// Own Components
import AddForm from './components/AddForm';

export default function Creation({ form }) {
  return (
    <div>
      <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
        <Form
          labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
          wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
          form={form}
          size="small"
          layout="vertical"
          scrollToFirstError
        >
          <AddForm />
        </Form>
      </Animate>
    </div>
  );
}
