import React from 'react';
//Libraries
import { Route, Switch, useRouteMatch } from 'react-router-dom';
// Pages
import ChooseUnit from './pages/ChooseUnit';
import Dashboard from './pages/Dashboard';

export default function PublicAgentDashboard() {
  let { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={`${path}`} component={ChooseUnit} />
        <Route path={`${path}/:organizationUnitId`} component={Dashboard} />
      </Switch>
    </div>
  );
}
