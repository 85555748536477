import React from 'react';
// Libraries
import { Switch, Input, Form } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
// Components
import Drawer from '~/components/drawer';
import Button from '~/components/button';
import Text from '~/components/typography/text';
import Space from '~/components/space';
import Select from '~/components/select';

const { TextArea } = Input;

const optionsBy = [
  {
    label: 'Nome do documento',
    value: 'name',
  },
  {
    label: 'Data de criação',
    value: 'created_at',
  },
];

const optionsOrder = [
  {
    label: 'Ordem crescente',
    value: 'ASC',
  },
  {
    label: 'Ordem decrescente',
    value: 'DESC',
  },
];

export default function FiltersButton({ filters, setFilters, activeTab }) {
  function ViewFilters() {
    return (
      <Space direction="vertical">
        <Text strong>Visualização</Text>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Switch
            checked={filters.i_am_interested}
            onChange={(checked) =>
              setFilters({ ...filters, i_am_interested: checked })
            }
            disabled={activeTab === 'ofMyInterest'}
          />
          <Text type="secondary" style={{ marginLeft: 10 }}>
            Mostrar documentos onde sou interessado
          </Text>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Switch
            checked={filters.i_am_signatory}
            onChange={(checked) =>
              setFilters({ ...filters, i_am_signatory: checked })
            }
            disabled={activeTab === 'pendingSignature'}
          />
          <Text type="secondary" style={{ marginLeft: 10 }}>
            Mostrar documentos em que sou assinante
          </Text>
        </div>
        {filters.i_am_signatory && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Switch
              checked={filters.i_signed === false}
              onChange={(checked) =>
                setFilters({ ...filters, i_signed: !checked })
              }
              disabled={activeTab === 'pendingSignature'}
            />
            <Text type="secondary" style={{ marginLeft: 10 }}>
              Mostrar documentos pendentes da minha assinatura
            </Text>
          </div>
        )}
        {/* 
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Switch
                        checked={filters.privacy}
                        onChange={(checked) => setFilters({ ...filters, privacy: checked })}
                    />
                    <Text type="secondary" style={{ marginLeft: 10 }}>Mostrar somente documentos sigilosos</Text>
                </div>
                */}
        {/*
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Switch />
                    <Text type="secondary" style={{ marginLeft: 10 }}>Mostrar documentos arquivados</Text>
                </div>
                */}
      </Space>
    );
  }

  function SortFilters() {
    return (
      <Space direction="vertical">
        <Text strong>Ordenação</Text>
        <div>
          <Text type="secondary" style={{ marginRight: 10 }}>
            Ordenar por:
          </Text>
          <Select
            value={filters.sort.by}
            onSelect={(value) =>
              setFilters({
                ...filters,
                sort: { by: value, order: filters.sort.order },
              })
            }
            options={optionsBy}
            style={{ width: '200px' }}
          />
        </div>
        <div>
          <Text type="secondary" style={{ marginRight: 10 }}>
            Em ordem
          </Text>
          <Select
            value={filters.sort.order}
            onSelect={(value) =>
              setFilters({
                ...filters,
                sort: { by: filters.sort.by, order: value },
              })
            }
            options={optionsOrder}
            style={{ width: '200px' }}
          />
        </div>
      </Space>
    );
  }

  function SearchFilters() {
    const [form] = Form.useForm();
    async function onFinish(values) {
      setFilters({ ...filters, ...values });
    }
    return (
      <Space direction="vertical">
        <Text strong>Busca</Text>
        <Form
          labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
          wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
          form={form}
          layout="vertical"
          scrollToFirstError
          onFinish={onFinish}
          initialValues={filters}
        >
          <Form.Item label="Nome" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Assunto" name="subject">
            <Input />
          </Form.Item>
          <Form.Item label="Ano" name="year">
            <Input />
          </Form.Item>
          <Form.Item label="Corpo do documento" name="body">
            <TextArea />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: '100%',
              }}
            >
              Filtrar
            </Button>
          </Form.Item>
        </Form>
      </Space>
    );
  }

  function Filters({ CloseDrawer }) {
    return (
      <Space direction="vertical">
        <ViewFilters />
        <SortFilters />
        <SearchFilters />
      </Space>
    );
  }

  const MainButton = () => {
    return <Button type="primary">Aplicar Filtros</Button>;
  };

  return (
    <Drawer
      Trigger={
        <Button icon={<FilterOutlined />} type="dashed">
          Filtros
        </Button>
      }
      title="Filtros"
      width="50%"
      DrawerContentComponent={Filters}
      // footer
      // footerCancelButton
      // FooterMainButton={MainButton}
    />
  );
}
