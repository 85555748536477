import React from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Avatar, Button, Typography } from 'antd';

import { useAuth } from '~/contexts/AuthContext';
import DropdownIcon from '../../components/DropdownIcon';
import useGetHeightInPixelsToApplyScroll from '../../hooks/useGetHeightInPixelsToApplyScroll';
import { useMessagerManager } from '../../../../contexts/MessagerManager/Provider';
import Chats from './components/Chats';
import { CapitalizeString } from '~/utils/Transformations';

import * as S from './style';

const { Search } = Input;
const { Text } = Typography;

export default function MyChats() {
  const { chats, setSearch, loadingChats } = useMessagerManager();
  const { ref, height } = useGetHeightInPixelsToApplyScroll();

  const { user } = useAuth();
  const history = useHistory();

  return (
    <S.Container>
      <div ref={ref}>
        <S.Navbar>
          <Button
            onClick={() => history.push('/')}
            size="default"
            type="default"
          >
            Voltar para o sistema
          </Button>

          <DropdownIcon />
        </S.Navbar>

        <S.HStack>
          <Avatar size={40} src={user.avatar} />

          <Text>{CapitalizeString(user.name)}</Text>
        </S.HStack>

        <S.SearchBox>
          <Search
            size="default"
            placeholder="Pesquisar contatos"
            allowClear
            onSearch={(value) => {
              console.log('value', value);
              setSearch(value);
            }}
          />
        </S.SearchBox>
      </div>

      <S.ChatsScrollView height={height}>
        <Chats chats={chats} loadingChats={loadingChats} />
      </S.ChatsScrollView>
    </S.Container>
  );
}
