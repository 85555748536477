import React from 'react';
import { Avatar, Typography } from 'antd';

import { CapitalizeString } from '~/utils/Transformations';
import * as S from './style';

const { Text } = Typography;

export default function ContactContent({ contact }) {
  return (
    <S.HStack>
      <Avatar size={30} src={contact.avatar || contact.person_avatar} />

      <Text style={{ lineHeight: '100%' }}>
        {CapitalizeString(contact.name || contact.person_name)}
      </Text>
    </S.HStack>
  );
}
