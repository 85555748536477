import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, Typography } from 'antd';
import UploadButton from './components/UploadButton';
import ScannerButton from './components/ScannerButton';

const { Title } = Typography;

export default function Header() {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={showDrawer}>
        Novo arquivo
      </Button>

      <Drawer
        title="Novo arquivo"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <Title level={5}>Selecione a forma de upload</Title>
          <UploadButton />
          <ScannerButton />
        </div>
      </Drawer>
    </>
  );
}
