import React, { useCallback, useState} from 'react';

import { message } from 'antd'

import Text from '~/components/typography/text';
import Button from '~/components/button';
import Avatar from '~/components/avatar';

import { cnpjMask, cpfMask } from '~/utils/Masks';
import { CapitalizeString ,DecodeURI} from '~/utils/Transformations';


import Api from '~/utils/SocialMeAPI';

import Flex from './style'

export default function PersonHeader({ institutionId, person, setPerson, onClose }) {
  const [ loading, setLoading ] = useState(false)

  const { id, avatar, name, social_name, cnpj_number, cpf_number } = person

  const LinkInstitution = useCallback(async () => {
    setLoading(true)

    const params = {
      operation: ["SIGAM","Admin", "Admin", "Link"],
      urlParams: {
        institutionId
      },
      data: {
        person: id
      }
    }

    const response = await Api(params)

    if (response.success) {
      message.success(`Administrador ${CapitalizeString(name)} foi vinculado`)
      onClose()
      setPerson()
    } else {
      message.error("Pessoa já vinculada na instituição")
    }
    setLoading(false)
  }, [id, name, institutionId])

  return (
    <Flex>
      <Avatar  style={{ width: 150, height: 150 }} src={DecodeURI(avatar)} />

      <Text style={{ marginTop: 5 }}>{CapitalizeString(social_name || name)}</Text>

      {cnpj_number ? (
        <Text style={{ opacity: 0.4, margin: "5px 0" }}>CNPJ {cnpjMask(cnpj_number)}</Text>
      ) : (
        <Text style={{ opacity: 0.4, margin: "5px 0" }}>CPF {cpfMask(cpf_number)}</Text>
      )}
     

      <Button loading={loading} onClick={LinkInstitution} type="primary">Vincular</Button>
    </Flex>
  );
}
