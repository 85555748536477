import React, { useContext, memo } from 'react';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Standalone Components
import { MainText } from './style';

function AntText({
  children,
  type,
  kind,
  color,
  style,
  strong,
}) {
  const { theme } = useContext(ThemeContext);

  function SetColor() {
    if (color) return color;
    switch (kind) {
      case 'primary':
        return theme.color.primary;
      case 'secondary':
        return 'rgba(0, 0, 0, .45)';
      default:
        break;
    }
  }

  return (
    <MainText
      type={type}
      strong={strong}
      color={SetColor()}
      style={style}
    >
      {children}
    </MainText>
  );
}

export default memo(AntText);
