import React, { useContext } from 'react';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
//Libraries
import { Form, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
//Components
import Text from '~/components/typography/text';

export default function FormItem({
  style,
  name,
  label,
  item,
  rules,
  valuePropName,
  hasFeedback,
  helpText,
  initialValue,
}) {
  const { theme } = useContext(ThemeContext);
  if (style) {
    style.color =
      (theme && theme.color && theme.color.textOnSecondaryBackground) ||
      '#303030';
  }
  const HelpMessage = () => {
    if (helpText) {
      return (
        <Tooltip title={helpText}>
          <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
        </Tooltip>
      );
    }
    return null;
  };
  return (
    <Form.Item
      validateFirst
      hasFeedback={hasFeedback}
      initialValue={initialValue}
      name={name}
      label={
        label ? (
          <div>
            <Text strong>
              {label} {HelpMessage()}
            </Text>
          </div>
        ) : null
      }
      style={
        style || {
          color:
            (theme && theme.color && theme.color.textOnSecondaryBackground) ||
            '#303030',
        }
      }
      rules={rules}
      valuePropName={valuePropName}
    >
      {item}
    </Form.Item>
  );
}
