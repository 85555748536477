import React, { useEffect, useState } from 'react';
// Libraries
import { useParams } from 'react-router-dom';
// Own Components
import PDFViewer from './components/PDFViewer'
// Components
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { SetupSIGAMTimeline } from '~/utils/Parsers';

export default function ProcessPreview({ process }) {
    const { institutionId, organizationUnitId } = useParams();
    const [loading, setLoading] = useState(true);
    const [fatherProcess, setFatherProcess] = useState();
    const [attachments, setAttachments] = useState();
    const [timelineItems, setTimelineItems] = useState()

    useEffect(() => {
      async function getHistoric(){
        const params = {
          operation: ['SIGAM', 'Processes', 'GetHistory'],
          urlParams: {
            institutionId,
            organizationUnitId,
            processId: process.id,
          },
          data: {
            filter: {
              not_action: 'ACCESS'
          },
          pagination: {
              sort: {
                  by: 'created_at',
                  order: 'ASC'
              },
              limit: 0
          }
          },
        };
        const response = await SocialMeAPI(params);
        if(response.success){
          // console.log('response -> ', response)
          const timelineArray = await SetupSIGAMTimeline(response.body.timeline.elements);
          // console.log('timelineArray -> ', timelineArray)
          const timeline = []
          // Day
          for (let i = 0; i < timelineArray.length; i++){
            // Hour
            const day = timelineArray[i]
            for (let j = 0; j < day.eventsOfTheDay.length; j++){
              // Events
              const hour = day.eventsOfTheDay[j]
              for (let k = 0; k < hour.eventsOfTheHour.length; k++){
                // Eventss
                const events = hour.eventsOfTheHour[k]
                for (let l = 0; l < events.events.length; l++){
                  // Events
                  const event = events.events[l]
                  timeline.push(event)
                }
              }
            }
          }
          // console.log(timeline)
          setTimelineItems(response.body.timeline.elements)
        }
      }
        async function GetAttachments() {
            const params = {
              operation: ['SIGAM', 'Processes', 'Attachments', 'Get'],
              urlParams: {
                institutionId,
                organizationUnitId,
                processId: process.id,
              },
              data: {
                pagination: {
                  limit: 0,
                },
              },
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
              const newArray = [];
              const father = process;
              father.attachment = false;
              newArray.push(father);
              const array = response.body.documents.elements;
              setAttachments(array);
              for (let i = 0; i < array.length; i++) {
                const newItem = array[i];
                newItem.attachment = true;
                newArray.push(newItem);
              }
            }
          }
        async function GetProcess() {
            const params = {
                operation: ['SIGAM', 'Processes', 'Show'],
                urlParams: {
                    institutionId,
                    organizationUnitId,
                    processId: process.id
                }
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                setFatherProcess(response.body.process);
            }
            // setLoading(false);
        }
        GetProcess();
        GetAttachments();
        getHistoric()
    }, [institutionId, organizationUnitId, process])

    useEffect(() => {
      if(fatherProcess && attachments && timelineItems){
        setLoading(false)
      }
    },[fatherProcess, attachments, timelineItems])

    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <div style={{ marginTop: 10 }}>
            <div>
                {attachments ?
                    <div>
                        <PDFViewer fatherProcess={fatherProcess} attachments={attachments} timelineItems={timelineItems} />
                    </div>
                    :
                    <Loading />
                }
            </div>
        </div>
    )
}