import React, { useEffect, useContext, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PageManagerContext from './Context';
import _readTemplates from '~/services/calls/communication/templates/read';
import _showTemplate from '~/services/calls/communication/templates/show';
import _createTemplate from '~/services/calls/communication/templates/create';
import _updateTemplate from '~/services/calls/communication/templates/update';
import _deleteTemplate from '~/services/calls/communication/templates/delete';

export default function PageManagerProvider({ children }) {
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const [templates, setTemplates] = useState([]);
  const { institutionId } = useParams();
  const [totalItems, setTotalItems] = useState(0);
  const [filter] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    sort: {
      by: 'id',
      order: 'DESC',
    },
  });

  async function showTemplate(templateId) {
    const response = await _showTemplate({ institutionId, templateId });
    return response;
  }

  async function deleteTemplate(templateId) {
    const response = await _deleteTemplate({ institutionId, templateId });
    if (response.success) {
      readTemplates(filter, pagination);
    }
    return response;
  }

  async function createTemplate(data) {
    const response = await _createTemplate({ institutionId, data });
    if (response.success) {
      readTemplates(filter, pagination);
    }
    return response;
  }

  async function updateTemplate(templateId, data) {
    const response = await _updateTemplate({ institutionId, templateId, data });
    return response;
  }

  const readTemplates = useCallback(async (filter, pagination) => {
    setLoadingTemplates(true);
    const response = await _readTemplates({
      institutionId,
      data: {
        filter,
        pagination,
      },
    });
    if (response.success) {
      setTemplates(response.body.communication_models.elements);
      setTotalItems(response.body.communication_models.totalElements);
    }
    setLoadingTemplates(false);
  }, []);

  useEffect(() => {
    async function readAsyncData() {
      readTemplates(filter, pagination);
    }
    if (institutionId) {
      readAsyncData();
    }
  }, [filter, pagination]);

  return (
    <PageManagerContext.Provider
      value={{
        loadingTemplates,
        templates,
        readTemplates,
        createTemplate,
        showTemplate,
        updateTemplate,
        deleteTemplate,
        filter,
        totalItems,
        pagination,
        setPagination,
      }}
    >
      {children}
    </PageManagerContext.Provider>
  );
}

export const usePageManager = () => useContext(PageManagerContext);
