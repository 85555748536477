import React from 'react';
// Components
import PersonDrawerAdmin from '~/pages/SIGAM/components/PersonDrawer/Admin';
import Button from '~/components/button';
import Drawer from '~/components/drawer';
// Utils
import { CapitalizeString } from '~/utils/Transformations';

export default function UserButton({ record, setUpdatePending, setActiveId, setRenderOrganizationalUnits, setRenderGroups }) {
    return (
        <div>
            <Drawer
                Trigger={
                    <Button onClick={() => setActiveId(record.id)}>Detalhes</Button>
                }
                title={`${CapitalizeString(record.social_name || record.name)}`}
                DrawerContentComponent={PersonDrawerAdmin}
                DrawerContentComponentParams={{ record, setRenderOrganizationalUnits, setRenderGroups, setUpdatePending }}
            />
        </div>
    )
}