import React, { useContext } from 'react';
// Libraries
import { EditOutlined } from '@ant-design/icons';
import { Form, message } from 'antd';
// Contexts
import { SIGAMContext } from '~/contexts/SIGAMContext';
// Own Components
import EditForm from './components/EditForm';
// Components
import Drawer from '~/components/drawer';
import Button from '~/components/button';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { CapitalizeString } from '~/utils/Transformations';

export default function Edit({ setUpdatePending, record, GetDataAgain }) {
  const { activeInstitution } = useContext(SIGAMContext);
  const [form] = Form.useForm();

  const DrawerBody = () => {
    return <EditForm form={form} record={record} />;
  };

  const EditFooterButton = ({ CloseDrawer }) => {
    async function onFinish(data) {
      if (data.status) {
        data.status = 'ACTIVE';
      } else {
        data.status = 'INACTIVE';
      }
      const params = {
        operation: ['SIGAM', 'TypesOfProcesses', 'Edit'],
        urlParams: {
          institutionId: activeInstitution.id,
          typeId: record.id
        },
        data,
      };
      console.log(params)
      const response = await SocialMeAPI(params);
      if (response.success) {
        message.success('Tipo de processo atualizado com sucesso!');
        CloseDrawer();
        return GetDataAgain();
      }
      return message.error('Alguma coisa aconteceu...');
    }
    return (
      <Button
        type="primary"
        onClick={() => {
          form
            .validateFields()
            .then((values) => {
              //form.resetFields();
              onFinish(values);
            })
            .catch((info) => {
              // console.log('Validate Failed:', info);
            });
        }}
      >
        Atualizar
      </Button>
    );
  };

  return (
    <Drawer
      Trigger={
        <Button icon={<EditOutlined />} type="dashed">
          Editar
        </Button>
      }
      title={`Editando ${CapitalizeString(record.name)}`}
      DrawerContentComponent={DrawerBody}
      footer
      footerCancelButton
      FooterMainButton={EditFooterButton}
    />
  );
}
