import styled from 'styled-components';

export const ContainerFirstTime = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 200;
    padding: 20px;
`

export const ContainerAvatars = styled.div`
    padding: 20px;
`

export const ContainerUploadButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
`

export const ContainerTakePhoto = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    width: 100%;
    padding: 10px;
    cursor: pointer;
`