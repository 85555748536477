import React, { useRef } from 'react';
import { Typography } from 'antd';

const { Text, Title } = Typography;

export default function Dropable({ onChange, onDrop }) {
  const inputRef = useRef();

  const dropableStyle = {
    cursor: 'pointer',
    transition: 'all 250ms',
    borderRadius: '10px',
    border: '1px dashed',
    borderColor: '#C9C9C9',
    height: '150px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  function HandleOnDrop(event) {
    event.preventDefault();

    if (event.dataTransfer && event.dataTransfer.files) {
      onDrop(event.dataTransfer.files);
    }
  }

  function HandleOnClick() {
    inputRef.current.value = null;

    inputRef.current.click();
  }

  function HandleOnChange(event) {
    event.preventDefault();

    const files = event.target.files;

    if (!files) return;

    onChange(files);
  }

  return (
    <div
      onDragEnter={(event) => event.preventDefault()}
      onDragLeave={(event) => event.preventDefault()}
      onDragOver={(event) => {
        event.preventDefault();
      }}
      onDrop={HandleOnDrop}
      style={dropableStyle}
      onClick={HandleOnClick}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Title level={4}>Clique ou arraste um arquivo</Title>
        <Text>Apenas arquivos PDF permitidos</Text>
      </div>
      <input
        onChange={HandleOnChange}
        ref={inputRef}
        type="file"
        hidden
        multiple={true}
      />
    </div>
  );
}
