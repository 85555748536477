import React, { useState, useEffect } from 'react';
// Libraries
import { Row, Col, message, Alert } from 'antd';
import { MdPublic } from 'react-icons/md';
import { GrSecure } from 'react-icons/gr';
import { FaUserSecret } from 'react-icons/fa';
import Animate, { FadeIn } from 'animate-css-styled-components';
import { useParams } from 'react-router-dom';
// Own Components
import SelectionItem from './components/SelectionItem';
import PeopleWithPermission from './components/PeopleWithPermission';
// Components
import Loading from '~/components/loading';
import Title from '~/components/typography/title';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function AccessLevel({ document, propertys }) {
  const { institutionId, organizationUnitId } = useParams();
  const [loadingContent, setLoadingContent] = useState(true);
  const [activePrivacy, setActivePrivacy] = useState();
  const [accessLevel, setAccessLevel] = useState();

  const documentInformation = document || propertys.document[0];

  const options = [
    {
      icon: MdPublic,
      title: 'Público',
      description: 'Todos podem visualizar',
      value: 'PUBLIC',
    },
    {
      icon: GrSecure,
      title: 'Restrito',
      description: 'Descrição do Restrito',
      value: 'RESTRICTED',
    },
    {
      icon: FaUserSecret,
      title: 'Sigiloso',
      description: 'Descrição do Restrito',
      value: 'CONFIDENTIAL',
    },
  ];

  useEffect(() => {
    async function Edit() {
      const params = {
        operation: ['SIGAM', 'Documents', 'Edit'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: documentInformation.id,
        },
        data: {
          privacy: activePrivacy,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        //return message.success('Documento atualizado com sucesso!')
      }
      //return message.error('Alguma coisa aconteceu...')
    }
    if (activePrivacy) {
      if (documentInformation.privacy !== activePrivacy) {
        Edit();
      }
    }
  }, [
    activePrivacy,
    documentInformation,
    institutionId,
    organizationUnitId,
    propertys,
  ]);

  useEffect(() => {
    async function PrepareAccess() {
      if (documentInformation) {
        if (documentInformation.privacy) {
          if (
            documentInformation.privacy === 'PUBLIC' ||
            documentInformation.privacy === 'RESTRICTED' ||
            documentInformation.privacy === 'CONFIDENTIAL'
          ) {
            setActivePrivacy(documentInformation.privacy);
          } else {
            setActivePrivacy('PUBLIC');
          }
        } else {
          setActivePrivacy('PUBLIC');
        }
        return setLoadingContent(false);
      }
    }
    async function verifyPermission() {
      const params = {
        operation: ['SIGAM', 'Documents', 'HavePermission'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: documentInformation.id,
        },
      };
      const response = await SocialMeAPI(params);
      setAccessLevel(response.body.access_level);
      PrepareAccess();
    }
    verifyPermission();
  }, [documentInformation]);

  if (loadingContent) {
    return <Loading />;
  }

  function RenderPeopleWithPermission() {
    switch (activePrivacy) {
      case 'RESTRICTED':
      case 'CONFIDENTIAL':
        return (
          <PeopleWithPermission
            accessLevel={accessLevel}
            document={documentInformation}
          />
        );
      default:
        return <></>;
    }
  }

  return (
    <div>
      {accessLevel && accessLevel.is_in_charge === false && (
        <Alert
          style={{ marginBottom: '10px' }}
          message="Você não tem permissão para alterar o documento"
          type="warning"
          showIcon
        />
      )}
      <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
        <Row>
          <Title style={{ textAlign: 'center', width: '100%' }} level={4}>
            Nível de Acesso
          </Title>
        </Row>
        <Row>
          {options.map((item) => (
            <Col
              key={item.value}
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
              style={{ padding: 5 }}
            >
              <SelectionItem
                item={item}
                activePrivacy={activePrivacy}
                setActivePrivacy={setActivePrivacy}
                accessLevel={accessLevel}
              />
            </Col>
          ))}
        </Row>
        {RenderPeopleWithPermission()}
      </Animate>
    </div>
  );
}
