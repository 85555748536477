import { useContext } from 'react';
// Contexts
import { AuthContext } from '~/contexts/AuthContext';
// Utils
import { CapitalizeString, DecodeURI } from '~/utils/Transformations';
import { GetDefaultAvatar } from '~/utils/SocialMeAssets';
import { cpfMask, cnpjMask } from '~/utils/Masks';

export function ExistingUser() {
  const { user } = useContext(AuthContext);

  if (user) {
    return true;
  }
  return false;
}

export function UserId() {
  const { user } = useContext(AuthContext);

  if (user) {
    return user.id;
  }
  return undefined;
}

export function UserShortName() {
  const { user } = useContext(AuthContext);

  if (user) {
    if (user.social_name) {
      const splits = user.social_name.split(' ');
      if (splits.length >= 3) {
        const firstName = splits[0];
        const lastName = splits[splits.length - 1];
        return CapitalizeString(`${firstName} ${lastName}`);
      }

      return CapitalizeString(user.social_name);
    }

    const splits = user.name.split(' ');
    if (splits.length >= 3) {
      const firstName = splits[0];
      const lastName = splits[splits.length - 1];
      return CapitalizeString(`${firstName} ${lastName}`);
    }
    return CapitalizeString(user.name);
  }
}

export function UserFirstName() {
  const { user } = useContext(AuthContext);

  if (user) {
    if (user.social_name) {
      const splits = user.social_name.split(' ');
      const firstName = splits[0];
      return CapitalizeString(firstName);
    }

    const splits = user.name.split(' ');
    const firstName = splits[0];
    return CapitalizeString(firstName);
  }
}

export function UserName() {
  const { user } = useContext(AuthContext);
  if (user) {
    /*
    if (user.social_name) {
      return CapitalizeString(user.social_name);
    }
    */
    return CapitalizeString(user.name);
  }
}

export function UserSex() {
  const { user } = useContext(AuthContext);

  if (user) {
    if (user.social_sex) {
      return user.social_sex;
    }
    return user.sex;
  }
}

export function UserKind() {
  const { user } = useContext(AuthContext);

  if (user) {
    return user.kind;
  }
}

export function UserMainDocument() {
  const { user } = useContext(AuthContext);

  if (user) {
    if (user.cpf_number) {
      return cpfMask(user.cpf_number);
    }
    if (user.cnpj_number) {
      return cnpjMask(user.cnpj_number);
    }
  }
}

export function UserAvatar() {
  const { user } = useContext(AuthContext);

  if (user) {
    if (user.avatar) {
      return DecodeURI(user.avatar);
    }
    if (user.kind === 'PF') {
      if (user.social_sex) {
        if (user.social_sex === 'M') {
          return GetDefaultAvatar('male');
        }
        return GetDefaultAvatar('feminine');
      }
      if (user.sex === 'M') {
        return GetDefaultAvatar('male');
      }
      return GetDefaultAvatar('feminine');
    }
    if (user.kind === 'PJ') {
      return GetDefaultAvatar('partner');
    }
  }
}

export function UserQRCode() {
  const { user } = useContext(AuthContext);

  if (user) {
    console.log(user);
  }
}
