import React, { useContext } from 'react';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { PermissionContext } from '~/contexts/PermissionContext';
// Components
import Space from '~/components/space';
import Card from '~/components/card';
import Title from '~/components/typography/title';
// Own Components
import FiltersButton from './components/FiltersButton';
import NewProcess from './components/NewProcess';

export default function Header({
  filters,
  setFilters,
  GetDataAgain,
  activeTab,
}) {
  const { isMobile } = useContext(ResponsiveContext);
  const { CheckPermission } = useContext(PermissionContext);
  return (
    <Card bodyStyle={{ padding: 10 }} style={{ marginBottom: 10 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: isMobile ? 'center' : 'space-between',
          alignItems: isMobile ? 'center' : 'start',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <Title level={4} style={{ margin: isMobile ? '0px 0px 10px' : 0 }}>
          Processos
        </Title>
        <Space>
          <FiltersButton
            filters={filters}
            setFilters={setFilters}
            activeTab={activeTab}
          />
          {CheckPermission('CREATE-PROCESS') && (
            <NewProcess GetDataAgain={GetDataAgain} />
          )}
        </Space>
      </div>
    </Card>
  );
}
