import React, { useState } from 'react';
import { Card, Form } from 'antd';

import { useArchiveFilesPageManager } from '../../../../../../../../../../contexts/ArchiveFilesPageManager/Provider';
import FileForm from '../../../../../../../../../Form';

export default function CreateFileForm({ file, fileUrl, setFileStatus }) {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const { CreateFile } = useArchiveFilesPageManager();

  async function onFinish(values) {
    values.url = fileUrl;

    setLoading(true);

    const response = await CreateFile(values);

    if (response.success) {
      setFileStatus('finished');
      form.resetFields();
    }

    setLoading(false);
  }

  return (
    <Card title={`Arquivo: ${file.name}`}>
      <FileForm
        form={form}
        onFinish={onFinish}
        loadingButton={loading}
        submitButtonText="Criar arquivo"
      />
    </Card>
  );
}
