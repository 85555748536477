import React, { useState } from 'react';
import { Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import { useArchiveFilesPageManager } from '../../../../../../contexts/ArchiveFilesPageManager/Provider';
import DetailsContent from './components/Details';

export default function FileDetailsButton({ file }) {
  const [loadingFile, setLoadingFile] = useState(false);
  const [completeFile, setCompleteFile] = useState();
  const [open, setOpen] = useState(false);

  const { ShowFile } = useArchiveFilesPageManager();

  function setMetatagsOnFile(currentFile) {
    let metas = [];
    let metasValues = [];

    for (const key in currentFile) {
      const value = currentFile[key];

      const isMetadataValue = key.includes('archive_meta');
      if (isMetadataValue) metas.push(value);

      const isMetadata = key.includes('meta') && key.length < 6;
      if (isMetadata) metasValues.push(value);
    }

    currentFile.metatags = metas.map((meta, index) => {
      return {
        label: meta,
        value: metasValues[index],
      };
    });

    return currentFile;
  }

  const LoadFileToUpdate = async () => {
    setLoadingFile(true);

    const response = await ShowFile(file.id);

    if (response.success) {
      const completeFile = setMetatagsOnFile(response.body.archive_file);

      setCompleteFile(completeFile);
      setOpen(true);
    }

    setLoadingFile(false);
  };

  return (
    <>
      <Button
        type="primary"
        onClick={LoadFileToUpdate}
        loading={loadingFile}
        icon={<EyeOutlined />}
      >
        Detalhes
      </Button>

      {completeFile && (
        <DetailsContent file={completeFile} open={open} setOpen={setOpen} />
      )}
    </>
  );
}
