import React, { useState, useEffect, useContext } from 'react';
//Libraries
import { Redirect, useRouteMatch } from 'react-router-dom';
import { Row, Col } from 'antd';
// Contexts
import { SIGAMContext } from '~/contexts/SIGAMContext';
// Own Components
import UnitCard from './components/UnitCard';
// Components
import Title from '~/components/typography/title';
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function ChooseUnit() {
    const { setActiveInstitution } = useContext(SIGAMContext);
    const [isPublicAgent, setIsPublicAgent] = useState(false);
    const [loadingContent, setLoadingContent] = useState(true);
    const [organizationalUnits, setOrganizationalUnits] = useState([]);
    let { params } = useRouteMatch();

    useEffect(() => {
        async function ChecksIfYouArePublicAgent(id) {
            const params = {
                operation: ['SIGAM', 'OrganizationalUnits', 'Get'],
                urlParams: {
                    institutionId: id,
                },
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                if (response.body.orgUnities.elements.length > 0) {
                    setOrganizationalUnits(response.body.orgUnities.elements)
                    setIsPublicAgent(true);
                }
                return setLoadingContent(false);
            }
        }
        if (params) {
            let id = params.institutionId;
            ChecksIfYouArePublicAgent(id);
        }
    }, [params]);

    if (loadingContent) {
        return <Loading text="Verificando permissão" height="100vh" />;
    }

    if (!isPublicAgent) {
        setActiveInstitution();
        return <Redirect to="/" />;
    }
    return (
        <div style={{ padding: 10 }}>
            <Row style={{ display: 'flex', justifyContent: 'center' }}>
                <Title level={3} style={{ textAlign: 'center' }}>Selecione a unidade</Title>
            </Row>
            <Row>
                {organizationalUnits.map(item => (
                    <Col key={item.id} xs={24} sm={24} md={12} lg={8} xl={8}>
                        <UnitCard item={item} />
                    </Col>
                ))}
            </Row>
        </div>
    )
}