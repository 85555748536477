import React, { useEffect, useState } from 'react';
// Libraries
import { useParams } from 'react-router-dom';
import Animate, { FadeIn } from 'animate-css-styled-components';
import { LinkOutlined } from '@ant-design/icons';
import { Alert, Result } from 'antd';
// Own Components
import AddDocument from './components/AddDocument';
import AttachmentsList from './components/AttachmentsList';
// Components
import Title from '~/components/typography/title';
import Loading from '~/components/loading';
import Drawer from '~/components/drawer';
import Button from '~/components/button';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Attachments({ process, propertys }) {
  const { institutionId, organizationUnitId } = useParams();
  const [attachments, setAttachments] = useState([]);
  const [loadingContent, setLoadingContent] = useState(true);
  const [accessLevel, setAccessLevel] = useState();

  const processInformation = process || propertys.process[0];

  async function GetAttachmentsAgain() {
    const params = {
      operation: ['SIGAM', 'Processes', 'Attachments', 'Get'],
      urlParams: {
        institutionId,
        organizationUnitId,
        processId: processInformation.id,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      setAttachments(response.body.documents.elements);
    }
  }

  useEffect(() => {
    async function GetAttachments() {
      const params = {
        operation: ['SIGAM', 'Processes', 'Attachments', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
          processId: processInformation.id,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setAttachments(response.body.documents.elements);
      }
      setLoadingContent(false);
    }
    async function verifyPermission() {
      const params = {
        operation: ['SIGAM', 'Processes', 'HavePermission'],
        urlParams: {
          institutionId,
          organizationUnitId,
          processId: processInformation.id,
        },
      };
      const response = await SocialMeAPI(params);
      setAccessLevel(response.body.access_level);
      GetAttachments();
    }
    verifyPermission();
  }, [processInformation, institutionId, organizationUnitId]);

  if (processInformation.status === 'INACTIVE') {
    return (
      <Result
        status="error"
        title="Você não pode atualizar um processo arquivado"
      />
    );
  }

  return (
    <div>
      <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
        {accessLevel && accessLevel.is_in_charge === false && (
          <Alert
            style={{ marginBottom: '10px' }}
            message="Você não tem permissão para alterar o processo"
            type="warning"
            showIcon
          />
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title level={5} style={{ margin: 0 }}>
            Documentos Anexados
          </Title>
          {accessLevel && accessLevel.is_in_charge && (
            <Drawer
              Trigger={
                <Button icon={<LinkOutlined />} type="dashed">
                  Adicionar anexo
                </Button>
              }
              title="Adicionar anexo"
              DrawerContentComponent={AddDocument}
              DrawerContentComponentParams={{
                process: [processInformation],
                GetAttachmentsAgain,
                GetDataAgain: propertys && propertys.GetDataAgain,
              }}
              width="50%"
            />
          )}
        </div>
        {loadingContent ? (
          <Loading />
        ) : (
          <AttachmentsList
            accessLevel={accessLevel}
            attachments={attachments}
            process={processInformation}
            GetAttachmentsAgain={GetAttachmentsAgain}
          />
        )}
      </Animate>
    </div>
  );
}
