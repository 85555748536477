import React, { useState } from 'react';
// Libraries
import { Form, message } from 'antd';
import Animate, { FadeIn } from 'animate-css-styled-components';
import { useParams } from 'react-router-dom';
// Components
import FormItem from '~/components/form/FormItem';
import RadioGroup from '~/components/radioGroup';
import Button from '~/components/button';
// Own Components
import AddForm from './components/AddForm';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { AmericanDateParser } from '~/utils/Parsers';

export default function DocumentCreation({ setSelectedDocument }) {
  const [documentType, setDocumentType] = useState();
  const [form] = Form.useForm();
  const { institutionId, organizationUnitId } = useParams();
  const [loadingCreationButton, setLoadingCreationButton] = useState(false);

  const KindsOptions = [
    {
      value: 'InBlank',
      label: 'Em Branco',
    },
    {
      value: 'External',
      label: 'Documento Externo',
    },
    {
      value: 'Model',
      label: 'Baseado em Modelo',
    },
  ];

  async function onFinishCreation(values) {
    setLoadingCreationButton(true);
    // console.log('values ', values)
    if (values.priority) {
      values.priority = 1;
    } else {
      values.priority = 0;
    }
    if (values.kind === 'InBlank' || values.kind === 'Model') {
      values.kind = 'HTML';
    }
    values.privacy = 'PUBLIC';
    values.status = 'ACTIVE';
    if (values.created_externally_at) {
      values.created_externally_at = AmericanDateParser(
        values.created_externally_at
      );
    }
    const params = {
      operation: ['SIGAM', 'Documents', 'Add'],
      urlParams: {
        institutionId,
        organizationUnitId,
      },
      data: values,
    };
    // return console.log('body enviado -> ', params.data)
    const response = await SocialMeAPI(params);
    // console.log('resposta -> ', response)
    if (response.success) {
      message.success('Documento criado com sucesso!');
      setSelectedDocument(response?.body?.document);
      form.resetFields();
    } else {
      message.error('Alguma coisa aconteceu...');
    }
    setLoadingCreationButton(false);
  }

  return (
    <div>
      <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
        <Form
          labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
          wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
          form={form}
          size="small"
          layout="vertical"
          scrollToFirstError
        >
          <FormItem
            label={'Tipo do Documento'}
            name={['kind']}
            rules={[
              {
                required: true,
                message: 'Insira um tipo para o documento',
              },
            ]}
            item={
              <RadioGroup
                onChange={(event) => setDocumentType(event.target.value)}
                options={KindsOptions}
              />
            }
          />
          <AddForm documentType={documentType} form={form} />
          <div>
            <Button
              type="primary"
              loading={loadingCreationButton}
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    onFinishCreation(values);
                  })
                  .catch((info) => {
                    // console.log('Validate Failed:', info);
                  });
              }}
            >
              Criar Documento
            </Button>
          </div>
        </Form>
      </Animate>
    </div>
  );
}
