import React, { useEffect, useState } from 'react';
// Libraries
import { Row, Col, Descriptions } from 'antd';
import { useParams } from 'react-router-dom';
// Components
import Loading from '~/components/loading';
import Avatar from '~/components/avatar';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { DateInBrazilianFormat, DistanceToNow } from '~/utils/DateAndTime';
import { CapitalizeString, DecodeURI } from '~/utils/Transformations';

export default function DrawerPublic({ propertys }) {
  const { institutionId, organizationUnitId } = useParams();
  const [loadingContent, setLoadingContent] = useState(false);
  const [person, setPerson] = useState();

  console.log(propertys)

  useEffect(() => {
    async function GetPerson() {
      const params = {
        operation: ['SIGAM', 'Person', 'Search'],
        urlParams: {
          institutionId,
          organizationUnitId
          // personId: propertys.person.id || propertys.person.person_id,
        },
        data: {
          filter: {
            id: propertys.person.id || propertys.person.person_id
          },
          pagination: {
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      console.log(response)
      if (response.success) {
        // setPerson(response.body.person);
      }
      // setLoadingContent(false);
    }
    // GetPerson();
  }, [propertys]);

  if (loadingContent) {
    return <Loading text="Carregando informações" />;
  }

  return (
    <div>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={6}
          lg={6}
          xl={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Avatar src={DecodeURI(propertys.person.avatar || propertys.person.person_avatar || '')} size={100} />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={18}
          lg={18}
          xl={18}
          style={{ paddingLeft: 10 }}
        >
          <Descriptions title="Informações" column={1}>
            <Descriptions.Item label="Nome">
              {CapitalizeString(propertys.person.name || propertys.person.person_name || '')}
            </Descriptions.Item>
            {propertys.person.cpf_number &&
              <Descriptions.Item label="CPF">
                {propertys.person.cpf_number}
              </Descriptions.Item>
            }
            {propertys.person.cnpj_number &&
              <Descriptions.Item label="CNPJ">
                {propertys.person.cnpj_number}
              </Descriptions.Item>
            }

            {propertys.person.person_cpf_number &&
              <Descriptions.Item label="CPF">
                {propertys.person.person_cpf_number}
              </Descriptions.Item>
            }
            {propertys.person.person_cnpj_number &&
              <Descriptions.Item label="CNPJ">
                {propertys.person.person_cnpj_number}
              </Descriptions.Item>
            }
            {/*
            <Descriptions.Item label="Email">
              <span style={{ textTransform: 'lowercase' }}>
                {person.main_contact}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Sexo">{person.sex}</Descriptions.Item>
            <Descriptions.Item label="Nascimento">{`${DateInBrazilianFormat(person.birth_certificate_date)} (${DistanceToNow(person.birth_certificate_date, 'year')})`}</Descriptions.Item>
           */}
          </Descriptions>
        </Col>
      </Row>
    </div>
  );
}
