import React from 'react';
import { Table, Card, Space } from 'antd';

import { useArchiveFilesPageManager } from '../../../../contexts/ArchiveFilesPageManager/Provider';

import DeleteFileButton from './components/DeleteButton';
import DetailsFileButton from './components/DetailsButton';
import UpdateFileButton from './components/UpdateButton';
import FileButton from './components/FileButton';

export default function TableContainer() {
  const { files } = useArchiveFilesPageManager();

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
    },

    {
      title: 'Ações',
      dataIndex: 'id',
      align: 'right',
      render: (_, record) => (
        <Space size="middle">
          <FileButton file={record} />
          <DeleteFileButton file={record} />
          <UpdateFileButton file={record} />
          <DetailsFileButton file={record} />
        </Space>
      ),
    },
  ];

  return (
    <Card bordered={false} bodyStyle={{ padding: 0 }}>
      <Table
        rowKey="id"
        dataSource={files}
        columns={columns}
        pagination={false}
      />
    </Card>
  );
}
