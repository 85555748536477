import React, { useState } from 'react';
// Libraries
import { Form } from 'antd';
import Animate, { FadeIn } from 'animate-css-styled-components';
// Components
import FormItem from '~/components/form/FormItem';
import RadioGroup from '~/components/radioGroup';
// Own Components
import AddForm from './components/AddForm';

export default function Creation({ form }) {
  const [documentType, setDocumentType] = useState();

  const KindsOptions = [
    {
      value: 'InBlank',
      label: 'Em Branco',
    },
    {
      value: 'External',
      label: 'Documento Externo',
    },
    {
      value: 'Model',
      label: 'Baseado em Modelo',
    },
  ];

  return (
    <div>
      <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
        <Form
          labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
          wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
          form={form}
          size="small"
          layout="vertical"
          scrollToFirstError
        >
          <FormItem
            label={'Tipo do Documento'}
            name={['kind']}
            rules={[
              {
                required: true,
                message: 'Insira um tipo para o documento',
              },
            ]}
            item={
              <RadioGroup
                onChange={(event) => setDocumentType(event.target.value)}
                options={KindsOptions}
              />
            }
          />
          <AddForm documentType={documentType} form={form} />
        </Form>
      </Animate>
    </div>
  );
}
