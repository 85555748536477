import React from 'react';
// Libraries
import { FaRegEdit, FaRegEye } from 'react-icons/fa';
import { RiFileAddLine, RiUserStarLine, RiMailSendLine } from 'react-icons/ri';
import { BiLockAlt } from 'react-icons/bi';
import { TiCalendarOutline } from 'react-icons/ti';
import { AiOutlineCheck } from 'react-icons/ai';
// Components
import Drawer from '~/components/drawer';
import Button from '~/components/button';
// Actions
import Actions from '../Actions';

export default function Options({ selectedRow, GetDataAgain }) {

  return (
    <div style={{ margin: '0px 0px 10px 0px', display: 'flex', justifyContent: 'flex-start' }}>
      {/* Proceed */}
      <Drawer
        Trigger={
          <div>
            <Button
              icon={<RiMailSendLine />}
              type={selectedRow.length > 0 ? 'default' : 'dashed'}
              style={{ marginRight: 5 }}
              disabled={selectedRow.length > 0 ? false : true}
              anotherLibraryIcon
            >
              {Actions.Proceed.text}
            </Button>
          </div>
        }
        title="Tramitar"
        width="50%"
        DrawerContentComponent={Actions.Proceed.content}
        DrawerContentComponentParams={{ process: selectedRow, GetDataAgain: GetDataAgain }}
      />
      {/* Interested */}
      <Drawer
        Trigger={
          <div>
            <Button
              icon={<RiUserStarLine />}
              type={selectedRow.length > 0 ? 'default' : 'dashed'}
              style={{ marginRight: 5 }}
              disabled={selectedRow.length > 0 ? false : true}
              anotherLibraryIcon
            >
              {Actions.Interested.text}
            </Button>
          </div>
        }
        title="Gerenciar Interessados"
        width="50%"
        DrawerContentComponent={Actions.Interested.content}
        DrawerContentComponentParams={{ process: selectedRow, GetDataAgain: GetDataAgain }}
      />
      {/* Edit */}
      <Drawer
        Trigger={
          <div>
            <Button
              icon={<FaRegEdit />}
              type={selectedRow.length > 0 ? 'default' : 'dashed'}
              style={{ marginRight: 5 }}
              disabled={selectedRow.length > 0 ? false : true}
              anotherLibraryIcon
            >
              {Actions.Edit.text}
            </Button>
          </div>
        }
        title="Editar"
        width="50%"
        DrawerContentComponent={Actions.Edit.content}
        DrawerContentComponentParams={{ process: selectedRow, GetDataAgain: GetDataAgain }}
      />
      {/* Attachments */}
      <Drawer
        Trigger={
          <div>
            <Button
              icon={<RiFileAddLine />}
              type={selectedRow.length > 0 ? 'default' : 'dashed'}
              style={{ marginRight: 5 }}
              disabled={selectedRow.length > 0 ? false : true}
              anotherLibraryIcon
            >
              {Actions.Attachments.text}
            </Button>
          </div>
        }
        title="Anexos"
        DrawerContentComponent={Actions.Attachments.content}
        DrawerContentComponentParams={{ process: selectedRow, GetDataAgain: GetDataAgain }}
      />
      {/* Visualize */}
      <Drawer
        Trigger={
          <div>
            <Button
              icon={<FaRegEye />}
              type={selectedRow.length > 0 ? 'default' : 'dashed'}
              style={{ marginRight: 5 }}
              disabled={selectedRow.length > 0 ? false : true}
              anotherLibraryIcon
            >
              {Actions.Visualize.text}
            </Button>
          </div>
        }
        title="Visualizar"
        width="100%"
        DrawerContentComponent={Actions.Visualize.content}
        DrawerContentComponentParams={{ process: selectedRow }}
      />
      {/* AccessLevel */}
      <Drawer
        Trigger={
          <div>
            <Button
              icon={<BiLockAlt />}
              type={selectedRow.length > 0 ? 'default' : 'dashed'}
              style={{ marginRight: 5 }}
              disabled={selectedRow.length > 0 ? false : true}
              anotherLibraryIcon
            >
              Acesso
            </Button>
          </div>
        }
        title="Nível de acesso"
        DrawerContentComponent={Actions.AccessLevel.content}
        DrawerContentComponentParams={{ process: selectedRow, GetDataAgain: GetDataAgain }}
      />
      <Drawer
        Trigger={
          <div>
            <Button
              icon={<TiCalendarOutline />}
              type={selectedRow.length > 0 ? 'default' : 'dashed'}
              style={{ margin: '0px 5px 10px 0px' }}
              disabled={selectedRow.length > 0 ? false : true}
              anotherLibraryIcon
            >
              {Actions.Historic.text}
            </Button>
          </div>
        }
        title="Histórico"
        width="50%"
        DrawerContentComponent={Actions.Historic.content}
        DrawerContentComponentParams={{ process: selectedRow, GetDataAgain: GetDataAgain }}
      />
      <Drawer
        Trigger={
          <div>
            <Button
              icon={<AiOutlineCheck />}
              type={selectedRow.length > 0 ? 'default' : 'dashed'}
              style={{ margin: '0px 5px 10px 0px' }}
              disabled={selectedRow.length > 0 ? false : true}
              anotherLibraryIcon
            >
              {Actions.Close.text}
            </Button>
          </div>
        }
        title="Encerrar"
        width="50%"
        DrawerContentComponent={Actions.Close.content}
        DrawerContentComponentParams={{ process: selectedRow, GetDataAgain: GetDataAgain }}
      />
    </div>
  );
}
