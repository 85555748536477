import React, { useState, useContext } from 'react';
import { EyeOutlined, ToolOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { SIGAMContext } from '~/contexts/SIGAMContext';
import SocialMeAPI from '~/utils/SocialMeAPI';
import returnUrl from '~/services/calls/pdf/returnUrl';
import getHtml from './utils/getHTML';

export default function DocumentView({ model }) {
  const { activeInstitution } = useContext(SIGAMContext);
  const [loadingButton, setLoadingButton] = useState(false);
  const [pdf, setPDF] = useState();

  async function getPDF(HTML) {
    const response = await returnUrl({ html: HTML });
    if (response.success) {
      setPDF(response?.body?.file);
      window.open(response?.body?.file, '_blank');
    }
    setLoadingButton(false);
  }

  async function getModelInformation() {
    const params = {
      operation: ['SIGAM', 'DocumentTemplates', 'Show'],
      urlParams: {
        institutionId: activeInstitution.id,
        modelId: model.id,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      const HTML = await getHtml({
        content: response?.body?.documentModel?.body,
      });
      getPDF(HTML);
    }
  }

  async function prepareView() {
    setLoadingButton(true);
    await getModelInformation();
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Button
        loading={loadingButton}
        type="outline"
        icon={<ToolOutlined />}
        onClick={() => prepareView()}
      >
        Preparar
      </Button>
      {pdf && (
        <a href={pdf} target="_blank" rel="noopener noreferrer">
          <Button icon={<EyeOutlined />} type="primary">
            Visualizar
          </Button>
        </a>
      )}
    </div>
  );
}
