import React from 'react';
// Libraries
import { VscFileSubmodule } from 'react-icons/vsc';
// Components
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';

export default function Filed() {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%'
        }}>
            <Title level={4} style={{ textAlign: 'center' }}>Processo Arquivado</Title>
            <VscFileSubmodule style={{ fontSize: '40pt' }} />
            <Text style={{ textAlign: 'center' }}>
                O processo se encontra arquivado
            </Text>
        </div>
    )
}