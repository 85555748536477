import React, { useState } from 'react';
// Libraries
import { useParams } from 'react-router-dom';
import { SearchOutlined, EyeOutlined } from '@ant-design/icons';
import { Form, Row, Col, message } from 'antd';
import Animate, { FadeIn } from 'animate-css-styled-components';
// Own Components
import Visualize from '../../../../../../Processes/components/Actions/Visualize';
// Components
import FormItem from '~/components/form/FormItem';
import Button from '~/components/button';
import Input from '~/components/input';
import Loading from '~/components/loading';
import Card from '~/components/card';
import Text from '~/components/typography/text';
import Drawer from '~/components/drawer';
// Utils
import { CapitalizeString } from '~/utils/Transformations';
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function AttachToExistingProcess({ setProcess, document }) {
  const { institutionId, organizationUnitId } = useParams();
  const [form] = Form.useForm();
  const [searchProcesses, setSearchProcesses] = useState();
  const [loadingContent, setLoadingContent] = useState(false);

  async function AddToProcess(processSelected) {
    const params = {
      operation: ['SIGAM', 'Processes', 'Attachments', 'Add'],
      urlParams: {
        institutionId: institutionId,
        organizationUnitId: organizationUnitId,
        processId: processSelected.id,
        documentId: document.id,
      },
      data: {
        name: document.name,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      message.success('Documento anexado com sucesso!');
      return setProcess(processSelected);
    } else {
      message.error('Alguma coisa aconteceu...');
    }
  }

  async function onFinish(values) {
    setLoadingContent(true);
    let searchString = '';
    for (const key in values) {
      if (values[key]) {
        searchString += `${values[key]}`;
      }
    }
    const params = {
      operation: ['SIGAM', 'Processes', 'Get'],
      urlParams: {
        institutionId: institutionId,
        organizationUnitId: organizationUnitId,
      },
      data: {
        filter: {
          ...values,
        },
        pagination: {
          sort: {
            by: 'name',
            order: 'ASC',
          },
          limit: 0,
        },
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      setSearchProcesses(response.body.processes.elements);
    }
    return setLoadingContent(false);
  }

  function ProcessCard({ item }) {
    return (
      <div style={{ marginBottom: 10 }}>
        <Card bodyStyle={{ padding: 5 }}>
          <Row style={{ display: 'flex', alignItems: 'center' }}>
            <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
              <Text>{CapitalizeString(item.name)}</Text>
            </Col>
            <Col
              span={12}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Drawer
                Trigger={<Button icon={<EyeOutlined />}>Visualizar</Button>}
                DrawerContentComponent={Visualize}
                DrawerContentComponentParams={{ process: [item] }}
                title={`${item.name}`}
                width="100%"
              />
              <Button
                type="primary"
                onClick={() => AddToProcess(item)}
                style={{ marginLeft: 10 }}
              >
                Selecionar Processo
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }

  if (searchProcesses && searchProcesses.length > 0) {
    return (
      <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
        <div>
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              type="dashed"
              onClick={() => setSearchProcesses()}
              icon={<SearchOutlined />}
            >
              Fazer outra busca
            </Button>
          </div>
          {searchProcesses.map((item) => (
            <ProcessCard key={item.id} item={item} />
          ))}
        </div>
      </Animate>
    );
  }

  if (loadingContent) {
    return (
      <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
        <Loading text="Buscando Processos" />
      </Animate>
    );
  }

  return (
    <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
      <Form
        labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        form={form}
        size="small"
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
      >
        <FormItem
          label={'Número do processo'}
          name={['internal_id']}
          item={<Input />}
        />
        <FormItem label={'Nome do processo'} name={['name']} item={<Input />} />
        <FormItem label={'Ano do processo'} name={['year']} item={<Input />} />
        <FormItem
          label={'Assunto do processo'}
          name={['subject']}
          item={<Input />}
        />
        <FormItem
          label={'Especificações do processo'}
          name={['specifications']}
          item={<Input />}
        />
        <FormItem
          item={
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
              Pesquisar
            </Button>
          }
        />
      </Form>
    </Animate>
  );
}
