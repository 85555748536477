import React, { useEffect, useState, useContext } from 'react';
// Libraries
import Animate, { FadeIn } from 'animate-css-styled-components';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Own Components
import OrganizationalUnitsContainer from './components/OrganizationalUnitsContainer';
import GroupsContainer from './components/GroupsContainer';
// Components
import Avatar from '~/components/avatar';
import Loading from '~/components/loading';
import Button from '~/components/button';
import Title from '~/components/typography/title';
import Space from '~/components/space';
// Utils
import { CapitalizeString } from '~/utils/Transformations';
import AdminButton from '~/pages/SIGAM/components/PersonDrawer/Admin/components/AdminButton';

export default function SearchDrawerComponent({
    selectedPerson,
    setSelectedPerson,
    setPeopleList,
    secondStepParameters
}) {
    const { isMobile } = useContext(ResponsiveContext);
    const [selectId, setSelectId] = useState();
    const [loadingContent, setLoadingContent] = useState(true);

    useEffect(() => {
        if (!selectId) {
            return;
        }
        if (loadingContent) {
            setLoadingContent(false)
        }
    }, [loadingContent, selectId])

    useEffect(() => {
        if (selectedPerson) {
            if (selectedPerson.id !== selectId) {
                if (!selectId) {
                    return setSelectId(selectedPerson.id);
                }
                setLoadingContent(true)
            } else {
                setSelectId(selectedPerson.id);
            }
        }
    }, [selectedPerson, selectId])

    function NewSearch() {
        setPeopleList();
        setSelectedPerson();
    }

    if (loadingContent) {
        return <Loading />
    }

    return (
        <Animate
            Animation={[FadeIn]}
            duration={['0.5s']}
            delay={['0.2s']}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: '20px'
                }}
            >
                <div style={{ marginBottom: 10 }}>
                    <Space direction={isMobile ? 'vertical' : 'horizontal'} align={isMobile ? 'center' : 'flex-start'}>
                        <Button onClick={() => NewSearch()}>Fazer outra busca</Button>
                        <Button onClick={() => setSelectedPerson()}>Selecionar outra pessoa</Button>
                    </Space>
                </div>
                <Avatar src={selectedPerson.avatar} size={120} />
                <Title level={4}>{CapitalizeString(selectedPerson.social_name || selectedPerson.name)}</Title>
                <OrganizationalUnitsContainer record={selectedPerson} setUpdatePending={secondStepParameters.setUpdatePending} />
                {/* <GroupsContainer record={selectedPerson} setUpdatePending={secondStepParameters.setUpdatePending} /> */}
                <AdminButton record={selectedPerson} setUpdatePending={secondStepParameters.setUpdatePending} />
            </div>
        </Animate>
    )
}