import React, { useEffect, useState } from 'react';
import { Spin, Space, Button, List, Tag, Divider } from 'antd';
import { useParams } from 'react-router-dom';
import SocialMeAPI from '~/utils/SocialMeAPI';
import { usePageManager } from '../../../../../../../../contexts/PageManager/Provider';

export default function Result({ formValues, setView, onClose }) {
  const { setSelectedProcessId } = usePageManager();
  const { institutionId } = useParams();
  const [loadingList, setLoadingList] = useState(true);
  const [processes, setProcesses] = useState(null);

  async function onSelect(id) {
    setSelectedProcessId(id);
    onClose();
  }

  useEffect(() => {
    async function GetData() {
      const params = {
        operation: ['SIGAM', 'Admin', 'Processes', 'GetAll'],
        urlParams: {
          institutionId: institutionId,
        },
        data: {
          filter: {
            ...formValues,
          },
          pagination: {
            sort: {
              by: 'name',
              order: 'asc',
            },
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setProcesses(response?.body?.processes?.elements);
      }
      setLoadingList(false);
    }
    GetData();
  }, [institutionId, formValues]);

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: 'flex',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Button
          type="dashed"
          style={{ width: '100%' }}
          onClick={() => setView('FORM')}
        >
          Buscar novamente
        </Button>
      </div>
      <Divider orientationMargin={0} />
      {loadingList ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginTop: 10,
          }}
        >
          <Spin />
        </div>
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={processes}
          renderItem={(item) => (
            <List.Item
              onClick={() => onSelect(item.id)}
              style={{
                border: '1px solid #F1F2F4',
                borderRadius: '8px',
                margin: '10px 0px',
                cursor: 'pointer',
              }}
            >
              <List.Item.Meta
                title={item.name}
                description={
                  <Space>
                    <Tag color="blue">{item.created_at_unity_name}</Tag>
                    {item.status === 'INACTIVE' && (
                      <Tag bordered={false}>Arquivado</Tag>
                    )}
                  </Space>
                }
              />
            </List.Item>
          )}
        />
      )}
    </Space>
  );
}
