import React, { useState, useContext, useEffect } from 'react';
//Libraries
import { Form, message, Row, Col } from 'antd';
//Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
//Standalone Components
import { Container, ButtonsContainer } from './style';
// Own Components
import CPFContainer from './components/CPFContainer';
import GeneralTerms from './components/GeneralTerms';
//Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import Button from '~/components/button';
import Select from '~/components/select';
import Title from '~/components/typography/title';
//Utils
import { ValidateDate } from '~/utils/Validations';
import { AmericanDateParser } from '~/utils/Parsers';
//Environments
import { Sex } from '~/environments/Selects';
import Debug from '~/environments/Debug';

import { dataBrasileiraMask } from '~/utils/Masks';

const isDebug = Debug.Modules.Authentication;

export default function RegisterPhysicalPerson({ login, setStep, setPerson }) {
  const { translate } = useContext(LanguageContext);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  const HandleChange = () => (event) => {
    console.log(event.target.value);
    return form.setFieldsValue({
      birth_certificate_date: dataBrasileiraMask(event.target.value),
    });
  };

  useEffect(() => {
    if (!formValues.name || formValues.name.length < 5)
      return setDisableButton(true);
    if (!formValues.mother_name || formValues.mother_name.length < 5)
      return setDisableButton(true);
    if (
      !formValues.birth_certificate_date ||
      formValues.birth_certificate_date.replace(/[^\d]+/g, '').length < 8
    )
      return setDisableButton(true);
    if (!formValues.sex || formValues.sex.lenght < 1)
      return setDisableButton(true);
    if (!formValues.main_contact || formValues.main_contact.length < 5)
      return setDisableButton(true);
    if (!formValues.auth_password || formValues.auth_password.length < 5)
      return setDisableButton(true);
    if (!formValues.confirm || formValues.confirm.length < 5)
      return setDisableButton(true);
    if (!formValues.agreement_terms) return setDisableButton(true);
    return setDisableButton(false);
  }, [formValues]);

  async function Register(data) {
    setLoadingButton(true);
    if (isDebug) console.log('🚀 API: Auth Register');
    const params = {
      operation: ['Auth', 'Register', 'Physical'],
      data,
    };
    if (isDebug) console.log('📦 Params: ', params);
    const response = await SocialMeAPI(params);
    if (isDebug) console.log('  ⚡ API Response, Auth Register: ', response);
    if (response.success) {
      if (response.message === 'Successfully registered person') {
        if (isDebug) console.log('    Successfully registered person');
        setPerson(response.body.newPerson);
        message.success(translate.authentication.registrationSuccessful.text);
        setLoadingButton(false);
        return setStep('Login');
      }
    } else {
      if (response.message === 'CPF in use') {
        if (isDebug) console.log('    CPF in use');
        message.error(translate.authentication.theCPFIsAlreadyRegistered.text);
        return setLoadingButton(false);
      }
      if (response.message === 'Incorrect parameter sent') {
        if (isDebug) console.log('    Incorrect parameter sent');
        message.error(translate.fields.checkTheFieldsAndTryAgain.text);
        return setLoadingButton(false);
      }
    }
  }
  async function onFinish(data) {
    data.kind = 'PF';
    data.birth_certificate_date = AmericanDateParser(
      data.birth_certificate_date
    );
    data.cpf_number = login.replace(/[^\d]+/g, '');
    Register(data);
  }

  return (
    <Container>
      <Row>
        <Title
          style={{ margin: '0px auto 10px', textAlign: 'center' }}
          level={4}
        >
          {translate.authentication.physicalPersonRegister.text}
        </Title>
      </Row>
      <Form
        labelCol={{
          xs: { span: 24 },
          sm: { span: 24 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 24 },
        }}
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onValuesChange={() => setFormValues(form.getFieldsValue())}
        scrollToFirstError
        style={{ width: '100%' }}
      >
        <Row>
          <Col span={24}>
            <CPFContainer login={login} setStep={setStep} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem
              style={{ margin: '0px 0px 10px' }}
              name="name"
              rules={[
                {
                  required: true,
                  message: translate.fields.required.name.text,
                },
              ]}
              item={
                <Input
                  label={translate.fields.label.fullName.text}
                  placeholder={translate.fields.example.fullName.text}
                />
              }
            />
          </Col>
        </Row>
        <Row span={24}>
          <Col span={24}>
            <FormItem
              style={{ margin: '0px 0px 10px' }}
              name={['mother_name']}
              rules={[
                {
                  required: true,
                  message: translate.fields.required.motherName.text,
                },
              ]}
              item={
                <Input
                  label={translate.fields.label.motherName.text}
                  placeholder={translate.fields.example.motherName.text}
                />
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <FormItem
              style={{ margin: '0px 10px 10px 0px', width: '120px' }}
              name={['birth_certificate_date']}
              rules={[
                {
                  required: true,
                  message: translate.fields.required.birth.text,
                },
              ]}
              item={
                <Input
                  onChange={HandleChange()}
                  label={translate.fields.label.birth.text}
                  placeholder={translate.fields.example.birth.text}
                />
              }
            />
          </Col>
          <Col span={12}>
            <FormItem
              style={{ margin: '0px 0px 10px', width: '150px' }}
              name="sex"
              rules={[
                {
                  required: true,
                  message: translate.fields.required.sex.text,
                },
              ]}
              item={
                <Select
                  options={Sex}
                  label={translate.fields.label.sex.text}
                  placeholder={translate.fields.placeholder.select.text}
                  noDefaultValue
                />
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem
              style={{ margin: '0px 0px 10px 0px' }}
              name={['main_contact']}
              rules={[
                {
                  required: true,
                  message: 'Um email é obrigatório',
                },
              ]}
              item={
                <Input
                  label={'Email'}
                  placeholder={'Ex: seunome@email.com.br'}
                  type="email"
                />
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem
              style={{ margin: '0px 0px 10px 0px' }}
              name="auth_password"
              item={
                <Input
                  type="password"
                  label={translate.fields.label.password.text}
                />
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem
              style={{ margin: '0px 0px 10px 0px' }}
              name="confirm"
              rules={[
                {
                  required: true,
                  message: translate.fields.required.passwordConfirm.text,
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('auth_password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      translate.fields.invalid.passwordConfirm.text
                    );
                  },
                }),
              ]}
              item={
                <Input
                  type="password"
                  label={translate.fields.label.passwordConfirm.text}
                />
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem
              style={{ margin: '10px 0px 0px 0px', height: 20 }}
              name="agreement_terms"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          translate.authentication.youMustAcceptTheTerms.text
                        ),
                },
              ]}
              item={
                <GeneralTerms
                  form={form}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              }
            />
          </Col>
        </Row>

        <ButtonsContainer>
          <Button
            loading={loadingButton}
            disabled={loadingButton}
            onClick={() => setStep('Verification')}
            size="large"
          >
            {translate.basic.back.text}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingButton}
            disabled={disableButton}
            size="large"
          >
            {translate.basic.next.text}
          </Button>
        </ButtonsContainer>
      </Form>
    </Container>
  );
}
