import React from 'react';
// Libraries
import { Row, Col } from 'antd';
// Components
import Text from '~/components/typography/text';
import Space from '~/components/space';
import Card from '~/components/card';
// Utils
import { CapitalizeString } from '~/utils/Transformations';
import { DateInBrazilianFormat } from '~/utils/DateAndTime';

export default function Header({ process }) {

    function InformationRow({ label, value, component }) {
        return (
            <Space>
                <Text strong>{label}:</Text>
                {value ?
                    <Text>{value}</Text>
                    :
                    component
                }
            </Space>
        )
    }

    function GetPrivacy(privacy) {
        switch (privacy) {
            case 'restricted':
                return 'Restrito';
            case 'confidential':
                return 'Sigiloso'
            default:
                return 'Público'
        }
    }

    return (
        <Card bodyStyle={{ display: 'flex', flexDirection: 'column' }}>
            <Row>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <InformationRow label="Nome" value={process.name} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <InformationRow label="Data de criação" value={DateInBrazilianFormat(process.created_at)} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <InformationRow label="Criado por" value={CapitalizeString(process.created_by_social_name || process.created_by_name)} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <InformationRow label="Unidade de origem" value={process.created_at_unity_name} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <InformationRow label="Processo prioritário" value={CapitalizeString(process.priority === 0 ? 'Não' : 'Sim')} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <InformationRow label="Status" value={CapitalizeString(process.status === 'ACTIVE' ? 'processo Ativo' : 'processo Inativo')} />
                </Col>
                {process.privacy &&
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <InformationRow label="Privacidade" value={GetPrivacy(process.privacy)} />
                    </Col>
                }
                {process.specifications &&
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <InformationRow label="Descrição" value={process.specifications} />
                    </Col>
                }
                {process.subject &&
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <InformationRow label="Assunto" value={process.subject} />
                    </Col>
                }
                {process.url &&
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <InformationRow label="Link de acesso" component={<a href={process.url} target="_blank" rel="noopener noreferrer">Clique Aqui</a>} />
                    </Col>
                }
                {process.internal_id &&
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <InformationRow label="Id Interno" value={process.internal_id} />
                    </Col>
                }
                {process.external_id &&
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <InformationRow label="Id Externo" value={process.external_id} />
                    </Col>
                }
                {process.manual_id &&
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <InformationRow label="Id Manual" value={process.manual_id} />
                    </Col>
                }
                {process.model_name &&
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <InformationRow label="Baseado no modelo" value={process.model_name} />
                    </Col>
                }
            </Row>
        </Card>
    )
}