import React, { useEffect, useState } from 'react';
import { GrFormCheckmark, GrFormClose, GrEdit } from 'react-icons/gr';
import { useParams } from 'react-router-dom';
import { SyncOutlined } from '@ant-design/icons';
import Text from '~/components/typography/text';
import SocialMeAPI from '~/utils/SocialMeAPI';
import AttachmentForm from './components/AttachmentForm';

export default function FileContainer({ file, propertys }) {
  const { institutionId, organizationUnitId } = useParams();
  const [status, setStatus] = useState('loading');
  const [message, setMessage] = useState('');
  const [fileLink, setFileLink] = useState('');

  useEffect(() => {
    async function checkSize() {
      if (file.size > 10485760) {
        setStatus('error');
        setMessage('O arquivo tem mais do que 10 MB');
        return false;
      }
      return true;
    }
    async function checkType() {
      if (file.type !== 'application/pdf') {
        setStatus('error');
        setMessage('O arquivo não é um PDF');
        return false;
      }
      return true;
    }
    async function Upload() {
      const size = await checkSize();
      if (!size) return;
      const type = await checkType();
      if (!type) return;
      const data = new FormData();
      data.append('file', file);
      const params = {
        operation: ['SIGAM', 'Documents', 'UploadFile'],
        urlParams: {
          institutionId,
          organizationUnitId,
        },
        data,
      };
      const response = await SocialMeAPI(params);
      console.log(response);
      if (response.success) {
        setStatus('success');
        setFileLink(response.body.file);
        return;
      }
      if (
        response.message === 'The file extension is not allowed in this context'
      ) {
        message.error('Formato inválido');
      } else {
        message.error('Alguma coisa aconteceu...');
      }
    }
    Upload();
    console.log(file);
  }, []);

  function formatBytes(bytes, decimals = 2) {
    if (!bytes) return '0 Bytes';
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  const bg = {
    loading: '#fafafa',
    success: '#fafafa',
    completed: '#C6F6D5',
    error: '#FED7D7',
  };

  const textColor = {
    loading: '#888888',
    success: '#888888',
    completed: '#38A169',
    error: '#E53E3E',
  };

  const icons = {
    loading: <SyncOutlined style={{ fontSize: '14pt' }} spin />,
    success: <GrEdit style={{ color: '#88888888', fontSize: '20pt' }} />,
    completed: (
      <GrFormCheckmark style={{ color: '#38A169', fontSize: '20pt' }} />
    ),
    error: <GrFormClose style={{ color: '#E53E3E', fontSize: '20pt' }} />,
  };

  return (
    <div
      style={{
        backgroundColor: bg[status],
        height: 'auto',
        width: '100%',
        marginTop: 10,
        borderRadius: 8,
        border: '1px solid #d9d9d9',
        borderStyle: 'solid',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '10px',
        }}
      >
        <div
          style={{
            width: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '10px',
          }}
        >
          {icons[status]}
        </div>
        <div>
          <Text>
            {file?.name ? file.name : 'arquivo desconhecido'} {``}
          </Text>
          <Text>{formatBytes(file?.size)}</Text>
        </div>
      </div>
      {status === 'success' ? (
        <AttachmentForm
          file={file}
          fileLink={fileLink}
          setStatus={setStatus}
          setMessage={setMessage}
          propertys={propertys}
        />
      ) : (
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <Text style={{ color: textColor[status] }}>{message}</Text>
        </div>
      )}
    </div>
  );
}
