import React from 'react';
import { Layout, Col, Row } from 'antd';
import { Redirect } from 'react-router-dom';

import { useMessagerManager } from './contexts/MessagerManager/Provider';
import { useAuth } from '~/contexts/AuthContext';

import Sidebar from './components/Sidebar';
import MessagerContainer from './components/MessagerContainer';

export default function Messager() {
  const { user } = useAuth();
  const { selectedChat } = useMessagerManager();

  if (!user) return <Redirect to="/" />;

  return (
    <Layout>
      <Row style={{ height: '100vh' }}>
        <Col xs={selectedChat ? 0 : 24} sm={24} md={10} lg={8} xl={8}>
          <Sidebar />
        </Col>

        <Col xs={selectedChat ? 24 : 0} sm={24} md={14} lg={16} xl={16}>
          <MessagerContainer />
        </Col>
      </Row>
    </Layout>
  );
}
