export default {
  // A
  almostThere: {
    original: 'Almost there',
    text: 'Quase lá',
  },
  // B
  // C
  checkingUser: {
    original: 'Checking user',
    text: 'Verificando usuário',
  },
  contactSuccessfullyRegistered: {
    original: 'Contact Successfully registered!',
    text: 'Contato Cadastrado!',
  },
  cookieNotification: {
    original:
      "We care about your data, and we'd love to use cookies to make your experience better.",
    text:
      'Nos preocupamos com seus dados e adoraríamos usar cookies para tornar sua experiência melhor.',
  },
  // D
  didNotChangeYourProfilePhotoForThe1stTime: {
    original: "You haven't changed your profile photo for the first time yet",
    text: 'Você ainda não trocou sua foto de perfil pela 1ª vez',
  },
  doYouReallyWantToDeleteThisPhoto: {
    original: 'Do you really want to delete this photo?',
    text: 'Deseja realmente apagar essa foto?',
  },
  // E
  // F
  fetchingYourPhotos: {
    original: 'Fetching your photos...',
    text: 'Buscando suas fotos...',
  },
  fetchingYourDocuments: {
    original: 'Fetching your documents...',
    text: 'Buscando seus documentos...',
  },
  // G
  // H
  // I
  invalidFileFormat: {
    original: 'Invalid file format',
    text: 'Formato de arquivo inválido',
  },
  itWasntSupposedToTakeThatLong: {
    original: "It wasn't supposed to take that long",
    text: 'Não era para demorar tanto assim',
  },
  // J
  // K
  // L
  loading: {
    original: 'Loading',
    text: 'Carregando',
  },
  loadingData: {
    original: 'Loading Data...',
    text: 'Carregando Dados...',
  },
  // M
  // N
  // O
  // P
  photoSuccessfullyDeleted: {
    original: 'Photo successfully deleted!',
    text: 'Foto apagada com sucesso!',
  },
  preparingYourDocuments: {
    original: 'Preparing your documents...',
    text: 'Preparando seus documentos...',
  },
  preparingYourTimeline: {
    original: 'Preparing your timeline...',
    text: 'Preparando sua linha do tempo...',
  },
  profilePhotoUpdatedSuccessfully: {
    original: 'Profile photo updated successfully!',
    text: 'Foto de perfil atualizada com sucesso!',
  },
  // Q
  QRCodeSuccessfullyChanged: {
    original: 'QRCode successfully changed',
    text: 'QRCode alterado com sucesso',
  },
  // R
  // S
  somethingHappened: {
    original: 'Something happened...',
    text: 'Alguma coisa aconteceu...',
  },
  sorryForTheDelay: {
    original: 'Sorry for the delay',
    text: 'Desculpa pela demora',
  },
  // T
  tryAgainLater: {
    original: 'Try again later',
    text: 'Tente novamente mais tarde',
  },
  // U
  updatedProfile: {
    original: 'Updated profile!',
    text: 'Perfil atualizado!',
  },
  // V
  // W
  // Y
  // X
  // Z
};
