import React, { useState, useEffect } from 'react';
// Libraries
import { Layout, message } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import Animate, { FadeIn } from 'animate-css-styled-components';
// Own Components
import Button from '~/components/button';
import Drawer from '~/components/drawer';
import SocialMeAPI from '~/utils/SocialMeAPI';
import Nav from './components/Nav';
import VerificationCard from './components/VerificationCard';
import Visualize from './components/Visualize';
// Components
// Utils

const { Header, Content } = Layout;

// Verifique a autenticidade deste documento em https://sigam.net.br/verificacao informando o código: TNWHCZU

export default function Verification() {
  const [number, setNumber] = useState('');
  const [code, setCode] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [document, setDocument] = useState();

  async function Validate() {
    setLoadingButton(true);
    const params = {
      operation: ['SIGAM', 'Consult', 'Document'],
      data: {
        internal_id: number,
        verification_id: code,
      },
    };
    // console.log('params ', params)
    const response = await SocialMeAPI(params);
    if (response.success) {
      setDocument(response.body);
      message.success('Documento encontrado!');
    } else {
      message.error('Documento não encontrado...');
    }
    setLoadingButton(false);
  }

  const HandleChangeNumber = () => (event) => {
    if (event.target.value.length > 18) {
      return;
    }
    return setNumber(event.target.value);
  };

  const HandleChangeCode = () => (event) => {
    if (event.target.value.length > 7) {
      return;
    }
    return setCode(event.target.value);
  };

  useEffect(() => {
    // 6-2021-0000102806
    // 77777
    if (number && number.length === 18) {
      if (code && code.length === 7) {
        return setDisableButton(false);
      }
    }
    setDisableButton(true);
  }, [number, code]);

  return (
    <Layout>
      <Header style={{ padding: 0 }}>
        <Nav />
      </Header>
      <Content
        style={{
          minHeight: `calc(100vh - 64px)`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <VerificationCard
          loadingButton={loadingButton}
          disableButton={disableButton}
          HandleChangeNumber={HandleChangeNumber}
          HandleChangeCode={HandleChangeCode}
          Validate={Validate}
          number={number}
          code={code}
          setNumber={setNumber}
        />
        {document && (
          <Animate Animation={[FadeIn]} duration={['0.8s']} delay={['0.3s']}>
            <Drawer
              Trigger={
                <Button
                  type="primary"
                  size="large"
                  style={{ marginTop: 20 }}
                  icon={<CloudDownloadOutlined />}
                >
                  Visualizar Documento
                </Button>
              }
              DrawerContentComponent={Visualize}
              DrawerContentComponentParams={{ document }}
              width="100%"
              title="Visualizar Documento"
            />
          </Animate>
        )}
      </Content>
    </Layout>
  );
}
