import API from '~/environments/APIs';

const System = {
  Location: {
    Search: {
      baseURL: API.socialMe,
      url: '/public/location/places',
      method: 'post',
      timeout: '50000',
    },
    Get: {
      baseURL: API.socialMe,
      url: '/location/csccs',
      method: 'post',
      timeout: '50000',
    },
    Add: {
      baseURL: API.socialMe,
      url: '/manage/person/personId/countryStateCity',
      method: 'post',
      timeout: '50000',
    },
    Edit: {
      baseURL: API.socialMe,
      url: '/manage/person/personId/countryStateCity/countryStateCityId',
      method: 'put',
      timeout: '50000',
    },
    Delete: {
      baseURL: API.socialMe,
      url: '/manage/person/personId/countryStateCity/countryStateCityId',
      method: 'delete',
      timeout: '50000',
    },
  },
  ActivityType: {
    ListSimple: {
      baseURL: API.socialMe,
      url: '/activity/activity-types',
      method: 'post',
      timeout: '50000',
    },
  },
  Person: {
    Search: {
      baseURL: API.socialMe,
      url: '/person/search',
      method: 'post',
      timeout: '50000',
    },
    PreRegistration: {
      baseURL: API.socialMe,
      url: '/person/pre-registration',
      method: 'post',
      timeout: '50000',
    },
    Show: {
      baseURL: API.socialMe,
      url: '/manage/person/profile/personId',
      method: 'get',
      timeout: '50000',
    },
  },
  Kinships: {
    Get: {
      baseURL: API.socialMe,
      url: '/kinships',
      method: 'post',
      timeout: '50000',
    },
  },
  UnitsOfMeasure: {
    Get: {
      baseURL: API.socialMe,
      url: '/prod-serv/m-unities',
      method: 'post',
      timeout: '50000',
    },
  },
};

export default System;
