const Status = [
    {
        title: 'Geral: Autenticação',
        items: [
            {
                text: 'Verificar Documento',
                finish: true
            },
            {
                text: 'Cadastrar Pessoa Física',
                finish: true
            },
            {
                text: 'Entrar no sistema',
                finish: true
            },
            {
                text: 'Sair no sistema',
                finish: true
            },
            {
                text: 'Recuperar senha',
                finish: true
            }
        ]
    },
    {
        title: 'Geral: Início',
        items: [
            {
                text: 'Listar todas as Instituições',
                finish: true
            },
            {
                text: 'Verificar instituições em que sou admin',
                finish: true
            },
            {
                text: 'Verificar instituições em que sou agente público',
                finish: true
            },
        ]
    },
    {
        title: 'Geral: Perfil',
        items: [
            {
                text: 'Listar Informações Básicas',
                finish: true
            },
            {
                text: 'Editar Informações Básicas',
                finish: true
            },
            {
                text: 'Alterar foto',
                finish: true
            },
        ]
    },
    {
        title: 'Geral: Consultas',
        items: [
            {
                text: 'Consultar Documento',
                finish: true
            },
            {
                text: 'Visualizar Documento',
                finish: true
            },
        ]
    },
    {
        title: 'Administrador: Usuários',
        items: [
            {
                text: 'Listar Usuários',
                finish: true
            },
            {
                text: 'Pesquisar usuários na base de dados',
                finish: true
            },
            {
                text: 'Listar informações de um usuário (dados, unidades e grupos)',
                finish: true
            },
            {
                text: 'Vincular unidade ao usuário',
                finish: true
            },
            {
                text: 'Desvincular unidade do usuário',
                finish: true
            },
            {
                text: 'Vincular grupo ao usuário',
                finish: true
            },
            {
                text: 'Desvincular grupo do usuário',
                finish: true
            },
        ]
    },
    {
        title: 'Administrador: Unidades',
        items: [
            {
                text: 'Listar Unidades',
                finish: true
            },
            {
                text: 'Filtrar Unidades',
                finish: true
            },
            {
                text: 'Criar nova Unidade',
                finish: true
            },
            {
                text: 'Editar Unidade',
                finish: true
            },
            {
                text: 'Listar Permissões da Unidade',
                finish: true
            },
            {
                text: 'Adicionar Permissão para a Unidade',
                finish: true
            },
            {
                text: 'Remover Permissão para a Unidade',
                finish: true
            },
            {
                text: 'Listar usuários da Unidade',
                finish: true
            },
            {
                text: 'Pesquisar usuários',
                finish: true
            },
            {
                text: 'Adicionar usuário',
                finish: true
            },
            {
                text: 'Remover usuário',
                finish: true
            },
        ]
    },
    {
        title: 'Administrador: Grupos',
        items: [
            {
                text: 'Listar Grupos',
                finish: true
            },
            {
                text: 'Filtrar Grupos',
                finish: true
            },
            {
                text: 'Criar nova Grupo',
                finish: true
            },
            {
                text: 'Editar Grupo',
                finish: true
            },
            {
                text: 'Listar Permissões da Grupo',
                finish: true
            },
            {
                text: 'Adicionar Permissão para a Grupo',
                finish: true
            },
            {
                text: 'Remover Permissão para a Grupo',
                finish: true
            },
            {
                text: 'Listar usuários da Grupo',
                finish: true
            },
            {
                text: 'Pesquisar usuários',
                finish: true
            },
            {
                text: 'Adicionar usuário',
                finish: true
            },
            {
                text: 'Remover usuário',
                finish: true
            },
        ]
    },
    {
        title: 'Administrador: Modelos de Documentos',
        items: [
            {
                text: 'Listar Modelos de Documentos',
                finish: true
            },
            {
                text: 'Filtrar Modelos de Documentos',
                finish: true
            },
            {
                text: 'Adicionar Modelo de Documento',
                finish: true
            },
            {
                text: 'Editar Modelo de Documento',
                finish: true
            },
            {
                text: 'Deletar Modelo de Documento',
                finish: true
            },
        ]
    },
    {
        title: 'Administrador: Tipos de Processos',
        items: [
            {
                text: 'Listar Tipos de Processos',
                finish: true
            },
            {
                text: 'Filtrar Tipos de Processos',
                finish: true
            },
            {
                text: 'Adicionar Tipo de Processo',
                finish: true
            },
            {
                text: 'Editar Tipo de Processo',
                finish: true
            },
            {
                text: 'Deletar Tipo de Processo',
                finish: true
            },
        ]
    },
    {
        title: 'Agente Público: Validações',
        items: [
            {
                text: 'Validar se pertence à instituição',
                finish: true
            },
            {
                text: 'Validar se pertence à unidade',
                finish: true
            },
        ]
    },
    {
        title: 'Agente Público: Documentos',
        items: [
            {
                text: 'Listar Documentos',
                finish: true
            },
            {
                text: 'Filtrar Documentos pelas abas',
                finish: true
            },
            {
                text: 'Filtrar Documentos pelas filtros',
                finish: true
            },
            {
                text: 'Criar novo documento em branco',
                finish: true
            },
            {
                text: 'Criar novo documento baseado em modelo',
                finish: true
            },
            {
                text: 'Criar novo documento externo',
                finish: true
            },
            {
                text: 'Editar documento em branco',
                finish: true
            },
            {
                text: 'Editar documento baseado em modelo',
                finish: true
            },
            {
                text: 'Editar documento externo',
                finish: true
            },
            {
                text: 'Visualizar documento em banco',
                finish: true
            },
            {
                text: 'Visualizar documento baseado em modelo',
                finish: true
            },
            {
                text: 'Visualizar documento externo',
                finish: true
            },
            {
                text: 'Visualizar tipo de acesso',
                finish: true
            },
            {
                text: 'Visualizar pessoas que podem ver o documento',
                finish: true
            },
            {
                text: 'Buscar nova pessoa',
                finish: true
            },
            {
                text: 'Adicionar pessoa na lista restrita',
                finish: true
            },
            {
                text: 'Remover pessoa da lista restrita',
                finish: true
            },
            {
                text: 'Listar Editores',
                finish: true
            },
            {
                text: 'Adicionar novo Editor',
                finish: true
            },
            {
                text: 'Remover Editor',
                finish: true
            },
            {
                text: 'Listar Interessados',
                finish: true
            },
            {
                text: 'Adicionar novo interessado',
                finish: true
            },
            {
                text: 'Remover interessado',
                finish: true
            },
            {
                text: 'Listar Assinantes',
                finish: true
            },
            {
                text: 'Adicionar novo assinante',
                finish: true
            },
            {
                text: 'Remover assinante',
                finish: true
            },
            {
                text: 'Listar Anexos',
                finish: true
            },
            {
                text: 'Adicionar novo anexo',
                finish: true
            },
            {
                text: 'Inativar anexo',
                finish: true
            },
            {
                text: 'Assinar documento',
                finish: true
            },
            {
                text: 'Cancelar assinatura no documento',
                finish: true
            },
            {
                text: 'Tramitar anexando em um novo processo',
                finish: true
            },
            {
                text: 'Tramitar buscando um processo existente',
                finish: true
            },
            {
                text: 'Visualizar histórico',
                finish: true
            },
        ]
    },
    {
        title: 'Agente Público: Processos',
        items: [
            {
                text: 'Listar Processos',
                finish: true
            },
            {
                text: 'Filtrar Processos pelas abas',
                finish: true
            },
            {
                text: 'Filtrar Processos pelas filtros',
                finish: true
            },
            {
                text: 'Criar novo processo',
                finish: true
            },
            {
                text: 'Editar processo',
                finish: true
            },
            {
                text: 'Visualizar processo',
                finish: true
            },
            {
                text: 'Visualizar tipo de acesso',
                finish: true
            },
            {
                text: 'Visualizar pessoas que podem ver o processo',
                finish: true
            },
            {
                text: 'Buscar nova pessoa',
                finish: true
            },
            {
                text: 'Adicionar pessoa na lista restrita',
                finish: true
            },
            {
                text: 'Remover pessoa da lista restrita',
                finish: true
            },
            {
                text: 'Listar Interessados',
                finish: true
            },
            {
                text: 'Adicionar novo interessado',
                finish: true
            },
            {
                text: 'Remover interessado',
                finish: true
            },
            {
                text: 'Listar Assinantes',
                finish: true
            },
            {
                text: 'Adicionar novo assinante',
                finish: true
            },
            {
                text: 'Remover assinante',
                finish: true
            },
            {
                text: 'Listar Anexos',
                finish: true
            },
            {
                text: 'Adicionar novo anexo',
                finish: true
            },
            {
                text: 'Inativar anexo',
                finish: true
            },
            {
                text: 'Assinar processo',
                finish: true
            },
            {
                text: 'Cancelar assinatura no processo',
                finish: true
            },
            {
                text: 'Tramitar processo',
                finish: true
            },
            {
                text: 'Visualizar histórico',
                finish: false
            },
            {
                text: 'Arquivar Processo',
                finish: true
            },
        ]
    }
]
  
  export default Status;
  