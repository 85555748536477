import React from 'react';
// Libraries
import { Row, Col } from 'antd';
// Components
import Text from '~/components/typography/text';
import Space from '~/components/space';
import Card from '~/components/card';
// Utils
import { CapitalizeString } from '~/utils/Transformations';
import { DateInBrazilianFormat } from '~/utils/DateAndTime';

export default function Header({ document }) {
  function InformationRow({ label, value, component }) {
    return (
      <Space>
        <Text strong>{label}:</Text>
        {value ? <Text>{value}</Text> : component}
      </Space>
    );
  }

  function GetPrivacy(privacy) {
    switch (privacy) {
      case 'RESTRICTED':
        return 'Restrito';
      case 'CONFIDENTIAL':
        return 'Sigiloso';
      default:
        return 'Público';
    }
  }

  return (
    <Card bodyStyle={{ display: 'flex', flexDirection: 'column' }}>
      <Row>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <InformationRow label="Nome" value={document.name} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <InformationRow
            label="Data de criação"
            value={DateInBrazilianFormat(document.created_at)}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <InformationRow
            label="Criado por"
            value={CapitalizeString(
              document.created_by_social_name || document.created_by_name
            )}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <InformationRow
            label="Unidade de origem"
            value={document.created_at_unity_name}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <InformationRow
            label="Documento prioritário"
            value={CapitalizeString(document.priority === 0 ? 'Não' : 'Sim')}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <InformationRow
            label="Status"
            value={CapitalizeString(
              document.status === 'ACTIVE'
                ? 'documento Ativo'
                : 'documento Inativo'
            )}
          />
        </Col>
        {document.privacy && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <InformationRow
              label="Privacidade"
              value={GetPrivacy(document.privacy)}
            />
          </Col>
        )}
        {document.specifications && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <InformationRow label="Descrição" value={document.specifications} />
          </Col>
        )}
        {document.subject && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <InformationRow label="Assunto" value={document.subject} />
          </Col>
        )}
        {document.url && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <InformationRow
              label="Link de acesso"
              component={
                <a
                  href={document.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Clique Aqui
                </a>
              }
            />
          </Col>
        )}
        {document.internal_id && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <InformationRow label="Id Interno" value={document.internal_id} />
          </Col>
        )}
        {document.external_id && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <InformationRow label="Id Externo" value={document.external_id} />
          </Col>
        )}
        {document.manual_id && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <InformationRow label="Id Manual" value={document.manual_id} />
          </Col>
        )}
        {document.model_name && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <InformationRow
              label="Baseado no modelo"
              value={document.model_name}
            />
          </Col>
        )}
      </Row>
    </Card>
  );
}
