import React from 'react';
import { Button } from 'antd';
import { FileAddFilled } from '@ant-design/icons';

export default function FileButton({ file }) {
  return (
    <a href={file.url} target="_blank">
      <Button icon={<FileAddFilled />}>Abrir em outra aba</Button>
    </a>
  );
}
