import React, { useState } from 'react';
import { Button, Drawer, Descriptions } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { usePageManager } from '../../../../../../contexts/PageManager/Provider';

export default function ViewButton({ record }) {
  const [open, setOpen] = useState(false);
  const [loadingEditButton, setLoadingEditButton] = useState();
  const [templateValues, setTemplateValues] = useState();
  const { showTemplate } = usePageManager();

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  async function getItem() {
    setLoadingEditButton(true);
    const response = await showTemplate(record.id);
    if (response.success) {
      setTemplateValues(response.body.communication_model);
      showDrawer();
    }
    setLoadingEditButton(false);
  }

  return (
    <>
      <Button
        type="primary"
        icon={<EyeOutlined />}
        loading={loadingEditButton}
        onClick={() => getItem()}
      >
        Visualizar
      </Button>
      <Drawer
        title={record.name}
        placement="right"
        onClose={onClose}
        open={open}
      >
        {templateValues && (
          <Descriptions column={1}>
            <Descriptions.Item label="Nome">
              {templateValues.name}
            </Descriptions.Item>
            <Descriptions.Item label="Descrição">
              {templateValues.description}
            </Descriptions.Item>
            <Descriptions.Item label="Corpo da mensagem">
              {templateValues.body}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Drawer>
    </>
  );
}
