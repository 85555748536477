import React from 'react';
// Libraries
import { Route, Switch, Redirect } from 'react-router-dom';
// Pages
import Home from './pages/Home';
import Profiles from './pages/Profiles';
import Updates from './pages/Updates';
import Admin from './pages/Admin'

export default function Dashboards() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/status" component={Updates} />
      <Route path="/sigam-admin" component={Admin} />
      <Route path="/:institutionId" component={Profiles} />
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}
