import { DecodeURI } from '~/utils/Transformations';
import { DateInBrazilianFormat } from '~/utils/DateAndTime';

function getProcessHeader(fatherProcess) {
  return `
  <thead style="display: table-header-group;font-family: Arial; width: 100%; margin-bottom: 10px;">
    <th style="display: flex; width: 100%; justify-content: space-between; align-content: center;">
      <div style="width: 100%; display: flex; justify-content: left;">
          <img src="${DecodeURI(
            fatherProcess.institution_avatar || ''
          )}" style="width: 50px; heigth: 50px;" />
      </div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-content: center; width: 100%;">
          <h5 style="margin: 0; font-weight: bold; font-size: 14px;">${
            fatherProcess.institution_name
          }</h5>
          <p style="font-size: 12px; margin: 0; font-weight: 100;">${
            fatherProcess.created_at_unity_name
          }</p>
      </div>
      <div style="width: 100%; display: flex; justify-content: right;">
        <p style="font-size: 12px; margin: 0; font-weight: 100;">NPro: ${
          fatherProcess.internal_id
        }</p>
      </div>
    </th>
  </thead>
  `;
}

function getProcessFooter() {
  return `
  <tfoot style="display: table-footer-group;font-family: Arial; margin-top: 10px;">
    <tr>
      <th style="width: 100%; display: flex; justify-content: end; align-items: end;">
      <div></div>
        <div style="margin-left: 10px"></div>
        <div style="margin-left: auto; display: flex; justify-content: end; align-items: end;">
          <img src="https://media.milanote.com/p/images/1L5DiR1bzJOVfr/R9B/SIGAM-02.jpg" style="width: 80px; heigth: 40px;" />
        </div>
      </th>
    </tr>
  </tfoot>
  `;
}

function getProcessContent(fatherProcess) {
  return `
  <div>
    <table style="font-family: Arial; width: 100%;">
      <tbody>
        <tr>
            <td>
              <div style="display: flex; gap: 6px; margin-bottom: 6px;">
                <p style="margin: 0; font-size: 14px;">Nome: </p>
                <p style="margin: 0; font-size: 14px;">${fatherProcess.name}</p>
              </div>
              <div style="display: flex; gap: 6px; margin-bottom: 6px;">
                <p style="margin: 0; font-size: 14px;">Criação: </p>
                <p style="margin: 0; font-size: 14px;">${DateInBrazilianFormat(
                  fatherProcess.created_at
                )}</p>
              </div>
              <div style="display: flex; gap: 6px; margin-bottom: 6px;">
                <p style="margin: 0; font-size: 14px;">Instituição: </p>
                <p style="margin: 0; font-size: 14px;">${
                  fatherProcess.institution_name
                }</p>
              </div>
              <div style="display: flex; gap: 6px; margin-bottom: 6px;">
                <p style="margin: 0; font-size: 14px;">Unidade: </p>
                <p style="margin: 0; font-size: 14px;">${
                  fatherProcess.created_at_unity_name
                }</p>
              </div>
              <div style="display: flex; gap: 6px; margin-bottom: 6px;">
                <p style="margin: 0; font-size: 14px;">Id interno: </p>
                <p style="margin: 0; font-size: 14px;">${
                  fatherProcess.internal_id
                }</p>
              </div>
            </td>
        </tr>
      </tbody>
    </table>
  </div>
  `;
}

const getTimeline = (timeline) => {
  return `
  <tr>
    <td style="border: 1px solid #F1F2F4; text-align: left; padding: 8px;">${timeline.date} ${timeline.hour}</td>
    <td style="border: 1px solid #F1F2F4; text-align: left; padding: 8px;">${timeline.title}</td>
    <td style="border: 1px solid #F1F2F4; text-align: left; padding: 8px;">${timeline.message}</td>
  </tr>
  `;
};

const getTimelines = (timelineItems) => {
  if (!timelineItems) return '';
  if (timelineItems.length === 0) return '';

  let pages = '';
  for (let i = 0; i < timelineItems.length; i++) {
    const htmlValue = getTimeline(timelineItems[i]);
    pages = pages.concat(htmlValue);
  }

  return `
  <table style="font-family: Arial; width: 100%; margin-top: 20px; border-collapse: collapse;">
      <tr>
        <th style="border: 1px solid #F1F2F4; text-align: left; padding: 8px;">Data</th>
        <th style="border: 1px solid #F1F2F4; text-align: left; padding: 8px;">Ação</th>
        <th style="border: 1px solid #F1F2F4; text-align: left; padding: 8px;">Detalhes</th>
      </tr>
      ${pages}
    </table>
  `;
};

const getAttachment = (attachment, fatherProcess, index) => {
  return `
  <table style="page-break-after: always; font-family: Arial; width: 100%;">
  ${getProcessHeader(fatherProcess)}
    <tbody>
      <tr>
          <td>
            <h5 style="margin: 0; font-weight: bold; font-size: 20px; margin-bottom: 20px;">ANEXO ${
              index + 1
            } do documento ${fatherProcess.internal_id}</h5>
            <h5 style="margin: 0; font-weight: bold; font-size: 16px; margin-bottom: 6px;">Sobre o processo de origem</h5>
            <div style="display: flex; gap: 6px; margin-bottom: 6px;">
              <p style="margin: 0; font-size: 14px;">Nome: </p>
              <p style="margin: 0; font-size: 14px;">${fatherProcess.name}</p>
            </div>
            <div style="display: flex; gap: 6px; margin-bottom: 6px;">
              <p style="margin: 0; font-size: 14px;">Criação: </p>
              <p style="margin: 0; font-size: 14px;">${DateInBrazilianFormat(
                fatherProcess.created_at
              )}</p>
            </div>
            <div style="display: flex; gap: 6px; margin-bottom: 6px;">
              <p style="margin: 0; font-size: 14px;">Instituição: </p>
              <p style="margin: 0; font-size: 14px;">${
                fatherProcess.institution_name
              }</p>
            </div>
            <div style="display: flex; gap: 6px; margin-bottom: 6px;">
              <p style="margin: 0; font-size: 14px;">Unidade: </p>
              <p style="margin: 0; font-size: 14px;">${
                fatherProcess.created_at_unity_name
              }</p>
            </div>
            <div style="display: flex; gap: 6px; margin-bottom: 6px;">
              <p style="margin: 0; font-size: 14px;">Id interno: </p>
              <p style="margin: 0; font-size: 14px;">${
                fatherProcess.internal_id
              }</p>
            </div>
            <h5 style="margin: 0; font-weight: bold; font-size: 16px; margin-bottom: 6px; margin-top: 20px;">Sobre o anexo</h5>
            <div style="display: flex; gap: 6px; margin-bottom: 6px;">
              <p style="margin: 0; font-size: 14px;">Nome: </p>
              <p style="margin: 0; font-size: 14px;">${attachment.name}</p>
            </div>
            <div style="display: flex; gap: 6px; margin-bottom: 6px;">
            <p style="margin: 0; font-size: 14px;">Anexado em: </p>
            <p style="margin: 0; font-size: 14px;">${DateInBrazilianFormat(
              attachment.created_at
            )}</p>
          </div>
          <div style="display: flex; gap: 6px; margin-bottom: 6px;">
              <p style="margin: 0; font-size: 14px;">Id interno: </p>
              <p style="margin: 0; font-size: 14px;">${
                attachment.internal_id
              }</p>
            </div>
          </td>
      </tr>
    </tbody>
  </table>
  `;
};

const getAttachments = (attachments, fatherProcess) => {
  if (!attachments) return '';
  if (attachments.length === 0) return '';
  let pages = '';
  for (let i = 0; i < attachments.length; i++) {
    const htmlValue = getAttachment(attachments[i], fatherProcess, i);
    pages = pages.concat(htmlValue);
  }

  return `
  <div style="width: 100%;">
    ${pages}
  </div>
  `;
};

export default function getTemplateModel({
  fatherProcess,
  timelineItems,
  attachments,
}) {
  return `
  <div>
    <table style="page-break-after: always; font-family: Arial; width: 100%;">
      ${getProcessHeader(fatherProcess)}
      ${getProcessFooter()}
      <tbody>
        <tr>
            <td>
                ${getProcessContent(fatherProcess)}
                ${getTimelines(timelineItems)}
            </td>
        </tr>
      </tbody>
    </table>
    ${getAttachments(attachments, fatherProcess)}
    </div>
    `;
}
