import React, { useContext } from 'react';
// Libraries
import { Modal, message } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { LanguageContext } from '~/contexts/LanguageContext';
// Standalone Components
import { Container } from './style';
// Components
import Button from '~/components/button';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

const { confirm } = Modal;

export default function DeleteButton({ item, GetAvatarsAgain }) {
    const { theme } = useContext(ThemeContext);
    const { translate } = useContext(LanguageContext);

    async function DeletePhoto() {
        const splits = item.url.split('/');
        const fileName = splits[splits.length-1];
        const params = {
            operation: ['Profile', 'Avatar', 'Delete'],
            data: {
                path: fileName
            }
        };
        const response = await SocialMeAPI(params);
        if(response.success){
            GetAvatarsAgain();
            return message.success(translate.messages.photoSuccessfullyDeleted.text);
        }
    }

    function Confirm() {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: translate.messages.doYouReallyWantToDeleteThisPhoto.text,
            onOk() { DeletePhoto() },
            onCancel() { },
            okText: translate.basic.yes.text,
            cancelText: translate.basic.no.text
        });
    }

    return (
        <Container>
            <Button shape="circle" size="small" style={{ padding: 0 }} onClick={() => Confirm()}>
                <DeleteOutlined style={{ color: theme.color.danger, fontSize: '8pt' }} />
            </Button>
        </Container>
    )
}