import React from 'react';
// Libraries
import Animate, { FadeIn } from 'animate-css-styled-components';
// Standalone Components
import { Container, Content } from './style';
// Kinds
import SIGAM from './kinds/SIGAM';

export default function SidebarHeader() {
  return (
    <Container>
      <Animate Animation={[FadeIn]} duration={['0.8s']} delay={['0.2s']}>
        <Content>
          <SIGAM />
        </Content>
      </Animate>
    </Container>
  );
}
