import React, { useContext } from 'react';
//Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
//Views
import DesktopView from './view/Desktop';
import MobileView from './view/Mobile';

export default function SidebarDefault({ items }) {
  const { isMobile } = useContext(ResponsiveContext);
  return isMobile ? (
    <MobileView items={items} />
  ) : (
    <DesktopView items={items} />
  );
}
