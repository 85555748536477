import React, { useEffect, useState } from 'react';
// Own Components
import OrganizationalUnitsContainer from './components/OrganizationalUnitsContainer';
import GroupsContainer from './components/GroupsContainer';
// Components
import Avatar from '~/components/avatar';
import Loading from '~/components/loading';
import AdminButton from './components/AdminButton';
// Utils
import { DecodeURI } from '~/utils/Transformations';

export default function UserContainer({ propertys }) {
  const [selectId, setSelectId] = useState();
  const [loadingContent, setLoadingContent] = useState(true);

  useEffect(() => {
    if (!selectId) {
      return;
    }
    if (loadingContent) {
      setLoadingContent(false)
    }
  }, [loadingContent, selectId])

  useEffect(() => {
    async function Prepare(){
      if (propertys.record.person_id) {
        propertys.record.id = propertys.record.person_id;
        propertys.record.avatar = propertys.record.person_avatar;
        propertys.record.name = propertys.record.person_name;
        propertys.record.social_name = propertys.record.person_social_name;
      }
      if (propertys.record.id !== selectId) {
        if (!selectId) {
          return setSelectId(propertys.record.id);
        }
        setLoadingContent(true)
      } else {
        setSelectId(propertys.record.id);
      }
    }
    if (propertys) {
      Prepare();
    }
  }, [propertys, selectId])

  if (loadingContent) {
    return <Loading />
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '0px 0px 20px'
        }}
      >
        <Avatar src={DecodeURI(propertys.record.avatar || '')} size={120} />
        <OrganizationalUnitsContainer record={propertys.record} setUpdatePending={propertys.setUpdatePending} setRenderOrganizationalUnits={propertys.setRenderOrganizationalUnits} />
        {/* <GroupsContainer record={propertys.record} setUpdatePending={propertys.setUpdatePending} setRenderGroups={propertys.setRenderGroups} /> */}
        <AdminButton record={propertys.record} setUpdatePending={propertys.setUpdatePending} />
      </div>
    </div>
  );
}
