import React, { useState } from 'react';
import { Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { useArchivesPageManager } from '../../../../../../../../contexts/ArchivesPageManager/Provider';
import ArchiveUpdateForm from './components/Form';

export default function UpdateIconButton({ archive }) {
  const [loadingArchive, setLoadingArchive] = useState(false);
  const [completeArchive, setCompleteArchive] = useState();
  const [open, setOpen] = useState(false);

  const { ShowArchive } = useArchivesPageManager();

  function setMetatagsOnArchive(currentArchive) {
    let metas = [];

    for (const key in currentArchive) {
      const isMetadata = key.includes('meta');
      if (isMetadata && currentArchive[key] !== null) {
        metas.push(currentArchive[key]);
      }
    }

    currentArchive.metas = metas;

    return currentArchive;
  }

  const LoadArchiveToUpdate = async () => {
    setLoadingArchive(true);

    const response = await ShowArchive(archive.id);

    if (response.success) {
      const completeArchive = setMetatagsOnArchive(response.body.archive);

      if (completeArchive.org_unit_id) {
        completeArchive.is_from_organizational_unit = true;
      }

      if (completeArchive.person_id) {
        completeArchive.is_from_user = true;
      }

      //console.log('completeArchive', completeArchive);

      setCompleteArchive(completeArchive);
      setOpen(true);
    }

    setLoadingArchive(false);
  };

  return (
    <>
      {loadingArchive ? (
        <Spin />
      ) : (
        <EditOutlined onClick={LoadArchiveToUpdate} />
      )}

      {completeArchive && (
        <ArchiveUpdateForm
          archive={completeArchive}
          open={open}
          setOpen={setOpen}
        />
      )}
    </>
  );
}
