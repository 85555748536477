import React, { useEffect, useState } from 'react';
// Libraries
import { useParams } from 'react-router-dom';
import Animate, { FadeIn } from 'animate-css-styled-components';
// Own Components
import AddDocument from './components/AddDocument';
import AttachmentsList from './components/AttachmentsList';
// Components
import Title from '~/components/typography/title';
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Attachments({ process, propertys }) {
    const { institutionId, organizationUnitId } = useParams();
    const [attachments, setAttachments] = useState([]);
    const [loadingContent, setLoadingContent] = useState(true);

    let processInformation = process || propertys.process[0];

    async function GetAttachmentsAgain() {
        const params = {
            operation: ['SIGAM', 'Processes', 'Attachments', 'Get'],
            urlParams: {
                institutionId: institutionId,
                organizationUnitId: organizationUnitId,
                processId: processInformation.id
            }
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            setAttachments(response.body.documents.elements);
        }
    }

    useEffect(() => {
        async function GetAttachments() {
            const params = {
                operation: ['SIGAM', 'Processes', 'Attachments', 'Get'],
                urlParams: {
                    institutionId: institutionId,
                    organizationUnitId: organizationUnitId,
                    processId: processInformation.id
                }
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                setAttachments(response.body.documents.elements);
            }
            setLoadingContent(false);
        }
        GetAttachments();
    }, [processInformation, institutionId, organizationUnitId])

    return (
        <div>
            <Animate
                Animation={[FadeIn]}
                duration={['0.5s']}
                delay={['0.2s']}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                    <Title level={5}>Documentos Anexados</Title>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                    <AddDocument attachments={attachments} process={processInformation} GetAttachmentsAgain={GetAttachmentsAgain} />
                </div>
                {loadingContent ?
                    <Loading />
                    :
                    <AttachmentsList attachments={attachments} process={processInformation} GetAttachmentsAgain={GetAttachmentsAgain} />
                }
            </Animate>
        </div>
    )
}