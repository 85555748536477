import React, { useEffect, useState, useContext } from 'react';
// Libraries
import { useParams } from 'react-router-dom';
import { Tabs, Result, Affix } from 'antd';
// Contexts
import { PermissionContext } from '~/contexts/PermissionContext';
// Own Components
import Loading from '~/components/loading';
import SocialMeAPI from '~/utils/SocialMeAPI';
import Header from './components/Header';
import Content from './components/Content';
import Options from './components/Options';
// Components
// Utils

const { TabPane } = Tabs;

export default function Processes() {
  const { setUnitPermissions } = useContext(PermissionContext);
  const { institutionId, organizationUnitId } = useParams();
  const [loadingContent, setLoadingContent] = useState(true);
  const [searchInput, setSearchInput] = useState();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [selectedRow, setselectedRow] = useState([]);
  const [processes, setProcess] = useState();
  const [activeTab, setActiveTab] = useState('inbox');
  const [filters, setFilters] = useState({
    i_received: true,
    from_my_unity: undefined,
    my_unity_received: undefined,
    my_group_received: undefined,
    i_read: undefined,
    i_created: undefined,
    i_am_signatory: undefined,
    i_signed: undefined,
    i_am_interested: undefined,
    i_am_editor: undefined,
    privacy: undefined,
    status: undefined,
    name: undefined,
    subject: undefined,
    sort: {
      by: 'created_at',
      order: 'DESC',
    },
  });
  const [pageParameters, setPageParameters] = useState({
    currentPage: 1,
    totalElements: 0,
    limit: 10,
  });
  const [withoutPermission, setWithoutPermission] = useState(false);

  async function GetDataAgain(newPageParameters) {
    setLoadingContent(true);
    const params = {
      operation: ['SIGAM', 'Processes', 'Get'],
      urlParams: {
        institutionId,
        organizationUnitId,
      },
      data: {
        filter: {
          ...filters,
          searchString: searchInput,
          i_received: filters.i_received,
          from_my_unity: filters.from_my_unity,
          my_unity_received: filters.my_unity_received,
          my_group_received: filters.my_group_received,
          i_read: filters.i_read,
          i_created: filters.i_created,
          i_am_signatory: filters.i_am_signatory,
          i_signed: filters.i_signed === false ? false : undefined,
          i_am_interested: filters.i_am_interested,
          i_am_editor: filters.i_am_editor,
          privacy: filters.privacy,
          status: filters.status,
          name: filters.name,
          subject: filters.subject,
        },
        pagination: {
          limit: newPageParameters
            ? newPageParameters.limit
            : pageParameters.limit,
          page: newPageParameters
            ? newPageParameters.currentPage
            : pageParameters.currentPage,
          sort: {
            by: filters.sort.by,
            order: filters.sort.order,
          },
        },
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      setPageParameters({
        ...pageParameters,
        currentPage: response.body.processes.currentPage,
        totalElements: response.body.processes.totalElements,
        limit: response.body.processes.limit,
      });
      setProcess(response.body.processes.elements);
      setLoadingContent(false);
    }
  }

  useEffect(() => {
    async function GetPermissions() {
      const params = {
        operation: ['SIGAM', 'OrganizationalUnits', 'Permissions', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setUnitPermissions(response.body.permission);
      }
    }
    GetPermissions();
  }, [institutionId, organizationUnitId, setUnitPermissions]);

  useEffect(() => {
    async function GetProcesses() {
      setLoadingContent(true);
      const params = {
        operation: ['SIGAM', 'Processes', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
        },
        data: {
          filter: {
            ...filters,
            searchString: searchInput,
            i_received: filters.i_received,
            from_my_unity: filters.from_my_unity,
            my_unity_received: filters.my_unity_received,
            my_group_received: filters.my_group_received,
            i_read: filters.i_read,
            i_created: filters.i_created,
            i_am_signatory: filters.i_am_signatory,
            i_signed: filters.i_signed === false ? false : undefined,
            i_am_interested: filters.i_am_interested,
            i_am_editor: filters.i_am_editor,
            privacy: filters.privacy,
            status: filters.status,
            name: filters.name,
            subject: filters.subject,
          },
          pagination: {
            limit: 10,
            page: 1,
            sort: {
              by: filters.sort.by,
              order: filters.sort.order,
            },
          },
        },
      };
      // console.log(params.data.filter)
      const response = await SocialMeAPI(params);
      // console.log(response)
      if (response.success) {
        setPageParameters({
          currentPage: response.body.processes.currentPage,
          totalElements: response.body.processes.totalElements,
          limit: response.body.processes.limit,
        });
        setProcess(response.body.processes.elements);
        if (searchInput) {
          setLoadingSearch(false);
        }
      } else if (
        response.message ===
        "You don't have the permission needed to perform such action"
      ) {
        setWithoutPermission(true);
      }
      setLoadingContent(false);
    }
    if (searchInput) {
      setLoadingSearch(true);
    }
    GetProcesses();
  }, [searchInput, institutionId, organizationUnitId, filters]);

  async function ChangeTab(key) {
    setActiveTab(key);
    switch (key) {
      case 'inbox':
        return setFilters({
          ...filters,
          i_received: true,
          from_my_unity: undefined,
          my_unity_received: undefined,
          my_group_received: undefined,
          privacy: undefined,
          i_read: undefined,
          i_created: undefined,
          i_am_signatory: undefined,
          i_signed: undefined,
          i_am_interested: undefined,
          status: undefined,
          i_am_editor: undefined,
        });
      case 'pendingSignature':
        return setFilters({
          ...filters,
          i_received: undefined,
          from_my_unity: undefined,
          my_unity_received: undefined,
          my_group_received: undefined,
          privacy: undefined,
          i_read: undefined,
          i_created: undefined,
          i_am_signatory: true,
          i_signed: false,
          i_am_interested: undefined,
          status: undefined,
          i_am_editor: undefined,
        });
      case 'ofMyInterest':
        return setFilters({
          ...filters,
          i_received: undefined,
          from_my_unity: undefined,
          my_unity_received: undefined,
          my_group_received: undefined,
          privacy: undefined,
          i_read: undefined,
          i_created: undefined,
          i_am_signatory: undefined,
          i_signed: undefined,
          i_am_interested: true,
          status: undefined,
          i_am_editor: undefined,
        });
      case 'myProcess':
        return setFilters({
          ...filters,
          i_received: undefined,
          from_my_unity: undefined,
          my_unity_received: undefined,
          my_group_received: undefined,
          privacy: undefined,
          i_read: undefined,
          i_created: true,
          i_am_signatory: undefined,
          i_signed: undefined,
          i_am_interested: undefined,
          i_am_editor: undefined,
          status: 'ACTIVE',
        });
      case 'sharedWithMe':
        return setFilters({
          ...filters,
          i_received: undefined,
          from_my_unity: undefined,
          my_unity_received: undefined,
          my_group_received: undefined,
          privacy: undefined,
          i_read: undefined,
          i_created: undefined,
          i_am_signatory: undefined,
          i_signed: undefined,
          i_am_interested: undefined,
          i_am_editor: true,
          status: 'ACTIVE',
        });
      case 'unitProcessesReceveid':
      default:
        return setFilters({
          ...filters,
          i_received: undefined,
          from_my_unity: undefined,
          my_unity_received: true,
          my_group_received: undefined,
          privacy: undefined,
          i_read: undefined,
          i_created: undefined,
          i_am_signatory: undefined,
          i_signed: undefined,
          i_am_interested: undefined,
          i_am_editor: undefined,
          status: undefined,
        });
      case 'unitProcesses':
        return setFilters({
          ...filters,
          i_received: undefined,
          from_my_unity: true,
          my_unity_received: undefined,
          my_group_received: undefined,
          privacy: undefined,
          i_read: undefined,
          i_created: undefined,
          i_am_signatory: undefined,
          i_signed: undefined,
          i_am_interested: undefined,
          i_am_editor: undefined,
          status: undefined,
        });
      /*
    case 'groupsProcesses':
      return setFilters({
        ...filters,
        i_received: undefined,
        my_unity_received: undefined,
        my_group_received: true,
        i_read: undefined,
        i_created: undefined,
        i_am_signatory: undefined,
        i_signed: undefined,
        i_am_interested: undefined,
        status: undefined
      });
      
    case 'allProcesses':
      return setFilters({
        ...filters,
        i_received: undefined,
        my_unity_received: undefined,
        my_group_received: undefined,
        i_read: undefined,
        i_created: undefined,
        i_am_signatory: undefined,
        i_signed: undefined,
        i_am_interested: undefined,
        status: undefined
      });
      */
      case 'filedProcesses':
        return setFilters({
          ...filters,
          i_received: undefined,
          from_my_unity: undefined,
          my_unity_received: undefined,
          my_group_received: undefined,
          privacy: undefined,
          i_read: undefined,
          i_created: undefined,
          i_am_signatory: undefined,
          i_signed: undefined,
          i_am_interested: undefined,
          i_am_editor: undefined,
          status: 'INACTIVE',
        });

      case 'privateProcesses':
        return setFilters({
          ...filters,
          i_received: undefined,
          from_my_unity: true,
          my_unity_received: undefined,
          my_group_received: undefined,
          privacy: ['RESTRICTED', 'CONFIDENTIAL'],
          i_read: undefined,
          i_created: undefined,
          i_am_signatory: undefined,
          i_signed: undefined,
          i_am_interested: undefined,
          i_am_editor: undefined,
          status: undefined,
        });
    }
  }

  if (withoutPermission) {
    return (
      <div
        style={{
          height: 'calc(100vh - 80px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Result
          status="warning"
          title="Você não tem permissão para acessar os processos da unidade"
        />
      </div>
    );
  }

  return (
    <div style={{ overflow: 'auto' }}>
      <div id="header">
        <Header
          GetDataAgain={GetDataAgain}
          setSearchInput={setSearchInput}
          loadingSearch={loadingSearch}
          activeTab={activeTab}
          filters={filters}
          setFilters={setFilters}
        />

        <Options selectedRow={selectedRow} GetDataAgain={GetDataAgain} />
      </div>
      {loadingContent ? (
        <Loading text="Carregando Processos" />
      ) : (
        <Tabs defaultActiveKey={activeTab} onChange={ChangeTab}>
          <TabPane tab="Minha Caixa de Entrada" key="inbox">
            <Content
              activeTab={activeTab}
              data={processes}
              pageParameters={pageParameters}
              GetDataAgain={GetDataAgain}
              setselectedRow={setselectedRow}
            />
          </TabPane>
          <TabPane tab="Meus Processos" key="myProcess">
            <Content
              activeTab={activeTab}
              data={processes}
              pageParameters={pageParameters}
              GetDataAgain={GetDataAgain}
              setselectedRow={setselectedRow}
            />
          </TabPane>
          <TabPane tab="Compartilhados comigo" key="sharedWithMe">
            <Content
              activeTab={activeTab}
              data={processes}
              pageParameters={pageParameters}
              GetDataAgain={GetDataAgain}
              setselectedRow={setselectedRow}
            />
          </TabPane>
          <TabPane tab="Pendentes de Assinatura" key="pendingSignature">
            <Content
              activeTab={activeTab}
              data={processes}
              pageParameters={pageParameters}
              GetDataAgain={GetDataAgain}
              setselectedRow={setselectedRow}
            />
          </TabPane>
          <TabPane tab="Do meu Interesse" key="ofMyInterest">
            <Content
              activeTab={activeTab}
              data={processes}
              pageParameters={pageParameters}
              GetDataAgain={GetDataAgain}
              setselectedRow={setselectedRow}
            />
          </TabPane>
          <TabPane tab="Processos Privados" key="privateProcesses">
            <Content
              activeTab={activeTab}
              data={processes}
              pageParameters={pageParameters}
              GetDataAgain={GetDataAgain}
              setselectedRow={setselectedRow}
            />
          </TabPane>
          <TabPane
            tab="Caixa de Entrada da Unidade"
            key="unitProcessesReceveid"
          >
            <Content
              activeTab={activeTab}
              data={processes}
              pageParameters={pageParameters}
              GetDataAgain={GetDataAgain}
              setselectedRow={setselectedRow}
            />
          </TabPane>
          <TabPane tab="Processos da Unidade" key="unitProcesses">
            <Content
              activeTab={activeTab}
              data={processes}
              pageParameters={pageParameters}
              GetDataAgain={GetDataAgain}
              setselectedRow={setselectedRow}
            />
          </TabPane>
          {/* 
          <TabPane tab="Processos dos Grupos" key="groupsProcesses">
            <Content
              activeTab={activeTab}
              data={processes}
              pageParameters={pageParameters}
              GetDataAgain={GetDataAgain}
              setselectedRow={setselectedRow}
            />
          </TabPane>
          */}
          {/* 
          <TabPane tab="Todos os Processos" key="allProcesses">
            <Content
              activeTab={activeTab}
              data={processes}
              pageParameters={pageParameters}
              GetDataAgain={GetDataAgain}
              setselectedRow={setselectedRow}
            />
          </TabPane>
          */}
          <TabPane tab="Processos Arquivados" key="filedProcesses">
            <Content
              activeTab={activeTab}
              data={processes}
              pageParameters={pageParameters}
              GetDataAgain={GetDataAgain}
              setselectedRow={setselectedRow}
            />
          </TabPane>
        </Tabs>
      )}
    </div>
  );
}
