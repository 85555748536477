import React from 'react';
// Libraries
import { List, Divider, Avatar, Button, Typography, Tag } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import Animate, { FadeIn } from 'animate-css-styled-components';
// Utils
import { CapitalizeString, DecodeURI } from '~/utils/Transformations';
import { cpfMask, cnpjMask } from '~/utils/Masks';

const { Text } = Typography;

export default function Result({
  peopleList,
  setPeopleList,
  setSelectedPerson,
}) {
  function renderDescription(item) {
    if (item.cpf_number) {
      return cpfMask(item.cpf_number);
    }
    if (item.cnpj_number) {
      return cnpjMask(item.cnpj_number);
    }
    return '';
  }

  return (
    <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
      <div style={{ padding: '10px', minHeight: 'calc(100vh - 55px)' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button type="dashed" onClick={() => setPeopleList()}>
            Fazer outra busca
          </Button>
        </div>
        <Divider />
        <List
          grid={{ gutter: 18, column: 1 }}
          itemLayout="horizontal"
          dataSource={peopleList}
          locale={{ emptyText: 'Nenhuma pessoa encontrada' }}
          renderItem={(item, index) => (
            <div
              key={index}
              style={{
                border: '1px solid #f1f3f4',
                padding: '5px',
                borderRadius: '8px',
                marginBottom: '8px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  gap: 8,
                }}
              >
                <Avatar src={DecodeURI(item.avatar || '')} />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <Text strong>{CapitalizeString(item.name)}</Text>
                  <Text type="secondary">{renderDescription(item)}</Text>
                </div>
              </div>
              {item?.institutions?.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    gap: 4,
                    flexDirection: 'column',
                    margin: '5px 0px',
                  }}
                >
                  {item.institutions.map((item, index) => (
                    <Tag key={index} color="blue">
                      {item.name}
                    </Tag>
                  ))}
                </div>
              )}
              <div style={{ margin: '5px 0px' }}>
                <Button
                  icon={<UserAddOutlined />}
                  onClick={() => setSelectedPerson(item)}
                  style={{ width: '100%' }}
                  type="dashed"
                >
                  Selecionar
                </Button>
              </div>
            </div>
          )}
        />
      </div>
    </Animate>
  );
}

/* 

<List.Item
              actions={[
                <div style={{ marginBottom: 10 }}>
                 
                </div>,
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar src={DecodeURI(item.avatar || '')} />}
                title={CapitalizeString(item.social_name || item.name)}
                description={renderDescription(item)}
              />
            </List.Item>

*/
