import React from 'react';
// Libraries
import { Row, Col } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
// Own Components
import Visualize from '../../../../../../Documents/components/Actions/Visualize';
// Components
import Card from '~/components/card';
import Drawer from '~/components/drawer';
import Button from '~/components/button';
import Text from '~/components/typography/text';
// Utils
import { CapitalizeString } from '~/utils/Transformations';

export default function DocumentCard({ document }) {
    return (
        <Card bodyStyle={{ padding: 5 }}>
        <Row style={{ display: 'flex', alignItems: 'center' }}>
            <Col span={16} style={{ display: 'flex', alignItems: 'center' }}>
                <Text>{CapitalizeString(document.name)}</Text>
            </Col>
            <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Drawer
                    Trigger={
                        <Button icon={<EyeOutlined />}>
                            Visualizar
                        </Button>
                    }
                    DrawerContentComponent={Visualize}
                    DrawerContentComponentParams={{ document: [document] }}
                    title={`${document.name}`}
                    width="100%"
                />
            </Col>
        </Row>
    </Card>
    )
}