import React from 'react';
import Header from './components/Header';
import Content from './components/Content';

export default function Archives() {
  return (
    <div>
      <Header />
      <Content />
    </div>
  );
}
