import React, { useState } from 'react';
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { useArchiveFilesPageManager } from '../../../../../../contexts/ArchiveFilesPageManager/Provider';

export default function DeleteFileButton({ file }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { DeleteFile } = useArchiveFilesPageManager();

  async function HandleDeleteFile() {
    setLoading(true);
    await DeleteFile(file.id);
    setLoading(false);
  }

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Popconfirm
      title="Você tem certeza que desejar deletar este arquivo?"
      open={open}
      onConfirm={HandleDeleteFile}
      onCancel={onClose}
      okText="Sim, tenho certeza"
      okButtonProps={{
        loading,
      }}
      cancelText="Não, cancelar"
    >
      <Button onClick={onOpen} type="dashed" danger icon={<DeleteOutlined />}>
        Deletar
      </Button>
    </Popconfirm>
  );
}
