import React from 'react';
// Components
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';

export default function Terms() {
  return (
    <div>
      <Title style={{ fontSize: '12pt' }} level={4}>
        TERMO DE DECLARAÇÃO DE CONCORDÂNCIA E VERACIDADE CADASTRO DE USUÁRIO NO SISTEMA INTELIGENTE DE GESTÃO DA ADMINISTRAÇÃO - SIGAM
      </Title>
      <Text style={{ fontSize: '9pt' }}>
        A realização do “registro” como USUÁRIO do SIGAM e a aceitação deste Termo de Concordância e Veracidade importará na aceitação das condições que regem o processo eletrônico, especialmente a LGPD e demais normas aplicáveis, bem como admitindo como válida a assinatura eletrônica na modalidade cadastrada (login e senha). Todas as ações efetuadas como usuário do sistema eletrônico são de sua total e inteira responsabilidade, as quais são passíveis de apuração civil, penal e administrativa.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Os dados fornecidos no cadastro do usuário têm a finalidade de validar as informações, assegurando a veracidade das mesmas.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        Declaro que são de minha exclusiva responsabilidade:
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        I - A veracidade e a integridade das informações e documentos enviados ao SIGAM;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        II - o sigilo da senha de acesso ao SIGAM, não sendo cabível, em hipótese alguma, a alegação de uso indevido;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        III - A protocolização de documentos digitais em conformidade com os requisitos estabelecidos pelo Sistema, no que se refere ao formato (PDF) e ao tamanho (até 5 MB) dos arquivos transmitidos eletronicamente;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        IV - A conservação dos originais em papel de documentos digitalizados enviados por meio do Protocolo Eletrônico até que decaia o direito da SIGAM de rever os atos praticados no processo, para que, caso solicitado, sejam apresentados à SIGAM para qualquer tipo de conferência;
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        V - As condições da minha rede de comunicação, o acesso ao meu provedor de internet e as configurações do computador utilizado nas transmissões eletrônicas.
      </Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>O USUÁRIO se compromete a utilizar o SIGAM e todas as suas ferramentas de acordo com a legislação vigente, a moral, a ordem pública, as condições gerais de uso e as condições particulares que, conforme o caso, sejam aplicáveis.</Text>
      <br />
      <Text style={{ fontSize: '9pt' }}>
        O USUÁRIO compromete-se, igualmente, a fazer um uso adequado das ferramentas do SIGAM e a não empregá-las para realizar atividades: a) ilícitas; b) que constituam crime ou contravenção; c) que atentem contra direitos de terceiros e/ou que infrinjam a legislação sobre propriedade intelectual e industrial, ou quaisquer outras normas do atual ordenamento jurídico brasileiro.
      </Text>
      <br />
    </div>
  );
}
