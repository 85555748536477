import React, { useState } from 'react';

import FileItem from './components/Item';
import FileForm from './components/Form';

export default function FileContent({ file }) {
  const [fileStatus, setFileStatus] = useState('processing');
  const [fileUrl, setFileUrl] = useState('');

  if (fileStatus === 'processing') {
    return (
      <FileItem
        file={file}
        setFileUrl={setFileUrl}
        fileStatus={fileStatus}
        setFileStatus={setFileStatus}
      />
    );
  }

  if (fileStatus === 'creation') {
    return (
      <FileForm
        file={file}
        fileUrl={fileUrl}
        setFileUrl={setFileUrl}
        setFileStatus={setFileStatus}
      />
    );
  }

  return null;
}
