import React, { memo } from 'react';

import { MainPopover } from './style';

function Popover({ style, content, children, title }) {
  return (
    <MainPopover style={style} content={content} title={title}>
      {children}
    </MainPopover>
  );
}

export default memo(Popover);