import React, { useState } from 'react';
import { Button } from 'antd';

import { useArchivesPageManager } from '../../../../../../../../contexts/ArchivesPageManager/Provider';
import DetailsContent from './components/Details';

export default function DetailsButton({ archive }) {
  const [loadingArchive, setLoadingArchive] = useState(false);
  const [completeArchive, setCompleteArchive] = useState();
  const [open, setOpen] = useState(false);

  const { ShowArchive } = useArchivesPageManager();

  function setMetatagsOnArchive(currentArchive) {
    let metas = [];

    for (const key in currentArchive) {
      const isMetadata = key.includes('meta');
      if (isMetadata && currentArchive[key] !== null) {
        metas.push(currentArchive[key]);
      }
    }

    currentArchive.metas = metas;

    return currentArchive;
  }

  const LoadArchiveToUpdate = async () => {
    setLoadingArchive(true);

    const response = await ShowArchive(archive.id);

    if (response.success) {
      const completeArchive = setMetatagsOnArchive(response.body.archive);

      setCompleteArchive(completeArchive);
      setOpen(true);
    }

    setLoadingArchive(false);
  };

  return (
    <>
      <Button loading={loadingArchive} onClick={LoadArchiveToUpdate}>
        Detalhes
      </Button>

      {completeArchive && (
        <DetailsContent
          archive={completeArchive}
          open={open}
          setOpen={setOpen}
        />
      )}
    </>
  );
}
