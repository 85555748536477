import React, { useContext } from 'react';
//Contexts
import { SidebarContext } from '~/contexts/SidebarContext';
//Components
import Sidebar from './../../components/Sidebar';
import Drawer from '~/components/drawer';

export default function SidebarDefault({ items }) {
  const { mobileCollapsed, ToggleCollapsed } = useContext(SidebarContext);
  return (
    <Drawer
      placement={'left'}
      onClose={() => ToggleCollapsed()}
      visible={mobileCollapsed}
      keyboard={true}
      closable={false}
      widthMobile={215}
      bodyStyle={{ padding: 0}}
    >
      <Sidebar items={items} />
    </Drawer>
  );
}
