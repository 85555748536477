import styled from 'styled-components';

export const HStack = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.div`
  margin-bottom: 10px;
  max-width: 35%;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 8px;
  background-color: white;
  border: 1px solid #c3c3c3;

  &.right {
    margin-left: auto;
  }

  &.left {
    margin-right: auto;
  }

  @media (max-width: 608px) {
    max-width: 85%;
  }
`;
