import React, { useContext, useEffect, useState } from 'react';
// Contexts
import { SIGAMContext } from '~/contexts/SIGAMContext';
// Components
import Loading from '~/components/loading';
import { TagsCell } from '~/components/table/cells';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function OrganizationalUnitsCell({ id, activeId, setRenderOrganizationalUnits, renderOrganizationalUnits }) {
  const { activeInstitution } = useContext(SIGAMContext);
  const [loadingContent, setLoadingContent] = useState(true);
  const [organizationalUnits, setOrganizationalUnits] = useState([]);

  useEffect(() => {
    async function GetOrganizationalUnits() {
      setLoadingContent(true);
      const params = {
        operation: ['SIGAM', 'Users', 'GetOrganizationalUnits'],
        urlParams: {
          institutionId: activeInstitution.id,
          personId: id,
        },
        data: {
          pagination: {
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        let array = response.body.orgUnities.elements;
        let newArray = []
        for (let i = 0; i < array.length; i++) {
          let newItem = {}
          newItem.text = array[i].name;
          if (array[i].settings && array[i].settings.color) {
            newItem.color = array[i].settings.color
          }
          newArray.push(newItem)
        }
        setOrganizationalUnits(newArray);
        setLoadingContent(false);
      }
    }
    if (renderOrganizationalUnits) {
      if (activeId === id) {
        GetOrganizationalUnits();
        setRenderOrganizationalUnits(false);
      }
    }
  }, [activeInstitution, id, activeId, setRenderOrganizationalUnits, renderOrganizationalUnits])

  useEffect(() => {
    async function GetOrganizationalUnits() {
      const params = {
        operation: ['SIGAM', 'Users', 'GetOrganizationalUnits'],
        urlParams: {
          institutionId: activeInstitution.id,
          personId: id,
        },
        data: {
          pagination: {
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        if (response.body.orgUnities.elements.length !== 0) {
          let array = response.body.orgUnities.elements;
          let newArray = []
          for (let i = 0; i < array.length; i++) {
            let newItem = {}
            newItem.text = array[i].name;
            if (array[i].settings && array[i].settings.color) {
              newItem.color = array[i].settings.color
            }
            newArray.push(newItem)
          }
          setOrganizationalUnits(newArray);
        }
        setLoadingContent(false);
      }
    }
    GetOrganizationalUnits();
  }, [activeInstitution, id]);

  if (loadingContent) {
    return <Loading height="0px" withoutText />;
  }

  return <TagsCell id="organizationalUnitsCellAdmin" tags={organizationalUnits} maxWidth={'250px'} />
}
