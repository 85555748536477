import React, { useEffect, useState } from 'react';
// Libraries
import { Tree, Badge } from 'antd';
import { useParams } from 'react-router-dom';
import {
  DownOutlined
} from '@ant-design/icons';
// Components
import Loading from '~/components/loading';
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function FilesList({
  process,
  setSelectedFile,
}) {
  const { institutionId, organizationUnitId } = useParams();
  const [treeData, setTreeData] = useState();
  const [loadingContent, setLoadingContent] = useState(true);

  useEffect(() => {
    async function GetAttachmentsDocument(document) {
      const params = {
        operation: ['SIGAM', 'Documents', 'Attachments', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: document.id,
        },
        data: {
          pagination: {
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        return response.body.attachments.elements;
      }
    }
    async function GetAttachments() {
      const params = {
        operation: ['SIGAM', 'Processes', 'Attachments', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
          processId: process.id,
        },
        data: {
          pagination: {
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      // console.log(response)
      if (response.success) {
        const tree = [];
        const father = {
          title: process.name,
          key: '1',
          type: 'process',
          data: process,
          children: [],
        };
        const array = response.body.documents.elements;
        for (let i = 0; i < array.length; i++) {
          const newItem = {
            title: <Text><Badge color={array[i].status === 'INACTIVE' ? 'red' : 'green'} style={{ marginRight: '2px' }} />{array[i].name}</Text>,
            key: `1-${i + 1}`,
            type: 'document',
            data: array[i],
            children: [],
          };
          const attachmentsDocument = await GetAttachmentsDocument(array[i]);
          for (let j = 0; j < attachmentsDocument.length; j++) {
            const newSubItem = {
              title: <Text><Badge color={attachmentsDocument[j].status === 'INACTIVE' ? 'red' : 'green'} style={{ marginRight: '2px' }} />{attachmentsDocument[j].name}</Text>,
              key: `1-${i + 1}-${j + 1}`,
              type: 'attachment',
              data: attachmentsDocument[j],
            };
            newItem.children.push(newSubItem);
          }
          father.children.push(newItem);
        }
        tree.push(father);
        setTreeData(tree);
      }
      setLoadingContent(false);
    }
    GetAttachments();
  }, [institutionId, organizationUnitId, process]);

  return (
    <div>
      <Title style={{ textAlign: 'center' }} level={5}>
        Anexos
      </Title>
      {loadingContent ? (
        <Loading />
      ) : (
        <Tree
          onSelect={(key, info) => setSelectedFile(info)}
          showIcon
          defaultExpandAll
          defaultSelectedKeys={['1']}
          switcherIcon={<DownOutlined />}
          treeData={treeData}
        />
      )}
    </div>
  );
}
