import API from '~/environments/APIs';

export default {
  GetInstitutions: {
    baseURL: API.socialMe,
    url: '/SIGAM/institutions/person',
    method: 'post',
    timeout: '50000',
  },
  GetPermissions: {
    baseURL: API.socialMe,
    url: '/SIGAM/institutionId/permissions',
    method: 'post',
    timeout: '50000',
  },
  Consult: {
    Document: {
      baseURL: API.socialMe,
      url: '/SIGAM/consult/document',
      method: 'post',
      timeout: '50000',
    },
  },
  Person: {
    Search: {
      baseURL: API.socialMe,
      // url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/people',
      url: '/SIGAM/institutionId/people/all',
      method: 'post',
      timeout: '50000',
    },
    SearchSocialMe: {
      baseURL: API.socialMe,
      // url: '/SIGAM/institutionId/people/all',
      url: '/SIGAM/institutionId/people',
      method: 'post',
      timeout: '50000',
    },
    SearchInSigam: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/people/all-with-inst',
      method: 'post',
      timeout: '50000',
    },
  },
  Citizen: {
    GetInstitutions: {
      baseURL: API.socialMe,
      //url: '/SIGAM/public/institutions',
      url: '/SIGAM/citizen/institutions',
      method: 'post',
      timeout: '50000',
    },
    Processes: {
      Get: {
        baseURL: API.socialMe,
        url: '/SIGAM/citizen/processes',
        method: 'post',
        timeout: '50000',
      },
      Show: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId',
        method: 'get',
        timeout: '50000',
      },
      Edit: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId',
        method: 'put',
        timeout: '50000',
      },
      Add: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process',
        method: 'post',
        timeout: '50000',
      },
      Proceed: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/send',
        method: 'post',
        timeout: '50000',
      },
      GetHistory: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/timeline',
        method: 'post',
        timeout: '50000',
      },
    },
  },
  Processes: {
    HavePermission: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/access_level',
      method: 'get',
      timeout: '50000',
    },
    Get: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/processes',
      method: 'post',
      timeout: '50000',
    },
    Show: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId',
      method: 'get',
      timeout: '50000',
    },
    Edit: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId',
      method: 'patch',
      timeout: '50000',
    },
    Finish: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId',
      method: 'put',
      timeout: '50000',
    },
    Delete: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId',
      method: 'delete',
      timeout: '50000',
    },
    Add: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process',
      method: 'post',
      timeout: '50000',
    },
    Proceed: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/send',
      method: 'post',
      timeout: '50000',
    },
    GetHistory: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/timeline',
      method: 'post',
      timeout: '50000',
    },
    OrganizationalUnits: {
      Get: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/get-from-org-unit',
        method: 'get',
        timeout: '50000',
      },
      Delete: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/let-go-from-org-unit',
        method: 'delete',
        timeout: '50000',
      },
      Groups: {
        Get: {
          baseURL: API.socialMe,
          url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/get-from-org-unit/groupId',
          method: 'get',
          timeout: '50000',
        },
        Delete: {
          baseURL: API.socialMe,
          url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/let-go-from-group/groupId',
          method: 'delete',
          timeout: '50000',
        },
      },
    },
    Attachments: {
      Get: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/documents',
        method: 'post',
        timeout: '50000',
      },
      Add: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/document/documentId',
        method: 'post',
        timeout: '50000',
      },
      Delete: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/document/documentId',
        method: 'put',
        timeout: '50000',
      },
      RealDelete: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/document/documentId',
        method: 'delete',
        timeout: '50000',
      },
    },
    PrivateAccess: {
      Get: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/private-access',
        method: 'post',
        timeout: '50000',
      },
      Add: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/assign/personId/private-access',
        method: 'post',
        timeout: '50000',
      },
      Delete: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/assign/personId/private-access',
        method: 'delete',
        timeout: '50000',
      },
    },
    Interested: {
      Get: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/interested',
        method: 'post',
        timeout: '50000',
      },
      Add: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/assign/personId/interested',
        method: 'post',
        timeout: '50000',
      },
      Delete: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/assign/personId/interested',
        method: 'delete',
        timeout: '50000',
      },
    },
    Contributors: {
      Get: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/co-owners',
        method: 'post',
        timeout: '50000',
      },
      Add: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/co-owner/personId',
        method: 'post',
        timeout: '50000',
      },
      Delete: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/co-owner/personId',
        method: 'delete',
        timeout: '50000',
      },
    },
    Signatories: {
      Get: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/signatories',
        method: 'post',
        timeout: '50000',
      },
      Add: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/assign/personId/signatory',
        method: 'post',
        timeout: '50000',
      },
      Delete: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/assign/personId/signatory',
        method: 'delete',
        timeout: '50000',
      },
    },
    Subscriptions: {
      ByPassword: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/sign/login-password',
        method: 'post',
        timeout: '50000',
      },
      Delete: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/process/processId/unsign/subscriptionId',
        method: 'delete',
        timeout: '50000',
      },
    },
  },
  Documents: {
    HavePermission: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId/access_level',
      method: 'get',
      timeout: '50000',
    },
    Get: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/documents',
      method: 'post',
      timeout: '50000',
    },
    Show: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId',
      method: 'get',
      timeout: '50000',
    },
    Add: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document',
      method: 'post',
      timeout: '50000',
    },
    Edit: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId',
      method: 'put',
      timeout: '50000',
    },
    Delete: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId',
      method: 'delete',
      timeout: '50000',
    },
    UploadFile: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/file',
      method: 'post',
      timeout: '50000',
    },
    GetHistory: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId/timeline',
      method: 'post',
      timeout: '50000',
    },
    PrivateAccess: {
      Get: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId/private-access',
        method: 'post',
        timeout: '50000',
      },
      Add: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId/assign/personId/private-access',
        method: 'post',
        timeout: '50000',
      },
      Delete: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId/assign/personId/private-access',
        method: 'delete',
        timeout: '50000',
      },
    },
    Signatories: {
      Get: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId/signatories',
        method: 'post',
        timeout: '50000',
      },
      Add: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId/assign/personId/signatory',
        method: 'post',
        timeout: '50000',
      },
      Delete: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId/assign/personId/signatory',
        method: 'delete',
        timeout: '50000',
      },
    },
    Subscriptions: {
      ByPassword: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId/sign/login-password',
        method: 'post',
        timeout: '50000',
      },
      Delete: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId/unsign/subscriptionId',
        method: 'delete',
        timeout: '50000',
      },
    },
    Interested: {
      Get: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId/interested',
        method: 'post',
        timeout: '50000',
      },
      Add: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId/assign/personId/interested',
        method: 'post',
        timeout: '50000',
      },
      Delete: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId/assign/personId/interested',
        method: 'delete',
        timeout: '50000',
      },
    },
    Contributors: {
      Get: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId/co-owners',
        method: 'post',
        timeout: '50000',
      },
      Add: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId/co-owner/personId',
        method: 'post',
        timeout: '50000',
      },
      Delete: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId/co-owner/personId',
        method: 'delete',
        timeout: '50000',
      },
    },
    Attachments: {
      Get: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId/attachments',
        method: 'post',
        timeout: '50000',
      },
      Add: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId/attachment',
        method: 'post',
        timeout: '50000',
      },
      Edit: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/document/documentId/attachment/attachmentId',
        method: 'put',
        timeout: '50000',
      },
    },
  },
  Institution: {
    Show: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId',
      method: 'get',
      timeout: '50000',
    },
    Search: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/search',
      method: 'post',
      timeout: '50000',
    },
    Update: {
      baseURL: API.socialMe,
      url: '/SIGAM/institution/institutionId',
      method: 'put',
      timeout: '50000',
    },
  },
  OrganizationalUnits: {
    Get: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unities/person',
      method: 'post',
      timeout: '50000',
    },
    GetAll: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unities',
      method: 'post',
      timeout: '50000',
    },
    Add: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unity',
      method: 'post',
      timeout: '50000',
    },
    Edit: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unity/organizationUnitId',
      method: 'put',
      timeout: '50000',
    },
    Permissions: {
      Get: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/permission/organizational-unity/organizationUnitId',
        method: 'post',
        timeout: '50000',
      },
      Edit: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/permission/organizational-unity/organizationUnitId',
        method: 'put',
        timeout: '50000',
      },
    },
    Users: {
      Get: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/public-agents',
        method: 'post',
        timeout: '50000',
      },
      Add: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/public-agent',
        method: 'post',
        timeout: '50000',
      },
      Delete: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/organizational-unity/organizationUnitId/public-agent',
        method: 'delete',
        timeout: '50000',
      },
    },
  },
  Groups: {
    Get: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/groups/person',
      method: 'post',
      timeout: '50000',
    },
    GetAll: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/groups',
      method: 'post',
      timeout: '50000',
    },
    Add: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/group',
      method: 'post',
      timeout: '50000',
    },
    Edit: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/group/groupId',
      method: 'put',
      timeout: '50000',
    },
    Permissions: {
      Get: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/permission/group/groupId',
        method: 'post',
        timeout: '50000',
      },
      Edit: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/permission/group/groupId',
        method: 'put',
        timeout: '50000',
      },
    },
    Users: {
      Get: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/group/groupId/grouped-people',
        method: 'post',
        timeout: '50000',
      },
      Add: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/group/groupId/grouped-person',
        method: 'post',
        timeout: '50000',
      },
      Delete: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/group/groupId/grouped-person',
        method: 'delete',
        timeout: '50000',
      },
    },
  },
  List: {
    Permissions: {
      baseURL: API.socialMe,
      url: '/SIGAM/system/permissions/available',
      method: 'post',
      timeout: '50000',
    },
  },
  DocumentTemplates: {
    Get: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/document/models',
      method: 'post',
      timeout: '50000',
    },
    Show: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/document/model/modelId',
      method: 'get',
      timeout: '50000',
    },
    Add: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/document/model',
      method: 'post',
      timeout: '50000',
    },
    Edit: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/document/model/modelId',
      method: 'put',
      timeout: '50000',
    },
    Delete: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/document/model/modelId',
      method: 'delete',
      timeout: '50000',
    },
  },
  TypesOfProcesses: {
    Get: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/process/kinds',
      method: 'post',
      timeout: '50000',
    },
    Show: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/document/model/modelId',
      method: 'get',
      timeout: '50000',
    },
    Add: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/process/kind',
      method: 'post',
      timeout: '50000',
    },
    Edit: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/process/kind/typeId',
      method: 'put',
      timeout: '50000',
    },
    Delete: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/document/model/modelId',
      method: 'delete',
      timeout: '50000',
    },
  },
  Users: {
    AddAdmin: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/permission/admin',
      method: 'post',
      timeout: '50000',
    },
    Get: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/people',
      method: 'post',
      timeout: '50000',
    },
    GetPeopleWithPersmission: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/permission/people',
      method: 'post',
      timeout: '50000',
    },
    GetGroups: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/groups/person/personId',
      method: 'post',
      timeout: '50000',
    },
    GetOrganizationalUnits: {
      baseURL: API.socialMe,
      url: '/SIGAM/institutionId/organizational-unities/person/personId',
      method: 'post',
      timeout: '50000',
    },
  },
  PDF: {
    Get: {
      baseURL: API.socialMe,
      url: '/SIGAM/render-pdf',
      method: 'post',
      timeout: '50000',
    },
  },
  Admin: {
    Search: {
      baseURL: API.socialMe,
      url: '/SIGAM/people/all',
      method: 'post',
      timeout: '50000',
    },
    Institutions: {
      Get: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutions',
        method: 'post',
        timeout: '50000',
      },
      Link: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/contract',
        method: 'post',
        timeout: '50000',
      },
    },
    Admin: {
      Link: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/permission/admin/admin',
        method: 'post',
        timeout: '50000',
      },
    },
    Processes: {
      GetAll: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/admin-processes',
        method: 'post',
        timeout: '50000',
      },
      Show: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/admin-process/processId',
        method: 'get',
        timeout: '50000',
      },
      TimelineV1: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/process/processId/timeline-workflow',
        method: 'post',
        timeout: '50000',
      },
      Tree: {
        baseURL: API.socialMe,
        url: '/SIGAM/institutionId/process/processId/processing-workflow-tree',
        method: 'post',
        timeout: '50000',
      },
    },
  },
};
