import React, { useEffect, useState } from 'react';
// Libraries
import { useParams } from 'react-router-dom';
// Own Components
import Header from './components/Header';
import Content from './components/Content';
// Components
import Loading from '~/components/loading';
import UpdatePendingCard from '~/pages/SIGAM/components/Cards/UpdatePendingCard';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function TypesOfProcesses() {
  const { institutionId } = useParams();
  const [loadingContent, setLoadingContent] = useState(true);
  const [searchInput, setSearchInput] = useState();
  const [types, setTypes] = useState([]);
  const [pageParameters, setPageParameters] = useState({
    currentPage: 1,
    totalElements: 0,
    limit: 10,
  });
  const [updatePending, setUpdatePending] = useState(false);

  async function GetDataAgain(newPageParameters) {
    setLoadingContent(true);
    // setUpdatePending(false);
    const params = {
      operation: ['SIGAM', 'TypesOfProcesses', 'Get'],
      urlParams: {
        institutionId,
      },
      data: {
        filter: {
          search_string: searchInput,
        },
        pagination: {
          limit: newPageParameters
            ? newPageParameters.limit
            : pageParameters.limit,
          page: newPageParameters
            ? newPageParameters.currentPage
            : pageParameters.currentPage,
          sort: {
            by: 'id',
            order: 'asc',
          },
        },
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      setPageParameters({
        ...pageParameters,
        currentPage: response.body.processKinds.currentPage,
        totalElements: response.body.processKinds.totalElements,
        limit: response.body.processKinds.limit,
      });
      setTypes(response.body.processKinds.elements);
      setLoadingContent(false);
    }
  }

  useEffect(() => {
    async function GetData() {
      const params = {
        operation: ['SIGAM', 'TypesOfProcesses', 'Get'],
        urlParams: {
          institutionId,
        },
        data: {
          filter: {
            search_string: searchInput,
          },
          pagination: {
            sort: {
              by: 'name',
              order: 'asc',
            },
          },
        },
      };
      // console.log(params)
      const response = await SocialMeAPI(params);
      // console.log(response)
      if (response.success) {
        setPageParameters({
          currentPage: response.body.processKinds.currentPage,
          totalElements: response.body.processKinds.totalElements,
          limit: response.body.processKinds.limit,
        });
        setTypes(response.body.processKinds.elements);
      }
      setLoadingContent(false);
    }
    GetData();
  }, [institutionId, searchInput]);

  return (
    <div>
      <Header GetDataAgain={GetDataAgain} setSearchInput={setSearchInput} />
      {loadingContent ? (
        <Loading text="Buscando Tipos de Processos" />
      ) : (
        <>
          <UpdatePendingCard
            GetDataAgain={GetDataAgain}
            updatePending={updatePending}
          />
          <Content
            data={types}
            pageParameters={pageParameters}
            GetDataAgain={GetDataAgain}
            setUpdatePending={setUpdatePending}
          />
        </>
      )}
    </div>
  );
}
