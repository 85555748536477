import React from 'react';
// Libraries
import { List, message, Alert } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import Animate, { FadeIn } from 'animate-css-styled-components';
import { VscFilePdf } from 'react-icons/vsc';
// Components
import Button from '~/components/button';
// Utils
import { CapitalizeString } from '~/utils/Transformations';
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function AttachmentsList({
  attachments,
  document,
  GetAttachmentsAgain,
  accessLevel,
}) {
  const { institutionId, organizationUnitId } = useParams();

  async function Inactivate(item) {
    const params = {
      operation: ['SIGAM', 'Documents', 'Attachments', 'Edit'],
      urlParams: {
        institutionId,
        organizationUnitId,
        documentId: document.id,
        attachmentId: item.id,
      },
      data: {
        status: document.process_count == '0' ? 'DELETED' : 'INACTIVE',
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      message.success(
        document.process_count == '0'
          ? 'Anexo deletado com sucesso!'
          : 'Anexo inativado com sucesso!'
      );
      GetAttachmentsAgain();
    } else {
      message.error('Alguma coisa aconteceu...');
    }
  }

  return (
    <div style={{ marginTop: 10 }}>
      <List
        itemLayout="horizontal"
        dataSource={attachments}
        locale={{ emptyText: 'O documento não possui anexos' }}
        renderItem={(item) => (
          <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
            <List.Item
              actions={
                accessLevel && accessLevel.is_in_charge
                  ? [
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button>Visualizar</Button>
                      </a>,
                      item.status === 'INACTIVE' ? (
                        <Alert message="Inativo" type="error" showIcon />
                      ) : (
                        <Button
                          onClick={() => Inactivate(item)}
                          icon={<UserDeleteOutlined />}
                          danger
                        >
                          {document.process_count === '0'
                            ? 'Apagar anexo'
                            : 'Inativar anexo'}
                        </Button>
                      ),
                    ]
                  : [
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button>Visualizar</Button>
                      </a>,
                    ]
              }
            >
              <List.Item.Meta
                avatar={<VscFilePdf style={{ fontSize: '20pt' }} />}
                title={CapitalizeString(item.name)}
              />
            </List.Item>
          </Animate>
        )}
      />
    </div>
  );
}
