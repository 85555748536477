import React, { memo, useContext } from 'react';
// Libraries
import Pagination from './style';

import { ThemeContext } from '~/contexts/ThemeContext'

function AntPagination({
  onChange,
  currentPage,
  current,
  limit,
  pageSize,
  onShowSizeChange,
  total,
  showTotal,
  showSizeChanger,
  showQuickJumper
}) {
  const { theme } = useContext(ThemeContext)

  return (
    <Pagination
      textColor={theme.color.textOnPrimaryBackground}
      primary={theme.color.primary}
      borderColor={theme.color.primary}
      defaultCurrent={1}
      pageSize={pageSize || limit || 10}
      current={current || currentPage}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      total={total}
      showTotal={showTotal}
      showSizeChanger={total < limit || total < pageSize ? false : showSizeChanger}
      showQuickJumper={total < limit || total < pageSize ? false : showQuickJumper}
    />
  );
}

export default memo(AntPagination);
