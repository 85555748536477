import React from 'react';
// Components
import Space from '~/components/space';
import Card from '~/components/card';
import Title from '~/components/typography/title';
// Own Components
import FiltersButton from './components/FiltersButton';
import NewProcess from './components/NewProcess';

export default function Header({ filters, setFilters, GetDataAgain }) {
  return (
    <Card bodyStyle={{ padding: 10 }} style={{ marginBottom: 10 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title level={4} style={{ margin: 0 }}>
          Processos
        </Title>
        <Space>
          <FiltersButton
            filters={filters}
            setFilters={setFilters}
          />
          <NewProcess GetDataAgain={GetDataAgain} />
        </Space>
      </div>
    </Card>
  );
}
