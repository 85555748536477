import React, { useContext, useEffect, useState } from 'react';
// Libraries
import { RiUserLine } from 'react-icons/ri';
import { Avatar } from 'antd';
import { useParams } from 'react-router-dom';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Tooltip from '~/components/tooltip';
import Loading from '~/components/loading';
import Drawer from '~/components/drawer';
import PersonDrawer from '~/pages/SIGAM/components/PersonDrawer/Public';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { CapitalizeString, DecodeURI } from '~/utils/Transformations';

export default function Interested({ id }) {
  const { institutionId, organizationUnitId } = useParams();
  const { theme } = useContext(ThemeContext);
  const [people, setPeople] = useState([]);
  const [loadingContent, setLoadingContent] = useState(true);

  useEffect(() => {
    async function GetDocuments() {
      const params = {
        operation: ['SIGAM', 'Documents', 'Interested', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: id,
        },
        data: {
          pagination: {
            sort: {
              by: 'id',
              order: 'asc',
            },
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        // console.log('interested: ', response.body.interested.elements)
        setPeople(response.body.interested.elements);
      }
      setLoadingContent(false);
    }
    GetDocuments();
  }, [institutionId, organizationUnitId, id]);

  if (loadingContent) {
    return <Loading withoutText height="0px" />;
  }

  if (people.length === 0) {
    return (
      <Tooltip title="Sem interessados">
        <Avatar
          style={{
            backgroundColor: theme.color.backgroundSecondaryDark,
          }}
          icon={<RiUserLine />}
        />
      </Tooltip>
    );
  }

  return (
    <Avatar.Group
      maxCount={4}
      maxStyle={{
        color: theme.color.textOnPrimaryBackground,
        backgroundColor: theme.color.primaryLight,
      }}
    >
      {people.map((item) => (
        <Drawer
          key={item.id}
          Trigger={
            <Tooltip
              title={CapitalizeString(item.social_name || item.name)}
              placement="top"
            >
              <div style={{ cursor: 'pointer' }}>
                <Avatar src={DecodeURI(item.avatar)} />
              </div>
            </Tooltip>
          }
          title={`${CapitalizeString(item.social_name || item.name)}`}
          DrawerContentComponent={PersonDrawer}
          DrawerContentComponentParams={{ person: item }}
        />
      ))}
    </Avatar.Group>
  );
}
