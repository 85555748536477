import SigamAPI from '~/utils/SigamAPI';

export default async function UpdateChat({ chat, chatId, institutionId }) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/chat/${chatId}`,
    method: 'PATCH',
    data: chat,
  });

  return response;
}
