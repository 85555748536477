import React from 'react';
import { Menu } from 'antd';

export default function ChatGroupMenu({
  onDelete,
  onEdit,
  onSeeDetails,
  onAddContactIntoGroup,
}) {
  return (
    <Menu
      items={[
        {
          key: '1',
          label: 'Excluir grupo',
          onClick: () => onDelete(),
        },
        {
          key: '2',
          label: 'Editar grupo',
          onClick: () => onEdit(),
        },
        {
          key: '3',
          label: 'Detalhes do grupo',
          onClick: () => onSeeDetails(),
        },
        {
          key: '4',
          label: 'Adicionar contato',
          onClick: () => onAddContactIntoGroup(),
        },
      ]}
    />
  );
}
