import React, { useState } from 'react';
import { CloudUploadOutlined } from '@ant-design/icons';
import { Drawer, message, Typography } from 'antd';

import Dropable from './components/Dropable';
import FileItem from './components/FileContent';

const { Text } = Typography;

export default function Header() {
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setFiles([]);
    setOpen(false);
  };

  function getFileExtension(file) {
    const splitedFilename = file.name.split('.');

    const fileExtension = splitedFilename[splitedFilename.length - 1];

    return fileExtension.toUpperCase();
  }

  function onAddFiles(files) {
    let correctFiles = [];

    for (let index = 0; index < files.length; index++) {
      const file = files[index];

      const extension = getFileExtension(file);

      if (extension.includes('PDF')) {
        correctFiles.push(file);
      } else {
        message.error(`Arquivo ${extension}: Aceitamos apenas PDFs`);
      }
    }

    setFiles((currentFiles) => {
      return [...currentFiles, ...correctFiles];
    });
  }

  return (
    <>
      <div
        onClick={showDrawer}
        className="option-container"
        style={{
          border: '1px solid #d9d9d9',
          borderStyle: 'dashed',
          padding: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          borderRadius: 10,
          cursor: 'pointer',
          backgroundColor: '#fafafa',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          <CloudUploadOutlined />
          <Text style={{ fontWeight: 'bold' }}>Upload de arquivos</Text>
        </div>
        <Text>Upload individual e em lote</Text>
      </div>

      <Drawer
        title="Upload de arquivos"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <Dropable onChange={onAddFiles} onDrop={onAddFiles} />

        {files.map((file) => (
          <div key={file.name} style={{ margin: '10px 0' }}>
            <FileItem file={file} />
          </div>
        ))}
      </Drawer>
    </>
  );
}
