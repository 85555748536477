import React, { useRef } from 'react';
// Libraries
import { Row, Col, Form } from 'antd';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import Checkbox from '~/components/checkbox';

export default function AddForm({ form }) {
  const editor = useRef();

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  async function CleanPastedHTML(input) {
    console.log('1 ', input);
    // 1. remove line breaks / Mso classes
    const stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g;
    var output = input.replace(stringStripper, ' ');
    console.log('2 ', output);
    // 2. strip Word generated HTML comments
    const commentSripper = new RegExp('<!--(.*?)-->', 'g');
    var output = output.replace(commentSripper, '');
    let tagStripper = new RegExp(
      '<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>',
      'gi'
    );
    // 3. remove tags leave content if any
    output = output.replace(tagStripper, '');
    // 4. Remove everything in between and including tags '<style(.)style(.)>'
    const badTags = [
      'style',
      'script',
      'applet',
      'embed',
      'noframes',
      'noscript',
    ];

    for (var i = 0; i < badTags.length; i++) {
      tagStripper = new RegExp(`<${badTags[i]}.*?${badTags[i]}(.*?)>`, 'gi');
      output = output.replace(tagStripper, '');
    }
    // 5. remove attributes ' style="..."'
    const badAttributes = ['style', 'start'];
    for (var i = 0; i < badAttributes.length; i++) {
      const attributeStripper = new RegExp(
        ` ${badAttributes[i]}="(.*?)"`,
        'gi'
      );
      output = output.replace(attributeStripper, '');
    }
    console.log('out ', output);
    return output;
  }

  function CleanPastedHTML2(value0) {
    console.log('0 ', value0);
    // 1. remove line breaks / Mso classes
    const stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g;
    const value1 = value0.replace(stringStripper, ' ');
    console.log('1 ', value1);
    // 2. strip Word generated HTML comments
    const commentSripper = new RegExp('<!--(.*?)-->', 'g');
    const value2 = value1.replace(commentSripper, '');
    console.log('2 ', value2);
    let tagStripper = new RegExp(
      '<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>',
      'gi'
    );
    // 3. remove tags leave content if any
    const value3 = value2.replace(tagStripper, '');
    console.log('3 ', value3);
    // 4. Remove everything in between and including tags '<style(.)style(.)>'
    const badTags = [
      'style',
      'script',
      'applet',
      'embed',
      'noframes',
      'noscript',
    ];

    let value4 = value3;
    for (let i = 0; i < badTags.length; i++) {
      tagStripper = new RegExp(`<${badTags[i]}.*?${badTags[i]}(.*?)>`, 'gi');
      value4 = value4.replace(tagStripper, '');
    }
    console.log('4 ', value4);
    // 5. remove attributes ' style="..."'
    const badAttributes = ['style', 'start'];
    let value5 = value4;
    for (let i = 0; i < badAttributes.length; i++) {
      const attributeStripper = new RegExp(
        ` ${badAttributes[i]}="(.*?)"`,
        'gi'
      );
      value5 = value5.replace(attributeStripper, '');
    }
    console.log('5 ', value5);
    return value5;
  }

  async function onPaste(event, cleanData, maxCharCount, core) {
    // const regexp = new RegExp('/^<(/)?[^br][a-z0-9]{0,}>$/g')
    // console.log(event)
    // console.log(cleanData)
    // console.log(maxCharCount)
    // console.log(core)
    const stripedHtml = CleanPastedHTML(cleanData);
    // let stripedHtml = cleanData.replace(/<\/p>/g, '<br>');
    // stripedHtml = cleanData.replace(/<\/a>/g, '<br>');
    // stripedHtml = cleanData.replace(/<\/h1>/g, '<br>');
    // const stripedHtml = cleanData.replace(/<[^>]+>/g, '');
    // const stripedHtml = cleanData.replace(/<(?!\s*br\s*\/?)[^>]+>/gi, '');
    return CleanPastedHTML(cleanData);
  }

  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      form={form}
      layout="vertical"
      size="small"
      scrollToFirstError
    >
      <Row>
        <Col span={24}>
          <FormItem
            name={['body']}
            item={
              <SunEditor
                lang="pt_br"
                name="body"
                autoFocus
                getSunEditorInstance={getSunEditorInstance}
                setOptions={{
                  buttonList: [
                    ['undo', 'redo'],
                    ['font', 'fontSize', 'formatBlock'],
                    ['paragraphStyle', 'blockquote'],
                    [
                      'bold',
                      'underline',
                      'italic',
                      'strike',
                      'subscript',
                      'superscript',
                    ],
                    ['fontColor', 'hiliteColor', 'textStyle'],
                    ['removeFormat'],
                    ['outdent', 'indent'],
                    ['align', 'horizontalRule', 'list', 'lineHeight'],
                    ['table', 'link', 'image'],
                    ['fullScreen', 'showBlocks', 'codeView'],
                  ],
                }}
                onPaste={(event, cleanData, maxCharCount, core) =>
                  onPaste(event, cleanData, maxCharCount, core)
                }
                //onPaste={(event, cleanData, maxCharCount, core) =>event.preventDefault()}
              />
            }
          />
        </Col>
        <Col span={24}>
          <FormItem
            label="Nome do Modelo de Documento"
            name={['name']}
            rules={[
              {
                required: true,
                message: 'Insira um nome para o modelo de documento',
              },
            ]}
            item={<Input />}
          />
        </Col>
        <Col span={24}>
          <FormItem
            label="Situação"
            name="status"
            valuePropName="checked"
            item={<Checkbox text="Ativo" />}
          />
        </Col>
      </Row>
    </Form>
  );
}
