import React from 'react';

import { useMessagerManager } from '../../../../../../../../contexts/MessagerManager/Provider';
import ChatContent from './components/ChatItem';
import * as S from './style';

export default function ChatItem({ chat }) {
  const { selectedChat, setSelectedChat } = useMessagerManager();

  return (
    <S.Content
      className={selectedChat?.id === chat.id && 'active'}
      onClick={() => setSelectedChat(chat)}
    >
      <ChatContent chat={chat} />
    </S.Content>
  );
}
