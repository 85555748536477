import React, { useContext, useState, useEffect } from 'react';
// Libraries
import { Avatar, Badge } from 'antd';
import { RiUserLine } from 'react-icons/ri';
import { BsGear } from 'react-icons/bs';
// Contexts
import { SIGAMContext } from '~/contexts/SIGAMContext';
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import PersonDrawerAdmin from '~/pages/SIGAM/components/PersonDrawer/Admin';
import Loading from '~/components/loading';
import Tooltip from '~/components/tooltip';
import Drawer from '~/components/drawer';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { CapitalizeString, DecodeURI} from '~/utils/Transformations';

export default function RenderUsers({
  record,
  setUpdatePending
}) {
  const { activeInstitution } = useContext(SIGAMContext);
  const { theme } = useContext(ThemeContext);
  const [loadingContent, setLoadingContent] = useState(true);
  const [users, setUsers] = useState();

  useEffect(() => {
    async function GetUsers() {
      const params = {
        operation: ['SIGAM', 'OrganizationalUnits', 'Users', 'Get'],
        urlParams: {
          institutionId: activeInstitution.id,
          organizationUnitId: record.id,
        },
        data: {
          filter: {
            status: 'ACTIVE',
          },
          pagination: {
            sort: {
              by: 'id',
              order: 'asc',
            },
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setUsers(response.body.publicAgents.elements);
        setLoadingContent(false);
      }
    }
    GetUsers();
  }, [record, activeInstitution]);

  if (loadingContent) {
    return <Loading text="Buscando usuários" height="0px" />;
  }

  if (users && users.length === 0) {
    return (
      <Tooltip title="Sem usuário">
        <Avatar
          style={{
            backgroundColor: theme.color.backgroundSecondaryDark,
          }}
          icon={<RiUserLine />}
        />
      </Tooltip>
    );
  }

  return (
    <Avatar.Group
      maxCount={4}
      maxStyle={{
        color: theme.color.textOnPrimaryBackground,
        backgroundColor: theme.color.primaryLight,
      }}
    >
      {users && users.map((item) => (


        <Drawer
          key={item.id}
          Trigger={
            <Tooltip
              title={CapitalizeString(
                item.person_social_name || item.person_name
              )}
              placement="top"
            >
              {item.is_admin ?
                <Badge
                  count={
                    <BsGear
                      style={{ color: '#fff', backgroundColor: '#143c6d' }}
                    />
                  }
                  offset={[-5, 30]}
                >
                  <div style={{ cursor: 'pointer' }}>
                    <Avatar src={DecodeURI(item.person_avatar || '')} />
                  </div>
                </Badge>
                :
                <div style={{ cursor: 'pointer' }}>
                  <Avatar src={DecodeURI(item.person_avatar || '')} />
                </div>
              }
            </Tooltip>
          }
          title={`${CapitalizeString(item.person_social_name || item.person_name)}`}
          DrawerContentComponent={PersonDrawerAdmin}
          DrawerContentComponentParams={{ record: item, setUpdatePending }}
        />
      ))}
    </Avatar.Group>
  );
}
