import React, { useEffect, useState } from 'react';
// Libraries
import {  Badge } from 'antd';
import { useParams } from 'react-router-dom';
// Components
import Lottie from 'react-lottie'
import Loading from '~/components/loading';
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
import Button from '~/components/button';
//
import List from './components/List';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import animationData from '~/assets/images/lotties/earth.json'

export default function FilesList({
  process,
}) {
  const { institutionId, organizationUnitId } = useParams();
  const [treeData, setTreeData] = useState();
  const [loadingContent, setLoadingContent] = useState(true);
  const [print, setPrint] = useState(false)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  useEffect(() => {
    async function GetAttachmentsDocument(document) {
      const params = {
        operation: ['SIGAM', 'Documents', 'Attachments', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: document.id,
        },
        data: {
          pagination: {
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        return response.body.attachments.elements;
      }
    }
    async function GetAttachments() {
      const params = {
        operation: ['SIGAM', 'Processes', 'Attachments', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
          processId: process.id,
        },
        data: {
          pagination: {
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      // console.log(response)
      if (response.success) {
        const tree = [];
        const father = {
          ...process,
          title: process.name,
          key: '1',
          type: 'process',
          data: process,
          children: [],
        };
        const array = response.body.documents.elements;
        for (let i = 0; i < array.length; i++) {
          const newItem = {
            ...array[i],
            title: <Text><Badge color={array[i].status === 'INACTIVE' ? 'red' : 'green'} style={{ marginRight: '2px' }} />{array[i].name}</Text>,
            key: `1-${i + 1}`,
            type: 'document',
            data: array[i],
            children: [],
          };
          const attachmentsDocument = await GetAttachmentsDocument(array[i]);
          for (let j = 0; j < attachmentsDocument.length; j++) {
            const newSubItem = {
              ...attachmentsDocument[j],
              title: <Text><Badge color={array[i].status === 'INACTIVE' ? 'red' : 'green'} style={{ marginRight: '2px' }} />{attachmentsDocument[j].name}</Text>,
              key: `1-${i + 1}-${j + 1}`,
              type: 'attachment',
              data: attachmentsDocument[j],
            };
            newItem.children.push(newSubItem);
          }
          father.children.push(newItem);
        }
        tree.push(father);
        setTreeData(tree);
      }
      setLoadingContent(false);
    }
    GetAttachments();
  }, [institutionId, organizationUnitId, process]);

  return (
     <div>
     {loadingContent ?
       <Loading />
       :
       print ?
         <List process={process} treeData={treeData} />
         :
         <div style={{ display: 'flex', gap: '10px' }}>
            <div>
              <Lottie options={defaultOptions} height={120} width={120} />
            </div>
            <div style={{ display: "flex", flexDirection: 'column', justifyContent:'space-between' }}>
              <Title level={4} style={{ margin: 0 }}>Digitalização é uma das principais medidas para a proteção do meio ambiente</Title>
              <Text type="success">Menos papel e mais árvores</Text>
              <Button onClick={() => setPrint(true)} type="primary">Preparar impressão</Button>
            </div>
          </div>
     }
   </div>
  );
}
