import React, { useState, useEffect, useContext } from 'react';
// Contexts
import { AuthContext } from '~/contexts/AuthContext';
// Own Components
import Loading from '~/components/loading';
import SocialMeAPI from '~/utils/SocialMeAPI';
import Avatar from './components/Avatar';
import Form from './components/Form';
import FormPJ from './components/FormPJ';
import Password from './components/Password'
// Components
// Utils

export default function ProfileDrawer() {
  const { GetUserAgain } = useContext(AuthContext);
  const [loadingContent, setLoadingContent] = useState(true);
  const [person, setPerson] = useState();

  async function GetProfileAgain() {
    const params = {
      operation: ['Profile', 'Get'],
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      GetUserAgain();
      return setPerson(response.body.person);
    }
  }

  useEffect(() => {
    async function GetData() {
      const params = {
        operation: ['Profile', 'Get'],
      };
      const response = await SocialMeAPI(params);
      //console.log(response);
      if (response.success) {
        setPerson(response.body.person);
        return setLoadingContent(false);
      }
      return setLoadingContent(false);
    }
    GetData();
  }, []);

  if (loadingContent) {
    return <Loading text="Buscando Informações" />;
  }

  return (
    <div>
      <Avatar person={person} GetProfileAgain={GetProfileAgain} />
      {person?.kind === "PF" && <Form person={person} GetProfileAgain={GetProfileAgain} />}
      {person?.kind === "PJ" && <FormPJ person={person} GetProfileAgain={GetProfileAgain} />}
      <Password person={person} GetProfileAgain={GetProfileAgain} />
    </div>
  );
}
