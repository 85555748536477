import React, { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Typography, Card, Spin } from 'antd';
import { usePageManager } from '../../../../contexts/PageManager/Provider';
import { useState } from 'react';

const { Title } = Typography;

export default function Users() {
  const { totalSubjects, totalSubjectsLoading } = usePageManager();
  const [chartState, setChartState] = useState(null);
  useEffect(() => {
    if (!totalSubjectsLoading) {
      if (totalSubjects) {
        setChartState({
          series: [
            {
              data: totalSubjects.data,
            },
          ],
          options: {
            legend: {
              show: false,
            },
            chart: {
              height: 350,
              type: 'bar',
              toolbar: {
                show: false,
              },
              parentHeightOffset: 0,
              /*
              sparkline: {
                enabled: true,
              },
              */
            },
            plotOptions: {
              bar: {
                columnWidth: '45%',
                distributed: true,
              },
            },
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: false,
            },
            xaxis: {
              categories: totalSubjects.categories,
              labels: {
                style: {
                  //colors: colors,
                  fontSize: '12px',
                },
              },
            },
          },
        });
      }
    }
  }, [totalSubjectsLoading, totalSubjects]);

  if (totalSubjectsLoading) {
    return (
      <Card bodyStyle={{ display: 'flex', justifyContent: 'center' }}>
        <Spin />
      </Card>
    );
  }

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <div
        style={{
          padding: '6px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#143c6d',
          borderTopRightRadius: '4px',
          borderTopLeftRadius: '4px',
        }}
      >
        <Title level={5} style={{ margin: 0, padding: 0, color: 'white' }}>
          Assuntos mais utilizados
        </Title>
      </div>
      {chartState && (
        <Chart
          options={chartState.options}
          series={chartState.series}
          type="bar"
          height={350}
        />
      )}
    </Card>
  );
}
