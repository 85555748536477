import SigamAPI from '~/utils/SigamAPI';

export default async function DeleteTemplate({ institutionId, templateId }) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/communication-model/${templateId}`,
    method: 'DELETE',
  });

  return response;
}
