import { message } from 'antd';
import SigamAPI from '~/utils/SigamAPI';

export default async function DeleteArchiveFile({
  fileId,
  archiveId,
  institutionId,
}) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/archive/${archiveId}/file/${fileId}`,
    method: 'DELETE',
  });

  if (response.success) {
    message.success('Arquivo deletado com sucesso');
  } else {
    message.error('Erro ao deletar o arquivo');
  }

  return response;
}
