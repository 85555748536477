import React, { useState, useContext } from 'react';
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import Button from '~/components/button';
import Loading from '~/components/loading'
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

export default function ExternalView({ document }) {
    const { isMobile } = useContext(ResponsiveContext);
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ margin: '0px 0px 5px 0px' }}>
                    <a href={document.url} target="_blank" rel="noopener noreferrer">
                        <Button type="dashed">Imprimir Documento</Button>
                    </a>
                </div>
                {isMobile ? <div id="ResumeContainer">
                    <Document
                        className="PDFDocument"
                        file={{ url: document.url }}
                        options={{ workerSrc: '/pdf.worker.js' }}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={<Loading text="Criando documento" />}
                    >
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} renderTextLayer={false} renderInteractiveForms={false} className="PDFPage PDFPageOne" />
                        ))}
                    </Document>
                </div> : <div
                    style={{
                        padding: 10,
                        backgroundColor: '#d9d9d9'
                    }}
                >
                    <Document
                        file={{ url: document.url }}
                        options={{ workerSrc: "/pdf.worker.js" }}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={<Loading text="Criando documento" />}
                        className="PDFDocument"
                    >
                        {Array.from(new Array(numPages), (el, index) => (
                            <div key={`page_${index + 1}`} style={{
                                borderBottom: '6px solid #d9d9d9'
                            }}>
                                <Page pageNumber={index + 1} className="pdf-page" />
                            </div>
                        ))}
                    </Document>
                </div>}

            </div>
        </div>
    )
}