import React, { useContext } from 'react';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Own Components
import AddButton from './components/AddButton';
// Components
import Space from '~/components/space';
import Card from '~/components/card';
import Title from '~/components/typography/title';
import Search from '~/components/search';

export default function Header({ setSearchInput, GetDataAgain }) {
  const { isMobile } = useContext(ResponsiveContext);
  return (
    <Card bodyStyle={{ padding: 10 }} style={{ marginBottom: 10 }}>
      <div style={
        isMobile ?
          { display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }
          :
          { display: 'flex', justifyContent: 'space-between' }}
      >
        <Title level={4} style={{ margin: isMobile ? '0px 0px 10px' : 0 }}>
          Unidades Organizacionais
        </Title>
        <Space align={isMobile ? 'center' : 'auto'} direction={isMobile ? 'vertical' : 'horizontal'}>
          <Search onSearch={(value) => setSearchInput(value)} />
          <AddButton GetDataAgain={GetDataAgain} />
        </Space>
      </div>
    </Card>
  );
}
