import React, { useContext, useEffect, useState } from 'react';
// Libraries
import { RobotOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { useParams } from 'react-router-dom';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Tooltip from '~/components/tooltip';
import Loading from '~/components/loading';
import Drawer from '~/components/drawer';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { CapitalizeString } from '~/utils/Transformations';

export default function Interested({ id }) {
    const { institutionId, organizationUnitId } = useParams();
    const { theme } = useContext(ThemeContext);
    const [people, setPeople] = useState([]);
    const [loadingContent, setLoadingContent] = useState(true);

    function PersonBody() {
        return (
            <div>
                <p>PersonBody</p>
            </div>
        )
    }

    useEffect(() => {
        async function GetInterested() {
            const params = {
                operation: ['SIGAM', 'Processes', 'Interested', 'Get'],
                urlParams: {
                    institutionId: institutionId,
                    organizationUnitId: organizationUnitId,
                    processId: id
                },
                data: {
                    pagination: {
                        sort: {
                            by: 'id',
                            order: 'asc',
                        },
                        limit: 0
                    },
                },
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                setPeople(response.body.interested.elements);
            }
            setLoadingContent(false);
        }
        GetInterested();
    }, [institutionId, organizationUnitId, id])

    if (loadingContent) {
        return <Loading withoutText height="0px" />
    }

    if (people.length === 0) {
        return (
            <Tooltip title="Sem interessados">
                <Avatar
                    style={{
                        backgroundColor: theme.color.backgroundSecondaryDark,
                    }}
                    icon={<RobotOutlined />}
                />
            </Tooltip>
        );
    }

    return (
        <Avatar.Group
            maxCount={4}
            maxStyle={{
                color: theme.color.textOnPrimaryBackground,
                backgroundColor: theme.color.primaryLight,
            }}
        >
            {people.map((item) => (
                <Drawer
                    key={item.id}
                    Trigger={
                        <Tooltip
                            title={CapitalizeString(
                                item.social_name || item.name
                            )}
                            placement="top"
                        >
                            <div style={{ cursor: 'pointer' }}>
                                <Avatar src={item.avatar} />
                            </div>
                        </Tooltip>
                    }
                    title={`${CapitalizeString(item.social_name || item.name)}`}
                    DrawerContentComponent={PersonBody}
                    DrawerContentComponentParams={{ record: item }}
                />
            ))}
        </Avatar.Group>
    )
}