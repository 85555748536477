import React, { useState } from 'react';
// Libraries
import {
  Button,
  message,
  Form,
  Typography,
  Input,
  Segmented,
  Spin,
  Space,
} from 'antd';
import { FaStamp } from 'react-icons/fa';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { usePageManager } from './contexts/PageManager/Provider';

const { Text } = Typography;

export default function LexML() {
  const { loadingContent, lexml, document, updateDocument } = usePageManager();
  const [form] = Form.useForm();
  const [loadingButton, setLoadingButton] = useState(false);

  async function onFinish(values) {
    setLoadingButton(true);
    const response = await updateDocument(values);
    if (response) {
      message.success('Documento sincronizado com sucesso!');
    }
    setLoadingButton(false);
  }

  if (loadingContent) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div>
      {document.urn && (
        <div style={{ display: 'flex', gap: 4 }}>
          <Text>URN:</Text>
          <Text strong>{document.urn}</Text>
        </div>
      )}

      <Form
        labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        form={form}
        layout="vertical"
        scrollToFirstError
        onFinish={onFinish}
        initialValues={lexml}
      >
        <Form.Item label="Categoria" name="category">
          <Segmented
            options={
              document?.kind !== 'EXTERNAL'
                ? ['Nenhuma', 'Lei municipal', 'Decreto']
                : ['Nenhuma', 'Lei municipal']
            }
          />
        </Form.Item>

        <Text>Metadados exclusivos</Text>

        <Form.List name="metas">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    marginBottom: 8,
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'label']}
                    rules={[
                      {
                        required: true,
                        message: 'Preencha o campo ou delete o metadado',
                      },
                    ]}
                  >
                    <Input placeholder="Nome do metadado" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'value']}
                    rules={[
                      {
                        required: true,
                        message: 'Preencha o campo ou delete o metadado',
                      },
                    ]}
                  >
                    <Input placeholder="Valor" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Adicionar metadado exclusivo
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            icon={<FaStamp style={{ marginRight: 5 }} />}
            loading={loadingButton}
            style={{
              width: '100%',
            }}
          >
            Sincronizar documento no LexML
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
