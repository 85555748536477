import styled from 'styled-components';

export const Container = styled.div`
  padding: 3px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 5px;
`;

export const Content = styled.div`
  width: 100%;
  border-radius: 15px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
