import React, { memo } from 'react';
// Own Components
import HasUser from './components/HasUser';
import HasNoUser from './components/HasNoUser';
// Utils
import { ExistingUser } from '~/utils/UserInformation';

function UserButton({ withoutSidebar }) {
  return ExistingUser() ? (
    <HasUser withoutSidebar={withoutSidebar} />
  ) : (
    <HasNoUser />
  );
}

export default memo(UserButton);
