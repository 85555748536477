export default {
  SM505AFC4: {
    message: "The parameter 'login' is required but wasn't sent",
  },
  SM6E06634: {
    message: "The parameter 'auth_password' is required but wasn't sent",
  },
  SMF8D3004: {
    message: "The parameter 'role' is required but wasn't sent"
  }
};
