import React from 'react';
// Components
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';

export default function Terms() {
    return (
        <div>
            <Title level={4}>Termos e Condições do Beneficiário</Title>
            <Text>Leia com atenção os Termos e Condições do beneficiário . O uso da plataforma implica na aceitação destes Termos.</Text>
            <br />
            <Text>A SOCIALME é plataforma online que corresponde a um conjunto de soluções tecnológicas que viabilizam doações online (crowdfunding), podendo ser utilizada por pessoas físicas e jurídicas doravante denominadas como “beneficiário ”. Estes Termos de Uso são localizados na plataforma (disponível em www.socialme.com.br/termos).</Text>
            <br />
            <Text>Para utilizar os serviços da SOCIALME, na qualidade de beneficiário , este deverá obrigatoriamente efetuar um cadastro fornecendo os seus dados de modo exato, preciso e verdadeiro. Dentre esses dados são eles, nome ou razão social, endereço de e-mail válido, e CPF ou CNPJ ativo.</Text>
            <br />
            <Text>Aos menores de 18 anos, às pessoas que não tenham capacidade para contratar ou aqueles usuários que tenham dados cadastrais com irregularidades, inconsistências ou que tenham sido, de alguma forma, suspensos da plataforma da SOCIALME, temporária ou definitivamente, é proibido utilizar os serviços aqui oferecidos.</Text>
            <br />
            <Text>A responsabilidade pelos dados cadastrais é única e exclusivamente do beneficiário , sendo certo que o fornecimento de informações falsas ou incorretas poderá sujeitar o beneficiário  à responsabilização nas esferas cível, administrativa e criminal, na forma prevista em lei.</Text>
            <br />
            <Text>Sempre utilize o endereço eletrônico www.socialme.com.br para saber informações sobre campanhas e doações.</Text>
            <br />
            <Text>Estes Termos e condições gerais aplicam-se ao uso dos serviços oferecidos pela SOCIALME BENEFÍCIOS E SERVIÇOS S.A., inscrita no CNPJ/MF sob o nº. 38.100.911/0001-63, com sede na cidade de Boa Vista, Estado de Roraima, na Rua Jaqueira, Brasil, nº. 623, Caçari, CEP: 69307-410, denominada a partir deste momento como SOCIALME e qualquer pessoa física ou jurídica que atenda todos os requisitos destes Termos e Condições Gerais (adiante denominada "DOADOR") e que deseje contratar os serviços oferecidos pela SOCIALME.</Text>
            <br />

            <Title level={4}>1. DO OBJETO</Title>
            <Text>1.1 A CONTRATADA, por si próprio ou por intermédio de empresas por ela contratadas ou com ela associadas, colocará à disposição do beneficiário  meios digitais de transmissão de informações, mais especificamente o serviço de gestão de pagamentos denominado “crowdfunding” ou “financiamento coletivo”, com o intuito de arrecadar doações para o beneficiário .</Text>
            <br />
            <Text>1.2 Os fins a que se destinam as doações deverão ser lícitos e serão de inteira e única responsabilidade do beneficiário .</Text>
            <br />
            <Text>1.3 Crowdfunding ou financiamento coletivo consiste na transferência de créditos (“Doação”) da Conta do Doador usuário da Plataforma (“Doador”) para a conta bancária designada pelo beneficiário  feita através da plataforma da SOCIALME.</Text>
            <br />

            <Title level={4}>2. REQUISITOS PARA SER UM beneficiário  (CADASTRAMENTO)</Title>
            <Text>2.1 Ser pessoa física maior de 18 anos ou pessoa jurídica;</Text>
            <br />
            <Text>2.2 O beneficiário  deverá informar nome, CPF/CNPJ, data de nascimento, endereço eletrônico, celular, endereço e especificar a os fins para qual doação quer receber de maneira clara e precisa sem que infrinja nenhum ato ou legislação e estes Termos de Uso;</Text>
            <br />
            <Text>2.3 O beneficiário  é exclusivamente responsável pelo cadastro de Login e Senha, sendo pessoal e intransferível a terceiros. Sob nenhuma hipótese a SOCIALME é responsável pelo Login e pela Senha cadastrada pelo beneficiário . Caso haja o fornecimento dos dados, o beneficiário  está ciente de que estará fornecendo acesso a Plataforma e a todas as informações disponíveis, não sendo possível realizar qualquer bloqueio ou suspensão de acesso aos usuários e portadores da senha. Portanto, é de obrigação do beneficiário  manter sua senha em sigilo e não revelar a qualquer terceiro.</Text>
            <br />
            <Text>2.4 A SOCIALME reserva-se o direito de utilizar todos os meios válidos e legais para, se entender necessário, confirmar os dados fornecidos pelo beneficiário  no momento de cadastramento, bem como sua situação de crédito, tendo em vista a natureza do serviço da plataforma e a necessidade de confiabilidade e segurança dos registros das transações eletrônicas e demais funcionalidades. Assim, a SOCIALME poderá, entre outras medidas, solicitar ao beneficiário  dados adicionais e documentos que julgue pertinentes, bem como consultar bancos de dados mantidos por terceiros, tais como SPC e SERASA.</Text>
            <br />
            <Text>2.5 A SOCIALME, caso suspeite de fraude na criação do cadastro de uma nova campanha poderá solicitar informações que entender cabíveis para evitar que uma atividade ilícita seja feita por meio da Boa Vista / RR 95 3276.9331 Rua Jaqueira, 623, Caçari suporte@socialme.com.br e www.socialme.com.br Termos e Condições do beneficiário  plataforma, podendo ainda congelar a plataforma até que haja a total certeza de que a campanha criada é legal e está dentro das políticas do site.</Text>
            <br />
            <Text>2.6 As informações colocadas na plataforma são de inteira responsabilidade do beneficiário  e este, por sua vez, atesta que são todas verídicas e guardam correlação com os dados reais.</Text>
            <br />
            <Text>2.7 O beneficiário  é responsável por qualquer informação falsa, incorreta, desatualizada ou incompleta. A SOCIALME reserva-se o direito de cancelar, imediatamente e independentemente de notificação, a campanha do beneficiário .</Text>
            <br />

            <Title level={4}>3. DA UTILIZAÇÃO DA PLATAFORMA (DISPOSIÇÕES GERAIS)</Title>
            <Text>3.1 Com o pedido de doação pelo beneficiário , permite-se sejam realizadas doações pelos DOADORES que serão transferidas para o beneficiário  dar a destinação ao dinheiro recolhido.</Text>
            <br />
            <Text>3.2 É preciso apresentar as principais informações e detalhes relacionados à doação a ser realizada.</Text>
            <br />
            <Text>3.3 É permitido empregar dados e imagens, que serão disponibilizados no site da SOCIALME, cuja titularidade e responsabilidade são exclusivas do beneficiário .</Text>
            <br />
            <Text>3.3.1 O beneficiário  se compromete a apenas utilizar na Plataforma imagens e/ou vídeos que sejam de sua autoria e propriedade. Caso as imagens ou vídeos contenham pessoas que não sejam a do beneficiário , autor da campanha, este deverá dispor das devidas autorizações das pessoas envolvidas ou seus responsáveis. No caso de denúncia de uso indevido de imagens, a campanha poderá ser cancelada e os valores devolvidos aos doadores descontadas as taxas de serviço da Plataforma.</Text>
            <br />
            <Text>3.3.2 O beneficiário  se responsabiliza pela adequação das imagens e vídeos para visualização pública. Imagens e/ou vídeos que exponham nudez, violência ou imagens ofensivas de maneira geral poderão acarretar no imediato cancelamento da campanha a critério da SOCIALME.</Text>
            <br />
            <Text>3.4 Caso haja múltipla criação de uma campanha com objeto igual ou substancialmente semelhante, poderá a SOCIALME bloquear as campanhas a seu exclusivo critério e contatar o beneficiário  sobre a duplicidade e a fim de se chegar a solução adequada.</Text>
            <br />
            <Text>3.5 A plataforma da SOCIALME não pode ser utilizada para transações comerciais. Nenhuma contrapartida pode ser oferecida pelas doações. </Text>
            <br />
            <Text>3.6. A SOCIALME não se responsabiliza pelos tributos devidos pelas receitas recebidas pelos beneficiários em suas campanhas.</Text>
            <br />

            <Title level={4}>4. LIBERAÇÃO DOS RECURSOS E REPASSES</Title>
            <Text>4.1 Após a realização de uma doação, a SOCIALME receberá os recursos enviados pelo DOADOR de forma a gerar o saldo de moeda eletrônica correspondente em sua Conta apta a receber recursos de instituição financeira nacional, bem como registrar a transferência do referido saldo para a Conta do beneficiário  donatário, para fins de contribuição à campanha de doação correspondente.</Text>
            <br />
            <Text>4.2 Os prazos para recebimento dos recursos pelo beneficiário  dependem do Meio de Pagamento escolhido pelo beneficiário , bem como de eventuais restrições que a SOCIALME possa identificar durante o processamento da doação.</Text>
            <br />
            <Text>4.3 Pagamentos feitos em cartão de crédito demoram 30 (trinta) dias para se efetivarem.</Text>
            <br />
            <Text>4.4 Pagamentos feitos por boleto demoram 2 dias para se efetivarem.</Text>
            <br />
            <Text>4.5 Pagamentos feitos por transferência bancária ou depósito demoram 2 dias para serem conciliados.</Text>
            <br />
            <Text>4.6 Pagamentos feitos por PIX demoram 2 dias úteis para serem conciliados.</Text>
            <br />
            <Text>4.7 Após o transcurso dos prazos do item 4.3. e 4.4., a Plataforma demora 2 (dois) dias úteis para liberar os valores.</Text>
            <br />
            <Text>4.6 A SOCIALME terá o direito de, caso sejam identificadas irregularidades (tais como, a ocorrência de fraudes, chargebacks, erros de processamento e/ou falhas de transação) e/ou situações que, a seu critério exclusivo, infrinjam as condições destes Termos, cancelar e/ou realizar estornos de quaisquer valores doados ou recebidos.</Text>
            <br />
            <Text>4.7 A SOCIALME poderá cobrar dos beneficiários doadores eventuais custos ou despesas relacionadas ao cancelamento e/ou estornos dos valores por eles aportados.</Text>
            <br />
            <Text>4.8 Caso tais irregularidades e/ou descumprimentos sejam identificados pelo SOCIALME após a retirada de valores pelo beneficiário , a SOCIALME, a seu exclusivo critério, poderá realizar a cobrança dos valores indevidamente pagos ao beneficiário , por meio de envio de boleto de cobrança Porto Alegre / RS 51 3276.9331 Rua Padre Chagas, 415 / Sala 502 contato@doacaolegal.com.br Bairro Moinhos de Vento www.doacaolegal.com.br Termos e Condições do beneficiário  e/ou qualquer outro meio de cobrança admitido, bem como através de retenção de valores futuros da campanha.</Text>
            <br />
            <Text>4.9 A SOCIALME se reserva o direito de, na hipótese do não pagamento dos valores acima mencionados, utilizar todos os meios de cobranças aceitos pela legislação brasileira e, inclusive, comunicar a inadimplência do beneficiário  aos órgãos e entidades de proteção ao crédito.</Text>
            <br />
            <Text>4.10 Sem prejuízo das normas acima, fica estabelecido que a SOCIALME poderá, por questões de segurança e gerenciamento de riscos, reter até 10% (dez por cento) do valor arrecadado em uma campanha de doação, por 90 (noventa) dias corridos a partir da data da última doação e/ou do encerramento da correspondente campanha, o que ocorrer por último.</Text>
            <br />
            <Text>4.11 A retenção mencionada acima poderá ser realizada pela SOCIALME nos casos de campanhas que, a exclusivo critério da SOCIALME, possam gerar grande repercussão e/ou estejam expostas a maiores riscos de questionamento.</Text>
            <br />
            <Text>4.12 O beneficiário  poderá, a seu exclusivo critério, recusar-se a receber doações de qualquer pessoa ou empresa a qualquer momento, fato que será comunicado à SOCIALME. Tal fato ensejará na devolução dos valores doados descontadas as taxas contratuais e eventuais custos diretos que venham a ocorrer para viabilizar a efetivação da devolução.</Text>
            <br />

            <Title level={4}>5. MODIFICAÇÃO DOS TERMOS</Title>
            <Text>A Plataforma SOCIALME se reserva o direito de, a seu exclusivo critério, modificar os Termos de Uso, ou alterar, suspender ou descontinuar o serviço, a qualquer momento através de um aviso na PLATAFORMA SOCIALME ou por outro meio de comunicação, sempre preservando os direitos dos beneficiário S.</Text>
            <br />

            <Title level={4}> 6. DO FORO</Title>
            <Text>6.1 Elegem as partes o foro de Boa Vista/ RR para dirimir quaisquer dúvidas oriundas deste instrumento particular de contrato, por mais privilegiado que outro possa ser.</Text>
            <br />
        </div>
    )
}