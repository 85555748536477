import React, { useRef } from 'react';
// Components
import SunEditor from 'suneditor-react';
import FormItem from '~/components/form/FormItem';
import Select from '~/components/select';

export default function ModelForm({
  form,
  onSelect,
  templatesList,
  defaultValue,
  value,
  setValue,
}) {
  const editor = useRef();

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  async function onPaste(event, cleanData, maxCharCount, core) {
    const stripedHtml = cleanData.replace(/<(?!\s*br\s*\/?)[^>]+>/gi, '');
    return stripedHtml;
  }

  return (
    <div>
      {/* 
            <FormItem
                label="Selecione o modelo"
                name={['model']}
                item={<Select options={templatesList} onSelect={(value) => onSelect(value)} />}
            />
            */}
      <FormItem
        label=""
        name={['body']}
        item={
          <SunEditor
            lang="pt_br"
            name="body"
            defaultValue={defaultValue}
            autoFocus
            getSunEditorInstance={getSunEditorInstance}
            setOptions={{
              height: 200,
              buttonList: [
                ['undo', 'redo'],
                ['font', 'fontSize', 'formatBlock'],
                ['paragraphStyle', 'blockquote'],
                [
                  'bold',
                  'underline',
                  'italic',
                  'strike',
                  'subscript',
                  'superscript',
                ],
                ['fontColor', 'hiliteColor', 'textStyle'],
                ['removeFormat'],
                ['outdent', 'indent'],
                ['align', 'horizontalRule', 'list', 'lineHeight'],
                ['table', 'link', 'image'],
                ['fullScreen', 'showBlocks', 'codeView'],
              ],
            }}
            onPaste={(event, cleanData, maxCharCount, core) =>
              onPaste(event, cleanData, maxCharCount, core)
            }
            //onPaste={(event, cleanData, maxCharCount, core) =>event.preventDefault()}
          />
        }
      />
    </div>
  );
}
