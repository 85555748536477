export default {
  SM00EF13A: {
    message: 'Browser token successfully created',
  },
  SM9DC07A7: {
    message: 'Successfully registered person',
  },
  SMC9DFC93: {
    message: 'The file was successfully created',
    // Profile.Avatar.Change
  },
  SM927FEDF: {
    message: 'The address was successfully created',
    // Profile.Address.Add
  },
  SM17C7C0C: {
    message: 'The contact was successfully created',
    // Profile.Contact.Add
  },
  SM9BC74C7: {
    message: 'The relationship was successfully created',
    // Profile.Relantionship.Add
  },
  SM2408E47: {
    message: 'The person pre register was successfully created',
    // Person.PreRegistration
  },
  SM2ACBEAB: {
    message: 'The qr-code was successfully created',
  },
  SM206DB5D: {
    message: 'The place was successfully created',
  },
  SM961DFCB: {
    message: "The 'Device' was successfully created",
  },
  SM7D4C2FB: {
    message: "The 'activity' was successfully created",
  },
  SM594912D: {
    message: "The 'Custom question' was successfully created",
  },
  SME60A688: {
    message: "The 'activity_frequency' was successfully created",
  },
  SM7B1AC19: {
    message: "The 'product/service offering' was successfully created",
  },
  SM81A593E: {
    message: "The 'operation intent' was successfully created",
  },
  SM74DF6E1: {
    message: "The 'Sigam org unity' was successfully created",
  },
  SM1D07707: {
    message: "The 'document model' was successfully created",
  },
  SM5114E6E: {
    message: "The 'group' was successfully created",
  },
  SMF81807B: {
    message: "The 'public agent' was successfully created",
  },
  SM5BE1A36: {
    message: "The 'grouped person' was successfully created"
  },
  SM3C4BBB1: {
    message: "The 'document' was successfully created"
  },
  SM9888707: {
    message: "The 'assignment' was successfully created"
  },
  SM5E049C8: {
    message: "The 'attachment' was successfully created"
  },
  SM2CECDEC: {
    message: "The 'signature' was successfully created"
  },
  SM406F6D4: {
    message: "The 'process kind' was successfully created"
  },
  SM4104D87: {
    message: "The 'process' was successfully created"
  },
  SM3FD6BC2: {
    message: "The 'process document' was successfully created"
  },
  SM0791575: {
    message: "The 'processing' was successfully created"
  },
  SMA0FBBB1: {
    message: "The 'Password recovery token' was successfully created"
  },
  SMFB74F94: {
    message: "The 'Sigam PERMISSION' was successfully created"
  },
  SM0FB4B7B: {
    message: "The 'Sigam Contract' was successfully created"
  }
};
