import styled from 'styled-components'

import { Pagination } from 'antd';

const MainPagination = styled(Pagination)`
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content:  flex-start;

  @media (max-width: 768px) {
    align-items: center;
    justify-content:  center;
  }

  & .ant-pagination-item-active {
    background-color: ${props => props.primary};

    a {
      color: ${props => props.textColor};
    }
  }

  & li.ant-pagination-item {
  
    border-radius:50px;
    padding:0;

    width: 35px;
    height: 35px;

    display: flex;  
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: ${props => props.primary};

      a {
        color: ${props => props.textColor};
      }
    }

  }

  & div.ant-pagination-item-container {
    background-color: ${props => props.textColor};
    border-radius: 50%;

    width: 35px;
    height: 35px;

    color: ${props => props.textColor};
        
    display: flex;
    align-items: center;
    justify-content: center;

    & .ant-pagination-item-ellipsis {
     
      color: ${props => props.primary};
    
      font-size: 14px;
      font-weight: 500;
    }

    &:hover {
      background-color: ${props => props.primary};
    }

    & span.ant-pagination-item-link-icon {
      color: ${props => props.textColor};
    }
  }

  & li.ant-pagination-next {

    a.ant-pagination-item-link {
      background-color: transparent;

      width: 35px ;
      height: 35px;
      border-radius: 50%;
      font-size: 18px;


      color: ${props => props.primary};

          
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: ${props => props.primary};
        color: ${props => props.textColor};
      }
    }
  }

  & li.ant-pagination-prev {

    a.ant-pagination-item-link {
      background-color: transparent;

      width: 35px ;
      height: 35px;
      border-radius: 50%;
      font-size: 18px;


      color: ${props => props.primary};

          
      display: flex;
      align-items: center;
      justify-content: flex-start;

      padding-left: 7px;

      &:hover {
        background-color: ${props => props.primary};
        color: ${props => props.textColor};
      }
    }
  }

  & li.ant-pagination-options {
    div.ant-select-selector {
      border: 1px solid ${props => props.primary};
      border-radius: 8px;
      color: ${props => props.primary};
      font-weight: 700;
    }

    span.ant-select-arrow {
      color: ${props => `${props.primary} !important`};
      font-weight: 700;
    }
  }

  & div.ant-pagination-options-quick-jumper{
    color: ${props => props.primary};
    font-weight: 700;

    input {
      border-radius: 8px;
      border: 1px solid ${props => props.primary};
      font-weight: 700;
    }
  }
`

export default MainPagination