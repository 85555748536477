import React, { useState, useEffect } from 'react';
// Libraries
import { useLocation, Redirect } from 'react-router-dom';
import { message, Result } from 'antd';
// Components
import Loading from '~/components/loading';
import Button from '~/components/button';
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
import Card from '~/components/card';
// Environments
import System from '~/environments/System';
import UrlParameters from '~/environments/UrlParameters';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Invalid() {
    const [loadingContent, setLoadingContent] = useState(true);
    const [tokenInvalid, setTokenInvalid] = useState(false);
    const [requestSuccessfullyDeleted, setRequestSuccessfullyDeleted] = useState(false);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const token = query.get(UrlParameters.recoveryPasswordToken);
    const personId = query.get(UrlParameters.personId);

    async function CancelToken() {
        const params = {
            operation: ['Auth', 'RedefinePassword', 'CancelToken'],
            urlParams: {
                tokenId: token,
                personId
            },
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            message.success('Solicitação apagada com sucesso!');
            setRequestSuccessfullyDeleted(true);
        } else {
            message.error('Alguma coisa aconteceu...')
        }
    }


    useEffect(() => {
        async function VerifyPasswordToken() {
            const params = {
                operation: ['Auth', 'RedefinePassword', 'VerifyToken'],
                urlParams: {
                    tokenId: token,
                    personId
                }
            };
            const response = await SocialMeAPI(params);
            if (!response.success) {
                setTokenInvalid(true);
            }
            setLoadingContent(false);
        }
        VerifyPasswordToken();
    }, [personId, token])

    if (loadingContent) {
        return (
            <div
                style={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Loading text="Verificando token" />
            </div>
        )
    }

    if (tokenInvalid) {
        return (
            <Redirect to="/" />
        )
    }

    return (
        <div
            style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Card>
                {requestSuccessfullyDeleted ?
                    <div>
                        <Result
                            status="success"
                            title={<Text strong style={{ textAlign: 'center' }}>Solicitação apagada com sucesso!</Text>}
                            extra={[
                                <a href={`https://${System.production ? System.hostname.SIGAMApp : System.hostname.SIGAMDev}`} key="link">
                                    <Button style={{ width: '100%', marginTop: 10 }} type="primary" size="large">Acessar o sistema</Button>
                                </a>
                            ]}
                        />
                    </div>
                    :
                    <div>
                        <Title level={4} style={{ textAlign: 'center' }}>Solicitação de redefinição de senha</Title>
                        <div>
                            <Button
                                style={{ width: '100%' }}
                                type="primary"
                                size="large"
                                onClick={() => CancelToken()}
                            >
                                Não fui eu que solicitei
                            </Button>
                            <a href={`https://${System.production ? System.hostname.SIGAMApp : System.hostname.SIGAMDev}/esqueci-senha/valido/?tkn=${token}&pd=${personId}`}>
                                <Button
                                    style={{ width: '100%', marginTop: 10 }}
                                >
                                    Sim, eu solicitei
                            </Button>
                            </a>

                        </div>
                    </div>
                }
            </Card>
        </div>
    )
}