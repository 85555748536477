import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export const Container = styled.div``;

export const Spinner = styled(Spin)`
  color: ${(props) => props.color};
`;

export const LoadingIcon = styled(LoadingOutlined)`
  color: ${(props) => props.color};
  font-size: 24;
`;

export const LoadContainer = styled.div`
  /* height: calc(100vh - 125px); */
  height: ${(props) => props.height || '300px'};
  display: flex;
  justify-content: center;
  align-items: center;
`;
