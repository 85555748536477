import API from 'utils/SigamAPI'

export default async function ListPublicDocuments({ filters, institutionId }) {
  const response = await API({
    method: 'POST',
    url: `/SIGAM/public/${institutionId}/documents`,
    data: filters
  })

  return response
}
