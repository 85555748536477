import SigamAPI from '~/utils/SigamAPI';

export default async function GetMessagesFromContact({
  chatId,
  institutionId,
}) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/chat/${chatId}/messages`,
    method: 'POST',
    data: {
      pagination: {
        limit: 0,
        sort: {
          by: 'created_at',
          order: 'ASC',
        },
      },
    },
  });

  return response;
}
