import API from '~/environments/APIs';

export default {
  BrowserToken: {
    Set: {
      baseURL: API.socialMe,
      url: '/auth/browser/login',
      method: 'get',
      timeout: '50000',
    },
    Check: {
      baseURL: API.socialMe,
      url: '/auth/browser/is-auth',
      method: 'get',
      timeout: '50000',
    },
  },
  UserToken: {
    Check: {
      baseURL: API.socialMe,
      url: '/auth/person/is-auth',
      method: 'post',
      timeout: '50000',
    },
  },
  Register: {
    Physical: {
      baseURL: API.socialMe,
      url: '/registration/person/physical',
      method: 'post',
      timeout: '50000',
    },
    Legal: {
      baseURL: API.socialMe,
      url: '/registration/person/legal',
      method: 'post',
      timeout: '50000',
    },
    PreRegistrationPhysical: {
      baseURL: API.socialMe,
      url: '/pre-registration/person/physical',
      method: 'post',
      timeout: '50000',
    },
  },
  Verify: {
    baseURL: API.socialMe,
    url: '/auth/person/pre-login',
    method: 'post',
    timeout: '50000',
  },
  Login: {
    baseURL: API.socialMe,
    url: '/auth/person/login',
    method: 'post',
    timeout: '50000',
  },
  Logout: {
    baseURL: API.socialMe,
    url: '/auth/person/logout',
    method: 'post',
    timeout: '50000',
  },
  RedefinePassword: {
    RequestToken: {
      baseURL: API.socialMe,
      url: '/person/forgot-password',
      method: 'post',
      timeout: '50000',
    },
    VerifyToken: {
      baseURL: API.socialMe,
      url: '/person/forgot-password/tokenId/personId',
      method: 'get',
      timeout: '50000',
    },
    CreateNewPassword: {
      baseURL: API.socialMe,
      url: '/person/forgot-password/tokenId/personId',
      method: 'post',
      timeout: '50000',
    },
    CancelToken: {
      baseURL: API.socialMe,
      url: '/person/forgot-password/tokenId/personId',
      method: 'delete',
      timeout: '50000',
    } 
  }

};
