import React, { useEffect, useState } from 'react';
// Libraries
import { useParams } from 'react-router-dom';
import { SearchOutlined, QrcodeOutlined, EyeOutlined } from '@ant-design/icons';
import { Form, Row, Col } from 'antd';
import Animate, { FadeIn } from 'animate-css-styled-components';
// Own Components
import QRCodeReaderDocumentSimple from '~/pages/SIGAM/components/qrcode/ReaderDocumentSimple';
import Visualize from '../../../../../../../../Documents/components/Actions/Visualize';
// Components
import FormItem from '~/components/form/FormItem';
import Button from '~/components/button';
import Input from '~/components/input';
import Loading from '~/components/loading';
import Card from '~/components/card';
import Text from '~/components/typography/text';
import Drawer from '~/components/drawer';
// Utils
import { CapitalizeString } from '~/utils/Transformations';
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function DocumentSearch({ setSelectedDocument }) {
    const { institutionId, organizationUnitId } = useParams();
    const [form] = Form.useForm();
    const [searchDocuments, setSearchDocuments] = useState();
    const [loadingContent, setLoadingContent] = useState(false);
    const [qrCodeId, setQRCodeId] = useState();

    useEffect(() => {
        if(qrCodeId){
            form.setFieldsValue({ internal_id: qrCodeId });
        }
    },[qrCodeId, form])

    async function onFinish(values) {
        setLoadingContent(true);
        let searchString = " "
        for (const key in values) {
            if (values[key]) {
                searchString += `${values[key]} `;
            }
        }
        const params = {
            operation: ['SIGAM', 'Documents', 'Get'],
            urlParams: {
                institutionId: institutionId,
                organizationUnitId: organizationUnitId,
            },
            data: {
                filter: {
                    status: 'ACTIVE',
                    search_string: searchString,
                },
                pagination: {
                    sort: {
                        by: 'name',
                        order: 'ASC',
                    },
                    limit: 0
                },
            },
        };
        const response = await SocialMeAPI(params)
        if (response.success) {
            setSearchDocuments(response.body.documents.elements)
        }
        return setLoadingContent(false);
    }


    function DocumentCard({ item }) {
        return (
            <div style={{ marginBottom: 10 }}>
                <Card bodyStyle={{ padding: 5 }}>
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <Text>{CapitalizeString(item.name)}</Text>
                        </Col>
                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Drawer
                                Trigger={
                                    <Button icon={<EyeOutlined />}>
                                        Visualizar
                                    </Button>
                                }
                                DrawerContentComponent={Visualize}
                                DrawerContentComponentParams={{ document: [item] }}
                                title={`${item.name}`}
                                width="100%"
                            />
                            <Button
                                type="primary"
                                onClick={() => setSelectedDocument(item)}
                                style={{ marginLeft: 10 }}
                            >
                                Selecionar Documento
                        </Button>
                        </Col>
                    </Row>
                </Card>
            </div>
        )
    }

    if (searchDocuments && searchDocuments.length > 0) {
        return (
            <Animate
                Animation={[FadeIn]}
                duration={['0.5s']}
                delay={['0.2s']}
            >
                <div>
                    <div style={{ margin: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            type="dashed"
                            onClick={() => setSearchDocuments()}
                            icon={<SearchOutlined />}
                        >
                            Fazer outra busca
                        </Button>
                    </div>
                    {searchDocuments.map(item => (
                        <DocumentCard key={item.id} item={item} />
                    ))}
                </div>
            </Animate>
        )
    }

    if (loadingContent) {
        return (
            <Animate
                Animation={[FadeIn]}
                duration={['0.5s']}
                delay={['0.2s']}
            >
                <Loading text="Buscando Documentos" />
            </Animate>
        )
    }

    return (
        <Animate
            Animation={[FadeIn]}
            duration={['0.5s']}
            delay={['0.2s']}
        >
            <Form
                labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
                wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
                form={form}
                size="small"
                layout="vertical"
                onFinish={onFinish}
                scrollToFirstError
            >
                <FormItem
                    label={'Número do documento'}
                    name={['internal_id']}
                    item={
                        <Input
                            suffix={
                                <QRCodeReaderDocumentSimple
                                    Trigger={
                                        <Button icon={<QrcodeOutlined />} type="dashed">Ler QR Code</Button>
                                    }
                                    title="Ler QR Code do Documento"
                                    description="Use seu dispositivo para ler o QR Code do documento"
                                    setValue={setQRCodeId}
                                />
                            }
                        />
                    }
                />
                <FormItem
                    label={'Nome do Documento'}
                    name={['name']}
                    item={<Input />}
                />
                <FormItem
                    item={
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                            Pesquisar
                        </Button>
                    }
                />
            </Form>
        </Animate>
    )
}