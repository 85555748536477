import React, { useContext } from 'react';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Own Components
import EditButton from './components/EditButton';
import PermissionsButton from './components/PermissionsButton';
import UsersButton from './components/UsersButton';
import RenderUsers from './components/RenderUsers';
// Components
import Card from '~/components/card';
import Table from '~/components/table';
import { PrimaryAndSecondaryTextCell } from '~/components/table/cells';
import Pagination from '~/components/pagination';
import Space from '~/components/space';

export default function Content({
  data,
  GetDataAgain,
  pageParameters,
  setUpdatePending,
}) {
  const { isMobile } = useContext(ResponsiveContext);
  const columns = [
    {
      title: 'Unidade',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <PrimaryAndSecondaryTextCell
          primaryText={name}
          secondaryText={
            record.kind === 'MAIN-ORG-UNITY' ? 'Unidade Principal' : null
          }
        />
      ),
    },
    {
      title: 'Usuários',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => (
        <RenderUsers record={record} setUpdatePending={setUpdatePending} />
      ),
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => (
        <Space
          align={isMobile ? 'center' : 'auto'}
          direction={isMobile ? 'vertical' : 'horizontal'}
        >
          <EditButton record={record} setUpdatePending={setUpdatePending} />
          <PermissionsButton
            record={record}
            setUpdatePending={setUpdatePending}
          />
          <UsersButton record={record} setUpdatePending={setUpdatePending} />
        </Space>
      ),
    },
  ];

  if (isMobile) {
    return (
      <div>
        <Table dataReceived={data} tableColumns={columns} />
        <div style={{ padding: 10 }}>
          <Pagination
            current={pageParameters.currentPage}
            pageSize={pageParameters.limit}
            onChange={(currentPage, limit) =>
              GetDataAgain({ currentPage, limit })
            }
            onShowSizeChange={(currentPage, limit) =>
              GetDataAgain({ currentPage, limit })
            }
            total={pageParameters.totalElements}
            showSizeChanger
            showQuickJumper={false}
            showTotal={(total) => `Total ${total} itens`}
          />
        </div>
      </div>
    );
  }

  return (
    <Card bodyStyle={{ padding: '10px' }}>
      <Table dataReceived={data} tableColumns={columns} />
      <div style={{ padding: 10 }}>
        <Pagination
          current={pageParameters.currentPage}
          pageSize={pageParameters.limit}
          onChange={(currentPage, limit) =>
            GetDataAgain({ currentPage, limit })
          }
          onShowSizeChange={(currentPage, limit) =>
            GetDataAgain({ currentPage, limit })
          }
          total={pageParameters.totalElements}
          showSizeChanger
          showQuickJumper={false}
          showTotal={(total) => `Total ${total} itens`}
        />
      </div>
    </Card>
  );
}
