import React, { useContext, useState, createContext, useEffect } from 'react';
//Environments
import { LightTheme, DarkTheme, SIGAMTheme } from '~/environments/Themes';
import System from '~/environments/System';
import Debug from '~/environments/Debug';
//Utils
import Cookie from '~/utils/Cookie';

const isDebug = Debug.Context.Theme;

export const ThemeContext = createContext();

const ThemeContextProvider = ({ children }) => {
  const [theme, setTheme] = useState();
  const [activeTheme, setActiveTheme] = useState('SIGAMTheme');

  useEffect(() => {
    function GetCookieValue() {
      switch (activeTheme) {
        case 'SIGAMTheme':
          return 'SIGAM';
        case 'DarkTheme':
          return 'Dark';
        case 'LightTheme':
        default:
          return 'Light';
      }
    }
    function GetTheme() {
      switch (activeTheme) {
        case 'SIGAMTheme':
          return SIGAMTheme;
        case DarkTheme:
          return 'Dark';
        case 'LightTheme':
        default:
          return LightTheme;
      }
    }
    async function SetCookie() {
      let creatingTheCookie = await Cookie.Create({
        name: System.cookie.name.theme,
        value: GetCookieValue(),
      });
      if (creatingTheCookie) {
        if (isDebug) console.log('  ✔ Theme cookie successfully created');
        return setTheme(GetTheme());
      } else {
        if (isDebug) console.log('  ❗ Error creating Theme Cookie');
      }
    }
    SetCookie();
  }, [activeTheme]);

  /*
  useEffect(() => {
    setTimeout(function () {
      setActiveTheme('DarkTheme');
    }, 3000);
  }, []);
  */

  const ChangeTheme = (newTheme) => {
    setActiveTheme(newTheme);
  };

  const ToggleTheme = () => {
    if (activeTheme === 'LightTheme') {
      return setActiveTheme('DarkTheme');
    } else {
      return setActiveTheme('LightTheme');
    }
  };

  return (
    <ThemeContext.Provider
      value={{ ChangeTheme, ToggleTheme, activeTheme, theme }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;

export const useTheme = () => useContext(ThemeContext);
