import React, { useEffect, useState, useRef } from 'react';
// Libraries
import { useParams } from 'react-router-dom';
// Components
import SunEditor from 'suneditor-react';
import FormItem from '~/components/form/FormItem';
import Select from '~/components/select';
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function ModelForm({ form }) {
  const { institutionId } = useParams();
  const [loadingContent, setLoadingContent] = useState(true);
  const [templatesList, setTemplatesList] = useState();
  const [defaultValue, setDefaultValue] = useState();
  const editor = useRef();

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  async function onPaste(event, cleanData, maxCharCount, core) {
    const stripedHtml = cleanData.replace(/<(?!\s*br\s*\/?)[^>]+>/gi, '');
    return stripedHtml;
  }

  async function onSelect(id) {
    setLoadingContent(true);
    const params = {
      operation: ['SIGAM', 'DocumentTemplates', 'Show'],
      urlParams: {
        institutionId,
        modelId: id,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      setDefaultValue(response.body.documentModel.body);
      form.setFieldsValue({ body: response.body.documentModel.body });
    }
    setLoadingContent(false);
  }

  useEffect(() => {
    async function GetDocumentTemplates() {
      const params = {
        operation: ['SIGAM', 'DocumentTemplates', 'Get'],
        urlParams: {
          institutionId,
        },
        data: {
          pagination: {
            sort: {
              by: 'name',
              order: 'asc',
            },
            limit: 0,
          },
          filter: {
            status: 'ACTIVE',
          },
        },
      };
      const response = await SocialMeAPI(params);
      // console.log(response)
      if (response.success) {
        const array = response.body.documentModels.elements;
        const newArray = [];
        for (let i = 0; i < array.length; i++) {
          const newItem = {};
          newItem.label = array[i].name;
          newItem.value = array[i].id;
          newArray.push(newItem);
        }
        setTemplatesList(newArray);
      }
      setLoadingContent(false);
    }
    GetDocumentTemplates();
  }, [institutionId]);

  if (loadingContent) {
    return <Loading withoutText height="0px" />;
  }

  return (
    <div>
      <FormItem
        label="Selecione o modelo"
        name={['model']}
        rules={[
          {
            required: true,
            message: 'Selecione um Modelo',
          },
        ]}
        item={
          <Select
            options={templatesList}
            noDefaultValue
            onSelect={(value) => onSelect(value)}
          />
        }
      />
      {defaultValue && (
        <FormItem
          label=""
          name={['body']}
          item={
            <SunEditor
              lang="pt_br"
              name="body"
              defaultValue={defaultValue}
              onChange={(contents, core) =>
                form.setFieldsValue({ body: contents })
              }
              autoFocus
              getSunEditorInstance={getSunEditorInstance}
              setOptions={{
                height: 200,
                buttonList: [
                  ['undo', 'redo'],
                  ['font', 'fontSize', 'formatBlock'],
                  ['paragraphStyle', 'blockquote'],
                  [
                    'bold',
                    'underline',
                    'italic',
                    'strike',
                    'subscript',
                    'superscript',
                  ],
                  ['fontColor', 'hiliteColor', 'textStyle'],
                  ['removeFormat'],
                  ['outdent', 'indent'],
                  ['align', 'horizontalRule', 'list', 'lineHeight'],
                  ['table', 'link', 'image'],
                  ['fullScreen', 'showBlocks', 'codeView'],
                ],
              }}
              onPaste={(event, cleanData, maxCharCount, core) =>
                onPaste(event, cleanData, maxCharCount, core)
              }
            />
          }
        />
      )}
    </div>
  );
}
