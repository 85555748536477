import React, { useContext, memo } from 'react';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { ThemeContext } from '~/contexts/ThemeContext';
import { LanguageContext } from '~/contexts/LanguageContext';
// Libraries
import {
  TextAreaInput,
  Container,
  LabelContainer,
  RequiredSpan,
} from './style';

function AntTextArea({ 
  disabled,
  placeholder,
  size,
  width,
  margin,
  value,
  onChange,
  onFocus,
  onBlur,
  maxLength,
  label,
  outFormItem,
  required,
  autosize,
}) {
  const { isMobile } = useContext(ResponsiveContext);
  const { theme } = useContext(ThemeContext);
  const { translate } = useContext(LanguageContext);
  return (
    <Container style={{ position: 'relative' }}>
      <TextAreaInput
        disabled={!!disabled}
        size={isMobile ? 'large' : size ? size : 'default'}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        type='textarea'
        margin={margin}
        maxLength={maxLength}
        width={width}
        color={theme.color.textOnSecondaryBackground}
        backgroundcolor={theme.color.backgroundSecondary}
        autosize={autosize}
      />
      <LabelContainer
        style={{
          backgroundColor: theme.color.backgroundSecondary,
          top: outFormItem ? '-10px' : '-5px',
        }}
      >
        {label}
        {required && (
          <RequiredSpan color={theme.color.danger}>
            ({translate.basic.required.text})
          </RequiredSpan>
        )}
      </LabelContainer>
    </Container>

  );
}

export default memo(AntTextArea);
