import React, { useContext, useEffect, useMemo, useState } from 'react';
import ArchiveFilesPageManagerContext from './Context';

import _ShowArchive from '~/services/calls/archive/show';
import _ReadFiles from '~/services/calls/archive/files/read';
import _DeleteFile from '~/services/calls/archive/files/delete';
import _CreateFile from '~/services/calls/archive/files/create';
import _UpdateFile from '~/services/calls/archive/files/update';
import _ShowFile from '~/services/calls/archive/files/show';
import _UploadFile from '~/services/calls/archive/files/upload';
import { useParams } from 'react-router-dom';
import Loading from '~/components/loading';

export default function ArchiveFilesPageManagerProvider({ children }) {
  const initialFilter = useMemo(() => {
    return {
      name: '',
      description: '',
      meta1: '',
      meta2: '',
      meta3: '',
      meta4: '',
      meta5: '',
      meta6: '',
    };
  }, []);

  const [loadingArchive, setLoadingArchive] = useState(true);
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [files, setFiles] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [archive, setArchive] = useState();
  const [filter, setFilter] = useState(initialFilter);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });

  const { archiveId, institutionId } = useParams();

  function setMetatagsOnArchive(currentArchive) {
    let metas = [];

    for (const key in currentArchive) {
      const isMetadata = key.includes('meta');
      if (isMetadata && currentArchive[key] !== null) {
        metas.push(currentArchive[key]);
      }
    }

    currentArchive.metas = metas;

    return currentArchive;
  }

  async function ShowArchive() {
    setLoadingArchive(true);

    const response = await _ShowArchive({ archiveId, institutionId });

    if (response.success) {
      const formatedArchive = setMetatagsOnArchive(response.body.archive);

      setArchive(formatedArchive);
    }

    setLoadingArchive(false);
  }

  async function CreateFile(values) {
    const response = await _CreateFile({
      file: values,
      archiveId,
      institutionId,
    });

    if (response.success) {
      setTotalItems(totalItems + 1);
      setFiles((currentFiles) => {
        return [response.body.archive_file, ...currentFiles];
      });
    }

    return response;
  }

  async function UpdateFile({ file, fileId }) {
    const response = await _UpdateFile({
      file,
      fileId,
      archiveId,
      institutionId,
    });

    if (response.success) {
      setFiles((currentFiles) => {
        const newFiles = currentFiles.map((currentFile) => {
          if (currentFile.id !== fileId) return currentFile;

          return response.body.archive_file;
        });

        return newFiles;
      });
    }

    return response;
  }

  async function DeleteFile(fileId) {
    const response = await _DeleteFile({ fileId, archiveId, institutionId });

    if (response.success) {
      setFiles((currentFiles) => {
        return currentFiles.filter((currentFile) => {
          return currentFile.id !== fileId;
        });
      });
    }
  }

  async function ShowFile(fileId) {
    const response = await _ShowFile({ fileId, archiveId, institutionId });

    return response;
  }

  async function ReadFiles(filter, pagination) {
    setLoadingFiles(true);

    const filters = {
      filter,
      pagination,
    };

    const response = await _ReadFiles({ filters, archiveId, institutionId });

    if (response.success) {
      const { elements, totalElements } = response.body.archive_files;

      setFiles(elements);
      setTotalItems(totalElements);
    }

    setLoadingFiles(false);
  }

  useEffect(() => {
    ShowArchive();
  }, []);

  useEffect(() => {
    ReadFiles(filter, pagination);
  }, [filter, pagination]);

  if (loadingArchive) {
    return <Loading text="Carregando acervo" height="300px" />;
  }

  return (
    <ArchiveFilesPageManagerContext.Provider
      value={{
        initialFilter,
        filter,
        setFilter,
        totalItems,
        loadingArchive,
        loadingFiles,
        files,
        archive,
        institutionId,
        pagination,
        setPagination,
        ReadFiles,
        CreateFile,
        DeleteFile,
        UpdateFile,
        ShowFile,
      }}
    >
      {children}
    </ArchiveFilesPageManagerContext.Provider>
  );
}

export const useArchiveFilesPageManager = () =>
  useContext(ArchiveFilesPageManagerContext);
