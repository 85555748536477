import SigamAPI from '~/utils/SigamAPI';

export default async function CreateMessage({ institutionId, data, method }) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/communication/${method.toLowerCase()}`,
    method: 'POST',
    data: data,
  });

  return response;
}
