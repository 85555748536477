import { message } from 'antd';
import SigamAPI from '~/utils/SigamAPI';

export default async function ShowArchive({ archiveId, institutionId }) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/archive/${archiveId}`,
    method: 'GET',
  });

  if (!response.success) {
    message.error('Erro ao pegar o acervo');
  }

  return response;
}
