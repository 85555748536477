import styled from 'styled-components';

export const Container = styled.div`
  padding: 12px;
  height: ${(style) => style.height};
  max-height: ${(style) => style.height};
  background-color: #f0f0f0;
  overflow-y: auto !important;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: #c3c3c3;
`;
