import React from 'react';
// Libraries
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/locale/pt_BR';
// Contexts
import { pdfjs } from 'react-pdf';
import AuthContextProvider from '~/contexts/AuthContext';
import BrowserContextProvider from '~/contexts/BrowserContext';
import ContractsContextProvider from '~/contexts/ContractsContext';
import GatewayContextProvider from '~/contexts/GatewayContext';
import InterfaceContextProvider from '~/contexts/InterfaceContext';
import LanguageContextProvider from '~/contexts/LanguageContext';
import ResponsiveContextProvider from '~/contexts/ResponsiveContext';
import SidebarContextProvider from '~/contexts/SidebarContext';
import SIGAMContextProvider from '~/contexts/SIGAMContext';
import ThemeContextProvider from '~/contexts/ThemeContext';
import WalletAccountContextProvider from '~/contexts/WalletAccountContext';
import PermissionContextProvider from '~/contexts/PermissionContext';
// Assets
import '~/assets/css/antDesignCustom.css';
import './index.css';
// Pages
import Page from '~/pages/SIGAM';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

//! OPÇÃO DE EDITAR
//! VISUALIZAR DOCUMENTO QUANDO FOR DO TIPO EXTERNAL

export default function App() {
  return (
    <Router>
      <ConfigProvider
        locale={ptBR}
        componentSize="middle"
        space="middle"
        input={{ size: 'middle' }}
        theme={{
          token: {
            borderRadius: 6,
            colorPrimary: '#153D6E',
            //fontSize: 14,
            //fontSizeHeading1: 34,
            //fontSizeHeading2: 26,
            //fontSizeHeading3: 20,
            //fontSizeHeading4: 16,
            //fontSizeHeading5: 12,
          },
        }}
      >
        <BrowserContextProvider>
          <LanguageContextProvider>
            <ResponsiveContextProvider>
              <ThemeContextProvider>
                <AuthContextProvider>
                  <WalletAccountContextProvider>
                    <InterfaceContextProvider>
                      <GatewayContextProvider>
                        <ContractsContextProvider>
                          <SidebarContextProvider>
                            <SIGAMContextProvider>
                              <PermissionContextProvider>
                                <Switch>
                                  <Route path="/" component={Page} />
                                </Switch>
                              </PermissionContextProvider>
                            </SIGAMContextProvider>
                          </SidebarContextProvider>
                        </ContractsContextProvider>
                      </GatewayContextProvider>
                    </InterfaceContextProvider>
                  </WalletAccountContextProvider>
                </AuthContextProvider>
              </ThemeContextProvider>
            </ResponsiveContextProvider>
          </LanguageContextProvider>
        </BrowserContextProvider>
      </ConfigProvider>
    </Router>
  );
}
