import created from './success/created';
import deleted from './success/deleted';
import edited from './success/edited';
import read from './success/read';
import validated from './success/validated';

export default {
  created,
  deleted,
  edited,
  read,
  validated,
};
