import { message } from 'antd';
import SigamAPI from '~/utils/SigamAPI';

export default async function CreateArchiveFile({
  file,
  archiveId,
  institutionId,
}) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/archive/${archiveId}/file`,
    method: 'POST',
    data: file,
  });

  console.log('response', response);

  if (response.success) {
    message.success('Arquivo criado com sucesso');
  } else {
    message.error('Erro ao criar o arquivo');
  }

  return response;
}
