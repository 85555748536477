import React, { useState } from 'react';
// Own Components
import OrganizationalUnitsCell from './components/OrganizationalUnitsCell';
import UserButton from './components/UserButton';
// Components
import Card from '~/components/card';
import Table from '~/components/table';
import { ImageWithPrimaryAndSecondaryTextCell } from '~/components/table/cells';
import Pagination from '~/components/pagination';
// Utils
import { CapitalizeString, DecodeURI } from '~/utils/Transformations';

export default function Content({
  data,
  GetDataAgain,
  pageParameters,
  setUpdatePending,
}) {
  const [activeId, setActiveId] = useState();
  const [renderOrganizationalUnits, setRenderOrganizationalUnits] =
    useState(false);
  const [renderGroups, setRenderGroups] = useState(false);

  function userKind(user) {
    if (user.is_admin && user.is_public_agent) {
      return 'Administrador / Agente Público';
    } else if (user.is_admin) {
      return 'Administrador';
    } else if (user.is_public_agent) {
      return 'Agente Público';
    }
  }

  const columns = [
    {
      title: 'Usuário',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <ImageWithPrimaryAndSecondaryTextCell
          src={record.avatar ? DecodeURI(record.avatar) : ''}
          primaryText={CapitalizeString(record.social_name || name)}
          secondaryText={userKind(record)}
        />
      ),
    },
    {
      title: 'Unidades Organizacionais',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <OrganizationalUnitsCell
          id={id}
          activeId={activeId}
          renderOrganizationalUnits={renderOrganizationalUnits}
          setRenderOrganizationalUnits={setRenderOrganizationalUnits}
        />
      ),
    },
    /*
    {
      title: 'Grupos',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <GroupsCell id={id} activeId={activeId} renderGroups={renderGroups} setRenderGroups={setRenderGroups} />,
    },
    */
    {
      title: 'Ações',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => (
        <UserButton
          record={record}
          setUpdatePending={setUpdatePending}
          setActiveId={setActiveId}
          setRenderOrganizationalUnits={setRenderOrganizationalUnits}
          setRenderGroups={setRenderGroups}
        />
      ),
    },
  ];
  return (
    <Card bodyStyle={{ padding: 10 }}>
      <Table dataReceived={data} tableColumns={columns} />
      <div style={{ padding: 10 }}>
        <Pagination
          current={pageParameters.currentPage}
          pageSize={pageParameters.limit}
          onChange={(currentPage, limit) =>
            GetDataAgain({ currentPage, limit })
          }
          onShowSizeChange={(currentPage, limit) =>
            GetDataAgain({ currentPage, limit })
          }
          total={pageParameters.totalElements}
          showSizeChanger
          showQuickJumper={false}
          showTotal={(total) => `Total ${total} itens`}
        />
      </div>
    </Card>
  );
}
