import React, { useEffect, useContext, useState, useCallback } from 'react';
import PageManagerContext from './Context';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import _readSettings from '~/services/calls/communication/settings/read';
import _updateSettings from '~/services/calls/communication/settings/update';

export default function PageManagerProvider({ children }) {
  const { institutionId } = useParams();
  const [loadingContent, setLoadingContent] = useState(true);
  const [initialValues, setInitialValues] = useState('');

  async function updateSettings({ institutionId, values }) {
    const response = await _updateSettings({ institutionId, data: values });
    if (response.success) {
      message.success('Configurações salvas com sucesso!');
    }
    return response;
  }

  const readSettings = useCallback(async () => {
    setLoadingContent(true);
    const response = await _readSettings({ institutionId });
    if (response.success) {
      setInitialValues(response.body.settings);
    }
    setLoadingContent(false);
  }, []);

  useEffect(() => {
    async function readAsyncData() {
      readSettings();
    }
    if (institutionId) {
      readAsyncData();
    }
  }, []);

  return (
    <PageManagerContext.Provider
      value={{ loadingContent, initialValues, updateSettings }}
    >
      {children}
    </PageManagerContext.Provider>
  );
}

export const usePageManager = () => useContext(PageManagerContext);
