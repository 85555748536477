import React from 'react';
// Own Components
import Interested from './components/Interested';
// Components
import Card from '~/components/card';
import Table from '~/components/table';
import { PrimaryAndSecondaryTextCell, BadgeCell } from '~/components/table/cells';
import Pagination from '~/components/pagination';
import Tooltip from '~/components/tooltip';

export default function Content({ 
  data, 
  GetDataAgain, 
  pageParameters,
  setselectedRow,
}) {

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tooltip title={status === 'ACTIVE' ? 'Processo Ativo' : 'Processo Inativo'}>
          <div>
            <BadgeCell
              status={status === 'ACTIVE' ? 'success' : 'error'}
              text={status === 'ACTIVE' ? 'Ativo' : 'Inativo'}
            />
          </div>
        </Tooltip>
      )
    },
    {
      title: 'Processo',
      dataIndex: 'internal_id',
      key: 'internal_id',
      render: (internal_id, record) => (
        <PrimaryAndSecondaryTextCell primaryText={record.name} secondaryText={`${internal_id.split('-')[1]}-${internal_id.split('-')[2]}`} />
      ),
    },
    {
      title: 'Interessados',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <Interested id={id} />
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setselectedRow(selectedRows);
      //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  };

  return (
    <Card bodyStyle={{ padding: '10px 0px' }}>
      <Table
        dataReceived={data}
        tableColumns={columns}
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
      />
      <div style={{ padding: 10 }}>
        <Pagination
          current={pageParameters.currentPage}
          pageSize={pageParameters.limit}
          onChange={(currentPage, limit) =>
            GetDataAgain({ currentPage, limit })
          }
          onShowSizeChange={(currentPage, limit) =>
            GetDataAgain({ currentPage, limit })
          }
          total={pageParameters.totalElements}
          showSizeChanger
          showQuickJumper={false}
          showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} registros`}
        />
      </div>
    </Card>
  );
}
