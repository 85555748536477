import React, { useState, useEffect, useContext } from 'react';
// Libraries
import { Redirect, useRouteMatch, Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import { FiFolder, FiUser } from 'react-icons/fi';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Components
import Loading from '~/components/loading';
import Sidebar from '~/pages/SIGAM/components/sidebar';
import MainNavbar from '~/pages/SIGAM/components/navbar';
// Pages
import Documents from './pages/Documents';
import Processes from './pages/Processes';
import Archives from './pages/Archives';
import ArchiveFiles from './pages/ArchiveFiles';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

const { Content } = Layout;

export default function Dashboard() {
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);
  const [belongsToUnit, setBelongsToUnit] = useState(false);
  const [loadingContent, setLoadingContent] = useState(true);
  const { path, url, params } = useRouteMatch();

  const sidebarItems = [
    {
      index: 0,
      title: 'First',
    },
    {
      index: 1,
      title: 'Processos',
      url: `${url}/processos`,
      icon: FiUser,
    },
    {
      index: 2,
      title: 'Documentos',
      url: `${url}/documentos`,
      icon: FiFolder,
    },
    /*
    {
      index: 3,
      title: 'Acervos',
      url: `${url}/acervos`,
      icon: FiFolder,
    },
    */
    {
      index: 3,
      title: 'Last',
    },
  ];

  useEffect(() => {
    async function ChecksIfYouArePublicAgent(
      institutionId,
      organizationUnitId
    ) {
      const params = {
        operation: ['SIGAM', 'OrganizationalUnits', 'Get'],
        urlParams: {
          institutionId,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        if (response.body.orgUnities.elements.length > 0) {
          const array = response.body.orgUnities.elements;
          for (let i = 0; i < array.length; i++) {
            if (Number(organizationUnitId) === Number(array[i].id)) {
              setBelongsToUnit(true);
            }
          }
        }
        return setLoadingContent(false);
      }
    }
    if (params) {
      const { institutionId } = params;
      const { organizationUnitId } = params;
      ChecksIfYouArePublicAgent(institutionId, organizationUnitId);
    }
  }, [params]);

  if (loadingContent) {
    return <Loading text="Verificando permissão" height="100vh" />;
  }

  if (!belongsToUnit) {
    return <Redirect to={`/${params.institutionId}/agente-publico`} />;
  }
  return (
    <div>
      <Layout
        style={{
          backgroundColor: theme.color.backgroundPrimary,
          height: '100vh',
        }}
      >
        <Sidebar items={sidebarItems} />
        <Layout
          style={{
            padding: isMobile ? 0 : 10,
            backgroundColor: theme.color.backgroundSecondary,
          }}
        >
          <MainNavbar />
          <Content
            style={{
              padding: '10px 5px',
              overflow: isMobile ? 'auto' : 'hidden',
            }}
          >
            <Switch>
              <Route exact path={`${path}`}>
                <Redirect to={`${url}/processos`} />
              </Route>
              <Route path={`${path}/processos`} component={Processes} />
              <Route path={`${path}/documentos`} component={Documents} />
              <Route
                path={`${path}/acervos/:archiveId`}
                component={ArchiveFiles}
              />
              <Route path={`${path}/acervos`} component={Archives} />
              <Route path={`${path}/*`}>
                <Redirect to={`${url}/documentos`} />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
