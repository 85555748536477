import React, { useMemo, useState, useEffect } from 'react';

import { Typography } from 'antd';

import { useMessagerManager } from '../../../../contexts/MessagerManager/Provider';
import useChatParticipants from '../../hooks/useChatParticipants';
import { useTheme } from '~/contexts/ThemeContext';
import DropdownIcon from '../../components/DropdownIcon';
import Loading from '~/components/loading';
import ContactToChatGroupItem from '../../components/ContactToChatGroupItem';

import * as S from './style';

const { Title, Text } = Typography;

export default function ChatDetails() {
  const { theme } = useTheme();

  const { view, setView, selectedChat } = useMessagerManager();

  const { chatParticipants, setChatParticipants, loadingChatParticipants } =
    useChatParticipants(selectedChat);

  const textStyle = useMemo(() => {
    return {
      fontSize: '1rem',
      fontWeight: 'bold',
      lineHeight: '100%',
      color: theme.color.white,
    };
  }, [theme]);

  const chatParticipantsIds = useMemo(() => {
    return chatParticipants.map(({ person_id }) => person_id);
  }, [chatParticipants]);

  return (
    <S.Container>
      <S.Header style={{ backgroundColor: theme.color.primary }}>
        <S.Stack>
          <Text style={{ color: theme.color.secondaryDark }}>
            Detalhes do grupo de conversa
          </Text>
          <Text style={textStyle}>{selectedChat.name}</Text>
        </S.Stack>

        <DropdownIcon view={view} setView={setView} />
      </S.Header>

      <S.TitleBox>
        <Title level={3}>{selectedChat.name}</Title>
      </S.TitleBox>

      {loadingChatParticipants ? (
        <Loading text="Carregando participantes" height="300px" />
      ) : (
        <>
          {chatParticipants.map((contact) => (
            <ContactToChatGroupItem
              key={contact.id}
              isContactInGroup
              chatParticipantsIds={chatParticipantsIds}
              setChatParticipants={setChatParticipants}
              contact={contact}
            />
          ))}
        </>
      )}
    </S.Container>
  );
}
