import React, { useEffect, useState } from 'react';
// Libraries
import { useParams } from 'react-router-dom';
// Own Components
import Header from './components/Header';
import FilesList from './components/FilesList';
// Components
import Card from '~/components/card';
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function CentralPrint({ document, propertys }) {
  const { institutionId, organizationUnitId } = useParams();
  const [completeDocumentInformation, setCompleteDocumentInformation] =
    useState([]);
  const [loadingContent, setLoadingContent] = useState(true);

  const documentInformation = document || propertys.document[0];

  useEffect(() => {
    async function GetDocument() {
      setLoadingContent(true);
      setCompleteDocumentInformation();
      const params = {
        operation: ['SIGAM', 'Documents', 'Show'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: documentInformation.id,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        // console.log(response.body.process)
        setCompleteDocumentInformation(response.body.document);
      }
      setLoadingContent(false);
    }
    GetDocument();
  }, [institutionId, organizationUnitId, documentInformation]);

  if (loadingContent) {
    return <Loading text="Carregando documento" />;
  }

  return (
    <div>
      <Header document={completeDocumentInformation} />

      <Card style={{ marginTop: '20px' }}>
        <FilesList document={completeDocumentInformation} />
      </Card>
    </div>
  );
}
