import React from 'react';
// Libraries
import { Switch } from 'antd';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import TextArea from '~/components/textArea';
import Checkbox from '~/components/checkbox';
// Own Components
import ExternalForm from './components/ExternalForm';
import ManualForm from './components/ManualForm';
import ModelForm from './components/ModelForm';

export default function AddForm({ documentType, form }) {
    return (
        <div>
            {documentType &&
                <>
                    <FormItem
                        label={'Nome do Documento'}
                        name={['name']}
                        rules={[
                            {
                                required: true,
                                message: 'Insira um nome para o documento',
                            },
                        ]}
                        item={<Input />}
                    />
                    <FormItem
                        label={'Assunto'}
                        name={['subject']}
                        item={<Input />}
                    />
                    <FormItem
                        label={'Descrição'}
                        name={['description']}
                        item={<TextArea />}
                    />
                    <FormItem
                        label={'Especificações'}
                        name={['specifications']}
                        item={<TextArea />}
                    />
                    <FormItem
                        label={'Documento Prioritário'}
                        name={['priority']}
                        valuePropName="checked"
                        item={<Switch />}
                    />
                    <FormItem
                        label="Situação"
                        name="status"
                        valuePropName="checked"
                        item={<Checkbox text={'Documento ativo'} />}
                    />
                </>
            }
            {documentType === 'InBlank' &&
                <ManualForm form={form} />
            }
            {documentType === 'External' &&
                <ExternalForm form={form} />
            }
            {documentType === 'Model' &&
                <ModelForm form={form} />
            }
        </div>
    )
}