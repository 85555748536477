import React, { createContext, useState } from 'react';
// Environments
import System from '~/environments/System';
import Debug from '~/environments/Debug';
// Utils
import Cookie from '~/utils/Cookie';
import SocialMeAPI from '~/utils/SocialMeAPI';

const isDebug = Debug.Context.Wallet;

export const WalletAccountContext = createContext();

const WalletAccountContextProvider = ({ children }) => {
  const [walletAccountNumber, setWalletAccountNumber] = useState();
  const [walletAccount, setWalletAccount] = useState();
  const [walletPassword, setWalletPassword] = useState();
  //const [verifyToken, setVerifyToken] = useState(false);
  const [walletTokenValid, setWalletTokenValid] = useState(false);

  // CheckWalletToken
  /*
  useEffect(() => {
    async function CheckWalletToken() {
      if (isDebug) console.log('🚀 API: Check wallet token');
      const params = {
        operation: ['Wallet', 'Accounts', 'CheckToken'],
      };
      const response = await SocialMeAPI(params);
      console.log(response);
      if (isDebug) console.log('  ⚡ API Response: ', response);
      if (response.success) {
        if (isDebug) console.log('    ✔ Valid wallet token');
        setWalletAccount(response.body.account);
        setWalletTokenValid(true);
      } else {
        if (isDebug) console.log('    ❌ Invalid wallet token');
        if (isDebug) console.log('    🍪❌ Deleting wallet token');
        await Cookie.Delete(System.cookie.name.wallet);
        setWalletTokenValid(false);
      }
      setVerifyToken(true);
    }
    async function VerifyWalletToken() {
      if (isDebug) console.log('🔍 Searching for wallet token');
      const WalletTokenCookies = await Cookie.Get(System.cookie.name.wallet);
      if (WalletTokenCookies) {
        if (isDebug) console.log('  ✔ Wallet token found');
        CheckWalletToken();
      } else {
        if (isDebug) console.log('  ❌ Wallet token not found');
        setVerifyToken(true);
      }
    }
    VerifyWalletToken();
  }, []);
  */

  async function FillAccount(account) {
    if (isDebug) console.log('📌 Fill Account Information');
    if (isDebug) console.log('  Account: ', account);
    setWalletAccount(account);
    const accountNumber = `${account.account_number}${account.account_vd}`;
    if (isDebug) console.log('  Account: ', accountNumber);
    setWalletAccountNumber(accountNumber);
  }

  async function WalletLogin(password) {
    if (isDebug) console.log('🚀 API: Wallet login');
    const params = {
      operation: ['Wallet', 'Accounts', 'Login'],
      urlParams: {
        agencyNumber: walletAccount.agency_number,
        accountNumber: walletAccountNumber,
      },
      data: {
        account_number: walletAccount.account_number,
        account_vd: walletAccount.account_vd,
        agency_number: walletAccount.agency_number,
        password,
      },
    };
    const response = await SocialMeAPI(params);
    console.log('login -> ', response);
    if (isDebug) console.log('  ⚡ API Response: ', response);
    if (response.success) {
      setWalletPassword(password);
      await setWalletAccount(response.body.account);
      if (isDebug) console.log('    🍪 Creating wallet cookie');
      Cookie.Create({
        name: System.cookie.name.wallet,
        value: response.body.wllttkn,
      });
      setWalletTokenValid(true);
      return response;
    }
    return response;
  }

  return (
    <WalletAccountContext.Provider
      value={{
        FillAccount,
        walletAccount,
        WalletLogin,
        walletPassword,
        setWalletPassword,
        walletTokenValid,
        walletAccountNumber,
      }}
    >
      {children}
    </WalletAccountContext.Provider>
  );
};

export default WalletAccountContextProvider;
