import React, { useState, useEffect } from 'react';
// Libraries
import { Timeline } from 'antd';
import { useParams } from 'react-router-dom';
// Own Components
import HistoricItem from './components/HistoricItem';
// Components
import Loading from '~/components/loading';
import Text from '~/components/typography/text';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { SetupSIGAMTimeline } from '~/utils/Parsers';

export default function Historic({ document, propertys }) {
    const { institutionId, organizationUnitId } = useParams();
    const [history, setHistory] = useState([]);
    const [loadingContent, setLoadingContent] = useState(true);

    const documentInformation = document || propertys.document[0];

    useEffect(() => {
        async function GetHistoric() {
            const params = {
                operation: ['SIGAM', 'Documents', 'GetHistory'],
                urlParams: {
                    institutionId,
                    organizationUnitId,
                    documentId: documentInformation.id
                },
                data: {
                    filter: {
                        not_action: 'ACCESS'
                    },
                    pagination: {
                        sort: {
                            by: 'created_at',
                            order: 'ASC'
                        },
                        limit: 0
                    }
                }
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                const timelineArray = await SetupSIGAMTimeline(response.body.timeline.elements);
                setHistory(timelineArray);
            }
            setLoadingContent(false);
        }
        GetHistoric();
    }, [institutionId, organizationUnitId, documentInformation])

    if (loadingContent) {
        return <Loading text="Carregando histórico" />
    }

    return (
        <div>
            <Timeline>
                {history.map(item => (
                    <Timeline.Item key={item.day}>
                        <Text strong>{item.day}</Text>
                        {item.eventsOfTheDay.map(eventOfDay => (
                            <div key={eventOfDay.hour} style={{ marginBottom: 10 }}>
                                <Text>{eventOfDay.hour}</Text>
                                {eventOfDay.eventsOfTheHour.map((eventOfHour,index) => (
                                    <HistoricItem key={`${item.day}-${eventOfDay.hour}-${index}`} item={eventOfHour} />
                                ))}
                            </div>
                        ))}
                    </Timeline.Item>
                ))}
            </Timeline>
        </div>
    )
}