import React, { useState, useMemo } from 'react';
import { Button } from 'antd';

import UpdateChatParticipants from '~/services/calls/messager/participants/update';
import { useMessagerManager } from '../../../../contexts/MessagerManager/Provider';
import ContactContent from '../ContactContent';
import * as S from './style';

export default function ContactToChatGroupItem({
  chatParticipantsIds = [],
  setChatParticipants,
  isContactInGroup,
  contact,
}) {
  const [loading, setLoading] = useState(false);

  const contactId = contact.person_id || contact.id;

  const { institutionId, selectedChat } = useMessagerManager();

  const chatId = useMemo(() => {
    return selectedChat.id;
  }, [selectedChat]);

  async function FormatContactToParticipant(contact) {
    contact.person_avatar = contact.avatar;
    contact.person_name = contact.name;
    contact.person_id = contact.id;

    return contact;
  }

  async function HandleAddParticipant() {
    setLoading(true);

    const participants = [...chatParticipantsIds, contact.id];

    const response = await UpdateChatParticipants({
      participants,
      chatId,
      institutionId,
    });

    if (response.success) {
      const formatedContact = await FormatContactToParticipant(contact);
      setChatParticipants((currentChartParticipants) => {
        return [...currentChartParticipants, formatedContact];
      });
    }

    setLoading(false);
  }

  async function HandleRemoveParticipant() {
    setLoading(true);

    const participants = chatParticipantsIds.filter((id) => {
      return id !== contactId;
    });

    const response = await UpdateChatParticipants({
      participants,
      chatId,
      institutionId,
    });

    if (response.success) {
      setChatParticipants((currentChartParticipants) => {
        return currentChartParticipants.filter((chartParticipants) => {
          return chartParticipants.person_id !== contactId;
        });
      });
    }

    setLoading(false);
  }

  return (
    <S.Container>
      <ContactContent contact={contact} />

      <S.Center>
        {isContactInGroup ? (
          <Button
            loading={loading}
            type="primary"
            danger
            onClick={HandleRemoveParticipant}
          >
            Excluir
          </Button>
        ) : (
          <Button
            loading={loading}
            type="primary"
            onClick={HandleAddParticipant}
          >
            Vincular
          </Button>
        )}
      </S.Center>
    </S.Container>
  );
}
