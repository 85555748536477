import React, { useEffect, useState, useContext } from 'react';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { ThemeContext } from '~/contexts/ThemeContext';
import { LanguageContext } from '~/contexts/LanguageContext';
import {
  ArrowLeftOutlined,
  //ArrowRightOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import Text from '~/components/typography/text';
import Button from '~/components/button';
import { Drawer } from 'antd';
import {
  Header,
  Content,
  TriggerContainer,
  DrawerFooterContainer,
} from './style';

export default function MyDrawer({
  placement,
  title,
  footer,
  closable,
  headerStyle,
  bodyStyle,
  Trigger,
  DrawerContentComponent,
  DrawerContentComponentParams,
  width,
  widthMobile,
  onClose,
  keyboard,
  visible,
  closeDrawer,
  children,
  footerCancelButton,
  FooterMainButton,
  footerMainButton,
}) {
  const { translate } = useContext(LanguageContext);
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const { isMobile } = useContext(ResponsiveContext);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (closeDrawer === true && drawerVisibility === true) {
      setDrawerVisibility(false);
    }
  }, [closeDrawer, drawerVisibility]);

  function OnClose() {
    if (onClose) return onClose();
    setDrawerVisibility(false);
  }
  function SetWidth() {
    if (widthMobile) return widthMobile;
    if (isMobile) {
      return '100vw';
    }
    if (width) return width;
    return '35vw';
  }

  const HeaderDesktop = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          padding: '20px 10px',
        }}
      >
        <CloseOutlined
          onClick={() => OnClose()}
          style={{
            marginRight: '10px',
            fontSize: '16pt',
            color: theme.color.textOnSecondaryBackground,
          }}
        />
        <Text
          strong
          style={{
            fontSize: '12pt',
            color: theme.color.textOnSecondaryBackground,
          }}
        >
          {title || ''}
        </Text>
      </div>
    );
  };

  const HeaderMobile = () => {
    return (
      <>
        <Content width="15%">
          <ArrowLeftOutlined
            onClick={() => OnClose()}
            color={theme.color.textOnSecondaryBackground}
            size={32}
          />
        </Content>
        <Content width="85%">
          <Text
            strong
            fontSize={14}
            color={theme.color.textOnSecondaryBackground}
          >
            {title || ''}
          </Text>
        </Content>
      </>
    );
  };

  const titleAtr = title
    ? {
      title: (
        <Header>{isMobile ? <HeaderMobile /> : <HeaderDesktop />}</Header>
      ),
    }
    : {};

  return (
    <>
      <TriggerContainer onClick={() => setDrawerVisibility(true)}>
        {Trigger}
      </TriggerContainer>
      <Drawer
        {...titleAtr}
        width={SetWidth()}
        footer={
          footer ? (
            <DrawerFooterContainer>
              {footerCancelButton && (
                <Button onClick={OnClose} style={{ marginRight: 8 }}>
                  {translate.basic.cancel.text}
                </Button>
              )}
              {FooterMainButton && <FooterMainButton CloseDrawer={OnClose} />}
              {footerMainButton}
            </DrawerFooterContainer>
          ) : (
            false
          )
        }
        closable={closable || false}
        placement={placement}
        visible={visible || drawerVisibility}
        headerStyle={
          headerStyle || {
            padding: 0,
            margin: 0,
          }
        }
        bodyStyle={bodyStyle || { padding: 20 }}
        onClose={OnClose}
        keyboard={keyboard}
      >
        {children}
        {DrawerContentComponent && (
          <DrawerContentComponent
            propertys={DrawerContentComponentParams}
            CloseDrawer={OnClose}
          />
        )}
      </Drawer>
    </>
  );
}

export function CloseDrawer() {
  console.log('CloseDrawer');
}
