import React from 'react';
// Libraries
import { Divider, Row, Col } from 'antd';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import Select from '~/components/select';
// Environments
import { Sex } from '~/environments/Selects';
import { dataBrasileiraMask } from '~/utils/Masks';

export default function BasicInformation({ form }) {
  const HandleChange = () => (event) => {
    return form.setFieldsValue({
      birth_certificate_date: dataBrasileiraMask(event.target.value),
    });
  };

  return (
    <div>
      <Divider style={{ margin: '0px 0px 10px' }}>Informações Básicas</Divider>
      <Row>
        <Col span={24}>
          <FormItem
            label="Nome Completo"
            name={['name']}
            rules={[
              {
                required: true,
                message: 'Um nome é obrigatório',
              },
            ]}
            item={<Input />}
          />
        </Col>
        <Col span={24}>
          <FormItem
            label="Email"
            name={['main_contact']}
            rules={[
              {
                required: true,
                message: 'Um email é obrigatório',
              },
            ]}
            item={<Input type="email" />}
          />
        </Col>

        <Col span={12}>
          <FormItem
            label="Nascimento"
            name={['birth_certificate_date']}
            rules={[
              {
                required: true,
                message: 'Uma data é obrigatória',
              },
            ]}
            item={<Input onChange={HandleChange()} />}
            style={{ marginRight: 10 }}
          />
        </Col>

        <Col span={12}>
          <FormItem
            label="Sexo"
            name={['sex']}
            item={<Select options={Sex} />}
          />
        </Col>
      </Row>
    </div>
  );
}
