import SigamAPI from '~/utils/SigamAPI';

import { message } from 'antd';

export default async function CreateArchive({ archive, institutionId }) {
  archive.metas.forEach((meta, index) => {
    archive[`meta${index + 1}`] = meta;
  });

  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/archive`,
    method: 'POST',
    data: archive,
  });

  if (response.success) {
    message.success('Acervo criado com sucesso');
  } else {
    message.error('Erro ao criar o acervo');
  }

  return response;
}
