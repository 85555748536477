import styled from 'styled-components';

const PopupDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  & span {
    margin-bottom: 5px;
  }
  & span:last-child {
    margin-bottom: 0;
  }
`;

export default PopupDiv;