import invalid from './error/invalid';
import missing from './error/missing';
import other from './error/other';
import taken from './error/taken';

export default {
  invalid,
  missing,
  other,
  taken,
};
