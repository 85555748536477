import React from 'react';
import { Typography, Card, Spin } from 'antd';
import { usePageManager } from '../../../../contexts/PageManager/Provider';
import IconAgent from '~/assets/images/icons/icone256-agente.png';

const { Title, Text } = Typography;

export default function Processes() {
  const { totalProcesses, totalProcessesLoading } = usePageManager();

  const Field = ({ label, value }) => {
    return (
      <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
        <Text>{label}:</Text>
        {value ? (
          <Text style={{ color: '#143c6d', fontWeight: 'bold' }}>{value}</Text>
        ) : (
          <Spin size="small" />
        )}
      </div>
    );
  };

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <div
        style={{
          padding: '6px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#143c6d',
          borderTopRightRadius: '4px',
          borderTopLeftRadius: '4px',
        }}
      >
        <Title level={5} style={{ margin: 0, padding: 0, color: 'white' }}>
          Processos
        </Title>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          padding: '0px 8px',
          alignItems: 'center',
          gap: 8,
        }}
      >
        <div>
          <img src={IconAgent} style={{ width: 64 }} alt="..." />
        </div>
        <div>
          <Field
            label="Total"
            value={totalProcessesLoading ? null : totalProcesses.total}
          />
          <Field
            label="Total de anexos"
            value={totalProcessesLoading ? null : totalProcesses.attachments}
          />
          <Field
            label="Total de visualizações"
            value={totalProcessesLoading ? null : totalProcesses.views}
          />
        </div>
      </div>
    </Card>
  );
}
