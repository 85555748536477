import React, { useContext, useState, useEffect } from 'react';
// Contexts
import { Tag } from 'antd';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Libraries
// Own Components
import Interested from './components/Interested';
import ProceedDetails from './components/ProceedDetails';
import Signatories from './components/Signatories';
import GetProcess from './components/GetProcess';
import Options from '../Options';
import Tags from './components/Tags';
// Components
import Button from '~/components/button';
import Card from '~/components/card';
import Table from '~/components/table';
import {
  PrimaryAndSecondaryTextCell,
  DateAndTimeCell,
} from '~/components/table/cells';
import Pagination from '~/components/pagination';
import Drawer from '~/components/drawer';
import Text from '~/components/typography/text';

export default function Content({
  data,
  GetDataAgain,
  pageParameters,
  setselectedRow,
  activeTab,
}) {
  const { isMobile } = useContext(ResponsiveContext);
  const [headerHeight, setHeaderHeight] = useState(null);
  const [paginationHeight, setpaginationHeight] = useState(null);

  useEffect(() => {
    if (!isMobile) {
      const height_header = document.getElementById('header').clientHeight;
      setHeaderHeight(height_header);

      const height_pagination =
        document.getElementById('pagination').clientHeight;
      setpaginationHeight(height_pagination);
    }
  }, []);

  const columns = [
    {
      title: 'Criação',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at, record) => (
        <div>
          <DateAndTimeCell timestamp={created_at} />
          <Text>
            Por:
            <Tag
              color={
                record.created_at_unity_settings &&
                record.created_at_unity_settings.color
                  ? record.created_at_unity_settings.color
                  : 'default'
              }
              style={{ marginLeft: 5 }}
            >
              {record.created_at_unity_name}
            </Tag>
          </Text>
        </div>
      ),
    },
    {
      title: 'Processo',
      dataIndex: 'internal_id',
      key: 'internal_id',
      render: (internal_id, record) => (
        <div>
          <PrimaryAndSecondaryTextCell
            primaryText={record.name}
            secondaryText={`${internal_id.split('-')[0]}-${
              internal_id.split('-')[1]
            }`}
          />
          <Tags item={record} />
        </div>
      ),
    },
    {
      title: 'Interessados',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <Interested id={id} />,
    },
    {
      title: 'Assinantes',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <Signatories id={id} />,
    },
  ];

  const mobileColumns = [
    {
      title: 'Criação',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at, record) => (
        <div>
          <DateAndTimeCell timestamp={created_at} />
          <Text>
            Por:
            <Tag
              color={
                record.created_at_unity_settings &&
                record.created_at_unity_settings.color
                  ? record.created_at_unity_settings.color
                  : 'default'
              }
              style={{ marginLeft: 5 }}
            >
              {record.created_at_unity_name}
            </Tag>
          </Text>
        </div>
      ),
    },
    {
      title: 'Processo',
      dataIndex: 'internal_id',
      key: 'internal_id',
      render: (internal_id, record) => (
        <div>
          <PrimaryAndSecondaryTextCell
            primaryText={record.name}
            secondaryText={`${internal_id.split('-')[0]}-${
              internal_id.split('-')[1]
            }`}
          />
          <Tags item={record} />
        </div>
      ),
    },
    {
      title: 'Interessados',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <Interested id={id} />,
    },
    {
      title: 'Assinantes',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <Signatories id={id} />,
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => <Options selectedRow={[record]} mobileColumn />,
    },
  ];

  const columnsProcesses = [
    {
      title: 'Criação',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at, record) => (
        <div>
          <DateAndTimeCell timestamp={created_at} />
          <Text>
            Por:
            <Tag
              color={
                record.created_at_unity_settings &&
                record.created_at_unity_settings.color
                  ? record.created_at_unity_settings.color
                  : 'default'
              }
              style={{ marginLeft: 5 }}
            >
              {record.created_at_unity_name}
            </Tag>
          </Text>
        </div>
      ),
    },
    {
      title: 'Processo',
      dataIndex: 'internal_id',
      key: 'internal_id',
      render: (internal_id, record) => (
        <div>
          <PrimaryAndSecondaryTextCell
            primaryText={record.name}
            secondaryText={`${internal_id.split('-')[0]}-${
              internal_id.split('-')[1]
            }`}
          />
          <Tags item={record} />
        </div>
      ),
    },
    {
      title: 'Tramitações',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => (
        <Drawer
          Trigger={<Button>Detalhes da tramitação</Button>}
          DrawerContentComponent={ProceedDetails}
          DrawerContentComponentParams={{ process: record }}
          title="Detalhes da tramitação"
        />
      ),
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => (
        <Drawer
          Trigger={<Button>Pegar Processo</Button>}
          DrawerContentComponent={GetProcess}
          DrawerContentComponentParams={{ process: record, GetDataAgain }}
          title="Pegar Processo"
        />
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setselectedRow(selectedRows);
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  };

  if (isMobile) {
    return (
      <div>
        <Table dataReceived={data} tableColumns={mobileColumns} />
        <div style={{ padding: 10 }}>
          <Pagination
            current={pageParameters.currentPage}
            pageSize={pageParameters.limit}
            onChange={(currentPage, limit) =>
              GetDataAgain({ currentPage, limit })
            }
            onShowSizeChange={(currentPage, limit) =>
              GetDataAgain({ currentPage, limit })
            }
            total={pageParameters.totalElements}
            showSizeChanger
            showQuickJumper={false}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} de ${total} registros`
            }
          />
        </div>
      </div>
    );
  }

  function activeTableColumns() {
    if (activeTab === 'unitProcessesReceveid') {
      return columnsProcesses;
    }
    switch (activeTab) {
      case 'unitProcessesReceived':
        return columnsProcesses;
      case 'groupsProcesses':
        return columnsProcesses;
      default:
        return columns;
    }
  }

  return (
    <Card bodyStyle={{ padding: '10px 0px' }}>
      <Table
        dataReceived={data}
        tableColumns={activeTableColumns()}
        scroll={
          isMobile
            ? false
            : {
                y: `calc(100vh - ${
                  headerHeight + 46 + 50 + paginationHeight + 80 + 47
                }px)`,
              }
        }
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
      />

      <div id="pagination" style={{ padding: 10 }}>
        <Pagination
          current={pageParameters.currentPage}
          pageSize={pageParameters.limit}
          onChange={(currentPage, limit) =>
            GetDataAgain({ currentPage, limit })
          }
          onShowSizeChange={(currentPage, limit) =>
            GetDataAgain({ currentPage, limit })
          }
          total={pageParameters.totalElements}
          showSizeChanger
          showQuickJumper={false}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} de ${total} registros`
          }
        />
      </div>
    </Card>
  );
}
