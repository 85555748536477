import { message } from 'antd';
import SigamAPI from '~/utils/SigamAPI';

export default async function DeleteArchive({ archiveId, institutionId }) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/archive/${archiveId}`,
    method: 'DELETE',
  });

  if (response.success) {
    message.success('Acervo deletado com sucesso');
  } else {
    message.error('Erro ao deletar o acervo');
  }

  return response;
}
