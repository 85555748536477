import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    & {
      flex-direction: column;
     
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  width: 40%;
  justify-content: space-between;
`;
