import React, { useState } from 'react';
import { Button, Drawer, Form, Space } from 'antd';
import { usePageManager } from '../../../../../../contexts/PageManager/Provider';
import AddForm from './views/Form';
import Result from './views/Result';

export default function AddProcess() {
  const [form] = Form.useForm();
  const { selectedProcessId } = usePageManager();
  const [open, setOpen] = useState(false);
  const [view, setView] = useState('FORM');
  const [loadingCreationButton, setLoadingCreationButton] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const RenderBody = () => {
    switch (view) {
      case 'RESULT':
        return (
          <Result formValues={formValues} setView={setView} onClose={onClose} />
        );
      default:
        return <AddForm form={form} />;
    }
  };

  const RenderExtra = () => {
    switch (view) {
      case 'RESULT':
        return <></>;
      default:
        return (
          <Button
            type="primary"
            loading={loadingCreationButton}
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  onFinishCreation(values);
                })
                .catch((info) => {
                  // console.log('Validate Failed:', info);
                });
            }}
          >
            Pesquisar processo
          </Button>
        );
    }
  };

  async function onFinishCreation(values) {
    setLoadingCreationButton(true);
    setFormValues(values);
    setView('RESULT');
    setLoadingCreationButton(false);
  }

  return (
    <>
      {selectedProcessId ? (
        <Button onClick={showDrawer} type="primary">
          Selecionar outro processo
        </Button>
      ) : (
        <Space
          direction="vertical"
          size="small"
          style={{
            display: 'flex',
          }}
        >
          <Button onClick={showDrawer} type="dashed">
            Selecionar processo
          </Button>
        </Space>
      )}

      <Drawer
        title="Selecionar processo"
        placement="right"
        onClose={onClose}
        open={open}
        extra={<RenderExtra />}
      >
        <RenderBody />
      </Drawer>
    </>
  );
}
