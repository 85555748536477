import React from 'react';
import { Card } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';

import Header from './components/Header';
import Body from './components/Body';
import UpdateDrawerButton from './components/UpdateIconButton';
import DeleteButton from './components/DeleteIconButton';
import DetailsButton from './components/DetailsButton';
import { useAuth } from '~/contexts/AuthContext';

const { Meta } = Card;

export default function ArchiveCard({ archive }) {
  const { user } = useAuth();
  const { url } = useRouteMatch();

  const archivePageURL = `${url}/${archive.id}`;

  const isLoggedUser = archive.person_id === user.id;

  const borderColor = isLoggedUser ? '#153d6e' : '#52c41a';

  return (
    <div style={{ padding: '0px 4px 8px 4px' }}>
      <Card
        style={{
          borderTop: `3px solid ${borderColor}`,
          borderRadius: '0px',
          width: '100%',
        }}
        title={<Header archive={archive} />}
        extra={<DetailsButton archive={archive} />}
        actions={[
          <UpdateDrawerButton archive={archive} />,

          <DeleteButton archive={archive} />,

          <Link to={archivePageURL}>
            <EyeOutlined key="ellipsis" />
          </Link>,
        ]}
      >
        <Meta description={<Body archive={archive} />} />
      </Card>
    </div>
  );
}
