import React, { useState } from 'react';
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { PrinterOutlined } from '@ant-design/icons';
import Button from '~/components/button';
import Loading from '~/components/loading'

export default function ExternalView({ document }) {
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <div>
            <a href={document.url} target="_blank" rel="noopener noreferrer">
                <Button icon={<PrinterOutlined />} type="primary">
                    Imprimir Documento
                </Button>
            </a>
        </div>

    )
}