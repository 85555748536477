import React, { useState, useEffect } from 'react';
// Libraries
import { Alert, Result, Timeline } from 'antd';
import { useParams } from 'react-router-dom';
import Animate, { FadeIn } from 'animate-css-styled-components';
import Loading from '~/components/loading';
import Text from '~/components/typography/text';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { CapitalizeString } from '~/utils/Transformations';
import {
  DateInBrazilianFormat,
  HourInBrazilianFormat,
} from '~/utils/DateAndTime';

export default function ProceedDetails({ process, propertys, CloseDrawer }) {
  const { institutionId, organizationUnitId } = useParams();
  const [history, setHistory] = useState([]);
  const [loadingContent, setLoadingContent] = useState(true);

  const processInformation = process || propertys.process[0];

  useEffect(() => {
    async function GetHistoric() {
      const params = {
        operation: ['SIGAM', 'Processes', 'GetHistory'],
        urlParams: {
          institutionId: institutionId,
          organizationUnitId: organizationUnitId,
          processId: processInformation.id,
        },
        data: {
          filter: {
            action: ['SEND'],
          },
          pagination: {
            sort: {
              by: 'created_at',
              order: 'DESC',
            },
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);

      if (response.success) {
        setHistory(response.body.timeline.elements);
      }
      setLoadingContent(false);
    }
    GetHistoric();
  }, [institutionId, organizationUnitId, propertys]);

  function GetReceiver(item) {
    if (item.receiver_person_social_name || item.receiver_person_name) {
      return `Para a pessoa ${CapitalizeString(
        item.receiver_person_social_name || item.receiver_person_name
      )}`;
    }
    if (item.receiver_group_name) {
      return `Para o grupo ${CapitalizeString(item.receiver_group_name)}`;
    }
    if (item.receiver_organizational_unity_name) {
      return `Para a unidade ${CapitalizeString(
        item.receiver_organizational_unity_name
      )}`;
    }
  }

  if (loadingContent) {
    return <Loading text="Carregando histórico" />;
  }

  if (history.length === 0) {
    return <Result status="info" title="O processo ainda não foi tramitado" />;
  }

  return (
    <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
      <div>
        <div style={{ marginBottom: 20 }}>
          {processInformation.status === 'INACTIVE' && (
            <Alert
              showIcon
              type="warning"
              description="Processo se encontra arquivado"
            />
          )}
        </div>
        <Timeline>
          {history.map((item) => (
            <Timeline.Item key={item.id}>
              <Text>
                Tramitado em {DateInBrazilianFormat(item.created_at)} às{' '}
                {HourInBrazilianFormat(item.created_at)}
              </Text>
              <Text>
                Por:{' '}
                {CapitalizeString(
                  item.created_by_social_name ||
                    item.created_by_name ||
                    'Desconhecido'
                )}
              </Text>
              <Text>{GetReceiver(item)}</Text>
              <Text>Mensagem: {item.receiver_specifications}</Text>
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
    </Animate>
  );
}
