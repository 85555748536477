import React, { useState } from 'react';
// Libraries
import { Form, message } from 'antd';
import { useParams } from 'react-router-dom';
import Animate, { FadeIn } from 'animate-css-styled-components';
// Own Components
import AddForm from './components/AddForm';
// Components
import Button from '~/components/button';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function AttachInNewProcess({ document, setProcess, setActiveMethod }) {
    const [form] = Form.useForm();
    const { institutionId, organizationUnitId } = useParams();
    const [loadingCreationButton, setLoadingCreationButton] = useState(false);

    async function AddDocument(processCreated) {
        const params = {
            operation: ['SIGAM', 'Processes', 'Attachments', 'Add'],
            urlParams: {
                institutionId: institutionId,
                organizationUnitId: organizationUnitId,
                processId: processCreated.id,
                documentId: document.id
            },
            data: {
                name: document.name
            }
        };
        const response = await SocialMeAPI(params);
        console.log(response);
        if (response.success) {
            message.success('Documento anexado com sucesso!');
            form.resetFields();
            setActiveMethod();
            return setProcess(processCreated);
        } else {
            message.error('Alguma coisa aconteceu...');
        }
        setLoadingCreationButton(false);
    }

    async function onFinishCreation(values) {
        setLoadingCreationButton(true);
        if (values.priority) {
            values.priority = 1;
        } else {
            values.priority = 0;
        }
        values.privacy = 'PUBLIC';
        values.status = 'ACTIVE';
        const params = {
            operation: ['SIGAM', 'Processes', 'Add'],
            urlParams: {
                institutionId: institutionId,
                organizationUnitId: organizationUnitId
            },
            data: values,
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            message.success("Processo criado com sucesso!");
            let processCreated = response.body.process;
            return AddDocument(processCreated);
        } else {
            message.error("Alguma coisa aconteceu...")
        }
        setLoadingCreationButton(false);
    }

    return (
        <Animate
            Animation={[FadeIn]}
            duration={['0.5s']}
            delay={['0.2s']}
        >
            <Form
                labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
                wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
                form={form}
                size="small"
                layout="vertical"
                scrollToFirstError
            >
                <AddForm />
                <Button
                    type="primary"
                    loading={loadingCreationButton}
                    onClick={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                onFinishCreation(values);
                            })
                            .catch((info) => {
                                // console.log('Validate Failed:', info);
                            });
                    }}
                >
                    Criar Processo e anexar documento
                    </Button>
            </Form>
        </Animate>
    );
}
