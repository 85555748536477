import React, { memo, useContext } from 'react';

import { ResponsiveContext } from '~/contexts/ResponsiveContext';

import Text from '~/components/typography/text';

import { Flex } from './style';

function PrimaryAndSecondaryTextCell({
  primaryText,
  secondaryText,
  secondaryTextComponent,
}) {
  const { isMobile } = useContext(ResponsiveContext);

  return (
    <Flex direction="column" align={isMobile ? 'center' : 'flex-start'}>
      <Text style={{ textAlign: isMobile ? 'center' : 'left' }} strong>
        {primaryText}
      </Text>
      {secondaryTextComponent && secondaryTextComponent}
      <Text style={{ textAlign: isMobile ? 'center' : 'left' }}>
        {secondaryText}
      </Text>
    </Flex>
  );
}

export default memo(PrimaryAndSecondaryTextCell);
