import React, { useContext } from 'react';
import { ThemeContext } from '~/contexts/ThemeContext';
import Text from '~/components/typography/text';

export default function Description({ text }) {
  const { theme } = useContext(ThemeContext);
  return (
    <div
      style={{
        padding: '10px 20px',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        height: 60,
        backgroundColor: theme.color.backgroundPrimaryDark,
      }}
    >
      <Text
        style={{ textAlign: 'center' }}
        color={theme.color.textOnPrimaryBackground}
        strong={true}
      >
        {text}
      </Text>
    </div>
  );
}
