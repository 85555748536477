import React, { useContext, useEffect, useState } from 'react';
// Libraries
import { Layout, Collapse } from 'antd';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Own Components
import InstitutionPanelBody from './components/InstitutionPanelBody';
// Components
import MainNavbar from '~/pages/SIGAM/components/navbar';
import Avatar from '~/components/avatar';
import Title from '~/components/typography/title';
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { GetDefaultAvatar } from '~/utils/SocialMeAssets';
import { CapitalizeString } from '~/utils/Transformations';

const { Panel } = Collapse;

const { Content } = Layout;

export default function Home() {
  const { theme } = useContext(ThemeContext);
  const [loadingContent, setLoadingContent] = useState(true);
  const [institutions, setInsitutions] = useState();

  useEffect(() => {
    async function GetInstitutions() {
      const params = {
        operation: ['SIGAM', 'Citizen', 'GetInstitutions'],
        data: {
          pagination: {
            limit: 0,
            sort: {
              by: 'name',
              order: 'ASC',
            },
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setInsitutions(response.body.institutionList.elements);
      }
      setLoadingContent(false);
    }
    GetInstitutions();
  }, []);

  function SetDefaultActiveKey() {
    const array = [];
    for (let i = 1; i <= institutions.length; i++) {
      array.push(i);
    }
    return array;
  }

  function GetName(item) {
    if (item.social_name) {
      //return item.social_name;
    }
    return item.name;
  }

  return (
    <div>
      <Layout
        style={{
          backgroundColor: theme.color.backgroundPrimary,
          height: '100vh',
        }}
      >
        <Layout
          style={{
            backgroundColor: theme.color.backgroundSecondary,
          }}
        >
          <MainNavbar withoutSidebar simple />
          <Content
            style={{
              padding: '10px 5px',
              overflow: 'auto',
            }}
          >
            {loadingContent ? (
              <Loading text="Verificando Instituições" />
            ) : (
              <Collapse
                defaultActiveKey={SetDefaultActiveKey()}
                bordered={false}
              >
                {institutions.map((item, index) => (
                  <Panel
                    header={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {item.avatar ? (
                          <Avatar src={item.avatar} />
                        ) : (
                          <Avatar src={GetDefaultAvatar('institution')} />
                        )}
                        <Title level={5} style={{ margin: '0px 0px 0px 10px' }}>
                          {CapitalizeString(GetName(item))}
                        </Title>
                      </div>
                    }
                    key={index + 1}
                  >
                    <InstitutionPanelBody item={item} />
                  </Panel>
                ))}
                {institutions.length === 0 && (
                  <div
                    style={{
                      height: '200px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <h1>
                      Você não se encontra vinculado em nenhuma instituição
                    </h1>
                  </div>
                )}
              </Collapse>
            )}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

/*

 <div>
              <Row>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <div style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link to="/admin">
                      <Card style={{ width: 300, height: 200, cursor: 'pointer' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                          <img src={IconAdmin} style={{ width: 64 }} alt="..." />
                          <Title level={4}>Administrador</Title>
                          <Text kind="secondary" style={{ textAlign: 'center' }}>Gerencie usuário, permissões, grupos e unidades organizacionais</Text>
                        </div>
                      </Card>
                    </Link>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <div style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link to="/agente-publico">
                      <Card style={{ width: 300, height: 200, cursor: 'pointer' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                          <img src={IconAgente} style={{ width: 64 }} alt="..." />
                          <Title level={4}>Agente Público</Title>
                          <Text kind="secondary" style={{ textAlign: 'center' }}>Gerencie processos, acompanhe suas movimentações, envios, históricos e documentos</Text>
                        </div>
                      </Card>
                    </Link>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <div style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link to="/cidadao">
                      <Card style={{ width: 300, height: 200, cursor: 'pointer' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                          <img src={IconCidadao} style={{ width: 64 }} alt="..." />
                          <Title level={4}>Cidadão</Title>
                          <Text kind="secondary" style={{ textAlign: 'center' }}>Crie e acompanhe seus pedidos</Text>
                        </div>
                      </Card>
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>

*/
