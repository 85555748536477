import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const MenuContainer = styled.div`
  border: 1px solid;
  border-color: ${(props) => props.borderColor};
  background-color: ${(props) => props.backgroundColor};
  padding: 10px;
  border-radius: 8px;
  margin-top: ${(props) => props.marginTop};
  font-size: ${(props) => props.fontSize};
`;

export const MenuItem = styled.div`
  cursor: pointer;
  margin-top: 5px;
`;

export const Link = styled.a``;
