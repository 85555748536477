import SigamAPI from '~/utils/SigamAPI';

export default async function Read({ institutionId }) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/settings`,
    method: 'GET',
  });

  return response;
}
