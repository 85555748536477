import React, { useEffect, useState } from 'react';
// Libraries
import { useParams } from 'react-router-dom';
// Own Components
import Header from './components/Header';
import FilesList from './components/FilesList';
// Components
import Card from '~/components/card';
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Visualize({ process, propertys }) {
    const { institutionId, organizationUnitId } = useParams();
    const [completeProcessInformation, setCompleteProcessInformation] = useState([]);
    const [loadingContent, setLoadingContent] = useState(true);

    const processInformation = process || propertys.process[0];

    useEffect(() => {
        async function GetProcess() {
            setLoadingContent(true)
            setCompleteProcessInformation()
            const params = {
                operation: ['SIGAM', 'Processes', 'Show'],
                urlParams: {
                    institutionId,
                    organizationUnitId,
                    processId: processInformation.id
                }
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                // console.log(response.body.process)
                setCompleteProcessInformation(response.body.process);
            }
            setLoadingContent(false);
        }
        GetProcess();
    }, [institutionId, organizationUnitId, processInformation])

    if (loadingContent) {
        return <Loading text="Carregando documento" />
    }

    return (
        <div>
            <Header process={completeProcessInformation} />
            <Card style={{ marginTop: 20 }}>
                <FilesList
                    process={completeProcessInformation}
                />
            </Card>
        </div>
    )
}