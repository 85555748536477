import SigamAPI from '~/utils/SigamAPI';

export default async function ReadExternal({ institutionId, data }) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/communication/sents`,
    method: 'POST',
    data: data,
  });

  return response;
}
