import SigamAPI from '~/utils/SigamAPI';

export default async function SearchInSigam({ institutionId, data }) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/people/all-with-inst`,
    method: 'POST',
    data,
  });

  return response;
}
