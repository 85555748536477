import React, { useEffect, useState, useContext } from 'react';
// Libraries
import { Row, Col } from 'antd';
import { useParams } from 'react-router-dom';
// Own Components
// Components
import Card from '~/components/card';
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import FileRender from './components/FileRender';
import FilesList from './components/FilesList';
import Header from './components/Header';

export default function Visualize({ propertys }) {
  const { isMobile } = useContext(ResponsiveContext);
  const [selectedFile, setSelectedFile] = useState();
  const documentInformation = propertys.document.document;
  const attachments = propertys.document.attachments.elements;
  const signatories = propertys.document.signatory.elements;

  useEffect(() => {
    setSelectedFile();
  }, [documentInformation]);

  return (
    <div>
      <Header document={documentInformation} />

      <Card style={{ marginTop: 10 }}>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={6}
            lg={6}
            xl={6}
            style={!isMobile && { borderRight: '1px solid #bfbfbf' }}
          >
            <FilesList
              document={documentInformation}
              setSelectedFile={setSelectedFile}
              attachments={attachments}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            style={!isMobile && { borderLeft: '1px solid #bfbfbf' }}
          >
            <FileRender
              document={documentInformation}
              selectedFile={selectedFile}
              signatories={signatories}
              attachments={attachments}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
}
