import React, { useEffect, useState, useContext } from 'react';
// Utils
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import Button from '~/components/button';
import { CapitalizeString } from '~/utils/Transformations';
import Loading from '~/components/loading/';
import {
  DateInBrazilianFormat,
  HourInBrazilianFormat,
} from '~/utils/DateAndTime';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import getHTML from './utils/getHTML';
import returnUrl from '~/services/calls/pdf/returnUrl';

function GetFieldValue(value) {
  switch (value) {
    case 'ACTIVE':
      return 'Ativo';
    case 'INACTIVE':
      return 'Inativo';
    case 'PUBLIC':
      return 'Público';
    case 'RESTRICTED':
      return 'Restrito';
    case 'CONFIDENTIAL':
      return 'Sigiloso';
    case '0':
      return 'Não é prioridade';
    case '1':
      return 'É prioridade';
    default:
      return value;
  }
}

function GetFieldName(field) {
  switch (field) {
    case 'id':
      return 'Id';
    case 'internal_id':
      return 'Id interno';
    case 'external_id':
      return 'Id externo';
    case 'manual_id':
      return 'manual_id';
    case 'created_at':
      return 'Criado em';
    case 'reated_externally_at':
      return 'Local criado externamente';
    case 'created_by':
      return 'Criado por';
    case 'updated_by':
      return 'Atualizado por';
    case 'created_at_unity':
      return 'Criado pela unidade';
    case 'updated_at_unity':
      return 'Atualizado pela unidade';
    case 'institution':
      return 'Instituição';
    case 'dispatched_by':
      return 'Emitido por';
    case 'subject':
      return 'Assunto';
    case 'model':
      return 'Modelo';
    case 'nature':
      return 'Natureza';
    case 'format':
      return 'Formato';
    case 'status':
      return 'Status';
    case 'kind':
      return 'Tipo';
    case 'name':
      return 'Nome';
    case 'body':
      return 'Corpo';
    case 'url':
      return 'URL';
    case 'description':
      return 'Descrição';
    case 'specifications':
      return 'Especificações';
    case 'settings':
      return 'Configurações';
    case 'privacy':
      return 'Privacidade';
    case 'priority':
      return 'Prioridade';
    case 'verification_id':
      return 'ID de verificação';
    default:
      return 'CAMPO DESCONHECIDO';
  }
}

function GetMessage(item) {
  if (item.agent === 'PROCESS') {
    if (item.kind === 'DIRECT CHANGE') {
      if (item.action === 'CREATE') {
        return `O campo "${GetFieldName(
          item.field
        )}" recebeu o valor "${GetFieldValue(item.value)}"`;
      }
      if (item.action === 'ACCESS') {
        return `Visualizado por ${CapitalizeString(item.created_by_name)}`;
      }
      if (item.action === 'UPDATE') {
        if (item.field === 'model') {
          return `O modelo do processo foi atualizado por ${item.created_by_name}`;
        }
        if (item.field === 'body') {
          return `O corpo do processo foi atualizado por ${item.created_by_name}`;
        }
        if (item.field === 'status') {
          return '';
        }
        return `O campo "${GetFieldName(
          item.field
        )}" recebeu o valor "${GetFieldValue(item.value)}"`;
      }
    }
    if (item.kind === 'ASSIGNMENT') {
      if (item.action === 'INTERESTED PART') {
        return `Interessado inserido por ${
          item.created_by_name
        }: ${CapitalizeString(item.assignment_person_name)}`;
      }
      if (item.action === 'SIGNATORY') {
        return `Assinante inserido por ${
          item.created_by_name
        }: ${CapitalizeString(item.assignment_person_name)}`;
      }
      if (item.action === 'PRIVATE ACCESS') {
        return `Pessoa inserida por ${item.created_by_name}: ${CapitalizeString(
          item.assignment_person_name
        )}`;
      }
      if (item.action === 'CO-OWNER') {
        return `Editor inserido por ${item.created_by_name}: ${CapitalizeString(
          item.assignment_person_name
        )}`;
      }
      if (item.action === 'LOGIN-PASSWORD') {
        return `Assinado por: ${CapitalizeString(item.created_by_name)}`;
      }
    }
    if (item.kind === 'UNASSIGNMENT') {
      if (item.action === 'SIGNATORY') {
        return `Assinante removido por ${
          item.created_by_name
        }: ${CapitalizeString(item.assignment_person_name)}`;
      }
      if (item.action === 'PRIVATE ACCESS') {
        return `Pessoa removida por ${item.created_by_name}: ${CapitalizeString(
          item.assignment_person_name
        )}`;
      }
      if (item.action === 'CO-OWNER') {
        return `Editor removido por ${item.created_by_name}: ${CapitalizeString(
          item.assignment_person_name
        )}`;
      }
      if (item.action === 'INTERESTED PART') {
        return `Interessado removido por ${
          item.created_by_name
        }: ${CapitalizeString(item.assignment_person_name)}`;
      }
      if (item.action === 'LOGIN-PASSWORD') {
        return `Assinatura cancelada por: ${CapitalizeString(
          item.created_by_name
        )}`;
      }
    }
    if (item.kind === 'PROCESSING') {
      if (item.action === 'SEND') {
        return `Tramitado de ${CapitalizeString(
          item.created_by_name
        )} para ${CapitalizeString(
          item.receiver_person_name || item.receiver_organizational_unity_name
        )}`;
        // return `Tramitado`;
      }
    }
    if (item.kind === 'ATTACHMENT') {
      if (item.action === 'CREATE') {
        return `Anexo inserido por ${item.created_by_name}: ${CapitalizeString(
          item.attached_document_name
        )}`;
        // return `Tramitado para: ${CapitalizeString(item.receiver_person_name)}`;
      }
      if (item.action === 'UPDATE') {
        return `Anexo atualizado por ${
          item.created_by_name
        }: ${CapitalizeString(item.attached_document_name)}`;
      }
    }
  }
  if (item.agent === 'DOCUMENT ATTACHMENT') {
    if (item.kind === 'DIRECT CHANGE') {
      if (item.action === 'CREATE') {
        if (item.field === 'name') {
          return `Anexo inserido por ${item.created_by_name}: ${item.attachment_name}`;
        }
      }
      if (item.action === 'UPDATE') {
        return `Anexo inativado por ${item.created_by_name}: ${item.attachment_name}`;
      }
    }
  }
}

function GetTitle(item) {
  if (item.action === 'CREATE') {
    if (item.kind === 'ATTACHMENT') {
      return `Lista de anexos atualizada por ${item.created_by_name}:`;
    }
    if (item.agent === 'PROCESS') {
      return `Processo criado por ${item.created_by_name}:`;
    }
    return `Movimentação desconhecida`;
  }
  if (item.action === 'UPDATE') {
    if (item.field === 'body') {
      return `Processo atualizado por ${item.created_by_name}, corpo foi alterado.`;
    }
    if (item.field === 'status') {
      if (item.value === 'INACTIVE') {
        return `O processo foi arquivado por ${item.created_by_name}`;
      }
      return `O processo foi reaberto por ${item.created_by_name}`;
    }
    if (item.agent === 'DOCUMENT ATTACHMENT') {
      return `Lista de anexos atualizada por ${item.created_by_name}:`;
    }
    return `Processo atualizado por ${item.created_by_name}:`;
  }
  if (item.action === 'ACCESS') {
    return `Processo visualizado por ${item.created_by_name}:`;
  }
  if (item.action === 'PRIVATE ACCESS') {
    return `Lista restrita atualizada por ${item.created_by_name}:`;
  }
  if (item.action === 'CO-OWNER') {
    return `Lista de editores atualizada por ${item.created_by_name}:`;
  }
  if (item.action === 'INTERESTED PART') {
    return `Lista de interessados atualizada por ${item.created_by_name}:`;
  }
  if (item.action === 'SIGNATORY') {
    return `Lista de assinantes atualizada por ${item.created_by_name}:`;
  }
  if (item.action === 'LOGIN-PASSWORD') {
    return `Lista de assinaturas atualizada por ${item.created_by_name}:`;
  }
  if (item.action === 'SEND') {
    return `Processo tramitado por ${item.created_by_name}`;
  }
}

export default function PDFViewer({
  fatherProcess,
  attachments,
  timelineItems,
}) {
  const { isMobile } = useContext(ResponsiveContext);
  const [loading, setLoading] = useState(true);
  const [pdf, setPDF] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    // console.log('carregou')
    setLoading(false);
  }

  useEffect(() => {
    async function getPDF(HTML) {
      const response = await returnUrl({ html: HTML });
      if (response.success) {
        setPDF(response?.body?.file);
      }
      setLoading(false);
    }

    async function getPDFMakeObj() {
      const timelineItemsArray = [];
      for (let i = 0; i < timelineItems.length; i++) {
        if (
          timelineItems[i].action !== 'CREATE' &&
          timelineItems[i].kind !== 'DIRECT CHANGE'
        ) {
          const newItem = {
            date: DateInBrazilianFormat(timelineItems[i].created_at),
            hour: HourInBrazilianFormat(timelineItems[i].created_at),
            title: GetTitle(timelineItems[i]),
            message: GetMessage(timelineItems[i]),
          };
          timelineItemsArray.push(newItem);
        }
      }

      const HTML = await getHTML({
        fatherProcess,
        timelineItems: timelineItemsArray,
        attachments,
      });
      getPDF(HTML);
    }

    if (fatherProcess && timelineItems) {
      setLoading(true);
      setPDF();
      getPDFMakeObj();
    }
  }, [fatherProcess, timelineItems]);

  return (
    <div>
      {loading ? (
        <Loading text="Criando documento" />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ margin: '0px 0px 5px 0px' }}>
            <a href={pdf} target="_blank" rel="noopener noreferrer">
              <Button type="dashed">Imprimir Documento</Button>
            </a>
          </div>
          {isMobile ? (
            <div id="ResumeContainer" className="mt-4">
              <Document
                className="PDFDocument"
                file={{ url: pdf }}
                options={{ workerSrc: '/pdf.worker.js' }}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={<Loading text="Criando documento" />}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    renderTextLayer={false}
                    renderInteractiveForms={false}
                    className="PDFPage PDFPageOne"
                  />
                ))}
              </Document>
            </div>
          ) : (
            <div
              style={{
                // minWidth: isMobile ? 'auto' : '590px',
                // width: isMobile ? `${window.innerWidth}px` : 'auto'
                padding: 10,
                backgroundColor: '#d9d9d9',
              }}
            >
              <Document
                file={{ url: pdf }}
                options={{ workerSrc: '/pdf.worker.js' }}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={<Loading text="Criando documento" />}
                className="pdf-document"
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <div
                    key={`page_${index + 1}`}
                    style={{
                      borderBottom: '6px solid #d9d9d9',
                    }}
                  >
                    <Page pageNumber={index + 1} className="pdf-page" />
                  </div>
                ))}
              </Document>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
