import React, { useMemo } from 'react';
import { Drawer, Descriptions } from 'antd';

import { useTheme } from '~/contexts/ThemeContext';

export default function DetailsContainer({ file, open, setOpen }) {
  const { theme } = useTheme();

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      title={`Arquivo: ${file.name}`}
      placement="right"
      onClose={onClose}
      open={open}
    >
      <Descriptions
        size="small"
        labelStyle={{ fontWeight: 'bold', color: theme.color.primary }}
        layout="vertical"
        column={2}
      >
        <Descriptions.Item label="Nome">{file.name}</Descriptions.Item>
        <Descriptions.Item label="Descrição">
          {file.description}
        </Descriptions.Item>

        {file.metatags.map(({ label, value }) => (
          <Descriptions.Item key={label} label={label}>
            {value}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Drawer>
  );
}
