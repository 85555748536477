import React from 'react';
import { Form, Button, Input, Radio } from 'antd';

const { TextArea } = Input;

export default function ArchiveFilterForm({ form, initialFilter, onFinish }) {
  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      form={form}
      layout="vertical"
      scrollToFirstError
      onFinish={onFinish}
      initialValues={initialFilter}
    >
      <Form.Item label="Nome" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="Descrição" name="description">
        <TextArea rows={4} />
      </Form.Item>

      <Form.Item label="Visibilidade" name="visibility">
        <Radio.Group>
          <Radio.Button value="">Todos</Radio.Button>
          <Radio.Button value="PUBLIC">Público</Radio.Button>
          <Radio.Button value="PRIVATE">Privado</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="Status" name="status">
        <Radio.Group>
          <Radio.Button value="">Todos</Radio.Button>
          <Radio.Button value="ACTIVE">Ativo</Radio.Button>
          <Radio.Button value="INACTIVE">Inativo</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            width: '100%',
          }}
        >
          Filtrar acervos
        </Button>
      </Form.Item>
    </Form>
  );
}
