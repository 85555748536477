import React, { useEffect, useState, useContext } from 'react';
// Libraries
import { Row, Col } from 'antd';
import { useParams } from 'react-router-dom';
// Own Components
// Components
import Card from '~/components/card';
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import FileRender from './components/FileRender';
import FilesList from './components/FilesList';
import Header from './components/Header';

export default function Visualize({ document, propertys }) {
  const { isMobile } = useContext(ResponsiveContext);
  const { institutionId, organizationUnitId } = useParams();
  const [completeDocumentInformation, setCompleteDocumentInformation] =
    useState([]);
  const [loadingContent, setLoadingContent] = useState(true);
  const [selectedFile, setSelectedFile] = useState();

  const documentInformation = document || propertys.document[0];

  useEffect(() => {
    async function GetDocument() {
      setLoadingContent(true);
      setCompleteDocumentInformation();
      setSelectedFile();
      const params = {
        operation: ['SIGAM', 'Documents', 'Show'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: documentInformation.id,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        // console.log(response.body.process)
        setCompleteDocumentInformation(response.body.document);
      }
      setLoadingContent(false);
    }
    GetDocument();
  }, [institutionId, organizationUnitId, documentInformation]);

  if (loadingContent) {
    return <Loading text="Carregando documento" />;
  }

  return (
    <div>
      <Header document={completeDocumentInformation} />

      <Card style={{ marginTop: 10 }}>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={6}
            lg={6}
            xl={6}
            style={!isMobile && { borderRight: '1px solid #bfbfbf' }}
          >
            <FilesList
              document={completeDocumentInformation}
              setSelectedFile={setSelectedFile}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            style={!isMobile && { borderLeft: '1px solid #bfbfbf' }}
          >
            <FileRender
              document={completeDocumentInformation}
              selectedFile={selectedFile}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
}
