import React, { useEffect, useState } from 'react';
// Libraries
import { Form, message, Switch, Result } from 'antd';
import { useParams } from 'react-router-dom';
import Animate, { FadeIn } from 'animate-css-styled-components';
// Components
import Button from '~/components/button';
import Loading from '~/components/loading';
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import Select from '~/components/select';
import TextArea from '~/components/textArea';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Edit({ process, propertys }) {
    const { institutionId, organizationUnitId } = useParams();
    const [form] = Form.useForm();
    const [loadingContent, setLoadingContent] = useState(true);
    const [initialValues, setInitialValues] = useState();
    const [types, setTypes] = useState([]);
    const [accessLevel, setAccessLevel] = useState();

    const processInformation = process || propertys.process[0];

    async function onFinish(values) {
        const processedData = {}
        processedData.priority = 0
        for (const key in values) {
            if (values[key]) {
                processedData[key] = values[key];
            }
        }
        if (values.priority) {
            processedData.priority = 1;
        } else {
            processedData.priority = 0;
        }
        const params = {
            operation: ['SIGAM', 'Processes', 'Edit'],
            urlParams: {
                institutionId,
                organizationUnitId,
                processId: processInformation.id
            },
            data: processedData
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            if (propertys.GetDataAgain) {
                propertys.GetDataAgain();
            }
            return message.success('Processo atualizado com sucesso!')
        }
        return message.error('Alguma coisa aconteceu...')
    }

    useEffect(() => {
        async function GetTypes() {
            const params = {
                operation: ['SIGAM', 'TypesOfProcesses', 'Get'],
                urlParams: {
                    institutionId,
                },
                data: {
                    pagination: {
                        sort: {
                            by: 'name',
                            order: 'asc',
                        },
                        limit: 0
                    },
                },
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                const array = response.body.processKinds.elements;
                const newArray = [];
                for (let i = 0; i < array.length; i++) {
                    if(array[i].status === 'ACTIVE'){
                        const newItem = {}
                        newItem.label = array[i].name;
                        newItem.value = array[i].id;
                        newArray.push(newItem)
                    }
                }
                setTypes(newArray);
            }
            setLoadingContent(false);
        }
        async function GetProcess() {
            const params = {
                operation: ['SIGAM', 'Processes', 'Show'],
                urlParams: {
                    institutionId,
                    organizationUnitId,
                    processId: processInformation.id
                }
            };
            const response = await SocialMeAPI(params);
            // console.log(response)
            if (response.success) {
                const responseInformation = response.body.process;
                if (responseInformation.priority === 1) {
                    responseInformation.priority = true
                } else {
                    responseInformation.priority = false
                }
                setInitialValues(responseInformation);
                form.setFieldsValue(responseInformation)
            }
            GetTypes();
        }
        async function verifyPermission() {
            const params = {
                operation: ['SIGAM', 'Processes', 'HavePermission'],
                urlParams: {
                    institutionId,
                    organizationUnitId,
                    processId: processInformation.id
                }
            };
            const response = await SocialMeAPI(params);
            setAccessLevel(response.body.access_level);
            GetProcess();
        }
        setLoadingContent(true);
        setInitialValues();
        verifyPermission()
    }, [institutionId, organizationUnitId, processInformation, form])

    if(processInformation){
        if(processInformation.total_tramitations > 0){
            return <Result
                status="error"
                title="Não é possível alterar um processo que já foi tramitado"
            />
        }
    }

    if (accessLevel) {
        if (!accessLevel.is_in_charge) {
            return <Result
                status="error"
                title="Você não pode alterar o processo"
            />
        }
    }

    if (processInformation.status === 'INACTIVE') {
        return <Result
            status="error"
            title="Você não pode atualizar um processo arquivado"
        />
    }

    if (loadingContent) {
        return <Loading />
    }

    return (
        <div>
            <Animate
                Animation={[FadeIn]}
                duration={['0.5s']}
                delay={['0.2s']}
            >
                <Form
                    labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
                    wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
                    form={form}
                    size="small"
                    layout="vertical"
                    onFinish={onFinish}
                    scrollToFirstError
                    initialValues={initialValues}
                >
                    <FormItem
                        label="Nome do Processo"
                        name={['name']}
                        rules={[
                            {
                                required: true,
                                message: 'Insira um nome para o processo',
                            },
                        ]}
                        item={<Input />}
                    />
                    <FormItem
                        label="Tipo de Processo"
                        name={['kind']}
                        item={<Select options={types} noDefaultValue />}
                    />
                    <FormItem
                        label="Assunto"
                        name={['subject']}
                        item={<Input />}
                    />
                    <FormItem
                        label="Especificações"
                        name={['specifications']}
                        item={<TextArea />}
                    />
                    <FormItem
                        label="Processo Prioritário"
                        name={['priority']}
                        valuePropName="checked"
                        item={<Switch />}
                    />
                    <FormItem
                        label=""
                        item={
                            <div style={{ padding: '10px 0px' }}>
                                <Button type="primary" htmlType="submit">Atualizar Processo</Button>
                            </div>
                        }
                    />
                </Form>
            </Animate>
        </div >
    )
}