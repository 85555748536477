import React, { useContext } from 'react';
//Libraries
import {
    Redirect,
    useRouteMatch,
    Route,
    Switch
} from 'react-router-dom';
import { Layout } from 'antd';
import { FiUser } from 'react-icons/fi';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Sidebar from '~/pages/SIGAM/components/sidebar';
import MainNavbar from '~/pages/SIGAM/components/navbar';
// Pages
import Processes from './pages/Processes';

const { Content } = Layout;

export default function Dashboard() {
    const { theme } = useContext(ThemeContext);
    let { path, url } = useRouteMatch();

    const sidebarItems = [
        {
            index: 0,
            title: 'First',
        },
        {
            index: 1,
            title: 'Processos',
            url: `${url}/processos`,
            icon: FiUser,
        },
        {
            index: 2,
            title: 'Last',
        },
    ];

    return (
        <div>
            <Layout
                style={{
                    backgroundColor: theme.color.backgroundPrimary,
                    height: '100vh',
                }}
            >
                <Sidebar items={sidebarItems} />
                <Layout
                    style={{
                        padding: 10,
                        backgroundColor: theme.color.backgroundSecondary,
                    }}
                >
                    <MainNavbar />
                    <Content
                        style={{
                            padding: '10px 5px',
                            overflow: 'auto',
                        }}
                    >
                        <Switch>
                            <Route exact path={`${path}`}>
                                <Redirect to={`${url}/processos`} />
                            </Route>
                            <Route path={`${path}/processos`} component={Processes} />
                            <Route path={`${path}/*`}>
                                <Redirect to={`${url}/processos`} />
                            </Route>
                        </Switch>
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}