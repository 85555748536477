import React, { useContext } from 'react';
// Libraries
import { PlusOutlined } from '@ant-design/icons';
import { Form, message } from 'antd';
// Contexts
import { SIGAMContext } from '~/contexts/SIGAMContext';
// Own Components
import AddForm from './components/AddForm';
// Components
import Drawer from '~/components/drawer';
import Button from '~/components/button';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Add({ GetDataAgain }) {
    const { activeInstitution } = useContext(SIGAMContext);
    const [form] = Form.useForm();

    const DrawerBody = () => {
        return <AddForm form={form} />
    }

    const AddFooterButton = ({ CloseDrawer }) => {
        async function onFinish(data) {
            if(data.status){
                data.status = 'ACTIVE';
            }
            const params = {
                operation: ['SIGAM', 'OrganizationalUnits', 'Add'],
                urlParams: {
                    institutionId: activeInstitution.id
                },
                data
            };
            const response = await SocialMeAPI(params);
            if(response.success){
                message.success('Unidade Organizacional criada com sucesso!');
                GetDataAgain();
                return CloseDrawer();
            }
                return message.error("Alguma coisa aconteceu...");
        }
        return (
            <Button
                type="primary"
                onClick={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onFinish(values);
                        })
                        .catch((info) => {
                            // console.log('Validate Failed:', info);
                        });
                }}
            >
                Adicionar
            </Button>
        )
    }

    return (
        <Drawer
            Trigger={
                <Button icon={<PlusOutlined />} type="primary">
                    Nova Unidade Organizacional
              </Button>
            }
            title="Nova Unidade Organizacional"
            DrawerContentComponent={DrawerBody}
            footer
            footerCancelButton
            FooterMainButton={AddFooterButton}
        />
    );
}
