import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.backgroundColor};
  height: inherit;
  padding: 5px 0px 5px 5px;
  width: ${(props) => props.width};
  transition: all 0.2s;
`;

export const Menu = styled.div`
  background-color: ${(props) => props.backgroundColor};
`;
