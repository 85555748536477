import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Button, Drawer, message } from 'antd';

import { useArchivesPageManager } from '../../../../contexts/ArchivesPageManager/Provider';
import ArchiveForm from '../../../Form';

export default function NewArchive() {
  const [loading, setLoading] = useState(false);

  const { CreateArchive } = useArchivesPageManager();

  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  async function onFinish(values) {
    if (!values.is_from_user && !values.is_from_organizational_unit) {
      message.error('O acervo precisa ter pelo menos um dono');
      return;
    }

    setLoading(true);

    const response = await CreateArchive(values);

    setLoading(false);

    if (response.success) form.resetFields();
  }

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={showDrawer}>
        Novo acervo
      </Button>

      <Drawer
        title="Novo acervo"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <ArchiveForm
          form={form}
          submitButtonText="Criar acervo"
          onFinish={onFinish}
          initialValues={{ visibility: 'PUBLIC', status: 'ACTIVE' }}
          loadingButton={loading}
        />
      </Drawer>
    </>
  );
}
