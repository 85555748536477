import React, { useEffect, useState } from 'react';
// Libraries
import { useParams } from 'react-router-dom';
import { Result } from 'antd';
import Loading from '~/components/loading';
// Own Components
import IsSignatory from './components/IsSignatory';
import NotSignatory from './components/NotSignatory';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { UserMainDocument, UserId } from '~/utils/UserInformation';

export default function Subscribe({ document, propertys }) {
    const { institutionId, organizationUnitId } = useParams();
    const [signature, setSignature] = useState([]);
    const [isSignatory, setIsSignatory] = useState(false);
    const [loadingContent, setLoadingContent] = useState(true);
    const mainDocument = UserMainDocument();
    const id = UserId();

    const documentInformation = document || propertys.document[0];

    async function GetPeopleAgain() {
        const params = {
            operation: ['SIGAM', 'Documents', 'Signatories', 'Get'],
            urlParams: {
                institutionId,
                organizationUnitId,
                documentId: documentInformation.id
            },
            data: {
                filter: {
                    searchString: mainDocument.replace(/[^\d]+/g, '')
                },
            }
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            if (propertys && propertys.GetDataAgain) {
                propertys.GetDataAgain();
            }
            if (response.body.signatory.elements.length > 0) {
                if (id === response.body.signatory.elements[0].person_id) {
                    setIsSignatory(true);
                    setSignature(response.body.signatory.elements[0])
                }
            }
        }
    }

    useEffect(() => {
        async function GetPeople() {
            const params = {
                operation: ['SIGAM', 'Documents', 'Signatories', 'Get'],
                urlParams: {
                    institutionId,
                    organizationUnitId,
                    documentId: documentInformation.id
                },
                data: {
                    filter: {
                        searchString: mainDocument.replace(/[^\d]+/g, '')
                    },
                }
            };
            const response = await SocialMeAPI(params);
            // console.log(response)
            if (response.success) {
                if (response.body.signatory.elements.length > 0) {
                    if (id === response.body.signatory.elements[0].person_id) {
                        setIsSignatory(true);
                        setSignature(response.body.signatory.elements[0])
                    }
                }
            }
            setLoadingContent(false);
        }
        GetPeople();
    }, [documentInformation, institutionId, organizationUnitId, id, mainDocument])

    if (documentInformation.kind === 'EXTERNAL') {
        return <Result
                status="error"
                title="Um documento externo não permite assinaturas"
                subTitle="Caso você queira assinar esse documento, recomenda-se criar um documento digital na plataforma e adicionar esse documento como anexo"
            />
    }

    if(loadingContent){
        return <Loading text="Verificando Assinaturas" />
    }

    function RenderContent() {
        if (isSignatory) {
            return <IsSignatory document={documentInformation} signature={signature} GetPeopleAgain={GetPeopleAgain} />
        }
        return <NotSignatory />
    }

    return (
        <div>
            {RenderContent()}
        </div>
    )
}