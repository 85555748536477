import { message } from 'antd';
import SigamAPI from '~/utils/SigamAPI';

export default async function UpdateArchive({
  archive,
  archiveId,
  institutionId,
}) {
  archive.metas.forEach((meta, index) => {
    const order = index + 1;
    archive[`meta${order}`] = meta;
  });

  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/archive/${archiveId}`,
    method: 'PATCH',
    data: archive,
  });

  if (response.success) {
    message.success('Acervo editado com sucesso');
  } else {
    message.error('Erro ao editar o acervo');
  }

  return response;
}
