import SigamAPI from '~/utils/SigamAPI';

export default async function GetCharts({ search, institutionId }) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/chats`,
    method: 'POST',
    data: {
      filter: {
        chat_name: search,
      },
      pagination: {
        limit: 0,
        sort: {
          by: 'last_message_at',
          order: 'DESC',
        },
      },
    },
  });

  return response;
}
