import React from 'react';

import { Empty } from 'antd';

import SearchAdmin from './components/SearchAdmin'
import StatusButton from './components/StatusButton';

import { ImageWithPrimaryAndSecondaryTextCell } from '~/components/table/cells';

import Table from '~/components/table';
import Pagination from '~/components/pagination';

import { cnpjMask } from '~/utils/Masks';

export default function Content({ data, total, pageParameters, setPageParameters, getDataAgain }) {


  const tableColumns = [
    {
      title: 'Instituição',
      dataIndex: 'institution_avatar',
      key: 'institution_avatar',
      width: '60%',
      render: (institution_avatar, { institution_name, institution_cpnj_number }) => (
        <ImageWithPrimaryAndSecondaryTextCell
          src={institution_avatar}
          primaryText={institution_name}
          secondaryText={cnpjMask(institution_cpnj_number)}
        />
      ),
    },
    {
      title: 'Ações',
      dataIndex: 'institution_id',
      key: 'institution_id',
      align: "center",
      width: '40%',
      render: (institution_id, record) => (
        <div style={{ display: 'flex' }}>
          <div>
            <SearchAdmin institutionId={institution_id}>
              Vincular Administrador
            </SearchAdmin>
          </div>
          <div style={{ marginLeft: 10 }}>
            <StatusButton institutionId={institution_id} status={record.status} getDataAgain={getDataAgain} />
          </div>
        </div>
      ),
    }
  ];


  return data.length !== 0 ? (
    <>
      <Table tableColumns={tableColumns} dataReceived={data} />
      <Pagination
        onChange={(currentPage, limit) => setPageParameters({ currentPage, limit })}
        onShowSizeChange={(currentPage, limit) => setPageParameters({ currentPage, limit })}
        total={total}
        limit={pageParameters.limit}
        currentPage={pageParameters.currentPage}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Total ${total} itens`}
      />
    </>
  ) : (
    <Empty description="Não há nenhuma instituição vinculada" />
  )
}
