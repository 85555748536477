import React, { useState, useContext } from 'react';
import { Drawer, Row, Col } from 'antd';
import { MobileOutlined, PlayCircleOutlined } from '@ant-design/icons';
// Components
import Text from '~/components/typography/text';
import Card from '~/components/card';

import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

const trainingVideos = [
    {
        label: 'Instalando APP SIGAM ANDROID',
        src: 'https://www.youtube.com/shorts/QPAudNdnqsA'
    },
    {
        label: 'APP SIGAM: Assinatura Digital ',
        src: 'https://www.youtube.com/shorts/7KPt1JeLPFk'
    }
]

export default function TrainingDrawer() {
    const { theme } = useContext(ThemeContext);
    const { isMobile } = useContext(ResponsiveContext);
    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    function VideoContainer({ video }) {
        return (
            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '20px' }}>
                <a href={video.src} target="_blank" rel="noreferrer">
                    <Card
                        style={{
                            border: '2px solid',
                            borderColor: theme.color.primary,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <PlayCircleOutlined style={{ fontSize: '18pt' }} />
                            <Text>{video.label}</Text>
                            <Text style={{ fontSize:"8pt", color: 'gray'}}>Clique para acessar</Text>
                        </div>
                    </Card>
                </a>
            </Col>
        )
    }

    return (
        <>
            <div onClick={showDrawer}>
                <Card
                    onClick={showDrawer}
                    style={{
                        border: '2px solid',
                        borderColor: theme.color.primary,
                        cursor: 'pointer'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <MobileOutlined style={{ fontSize: '24pt' }} />
                        <Text>Versão Mobile:</Text>
                        <Text>Série de vídeos</Text>
                    </div>
                </Card>
            </div>



            <Drawer title="Versão Mobile" placement="right" width={isMobile ? '100%' : '50%'} onClose={onClose} visible={visible}>
                <Row style={{ marginTop: 4 }}>
                    {trainingVideos.map((video, index) => (
                        <VideoContainer key={index} video={video} />
                    ))}
                </Row>
            </Drawer>
        </>
    );
}