import styled from 'styled-components';

export const Content = styled.div`
  cursor: pointer;
  transition: all 250ms;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  border-bottom: 1px solid;
  border-color: #c3c3c3;

  &:hover {
    background-color: #f0f3f3;
  }

  &.active {
    background-color: #f0f3f3;
  }
`;

export const VStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
