import React, { useState } from 'react';

import { Form, Drawer } from 'antd';
import { useArchiveFilesPageManager } from '../../../../../../../../contexts/ArchiveFilesPageManager/Provider';
import FileForm from '../../../../../../../Form';

export default function FileUpdateForm({ file, open, setOpen }) {
  const [loading, setLoading] = useState(false);

  const { UpdateFile } = useArchiveFilesPageManager();

  const [form] = Form.useForm();

  const onClose = () => {
    setOpen(false);
  };

  async function onFinish(values) {
    setLoading(true);
    const response = await UpdateFile({ file: values, fileId: file.id });
    if (response.success) onClose();
    setLoading(false);
  }

  return (
    <Drawer
      title="Editando arquivo"
      placement="right"
      onClose={onClose}
      open={open}
    >
      <FileForm
        initialValues={file}
        form={form}
        onFinish={onFinish}
        loadingButton={loading}
        submitButtonText="Editar arquivo"
      />
    </Drawer>
  );
}
