import React from 'react';
// Libraries
import { AiOutlineFilePdf } from 'react-icons/ai';
// Components
import Button from '~/components/button';

export default function FileButton({ link }) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <AiOutlineFilePdf style={{ fontSize: '26pt' }} />
                <a href={link} target="_blank" rel="noopener noreferrer">
                    <Button type="dashed">Visualizar Arquivo</Button>
                </a>
            </div>
        </div>
    )
}