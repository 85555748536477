// Actions
import AccessLevel from './AccessLevel';
import Attachments from './Attachments';
import CentralPrint from './CentralPrint';
import Contributors from './Contributors';
import Delete from './Delete';
import Interested from './Interested';
import LexML from './LexML';
import Proceed from './Proceed';
import ProceedDetails from './ProceedDetails';
import Signatories from './Signatories';
import Subscribe from './Subscribe';
import Visualize from './Visualize';
import Edit from './Edit';
import Historic from './Historic';
import Close from './Close';

export default {
  AccessLevel: {
    text: 'Nível de Acesso',
    content: AccessLevel,
  },
  Attachments: {
    text: 'Anexos',
    content: Attachments,
  },
  CentralPrint: {
    text: 'Central de Impressão',
    content: CentralPrint,
  },
  Contributors: {
    text: 'Editores',
    content: Contributors,
  },
  Delete: {
    text: 'Deletar',
    content: Delete,
  },
  Close: {
    text: 'Arquivar',
    content: Close,
  },
  Interested: {
    text: 'Interessados',
    content: Interested,
  },
  LexML: {
    text: 'LexML',
    content: LexML,
  },
  Edit: {
    text: 'Editar',
    content: Edit,
  },
  Historic: {
    text: 'Histórico',
    content: Historic,
  },
  ProceedDetails: {
    text: 'Detalhes de tramitação',
    content: ProceedDetails,
  },
  Signatories: {
    text: 'Assinantes',
    content: Signatories,
  },
  Subscribe: {
    text: 'Assinar',
    content: Subscribe,
  },
  Proceed: {
    text: 'Tramitar',
    content: Proceed,
  },
  Visualize: {
    text: 'Visualizar',
    content: Visualize,
  },
};
