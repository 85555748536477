import React, { useState } from 'react';
// Libraries
import { message } from 'antd';
import { useParams } from 'react-router-dom';
import { VscFileSubmodule } from 'react-icons/vsc';
// Components
import Input from '~/components/input';
import Space from '~/components/space';
import Button from '~/components/button';
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { UserMainDocument } from '~/utils/UserInformation';

export default function ByPassword({ setPageActive, process, GetDataAgain }) {
    const { institutionId, organizationUnitId } = useParams();
    const [password, setPassword] = useState();
    const mainDocument = UserMainDocument();

    async function Sign() {
        const params = {
            operation: ['SIGAM', 'Processes', 'Finish'],
            urlParams: {
                institutionId,
                organizationUnitId,
                processId: process.id
            },
            data: {
                login: mainDocument.replace(/[^\d]+/g, ''),
                password
            }
        };
        const response = await SocialMeAPI(params);
        // console.log(response)
        if (response.success) {
            message.success('Processo arquivado com sucesso!');
            setPageActive('Filed');
            GetDataAgain()
        } else {
            message.error('Alguma coisa aconteceu...');
        }
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Space direction="vertical" align="center">
                <Title level={4} style={{ textAlign: 'center' }}>Arquivar Processo</Title>
                <VscFileSubmodule style={{ fontSize: '40pt' }} />
                <Text>Insira seus dados de acesso para confirmar</Text>
                <Input value={mainDocument} />
                <Input type="password" onChange={(event) => setPassword(event.target.value)} />
                <Button type="primary" style={{ width: '100%' }} onClick={() => Sign()}>Arquivar</Button>
            </Space>
        </div>
    )
}