export default {
  SM6463D81: {
    message: 'The person profile was successfully Updated',
    // Profile.Edit
  },
  SMF4DF986: {
    message: 'The contact was successfully Updated',
    // Profile.Contact.Edit
  },
  SME396849: {
    message: 'The relationship was successfully Updated',
    // Profile.Relantionship.Delete
  },
  SM3034963: {
    message: 'The role was successfully Updated',
  },
  SMD571DD1: {
    message: 'The place was successfully Updated',
  },
  SM4513863: {
    message: "The 'device' was successfully Updated",
  },
  SMDBCDB5B: {
    message: "The 'activity' was successfully Updated",
  },
  SMD8B9437: {
    message: "The 'Custom questions' was successfully Updated",
  },
  SM3A85CC8: {
    message: "The 'product/service offering' was successfully Updated",
  },
  SM4CA90C9: {
    message: "The 'account' was successfully Updated",
  },
  SM2956BDF: {
    message: "The 'sigam organizational unity' was successfully Updated",
  },
  SMD2C334A: {
    message: "The 'sigam permission' was successfully Updated",
  },
  SMD37241D: {
    message: "The 'permission' was successfully Updated",
  },
  SMF13CB5E: {
    message: "The 'document model' was successfully Updated",
  },
  SM5F6038E: {
    message: "The 'document' was successfully Updated"
  },
  SMF7E2F06: {
    message: "The 'attachment' was successfully Updated"
  },
  SM6B5DE4B: {
    message: "The 'process Kind' was successfully Updated"
  },
  SM0230923: {
    message: "The 'process' was successfully Updated"
  },
  SM74A13D2: {
    message: "The 'process document' was successfully Updated"
  },
  SMF63ECAE: {
    message: "The 'Password' was successfully Updated"
  },
  SMB86DBEB: {
    message: "The 'sigam contract' was successfully Updated"
  }
};
