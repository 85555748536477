import React, { useContext, useState } from 'react';
// Libraries
import { Drawer } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { ThemeContext } from '~/contexts/ThemeContext';
// Own Components
import QrReader from './components/QrReader';
import Description from './components/Description';
// Components
import Text from '~/components/typography/text';

export default function QRCodeDrawer({
  Trigger,
  SecondStepComponent,
  title,
  description,
  kind,
  secondStepParameters,
  neverOpen,
  setValue
  //Component,
  //qrCodeType,
  //qrCodeDescription,
  //qrCodeTitle
}) {
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  const [visibility, setVisibility] = useState(false);
  const [QRCodeInformation, setQRCodeInformation] = useState();


  function ShowQRCodeReader() {
    if (neverOpen) {
      return setVisibility(false);
    }
    setVisibility(true);
  }

  function CloseQRCodeReader() {
    setVisibility(false);
    setQRCodeInformation();
  }

  async function GetQRCodeScanResult(scanResult) {
    if(scanResult && scanResult.includes(`documento=`)){
      setValue(scanResult.split('=')[1]);
      return CloseQRCodeReader();
    }
  }

  async function GetQRCodeSancError(err) {
    console.error(`Error: ${err}`);
  }

  return (
    <>
      <div onClick={() => ShowQRCodeReader()}>{Trigger}</div>
      <Drawer
        width={`${isMobile ? '100vw' : '30vw'}`}
        closable={false}
        placement="right"
        visible={visibility}
        title={
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ArrowLeftOutlined
              onClick={CloseQRCodeReader}
              style={{
                color: theme.color.textOnPrimaryBackground,
                cursor: 'pointer',
              }}
            />
            <Text
              style={{
                color: theme.color.textOnPrimaryBackground,
                marginLeft: 'auto',
              }}
            >
              {title}
            </Text>
          </div>
        }
        headerStyle={{
          backgroundColor: theme.color.backgroundPrimary,
          borderRadius: 0,
        }}
        bodyStyle={{
          padding: 0,
          //overflowY: 'hidden',
          maxHeight: 'calc(100vh - 55px)',
          backgroundColor: theme.color.backgroundPrimaryDark,
        }}
      >
        {QRCodeInformation ? (
          <div>
            <SecondStepComponent
              QRCodeInformation={QRCodeInformation}
              setQRCodeInformation={setQRCodeInformation}
              CloseQRCodeReader={CloseQRCodeReader}
              secondStepParameters={secondStepParameters}
            />
          </div>
        ) : (
          <div>
            <Description
              text={
                description ||
                'Use seu dispositivo ou o aplicativo do Social Me para ler o QR Code'
              }
            />
            <QrReader
              GetQRCodeScanResult={GetQRCodeScanResult}
              GetQRCodeSancError={GetQRCodeSancError}
            />
          </div>
        )}
      </Drawer>
    </>
  );
}

/* 
{QRCodeInfo.length === 0 ? (
          <>
            {QRCodeError ? (
              <Description
                icon={true}
                color={theme.color.danger}
                text={'QRCode Inválido'}
              />
            ) : (
              <Description
                color={theme.color.textOnPrimaryBackground}
                text={qrCodeDescription}
              />
            )}
            <QRCodeReader
              QRCodeType={qrCodeType}
              setQRCodeError={setQRCodeError}
              setQRCodeInformation={setQRCodeInformation}
            />
          </>
        ) : (
          <Component.mount
            CloseDrawer={CloseQRCodeReader}
            setQRCodeInformation={setQRCodeInformation}
            QRCodeInfo={QRCodeInfo}
            realoadActivitysFromAPI={Component.actions.LoadActivitysFromAPI}
          />
        )}
*/
