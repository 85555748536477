import API from 'utils/SigamAPI'

export default async function ListPublicProcesses({ filters, institutionId }) {
  const response = await API({
    method: 'POST',
    url: `/SIGAM/public/${institutionId}/processes`,
    data: filters
  })

  return response
}
