import React from 'react';
import { Table, Card, Space, Typography, Tag, Avatar } from 'antd';
import { usePageManager } from '../../../../contexts/PageManager/Provider';
import { CapitalizeString } from '~/utils/Transformations';
import {
  DateInBrazilianFormat,
  HourInBrazilianFormat,
} from '~/utils/DateAndTime';
import ViewButton from './components/ViewButton';

const { Text } = Typography;

export default function TableContainer() {
  const { messages } = usePageManager();

  const columns = [
    /*
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) =>
        record.status === 'FAILED' ? (
          <Tag color="error">Falha</Tag>
        ) : (
          <Tag color="success">Enviado</Tag>
        ),
    },
    */
    {
      title: 'Data de envio',
      dataIndex: 'date',
      key: 'date',
      render: (_, record) => (
        <Space direction="vertical" align="center">
          <Text>{DateInBrazilianFormat(record.created_at)}</Text>
          <Text type="secondary">
            {HourInBrazilianFormat(record.created_at)}
          </Text>
        </Space>
      ),
    },
    {
      title: 'Método',
      dataIndex: 'method',
      key: 'method',
      render: (_, record) => <Tag color="processing">{record.method}</Tag>,
    },
    {
      title: 'Enviado por',
      dataIndex: 'created_by_name',
      key: 'created_by_name',
      render: (_, record) => (
        <Space direction="horizontal" align="center">
          <Avatar src={record.created_by_avatar} />
          <Text>{CapitalizeString(record.created_by_name)}</Text>
        </Space>
      ),
    },
    {
      title: 'Contatos',
      dataIndex: 'contact',
      key: 'contact',
      render: (_, record) => (
        <Space>
          {record.contacts.map((item, index) => {
            return <Tag key={index}>{item}</Tag>;
          })}
        </Space>
      ),
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <ViewButton record={record} />
        </Space>
      ),
    },
  ];

  return (
    <Card bordered={false} bodyStyle={{ padding: 0 }}>
      <Table
        dataSource={messages}
        columns={columns}
        pagination={false}
        rowKey="id"
      />
    </Card>
  );
}
