import React, { useState, useEffect } from 'react';
//Libraries
import { Row, Col, Form } from 'antd';
//Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import TextArea from '~/components/textArea';
import Checkbox from '~/components/checkbox';
import Loading from '~/components/loading';

export default function EditForm({ form, record }) {
  const [loadingForm, setLoadingForm] = useState(true);
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    async function Prepare() {
      let values = record;
      if (record.status === 'ACTIVE') {
        values.status = true;
      } else {
        values.status = false
      }
      setInitialValues(values);
      setLoadingForm(false);
    }
    if (record) {
      Prepare()
    }
  }, [record])

  if (loadingForm) {
    return <Loading text="Carregando formulário" />
  }

  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      form={form}
      layout="vertical"
      size="small"
      scrollToFirstError
      initialValues={initialValues}
    >
      <Row>
        <Col span={24}>
          <FormItem
            label="Nome do tipo de processo"
            name={['name']}
            rules={[
              {
                required: true,
                message: 'Insira um nome para o tipo de processo',
              },
            ]}
            item={<Input />}
          />
        </Col>
        <Col span={24}>
          <FormItem
            label="Situação"
            name="status"
            valuePropName="checked"
            item={<Checkbox text={'Tipo de processo ativo'} />}
          />
        </Col>
        <Col span={24}>
          <FormItem
            label={'Descrição'}
            name={['description']}
            item={<TextArea />}
          />
        </Col>
        <Col span={24}>
          <FormItem
            label={'Especificações'}
            name={['specifications']}
            item={<TextArea />}
          />
        </Col>
      </Row>
    </Form>
  );
}