import React from 'react';
import { Col, Row } from 'antd';
import Institution from './components/Institution';
import Processes from './components/Processes';
import Documents from './components/Documents';
import Users from './components/Users';
import Subjects from './components/Subjects';

export default function Content() {
  return (
    <>
      <Row style={{ marginBottom: 8 }}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={6}
          xl={6}
          style={{ marginBottom: '4px' }}
        >
          <Users />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={6}
          xl={6}
          style={{ marginBottom: '4px' }}
        >
          <Institution />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={6}
          xl={6}
          style={{ marginBottom: '4px' }}
        >
          <Processes />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={6}
          xl={6}
          style={{ marginBottom: '4px' }}
        >
          <Documents />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={12} lg={24} xl={24}>
          <Subjects />
        </Col>
      </Row>
    </>
  );
}
