import React, { useState, createContext, useContext } from 'react';
import { SIGAMContext } from './SIGAMContext';

export const PermissionContext = createContext();

const PermissionContextProvider = ({ children }) => {
  const { activeInstitution } = useContext(SIGAMContext);
  const [unitPermissions, setUnitPermissions] = useState();

  function CheckPermission(permissionName) {
    if(activeInstitution.status === "INACTIVE") return false
    for (const key in unitPermissions) {
      if(key === permissionName){
        return true;
      }
    }
    return false;
  }

  return (
    <PermissionContext.Provider value={{ CheckPermission, setUnitPermissions }}>
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionContextProvider;
