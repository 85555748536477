import React from 'react';
import PageManagerProvider from './contexts/PageManager/Provider';
import Page from './page';

export default function LexML({ document, propertys }) {
  const documentInformation = document || propertys.document[0];

  if (!documentInformation) return <></>;

  return (
    <PageManagerProvider document={documentInformation}>
      <Page />
    </PageManagerProvider>
  );
}
