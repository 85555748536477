import styled from 'styled-components';

export const Header = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 8px;
`;

export const Center = styled.div`
  display: none !important;
  width: max-content;
  cursor: pointer;

  @media (max-width: 608px) {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
`;

export const HStack = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
