import React, { useContext } from 'react';
//Libraries
import {
    LockOutlined,
    LoadingOutlined
} from '@ant-design/icons';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Text from '~/components/typography/text';

export default function LoadingGateway() {
    const { theme } = useContext(ThemeContext);
    return (
        <div
            style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <div
                style={{
                    height: 120,
                    width: 120,
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <LoadingOutlined style={{ fontSize: '50pt', color: theme && theme.color && theme.color.primary }} />
                <LockOutlined style={{ position: 'absolute', fontSize: '14pt', color: theme && theme.color && theme.color.primaryDark }} />
            </div>
            <div>
                <Text>Criando ambiente seguro</Text>
            </div>
        </div>
    )
}