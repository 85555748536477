import React, { useMemo } from 'react';
import { Avatar, Typography } from 'antd';
import { HiUserGroup } from 'react-icons/hi';

import * as S from './style';
import { CapitalizeString } from '~/utils/Transformations';
import { useAuth } from '~/contexts/AuthContext';
import { useTheme } from '~/contexts/ThemeContext';
import {
  DiferenceBetweenTheCurrentDate,
  DateInBrazilianFormat,
  HourInBrazilianFormat,
} from '~/utils/DateAndTime';

const { Text } = Typography;

export default function ChatItem({ chat, hideMessage }) {
  const { user } = useAuth();
  const { theme } = useTheme();

  const isLoggedUser = chat.last_message_from === user.name;

  const userThatSendLastMessage = chat.last_message_from
    ? CapitalizeString(chat.last_message_from.slice(' ')[0])
    : '';

  const lastMessage = chat.last_message
    ? chat.last_message.substring(0, 20)
    : '';

  const ellipses = lastMessage.length === 20 ? '...' : '';

  const lastMessageData = useMemo(() => {
    if (!chat.last_message_at) return '';

    const result = DiferenceBetweenTheCurrentDate(chat.last_message_at);

    if (result === 0) return HourInBrazilianFormat(chat.last_message_at);

    return DateInBrazilianFormat(chat.last_message_at);
  }, [chat]);

  if (chat.kind === 'PERSON') {
    return (
      <S.Container>
        <S.HStack>
          <Avatar size={30} src={chat.person_avatar} />

          <S.VStack>
            <Text>{CapitalizeString(chat.person_name)}</Text>
            {chat.last_message && (
              <Text style={{ color: theme.color.secondaryDark }}>
                {isLoggedUser ? 'Eu' : userThatSendLastMessage}: {lastMessage}
                {ellipses}
              </Text>
            )}
          </S.VStack>
        </S.HStack>

        <S.Center>
          {chat.last_message_at && <Text>{lastMessageData}</Text>}
        </S.Center>
      </S.Container>
    );
  }

  return (
    <S.Container>
      <S.HStack>
        <HiUserGroup size={30} />

        <S.VStack>
          <Text>{CapitalizeString(chat.name)}</Text>

          {chat.last_message && (
            <Text style={{ color: theme.color.secondaryDark }}>
              {isLoggedUser ? 'Eu' : userThatSendLastMessage}: {lastMessage}
              {ellipses}
            </Text>
          )}
        </S.VStack>
      </S.HStack>

      <S.Center>
        {chat.last_message_at && <Text>{lastMessageData}</Text>}
      </S.Center>
    </S.Container>
  );
}
