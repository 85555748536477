import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Spin, Progress, Typography, message } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import UploadArchiveFile from '~/services/calls/archive/files/upload';

import { useArchiveFilesPageManager } from '../../../../../../../../../../contexts/ArchiveFilesPageManager/Provider';

const { Text } = Typography;

export default function FileItem({
  file,
  setFileUrl,
  fileStatus,
  setFileStatus,
}) {
  const [uploadProgress, setUploadProgress] = useState(false);

  const { archive, institutionId } = useArchiveFilesPageManager();

  const archiveId = archive.id;

  function onUploadProgress(event) {
    const fileLoad = (event.loaded / event.total) * 100;

    setUploadProgress(parseFloat(fileLoad.toFixed('2')));
  }

  const fileExtension = useMemo(() => {
    const splitedFilename = file.name.split('.');

    return splitedFilename[splitedFilename.length - 1];
  }, [file]);

  const UploadFile = useCallback(async () => {
    const response = await UploadArchiveFile({
      file,
      archiveId,
      institutionId,
      onUploadProgress,
    });

    if (response.success) {
      setFileUrl(response.body.file);
      setFileStatus('creation');
    } else {
      message.error('Upload de arquivo deu erro');
    }
  }, []);

  useEffect(() => {
    UploadFile();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Text>{file.name}</Text>

        {fileStatus ? <CheckCircleOutlined /> : <Spin />}
      </div>

      <Progress percent={uploadProgress} />
    </div>
  );
}
