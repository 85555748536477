import React from 'react';
// Libraries
import { VscFileSubmodule } from 'react-icons/vsc';
// Components
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
import Button from '~/components/button';

export default function Message({ setPageActive }) {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%'
        }}>
            <Title level={4} style={{ textAlign: 'center' }}>Arquivar Processo</Title>
            <VscFileSubmodule style={{ fontSize: '40pt' }} />
            <Text style={{ textAlign: 'center' }}>
            Arquivar um processo é uma ação irreversível. Tenha em mente que você não poderá mais realizar operações quando o processo estiver arquivado.
            </Text>
            <Button
                type="primary"
                style={{ marginTop: 10 }}
                onClick={() => setPageActive('ByPassword')}
            >
                Arquivar processo
                </Button>
        </div>
    )
}