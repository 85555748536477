export default {
  example: {
    birth: {
      original: '0000/00/00',
      text: '00/00/0000',
    },
    companyName: {
      original: 'Ex.: My business Ltda.',
      text: 'Ex.: Minha empresa Ltda.',
    },
    fantasyName: {
      original: 'Ex.: My business',
      text: 'Ex.: Minha empresa',
    },
    fullName: {
      original: '',
      text: 'Ex.: João Miguel da Silva Pires',
    },
    mainContact: {
      original: '00 00000-0000 or yourname@email.com',
      text: '00 00000-0000 ou seunome@email.com',
    },
    motherName: {
      original: '',
      text: 'Ex.: Maria José da Silva',
    },
    password: {
      original: '********',
      text: '********',
    },
  },
  helpText: {
    mainContact: {
      original: 'Enter an email or phone you use often',
      text: 'Insira um email ou telefone que use com frequência',
    },
  },
  invalid: {
    CNPJ: {
      original: 'Invalid CNPJ',
      text: 'CNPJ inválido',
    },
    CPF: {
      original: 'Invalid CPF',
      text: 'CPF inválido',
    },
    date: {
      original: 'Invalid date',
      text: 'Data inválida',
    },
    email: {
      original: 'Invalid email',
      text: 'Email inválido',
    },
    fields: {
      original: 'Check the fields and try again',
      text: 'Verifique os campos e tente novamente',
    },
    motherName: {
      original: 'Invalid mother name',
      text: 'Este nome está certo mesmo?',
    },
    name: {
      original: 'Invalid name',
      text: 'Este nome está certo mesmo?',
    },
    passwordConfirm: {
      original: 'The two passwords are not the same',
      text: 'As duas senhas não são iguais',
    },
  },
  label: {
    birth: {
      original: 'Birth',
      text: 'Nascimento',
    },
    companyName: {
      original: 'Company name',
      text: 'Razão social',
    },
    CPFOrCNPJ: {
      original: 'CPF or CNPJ',
      text: 'CPF ou CNPJ',
    },
    CNPJ: {
      original: 'CNPJ',
      text: 'CNPJ',
    },
    CPF: {
      original: 'CPF',
      text: 'CPF',
    },
    fantasyName: {
      original: 'Fantasy name',
      text: 'Nome fantasia',
    },
    fullName: {
      original: 'Full name',
      text: 'Nome completo',
    },
    mainContact: {
      original: 'Main contact',
      text: 'Contato principal',
    },
    motherName: {
      original: 'Mother name',
      text: 'Nome da mãe',
    },
    password: {
      original: 'Password',
      text: 'Senha',
    },
    passwordConfirm: {
      original: 'Password confirm',
      text: 'Confirme sua senha',
    },
    sex: {
      original: 'Sex',
      text: 'Sexo',
    },
  },
  placeholder: {
    mainContact: {
      original: 'Email or phone',
      text: 'Email ou telefone',
    },
    select: {
      original: 'Select',
      text: 'Selecione',
    },
  },
  required: {
    birth: {
      original: 'Birth is required',
      text: 'Por favor, insira um nascimento',
    },
    companyName: {
      original: 'Company name is required',
      text: 'Por favor, insira uma razão social',
    },
    fantasyName: {
      original: 'Fantasy name is required',
      text: 'Por favor, insira um nome fantasia',
    },
    mainContact: {
      original: 'You need to add a contact',
      text: 'É necessário adicionar um contato',
    },
    motherName: {
      original: 'Mother name is required',
      text: 'Por favor, insira o nome da sua mãe',
    },
    name: {
      original: 'Name is required',
      text: 'Por favor, insira um nome válido',
    },
    password: {
      original: 'It is important to enter a password',
      text: 'É importante informar uma senha',
    },
    passwordConfirm: {
      original: 'Confirm your password',
      text: 'Confirme a sua senha',
    },
    sex: {
      original: 'Select one of the options',
      text: 'Selecione uma das opções',
    },
  },
};
