import React, { useEffect, useContext, useState, useCallback } from 'react';
import PageManagerContext from './Context';
import { useParams } from 'react-router-dom';
import _readMessages from '~/services/calls/communication/external/read';
import _createMessage from '~/services/calls/communication/external/create';

export default function PageManagerProvider({ children }) {
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [messages, setMessages] = useState([]);
  const { institutionId } = useParams();
  const [totalItems, setTotalItems] = useState(0);
  const [filter] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    sort: {
      by: 'id',
      order: 'DESC',
    },
  });

  async function createMessage(data, method) {
    const response = await _createMessage({ institutionId, data, method });
    if (response) {
      readMessages(filter, pagination);
    }
    return response;
  }

  const readMessages = useCallback(async (filter, pagination) => {
    setLoadingMessages(true);
    const response = await _readMessages({
      institutionId,
      data: {
        filter,
        pagination,
      },
    });
    if (response.success) {
      setMessages(response.body.communication_sents.elements);
      setTotalItems(response.body.communication_sents.totalElements);
    }
    setLoadingMessages(false);
  }, []);

  useEffect(() => {
    async function readAsyncData() {
      readMessages(filter, pagination);
    }
    if (institutionId) {
      readAsyncData();
    }
  }, [filter, pagination]);

  return (
    <PageManagerContext.Provider
      value={{
        loadingMessages,
        messages,
        createMessage,
        filter,
        totalItems,
        pagination,
        setPagination,
      }}
    >
      {children}
    </PageManagerContext.Provider>
  );
}

export const usePageManager = () => useContext(PageManagerContext);
