import React from 'react';
// Libraries
import { Result } from 'antd';
// Components
import Button from '~/components/button';

export default function Finished({ CloseDrawer, setActivePage, GetDataAgain }) {

    function onClose() {
        CloseDrawer();
        setActivePage('Creation');
        GetDataAgain();
    }

    return (
        <div>
            <Result
                status="success"
                title="Documento criado com sucesso!"
                extra={[
                    <Button type="primary" key={1} onClick={() => onClose()}>Fechar</Button>
                ]}
            />

        </div>
    )
}