import React, { useState, useEffect } from 'react';
// Libraries
import { Row, Col, Result } from 'antd';
import { AiOutlineLink } from 'react-icons/ai';
import { BsFileEarmarkPlus } from 'react-icons/bs';
import Animate, { FadeIn } from 'animate-css-styled-components';
import { useParams } from 'react-router-dom';
// Own Components
import SelectionItem from './components/SelectionItem';
import ProcessView from './components/ProcessView';
// Components
import Text from '~/components/typography/text';
import Divider from '~/components/divider';
import Loading from '~/components/loading';
// methods
import AttachToExistingProcess from './methods/AttachToExistingProcess';
import AttachInNewProcess from './methods/AttachInNewProcess';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Proceed({ document, propertys, CloseDrawer }) {
    const { institutionId, organizationUnitId } = useParams();
    const [activeMethod, setActiveMethod] = useState();
    const [process, setProcess] = useState();
    const [loadingContent, setLoadingContent] = useState(true);
    const [accessLevel, setAccessLevel] = useState();

    const documentInformation = document || propertys.document[0];

    useEffect(() => {
        async function TransformStatus(){
            const params = {
                operation: ['SIGAM', 'Documents', 'Edit'],
                urlParams: {
                    institutionId,
                    organizationUnitId,
                    documentId: documentInformation.id
                },
                data: {
                    status: 'ACTIVE'
                }
            };
            await SocialMeAPI(params);
        }
        async function verifyPermission() {
            const params = {
                operation: ['SIGAM', 'Documents', 'HavePermission'],
                urlParams: {
                    institutionId,
                    organizationUnitId,
                    documentId: documentInformation.id
                }
            };
            const response = await SocialMeAPI(params);
            setAccessLevel(response.body.access_level);
            if(documentInformation){
                if(documentInformation.status !== 'ACTIVE'){
                    TransformStatus();
                }
            }
            setLoadingContent(false)
        }
        setLoadingContent(true)
        verifyPermission()
    },[documentInformation, institutionId, organizationUnitId])

    const options = [
        {
            icon: AiOutlineLink,
            title: 'Anexar em Processo existente',
            description: '',
            value: 'attachToExistingProcess'
        },
        {
            icon: BsFileEarmarkPlus,
            title: 'Anexar em novo Processo',
            description: 'Descrição do Restrito',
            value: 'attachInNewProcess'
        }
    ]

    function SuccessfullyProcessed() {
        setActiveMethod();
        setProcess();
        CloseDrawer();
    }

    function RenderMethod() {
        switch (activeMethod) {
            case 'attachToExistingProcess':
                return <AttachToExistingProcess document={documentInformation} setProcess={setProcess} setActiveMethod={setActiveMethod}  />
            case 'attachInNewProcess':
                return <AttachInNewProcess document={documentInformation} setProcess={setProcess} setActiveMethod={setActiveMethod} />
            default:
                break;
        }
    }

    if (accessLevel) {
        if (!accessLevel.is_in_charge) {
            return <Result
                status="error"
                title="Você não pode anexar o documento para tramitação"
            />
        }
    }

    if (loadingContent) {
        return <Loading />
    }

    return (
        <Animate
            Animation={[FadeIn]}
            duration={['0.5s']}
            delay={['0.2s']}
        >
            <Row>
                <Col span={24}>
                    <Text>Método de Anexo</Text>
                </Col>
                {options.map(item => (
                    <Col key={item.value} span={12} style={{ padding: 5 }}>
                        <SelectionItem item={item} activeValue={activeMethod} setActiveValue={setActiveMethod} />
                    </Col>
                ))}
            </Row>
            <Divider />
            {activeMethod && process === undefined && RenderMethod()}
            {process && <ProcessView process={process} SuccessfullyProcessed={SuccessfullyProcessed} setProcess={setProcess} />}
        </Animate>
    );
}