import React, { useState } from 'react';
import { Button, Drawer, Descriptions, Alert } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

export default function ViewButton({ record }) {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button icon={<EyeOutlined />} onClick={() => showDrawer()}>
        Visualizar
      </Button>
      <Drawer
        title="Mensagem externa"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <Alert description={record.body} style={{ marginBottom: 10 }} />
        {record && (
          <Descriptions column={1}>
            {record.contacts.map((item, index) => (
              <Descriptions.Item key={index} label={`Contato ${index + 1}`}>
                {item}
              </Descriptions.Item>
            ))}
          </Descriptions>
        )}
      </Drawer>
    </>
  );
}
