import SigamAPI from '~/utils/SigamAPI';

export default async function DeleteChat({ chatId, institutionId }) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/chat/${chatId}`,
    method: 'DELETE',
  });

  return response;
}
