import { Input, Typography } from 'antd';
import React, { useRef, useState, useEffect } from 'react';

import { useMessagerManager } from '../../../../contexts/MessagerManager/Provider';
import { useTheme } from '~/contexts/ThemeContext';
import DropdownIcon from '../../components/DropdownIcon';
import Contacts from './components/Contacts';

import * as S from './style';
import useContacts from '../../hooks/useContacts';

const { Text } = Typography;
const { Search } = Input;

export default function AddContactsToChat() {
  const { contacts, setSearch, loadingContacts } = useContacts();

  const [height, setHeight] = useState('100%');

  const { selectedChat } = useMessagerManager();
  const { theme } = useTheme();

  const textStyle = {
    fontSize: '1rem',
    fontWeight: 'bold',
    lineHeight: '100%',
    color: theme.color.white,
  };

  const headerRef = useRef(null);

  useEffect(() => {
    const idealHeight = window.innerHeight - headerRef.current?.clientHeight;

    setHeight(`${idealHeight}px`);
  }, [window]);

  return (
    <S.Container>
      <div ref={headerRef}>
        <S.Header style={{ backgroundColor: theme.color.primary }}>
          <S.Stack>
            <Text style={{ color: theme.color.secondaryDark }}>
              Vinculando contato no grupo:
            </Text>
            <Text style={textStyle}>{selectedChat.name}</Text>
          </S.Stack>

          <DropdownIcon />
        </S.Header>

        <S.SearchBox>
          <Search
            size="default"
            placeholder="Pesquisar contatos"
            allowClear
            onSearch={setSearch}
          />
        </S.SearchBox>
      </div>

      <S.ContactsWrapper height={height}>
        <Contacts contacts={contacts} loadingContacts={loadingContacts} />
      </S.ContactsWrapper>
    </S.Container>
  );
}
