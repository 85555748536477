import React, { useContext, memo } from 'react';

import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

import Text from '~/components/typography/text';

import { CapitalizeString } from '~/utils/Transformations';

import { Div } from './style.js';

function PrimaryTextCell({ text, textAlign, strong }) {
  const { isMobile } = useContext(ResponsiveContext);
  const { theme } = useContext(ThemeContext);

  return (
    <Div>
      <Text
        style={{ textAlign: isMobile ? 'center' : textAlign && 'left' }}
        strong={strong || false}
        color={theme.color.textOnSecondaryBackground}
      >
        {CapitalizeString(text)}
      </Text>
    </Div>
  );
}

export default memo(PrimaryTextCell);
