import { message } from 'antd';
import SigamAPI from '~/utils/SigamAPI';

export default async function UpdateArchiveFile({
  file,
  fileId,
  archiveId,
  institutionId,
}) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/archive/${archiveId}/file/${fileId}`,
    method: 'PATCH',
    data: file,
  });

  if (response.success) {
    message.success('Arquivo editado com sucesso');
  } else {
    message.error('Erro ao editar o arquivo');
  }

  return response;
}
