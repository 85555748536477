import { message } from 'antd';
import SigamAPI from '~/utils/SigamAPI';

export default async function GetArchives({
  filters,
  institutionId,
  organizationUnitId,
}) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/organizational-unity/${organizationUnitId}/archives`,
    method: 'POST',
    data: filters,
  });

  if (!response.success) {
    message.error('Erro ao listar os acervos');
  }

  return response;
}
