import React, { useState } from 'react';
// Libraries
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
// Standalone Components
import { Container, TextContainer } from './style';
// Loading
import Loading from '~/components/loading';
//import Text from '~/components/typography/text'
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function UploadFile({ setFile }) {
  const { institutionId, organizationUnitId } = useParams();
  const [loading, setLoading] = useState(false);

  const beforeUpload = (file) => {
    const isPDF = file.type === 'application/pdf';
    const isBig = file.size > 10485760;
    if (!isPDF) {
      message.error('Você só pode enviar arquivos no formato PDF');
      return false;
    }
    if (isBig) {
      message.error('Arquivo ultrapassou limite de 10 MB');
      return false;
    }
    return true;
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      //setLoading(true);
      return;
    }
  };

  const UploadButton = () => {
    return (
      <Container>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <TextContainer>Anexar arquivo</TextContainer>
      </Container>
    );
  };

  async function UploadAvatar(file) {
    setLoading(true);
    const data = new FormData();
    data.append('file', file);
    const params = {
      operation: ['SIGAM', 'Documents', 'UploadFile'],
      urlParams: {
        institutionId: institutionId,
        organizationUnitId: organizationUnitId,
      },
      data,
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      let fileInformations = file;
      fileInformations.url = response.body.file;
      setFile(fileInformations);
    }
    return setLoading(false);
  }

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      showUploadList={false}
      action={UploadAvatar}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {loading ? <Loading withoutText height="0px" /> : <UploadButton />}
    </Upload>
  );
}
