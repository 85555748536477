import React, { useMemo, useState } from 'react';
import { FilterOutlined, CloseCircleFilled } from '@ant-design/icons';
import { Form, Button, Drawer, Space } from 'antd';

import { useArchivesPageManager } from '../../../../contexts/ArchivesPageManager/Provider';
import ArchiveFilterForm from './components/FilterForm';

export default function NewArchive() {
  const { initialFilter, filter, setFilter } = useArchivesPageManager();

  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
    form.resetFields();
  };
  const onClose = () => {
    setOpen(false);
  };

  async function onFinish(values) {
    setFilter(values);
  }

  async function ResetFilter() {
    setFilter(initialFilter);
  }

  const isFilterChanged = useMemo(() => {
    return initialFilter !== filter;
  }, [filter]);

  return (
    <>
      <Space>
        {isFilterChanged && (
          <Button
            type="dashed"
            icon={<CloseCircleFilled />}
            onClick={ResetFilter}
          >
            Resetar filtros
          </Button>
        )}
        <Button type="dashed" icon={<FilterOutlined />} onClick={showDrawer}>
          Filtros
        </Button>
      </Space>

      <Drawer
        title="Filtrandos acervos"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <ArchiveFilterForm
          form={form}
          onFinish={onFinish}
          ResetFilter={ResetFilter}
          initialFilter={filter}
        />
      </Drawer>
    </>
  );
}
