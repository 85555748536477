import React, { useContext } from 'react';
// Libraries
import QrReader from 'react-qr-reader';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
//import image from '~/assets/images/backgrounds/bg1.jpg';

import './style.css';

export default function QRCodeReader({
  GetQRCodeScanResult,
  GetQRCodeSancError,
}) {
  const { isMobile } = useContext(ResponsiveContext);
  return (
    <div id="qr-code-reader">
      <div
        className="bar-container"
        style={{ padding: isMobile ? '100px 50px 150px' : '100px 50px' }}
      >
        <div className="bar-content">
          <div className="bar"></div>
        </div>
      </div>
      <QrReader
        delay={1000}
        onError={GetQRCodeSancError}
        onScan={GetQRCodeScanResult || console.log('value')}
        showViewFinder={false}
        //openImageDialog={image}
      />
    </div>
  );
}
