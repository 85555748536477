import React, { useEffect, useState } from 'react';
// Libraries
import { SearchOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { Alert } from 'antd';
// Own Components
import PeopleList from './components/PeopleList';
// Components
import Title from '~/components/typography/title';
import Button from '~/components/button';
import Loading from '~/components/loading';
import SearchDrawer from '~/pages/SIGAM/components/SearchDrawer';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
// Pages
import SearchDrawerComponent from './components/SearchDrawerComponent';

export default function Contributors({ document, propertys }) {
    const { institutionId, organizationUnitId } = useParams();
    const [people, setPeople] = useState([]);
    const [loadingContent, setLoadingContent] = useState(true);
    const [accessLevel, setAccessLevel] = useState();

    const documentInformation = document || propertys.document[0];

    async function GetPeopleAgain() {
        if (propertys && propertys.GetDataAgain) {
            propertys.GetDataAgain();
        }
        const params = {
            operation: ['SIGAM', 'Documents', 'Contributors', 'Get'],
            urlParams: {
                institutionId,
                organizationUnitId,
                documentId: documentInformation.id
            }
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            setPeople(response.body.peopleList.elements);
        }
    }

    useEffect(() => {
        async function GetPeople() {
            const params = {
                operation: ['SIGAM', 'Documents', 'Contributors', 'Get'],
                urlParams: {
                    institutionId,
                    organizationUnitId,
                    documentId: documentInformation.id
                }
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                setPeople(response.body.peopleList.elements);
            }
            setLoadingContent(false);
        }
        async function verifyPermission() {
            const params = {
                operation: ['SIGAM', 'Documents', 'HavePermission'],
                urlParams: {
                    institutionId,
                    organizationUnitId,
                    documentId: documentInformation.id
                }
            };
            const response = await SocialMeAPI(params);
            setAccessLevel(response.body.access_level);
            GetPeople();
        }
        verifyPermission();
    }, [documentInformation, institutionId, organizationUnitId])

    if (loadingContent) {
        return <Loading />
    }

    if(accessLevel && accessLevel.can_read === false){
        return <div>sem permissão de leitura</div>
    }

    return (
        <div>
            { accessLevel && accessLevel.is_in_charge === false &&  <Alert style={{ marginBottom: '10px' }} message="Você não tem permissão para alterar o documento" type="warning" showIcon />}      
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                <Title level={5}>Editores</Title>
                {accessLevel && accessLevel.is_in_charge && <SearchDrawer
                    Trigger={
                        <Button icon={<SearchOutlined />}>Buscar Usuário</Button>
                    }
                    title="Buscar Usuários"
                    SecondStepComponent={SearchDrawerComponent}
                    secondStepParameters={{ document: documentInformation, people, GetPeopleAgain, searchOnSocialMe: true, }}
                />}
            </div>
            <PeopleList people={people} document={documentInformation} GetPeopleAgain={GetPeopleAgain} />
        </div>
    )
}