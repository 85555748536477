import React, { useState, useEffect } from 'react';
import { Card, Timeline, Typography, Spin, Space, Empty } from 'antd';
import { usePageManager } from '../../../../contexts/PageManager/Provider';
import { useParams } from 'react-router-dom';
import SocialMeAPI from '~/utils/SocialMeAPI';
import { SetupSIGAMTimeline } from '~/utils/Parsers';
import HistoricItem from './components/HistoricItem';

const { Title, Text } = Typography;

export default function Flowchart() {
  const { institutionId } = useParams();
  const { selectedProcessId } = usePageManager();
  const [history, setHistory] = useState([]);
  const [loadingContent, setLoadingContent] = useState(true);

  useEffect(() => {
    async function GetHistoric() {
      const params = {
        operation: ['SIGAM', 'Admin', 'Processes', 'TimelineV1'],
        urlParams: {
          institutionId,
          processId: selectedProcessId,
        },
        data: {
          pagination: {
            sort: {
              by: 'created_at',
              order: 'ASC',
            },
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        const timelineArray = await SetupSIGAMTimeline(
          response.body.timeline.elements
        );
        setHistory(timelineArray);
      }
      setLoadingContent(false);
    }
    if (selectedProcessId) {
      GetHistoric();
    }
  }, [institutionId, selectedProcessId]);

  if (!selectedProcessId) {
    return <></>;
  }

  if (loadingContent) {
    return (
      <Card
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Spin />
      </Card>
    );
  }

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: 'flex',
      }}
    >
      <Title level={5} style={{ margin: 0 }}>
        Fluxograma temporal do processo
      </Title>
      {history.length > 0 ? (
        <Timeline>
          {history.map((item) => (
            <Timeline.Item key={item.day}>
              <Text strong>{item.day}</Text>
              {item.eventsOfTheDay.map((eventOfDay) => (
                <div key={eventOfDay.hour} style={{ marginBottom: 10 }}>
                  <Text>{eventOfDay.hour}</Text>
                  {eventOfDay.eventsOfTheHour.map((eventOfHour, index) => (
                    <HistoricItem
                      key={`${item.day}-${eventOfDay.hour}-${index}`}
                      item={eventOfHour}
                    />
                  ))}
                </div>
              ))}
            </Timeline.Item>
          ))}
        </Timeline>
      ) : (
        <Empty description="Sem registros de tramitações" />
      )}
    </Space>
  );
}
