import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import Title from '~/components/typography/title'
import Text from '~/components/typography/text'
import Checkbox from '~/components/checkbox'
import Button from '~/components/button'
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function GetProcess({propertys }){
    const { institutionId, organizationUnitId } = useParams();
    const [withdrawProcess, setWithdrawProcess] = useState(false)

    async function Delete(){
        const params = {
            operation: ['SIGAM', 'Processes', 'OrganizationalUnits', 'Delete'],
            urlParams: {
                institutionId,
                organizationUnitId,
                processId: propertys.process.id
            }
        };
        const response = await SocialMeAPI(params);
        if(response.success){
            propertys.GetDataAgain()
        }
    }

    async function Get(){
        const params = {
            operation: ['SIGAM', 'Processes', 'OrganizationalUnits', 'Get'],
            urlParams: {
                institutionId,
                organizationUnitId,
                processId: propertys.process.id
            }
        };
        const response = await SocialMeAPI(params);
        if(response.success){
            if(withdrawProcess){
                return Delete()
            }
            return propertys.GetDataAgain()
        }
    }

    return(
        <div>
            <Title level={4} style={{ textAlign:"center" }}>Pegar Processo</Title>
            <div>
                <Checkbox checked={withdrawProcess} onChange={(event) => setWithdrawProcess(event.target.checked)} text="Retirar processo da caixa de entrada na unidade" />
                <Text type="secondary">Ao marcar a caixa acima, o processo será excluído da caixa de entrada na unidade e ninguém mais da unidade poderá pegá-lo</Text>
            </div>
            <div style={{ padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button type="primary" size="large" onClick={() => Get()}>Pegar processo</Button>
            </div>
        </div>
    )
}