import React, { useState } from 'react';
import {
  Button,
  Drawer,
  Form,
  Input,
  Radio,
  Mentions,
  Alert,
  Collapse,
  message,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { usePageManager } from '../../../../../../contexts/PageManager/Provider';

const { TextArea } = Input;
const { Option } = Mentions;
const { Panel } = Collapse;

export default function EditButton({ record }) {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loadingEditButton, setLoadingEditButton] = useState();
  const [loadingButton, setLoadingButton] = useState();
  const [templateValues, setTemplateValues] = useState();
  const { showTemplate, updateTemplate, readTemplates, filter, pagination } =
    usePageManager();
  const [edited, setEdited] = useState(false);

  const showDrawer = () => {
    setOpen(true);
    setEdited(false);
  };
  const onClose = () => {
    setOpen(false);
    if (edited) {
      readTemplates(filter, pagination);
    }
  };

  async function getItem() {
    setLoadingEditButton(true);
    const response = await showTemplate(record.id);
    if (response.success) {
      setTemplateValues(response.body.communication_model);
      showDrawer();
    }
    setLoadingEditButton(false);
  }

  const onFinish = async (values) => {
    setLoadingButton(true);
    const response = await updateTemplate(record.id, values);
    if (response.success) {
      message.success('Modelo atualizado com sucesso!');
      setEdited(true);
    } else {
      message.error('Alguma coisa aconteceu...');
    }
    setLoadingButton(false);
  };

  return (
    <>
      <Button
        icon={<EditOutlined />}
        loading={loadingEditButton}
        onClick={() => getItem()}
      >
        Editar
      </Button>
      <Drawer
        title={record.name}
        placement="right"
        onClose={onClose}
        open={open}
      >
        <Form
          labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
          wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
          form={form}
          layout="vertical"
          scrollToFirstError
          onFinish={onFinish}
          initialValues={templateValues}
        >
          <Form.Item
            label="Nome do modelo"
            name="name"
            rules={[
              {
                required: true,
                message: 'O nome é obrigatório',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Descrição"
            name="description"
            rules={[
              {
                required: true,
                message: 'Uma descrição é obrigatória',
              },
            ]}
          >
            <TextArea rows={2} />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="ACTIVE">Ativo</Radio.Button>
              <Radio.Button value="INACTIVE">Inativo</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Corpo da mensagem"
            name="body"
            rules={[
              {
                required: true,
                message: 'O nome é obrigatório',
              },
            ]}
          >
            <Mentions rows={4}>
              <Option value="NOME_PESSOA@">NOME_PESSOA</Option>
              <Option value="IDADE_PESSOA@">IDADE_PESSOA</Option>
              <Option value="CPF_PESSOA@">CPF_PESSOA</Option>
              <Option value="NOME_UNIDADE@">NOME_UNIDADE</Option>
              <Option value="NOME_PROCESSO@">NOME_PROCESSO</Option>
              <Option value="NUMERO_PROCESSO@">NUMERO_PROCESSO</Option>
              <Option value="ASSUNTO_PROCESSO@">ASSUNTO_PROCESSO</Option>
            </Mentions>
          </Form.Item>

          <Collapse bordered={false} style={{ marginBottom: '10px' }}>
            <Panel header="Precisa de ajuda?" key="1">
              <Alert
                description="Você pode inserir dados dinâmicos na mensagem digitando o símbolo '@'. Os campos dinâmico serão substituidos pelos seus respectivos valores antes da mensagem ser enviada"
                type="info"
              />
            </Panel>
          </Collapse>

          <Form.Item>
            <Button
              type="primary"
              loading={loadingButton}
              htmlType="submit"
              style={{
                width: '100%',
              }}
            >
              {loadingButton ? 'Atualizando modelo' : 'Atualizar modelo'}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
