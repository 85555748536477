import React from 'react';
import Card from '~/components/card';
import Text from '~/components/typography/text';

import { Cell, Space, MainCollapse, MainPanel } from './style';

export default function ResponsiveTable({ theme, data, columns, expandable }) {
  const cardStyle = {
    width: '100%',
    margin: '0 auto 10px auto',
    padding: 0,
  };

  const bodyCardStyle = {
    padding: 5,
  };

  return (
    <>
      {data.map((item, index) => (
        <Card bodyStyle={bodyCardStyle} style={cardStyle} key={`card-${index}`}>
          {columns.map((column, index2) => (
            <Cell key={`cell-${index}-${index2}`}>
              <Space>
                <Text strong>{column.title}</Text>
              </Space>
              {column && column.render && column.render(item[column.key], item)}
            </Cell>
          ))}

          {expandable ? (
            <MainCollapse>
              <MainPanel
                backgroundColor={theme.color.white}
                color={theme.color.textOnSecondaryBackground}
                header={expandable.title || 'Detalhes'}
                key="1"
              >
                {expandable.expandedRowRender(item)}
              </MainPanel>
            </MainCollapse>
          ) : (
            () => {}
          )}
        </Card>
      ))}
    </>
  );
}
