import React from 'react';
import { Link } from 'react-router-dom';
import { Tabs } from 'antd';
// Components
import Title from '~/components/typography/title';
import Button from '~/components/button';
// Tabs
import Status from './tabs/Status';
import Releases from './tabs/Releases';
import Developing from './tabs/Developing';

const { TabPane } = Tabs;

export default function Updates() {
    return (
        <div>
            <div style={{ backgroundColor: '#143c6d', padding: 10, display: 'flex', justifyContent: 'space-between' }}>
                <Title style={{ color: 'white', margin: 0 }} level={4}>Atualizações do Desenvolvimento</Title>
                <Button>
                    <Link to="/">
                    Voltar para o Sistema
                    </Link>
                </Button>
            </div>
            <div style={{ padding: 20 }}>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Atualizações" key="1">
                        <Releases />
                    </TabPane>
                    <TabPane tab="Status Geral" key="2">
                        <Status />
                    </TabPane>
                    <TabPane tab="Em desenvolvimento" key="3">
                        <Developing />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}