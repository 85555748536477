import React, { useContext } from 'react';
// Libraries
import { FaCheckCircle } from 'react-icons/fa';
import { RiCheckboxBlankCircleLine } from 'react-icons/ri';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Card from '~/components/card';
import Text from '~/components/typography/text';
import Title from '~/components/typography/title';

export default function SelectionItem({ item, activePrivacy, setActivePrivacy, accessLevel }) {
    const { theme } = useContext(ThemeContext);
    const Icon = item.icon;

    return (
        <Card style={{ border: '2px solid', borderColor: activePrivacy === item.value ? theme.color.primary : theme.color.borderColor }} bodyStyle={{ padding: 0 }}>
            <div style={{ padding: 5, cursor: 'pointer' }} onClick={() => accessLevel && accessLevel.is_in_charge ? setActivePrivacy(item.value) : null}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Icon style={{ fontSize: '14pt', marginRight: 10 }} />
                        <Title level={5} style={{ margin: 0 }}>{item.title}</Title>
                    </div>
                    <div>
                        {activePrivacy === item.value ?
                            <FaCheckCircle style={{ fontSize: '14pt', color: theme.color.primary }} />
                            :
                            <RiCheckboxBlankCircleLine style={{ fontSize: '14pt', color: theme.color.borderColor }} />
                        }
                    </div>
                </div>
                <div>
                    <Text kind="secondary" />
                </div>
            </div>
        </Card>
    )
}