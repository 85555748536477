import React, { useContext, memo } from 'react';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { ThemeContext } from '~/contexts/ThemeContext';

import { SearchInput } from './style';

function AntInput({
  disabled,
  placeholder,
  size,
  prefix,
  suffix,
  width,
  margin,
  value,
  onChange,
  onSearch,
  enterButton,
  loading,
}) {
  const { isMobile } = useContext(ResponsiveContext);
  const { theme } = useContext(ThemeContext);

  return (
    <SearchInput
      disabled={disabled}
      loading={loading}
      onSearch={onSearch}
      onChange={onChange}
      enterButton={enterButton}
      placeholder={placeholder}
      size={size || isMobile ? 'large' : 'default'}
      prefix={prefix}
      suffix={suffix}
      value={value}
      width={width}
      margin={margin}
      backgroundcolor={theme.color.backgroundSecondary}
      color={theme.color.textOnSecondaryBackground}
    />
  );
}

export default memo(AntInput);
