import React from 'react';
// Libraries
import { Result } from 'antd';

export default function NotSignatory() {
    return (
        <div>
            <Result
                status="error"
                title="Você não é um assinante do processo"
            />
        </div>
    )
}