import React, { useEffect, useState } from 'react';
// Libraries
import { useParams } from 'react-router-dom';
// Own Components
// import PrintContent from './components/PrintContent';
import PDFViewer from './components/PDFViewer';
import ExternalView from './components/ExternalView';
// import AttachmentView from '../AttachmentView';
// Components
import Button from '~/components/button';
// import Text from '~/components/typography/text';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { SetupSIGAMTimeline } from '~/utils/Parsers';

export default function DocumentView({ document }) {
  const { institutionId, organizationUnitId } = useParams();
  const [loading, setLoading] = useState(true);
  const [fatherDocument, setFatherDocument] = useState();
  const [attachments, setAttachments] = useState();
  const [timelineItems, setTimelineItems] = useState();

  useEffect(() => {
    setLoading(true);
    async function getHistoric() {
      const params = {
        operation: ['SIGAM', 'Documents', 'GetHistory'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: document.id,
        },
        data: {
          filter: {
            // not_action: 'ACCESS'
            action: 'LOGIN-PASSWORD',
          },
          pagination: {
            sort: {
              by: 'created_at',
              order: 'ASC',
            },
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        // console.log('response -> ', response)
        const timelineArray = await SetupSIGAMTimeline(
          response.body.timeline.elements
        );
        // console.log('timelineArray -> ', timelineArray)
        const timeline = [];
        // Day
        for (let i = 0; i < timelineArray.length; i++) {
          // Hour
          const day = timelineArray[i];
          for (let j = 0; j < day.eventsOfTheDay.length; j++) {
            // Events
            const hour = day.eventsOfTheDay[j];
            for (let k = 0; k < hour.eventsOfTheHour.length; k++) {
              // Eventss
              const events = hour.eventsOfTheHour[k];
              for (let l = 0; l < events.events.length; l++) {
                // Events
                const event = events.events[l];
                timeline.push(event);
              }
            }
          }
        }
        setTimelineItems(response.body.timeline.elements);
      }
    }
    async function GetAttachments() {
      const params = {
        operation: ['SIGAM', 'Documents', 'Attachments', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: document.id,
        },
        data: {
          pagination: {
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        const newArray = [];
        const father = document;
        father.attachment = false;
        newArray.push(father);
        const array = response.body.attachments.elements;
        setAttachments(array);
        for (let i = 0; i < array.length; i++) {
          const newItem = array[i];
          newItem.attachment = true;
          newArray.push(newItem);
        }
      }
    }
    async function GetDocument() {
      const params = {
        operation: ['SIGAM', 'Documents', 'Show'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: document.id,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setFatherDocument(response.body.document);
      }
      setLoading(false);
    }
    setTimelineItems();
    GetDocument();
    GetAttachments();
    getHistoric();
  }, [institutionId, organizationUnitId, document]);

  if (loading) {
    return <Button loading type="primary" disabled>
      Carregando
    </Button>;
  }

  return (
    <div>
      <div>
        {attachments ? (
          <div>
            {fatherDocument.url ? (
              <ExternalView document={fatherDocument} />
            ) : (
              <PDFViewer
                fatherDocument={fatherDocument}
                attachments={attachments}
                timelineItems={timelineItems}
                status={document.status}
              />
            )}
          </div>
        ) : (
          <Button loading type="primary" disabled>
            Carregando
          </Button>
        )}
      </div>
    </div>
  );
}
