import React, { useEffect, useContext } from 'react';
// Libraries
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
// Contexts
import { SIGAMContext } from '~/contexts/SIGAMContext';
// Pages
import Admin from './pages/Admin';
import Citizen from './pages/Citizen';
import PublicAgent from './pages/PublicAgent';
import Messager from './pages/Messager';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Profiles() {
  const { setActiveInstitution } = useContext(SIGAMContext);
  let { params } = useRouteMatch();

  useEffect(() => {
    async function GetInstitution(id) {
      const params = {
        operation: ['SIGAM', 'Institution', 'Show'],
        urlParams: {
          institutionId: id,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setActiveInstitution(response.body.institution);
      }
    }
    if (params) {
      let id = params.institutionId;
      GetInstitution(id);
    }
  }, [params, setActiveInstitution]);

  return (
    <Switch>
      <Route path={`/:institutionId/admin`} component={Admin} />
      <Route path={`/:institutionId/agente-publico`} component={PublicAgent} />
      <Route path={`/:institutionId/cidadao`} component={Citizen} />
      <Route path={`/:institutionId/chat`} component={Messager} />
      <Route path={`*`}>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}
