import React from 'react';
import { Typography } from 'antd';

import Loading from '~/components/loading';
import ContactItem from './components/Item';
import * as S from './style';
import { useMessagerManager } from '../../../../../../contexts/MessagerManager/Provider';

const { Title } = Typography;

export default function Contacts({ contacts, loadingContacts }) {
  const { chats, setChats } = useMessagerManager();

  if (loadingContacts) {
    return <Loading height="50%" text="Carregando contatos" />;
  }

  if (contacts.length === 0) {
    return (
      <S.Center>
        <Title level={3}>Nenhum contato encontrado</Title>
      </S.Center>
    );
  }

  function getCreatedChatFromContact(currentContact) {
    let createdChat;

    chats.forEach((chat) => {
      if (chat.person_id === currentContact.id) createdChat = chat;
    });

    return createdChat;
  }

  return (
    <>
      {contacts.map((contact) => (
        <ContactItem
          createdChat={getCreatedChatFromContact(contact)}
          key={contact.id}
          contact={contact}
          setChats={setChats}
        />
      ))}
    </>
  );
}
