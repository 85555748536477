import SigamAPI from '~/utils/SigamAPI';

export default async function CreateChatGroup({ group, institutionId }) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/chat-group`,
    method: 'POST',
    data: group,
  });

  return response;
}
