import React, { useState, useContext, useEffect } from 'react';
// Library
import { Row, Col } from 'antd';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
import { ThemeContext } from '~/contexts/ThemeContext';
// Standalone components
import {
  ContainerFirstTime,
  ContainerAvatars,
  ContainerUploadButton,
} from './style';
// Own Components
import UploadAvatar from './components/UploadAvatar';
import DeleteButton from './components/DeleteButton';
// Components
import Avatar from '~/components/avatar';
import Text from '~/components/typography/text';
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { DecodeURI } from '~/utils/Transformations';

export default function AvatarsAlreadyUsed({
  person,
  usedAvatarUrl,
  setUsedAvatarUrl,
  GetProfileAgain,
}) {
  const { translate } = useContext(LanguageContext);
  const { theme } = useContext(ThemeContext);
  const [avatars, setAvatars] = useState([]);
  const [load, setLoad] = useState(true);
  const [empty, setEmpty] = useState(false);

  useEffect(() => {
    async function GetAvatars() {
      const params = {
        operation: ['Profile', 'Avatar', 'GetAll'],
        data: {
          pagination: {
            sort: {
              order: 'desc',
              by: 'lastModifiedAt',
            },
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        if (response.body.files.elements.length > 0) {
          setAvatars(response.body.files.elements);
        } else {
          setEmpty(true);
        }
        return setLoad(false);
      }
    }
    GetAvatars();
  }, [person]);

  async function GetAvatarsAgain() {
    setLoad(true);
    const params = {
      operation: ['Profile', 'Avatar', 'GetAll'],
      data: {
        pagination: {
          sort: {
            order: 'desc',
            by: 'lastModifiedAt',
          },
        },
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      if (response.body.files.elements.length > 0) {
        setAvatars(response.body.files.elements);
        setEmpty(false);
      } else {
        setEmpty(true);
      }
    }
    setLoad(false);
  }

  return (
    <>
      {load ? (
        <Loading text={translate.messages.fetchingYourPhotos.text} />
      ) : empty ? (
        <>
          <ContainerFirstTime>
            <Text kind="secondary">
              {
                translate.messages.didNotChangeYourProfilePhotoForThe1stTime
                  .text
              }
            </Text>
            <ContainerUploadButton>
              <UploadAvatar GetProfileAgain={GetProfileAgain} GetAvatarsAgain={GetAvatarsAgain} />
            </ContainerUploadButton>
          </ContainerFirstTime>
        </>
      ) : (
        <>
          <ContainerAvatars>
            <Row>
              <Col span={6}>
                <UploadAvatar GetProfileAgain={GetProfileAgain} GetAvatarsAgain={GetAvatarsAgain} />
              </Col>
              {avatars.map((item, index) => (
                <Col key={index} span={6} style={{ marginBottom: 10 }}>
                  <Avatar
                    onClick={
                      person.avatar === item.url
                        ? null
                        : () => setUsedAvatarUrl(item.url)
                    }
                    shape="square"
                    size={usedAvatarUrl === item.url ? 100 : 103}
                    src={DecodeURI(item.url)}
                    style={{
                      cursor: 'pointer',
                      border: `3px solid ${
                        usedAvatarUrl === item.url
                          ? theme.color.primary
                          : theme.color.white
                      }`,
                      filter:
                        person.avatar === item.url ? 'grayscale(100%)' : 'none',
                    }}
                  />
                  {person.avatar !== item.url && (
                    <DeleteButton
                      item={item}
                      GetAvatarsAgain={GetAvatarsAgain}
                    />
                  )}
                </Col>
              ))}
            </Row>
          </ContainerAvatars>
        </>
      )}
    </>
  );
}
