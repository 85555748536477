import React, { useContext } from 'react';
// Libraries
import { CloseOutlined } from '@ant-design/icons';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Text from '~/components/typography/text';
// Utils
import { CapitalizeString } from '~/utils/Transformations';

export default function List({
    people,
    setPeople,
    units,
    setUnits,
    groups,
    setGroups,
}) {
    const { theme } = useContext(ThemeContext);

    function removePerson(person) {
        setPeople(people.filter(item => item.id !== person.id));
    }
    function removeUnit(unit) {
        setUnits(units.filter(item => item.id !== unit.id));
    }
    function removeGroup(group) {
        setGroups(groups.filter(item => item.id !== group.id));
    }

    function RenderPeople() {
        return (
            <div>
                <Text>Pessoas</Text>
                <div>
                    {people.map(item => (
                        <div
                            key={item.id}
                            style={{
                                padding: 2,
                                borderRadius: 8,
                                border: '1px solid',
                                borderColor: theme.color.borderColor,
                                width: 'max-content',
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 5
                            }}
                        >
                            <Text style={{ margin: 0 }}>{CapitalizeString(item.social_name || item.name)}</Text>
                            <CloseOutlined
                                style={{ marginLeft: 10, cursor: 'pointer', fontSize: '10pt' }}
                                onClick={() => removePerson(item)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    function RenderUnits() {
        return (
            <div>
                <Text>Unidades</Text>
                <div>
                    {units.map(item => (
                        <div
                            key={item.id}
                            style={{
                                padding: 2,
                                borderRadius: 8,
                                border: '1px solid',
                                borderColor: theme.color.borderColor,
                                width: 'max-content',
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 5
                            }}
                        >
                            <Text style={{ margin: 0 }}>{CapitalizeString(item.name)}</Text>
                            <CloseOutlined
                                style={{ marginLeft: 10, cursor: 'pointer', fontSize: '10pt' }}
                                onClick={() => removeUnit(item)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    function RenderGroups() {
        return (
            <div>
                <Text>Grupos</Text>
                <div>
                    {groups.map(item => (
                        <div
                            key={item.id}
                            style={{
                                padding: 2,
                                borderRadius: 8,
                                border: '1px solid',
                                borderColor: theme.color.borderColor,
                                width: 'max-content',
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 5
                            }}
                        >
                            <Text style={{ margin: 0 }}>{CapitalizeString(item.name)}</Text>
                            <CloseOutlined
                                style={{ marginLeft: 10, cursor: 'pointer', fontSize: '10pt' }}
                                onClick={() => removeGroup(item)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    return (
        <div
            style={{ marginBottom: 10 }}
        >
            {people.length > 0 && RenderPeople()}
            {units.length > 0 && RenderUnits()}
            {groups.length > 0 && RenderGroups()}
        </div>
    )
}