import SigamAPI from '~/utils/SigamAPI';

export default async function CreateTemplate({ institutionId, data }) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/communication-model`,
    method: 'POST',
    data: data,
  });

  return response;
}
