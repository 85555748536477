import React, { useCallback, useContext, useState } from 'react';

import { Drawer, message } from 'antd' 

import { PlusOutlined } from '@ant-design/icons';

import { ResponsiveContext } from '~/contexts/ResponsiveContext'

import Button from '~/components/button';
import Input from '~/components/input'

import { cnpjMask } from '~/utils/Masks';
import Api from '~/utils/SocialMeAPI';

import Flex from './style'

import PersonHeader from './components/PersonHeader';

export default function SearchInstitutions({ setTotal }) {
    const { isMobile } = useContext(ResponsiveContext)
    const [search, setSearch] = useState("");
    const [ loading, setLoading ] = useState(false)
    const [institution, setInstitution] = useState();
    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
      setVisible(true);
    };
    const onClose = () => {
      setVisible(false);
    };

    const GetInstitution = useCallback(async (cnpj) => {
      setLoading(true)

      const params = {
        operation: ["SIGAM","Admin", "Search"],
        data: {
          filter: {
         //   kind: "PJ",
            cnpj_number: cnpj.replace(/[^\d]+/g, '')
          }
        }
      }

      // cnpj.replace(/[^\d]+/g, '')
      console.log(params)
      const response = await Api(params)
      console.log(response)
      if (response.success) {
        const items = response.body.peopleList.elements
        if (items.length === 0) {
          message.error("Nenhuma instituição foi encontrada")
        } else if (items[0].cnpj_number !== null) {
         
          setInstitution(items[0])
        } else {
          message.error("Instituição não encontrada")
        }   
      } else {
        message.error("Houve um erro ao buscar uma instituição")
      }

      setLoading(false)
    }, [])


  return (
    <>
        <Button style={{ marginLeft: "10px"}} type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
            Vincular instituição
        </Button>

        <Drawer title="Vincular uma instituição" width={isMobile ? "100vw" : "35vw"} placement="right" onClose={onClose} visible={visible}>
          {institution && (
            <PersonHeader onClose={onClose} setTotal={setTotal} institution={institution} />
          )}
         
          <Flex>
            <Input placeholder="CNPJ" value={search} onChange={({ target }) => setSearch(cnpjMask(target.value))} />
            <Button onClick={() => GetInstitution(search)} loading={loading} style={{ marginLeft: 10}} type={!institution && "primary"}>Buscar</Button>
          </Flex>
        </Drawer>
    </>
  );
}
