import React, { memo, useContext } from 'react';

import { ResponsiveContext } from '~/contexts/ResponsiveContext';

import Avatar from '~/components/avatar';
import Text from '~/components/typography/text';

import { CapitalizeString } from '~/utils/Transformations';

import { Flex } from './style';

function ImageWithTextCell({ text, size, src, alt, color }) {
  const { isMobile } = useContext(ResponsiveContext);

  return (
    <Flex align="center" direction={isMobile ? 'column' : 'row'}>
      <Avatar
        style={{
          margin: isMobile ? '10px 0 0 0' : '0 10px 0 0',
        }}
        size={size || 30}
        src={src}
        alt={alt || 'imagem'}
      />

      <Text style={{ textAlign: isMobile ? 'center' : 'left' }}>
        {CapitalizeString(text)}
      </Text>
    </Flex>
  );
}

export default memo(ImageWithTextCell);
