import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import GetChatParticipants from '~/services/calls/messager/participants/read';

export default function useChatParticipants(selectedChat) {
  const [loadingChatParticipants, setLoadingChatParticipants] = useState(false);
  const [chatParticipants, setChatParticipants] = useState([]);

  const { institutionId } = useParams();

  useEffect(() => {
    async function LoadAsyncData() {
      if (!selectedChat) return;

      const chatId = selectedChat.id;

      setLoadingChatParticipants(true);

      const response = await GetChatParticipants({ chatId, institutionId });

      if (response.success) {
        const { elements } = response.body.chat_group_participants;

        setChatParticipants(elements);
      }

      setLoadingChatParticipants(false);
    }

    LoadAsyncData();
  }, []);

  return { chatParticipants, setChatParticipants, loadingChatParticipants };
}
