import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import GetContacts from '~/services/calls/messager/contacts/read';

export default function useContacts() {
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState('');

  const { institutionId } = useParams();

  useEffect(() => {
    async function LoadAsyncData() {
      setLoadingContacts(true);

      const response = await GetContacts({ search, institutionId });

      if (response.success) {
        const { elements } = response.body.peopleList;

        setContacts(elements);
      }

      setLoadingContacts(false);
    }

    LoadAsyncData();
  }, [search]);

  return { contacts, loadingContacts, search, setSearch };
}
