export const ValidateDate = (value, past) => {
  if (value) {
    if (value.includes('_')) {
      return false;
    }
    const date = value.split('/');
    let year;
    if (past) {
      year = new Date().getFullYear();
    } else {
      const yearActive = new Date().getFullYear();
      year = yearActive + 50;
    }
    if (
      date[2] > year ||
      date[1] > 12 ||
      date[1] === '00' ||
      date[0] === '00' ||
      date[0] > 31
    ) {
      return false;
    }
    return true;
  }
  return false;
};

export const validateCPF = (cpf) => {
  cpf = cpf.replace(/\./g, '');
  cpf = cpf.replace('-', '');
  cpf = cpf.split('');

  var v1 = 0;
  var v2 = 0;
  var aux = false;

  for (let index = 1; cpf.length > index; index++) {
    if (cpf[index - 1] !== cpf[index]) {
      aux = true;
    }
  }

  if (aux === false) {
    return false;
  }

  for (let index = 0, number = 10; cpf.length - 2 > index; index++, number--) {
    v1 += cpf[index] * number;
  }

  v1 = (v1 * 10) % 11;

  if (v1 === 10) {
    v1 = 0;
  }

  if (v1 !== Number(cpf[9])) {
    return false;
  }

  for (let index = 0, number = 11; cpf.length - 1 > index; index++, number--) {
    v2 += cpf[index] * number;
  }

  v2 = (v2 * 10) % 11;

  if (v2 === 10) {
    v2 = 0;
  }

  if (v2 !== Number(cpf[10])) {
    return false;
  } else {
    return true;
  }
};

export const validateCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/\./g, '');
  cnpj = cnpj.replace('-', '');
  cnpj = cnpj.replace('/', '');
  cnpj = cnpj.split('');

  var v1 = 0;
  var v2 = 0;
  var aux = false;

  for (var i = 1; cnpj.length > i; i++) {
    if (cnpj[i - 1] !== cnpj[i]) {
      aux = true;
    }
  }

  if (aux === false) {
    return false;
  }

  for (
    let index = 0, p1 = 5, p2 = 13;
    cnpj.length - 2 > index;
    index++, p1--, p2--
  ) {
    if (p1 >= 2) {
      v1 += cnpj[index] * p1;
    } else {
      v1 += cnpj[index] * p2;
    }
  }

  v1 = v1 % 11;

  if (v1 < 2) {
    v1 = 0;
  } else {
    v1 = 11 - v1;
  }

  if (v1 !== Number(cnpj[12])) {
    return false;
  }

  for (
    let index = 0, p1 = 6, p2 = 14;
    cnpj.length - 1 > index;
    index++, p1--, p2--
  ) {
    if (p1 >= 2) {
      v2 += cnpj[index] * p1;
    } else {
      v2 += cnpj[index] * p2;
    }
  }

  v2 = v2 % 11;

  if (v2 < 2) {
    v2 = 0;
  } else {
    v2 = 11 - v2;
  }

  if (v2 !== Number(cnpj[13])) {
    return false;
  } else {
    return true;
  }
};

export function validateDigitalWalletPassword(password) {
  let DW_PASSWORD_LENGTH = 4;
  if (/^\d+$/.test(password)) {
    if (password.length === DW_PASSWORD_LENGTH) {
      let repeatCount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let upSequenceCounter = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let downSequenceCounter = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      for (let i = 0; i < password.length; i++) {
        repeatCount[parseInt(password[i])]++;
        if (i !== 0) {
          let first = parseInt(password[i]);
          let second = parseInt(password[i - 1]);
          if (first >= second) {
            upSequenceCounter[first - second]++;
          } else {
            downSequenceCounter[second - first]++;
          }
        }
      }

      for (let i = 0; i < repeatCount.length; i++) {
        if (
          repeatCount[i] > Math.ceil(DW_PASSWORD_LENGTH / 2) ||
          upSequenceCounter[i] > Math.floor(DW_PASSWORD_LENGTH / 2) ||
          downSequenceCounter[i] > Math.floor(DW_PASSWORD_LENGTH / 2)
        ) {
          return false;
        }
      }

      return true;
    }
  }
  return false;
};

export const isEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};