import React, { useEffect, useState } from 'react';
// Libraries
import { useParams } from 'react-router-dom';
// Own Components
// import PrintContent from './components/PrintContent';
import PDFViewer from './components/PDFViewer';
// import AttachmentView from '../AttachmentView';
// Components
import Loading from '~/components/loading';
// import Text from '~/components/typography/text';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { SetupSIGAMTimeline } from '~/utils/Parsers';

export default function DocumentView({ document, attachments, signatories }) {
  const { institutionId, organizationUnitId } = useParams();
  const [loading, setLoading] = useState(true);
  const [fatherDocument, setFatherDocument] = useState(document);
  const [timelineItems, setTimelineItems] = useState();

  useEffect(() => {
    async function getHistoric() {
      const params = {
        operation: ['SIGAM', 'Documents', 'Signatories', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: document.id,
        },
        data: {
          pagination: {
            sort: {
              by: 'created_at',
              order: 'ASC',
            },
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      const array = response.body.signatory.elements;
      function isValid(item) {
        return item.signature_id !== null;
      }
      setTimelineItems(array.filter(isValid));
    }
    async function GetAttachments() {
      const params = {
        operation: ['SIGAM', 'Documents', 'Attachments', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: document.id,
        },
        data: {
          pagination: {
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        const newArray = [];
        const father = document;
        father.attachment = false;
        newArray.push(father);
        const array = response.body.attachments.elements;
        //setAttachments(array);
        for (let i = 0; i < array.length; i++) {
          const newItem = array[i];
          newItem.attachment = true;
          newArray.push(newItem);
        }
      }
    }
    async function GetDocument() {
      setFatherDocument(document);
      setLoading(false);
    }
    GetDocument();
    //GetAttachments();
    //getHistoric();
  }, [institutionId, organizationUnitId, document]);

  return (
    <div style={{ marginTop: 10 }}>
      <div>
        {attachments ? (
          <div>
            <PDFViewer
              fatherDocument={fatherDocument}
              attachments={attachments}
              timelineItems={signatories}
            />
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
}
