import React, { useContext } from 'react';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
// Standalone Components
import { Container, Link } from './style';
// Components
import Button from '~/components/button';
// Environments
import System from '~/environments/System';
// Utils
import { GetModuleNameByHostname } from '~/utils/Hosts';

export default function HasNoUser() {
  const { translate } = useContext(LanguageContext);

  return (
    <Container>
      <Link
        href={`https://${
          System.hostname.authentication
        }/?service=${GetModuleNameByHostname()}`}
      >
        <Button type="primary">{translate.basic.login.text}</Button>
      </Link>
    </Container>
  );
}
