import React, { memo } from 'react';
// Standalone Components
import { MainBadge } from './style';

function AntBadge({
  color,
  count,
  dot,
  offset,
  overflowCount,
  showZero,
  size,
  status,
  text,
  title,
  children,
  style
}) {
  return (
    <MainBadge
      color={color}
      count={count}
      dot={dot}
      offset={offset}
      overflowCount={overflowCount}
      showZero={showZero}
      size={size}
      status={status}
      text={text}
      title={title}
      style={style}
    >
      {children}
    </MainBadge>
  )
}

export default memo(AntBadge);
