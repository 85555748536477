const Tasks = [
    {
        title: 'Correção na operação de anexar documento ao processo',
        type: 'bug',
        description: 'Na hora de anexar um documento ao processo, um erro occore. Estamos verificando o que está causando isso.'
    },
    {
        title: 'Histórico do Documento',
        type: 'layout',
        description: 'Precisa ser melhorado o histórico, trazendo as informações da maneira certa'
    },
    {
        title: 'Histórico do Processo',
        type: 'layout',
        description: 'Precisa ser melhorado o histórico, trazendo as informações da maneira certa'
    },
    {
        title: 'Capa do Processo',
        type: 'feature',
        description: 'Precisa ser revisado quais informações serão apresentadas aqui, assim como também preciso arrumar o histórico para poder colocá-lo aqui.'
    },
    {
        title: 'Visualização de PDF',
        type: 'feature',
        description: 'Novo método de armazenamento/ recuperação de PDF precisa ser implementado. Já solicitamos, estamos esperando a resposta da empresa.'
    },
    {
        title: 'Gerar PDF do Processo',
        type: 'feature',
        description: 'Método de Visualização de PDF precisa ser corrigido primeiro.'
    },
    {
        title: 'Impressão Juntada',
        type: 'feature',
        description: 'Método de Visualização de PDF precisa ser corrigido primeiro.'
    },
    {
        title: 'Auxílio Visuais na tabela de Processos',
        type: 'layout',
        description: 'Informações para saber se o processo está arquivado, se é priorirade e etc'
    },
]

export default Tasks;
