import styled from 'styled-components';

import { Select } from 'antd';

const { Option } = Select;

export const MainSelect = styled(Select)`
  width: ${(style) => style.width || '100%'};
  background-color: ${(style) => style.backgroundcolor};
  color: ${(style) => style.color};
  margin: ${(style) => style.margin};
  border-radius: 8px;

  &: .ant-select-selector {
    border: none;
  }
  .ant-select-selector {
    border: none;
  }
  &.ant-select-selector {
    border: none;
  }
`;

export const MainOption = styled(Option)`
  background: ${(style) => style.backgroundColor};
  color: ${(style) => style.color};
`;

export const Container = styled.div`
  position: relative;
`;

export const LabelContainer = styled.div`
  background-color: ${(props) => props.backgroundcolor};
  padding: 1px 5px;
  border-radius: 8px;
  position: absolute;
  top: ${(props) => props.top};
  left: 10;
  font-size: 8pt;
  line-height: 15px;
`;

export const RequiredSpan = styled.span`
  color: ${(props) => props.color};
  margin-left: 5px;
`;
