export default {
  // A
  accountManagement: {
    original: 'Profile',
    text: 'Perfil',
  },
  administration: {
    original: 'Administration',
    text: 'Administração',
  },
  authentication: {
    original: 'Account',
    text: 'Conta',
  },
  // B
  beneficiary: {
    original: 'Beneficiary',
    text: 'Beneficiário',
  },
  // C
  citizen: {
    original: 'Citizen',
    text: 'Cidadão',
  },
  // D
  digitalWallet: {
    original: 'Wallet',
    text: 'Carteira',
  },
  donationsPortal: {
    original: 'DonationsPortal',
    text: 'Doações',
  },
  donor: {
    original: 'Donor',
    text: 'Doador',
  },
  // E
  ecoop: {
    original: 'Ecoop',
    text: 'Ecoop',
  },
  // F
  // G
  // H
  // I
  // J
  // K
  // L
  localhost: {
    original: 'Development',
    text: ' Desenvolvimento',
  },
  // M
  manager: {
    original: 'Manager',
    text: 'Gestor',
  },
  marketplace: {
    original: 'Marketplace',
    text: 'Feira livre',
  },
  medicalRecord: {
    original: 'MedicalRecord',
    text: 'Prontuário',
  },
  // N
  // O
  operationsFinder: {
    original: 'OperationsFinder',
    text: 'Achei.me',
  },
  // P
  partner: {
    original: 'Partner',
    text: 'Parceiro',
  },
  paymentGateway: {
    original: 'Payments',
    text: 'Pagamentos',
  },
  // Q
  // R
  // S
  socialMeModules: {
    original: 'SocialMe Modules',
    text: 'Módulos SocialMe',
  },
  // T
  telephoneOperator: {
    original: 'TelephoneOperator',
    text: 'Me Liga',
  },
  // U
  // V
  // W
  // Y
  // X
  // Z
};
