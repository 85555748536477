import React, { useEffect, useState } from 'react';
// Libraries
import { Row, Col, message } from 'antd';
import { useParams } from 'react-router-dom';
import { LinkOutlined } from '@ant-design/icons';
import { BiUnlink, BiSearchAlt, BiFile } from 'react-icons/bi';
import Animate, { FadeIn } from 'animate-css-styled-components';
// Own Components
import SelectionItem from './components/SelectionItem';
import DocumentCard from '../DocumentCard';
// Components
import Text from '~/components/typography/text';
import Divider from '~/components/divider';
import Button from '~/components/button';
// methods
import DocumentsWithoutAttachment from './methods/DocumentsWithoutAttachment';
import DocumentSearch from './methods/DocumentSearch';
import DocumentCreation from './methods/DocumentCreation';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function AddAttachments({ process, propertys, CloseDrawer }) {
  const { institutionId, organizationUnitId } = useParams();
  const [activeMethod, setActiveMethod] = useState();
  const [selectedDocument, setSelectedDocument] = useState();

  const processInformation = process || propertys.process[0];

  const options = [
    {
      icon: BiUnlink,
      title: 'Documentos não anexados',
      description: 'Liste os documentos que nunca foram anexos em um processo',
      value: 'DocumentsWithoutAttachment',
    },
    {
      icon: BiSearchAlt,
      title: 'Pesquisa de documentos',
      description: 'Busque por documentos através do seus parâmetros',
      value: 'DocumentSearch',
    },
    {
      icon: BiFile,
      title: 'Criação rápida',
      description: 'Crie um documento que será anexado ao processo sem seguida',
      value: 'DocumentCreation',
    },
  ];

  async function AddAttachment() {
    const params = {
      operation: ['SIGAM', 'Processes', 'Attachments', 'Add'],
      urlParams: {
        institutionId,
        organizationUnitId,
        processId: processInformation.id,
        documentId: selectedDocument.id,
      },
      data: {
        name: selectedDocument.name,
      },
    };
    // console.log(params)
    const response = await SocialMeAPI(params);
    // console.log(response)
    if (response.success) {
      message.success('Documento anexado com sucesso!');
      setActiveMethod();
      setSelectedDocument();
      CloseDrawer();
      if (propertys && propertys.GetDataAgain) {
        propertys.GetDataAgain();
      }
      return propertys.GetAttachmentsAgain();
    }
    message.error('Alguma coisa aconteceu...');
  }

  function RenderMethod() {
    switch (activeMethod) {
      case 'DocumentsWithoutAttachment':
        return (
          <DocumentsWithoutAttachment
            setSelectedDocument={setSelectedDocument}
          />
        );
      case 'DocumentSearch':
        return <DocumentSearch setSelectedDocument={setSelectedDocument} />;
      case 'DocumentCreation':
        return <DocumentCreation setSelectedDocument={setSelectedDocument} />;
      default:
        break;
    }
  }

  useEffect(() => {
    setSelectedDocument();
  }, [activeMethod]);

  return (
    <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
      <Row>
        <Col span={24}>
          <Text>Método de Anexo</Text>
        </Col>
        {options.map((item) => (
          <Col
            key={item.value}
            style={{ padding: '5px 5px 5px 0px' }}
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
          >
            <SelectionItem
              item={item}
              activeValue={activeMethod}
              setActiveValue={setActiveMethod}
            />
          </Col>
        ))}
      </Row>
      <Divider />
      {selectedDocument ? (
        <div>
          <Text>Documento selecionado:</Text>
          <DocumentCard document={selectedDocument} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '10px 0px',
            }}
          >
            <Button type="dashed" onClick={() => setSelectedDocument()}>
              Selecionar outro documento
            </Button>
            <Button
              icon={<LinkOutlined />}
              type="primary"
              onClick={() => AddAttachment()}
            >
              Anexar documento
            </Button>
          </div>
        </div>
      ) : (
        RenderMethod()
      )}
    </Animate>
  );
}
