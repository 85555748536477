import React, { useContext } from 'react';
// Libraries
import { PlusOutlined } from '@ant-design/icons';
import { Form, message } from 'antd';
// Contexts
import { SIGAMContext } from '~/contexts/SIGAMContext';
// Own Components
import AddForm from './components/AddForm';
// Components
import Drawer from '~/components/drawer';
import Button from '~/components/button';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Add({ GetDataAgain }) {
  const { activeInstitution } = useContext(SIGAMContext);
  const [form] = Form.useForm();

  const DrawerBody = () => {
    return <AddForm form={form} />;
  };

  const AddFooterButton = ({ CloseDrawer }) => {
    async function onFinish(data) {
      if (data.status) {
        data.status = 'ACTIVE';
      }
      data.kind = 'HTML';
      const params = {
        operation: ['SIGAM', 'DocumentTemplates', 'Add'],
        urlParams: {
          institutionId: activeInstitution.id,
        },
        data,
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        message.success('Modelo de Documento criado com sucesso!');
        GetDataAgain();
        CloseDrawer();
        return form.resetFields();
      }
      return message.error('Alguma coisa aconteceu...');
    }
    return (
      <Button
        type="primary"
        onClick={() => {
          form
            .validateFields()
            .then((values) => {
              onFinish(values);
            })
            .catch((info) => {
              // console.log('Validate Failed:', info);
            });
        }}
      >
        Adicionar
      </Button>
    );
  };

  return (
    <Drawer
      Trigger={
        <Button icon={<PlusOutlined />} type="primary">
          Novo Modelo de Documento
        </Button>
      }
      title="Novo Modelo de Documento"
      DrawerContentComponent={DrawerBody}
      footer
      footerCancelButton
      FooterMainButton={AddFooterButton}
      width="100%"
    />
  );
}
