import React, { useRef } from 'react';
// Components
import SunEditor from 'suneditor-react';
import FormItem from '~/components/form/FormItem';
import 'suneditor/dist/css/suneditor.min.css';

export default function ManualForm({ form }) {
  const editor = useRef();

  async function onPaste(event, cleanData, maxCharCount, core) {
    const stripedHtml = cleanData.replace(/<(?!\s*br\s*\/?)[^>]+>/gi, '');
    return stripedHtml;
  }

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  return (
    <div>
      <FormItem
        label=""
        name={['body']}
        item={
          <SunEditor
            lang="pt_br"
            name="body"
            autoFocus
            getSunEditorInstance={getSunEditorInstance}
            setOptions={{
              buttonList: [
                ['undo', 'redo'],
                ['font', 'fontSize', 'formatBlock'],
                ['paragraphStyle', 'blockquote'],
                [
                  'bold',
                  'underline',
                  'italic',
                  'strike',
                  'subscript',
                  'superscript',
                ],
                ['fontColor', 'hiliteColor', 'textStyle'],
                ['removeFormat'],
                ['outdent', 'indent'],
                ['align', 'horizontalRule', 'list', 'lineHeight'],
                ['table', 'link', 'image'],
                ['fullScreen', 'showBlocks', 'codeView'],
              ],
            }}
            onPaste={(event, cleanData, maxCharCount, core) =>
              onPaste(event, cleanData, maxCharCount, core)
            }
            //onPaste={(event, cleanData, maxCharCount, core) =>event.preventDefault()}
          />
        }
      />
    </div>
  );
}
