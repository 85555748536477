import React, { useEffect, useState } from 'react';
// Libraries
import { useParams } from 'react-router-dom';
import Animate, { FadeIn } from 'animate-css-styled-components';
import { Alert } from 'antd';
// Own Components
// Components
import Text from '~/components/typography/text';
import Loading from '~/components/loading';
import Drawer from '~/components/drawer';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { BsFile, BsFiles } from 'react-icons/bs';
import NewAttachment from './components/NewAttachment';
import NewAttachments from './components/NewAttachments';
import AttachmentsList from './components/AttachmentsList';
import './styles.css';

export default function Attachments({ document, propertys }) {
  const { institutionId, organizationUnitId } = useParams();
  const [attachments, setAttachments] = useState([]);
  const [loadingContent, setLoadingContent] = useState(true);
  const [accessLevel, setAccessLevel] = useState();

  const documentInformation = document || propertys.document[0];

  async function GetAttachmentsAgain() {
    const params = {
      operation: ['SIGAM', 'Documents', 'Attachments', 'Get'],
      urlParams: {
        institutionId,
        organizationUnitId,
        documentId: documentInformation.id,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      setAttachments(response.body.attachments.elements);
    }
  }

  useEffect(() => {
    async function GetAttachments() {
      const params = {
        operation: ['SIGAM', 'Documents', 'Attachments', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: documentInformation.id,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setAttachments(response.body.attachments.elements);
      }
      setLoadingContent(false);
    }
    async function verifyPermission() {
      const params = {
        operation: ['SIGAM', 'Documents', 'HavePermission'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: documentInformation.id,
        },
      };
      const response = await SocialMeAPI(params);
      setAccessLevel(response.body.access_level);
      GetAttachments();
    }
    verifyPermission();
  }, [documentInformation, institutionId, organizationUnitId]);

  return (
    <div>
      <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
        {accessLevel && accessLevel.is_in_charge === false && (
          <Alert
            style={{ marginBottom: '10px' }}
            message="Você não tem permissão para alterar o documento"
            type="warning"
            showIcon
          />
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            padding: 10,
          }}
        >
          {accessLevel && accessLevel.is_in_charge && (
            <Drawer
              Trigger={
                <div
                  className="option-container"
                  style={{
                    border: '1px solid #d9d9d9',
                    borderStyle: 'dashed',
                    padding: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    borderRadius: 10,
                    cursor: 'pointer',
                    backgroundColor: '#fafafa',
                  }}
                >
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                  >
                    <BsFile />
                    <Text style={{ fontWeight: 'bold' }}>Único anexo</Text>
                  </div>
                  <Text>Adicione somente 1 anexo por vez</Text>
                </div>
              }
              title="Novo anexo"
              width="50%"
              DrawerContentComponent={NewAttachment}
              DrawerContentComponentParams={{
                GetAttachmentsAgain,
                document: documentInformation,
                GetDataAgain: propertys && propertys.GetDataAgain,
              }}
            />
          )}

          {accessLevel && accessLevel.is_in_charge && (
            <Drawer
              Trigger={
                <div
                  className="option-container"
                  style={{
                    border: '1px solid #d9d9d9',
                    borderStyle: 'dashed',
                    padding: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    borderRadius: 10,
                    cursor: 'pointer',
                    backgroundColor: '#fafafa',
                  }}
                >
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                  >
                    <BsFiles />
                    <Text style={{ fontWeight: 'bold' }}>Vários anexos</Text>
                  </div>
                  <Text>Adicione vários anexos em lote</Text>
                </div>
              }
              title="Novo anexo"
              width="50%"
              DrawerContentComponent={NewAttachments}
              DrawerContentComponentParams={{
                GetAttachmentsAgain,
                document: documentInformation,
                GetDataAgain: propertys && propertys.GetDataAgain,
              }}
            />
          )}
        </div>
        {loadingContent ? (
          <Loading />
        ) : (
          <AttachmentsList
            attachments={attachments}
            document={documentInformation}
            GetAttachmentsAgain={GetAttachmentsAgain}
            accessLevel={accessLevel}
          />
        )}
      </Animate>
    </div>
  );
}
