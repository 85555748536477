import React, { useEffect, useState } from 'react';
// Libraries
import Animate, { FadeIn } from 'animate-css-styled-components';
import { useParams } from 'react-router-dom';
import { Result } from 'antd';
// Pages
import Message from './pages/Message';
import ByPassword from './pages/ByPassword';
import Filed from './pages/Filed';
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Close({ process, propertys, CloseDrawer }) {
  const { institutionId, organizationUnitId } = useParams();
  const [pageActive, setPageActive] = useState('Message');
  const [accessLevel, setAccessLevel] = useState();

  const processInformation = process || propertys.process[0];

  // console.log(processInformation)

  function Render() {
    switch (pageActive) {
      case 'Message':
        return <Message setPageActive={setPageActive} />;
      case 'ByPassword':
        return (
          <ByPassword
            setPageActive={setPageActive}
            process={processInformation}
            GetDataAgain={propertys.GetDataAgain}
          />
        );
      case 'Filed':
        return <Filed setPageActive={setPageActive} />;
      default:
    }
  }

  useEffect(() => {
    setPageActive('Message');
    if (processInformation) {
      if (processInformation.status === 'INACTIVE') {
        setPageActive('Filed');
      }
    }
    async function verifyPermission() {
      const params = {
        operation: ['SIGAM', 'Processes', 'HavePermission'],
        urlParams: {
          institutionId,
          organizationUnitId,
          processId: processInformation.id,
        },
      };
      const response = await SocialMeAPI(params);
      setAccessLevel(response.body.access_level);
    }
    verifyPermission();
  }, [processInformation]);

  if (accessLevel) {
    if (!accessLevel.is_in_charge) {
      return <Result status="error" title="Você não pode alterar o processo" />;
    }
  }

  return (
    <div>
      <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
        {Render()}
      </Animate>
    </div>
  );
}
