import React, { useEffect, useState } from 'react';
// Libraries
import { useParams } from 'react-router-dom';
// Own Components
import Header from './components/Header';
import Content from './components/Content';
import Options from './components/Options';
// Components
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Processes() {
  const { institutionId, organizationUnitId } = useParams();
  const [loadingContent, setLoadingContent] = useState(true);
  const [searchInput, setSearchInput] = useState();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [selectedRow, setselectedRow] = useState([]);
  const [documents, setDocuments] = useState();
  const [filters, setFilters] = useState({
    i_am_owner: true,
    organizational_unity: undefined,
    i_am_signatory: undefined,
    i_signed: undefined,
    sort: {
      by: 'created_at',
      order: 'DESC',
    },
  });
  const [pageParameters, setPageParameters] = useState({
    currentPage: 1,
    totalElements: 0,
    limit: 10,
  });

  async function GetDataAgain(newPageParameters) {
    setLoadingContent(true);
    const params = {
      operation: ['SIGAM', 'Citizen', 'Processes', 'Get'],
      urlParams: {
        institutionId: institutionId,
      },
      data: {
        filter: {
          searchString: searchInput,
          i_am_owner: filters.i_am_owner,
          organizational_unity: filters.organizational_unity,
          i_am_signatory: filters.i_am_signatory,
          i_signed: filters.i_signed === false ? false : undefined
        },
        pagination: {
          limit: newPageParameters
            ? newPageParameters.limit
            : pageParameters.limit,
          page: newPageParameters
            ? newPageParameters.currentPage
            : pageParameters.currentPage,
          sort: {
            by: filters.sort.by,
            order: filters.sort.order,
          },
        },
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      setPageParameters({
        ...pageParameters,
        currentPage: response.body.processes.currentPage,
        totalElements: response.body.processes.totalElements,
        limit: response.body.processes.limit,
      });
      setDocuments(response.body.processes.elements);
      setLoadingContent(false);
    }
  }

  useEffect(() => {
    async function GetDocuments() {
      const params = {
        operation: ['SIGAM', 'Citizen', 'Processes', 'Get'],
        urlParams: {
          institutionId: institutionId,
        },
        data: {
          filter: {
            /*
            searchString: searchInput,
            i_am_owner: filters.i_am_owner,
            organizational_unity: filters.organizational_unity,
            i_am_signatory: filters.i_am_signatory,
            i_signed: filters.i_signed === false ? false : undefined
            */
          },
          pagination: {
            limit: 10,
            page: 1,
            sort: {
              by: filters.sort.by,
              order: filters.sort.order,
            },
          },
        },
      };
      const response = await SocialMeAPI(params);
      console.log(response)
      if (response.success) {
        setPageParameters({
          currentPage: response.body.processes.currentPage,
          totalElements: response.body.processes.totalElements,
          limit: response.body.processes.limit,
        });
        setDocuments(response.body.processes.elements);
        if (searchInput) {
          setLoadingSearch(false)
        }
      }
      setLoadingContent(false);
    }
    if (searchInput) {
      setLoadingSearch(true)
    }
    GetDocuments();
  }, [searchInput, institutionId, organizationUnitId, filters]);


  return (
    <div>
      <Header
        GetDataAgain={GetDataAgain}
        setSearchInput={setSearchInput}
        loadingSearch={loadingSearch}
        filters={filters}
        setFilters={setFilters}
      />
      <Options selectedRow={selectedRow} GetDataAgain={GetDataAgain} />
      {loadingContent ? (
        <Loading text={'Carregando Processos'} />
      ) : (
        <Content
          data={documents}
          pageParameters={pageParameters}
          GetDataAgain={GetDataAgain}
          setselectedRow={setselectedRow}
        />
      )}
    </div>
  );
}
