import React, { useContext, memo } from 'react';

import { ThemeContext } from '~/contexts/ThemeContext';
import { QuestionCircleFilled } from '@ant-design/icons';

import { Tooltip } from 'antd';

function AntIconHelper({ text }) {
  const { theme } = useContext(ThemeContext);

  return (
    <Tooltip
      placement="topLeft"
      overlayStyle={{
        color: theme.color.textOnPrimaryBackground,
      }}
      mouseEnterDelay={0.5}
      title={text}
    >
      <QuestionCircleFilled
        style={{ color: theme.color.primary, fontSize: 18 }}
      />
    </Tooltip>
  );
}

export default memo(AntIconHelper);
