import React, { useContext, useEffect, useState } from 'react';
// Libraries
import { Dropdown } from 'antd';
import { RetweetOutlined } from '@ant-design/icons';
import { useParams, NavLink } from 'react-router-dom';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Components
import Button from '~/components/button';
import Loading from '~/components/loading';
// Standalone Components
import { MenuContainer, MenuItem, Container } from './style';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { CapitalizeString } from '~/utils/Transformations';

export default function DashboardSwitchButton() {
    const { theme } = useContext(ThemeContext);
    const { isMobile } = useContext(ResponsiveContext);
    const { institutionId, organizationUnitId } = useParams();
    const [loadingContent, setLoadingContent] = useState(true);
    const [organizationalUnits, setOrganizationalUnits] = useState([]);
    const [activeOrganizationUnit, setActiveOrganizationUnit] = useState();
    const [calledAPI, setCalledAPI] = useState(false);

    const menu = (
        <MenuContainer
            borderColor={theme.color.borderColor}
            backgroundColor={theme.color.backgroundSecondaryLight}
            marginTop={isMobile ? '10px' : '0px'}
            fontSize={isMobile ? '12pt' : '10pt'}
        >
            {organizationalUnits.map(item => (
                <MenuItem key={item.id} style={{ marginBottom: 10 }} onClick={() => setActiveOrganizationUnit(item)}>
                    <NavLink to={`/${institutionId}/agente-publico/${item.id}`} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        {CapitalizeString(item.name)}
                    </NavLink>
                </MenuItem>
            ))}

        </MenuContainer>
    );

    useEffect(() => {
        async function GetOrganizationalUnits() {
            const params = {
                operation: ['SIGAM', 'OrganizationalUnits', 'Get'],
                urlParams: {
                    institutionId: institutionId,
                },
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                if (response.body.orgUnities.elements.length > 0) {
                    setOrganizationalUnits(response.body.orgUnities.elements)
                    let array = response.body.orgUnities.elements;
                    for (let i = 0; i < array.length; i++) {
                        if (Number(organizationUnitId) === Number(array[i].id)) {
                            setActiveOrganizationUnit(array[i]);
                        }
                    }
                }
            }
            return setLoadingContent(false);
        }
        if(!calledAPI){
            setCalledAPI(true);
            GetOrganizationalUnits();
        }
    }, [activeOrganizationUnit, calledAPI, institutionId, organizationUnitId]);

    if (loadingContent) {
        return (
            <Button type={'dashed'}>
                <Loading withoutText height="0px" />
            </Button>
        )
    }

    return (
        <Container>
            <Dropdown overlay={menu} trigger={['click']}>
                <Button type={'dashed'} icon={<RetweetOutlined />}>
                    {activeOrganizationUnit.name}
                </Button>
            </Dropdown>
        </Container>
    )
}