import React from 'react';
import { Form, Button, Input } from 'antd';

import { useArchiveFilesPageManager } from '../../../../../../contexts/ArchiveFilesPageManager/Provider';

const { TextArea } = Input;

export default function FilesFilterForm({ form, initialFilter, onFinish }) {
  const { archive } = useArchiveFilesPageManager();

  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      form={form}
      layout="vertical"
      scrollToFirstError
      onFinish={onFinish}
      initialValues={initialFilter}
    >
      <Form.Item label="Nome" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="Descrição" name="description">
        <TextArea rows={4} />
      </Form.Item>

      {archive.metas.map((meta, index) => (
        <Form.Item key={meta} label={meta} name={`meta${index + 1}`}>
          <Input />
        </Form.Item>
      ))}

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            width: '100%',
          }}
        >
          Filtrar acervos
        </Button>
      </Form.Item>
    </Form>
  );
}
