import React from 'react';
// Libraries
import {
    UserAddOutlined
} from '@ant-design/icons';
// Components
import Title from '~/components/typography/title';
import Button from '~/components/button';
import SearchDrawer from '~/pages/SIGAM/components/SearchDrawer';
import SearchDrawerComponent from './components/SearchDrawerComponent';

export default function Header({ record, users, setUpdatePending, CloseDrawerFather }) {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
                <Title level={5} style={{ margin: 0 }}>Usuários</Title>
            </div>
            <div>
                <SearchDrawer
                    Trigger={
                        <Button icon={<UserAddOutlined />} type="primary">Novo usuário</Button>
                    }
                    title="Novo usuários"
                    SecondStepComponent={SearchDrawerComponent}
                    secondStepParameters={{ record, users, setUpdatePending, CloseDrawerFather, searchOnSocialMe: false }}
                    width="50%"
                />
            </div>
        </div>
    )
}