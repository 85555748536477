import React, { useEffect, useState } from 'react';
// Libraries
import { SearchOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
// Own Components
import PeopleList from './components/PeopleList';
// Components
import Title from '~/components/typography/title';
import Divider from '~/components/divider';
import Button from '~/components/button';
import Loading from '~/components/loading';
import SearchDrawer from '~/pages/SIGAM/components/SearchDrawer';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
// Pages
import SearchDrawerComponent from './components/SearchDrawerComponent';

export default function PeopleWithPermission({ process, accessLevel }) {
    const { institutionId, organizationUnitId } = useParams();
    const [people, setPeople] = useState([]);
    const [loadingContent, setLoadingContent] = useState(true);

    async function GetPeopleAgain() {
        const params = {
            operation: ['SIGAM', 'Processes', 'PrivateAccess', 'Get'],
            urlParams: {
                institutionId,
                organizationUnitId,
                processId: process.id
            }
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            setPeople(response.body.privateAccess.elements);
        }
    }

    useEffect(() => {
        async function GetPeople() {
            const params = {
                operation: ['SIGAM', 'Processes', 'PrivateAccess', 'Get'],
                urlParams: {
                    institutionId,
                    organizationUnitId,
                    processId: process.id
                }
            };
            const response = await SocialMeAPI(params);
            console.log(response)
            if (response.success) {
                setPeople(response.body.privateAccess.elements);
            }
            setLoadingContent(false);
        }
        GetPeople();
    }, [process, institutionId, organizationUnitId])

    function renderDrawer() {
        if(process.total_tramitations > 0) return <></>
        if (accessLevel) {
            if (accessLevel.is_in_charge) {
                return <SearchDrawer
                    Trigger={
                        <Button icon={<SearchOutlined />}>Buscar Usuário</Button>
                    }
                    title="Buscar Usuários"
                    SecondStepComponent={SearchDrawerComponent}
                    secondStepParameters={{ process, people, GetPeopleAgain, searchOnSocialMe: true, }}
                />
            }
        }
    }

    return (
        <div>
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                <Title level={5}>Pessoas Autorizadas</Title>
                {renderDrawer()}
            </div>
            {loadingContent ?
                <Loading />
                :
                <PeopleList accessLevel={accessLevel} people={people} process={process} GetPeopleAgain={GetPeopleAgain} />
            }
        </div>
    )
}