import React from 'react';
import { Menu } from 'antd';

import VIEWS from '../../../../../../constants/views';

export default function DotsMenuOptions({ setView }) {
  return (
    <Menu
      items={[
        {
          key: '1',
          label: 'Minhas conversas',
          onClick: () => {
            setView(VIEWS.MY_CHATS);
          },
        },
        {
          key: '2',
          label: 'Novo grupo de conversa',
          onClick: () => {
            setView(VIEWS.NEW_CHAT);
          },
        },
        {
          key: '3',
          label: 'Nova conversa',
          onClick: () => {
            setView(VIEWS.LIST_CONTACTS);
          },
        },
      ]}
    />
  );
}
