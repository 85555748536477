import React from 'react';

import MessagerProvider from './contexts/MessagerManager/Provider';

import Page from './Page';

export default function MessagerProvuider() {
  return (
    <MessagerProvider>
      <Page />
    </MessagerProvider>
  );
}
