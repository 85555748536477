import React, { useContext } from 'react';
import { Space, Card, Typography } from 'antd';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
//import Settings from './components/Settings';
import NewMessage from './components/NewMessage';

const { Title } = Typography;

export default function Header() {
  const { isMobile } = useContext(ResponsiveContext);
  return (
    <Card bodyStyle={{ padding: 10 }} style={{ marginBottom: '8px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: isMobile ? 'center' : 'space-between',
          alignItems: isMobile ? 'center' : 'start',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <Title level={4} style={{ margin: isMobile ? '0px 0px 10px' : 0 }}>
          Mensageria
        </Title>
        <Space align="center">
          <NewMessage />
        </Space>
      </div>
    </Card>
  );
}
