import styled from 'styled-components';

export const ImageCookies = styled.img`
  width: 64px;
`;

export const Link = styled.a``;

export const LinkText = styled.span`
  font-size: 8pt;
`;
