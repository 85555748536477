import { useState, useEffect } from 'react';

export default function useHeigthPixeilsToScroll() {
  const [height, setHeight] = useState('100%');

  useEffect(() => {
    const idealHeight = window.innerHeight - 100;

    setHeight(`${idealHeight}px`);
  }, [window]);

  return height;
}
