import React from 'react';
import { Form, Button, Input, message } from 'antd';

import { useArchiveFilesPageManager } from '../../contexts/ArchiveFilesPageManager/Provider';

const { TextArea } = Input;

export default function FileForm({
  initialValues,
  onFinish,
  form,
  loadingButton,
  submitButtonText,
}) {
  const { archive } = useArchiveFilesPageManager();

  function onFinishFailed() {
    message.error('Dados do formulário encontrado.');
  }

  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      form={form}
      layout="vertical"
      scrollToFirstError
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={initialValues}
    >
      <Form.Item
        label="Nome"
        name="name"
        rules={[
          {
            required: true,
            message: 'O nome é obrigatório',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Descrição" name="description">
        <TextArea rows={4} />
      </Form.Item>

      {archive.metas.map((meta, index) => (
        <Form.Item key={meta} label={meta} name={`meta${index + 1}`}>
          <Input />
        </Form.Item>
      ))}
      <Form.Item>
        <Button
          loading={loadingButton}
          type="primary"
          htmlType="submit"
          style={{
            width: '100%',
          }}
        >
          {submitButtonText}
        </Button>
      </Form.Item>
    </Form>
  );
}
