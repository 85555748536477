import React from 'react';
import { Typography } from 'antd';

import Loading from '~/components/loading';
import ChatItem from './components/Item';

import * as S from './style';

const { Title } = Typography;

export default function Chats({ chats, loadingChats }) {
  if (loadingChats) {
    return <Loading height="50%" text="Carregando conversas" />;
  }

  if (chats.length === 0) {
    return (
      <S.Center>
        <Title level={3}>Nenhuma conversa encontrada</Title>
      </S.Center>
    );
  }

  return (
    <>
      {chats.map((chat) => (
        <ChatItem key={chat.id} chat={chat} />
      ))}
    </>
  );
}
