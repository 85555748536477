import React from 'react';
// Libraries
import { List, message } from 'antd';
import { useParams } from 'react-router-dom';
import Animate, { FadeIn } from 'animate-css-styled-components';
// Components
import Button from '~/components/button';
import Drawer from '~/components/drawer';
import Text from '~/components/typography/text';
// Utils
import { CapitalizeString } from '~/utils/Transformations';
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function AttachmentsList({ attachments, process, GetAttachmentsAgain }) {
    const { institutionId, organizationUnitId } = useParams();

    async function Inactivate(item) {
        const params = {
            operation: ['SIGAM', 'Processes', 'Attachments', 'Delete'],
            urlParams: {
                institutionId: institutionId,
                organizationUnitId: organizationUnitId,
                processId: process.id,
                documentId: item.id
            }
        };
        const response = await SocialMeAPI(params);
        console.log(response)
        if (response.success) {
            message.success('Anexo inativado com sucesso!');
            GetAttachmentsAgain();
        } else {
            message.error('Alguma coisa aconteceu...')
        }
    }

    function Body() {
        return (
            <div>
                <p>Body</p>
            </div>
        )
    }

    return (
        <div style={{ marginTop: 10 }}>
            <List
                itemLayout="horizontal"
                dataSource={attachments}
                locale={{ emptyText: 'O documento não possui anexos' }}
                renderItem={(item) => (
                    <Animate
                        Animation={[FadeIn]}
                        duration={['0.5s']}
                        delay={['0.2s']}
                    >
                        <List.Item actions={[
                            item.status === 'INACTIVE' ?
                                <Button size="small" disabled danger>
                                    Inativar Anexo
                                </Button>
                                :
                                <Button size="small" onClick={() => Inactivate(item)} danger>
                                    Inativar Anexo
                                </Button>
                        ]}
                        >
                            <List.Item.Meta
                                title={
                                    <Drawer
                                        Trigger={
                                            <Text style={{ cursor: 'pointer' }}>
                                                {CapitalizeString(item.name)}
                                            </Text>
                                        }
                                        DrawerContentComponent={Body}
                                    />
                                }
                            />
                        </List.Item>
                    </Animate>
                )}
            />
        </div>
    )
}