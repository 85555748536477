export default {
  // A
  // B
  // C
  // D
  // E
  // F
  // G
  // H
  // I
  // J
  // K
  // L
  // M
  // N
  // O
  // P
  // Q
  // R
  // S
  socialMeLogo: {
    original: 'Social Me Logo',
    text: 'Logo da Social Me',
  },
  // T
  // U
  // V
  // W
  // Y
  // X
  // Z
};
