import React, { useState } from 'react';

import { Form, Drawer } from 'antd';
import { useArchivesPageManager } from '../../../../../../../../../../contexts/ArchivesPageManager/Provider';
import ArchiveForm from './Fields';

export default function ArchiveUpdateForm({ archive, open, setOpen }) {
  const [loading, setLoading] = useState(false);

  const { UpdateArchive } = useArchivesPageManager();

  const [form] = Form.useForm();

  const onClose = () => {
    setOpen(false);
  };

  async function onFinish(values) {
    setLoading(true);
    await UpdateArchive({ archive: values, archiveId: archive.id });
    setLoading(false);
  }

  return (
    <Drawer
      title="Editando acervo"
      placement="right"
      onClose={onClose}
      open={open}
    >
      <ArchiveForm
        form={form}
        initialValues={archive}
        onFinish={onFinish}
        loadingButton={loading}
        submitButtonText="Editar acervo"
      />
    </Drawer>
  );
}
