import React, { useState, useEffect } from 'react';
// Libraries
import { Switch } from 'antd';
import { useParams } from 'react-router-dom';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import TextArea from '~/components/textArea';
import Select from '~/components/select';
import Checkbox from '~/components/checkbox';
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function AddForm() {
    const { institutionId } = useParams();
    const [loadingForm, setLoadingForm] = useState(true);
    const [types, setTypes] = useState([]);

    useEffect(() => {
        async function GetData() {
            const params = {
                operation: ['SIGAM', 'TypesOfProcesses', 'Get'],
                urlParams: {
                    institutionId: institutionId,
                },
                data: {
                    pagination: {
                        sort: {
                            by: 'name',
                            order: 'asc',
                        },
                        limit: 0
                    },
                },
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                let array = response.body.processKinds.elements;
                let newArray = [];
                for (let i = 0; i < array.length; i++) {
                    let newItem = {}
                    newItem.label = array[i].name;
                    newItem.value = array[i].id;
                    newArray.push(newItem)
                }
                setTypes(newArray);
            }
            setLoadingForm(false);
        }
        GetData();
    }, [institutionId]);

    if (loadingForm) {
        return <Loading text="Carregando formulário" />
    }

    return (
        <div>
            <FormItem
                label={'Nome do Documento'}
                name={['name']}
                rules={[
                    {
                        required: true,
                        message: 'Insira um nome para o processo',
                    },
                ]}
                item={<Input />}
            />
            <FormItem
                label={'Tipo de Processo'}
                name={['kind']}
                item={<Select options={types} noDefaultValue />}
            />
            <FormItem
                label={'Assunto'}
                name={['subject']}
                item={<Input />}
            />
            <FormItem
                label={'Especificações'}
                name={['specifications']}
                item={<TextArea />}
            />
            <FormItem
                label="Situação"
                name="status"
                valuePropName="checked"
                item={<Checkbox text={'Processo ativo'} />}
            />
            <FormItem
                label={'Processo Prioritário'}
                name={['priority']}
                valuePropName="checked"
                item={<Switch />}
            />
        </div>
    )
}