import React, { useState, useEffect } from 'react';
// Libraries
import { Row, Alert, Result, Button } from 'antd';
import { GrTrash } from 'react-icons/gr';
import Animate, { FadeIn } from 'animate-css-styled-components';
import { useParams } from 'react-router-dom';
// Components
import Loading from '~/components/loading';
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Delete({ document, propertys, CloseDrawer }) {
  const { institutionId, organizationUnitId } = useParams();
  const [loadingContent, setLoadingContent] = useState(true);
  const [accessLevel, setAccessLevel] = useState();
  const [loadingButton, setLoadingButton] = useState(false);

  const documentInformation = document || propertys.document[0];

  async function onDelete() {
    setLoadingButton(true);
    const params = {
      operation: ['SIGAM', 'Documents', 'Delete'],
      urlParams: {
        institutionId,
        organizationUnitId,
        documentId: documentInformation.id,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      if (propertys && propertys.GetDataAgain) {
        propertys.GetDataAgain();
      }
      if (CloseDrawer) {
        CloseDrawer();
      }
    }
    setLoadingButton(false);
  }

  useEffect(() => {
    async function verifyPermission() {
      const params = {
        operation: ['SIGAM', 'Documents', 'HavePermission'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: documentInformation.id,
        },
      };
      const response = await SocialMeAPI(params);
      setAccessLevel(response.body.access_level);
      setLoadingContent(false);
    }
    verifyPermission();
  }, [institutionId, organizationUnitId, documentInformation]);

  if (loadingContent) {
    return <Loading />;
  }

  if (accessLevel?.is_in_charge === false) {
    return (
      <Result
        status="error"
        title="Você não tem permissão para deletar o documento"
      />
    );
  }

  if (documentInformation.process_count > 0) {
    return (
      <Result
        status="error"
        title="Não pode deletar um documento que se encontra anexado"
      />
    );
  }

  return (
    <div>
      <Alert
        style={{ marginBottom: '10px' }}
        message="Uma vez que o documento seja deletado, você não terá mais acesso ao mesmo"
        type="warning"
        showIcon
      />
      <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
        <Row>
          <Title style={{ textAlign: 'center', width: '100%' }} level={4}>
            Deletar documento
          </Title>
          <Text style={{ textAlign: 'center', width: '100%' }}>
            Uma vez que o documento seja deletado, você não terá mais acesso ao
            mesmo. Você tem certeza disso?
          </Text>
        </Row>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
            width: '100%',
          }}
        >
          <Button
            danger
            style={{
              backgroundColor: '#ff4d4f',
              color: 'white',
            }}
            onClick={() => onDelete()}
            loading={loadingButton}
          >
            Sim, deletar documento
          </Button>
        </Row>
      </Animate>
    </div>
  );
}
