import React, { useContext } from 'react';
import { Space, Card, Typography, Badge } from 'antd';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

import FilterArchive from './components/FilterArchive';
import NewArchive from './components/NewArchive';
const { Title } = Typography;

export default function Header() {
  const { isMobile } = useContext(ResponsiveContext);
  return (
    <Card bodyStyle={{ padding: 10 }} style={{ marginBottom: '8px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: isMobile ? 'center' : 'space-between',
          alignItems: isMobile ? 'center' : 'start',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <Title level={4} style={{ margin: isMobile ? '0px 0px 10px' : 0 }}>
          Acervos
        </Title>

        <Space
          align="center"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Badge status="processing" text="Meu acervo" />
          <Badge status="success" text="Unidade organizacional" />
          <FilterArchive />
          <NewArchive />
        </Space>
      </div>
    </Card>
  );
}
