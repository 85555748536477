import React from 'react';
// Components
import Title from '~/components/typography/title';
//import Text from '~/components/typography/text';

export default function Terms() {
    return (
        <div>
            <Title level={4}>Termos e Condições do Gestor</Title>
        </div>
    )
}