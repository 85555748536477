import React, { useContext, memo } from 'react';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Libraries
import { Checkbox, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

function AntCheckbox({
  text,
  onChange,
  disabled,
  defaultChecked,
  checked,
  helpText,
}) {
  const { theme } = useContext(ThemeContext);
  const HelpMessage = () => {
    if (helpText) {
      return (
        <Tooltip title={helpText}>
          <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
        </Tooltip>
      );
    }
    return null;
  };
  return (
    <Checkbox
      onChange={onChange}
      disabled={disabled}
      defaultChecked={defaultChecked}
      checked={checked}
      style={{ color: theme.color.textOnSecondaryBackground }}
    >
      {text} {HelpMessage()}
    </Checkbox>
  );
}

export default memo(AntCheckbox);
