import React, { useContext, useState, useEffect } from 'react';
// Contexts
import { Tag } from 'antd';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Libraries
// Own Components
import Interested from './components/Interested';
import Signatories from './components/Signatories';
import Tags from './components/Tags';
import Options from '../Options';
// Components
import Card from '~/components/card';
import Table from '~/components/table';
import {
  PrimaryAndSecondaryTextCell,
  BadgeCell,
  DateAndTimeCell,
} from '~/components/table/cells';
import { Flex } from './style';
import Pagination from '~/components/pagination';
import Tooltip from '~/components/tooltip';
import Text from '~/components/typography/text';

export default function Content({
  data,
  GetDataAgain,
  pageParameters,
  setselectedRow,
}) {
  const { isMobile } = useContext(ResponsiveContext);
  const [headerHeight, setHeaderHeight] = useState(null);
  const [paginationHeight, setpaginationHeight] = useState(null);

  useEffect(() => {
    if (!isMobile) {
      const height_header = document.getElementById('header').clientHeight;
      setHeaderHeight(height_header);

      const height_pagination =
        document.getElementById('pagination').clientHeight;
      setpaginationHeight(height_pagination);
    }
  }, []);

  const columns = [
    {
      title: 'Documento',
      dataIndex: 'internal_id',
      key: 'internal_id',
      render: (internal_id, record) => (
        <div style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Tooltip
            title={
              record.status === 'ACTIVE'
                ? 'Documento Ativo'
                : 'Documento Inativo'
            }
          >
            <div style={{ width: '20px' }}>
              <BadgeCell
                status={record.status === 'ACTIVE' ? 'success' : 'error'}
                text=""
              />
            </div>
          </Tooltip>
          <Flex direction="column" align={isMobile ? 'center' : 'flex-start'}>
            <Text style={{ textAlign: isMobile ? 'center' : 'left' }} strong>
              {record.name}
            </Text>
            <Text style={{ textAlign: isMobile ? 'center' : 'left' }}>
              {`Número: ${internal_id.split('/')[0]}`}
            </Text>
          </Flex>
        </div>
      ),
    },
    {
      title: 'Tags',
      dataIndex: 'internal_id',
      key: 'internal_id',
      render: (internal_id, record) => (
        <div>
          <Tags item={record} />
        </div>
      ),
    },
    {
      title: 'Criação',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at, record) => (
        <div>
          <DateAndTimeCell timestamp={created_at} />
          <Text>
            Por:
            <Tag
              color={
                record.created_at_unity_settings &&
                record.created_at_unity_settings.color
                  ? record.created_at_unity_settings.color
                  : 'default'
              }
              style={{ marginLeft: 5 }}
            >
              {record.created_at_unity_name}
            </Tag>
          </Text>
        </div>
      ),
    },

    {
      title: 'Interessados',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <Interested id={id} />,
    },
    {
      title: 'Assinantes',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <Signatories id={id} />,
    },
  ];

  const mobileColumns = [
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <div>
          <BadgeCell
            status={status === 'ACTIVE' ? 'success' : 'error'}
            text={status === 'ACTIVE' ? 'Documento Ativo' : 'Documento Inativo'}
          />
        </div>
      ),
    },
    {
      title: 'Criação',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at, record) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <DateAndTimeCell timestamp={created_at} />
          <Text>
            Por:
            <Tag
              color={
                record.created_at_unity_settings &&
                record.created_at_unity_settings.color
                  ? record.created_at_unity_settings.color
                  : 'default'
              }
              style={{ marginLeft: 5 }}
            >
              {record.created_at_unity_name}
            </Tag>
          </Text>
        </div>
      ),
    },
    {
      title: 'Documento',
      dataIndex: 'internal_id',
      key: 'internal_id',
      render: (internal_id, record) => (
        <div>
          <PrimaryAndSecondaryTextCell
            primaryText={record.name}
            secondaryText={`Número: ${internal_id.split('/')[0]}`}
          />
          <Tags item={record} />
        </div>
      ),
    },
    {
      title: 'Interessados',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <Interested id={id} />,
    },
    {
      title: 'Assinantes',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <Signatories id={id} />,
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => <Options selectedRow={[record]} mobileColumn />,
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setselectedRow(selectedRows);
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  };

  if (isMobile) {
    return (
      <div>
        <Table dataReceived={data} tableColumns={mobileColumns} />
        <div style={{ padding: 10 }}>
          <Pagination
            current={pageParameters.currentPage}
            pageSize={pageParameters.limit}
            onChange={(currentPage, limit) =>
              GetDataAgain({ currentPage, limit })
            }
            onShowSizeChange={(currentPage, limit) =>
              GetDataAgain({ currentPage, limit })
            }
            total={pageParameters.totalElements}
            showSizeChanger
            showQuickJumper={false}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} de ${total} registros`
            }
          />
        </div>
      </div>
    );
  }

  return (
    <Card bodyStyle={{ padding: '10px 0px' }}>
      <Table
        dataReceived={data}
        tableColumns={columns}
        scroll={
          isMobile
            ? false
            : {
                y: `calc(100vh - ${
                  headerHeight + 46 + 50 + paginationHeight + 80 + 47
                }px)`,
              }
        }
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
      />
      <div id="pagination" style={{ padding: 10 }}>
        <Pagination
          current={pageParameters.currentPage}
          pageSize={pageParameters.limit}
          onChange={(currentPage, limit) =>
            GetDataAgain({ currentPage, limit })
          }
          onShowSizeChange={(currentPage, limit) =>
            GetDataAgain({ currentPage, limit })
          }
          total={pageParameters.totalElements}
          showSizeChanger
          showQuickJumper={false}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} de ${total} registros`
          }
        />
      </div>
    </Card>
  );
}
