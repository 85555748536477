import React from 'react';
import { Dropdown } from 'antd';
import { BsThreeDotsVertical } from 'react-icons/bs';

import { useMessagerManager } from '../../../../contexts/MessagerManager/Provider';
import { useTheme } from '~/contexts/ThemeContext';
import DotsMenu from './components/DotsMenu';
import VIEWS from '../../../../constants/views';
import * as S from './style';

export default function DropdownIcon() {
  const { theme } = useTheme();

  const { view, setView } = useMessagerManager();

  const { white, primary, primaryLight } = theme.color;

  const iconColor = view !== VIEWS.MY_CHATS ? white : primary;
  const iconBgColor = view !== VIEWS.MY_CHATS ? primaryLight : '#F3F3F3';

  return (
    <Dropdown overlay={<DotsMenu setView={setView} />}>
      <S.IconGroup backgroundColor={iconBgColor}>
        <BsThreeDotsVertical size={20} color={iconColor} />
      </S.IconGroup>
    </Dropdown>
  );
}
