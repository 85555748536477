import React from 'react';
// Own Components
import EditButton from './components/EditButton';
import DeleteButton from './components/DeleteButton';
import ViewButton from './components/NewViewButton';
// Components
import Card from '~/components/card';
import Table from '~/components/table';
import { BadgeCell, PrimaryTextCell } from '~/components/table/cells';
import Pagination from '~/components/pagination';
import Tooltip from '~/components/tooltip';
import Space from '~/components/space';

export default function Content({
  data,
  GetDataAgain,
  pageParameters,
  setUpdatePending,
}) {
  const columns = [
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tooltip
          title={status === 'ACTIVE' ? 'Modelo ativo' : 'Modelo inativo'}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BadgeCell status={status === 'ACTIVE' ? 'success' : 'error'} />
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Modelo',
      dataIndex: 'name',
      key: 'name',
      render: (name) => <PrimaryTextCell text={name} />,
    },
    {
      title: 'Visualização',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => (
        <Space>
          <ViewButton model={record} />
        </Space>
      ),
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => (
        <Space>
          <EditButton
            record={record}
            setUpdatePending={setUpdatePending}
            GetDataAgain={GetDataAgain}
          />
          <DeleteButton
            record={record}
            setUpdatePending={setUpdatePending}
            GetDataAgain={GetDataAgain}
          />
        </Space>
      ),
    },
  ];
  return (
    <Card bodyStyle={{ padding: '10px 0px' }}>
      <Table dataReceived={data} tableColumns={columns} />
      <div style={{ padding: 10 }}>
        <Pagination
          current={pageParameters.currentPage}
          pageSize={pageParameters.limit}
          onChange={(currentPage, limit) =>
            GetDataAgain({ currentPage, limit })
          }
          onShowSizeChange={(currentPage, limit) =>
            GetDataAgain({ currentPage, limit })
          }
          total={pageParameters.totalElements}
          showSizeChanger
          showQuickJumper={false}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} de ${total} registros`
          }
        />
      </div>
    </Card>
  );
}
