import React from 'react';
import releasesNotes from './releases';

import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
import Divider from '~/components/divider';


export default function Releases() {
    return (
        <div>
            {releasesNotes.map(item => (
                <div style={{ marginBottom: 50 }}>
                    <Title level={2}>{`Notas de Atualização - ${item.date}`}</Title>
                    {item.features &&
                        <div>
                            <Title level={4}>Novos Recursos</Title>
                            <Divider style={{ margin: '0px 0px 10px' }} />
                            <ul>
                                {item.features.map(feature => (
                                    <li>
                                        <Text strong>{feature.description}</Text>
                                        {feature.details && 
                                            <div>
                                                <ul>
                                                    {feature.details.map(detail => (
                                                        <li style={{ color: '#143c6d' }}>{detail}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        }
                                    </li>
                                ))}

                            </ul>
                        </div>
                    }
                    {item.fix &&
                        <div>
                            <Title level={4}>Correções</Title>
                            <Divider style={{ margin: '0px 0px 10px' }} />
                            <ul>
                                {item.fix.map(fix => (
                                    <li>
                                        <Text strong>{fix.description}</Text>
                                        {fix.details && 
                                            <div>
                                                <ul>
                                                    {fix.details.map(detail => (
                                                        <li style={{ color: '#143c6d' }}>{detail}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        }
                                    </li>
                                ))}

                            </ul>
                        </div>
                    }
                </div>
            ))}
        </div>
    )
}