import React, { memo, useContext } from "react";

import { ResponsiveContext } from "~/contexts/ResponsiveContext";

import Avatar from "~/components/avatar";
import Text from "~/components/typography/text";

import { CapitalizeString } from "~/utils/Transformations";

import { Flex } from "./style";

function ImageWithPrimaryAndSecondaryTextCell({
  src,
  alt,
  shape,
  primaryText,
  secondaryText,
}) {
  const { isMobile } = useContext(ResponsiveContext);

  return (
    <Flex
      direction={isMobile ? "column" : "row"}
      align="center"
      justify={isMobile ? "center" : "flex-start"}
    >
      <Avatar
        style={{
          margin: isMobile ? "10px 0 0 0" : "0 10px 0 0",
        }}
        shape={shape || "squart"}
        src={src}
        alt={alt || "avatar"}
      >
        {primaryText ? primaryText.charAt(0) : ""}
      </Avatar>

      <Flex
        direction="column"
        align={isMobile && "center"}
        justify={isMobile && "center"}
      >
        <Text strong>{CapitalizeString(primaryText)}</Text>
        <Text type="secondary" style={isMobile && { textAlign: "center" }}>
          {CapitalizeString(secondaryText)}
        </Text>
      </Flex>
    </Flex>
  );
}

export default memo(ImageWithPrimaryAndSecondaryTextCell);
