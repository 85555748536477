import React from 'react';
import { Col, Row } from 'antd';

import { useArchivesPageManager } from '../../../../contexts/ArchivesPageManager/Provider';
import ArchiveItem from './components/ArchiveItem';

export default function CardsContainer() {
  const { archives } = useArchivesPageManager();

  return (
    <div>
      <Row>
        {archives.map((item, index) => (
          <Col key={index} xs={24} sm={24} md={12} lg={8} xl={8}>
            <ArchiveItem archive={item} />
          </Col>
        ))}
      </Row>
    </div>
  );
}
