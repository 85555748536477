const Releases = [
    {
        date: '09/06/2021',
        fix: [
            {
                description: 'Geral - Impressão',
                details: [
                    "Página configurada para navegadores diferentes (Testado no Mozilla e no Google Chrome)",
                ]
            },
            {
                description: 'Geral - QR Code',
                details: [
                    "Corrigido a leitura do QR Code",
                ]
            },
            {
                description: 'Geral - Arquivos de fotos',
                details: [
                    "Problema que poderia acontecer caso a url do arquivo tivesse um caractere especial",
                    'Foi feito a troca em todas as páginas e componentes que utilizam imagens'
                ]
            },
            {
                description: 'Admin - Drawer Usuário / Página Usuários',
                details: [
                    "Correção do problema que ocorria quando desvinculava o usuário da última unidade e/ou grupo",
                    'Agora as colunas da tabela de unidades/ grupos atualizam sozinho'
                ]
            },
        ]
    },
    {
        date: '06/06/2021',
        features: [
            {
                description: 'Admin - Drawer do usuário',
                details: [
                    "Agora quando se troca uma Unidade ou Grupo do usuário, as opções serão recarregadas de forma automática",
                    'Agora é realizada uma filtragem na opções de unidades e grupos, mostrando como opções somente as unidades/ grupos que o usuário não está'
                ]
            },
            {
                description: 'Admin - Usuários',
                details: [
                    "Agora quando se troca uma Unidade ou Grupo do usuário, somente as unidades/ grupos daquele usuário serão recarregados de forma automática",
                ]
            },
            {
                description: 'Admin - Modelos de Documentos',
                details: [
                    "Tabela recarrega sozinha quando alterado algum dado",
                ]
            },
            {
                description: 'Admin - Tipos de Processos',
                details: [
                    "Tabela recarrega sozinha quando alterado algum dado",
                ]
            },
        ],
        layout: [],
        fix: [
            {
                description: 'Agente Público - Tramitação do Processo',
                details: [
                    "Agora a busca está sendo realizada da maneira correta",
                ]
            },
            {
                description: 'Agente Público - Editar Documento',
                details: [
                    "Documento em preparação agora edita o corpo",
                ]
            },
        ]
    },
    {
        date: '04/06/2021',
        features: [
            {
                description: 'Agente Público - Visualização do Documento',
                details: [
                    'Agora cada anexo possui uma capa',
                    'Retirada bordas do documento na hora da impressão'
                ]
            },
            {
                description: 'Agente Público - Visualização do Processo',
                details: [
                    'Agora cada anexo possui uma capa',
                ]
            },
        ],
        layout: [],
        fix: [
            {
                description: 'Agente Público - Visualização do Documento',
                details: [
                    "Logo da instituição agora é dinâmica",
                ]
            },
            {
                description: 'Agente Público - Criação de Processo',
                details: [
                    "Quando criava um processo e chegava na etapa de inserir interessados, assim que era inserido algum, a tabela não recarregava de forma dinâmica, agora recarrega",
                ]
            },
        ]
    },
    {
        date: '02/06/2021',
        features: [
            {
                description: 'Geral - Verificação de Documento',
                details: [
                    'Reformulação no processo de verificação de documento',
                ]
            },
            {
                description: 'Geral - Termos',
                details: [
                    'Os termos do usuários foram inseridos na criação do cadastro',
                ]
            },
            {
                description: 'Admin - Drawer do Usuário',
                details: [
                    'Um drawer com informações e operações de acesso rápido foi inserido para o administrador',
                    'Ele estará disponível clicando sobre a foto de um usuário',
                    'Nele será possível gerenciar Unidades Organizacionais e Grupos'
                ]
            },
            {
                description: 'Admin - Botão para tornar administrador',
                details: [
                    'Agora é possível tornar um usuário administrador através de um botão no Drawer do usuário',
                    'Auxílios visuais para saber se o usuário é administrador foram inseridos nas telas de Usuários, Unidades Organizacionais, Grupos e Pesquisa',
                ]
            },
            {
                description: 'Admin - Função Recarregar dados',
                details: [
                    'Quando um dado é alterado, um botão irá surgir acima da tabela, informando que os dados estão desatualizados e precisam ser recarregados',
                    'Isso evitará requisições desnecessárias para a API, além de evitar perde de desempenho, uma vez que as tabelas não serão forçadas a serem recarregadas de forma dinâmica.',
                ]
            },
            {
                description: 'Agente Público - Histórico do Documento',
                details: [
                    'Reformulação na visualização do Histórico do Documento',
                    'Agora os eventos foram agrupados em dias e horários',
                    'Cada tipo de evento possui um ícone e textos próprios'
                ]
            },
            {
                description: 'Agente Público - Visualização do Documento',
                details: [
                    'Reformulação no tamanho das páginas de impressão, assim como a correção no cabeçalho e rodapé de cada página',
                    'Agora é possível visualizar o documento PDF dentro do sistema',
                    'A impressão será feita através de um botão acima de cada documento',
                ]
            },
            {
                description: 'Agente Público - Histórico do Processo',
                details: [
                    'Reformulação na visualização do Histórico do Processo',
                    'Agora os eventos foram agrupados em dias e horários',
                    'Cada tipo de evento possui um ícone e textos próprios'
                ]
            },
            {
                description: 'Agente Público - Visualização do Processo',
                details: [
                    'Reformulação no tamanho das páginas de impressão, assim como a correção no cabeçalho e rodapé de cada página',
                    'Criação da capa do processo',
                ]
            },
        ],
        layout: [],
        fix: [
            {
                description: 'Geral - Alteração do link para troca de senhas',
                details: [
                    "Corrigido o link para onde era redirecionado quando solicitava a troca de senha",
                ]
            },
            {
                description: 'Geral - Botão nos Drawer',
                details: [
                    "Corrigido o problema que fazia alguns botões de drawers desaparecerem",
                ]
            },
            {
                description: 'Agente Público - Criação do Processo',
                details: [
                    "Corrigida o problema que ocorria quando um processo era criado e quando passava algumas etapas a tela ficava branca",
                ]
            },
            {
                description: 'Agente Público - Anexos do Processo',
                details: [
                    "Corrigida a opção de mostrar somente documentos que nunca foram anexados",
                ]
            },
            {
                description: 'Agente Público - Tramitação de Processo',
                details: [
                    "Quando um documento for ser anexo em um processo para que ocorra a tramitação, agora é verificado se tal documento é ativo e válido para a operação",
                ]
            },
        ]
    },
    {
        date: '15/05/2021',
        features: [
            {
                description: 'Geral - Perfil do usuário criado',
                details: [
                    'Agora é possível adicionar uma foto de perfil',
                    'Também é possível selecionar uma nova foto dentre as fotos do usuário',
                    'E alterar suas informações básicas, como nome, email, nascimento e sexo'
                ]
            },
            {
                description: 'Geral - Tela de detalhes do usuário criada',
                details: [
                    "Uma tela de informações da pessoa pode ser acessada diretamente das janelas de Documentos e Processo. Basta clicar sobre o avatar do usuário que a janela irá se abrir.",
                    'Informações como nome, email, nascimento e sexo do usuário estão disponíveis',
                ]
            },
            {
                description: 'Geral - Cookies setados no domínio, isso significa que poderemos usar quaisquer subdomínios .sigam.net.br que os cookies serão compartilhados entre si.',
                details: [
                    "Exemplo: temos hoje o endereço 'app.sigam.net.br' que será usado para acessar o sistema, suponhamos que futuramente criaremos outro subsistema no endereço 'suporte.sigam.net.br', então não precisaremos fazer o login em ambos os sistema, uma vez que acessar um sistema, o outro irá 'recuperar' as informações de quem está logado. Outro exemplo mais real é quando você entra no Gmail e faz o login, e a partir desse momento não precisa mais fazer logins em outros sistemas como YouTube, Google Drive e etc porque eles compartilham seus dados, você já estará logado nesses sistemas.",
                ]
            },
            {
                description: 'Agente Público - Documentos',
                details: [
                    "Agora na aba de Níveis de acesso, tem a opção de colocar o documento como Sigiloso.",
                    "Agora, quando entra na página de edição de um documento, é verificado se o mesmo já se encontra anexado em algum processo, caso se encontre, uma mensagem aparecerá na tela, informando que o documento não poderá ser atualizado",
                    "Foi inserido o componente que apresenta mais informações de Assinantes/ Interessado na tabela"
                ]
            },
            {
                description: 'Agente Público - Processos',
                details: [
                    "Agora na aba de Níveis de acesso, tem a opção de colocar o documento como Sigiloso.",
                    "Foi inserido o componente que apresenta mais informações de Assinantes/ Interessado na tabela"
                ]
            },
        ],
        layout: [],
        fix: [
            {
                description: 'Geral - Logo no Sidebar',
                details: [
                    "A logo do SIGAM na barra de menu lateral sempre irá aparecer agora.",
                ]
            },
            {
                description: 'Admin - Página de Usuários',
                details: [
                    "Campo de busca: Correção no campo de busca da página, agora está filtrando corretamente",
                ]
            },
            {
                description: 'Agente Público - Documentos',
                details: [
                    "Nomenclatura: Trocado a nomenclatura 'Colaboradores' para 'Assinantes'",
                    "Corrigido bug que não estava deixando adicionar assinantes",
                    "Corrigido caixa de seleção que trazia tipos de processos inativos quando iria anexar um documento ao processo para a tramitação."
                ]
            },
            {
                description: 'Agente Público - Processos',
                details: [
                    "Nomenclatura: Trocado a nomenclatura 'Colaboradores' para 'Assinantes'",
                    "Corrigido bug na criação da processo",
                    "Corrigido caixa de seleção que trazia tipos de processos inativos quando iria criar um novo processo."
                ]
            },
        ]
    },
]

export default Releases;
