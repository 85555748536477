import React, { useState, useEffect, useContext } from 'react';
// Libraries
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
// Contexts
import { SIGAMContext } from '~/contexts/SIGAMContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Components
import Card from '~/components/card';
import Text from '~/components/typography/text';
import Title from '~/components/typography/title';
import Loading from '~/components/loading';
// Assets
import IconAdmin from '~/assets/images/icons/icone256-admin.png';
import IconAgent from '~/assets/images/icons/icone256-agente.png';
//import IconChat from '~/assets/images/icons/icone256-chat.png';
//import IconCitizen from '~/assets/images/icons/icone256-cidadao.png';
// import IconCidadao from '~/assets/images/icons/icone256-cidadao.png';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function InstitutionPanelBody({ item }) {
  const { setActiveInstitution } = useContext(SIGAMContext);
  const { isMobile } = useContext(ResponsiveContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminVerification, setAdminVerification] = useState(false);
  const [isPublicAgent, setIsPublicAgent] = useState(false);
  const [publicAgentVerification, setpublicAgentVerification] = useState(false);
  const [loadingContent, setLoadingContent] = useState(true);

  useEffect(() => {
    if (adminVerification && publicAgentVerification) {
      setLoadingContent(false);
    }
  }, [adminVerification, publicAgentVerification]);

  useEffect(() => {
    async function CheckIfItIsAdmin() {
      const params = {
        operation: ['SIGAM', 'GetPermissions'],
        urlParams: {
          institutionId: item.id,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        if (response.body.permission.ADMIN) {
          setIsAdmin(true);
        }
      }
      setAdminVerification(true);
    }
    async function ChecksIfYouArePublicAgent() {
      const params = {
        operation: ['SIGAM', 'OrganizationalUnits', 'Get'],
        urlParams: {
          institutionId: item.id,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        if (response.body.orgUnities.elements.length > 0) {
          setIsPublicAgent(true);
        }
      }
      setpublicAgentVerification(true);
    }
    CheckIfItIsAdmin();
    ChecksIfYouArePublicAgent();
  }, [item]);

  if (loadingContent) {
    return <Loading text="Verificando permissões" height="100px" />;
  }

  /*
  const DisabledRuleCard = ({ icon, title }) => {
    return (
      <Col xs={24} sm={24} md={24} lg={6} xl={6}>
        <Tooltip title="Em breve">
          <div
            style={{
              padding: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'not-allowed',
            }}
          >
            <Card
              style={{ backgroundColor: '#fafafa' }}
              bodyStyle={{ padding: 5 }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 200,
                }}
              >
                <div
                  style={{
                    width: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <img src={icon} style={{ width: 32 }} alt="..." />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    marginLeft: 10,
                    width: '150px',
                  }}
                >
                  <Text
                    kind="secondary"
                    style={{ textAlign: 'center', margin: '0px' }}
                  >
                    Acessar como
                  </Text>
                  <Title level={5} style={{ margin: '0px' }}>
                    {title}
                  </Title>
                </div>
              </div>
            </Card>
          </div>
        </Tooltip>
      </Col>
    );
  };
  */

  const LinkCard = ({ href, icon, title, secondaryText = 'Acessar como' }) => {
    return (
      <Col xs={8} sm={8} md={8} lg={4} xl={4}>
        <div
          style={{
            padding: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <a href={href} target="_blank">
            <Card style={{ cursor: 'pointer' }} bodyStyle={{ padding: 5 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 200,
                }}
              >
                <div
                  style={{
                    width: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <img src={icon} style={{ width: 32 }} alt="..." />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    marginLeft: 10,
                    width: '150px',
                  }}
                >
                  <Text
                    kind="secondary"
                    style={{ textAlign: 'center', margin: '0px' }}
                  >
                    {secondaryText}
                  </Text>

                  <Title level={5} style={{ margin: '0px' }}>
                    {title}
                  </Title>
                </div>
              </div>
            </Card>
          </a>
        </div>
      </Col>
    );
  };

  const RuleCard = ({ to, icon, title, secondaryText = 'Acessar como' }) => {
    return (
      <Col xs={8} sm={8} md={8} lg={4} xl={4}>
        <div
          onClick={() => setActiveInstitution(item)}
          style={{
            padding: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Link to={to}>
            <Card style={{ cursor: 'pointer' }} bodyStyle={{ padding: 5 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 200,
                }}
              >
                <div
                  style={{
                    width: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <img src={icon} style={{ width: 32 }} alt="..." />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    marginLeft: 10,
                    width: '150px',
                  }}
                >
                  <Text
                    kind="secondary"
                    style={{ textAlign: 'center', margin: '0px' }}
                  >
                    {secondaryText}
                  </Text>

                  <Title level={5} style={{ margin: '0px' }}>
                    {title}
                  </Title>
                </div>
              </div>
            </Card>
          </Link>
        </div>
      </Col>
    );
  };

  if (isMobile) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {isAdmin && (
          <RuleCard
            to={`/${item.id}/admin`}
            icon={IconAdmin}
            title="Administrador"
          />
        )}
        {isPublicAgent && (
          <RuleCard
            to={`/${item.id}/agente-publico`}
            icon={IconAgent}
            title="Agente Público"
          />
        )}
        {/* 
        {isPublicAgent && (
          <RuleCard
            to={`/${item.id}/chat`}
            icon={IconChat}
            title="Chat interno"
            secondaryText="Entrar no"
          />
        )}
        */}
        {/* 
        <LinkCard
          href={`https://${item.slug}.sigam.net.br`}
          icon={IconCitizen}
          title="Cidadão"
          secondaryText="Acessar como"
        />
        */}
      </div>
    );
  }

  return (
    <div>
      <Row>
        {isAdmin && (
          <RuleCard
            to={`/${item.id}/admin`}
            icon={IconAdmin}
            title="Administrador"
          />
        )}
        {isPublicAgent && (
          <RuleCard
            to={`/${item.id}/agente-publico`}
            icon={IconAgent}
            title="Agente Público"
          />
        )}
        {/* 
        {isPublicAgent && (
          <RuleCard
            to={`/${item.id}/chat`}
            icon={IconChat}
            title="Chat interno"
            secondaryText="Entrar no"
          />
        )}
        */}
        {/* 
        <LinkCard
          href={`https://${item.slug}.sigam.net.br`}
          icon={IconCitizen}
          title="Cidadão"
          secondaryText="Acessar como"
        />
       
        <DisabledRuleCard
          icon={IconCidadao}
          title="Cidadão"
        />
        */}
      </Row>
    </div>
  );
}
