import React, { useContext, useEffect, useState } from 'react';
// Libraries
import { Dropdown } from 'antd';
import { useRouteMatch, useParams, NavLink } from 'react-router-dom';
import { RetweetOutlined } from '@ant-design/icons';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { SIGAMContext } from '~/contexts/SIGAMContext';
// Components
import Button from '~/components/button';
import Loading from '~/components/loading';
import Divider from '~/components/divider';
// Standalone Components
import { MenuContainer, MenuItem, Container } from './style';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
// Assets
import IconAdmin from '~/assets/images/icons/icone256-admin.png';
import IconAgente from '~/assets/images/icons/icone256-agente.png';
import IconCidadao from '~/assets/images/icons/icone256-cidadao.png';

export default function DashboardSwitchButton({ withoutSidebar }) {
    const { theme } = useContext(ThemeContext);
    const { isMobile } = useContext(ResponsiveContext);
    const { activeInstitution, setActiveInstitution } = useContext(SIGAMContext);
    const { path } = useRouteMatch();
    const { institutionId } = useParams();
    const [loadingContent, setLoadingContent] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isPublicAgent, setIsPublicAgent] = useState(false);
    const [calledAPI, setCalledAPI] = useState(false);

    const menu = (
        <MenuContainer
            borderColor={theme.color.borderColor}
            backgroundColor={theme.color.backgroundSecondaryLight}
            marginTop={isMobile ? '10px' : '0px'}
            fontSize={isMobile ? '12pt' : '10pt'}
        >
            {isAdmin &&
                <MenuItem style={{ marginBottom: 10 }}>
                    <NavLink to={`/${activeInstitution.id}/admin`} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <img src={IconAdmin} style={{ width: 24, marginRight: 10 }} alt="..." />
                        Administrador
                    </NavLink>
                </MenuItem>
            }
            {isPublicAgent &&
                <MenuItem style={{ marginBottom: 10 }}>
                    <NavLink to={`/${activeInstitution.id}/agente-publico`} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <img src={IconAgente} style={{ width: 24, marginRight: 10 }} alt="..." />
                        Agente Público
                    </NavLink>
                </MenuItem>
            }
            {/* 
            <MenuItem style={{ marginBottom: 10 }}>
                <NavLink to={`/${activeInstitution.id}/cidadao`} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <img src={IconCidadao} style={{ width: 24, marginRight: 10 }} alt="..." />
                    Cidadão
                </NavLink>
            </MenuItem>
            */}
            <Divider style={{ margin: '10px 0px' }} />
            <MenuItem style={{ marginBottom: 10 }}>
                <NavLink to="/" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <RetweetOutlined style={{ fontSize: 20, marginRight: 10, marginLeft: 3 }} />
                    Trocar instituição
                </NavLink>
            </MenuItem>
        </MenuContainer >
    );

    function GetDashboardName() {
        if (path.includes('/admin')) {
            return 'Administrador';
        }
        if (path.includes('/agente-publico')) {
            return 'Agente Público';
        }
        if (path.includes('/cidadao')) {
            return 'Cidadão'
        }
    }

    useEffect(() => {
        async function CheckIfItIsAdmin() {
            const params = {
                operation: ['SIGAM', 'GetPermissions'],
                urlParams: {
                    institutionId: activeInstitution.id,
                },
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                if (response.body.permission.ADMIN) {
                    setIsAdmin(true);
                }
            }
        }
        async function ChecksIfYouArePublicAgent() {
            const params = {
                operation: ['SIGAM', 'OrganizationalUnits', 'Get'],
                urlParams: {
                    institutionId: activeInstitution.id,
                },
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                if (response.body.orgUnities.elements.length > 0) {
                    setIsPublicAgent(true);
                }
            }
        }
        async function GetInstitutions() {
            const params = {
                operation: ['SIGAM', 'GetInstitutions'],
                data: {
                    pagination: {
                        limit: 0,
                    },
                },
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                for (let i = 0; i < response.body.institutionList.elements.length; i++) {
                    if (Number(institutionId) === response.body.institutionList.elements[i].id) {
                        setActiveInstitution(response.body.institutionList.elements[i])
                    }
                }
            }
            CheckIfItIsAdmin();
            ChecksIfYouArePublicAgent();
            setLoadingContent(false);
        }
        if(!calledAPI){
            setCalledAPI(true);
            GetInstitutions();
        }
    }, [institutionId, setActiveInstitution, activeInstitution, calledAPI]);


    if (loadingContent) {
        return (
            <Button type="dashed">
                <Loading withoutText height="0px" />
            </Button>
        )
    }

    if (!activeInstitution) {
        return <></>
    }
    return (
        <Container>
            <Dropdown overlay={menu} trigger={['click']}>
                <Button type="dashed" icon={<RetweetOutlined />}>
                    {GetDashboardName()}
                </Button>
            </Dropdown>
        </Container>
    )
}