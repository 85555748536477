import React, { useState, useEffect } from 'react';
// Libraries
import { useParams } from 'react-router-dom';
// Own Components
import DocumentCard from '../../../DocumentCard';
// Components
import Loading from '~/components/loading';
import Select from '~/components/select';
import Button from '~/components/button';
import Text from '~/components/typography/text';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function DocumentsWithoutAttachment({ setSelectedDocument }) {
  const { institutionId, organizationUnitId } = useParams();
  const [documents, setDocuments] = useState();
  const [loadingContent, setLoadingContent] = useState(true);
  const [selectValue, setSelectValue] = useState();
  const [selectedDocumentToView, setSelectedDocumentToView] = useState();

  useEffect(() => {
    if (selectValue && documents) {
      for (let i = 0; i < documents.length; i++) {
        if (documents[i].value === selectValue) {
          return setSelectedDocumentToView(documents[i]);
        }
      }
    }
  }, [selectValue, documents]);

  useEffect(() => {
    async function GetDocuments() {
      const params = {
        operation: ['SIGAM', 'Documents', 'Get'],
        urlParams: {
          institutionId: institutionId,
          organizationUnitId: organizationUnitId,
        },
        data: {
          filter: {
            status: 'ACTIVE',
            attached_to_process: false,
          },
          pagination: {
            limit: 0,
            sort: {
              by: 'name',
              order: 'ASC',
            },
          },
        },
      };
      const response = await SocialMeAPI(params);
      //console.log(response)
      if (response.success) {
        let array = response.body.documents.elements;
        let newArray = [];
        for (let i = 0; i < array.length; i++) {
          let newItem = array[i];
          newItem.label = array[i].name;
          newItem.value = array[i].id;
          newArray.push(newItem);
        }
        setDocuments(newArray);
      }
      setLoadingContent(false);
    }
    GetDocuments();
  }, [institutionId, organizationUnitId]);

  if (loadingContent) {
    return <Loading height="0px" withoutText />;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <div style={{ width: '100%', marginBottom: 10 }}>
        <Text>Selecione o Documento:</Text>
        <Select
          options={documents}
          noDefaultValue
          style={{ width: '100%' }}
          value={selectValue}
          onChange={(value) => setSelectValue(value)}
        />
      </div>
      {selectedDocumentToView && (
        <div>
          <Text>Documento selecionado:</Text>
          <DocumentCard document={selectedDocumentToView} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '10px 0px',
            }}
          >
            <Button
              type="primary"
              onClick={() => setSelectedDocument(selectedDocumentToView)}
            >
              Selecionar Documento
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
