import React, { useContext } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Space, Card, Typography, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import NewFile from './components/NewFile';
import FilterFile from './components/FilterFile';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { useArchiveFilesPageManager } from '../../contexts/ArchiveFilesPageManager/Provider';

const { Title } = Typography;

export default function Header() {
  const { params } = useRouteMatch();
  const { isMobile } = useContext(ResponsiveContext);

  const { archive } = useArchiveFilesPageManager();

  function getArchiveURL() {
    return `/${params.institutionId}/agente-publico/${params.organizationUnitId}/acervos`;
  }

  return (
    <Card bodyStyle={{ padding: 10 }} style={{ marginBottom: '8px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: isMobile ? 'center' : 'space-between',
          alignItems: isMobile ? 'center' : 'start',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <Title level={4} style={{ margin: isMobile ? '0px 0px 10px' : 0 }}>
          {archive.name}
        </Title>
        <Space align="center">
          <Link to={getArchiveURL()}>
            <Button type="dashed" icon={<ArrowLeftOutlined />}>
              Todos os acervos
            </Button>
          </Link>

          <FilterFile />
          <NewFile />
        </Space>
      </div>
    </Card>
  );
}
