import React from 'react';

import * as S from './style';

import Text from '~/components/typography/text';
import Search from '~/components/search';

import SearchInstitution from './components/SearchInstitution'

export default function Header({  setSearch, setTotal}) {
  return (
    <S.Container>
      <Text style={{ fontSize: "16pt"}}>Instituições </Text>
      <S.Flex>
        <Search onSearch={(value) => setSearch(value)} />
        <SearchInstitution setTotal={setTotal} />
      </S.Flex>
    </S.Container>
  );
}
