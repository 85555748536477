import React from 'react';
// Libraries
import { Row, Col } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
// Own Components
import ProceedProcess from '../../../../../../Processes/components/Actions/Proceed';
import Visualize from '../../../../../../Processes/components/Actions/Visualize';
// Components
import Text from '~/components/typography/text';
import Card from '~/components/card';
import Drawer from '~/components/drawer';
import Button from '~/components/button';
// Utils
import { CapitalizeString } from '~/utils/Transformations';

export default function ProcessView({ process, SuccessfullyProcessed, setProcess }) {
    return (
        <div>
            <div style={{ padding: 10, display: 'flex', justifyContent: 'center' }}>
                <Button
                    type="dashed"
                    onClick={() => setProcess()}
                >
                    Selecionar outro processo
                </Button>
            </div>
            <Text>Processo a ser anexado</Text>
            <Card bodyStyle={{ padding: 5 }}>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col span={16} style={{ display: 'flex', alignItems: 'center' }}>
                        <Text>{CapitalizeString(process.name)}</Text>
                    </Col>
                    <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Drawer
                            Trigger={
                                <Button icon={<EyeOutlined />}>
                                    Visualizar
                                </Button>
                            }
                            DrawerContentComponent={Visualize}
                            DrawerContentComponentParams={{ process: [process] }}
                            title={`${process.name}`}
                            width="100%"
                        />
                    </Col>
                </Row>
            </Card>
            <Drawer
                Trigger={
                    <Button style={{ width: '100%', margin: '20px 0px' }} type="primary">Tramitar Processo</Button>
                }
                DrawerContentComponent={ProceedProcess}
                DrawerContentComponentParams={{ process: [process], GetDataAgain: SuccessfullyProcessed }}
                title="Tramitar Processo"
            />
        </div>
    )
}