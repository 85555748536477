import React, { useEffect, useState, useContext } from 'react';
// Libraries
import { Row, Col, Typography, Result } from 'antd';
import { useParams } from 'react-router-dom';
// Own Components
import Header from './components/Header';
import FilesList from './components/FilesList';
import FileRender from './components/FileRender';
// Components
import Card from '~/components/card';
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

const { Text, Title } = Typography;

export default function Visualize({ process, propertys }) {
  const { isMobile } = useContext(ResponsiveContext);
  const { institutionId, organizationUnitId } = useParams();
  const [completeProcessInformation, setCompleteProcessInformation] = useState(
    []
  );
  const [processNotFound, setProcessNotFound] = useState(false);
  const [loadingContent, setLoadingContent] = useState(true);
  const [selectedFile, setSelectedFile] = useState();

  const processInformation = process || propertys.process[0];

  useEffect(() => {
    setProcessNotFound(false);
    async function GetProcess() {
      setLoadingContent(true);
      setCompleteProcessInformation();
      setSelectedFile();
      const params = {
        operation: ['SIGAM', 'Processes', 'Show'],
        urlParams: {
          institutionId,
          organizationUnitId,
          processId: processInformation.id,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setCompleteProcessInformation(response.body.process);
      } else {
        setProcessNotFound(true);
      }
      setLoadingContent(false);
    }
    GetProcess();
  }, [institutionId, organizationUnitId, processInformation]);

  if (loadingContent) {
    return <Loading text="Carregando processo" />;
  }

  if (processNotFound) {
    return (
      <Result
        status="warning"
        title="O processo não foi encontrado"
        subTitle="Não foi possível recuperar as informações do processo, por favor,
        tente novamente mais tarde."
      />
    );
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Title level={3}>Processo não foi encontrado</Title>
        <Text>
          Não foi possível recuperar as informações do processo, por favor,
          tente novamente mais tarde.
        </Text>
      </div>
    );
  }

  return (
    <div>
      <Header process={completeProcessInformation} />
      <Card style={{ marginTop: 10 }}>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={6}
            lg={6}
            xl={6}
            style={!isMobile && { borderRight: '1px solid #bfbfbf' }}
          >
            <FilesList
              process={completeProcessInformation}
              setSelectedFile={setSelectedFile}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            style={!isMobile && { borderLeft: '1px solid #bfbfbf' }}
          >
            <FileRender
              process={completeProcessInformation}
              selectedFile={selectedFile}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
}
