import API from '~/environments/APIs';

export default {
    Frequency: {
        Show: {
            baseURL: API.socialMe,
            url: '/activity/activity-frequency/frequencyId',
            method: 'get',
            timeout: '50000',
        },
    }
};
