import SigamAPI from '~/utils/SigamAPI';

export default async function GetChatParticipants({ chatId, institutionId }) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/chat/${chatId}/participants`,
    method: 'POST',
  });

  return response;
}
