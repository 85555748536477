import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import ArchivesPageManagerContext from './Context';
import { useAuth } from '~/contexts/AuthContext';
import _ReadArchives from '~/services/calls/archive/read';
import _CreateArchive from '~/services/calls/archive/create';
import _UpdateArchive from '~/services/calls/archive/update';
import _DeleteArchive from '~/services/calls/archive/delete';
import _ShowArchive from '~/services/calls/archive/show';
import _GetArchive from '~/services/calls/archive/show';

export default function ArchivesPageManagerProvider({ children }) {
  const { user } = useAuth();

  const initialFilter = useMemo(() => {
    return {
      name: '',
      description: '',
      status: '',
      visibility: '',
    };
  }, []);

  const [loadingArchives, setLoadingArchives] = useState(true);
  const [archives, setArchives] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState(initialFilter);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });

  const { institutionId, organizationUnitId } = useParams();

  async function ReadArchives(filter, pagination) {
    setLoadingArchives(true);

    const filters = {
      filter,
      pagination,
    };

    const response = await _ReadArchives({
      filters,
      institutionId,
      organizationUnitId,
    });

    if (response.success) {
      const { elements, totalElements } = response.body.archives;

      setArchives(elements);
      setTotalItems(totalElements);
    }

    setLoadingArchives(false);
  }

  async function CreateArchive(archive) {
    if (archive.is_from_user) {
      archive.person_id = user.id;
    }

    if (archive.is_from_organizational_unit) {
      archive.org_unit_id = organizationUnitId;
    }

    const response = await _CreateArchive({ archive, institutionId });

    if (response.success) {
      ReadArchives(filter, pagination);
    }

    return response;
  }

  async function UpdateArchive({ archive, archiveId }) {
    const response = await _UpdateArchive({
      archive,
      archiveId,
      institutionId,
    });

    if (response.success) {
      setArchives((currentArchives) => {
        const newArchives = currentArchives.map((currentArchive) => {
          if (currentArchive.id !== archiveId) return currentArchive;

          return response.body.archive;
        });

        return newArchives;
      });
    }

    return response;
  }

  async function DeleteArchive(archiveId) {
    const response = await _DeleteArchive({
      archiveId,
      institutionId,
    });

    if (response.success) {
      ReadArchives(filter, pagination);
    }
  }

  async function ShowArchive(archiveId) {
    const response = await _ShowArchive({
      archiveId,
      institutionId,
    });

    return response;
  }

  async function GetArchive(archiveId) {
    const response = await _GetArchive({
      archiveId,
      institutionId,
    });

    if (response.success) {
    }
  }

  useEffect(() => {
    ReadArchives(filter, pagination);
  }, [filter, pagination]);

  return (
    <ArchivesPageManagerContext.Provider
      value={{
        initialFilter,
        filter,
        setFilter,
        totalItems,
        loadingArchives,
        archives,
        pagination,
        setPagination,
        CreateArchive,
        UpdateArchive,
        ShowArchive,
        DeleteArchive,
        GetArchive,
      }}
    >
      {children}
    </ArchivesPageManagerContext.Provider>
  );
}

export const useArchivesPageManager = () =>
  useContext(ArchivesPageManagerContext);
