import React, { useContext } from 'react';
// Contexts
import { SIGAMContext } from '~/contexts/SIGAMContext';
// Components
import Text from '~/components/typography/text';
import Avatar from '~/components/avatar';
// Standalone Components
import { Container } from './style';
// Utils
import { CapitalizeString,  DecodeURI } from '~/utils/Transformations';

export default function InstitutionName({ withoutSidebar }) {
    const { activeInstitution } = useContext(SIGAMContext);

    if (!activeInstitution) {
        return <></>
    }
    
    return (
        <Container>
            {activeInstitution.avatar && <Avatar src={DecodeURI(activeInstitution.avatar || '')} style={{ marginRight: 10 }} />}
            <Text style={{ textAlign: 'center', color: withoutSidebar ? 'white' : 'auto'}}>{CapitalizeString(activeInstitution.social_name || activeInstitution.name || 'Carregando')}</Text>
        </Container>
    )
}