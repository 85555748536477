import React, { useEffect, useState } from 'react';
// Libraries
import { PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
// Own Components
import AddPerson from './components/AddPerson';
import PeopleList from './components/PeopleList';
// Components
import Title from '~/components/typography/title';
import Drawer from '~/components/drawer';
import Button from '~/components/button';
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Interested({ process, propertys }) {
    const { institutionId, organizationUnitId } = useParams();
    const [people, setPeople] = useState([]);
    const [loadingContent, setLoadingContent] = useState(true);

    let processInformation = process || propertys.process[0];

    async function GetPeopleAgain() {
        if (propertys.GetDataAgain) {
            propertys.GetDataAgain();
        }
        const params = {
            operation: ['SIGAM', 'Processes', 'Interested', 'Get'],
            urlParams: {
                institutionId: institutionId,
                organizationUnitId: organizationUnitId,
                processId: processInformation.id
            }
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            setPeople(response.body.interested.elements);
        }
    }

    useEffect(() => {
        async function GetPeople() {
            const params = {
                operation: ['SIGAM', 'Processes', 'Interested', 'Get'],
                urlParams: {
                    institutionId: institutionId,
                    organizationUnitId: organizationUnitId,
                    processId: processInformation.id
                }
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                setPeople(response.body.interested.elements);
            }
            setLoadingContent(false);
        }
        GetPeople();
    }, [institutionId, organizationUnitId, processInformation])

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                <Title level={5}>Interessados</Title>
                <Drawer
                    Trigger={
                        <Button icon={<PlusOutlined />} type="dashed">Novo Interessado</Button>
                    }
                    title="Novo Interessado"
                    DrawerContentComponent={AddPerson}
                    DrawerContentComponentParams={{ process: processInformation, people, GetPeopleAgain }}
                />
            </div>
            {loadingContent ?
                <Loading />
                :
                <PeopleList people={people} process={processInformation} GetPeopleAgain={GetPeopleAgain} />
            }
        </div>
    )
}