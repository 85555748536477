import React, { useEffect, useState } from 'react';
// Libraries
import { List } from 'antd';
import Animate, { FadeIn } from 'animate-css-styled-components';
import { CheckOutlined } from '@ant-design/icons';
// Components
import Button from '~/components/button';
import FileRender from './components/FileRender'

export default function CustomList({ process, treeData }) {
    const [printedIndex, setPrintedIndex] = useState([])

    const ListNode = ({ level, data }) => {
        return (
            <List
                itemLayout="horizontal"
                dataSource={data}
                locale={{ emptyText: '' }}
                renderItem={(item, index) => (
                    <Animate
                        Animation={[FadeIn]}
                        duration={['0.5s']}
                        delay={['0.2s']}
                    >
                        <List.Item actions={[
                            <div>
                                {printedIndex.includes(`${level}-${index}`) ?
                                    <Button icon={<CheckOutlined />} type="primary" disabled>
                                        Clicado
                                    </Button>
                                    :
                                    <></>}
                            </div>,
                            <div>
                                <div onClick={() => setPrintedIndex(printedIndex => [...printedIndex, `${level}-${index}`])}>
                                    <FileRender document={item} fatherProcess={process} />
                                </div>
                            </div>
                        ]}
                        >
                            <List.Item.Meta
                                title={item.title}
                                description={
                                    `Quantidade de anexos: ${item?.children?.length || '0'}`
                                }
                            />
                        </List.Item>
                        {item?.children?.length > 0 && <div style={{ marginLeft: '30px' }}>
                            <ListNode level={level + 1} data={item.children} />
                        </div>}

                    </Animate>
                )}
            />
        )
    }

    return (
        <div style={{ marginTop: 10 }}>
            <ListNode level={0} data={treeData} />
        </div>
    )
}