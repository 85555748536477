import React from 'react';
import { message, Popconfirm, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { usePageManager } from '../../../../../../contexts/PageManager/Provider';

export default function DeleteButton({ record }) {
  const { deleteTemplate } = usePageManager();

  const confirm = async (e) => {
    const response = await deleteTemplate(record.id);
    if (response.success) {
      message.success('Modelo deletado com sucesso!');
    } else {
      message.error('Alguma coisa aconteceu...');
    }
  };

  return (
    <Popconfirm
      title="Você tem certeza que deseja deletar o modelo?"
      onConfirm={confirm}
      okText="Sim, tenho certeza"
      cancelText="Não, quero cancelar"
    >
      <Button type="dashed" danger icon={<DeleteOutlined />}>
        Apagar
      </Button>
    </Popconfirm>
  );
}
