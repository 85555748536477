export default {
  bag: 'merchant',
  gatewayIntention: 'tiririca',
  returnURL: 'boomerang',
  gatewayPiece1: 'power',
  gatewayPiece2: 'wisdom',
  gatewayPiece3: 'courage',
  recoveryPasswordToken: 'tkn',
  personId: 'pd'
};
