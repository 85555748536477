export default {
  SM04C8874: {
    message: 'User account successfully listed',
  },
  SMF5177B8: {
    message: 'The account balance was successfully Read',
  },
  SMD275823: {
    message: 'The digital wallet account was successfully Read',
  },
  SM7533DBE: {
    message: 'The qr-code was successfully Listed',
  },
  SMB2D88E7: {
    message: "The 'detailed account balance' was successfully Read",
  },
  SME9664D8: {
    message: 'The qr-code was successfully Read',
  },
  SMC7BCEEA: {
    message: 'The product/service model was successfully Listed',
  },
  SM8117F92: {
    message: 'The measurement unity was successfully Listed',
  },
  SMFE8020E: {
    message: 'The people was successfully Listed',
    //Manage.Permission.SearchPerson
  },
  SM69F70D3: {
    message: 'The permission was successfully Listed',
    //Manage.Permission.Get
  },
  SMC37772B: {
    message: 'The person profile was successfully Read',
    //Profile.Get
  },
  SMF33830B: {
    message: 'The country, state, city or community was successfully Listed',
    //Location.Get
  },
  SM9F3778A: {
    message: 'The person percentage was successfully Read',
    //Profile.GetProfilePercentage
  },
  SME3363CC: {
    message: 'The file was successfully Listed',
    //Profile.Avatar.GetAll
  },
  SM4CDB57A: {
    message: 'The address was successfully Listed',
    //Profile.Address.Get
  },
  SM1E330C2: {
    message: 'The contact was successfully Listed',
    //Profile.Contact.Get
  },
  SM4F7704A: {
    message: 'The disability was successfully Listed',
    //System.Disabilities.GetAll
  },
  SM12ED2CA: {
    message: 'The kinship was successfully Listed',
    //System.Relantionship.Kinships
  },
  SM646F684: {
    message: 'The relationship was successfully Listed',
    //Profile.Relantionship.GetAll
  },
  SM0A94EF9: {
    message: 'The place was successfully Listed',
  },
  SM691A7F9: {
    message: "The 'timeline' was successfully Read",
  },
  SM70F4174: {
    message: "The 'devices' was successfully Listed",
  },
  SMA0F532D: {
    message: "The 'activity' was successfully Listed",
  },
  SMAF702EE: {
    message: "The 'activity type' was successfully Listed",
  },
  SM4396809: {
    message: "The 'activity_frequency' was successfully Listed",
  },
  SM275A9DC: {
    message: "The 'activity' was successfully Read",
  },
  SMA9DAFD2: {
    message: "The 'Custom questions' was successfully Listed",
  },
  SM4597479: {
    message: "The 'activity_frequency' was successfully Read",
  },
  SMF3F4CF9: {
    message: "The 'product/service offering' was successfully Listed",
  },
  SMD46D8B6: {
    message: "The 'operation intent' was successfully Read",
  },
  SM71C5F4B: {
    message: "The 'operation intent contract' was successfully Read",
  },
  SMC27A776: {
    message: "The 'operation intent contract' was successfully Read",
  },
  SMCA8EB56: {
    message: "The 'Sigam institution' was successfully Listed",
  },
  SMAD4087E: {
    message: "The 'Sigam Permission' was successfully Listed",
  },
  SM40E27A8: {
    message: "The 'Sigam ORG UNITY' was successfully Listed",
  },
  SM1022983: {
    message: "The 'Sigam public agent' was successfully Listed",
  },
  SM2849FAA: {
    message: "The 'document model' was successfully Listed",
  },
  SMC2B16D3: {
    message: "The 'Sigam group' was successfully Listed",
  },
  SM3760529: {
    message: "The 'Sigam grouped person' was successfully Listed",
  },
  SMFA1179D: {
    message: "The 'Sigam permission' was successfully Listed",
  },
  SM84736CC: {
    message: "The 'document model' was successfully Read"
  },
  SM3AD16B1: {
    message: "The 'Sigam document model' was successfully Listed"
  },
  SM953AE3B: {
    message: "The 'institution' was successfully Read"
  },
  SMF0A06ED: {
    message: "The 'assignment' was successfully Listed"
  },
  SM6591AE6: {
    message: "The 'attachment' was successfully Listed"
  },
  SM6F1FA85: {
    message: "The 'document' was successfully Read"
  },
  SM2AED5F9: {
    message: "The 'process' was successfully Listed"
  },
  SM0A8257A: {
    message: "The 'process kind' was successfully Listed"
  },
  SM317E9E0: {
    message: "The 'process' was successfully Read"
  },
  SMA5B590C: {
    message: "The 'timeline' was successfully Read"
  }
};
