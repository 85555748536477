import React from 'react';
import { Spin, Empty } from 'antd';
import { useArchiveFilesPageManager } from '../../contexts/ArchiveFilesPageManager/Provider';
import TableContainer from './components/TableContainer';
import Pagination from './components/Pagination';

export default function Content() {
  const { loadingFiles, files } = useArchiveFilesPageManager();

  if (loadingFiles) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '8px',
          height: '100px',
        }}
      >
        <Spin tip="Carregando arquivos..." />
      </div>
    );
  }

  if (files.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '8px',
          height: '100px',
        }}
      >
        <Empty />
      </div>
    );
  }

  return (
    <>
      <TableContainer />
      <Pagination />
    </>
  );
}
