import React from 'react';
import { Space, Card } from 'antd';
import ProcessCard from './components/ProcessCard';
import Flowchart from './components/Flowchart';

export default function Content() {
  return (
    <Card>
      <Space
        direction="vertical"
        size="middle"
        style={{
          display: 'flex',
        }}
      >
        <ProcessCard />
        <Flowchart />
      </Space>
    </Card>
  );
}
