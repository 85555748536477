import { Input, Typography } from 'antd';
import React from 'react';

import { useTheme } from '~/contexts/ThemeContext';
import DropdownIcon from '../../components/DropdownIcon';
import useContacts from '../../hooks/useContacts';
import Contacts from './components/Contacts';
import * as S from './style';
import useGetHeightInPixelsToApplyScroll from '../../hooks/useGetHeightInPixelsToApplyScroll';

const { Text } = Typography;
const { Search } = Input;

export default function ListContacts() {
  const { ref, height } = useGetHeightInPixelsToApplyScroll();

  const { contacts, setSearch, loadingContacts } = useContacts();

  const { theme } = useTheme();

  const textStyle = {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: theme.color.white,
  };

  return (
    <S.Container>
      <div ref={ref}>
        <S.Header style={{ backgroundColor: theme.color.primary }}>
          <Text style={textStyle}>Adicionar contato</Text>

          <DropdownIcon />
        </S.Header>

        <S.SearchBox>
          <Search
            size="default"
            placeholder="Pesquisar contatos"
            allowClear
            onSearch={setSearch}
          />
        </S.SearchBox>
      </div>

      <S.ContactsWrapper height={height}>
        <Contacts contacts={contacts} loadingContacts={loadingContacts} />
      </S.ContactsWrapper>
    </S.Container>
  );
}
