import React, { useContext } from 'react';
// Libraries
import { Link } from 'react-router-dom';
import Animate, { FadeIn } from 'animate-css-styled-components';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Components
import Text from '~/components/typography/text';
import Tooltip from '~/components/tooltip';

export default function NewSidebar({
  title,
  url,
  index,
  activeIndex,
  collapsed,
  icon
}) {
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  const renderIconOrImage = () => {
    let Icon = icon;
    if (icon) {
      return (
        <Tooltip title={collapsed ? title : ''} placement="right">
          <Icon
            style={{
              color:
                index === activeIndex
                  ? theme.color.primary
                  : theme.color.textOnPrimaryBackground,
              marginRight: 10,
            }}
          />
        </Tooltip>
      );
    }
    return (
      <img
        style={{ width: 15, height: 15, marginRight: 10 }}
        src={
          'https://socialme-assets.s3.us-east-1.amazonaws.com/images/circle.svg'
        }
        alt="..."
      />
    );
  };

  return title === 'First' || title === 'Last' ? (
    <>
      <div
        style={{
          backgroundColor:
            index === activeIndex ? theme.color.primary : theme.color.secondary,
          borderLeft: `5px solid ${theme.color.primary}`,
        }}
      >
        <div
          style={
            isMobile
              ? {
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                backgroundColor:
                  index === activeIndex ? 'red' : theme.color.primary,
              }
              : {
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                backgroundColor:
                  index === activeIndex
                    ? theme.color.secondary
                    : theme.color.primary,
                borderBottomLeftRadius: index === activeIndex ? 25 : 0,
                borderTopLeftRadius: index === activeIndex ? 25 : 0,
                borderBottomRightRadius: index === activeIndex - 1 ? 25 : 0,
                borderTopRightRadius: index === activeIndex + 1 ? 25 : 0,
              }
          }
        />
      </div>
    </>
  ) : (
      <Link to={url}>
        <div
          style={
            isMobile
              ? {
                backgroundColor:
                  index === activeIndex
                    ? theme.color.primary
                    : theme.color.secondary,
                borderLeft: `5px solid ${theme.color.primary}`,
                borderRight: `5px solid ${theme.color.primary}`,
              }
              : {
                backgroundColor:
                  index === activeIndex
                    ? theme.color.primary
                    : theme.color.secondary,
                borderLeft: `5px solid ${theme.color.primary}`,
              }
          }
        >
          <div
            style={
              isMobile
                ? {
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px',
                  backgroundColor:
                    index === activeIndex
                      ? theme.color.secondary
                      : theme.color.primary,
                  borderRadius: index === activeIndex ? 25 : 0,
                }
                : {
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px',
                  backgroundColor:
                    index === activeIndex
                      ? theme.color.secondary
                      : theme.color.primary,
                  borderBottomLeftRadius: index === activeIndex ? 25 : 0,
                  borderTopLeftRadius: index === activeIndex ? 25 : 0,
                  borderBottomRightRadius: index === activeIndex - 1 ? 25 : 0,
                  borderTopRightRadius: index === activeIndex + 1 ? 25 : 0,
                }
            }
          > <Animate Animation={[FadeIn]} duration={['0.8s']} delay={['0.3s']}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {renderIconOrImage()}
                <Text
                  style={{
                    textAlign: 'left',
                    fontSize: collapsed ? '0pt' : '10pt'
                  }}
                  color={
                    index === activeIndex
                      ? theme.color.primary
                      : theme.color.secondary
                  }
                >
                  {title}
                </Text>
              </div>
            </Animate>
          </div>
        </div>
      </Link>
    );
}
