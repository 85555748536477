import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
`;

export const Content = styled.div`
  width: ${(props) => props.width};
  padding: 20px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const DrawerFooterContainer = styled.div`
  text-align: right;
`;

export const TriggerContainer = styled.div``;
