import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
`;

export const TextContainer = styled.div`
  margin-left: 5px;
`;

export const IAgreeText = styled.span`
  font-size: 9pt;
`;

export const TermsText = styled.span`
  font-size: 9pt;
  margin-left: 5px;
  font-weight: bold;
  color: ${(props) => props.color || '#000000'};
`;
