import React, { useState } from 'react';
// Libraries
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
// Standalone Components
// Loading
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { Container, TextContainer } from './style';


export default function UploadFile({ setFile, setFileLink }) {
  const { institutionId, organizationUnitId } = useParams();
  const [loading, setLoading] = useState(false);

  const beforeUpload = (file) => {
    const isPDF = file.type === "application/pdf";
    if (!isPDF) {
      message.error('Você só pode enviar arquivos no formato PDF');
    }
    const size = file.size < 10485760
    if(!size){
      message.error('O arquivo precisa ter no máximo 10 MB');
    }
    if(!isPDF || !size){
      return false
    }
    return true;
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      // setLoading(true);
    }
  };

  const UploadButton = () => {
    return (
      <Container>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <TextContainer>Anexar arquivo</TextContainer>
      </Container>
    );
  };


  async function UploadAvatar(file) {
    setLoading(true);
    const data = new FormData();
    data.append('file', file);
    const params = {
      operation: ['SIGAM', 'Documents', 'UploadFile'],
      urlParams: {
        institutionId,
        organizationUnitId,
      },
      data,
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      // console.log('file ', file)
      // console.log('link ', response.body.file)
      setFile(file);
      setFileLink(response.body.file);
      return
    } 
      if (response.message === "The file extension is not allowed in this context") {
        message.error('Formato inválido');
      } else {
        message.error('Alguma coisa aconteceu...');
      }
    
    return setLoading(false);
  }

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      showUploadList={false}
      action={UploadAvatar}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {loading ?
        <Loading withoutText height="0px" />
        :
        <UploadButton />
      }
    </Upload>
  );
}
