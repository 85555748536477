import React, { useContext, useState, useEffect } from 'react';
// Libraries
import { TeamOutlined } from '@ant-design/icons';
import { Form } from 'antd';
// Contexts
import { SIGAMContext } from '~/contexts/SIGAMContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Own Components
import UsersContainer from './components/UsersContainer';
// Components
import Drawer from '~/components/drawer';
import Button from '~/components/button';
import Loading from '~/components/loading';
// Utils
import { CapitalizeString } from '~/utils/Transformations';
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function UsersButton({ setUpdatePending, record }) {
  const { activeInstitution } = useContext(SIGAMContext);
  const { isMobile } = useContext(ResponsiveContext);
  const [form] = Form.useForm();
  const [users, setUsers] = useState();
  const [loadingContent, setLoadingContent] = useState(true);

  useEffect(() => {
    async function GetUsers() {
      const params = {
        operation: ['SIGAM', 'OrganizationalUnits', 'Users', 'Get'],
        urlParams: {
          institutionId: activeInstitution.id,
          organizationUnitId: record.id,
        },
        data: {
          filter: {
            status: 'ACTIVE',
          },
          pagination: {
            sort: {
              by: 'id',
              order: 'asc',
            },
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setUsers(response.body.publicAgents.elements);
        setLoadingContent(false);
      }
    }
    GetUsers();
  }, [record, activeInstitution]);

  const DrawerBody = ({ CloseDrawer }) => {
    if (loadingContent) {
      return <Loading text="Buscando usuários" height="0px" />;
    }
    return <UsersContainer CloseDrawerFather={CloseDrawer} form={form} record={record} users={users} setUpdatePending={setUpdatePending} />;
  };

  return (
    <Drawer
      Trigger={
        <Button icon={<TeamOutlined />} type="dashed">
          Usuários
        </Button>
      }
      title={`Usuários de ${CapitalizeString(record.name)}`}
      DrawerContentComponent={DrawerBody}
      width={isMobile ? '100%' :'50%'}
    />
  );
}
