import React, { useContext } from 'react';
//Libraries
import { Popconfirm, List, message } from 'antd';
import { PoweroffOutlined } from '@ant-design/icons';
// Contexts
import { SIGAMContext } from '~/contexts/SIGAMContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Own Components
import Header from './components/Header';
//Components
import Avatar from '~/components/avatar';
import Button from '~/components/button';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { CapitalizeString, DecodeURI } from '~/utils/Transformations';
import { cpfMask, cnpjMask } from '~/utils/Masks';

export default function UsersContainer({
  record,
  users,
  setUpdatePending,
  CloseDrawerFather,
}) {
  const { activeInstitution } = useContext(SIGAMContext);
  const { isMobile } = useContext(ResponsiveContext);

  async function RemoveUser(person) {
    const params = {
      operation: ['SIGAM', 'OrganizationalUnits', 'Users', 'Delete'],
      urlParams: {
        institutionId: activeInstitution.id,
        organizationUnitId: record.id,
      },
      data: {
        person: person.person_id,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      message.success('Usuário removido com sucesso!');
      CloseDrawerFather();
      setUpdatePending(true);
    } else {
      message.error('Alguma coisa aconteceu...')
    }
  }

  function cancel(e) {
    //console.log(e);
  }

  return (
    <div>
      <Header
        CloseDrawerFather={CloseDrawerFather}
        record={record}
        users={users}
        setUpdatePending={setUpdatePending}
      />
      <List
        grid={{ gutter: 18, column: 1 }}
        itemLayout="horizontal"
        dataSource={users}
        locale={{ emptyText: 'Sem usuários na unidade' }}
        renderItem={(item) => (
          <List.Item
            style={{ marginBottom: isMobile ? 20 : 10 }}
            actions={[
              <div key="pop" style={{ marginBottom: 10 }}>
                <Popconfirm
                  title={`Você tem certeza que deseja desligar ${CapitalizeString(
                    item.person_social_name || item.person_name
                  )} da unidade?`}
                  onConfirm={() => RemoveUser(item)}
                  onCancel={cancel}
                  okText="Sim, tenho certeza"
                  cancelText="Não"
                >
                  <Button icon={<PoweroffOutlined />} danger>
                    Desligar usuário da unidade
                </Button>
                </Popconfirm>
              </div>
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar src={DecodeURI(item.person_avatar)} />}
              title={CapitalizeString(item.person_social_name || item.person_name)}
              description={
                item.person_cpf_number
                  ? cpfMask(item.person_cpf_number)
                  : cnpjMask(item.person_cnpj_number)
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
}
