import React from 'react';
import { Spin, Empty } from 'antd';
import { usePageManager } from '../../contexts/PageManager/Provider';
import TableContainer from './components/TableContainer';
import Pagination from './components/Pagination';

export default function Content() {
  const { loadingMessages, messages } = usePageManager();

  if (loadingMessages) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '8px',
          height: '100px',
        }}
      >
        <Spin />
      </div>
    );
  }

  if (messages.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '8px',
          height: '100px',
        }}
      >
        <Empty />
      </div>
    );
  }

  return (
    <>
      <TableContainer />
      <Pagination />
    </>
  );
}
