import React, { createContext, useContext, useEffect, useState } from 'react';
// Libraries
import styled from 'styled-components';
import { Spin } from 'antd';
import {
  //  WalletOutlined,
  LoadingOutlined,
  GiftOutlined,
  HeartOutlined,
  UserOutlined,
  //BankOutlined,
} from '@ant-design/icons';
// Contexts
import { AuthContext } from './AuthContext';
import { ThemeContext } from './ThemeContext';
import { LanguageContext } from '~/contexts/LanguageContext';
// Components
import Button from '~/components/button';
import Card from '~/components/card';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { GetModuleNameByHostname } from '~/utils/Hosts';
// Environments
import System from '~/environments/System';
// Terms
import BeneficiaryTerms from '~/environments/Terms/beneficiaryTerms';
import DonorTerms from '~/environments/Terms/donorTerms';
import PartnerTerms from '~/environments/Terms/partnerTerms';
import ManagerTerms from '~/environments/Terms/managerTerms';

const Container = styled.div`
  background-color: ${(style) => style.backgroundColor || '#fff'};
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${(style) => style.primary || '#fff'};
`;

export const ContractsContext = createContext();

let hostname = GetModuleNameByHostname();

const ContractsContextProvider = ({ children }) => {
  const { user, GetUserAgain } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const { translate } = useContext(LanguageContext);

  const [loadInterface, setLoadInterface] = useState(true);
  const [permission, setPermission] = useState(false);

  const menuItemsPF = [
    {
      icon: <UserOutlined />,
      text: translate.brands.accountManagement.text,
      url: `https://${System.hostname.accountManagement}`,
    },
    {
      icon: <GiftOutlined />,
      text: translate.brands.beneficiary.text,
      url: `https://${System.hostname.beneficiary}`,
    },
    {
      icon: <HeartOutlined />,
      text: translate.brands.donor.text,
      url: `https://${System.hostname.donor}`,
    },
    /*
    {
      icon: <WalletOutlined />,
      text: translate.brands.digitalWallet.text,
      url: `https://${System.hostname.digitalWallet}`,
    },
    */
  ];

  useEffect(() => {
    if (user) {
      if (hostname === 'beneficiary') {
        if (user.role) {
          if (user.role.BENEFICIARY) {
            setPermission(true);
          }
          return setLoadInterface(true);
        }
      }
      if (hostname === 'donor') {
        if (user.role) {
          if (user.role.GIVER) {
            setPermission(true);
          }
          return setLoadInterface(true);
        }
      }
      if (hostname === 'partner') {
        if (user.role) {
          if (user.role.PARTNER) {
            setPermission(true);
          }
          return setLoadInterface(true);
        }
      }
      if (hostname === 'manager') {
        if (user.role) {
          if (user.role.MANAGER) {
            setPermission(true);
          }
          return setLoadInterface(true);
        }
      }
      setPermission(true);
      setLoadInterface(true);
    }
    setPermission(true);
    setLoadInterface(true);
  }, [user])

  async function AddRole(operationName) {
    const params = {
      operation: ['Profile', 'Roles', 'Add', operationName],
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      GetUserAgain();
    }
  }

  const BeneficiaryContract = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 30 }}>
        <Card style={{ width: '80%' }}>
          <BeneficiaryTerms />
          <Button type="primary" size="large" onClick={() => AddRole('Beneficiary')}>Li e concordo</Button>
        </Card>
      </div>
    )
  }
  const DonorContract = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 30 }}>
        <Card style={{ width: '80%' }}>
          <DonorTerms />
          <Button type="primary" size="large" onClick={() => AddRole('Giver')}>Li e concordo</Button>
        </Card>
      </div>
    )
  }
  const PartnerContract = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 30 }}>
        <Card style={{ width: '80%' }}>
          <PartnerTerms />
          <Button type="primary" size="large" onClick={() => AddRole('Partner')}>Li e concordo</Button>
        </Card>
      </div>
    )
  }
  const ManagerContract = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 30 }}>
        <Card style={{ width: '80%' }}>
          <ManagerTerms />
          <Button type="primary" size="large" onClick={() => AddRole('Manager')}>Li e concordo</Button>
        </Card>
      </div>
    )
  }

  const PJOnly = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 30 }}>
        <Card style={{ width: '80%' }}>
          <p>Página destinada somente a pessoas jurídicas. Por favor selecione um novo caminho para seguir:</p>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column' }}>
            {menuItemsPF.map((item) => (
              <a key={item.url} href={item.url}>
                <Button type="link" icon={item.icon}>
                  {item.text}
                </Button>
              </a>
            ))}
          </div>
        </Card>
      </div>
    )
  }

  const Contract = () => {
    if (hostname === 'beneficiary') {
      return <BeneficiaryContract />
    }
    if (hostname === 'donor') {
      return <DonorContract />
    }
    if (hostname === 'partner') {
      if (user && user.kind === 'PJ') {
        return <PartnerContract />
      }
      return <PJOnly />
    }
    if (hostname === 'manager') {
      if (user && user.kind === 'PJ') {
        return <ManagerContract />
      }
      return <PJOnly />
    }
    return <div />
  }



  return (
    <ContractsContext.Provider value={{}}>
      {!loadInterface ?
        <Container
          style={{
            backgroundColor:
              (theme && theme.color && theme.color.backgroundSecondary) ||
              '#fff',
            color: (theme && theme.color && theme.color.primary) || '#fff',
          }}
        >
          <Spin
            style={{
              color: (theme && theme.color && theme.color.primary) || '#fff',
            }}
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 24,
                  color:
                    (theme && theme.color && theme.color.primary) || '#fff',
                }}
                spin
              />
            }
            tip={`Verificando contratos...`}
          />
        </Container>
        :
        permission ?
          children
          :
          <Contract />
      }
    </ContractsContext.Provider>
  );
};

export default ContractsContextProvider;
