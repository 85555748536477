import React, { useContext, memo } from 'react';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Standalone Components
import { MainTooltip } from './style';

function AntTooltip({ 
  placement,
  title,
  children,
  align,
  arrowPointAtCenter,
  autoAdjustOverflow,
  color,
  defaultVisible,
  destroyTooltipOnHide,
  getPopupContainer,
  mouseEnterDelay,
  mouseLeaveDelay,
  overlayClassName,
  overlayStyle,
  trigger,
  visible,
  onVisibleChange,
  onClick
}) {
  const { theme } = useContext(ThemeContext);
  return (
    <MainTooltip
      placement={placement || 'top'}
      title={title}
      align={align}
      arrowPointAtCenter={arrowPointAtCenter}
      autoAdjustOverflow={autoAdjustOverflow}
      color={color}
      defaultVisible={defaultVisible}
      destroyTooltipOnHide={destroyTooltipOnHide}
      getPopupContainer={getPopupContainer}
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
      overlayClassName={overlayClassName}
      overlayStyle={ overlayStyle || {
        backgroundColor: theme.color.tooltipBackground,
      }}
      trigger={trigger}
      //visible={visible}
      onVisibleChange={onVisibleChange}
      onClick={onClick}
    >
      {children}
    </MainTooltip>
  );
}

export default memo(AntTooltip);
