import React, { useState, useContext, useEffect } from 'react';
//Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Checkbox from '~/components/checkbox';
import Modal from '~/components/modal';
// Standalone Components
import { Container, TextContainer, IAgreeText, TermsText } from './style';

import TermsOfUse from './TermsOfUse';

export default function GeneralTerms({ form, formValues, setFormValues }) {
  const { translate } = useContext(LanguageContext);
  const { theme } = useContext(ThemeContext);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checked) {
      form.setFieldsValue({
        agreement_terms: true,
      });
      if (!formValues.agreement_terms)
        setFormValues({ ...formValues, agreement_terms: true });
      return;
    }
    form.setFieldsValue({
      agreement_terms: false,
    });
    if (formValues.agreement_terms)
      setFormValues({ ...formValues, agreement_terms: false });
    return;
  }, [checked, form, formValues, setFormValues]);


  const TermsModal = (
    <Modal
      Trigger={
        <Container>
          <Checkbox checked={checked} />
          <TextContainer>
            <IAgreeText>
              {translate.authentication.readAndAgreeWith.text}
            </IAgreeText>
            <TermsText color={theme.color.primary}>
              {translate.authentication.generalTerms.text}
            </TermsText>
          </TextContainer>
        </Container>
      }
      title={translate.authentication.generalTerms.text.toUpperCase()}
      InheritedContent={
        TermsOfUse
      }
      cancelText={translate.authentication.iDoNotAgreeWithTheTerms.text}
      onCancel={() => setChecked(false)}
      okText={translate.authentication.iAgreeWithTheTerms.text}
      onOk={() => setChecked(true)}
      width={'80vw'}
      style={{ top: 10 }}
    />
  );

  return TermsModal;
}
