import React from 'react';
// Own Components
import EditButton from './components/EditButton';
// Components
import Card from '~/components/card';
import Table from '~/components/table';
import { PrimaryTextCell, BadgeCell } from '~/components/table/cells';
import Pagination from '~/components/pagination';
// Utils
import { CapitalizeString } from '~/utils/Transformations';

export default function Content({
  data,
  GetDataAgain,
  pageParameters,
  setUpdatePending
}) {
  const columns = [
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <BadgeCell status={status === 'ACTIVE' ? 'success' : 'error'} text={''} />,
    },
    {
      title: 'Tipo de processo',
      dataIndex: 'name',
      key: 'name',
      render: (name) => (
        <PrimaryTextCell
          text={CapitalizeString(name)}
        />
      ),
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => (
        <div>
          <EditButton setUpdatePending={setUpdatePending} record={record} GetDataAgain={GetDataAgain} />
        </div>
      ),
    },
  ];
  return (
    <Card bodyStyle={{ padding: '10px' }}>
      <Table dataReceived={data} tableColumns={columns} />
      <div style={{ padding: 10 }}>
        <Pagination
          current={pageParameters.currentPage}
          pageSize={pageParameters.limit}
          onChange={(currentPage, limit) =>
            GetDataAgain({ currentPage, limit })
          }
          onShowSizeChange={(currentPage, limit) =>
            GetDataAgain({ currentPage, limit })
          }
          total={pageParameters.totalElements}
          showSizeChanger
          showQuickJumper={false}
          showTotal={(total) => `Total ${total} itens`}
        />
      </div>
    </Card>
  );
}
