import React from 'react';
// Libraries
import { Button, Typography, Row, Col } from 'antd';
// Standalone Components
import { ImageCookies, Link, LinkText } from './style';
// Environments
import System from '~/environments/System';
// Assets
import CookieImage from '~/assets/images/cookie.svg';

const { Text } = Typography;

export default function CookiesNotification({
  message,
  buttonText,
  policyButtonText,
  onClick,
}) {
  return (
    <Row>
      <Col
        xs={24}
        sm={24}
        md={6}
        lg={6}
        xl={6}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ImageCookies src={CookieImage} alt="..." />
      </Col>
      <Col xs={24} sm={24} md={18} lg={18} xl={18}>
        <Text>{message}</Text>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={24}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Link href={`https://${System.hostname.main}/privacidade`}>
          <Button type="link">
            <LinkText>{policyButtonText}</LinkText>
          </Button>
        </Link>
        <Button type="primary" onClick={onClick} style={{ borderRadius: 8 }}>
          {buttonText}
        </Button>
      </Col>
    </Row>
  );
}
