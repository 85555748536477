import React from 'react';
import { Typography } from 'antd';
import { FolderOpenOutlined } from '@ant-design/icons';

const { Text } = Typography;

export default function ArchiveCard({ archive }) {
  return (
    <div style={{ display: 'flex', gap: '4px' }}>
      <FolderOpenOutlined style={{ fontSize: 16 }} />
      <Text>{archive.name}</Text>
    </div>
  );
}
