import React, { useEffect, useState, useContext } from 'react';

import { message } from 'antd'

import Api from '~/utils/SocialMeAPI';

import Header from './components/Header';
import Content from './components/Content';

import Card from '~/components/card';
import Divider from '~/components/divider';
import Loading from '~/components/loading';

import { ThemeContext } from '~/contexts/ThemeContext';

export default function InstitutionsSigam() {

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageParameters, setPageParameters] = useState({
    currentPage: 1,
    limit: 10,
  });

  const { theme } = useContext(ThemeContext);

  async function getDataAgain() {
    setLoading(true)
    const params = {
      operation: ["SIGAM", "Admin", "Institutions", "Get"],
      data: {

        filter: {
          search_string: search,
          pagination: {
            page: pageParameters.currentPage,
            limit: pageParameters.limit
          }
        }
      }
    }
    const response = await Api(params)
    if (response.success) {
      setTotal(response.body.institutionList.totalElements)
      setData(response.body.institutionList.elements)
    } else {
      message.error("Houve um erro ao lista as instituiçoes")
    }
    setLoading(false)
  }

  useEffect(() => {
    async function GetData() {
      setLoading(true)
      const params = {
        operation: ["SIGAM", "Admin", "Institutions", "Get"],
        data: {

          filter: {
            search_string: search,
            pagination: {
              page: pageParameters.currentPage,
              limit: pageParameters.limit
            }
          }
        }
      }
      const response = await Api(params)
      if (response.success) {
        setTotal(response.body.institutionList.totalElements)
        setData(response.body.institutionList.elements)
      } else {
        message.error("Houve um erro ao lista as instituiçoes")
      }
      setLoading(false)
    }
    GetData();
  }, [search, total, pageParameters]);


  return (
    <Card style={{ padding: 10 }} >
      <Header setSearch={setSearch} setTotal={setTotal} />
      <Divider borderColor={theme.color.white} />
      {loading ? (
        <Loading text="Carregando instituições" />
      ) : (
        <Content
          data={data}
          total={total}
          pageParameters={pageParameters}
          setPageParameters={setPageParameters}
          getDataAgain={getDataAgain}
        />
      )}
    </Card>
  );
}
