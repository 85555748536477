import React, { useState } from 'react';
// Libraries
import { Form, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Animate, { FadeIn } from 'animate-css-styled-components';
import { useParams } from 'react-router-dom';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import Button from '~/components/button';
// Utils
import { cnpjMask, cpfMaskContinuos } from '~/utils/Masks';
import SocialMeAPI from '~/utils/SocialMeAPI';
import _searchInSigam from 'services/calls/person/search/searchInSigam';

export default function Search({ setPeopleList, form, secondStepParameters }) {
  const { institutionId, organizationUnitId } = useParams();
  const [loadingButton, setLoadingButton] = useState(false);

  async function Search(values) {
    setLoadingButton(true);
    const filter = {};
    if (values.main_document) {
      if (values.main_document.length < 14) {
        setLoadingButton(false);
        return message.error('Digite um CPF ou CNPJ válido');
      }
      if (
        values.main_document.length > 14 &&
        values.main_document.length > 18
      ) {
        setLoadingButton(false);
        return message.error('Digite um CPF ou CNPJ válido');
      }
      if (values.main_document.length === 14) {
        filter.cpf_number = values.main_document.replace(/[^\d]+/g, '');
      }
      if (values.main_document.length === 18) {
        filter.cnpj_number = values.main_document.replace(/[^\d]+/g, '');
      }
    }
    if (values.name) {
      filter.name = values.name;
    }
    if (secondStepParameters.searchInSigam) {
      const response = await _searchInSigam({
        institutionId,
        data: {
          filter,
          pagination: {
            limit: 0,
            sort: {
              by: 'name',
              order: 'ASC',
            },
          },
        },
      });
      if (response.success) {
        return setPeopleList(response.body.peopleList.elements);
      }
      setLoadingButton(false);
    }
    const params = {
      operation: secondStepParameters.searchOnSocialMe
        ? ['SIGAM', 'Person', 'SearchSocialMe']
        : ['SIGAM', 'Person', 'Search'],
      urlParams: {
        institutionId,
        organizationUnitId,
      },
      data: {
        filter,
        pagination: {
          limit: 0,
          sort: {
            by: 'name',
            order: 'ASC',
          },
        },
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      return setPeopleList(response.body.peopleList.elements);
    }
    setLoadingButton(false);
  }

  const HandleChangeFieldDocument = () => (event) => {
    if (event.target.value.length > 18) {
      return form.setFieldsValue({
        main_document: cnpjMask(event.target.value),
      });
    }
    if (event.target.value.length <= 14) {
      return form.setFieldsValue({
        main_document: cpfMaskContinuos(event.target.value),
      });
    }
    if (event.target.value.length > 14) {
      return form.setFieldsValue({
        main_document: cnpjMask(event.target.value),
      });
    }
  };

  return (
    <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
      <div style={{ padding: '10px', minHeight: 'calc(100vh - 55px)' }}>
        <Form
          labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
          wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
          form={form}
          onFinish={Search}
          size="small"
          layout="vertical"
          scrollToFirstError
        >
          <FormItem label="Nome" name={['name']} item={<Input />} />
          <FormItem
            label="CPF / CNPJ"
            name={['main_document']}
            item={<Input onChange={HandleChangeFieldDocument()} />}
          />
          <FormItem
            item={
              <Button
                icon={<SearchOutlined />}
                htmlType="submit"
                type="primary"
                style={{ width: '100%' }}
                loading={loadingButton}
              >
                Pesquisar
              </Button>
            }
          />
        </Form>
      </div>
    </Animate>
  );
}
