import React, { useContext, useEffect, useState } from 'react';
//Libraries
import { Form } from 'antd';
// Contexts
import { SIGAMContext } from '~/contexts/SIGAMContext';
// Own Components
import CheckboxGroup from './components/CheckboxGroup';
//Components
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function AddForm({ form, record }) {
  const { activeInstitution } = useContext(SIGAMContext);
  const [permissions, setPermissions] = useState();
  const [loadingForm, setLoadingForm] = useState(true);
  const [getAllPermissions, setGetAllPermissions] = useState(false);
  const [getMyPermissions, setGetMyPermissions] = useState(false);
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (getAllPermissions && getMyPermissions) {
      setLoadingForm(false);
    }
  }, [getAllPermissions, getMyPermissions]);

  useEffect(() => {
    async function GetMyPermissions() {
      const params = {
        operation: ['SIGAM', 'OrganizationalUnits', 'Permissions', 'Get'],
        urlParams: {
          institutionId: activeInstitution.id,
          organizationUnitId: record.id,
        },
        data: {
          pagination: {
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        let myPermissions = response.body.permission;
        if (myPermissions) {
          let processedData = {};
          for (const key in myPermissions) {
            processedData[key] = true;
          }
          setInitialValues(processedData);
        }
        setGetMyPermissions(true);
      }
    }
    async function GetPermissions() {
      const params = {
        operation: ['SIGAM', 'List', 'Permissions'],
        data: {
          pagination: {
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      console.log(response.body.permissions)
      if (response.success) {
        setPermissions(response.body.permissions);
        setGetAllPermissions(true);
      }
    }
    GetPermissions();
    GetMyPermissions();
  }, [activeInstitution, record]);

  if (loadingForm) {
    return <Loading text="Buscando informações" />;
  }

  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      form={form}
      layout="vertical"
      size="small"
      scrollToFirstError
      initialValues={initialValues}
    >
      <CheckboxGroup form={form} permissions={permissions} />
    </Form>
  );
}
