import React, { useContext, useState } from 'react';
// Libraries
import { Drawer, Form } from 'antd';
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Components
import Text from '~/components/typography/text';
// Pages
import Search from './pages/Search';
import Result from './pages/Result';

export default function SearchDrawer({
    Trigger,
    SecondStepComponent,
    title,
    secondStepParameters,
    neverOpen,
}) {
    const { isMobile } = useContext(ResponsiveContext);
    const [visibility, setVisibility] = useState(false);
    const [form] = Form.useForm();
    const [peopleList, setPeopleList] = useState();
    const [selectedPerson, setSelectedPerson] = useState();

    function ShowSearchDrawer() {
        if (neverOpen) {
            return setVisibility(false);
        }
        setVisibility(true);
    }

    function CloseSearchDrawer() {
        setVisibility(false);
        setSelectedPerson();
    }

    return (
        <>
            <div onClick={() => ShowSearchDrawer()}>{Trigger}</div>
            <Drawer
                width={`${isMobile ? '100vw' : '30vw'}`}
                closable={false}
                placement="right"
                visible={visibility}
                title={
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {isMobile ?
                            <ArrowLeftOutlined
                                onClick={CloseSearchDrawer}
                                style={{
                                    cursor: 'pointer',
                                    marginRight: 10
                                }}
                            />
                            :
                            <CloseOutlined
                                onClick={CloseSearchDrawer}
                                style={{
                                    cursor: 'pointer',
                                    marginRight: 10
                                }}
                            />
                        }

                        <Text>
                            {title}
                        </Text>
                    </div>
                }
            >
                {selectedPerson ?
                    <SecondStepComponent
                        CloseSearchDrawer={CloseSearchDrawer}
                        secondStepParameters={secondStepParameters}
                        peopleList={peopleList}
                        setPeopleList={setPeopleList}
                        selectedPerson={selectedPerson}
                        setSelectedPerson={setSelectedPerson}
                    />
                    :
                    peopleList ?
                        <Result peopleList={peopleList} setPeopleList={setPeopleList} setSelectedPerson={setSelectedPerson} />
                        :
                        <Search
                            setPeopleList={setPeopleList}
                            form={form}
                            secondStepParameters={secondStepParameters}
                        />
                }
            </Drawer>
        </>
    );
}