import React, { useState } from 'react';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import FileContainer from './components/FileContainer';

const { Dragger } = Upload;

export default function NewAttachments({ propertys, CloseDrawer }) {
  const [files, setFiles] = useState([]);

  async function CustomUpload(file) {
    setFiles((oldFiles) => [...oldFiles, file]);
  }

  const props = {
    name: 'file',
    multiple: true,
    action: (file) => CustomUpload(file),
    showUploadList: false,
  };

  return (
    <div>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Clique ou arraste os arquivos para o upload
        </p>
        <p className="ant-upload-hint">
          O arquivo deverá ser no formato PDF e ter no máximo 10 MB
        </p>
      </Dragger>
      {files && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          {files.map((file, index) => (
            <FileContainer key={index} file={file} propertys={propertys} />
          ))}
        </div>
      )}
    </div>
  );
}
