import React, { useState } from 'react';
// Libraries
import { Button, message } from 'antd';
import { FaStamp } from 'react-icons/fa';

export default function LexML({ document, propertys }) {
  const [loadingButton, setLoadingButton] = useState(false);

  function fakeSync() {
    setLoadingButton(true);
    setTimeout(function () {
      setLoadingButton(false);
      message.success('Processo sincronizado com sucesso!');
    }, 5000);
  }

  return (
    <div style={{ padding: 10, display: 'flex', justifyContent: 'center' }}>
      <Button
        type="primary"
        size="large"
        icon={<FaStamp style={{ marginRight: 5 }} />}
        onClick={() => fakeSync()}
        loading={loadingButton}
      >
        Sincronizar processo no LexML
      </Button>
    </div>
  );
}
