import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth } from '~/contexts/AuthContext';
import MessagerManagerContext from './Context';
import GetMessages from '~/services/calls/messager/messages/read';
import GetChats from '~/services/calls/messager/chat/read';
import VIEWS from '../../constants/views';

export default function MessagerManagerProvider({ children }) {
  const { user } = useAuth();

  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [loadingChats, setLoadingChats] = useState(false);
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState();
  const [search, setSearch] = useState('');
  const [view, setView] = useState(VIEWS.MY_CHATS);

  const { institutionId } = useParams();

  async function LoadMessages(chatId) {
    const response = await GetMessages({ chatId, institutionId });

    if (response.success) {
      setMessages(response.body.chat_group_messages.elements);
    }
  }

  async function LoadChats(search) {
    const person_id = user.id;

    const response = await GetChats({ person_id, institutionId, search });

    if (response.success) {
      const { elements } = response.body.chat_groups;

      setChats(elements);
    }
  }

  useEffect(() => {
    setLoadingChats(true);

    LoadChats(search).finally(() => {
      setLoadingChats(false);
    });
  }, [search]);

  useEffect(() => {
    if (selectedChat) {
      setLoadingMessages(true);

      LoadMessages(selectedChat.id).finally(() => {
        setLoadingMessages(false);
      });
    }
  }, [selectedChat]);

  useEffect(() => {
    let timer;

    timer = setInterval(() => {
      LoadChats(search);
      if (selectedChat) LoadMessages(selectedChat.id);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [messages, selectedChat]);

  return (
    <MessagerManagerContext.Provider
      value={{
        institutionId,
        view,
        setView,
        loadingChats,
        chats,
        setChats,
        selectedChat,
        setSelectedChat,
        messages,
        setMessages,
        loadingMessages,
        setSearch,
      }}
    >
      {children}
    </MessagerManagerContext.Provider>
  );
}

export const useMessagerManager = () => useContext(MessagerManagerContext);
