import React, { useState, useEffect } from 'react';
// Libraries
import { Row, Col, message, Alert, Result } from 'antd';
import { MdPublic } from 'react-icons/md';
import { GrSecure } from 'react-icons/gr';
import { FaUserSecret } from 'react-icons/fa';
import Animate, { FadeIn } from 'animate-css-styled-components';
import { useParams } from 'react-router-dom';
// Own Components
import SelectionItem from './components/SelectionItem';
import PeopleWithPermission from './components/PeopleWithPermission';
// Components
import Loading from '~/components/loading';
import Title from '~/components/typography/title';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function AccessLevel({ process, propertys }) {
  const { institutionId, organizationUnitId } = useParams();
  const [loadingContent, setLoadingContent] = useState(true);
  const [activePrivacy, setActivePrivacy] = useState();
  const [accessLevel, setAccessLevel] = useState();

  const processInformation = process || propertys.process[0];

  const options = [
    {
      icon: MdPublic,
      title: 'Público',
      description: 'Todos podem visualizar',
      value: 'PUBLIC',
    },
    {
      icon: GrSecure,
      title: 'Restrito',
      description: 'Descrição do Restrito',
      value: 'RESTRICTED',
    },
    {
      icon: FaUserSecret,
      title: 'Sigiloso',
      description: 'Descrição do Restrito',
      value: 'CONFIDENTIAL',
    },
  ];

  async function Edit(newPrivacy) {
    const params = {
      operation: ['SIGAM', 'Processes', 'Edit'],
      urlParams: {
        institutionId,
        organizationUnitId,
        processId: processInformation.id,
      },
      data: {
        privacy: newPrivacy,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      if (propertys.GetDataAgain) {
        propertys.GetDataAgain();
      }
      return true;
    }
    return message.error('Alguma coisa aconteceu...');
  }

  useEffect(() => {
    console.log(1);
    async function PrepareAccess() {
      if (processInformation) {
        if (processInformation.privacy) {
          if (
            processInformation.privacy === 'PUBLIC' ||
            processInformation.privacy === 'RESTRICTED' ||
            processInformation.privacy === 'CONFIDENTIAL'
          ) {
            setActivePrivacy(processInformation.privacy);
          } else {
            setActivePrivacy('PUBLIC');
          }
        } else {
          setActivePrivacy('PUBLIC');
        }
        return setLoadingContent(false);
      }
    }
    async function verifyPermission() {
      const params = {
        operation: ['SIGAM', 'Processes', 'HavePermission'],
        urlParams: {
          institutionId,
          organizationUnitId,
          processId: processInformation.id,
        },
      };
      const response = await SocialMeAPI(params);
      // console.log(response)
      setAccessLevel(response.body.access_level);
      PrepareAccess();
    }
    verifyPermission();
  }, [processInformation]);

  if (processInformation.status === 'INACTIVE') {
    return (
      <Result
        status="error"
        title="Você não pode atualizar um processo arquivado"
      />
    );
  }

  if (accessLevel?.is_in_charge === false) {
    return (
      <Result
        status="error"
        title="Você não tem permissão para alterar o processo"
      />
    );
  }

  if (loadingContent) {
    return <Loading />;
  }

  function RenderPeopleWithPermission() {
    switch (activePrivacy) {
      case 'RESTRICTED':
      case 'CONFIDENTIAL':
        return (
          <PeopleWithPermission
            accessLevel={accessLevel}
            process={processInformation}
          />
        );
      default:
        return <></>;
    }
  }

  return (
    <div>
      {/* 
      {accessLevel && accessLevel.is_in_charge === false && (
        <Alert
          style={{ marginBottom: '10px' }}
          message="Você não tem permissão para alterar o processo"
          type="warning"
          showIcon
        />
      )}
    
      {accessLevel &&
        accessLevel.is_in_charge &&
        processInformation.total_tramitations > 0 && (
          <Alert
            style={{ marginBottom: '10px' }}
            message="Você não pode alterar um processo que já foi tramitado"
            type="warning"
            showIcon
          />
        )}
        */}
      <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
        <Row>
          <Title style={{ textAlign: 'center', width: '100%' }} level={4}>
            Nível de Acesso
          </Title>
        </Row>
        <Row>
          {options.map((item) => (
            <Col
              key={item.value}
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
              style={{ padding: 5 }}
            >
              <SelectionItem
                item={item}
                Edit={Edit}
                activePrivacy={activePrivacy}
                setActivePrivacy={setActivePrivacy}
                accessLevel={accessLevel}
                processInformation={processInformation}
              />
            </Col>
          ))}
        </Row>
        {RenderPeopleWithPermission()}
      </Animate>
    </div>
  );
}
