import React from 'react';
// Libraries
import { Result } from 'antd';
// Components
import Button from '~/components/button';
// Environments
import System from '~/environments/System';

export default function IvanlidOperation({ operationResponse, intent }) {
  return (
    <div>
      <Result
        status="error"
        title="Operação inválida"
        subTitle={`A operação solicitada não existe`}
        extra={[
          <Button
            type="primary"
            key={1}
            onClick={() =>
              window.location.replace(
                `https://${System.hostname.operationsFinder}`
              )
            }
          >
            Me tire daqui
          </Button>,
        ]}
      />
    </div>
  );
}
