import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0px;
`;
export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${(props) => props.borderColor || '#000'};
  border-radius: 10px;
  padding: 5px;
`;