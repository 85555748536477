import React, { useContext, useEffect, useState } from 'react';
//Libraries
import { Row, Col, Form, TreeSelect } from 'antd';
// Contexts
import { SIGAMContext } from '~/contexts/SIGAMContext';
//Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import RadioGroup from '~/components/radioGroup';
import Checkbox from '~/components/checkbox';
import TextArea from '~/components/textArea';
import Text from '~/components/typography/text';
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

const { TreeNode } = TreeSelect;

export default function EditForm({ form, record }) {
  const { activeInstitution } = useContext(SIGAMContext);
  const [loadingTree, setLoadingTree] = useState(true);
  const [loadingForm, setLoadingForm] = useState(true);
  const [tree, setTree] = useState();
  const [initialValues, setInitialValues] = useState();

  console.log('record: ', record);

  const Option = ({ color, text }) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '15px',
          marginBottom: '1px',
        }}
      >
        <div
          style={{
            backgroundColor: color,
            width: 16,
            height: 16,
            borderRadius: 50,
          }}
        />
        <Text style={{ marginLeft: 8 }}>{text}</Text>
      </div>
    );
  };

  const colorOptions = [
    { label: <Option color={'#E593C2'} text="Margenta" />, value: 'magenta' },
    { label: <Option color={'#E57982'} text="Vermelho" />, value: 'red' },
    { label: <Option color={'#DD6340'} text="Vulcão" />, value: 'volcano' },
    { label: <Option color={'#DB822E'} text="Laranja" />, value: 'orange' },
    { label: <Option color={'#EBC57E'} text="Ouro" />, value: 'gold' },
    { label: <Option color={'#D2E69F'} text="Lima" />, value: 'lime' },
    { label: <Option color={'#69B648'} text="Verde" />, value: 'green' },
    { label: <Option color={'#2EA7AB'} text="Ciano" />, value: 'cyan' },
    { label: <Option color={'#4C96E4'} text="Azul" />, value: 'blue' },
    {
      label: <Option color={'#8998E1'} text="Azul Marinho" />,
      value: 'geekblue',
    },
    { label: <Option color={'#6D40B8'} text="Roxo" />, value: 'purple' },
  ];

  useEffect(() => {
    if (!loadingTree) {
      if (record) {
        let values = record;
        if (values.status === 'ACTIVE') {
          values.status = true;
        } else {
          values.status = false;
        }
        setInitialValues(values);
        setLoadingForm(false);
      }
    }
  }, [loadingTree, record]);

  useEffect(() => {
    const CreateTree = (organizationalUnits) => {
      let treeData = {};
      let mainElement = {};
      if (
        organizationalUnits &&
        Array.isArray(organizationalUnits) &&
        organizationalUnits.length > 0
      ) {
        organizationalUnits.forEach((orgUnity) => {
          treeData[orgUnity.id] = {
            id: orgUnity.id,
            title: orgUnity.name,
            key: orgUnity.id,
            children: [],
          };

          if (!orgUnity.parent) mainElement = treeData[orgUnity.id];
          else {
            if (treeData[orgUnity.parent]) {
              treeData[orgUnity.parent].children.push(treeData[orgUnity.id]);
            }
          }
        });
      }
      return mainElement;
    };
    async function GetOrganizationalUnits() {
      const params = {
        operation: ['SIGAM', 'OrganizationalUnits', 'GetAll'],
        urlParams: {
          institutionId: activeInstitution.id,
        },
        data: {
          pagination: {
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      console.log(response);
      if (response.success) {
        const organizeTree = await CreateTree(
          response.body.orgUnities.elements
        );
        setTree(organizeTree);
      }
      return setLoadingTree(false);
    }
    GetOrganizationalUnits();
  }, [activeInstitution]);

  function ChildrenRender(data) {
    if (!data) return;
    return (
      <TreeNode key={data.key} value={data.id} title={data.title}>
        {data.children.map((item) => ChildrenRender(item))}
      </TreeNode>
    );
  }

  if (loadingForm) {
    return <Loading text="Buscando informações" />;
  }

  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      form={form}
      layout="vertical"
      size="small"
      scrollToFirstError
      initialValues={initialValues}
    >
      <Row>
        <Col span={24}>
          <FormItem
            label="Nome da Unidade Organizacional"
            name={['name']}
            rules={[
              {
                required: true,
                message: 'Insira um nome para a Unidade',
              },
            ]}
            item={<Input />}
          />
        </Col>
        <Col span={24}>
          <FormItem
            label="Sigla"
            name={['abbreviation']}
            item={<Input width="100px" />}
          />
        </Col>
        {record.kind !== 'MAIN-ORG-UNITY' && (
          <Col span={24}>
            <FormItem
              label="Selecione a Unidade Organizacional a qual pertence"
              name={['parent']}
              rules={[
                {
                  required: true,
                  message: 'Selecione uma Unidade',
                },
              ]}
              item={
                loadingTree ? (
                  <Loading text="Buscando unidades" height="100px" />
                ) : (
                  <TreeSelect
                    showSearch
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder="Selecione"
                    allowClear
                    treeDefaultExpandAll
                    size={'default'}
                  >
                    <TreeNode value={tree.id} title={tree.title}>
                      {tree.children.map((item) => ChildrenRender(item))}
                    </TreeNode>
                  </TreeSelect>
                )
              }
            />
          </Col>
        )}
        {record.kind !== 'MAIN-ORG-UNITY' && (
          <Col span={24}>
            <FormItem
              label="Situação"
              name="status"
              valuePropName="checked"
              item={<Checkbox text={'Ativa'} />}
            />
          </Col>
        )}
        <Col span={24}>
          <FormItem
            label="Descrição"
            name={['description']}
            item={<TextArea row="4" />}
          />
        </Col>

        <Col span={24}>
          <FormItem
            label="Selecione uma cor para representar a unidade"
            name={['settings', 'color']}
            item={<RadioGroup options={colorOptions} />}
          />
        </Col>
      </Row>
    </Form>
  );
}

/*

<>
                <TreeNode value="parent 1-0" title="parent 1-0">
                    <TreeNode value="leaf1" title="my leaf" />
                    <TreeNode value="leaf2" title="your leaf" />
                </TreeNode>
                <TreeNode value="parent 1-1" title="parent 1-1">
                    <TreeNode value="sss" title={<b style={{ color: '#08c' }}>sss</b>} />
                </TreeNode>
            </>

*/
