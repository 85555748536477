import React, { useRef, useEffect, useState } from 'react';

import { useAuth } from '~/contexts/AuthContext';
import { useMessagerManager } from '../../../../contexts/MessagerManager/Provider';
import useHeigthPixeilsToScroll from './hooks/useHeigthPixeilsToScroll';
import Loading from '~/components/loading';

import * as S from './style';

import MessageItem from './components/Item';

export default function MessagerContent() {
  const [scrollToBottom, setScrollToBottom] = useState(true);

  const { user } = useAuth();

  const { messages, loadingMessages } = useMessagerManager();

  const height = useHeigthPixeilsToScroll();

  const ref = useRef(null);

  function HandleCheckIfScrollIsOnBottom(event) {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    const maxScrollHeight = scrollTop + clientHeight;

    const goToBottom = maxScrollHeight === scrollHeight;

    setScrollToBottom(goToBottom);
  }

  useEffect(() => {
    ref.current.addEventListener('scroll', HandleCheckIfScrollIsOnBottom);

    return () => {
      ref.current.removeEventListener('scroll', HandleCheckIfScrollIsOnBottom);
    };
  }, []);

  useEffect(() => {
    if (scrollToBottom) ref.current.scrollTop = ref.current.scrollHeight;
  }, [messages, loadingMessages]);

  return (
    <S.Container height={height} ref={ref}>
      {loadingMessages ? (
        <Loading text="Carregando mensagens" height="500px" />
      ) : (
        <>
          {messages.map((message) => {
            const isLoggedUser = message.person_id === user.id;

            return (
              <MessageItem
                key={message.id}
                isLoggedUser={isLoggedUser}
                {...message}
              />
            );
          })}
        </>
      )}
    </S.Container>
  );
}
