export default {
  // A
  accepted: {
    original: 'Accepted',
    text: 'Aceito',
  },
  add: {
    original: 'Add',
    text: 'Adicionar',
  },
  // B
  back: {
    original: 'Back',
    text: 'Voltar',
  },
  // C
  cancel: {
    original: 'Cancel',
    text: 'Cancelar',
  },
  // D
  // E
  // F
  // G
  // H
  // I
  // J
  // K
  // L
  language: {
    original: 'Language',
    text: 'Idioma',
  },
  login: {
    original: 'Login',
    text: 'Entrar',
  },
  logout: {
    original: 'Logout',
    text: 'Sair',
  },
  // M
  // N
  next: {
    original: 'Next',
    text: 'Próxima',
  },
  no: {
    original: 'No',
    text: 'Não',
  },
  // O
  // P
  privacy: {
    original: 'Privacy',
    text: 'Privacidade',
  },
  privacyPolicy: {
    original: 'Privacy policy',
    text: 'Política de privacidade',
  },
  // Q
  // R
  required: {
    original: 'Required',
    text: 'Obrigatório',
  },
  // S
  select: {
    original: 'Select',
    text: 'Selecione',
  },
  settings: {
    original: 'Settings',
    text: 'Configurações',
  },
  // T
  terms: {
    original: 'Terms',
    text: 'Termos',
  },
  // U
  update: {
    original: 'Update',
    text: 'Atualizar',
  },
  // V
  // W
  // X
  // Y
  yes: {
    original: 'Yes',
    text: 'Sim',
  },
  // Z
};
