import React, { useEffect, useState } from 'react';
// Libraries
import { ReloadOutlined } from '@ant-design/icons';
import Animate, { FadeInUp } from 'animate-css-styled-components';
// Components
import Button from '~/components/button';

export default function UpdatePendingCard({ GetDataAgain, updatePending }) {
    const [loading, setLoading] = useState(false);

    function CallTheData() {
        setLoading(true);
        GetDataAgain();
    }

    useEffect(() => {
        if (loading) {
            if (!updatePending) {
                setLoading(false);
            }
        }
    }, [loading, updatePending])

    return updatePending ?
        (
            <Animate
                Animation={[FadeInUp]}
                duration={['0.5s']}
                delay={['0.2s']}
            >
                <div style={{ marginBottom: 10 }}>
                    <Button
                        icon={<ReloadOutlined />}
                        type="primary"
                        style={{ width: '100%' }}
                        onClick={() => CallTheData()}
                        loading={loading}
                    >
                        Você tem dados desatualizados, clique aqui para atualizá-los
                    </Button>
                </div>
            </Animate>
        )
        :
        <></>
}