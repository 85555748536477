import React, { useContext } from 'react';
//Contexts
import { SidebarContext } from '~/contexts/SidebarContext';
//Libraries
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
//Components
import Button from '~/components/button';

export default function CollapsedButton() {
  const { collapsed, ToggleCollapsed } = useContext(SidebarContext);

  if (collapsed) {
    return (
      <Button
        shape="circle"
        onClick={() => ToggleCollapsed()}
        icon={<MenuUnfoldOutlined />}
      />
    );
  }
  return (
    <Button
      shape="circle"
      onClick={() => ToggleCollapsed()}
      icon={<MenuFoldOutlined />}
    />
  );
}
