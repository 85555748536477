import React, { useContext } from 'react';
// Own Components
import Title from '~/components/typography/title';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import ProcessView from './components/ProcessView';
import AttachmentView from './components/AttachmentView';
import ExternalView from './components/ExternalView';
import DocumentView from './components/DocumentView';
// Component

export default function FileView({ selectedFile,  process }) {
  const { isMobile } = useContext(ResponsiveContext);

  return (
    <div style={{ padding: isMobile ? '0px' : '0px 10px 20px 20px', marginTop: isMobile ? '40px' : '0px' }}>
      <Title style={{ textAlign: 'center' }} level={5}>
        Visualização
      </Title>
      {selectedFile && selectedFile.node.type === 'document' ? (
        <DocumentView document={selectedFile.node.data} />
      ) : selectedFile && selectedFile.node.type === 'attachment' ? (
        selectedFile.node.data.url ? (
          <ExternalView document={selectedFile.node.data} />
        ) : (
          <AttachmentView
            document={selectedFile.node.data}
            fatherProcess={process}
          />
        )
      ) : (
        <ProcessView process={process} />
      )}
    </div>
  );
}

/* 

<ExternalView document={selectedFile} />
                :
                <AttachmentView document={selectedFile} />

*/
