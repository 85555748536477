import React, { useContext } from 'react';
// Libraries
import { Space } from 'antd';
import Animate, { FadeIn } from 'animate-css-styled-components';
import { Link, useRouteMatch } from 'react-router-dom';
import { QuestionCircleOutlined, KeyOutlined } from '@ant-design/icons';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { SIGAMContext } from '~/contexts/SIGAMContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Standalone Components
import { Container, Content, LeftItems, RightItems } from './style';
// Own Components
import CollapsedButton from './components/CollapsedButton';
import UserButton from './components/UserButton';
import InstitutionName from './components/InstitutionName';
import DashboardSwitchButton from './components/DashboardSwitchButton';
import UnitsSwitchButton from './components/UnitsSwitchButton'
// Components
import Button from '~/components/button'

export default function MainNavbar({ withoutSidebar, simple }) {
  const { theme } = useContext(ThemeContext);
  const { activeInstitution } = useContext(SIGAMContext);
  const { isMobile } = useContext(ResponsiveContext);
  const { path } = useRouteMatch();

  if (isMobile) {
    return simple ? (
      <Container
        backgroundcolor={
          withoutSidebar ? theme.color.primary : theme.color.secondary
        }
      >
        <Content>
          <LeftItems>
            <Animate Animation={[FadeIn]} duration={['0.8s']} delay={['0.2s']}>
              {withoutSidebar ? (
                <Link to="/">
                  <img
                    src="https://media.milanote.com/p/images/1L5DiK1bzJOVfq/7lo/SIGAM-01.png"
                    style={{ height: 50 }}
                    alt="..."
                  />
                </Link>
              ) : (
                <CollapsedButton />
              )}
            </Animate>
          </LeftItems>
          <Animate Animation={[FadeIn]} duration={['0.8s']} delay={['0.2s']}>
            <RightItems>
              <Link to="/verificacao" style={{ margin: '0px 4px 0px 4px' }}>
                <Button icon={<KeyOutlined />} />
              </Link>
              <Link to="/ajuda" style={{ margin: '0px 4px 0px 4px' }}>
                <Button icon={<QuestionCircleOutlined />} />
              </Link>
              <UserButton withoutSidebar={withoutSidebar} />
            </RightItems>
          </Animate>
        </Content>
        <Content>
          <InstitutionName />
        </Content>
      </Container>
    )
      :
      (
        <Container
          backgroundcolor={theme.color.primary}
        >
          <Content>
            <div>
              <CollapsedButton />
            </div>
            <div>
              <Link to="/">
                <img
                  src="https://media.milanote.com/p/images/1L5DiK1bzJOVfq/7lo/SIGAM-01.png"
                  style={{ height: 50 }}
                  alt="..."
                />
              </Link>
            </div>
            <div>
              <UserButton withoutSidebar={withoutSidebar} />
            </div>
          </Content>
          <Content justifyContent="center" marginTop="10px">
            <InstitutionName withoutSidebar />
          </Content>
          {activeInstitution &&
            <Content justifyContent="center" marginTop="10px">
              <DashboardSwitchButton withoutSidebar={withoutSidebar} />
            </Content>
          }
          {activeInstitution &&
            path.includes('/agente-publico') &&
            <Content justifyContent="center" marginTop="10px">
              <UnitsSwitchButton />
            </Content>
          }
        </Container>
      )
  }

  return (
    <Container
      backgroundcolor={
        withoutSidebar ? theme.color.primary : theme.color.secondary
      }
    >
      <Content>
        <LeftItems>
          <Animate Animation={[FadeIn]} duration={['0.8s']} delay={['0.2s']}>
            {withoutSidebar ? (
              <Link to="/">
                <img
                  src="https://media.milanote.com/p/images/1L5DiK1bzJOVfq/7lo/SIGAM-01.png"
                  style={{ height: 50 }}
                  alt="..."
                />
              </Link>
            ) : (
              <CollapsedButton />
            )}
          </Animate>
        </LeftItems>
        <Animate Animation={[FadeIn]} duration={['0.8s']} delay={['0.2s']}>
          <RightItems>
            <Space direction="horizontal">
              <InstitutionName />
              {activeInstitution && <DashboardSwitchButton withoutSidebar={withoutSidebar} />}
              {activeInstitution && path.includes('/agente-publico') && <UnitsSwitchButton />}
              <Link to="/verificacao" style={{ margin: '0px 4px 0px 4px' }}>
                <Button icon={<KeyOutlined />}>Pesquisar documento</Button>
              </Link>
              <Link to="/ajuda" style={{ margin: '0px 4px 0px 4px' }}>
                <Button icon={<QuestionCircleOutlined />}>Ajuda</Button>
              </Link>
              <UserButton withoutSidebar={withoutSidebar} />
            </Space>
          </RightItems>
        </Animate>
      </Content>
    </Container>
  );
}
