import React, { useEffect, useState } from 'react';
// Libraries
import { PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
// Own Components
import AddPerson from './components/AddPerson';
import PeopleList from './components/PeopleList';
// Components
import Title from '~/components/typography/title';
import Divider from '~/components/divider';
import Drawer from '~/components/drawer';
import Button from '~/components/button';
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function PeopleWithPermission({ process }) {
    const { institutionId, organizationUnitId } = useParams();
    const [people, setPeople] = useState([]);
    const [loadingContent, setLoadingContent] = useState(true);

    async function GetPeopleAgain() {
        const params = {
            operation: ['SIGAM', 'Processes', 'PrivateAccess', 'Get'],
            urlParams: {
                institutionId: institutionId,
                organizationUnitId: organizationUnitId,
                processId: process.id
            }
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            setPeople(response.body.privateAccess.elements);
        }
    }

    useEffect(() => {
        async function GetPeople() {
            const params = {
                operation: ['SIGAM', 'Processes', 'PrivateAccess', 'Get'],
                urlParams: {
                    institutionId: institutionId,
                    organizationUnitId: organizationUnitId,
                    processId: process.id
                }
            };
            const response = await SocialMeAPI(params);
            console.log(response)
            if (response.success) {
                setPeople(response.body.privateAccess.elements);
            }
            setLoadingContent(false);
        }
        GetPeople();
    }, [process, institutionId, organizationUnitId])

    return (
        <div>
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                <Title level={5}>Pessoas Autorizadas</Title>
                <Drawer
                    Trigger={
                        <Button icon={<PlusOutlined />} type="dashed">Novo Acesso</Button>
                    }
                    title="Novo Acesso"
                    DrawerContentComponent={AddPerson}
                    DrawerContentComponentParams={{ process, people, GetPeopleAgain }}
                />
            </div>
            {loadingContent ?
                <Loading />
                :
                <PeopleList people={people} process={process} GetPeopleAgain={GetPeopleAgain} />
            }
        </div>
    )
}