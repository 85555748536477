import React, { createContext, useEffect, useState } from 'react';
// Environments
import System from '~/environments/System';
import Debug from '~/environments/Debug';
// Utils
import Cookie from '~/utils/Cookie';
import SocialMeAPI from '~/utils/SocialMeAPI';

const isDebug = Debug.Context.Browser;

export const BrowserContext = createContext();

const BrowserContextProvider = ({children}) => {
  const [endedBrowserLogic, setEndedBrowserLogic] = useState(false);

  useEffect(() => {
    async function SetNewBrowserToken() {
      if (isDebug) console.log('🚀 API: Set new browser token');
      const params = {
        operation: ['Auth', 'BrowserToken', 'Set'],
      };
      const response = await SocialMeAPI(params);
      if (isDebug) console.log('  ⚡ API Response: ', response);
      if (response.success) {
        if (isDebug) console.log('🍪 Creating the Browser Cookie');
        const creatingTheCookie = await Cookie.Create({
          name: System.cookie.name.browser,
          value: response.body.brwsrtkn,
        });
        if (creatingTheCookie) {
          if (isDebug) console.log('  ✔ Browser cookie successfully created');
        } else if (isDebug) console.log('  ❗ Error creating Browser Cookie');
        return setEndedBrowserLogic(true);
      }
    }
    async function CheckBrowserToken() {
      if (isDebug) console.log('🚀 API: Check browser token');
      const params = {
        operation: ['Auth', 'BrowserToken', 'Check'],
      };
      const response = await SocialMeAPI(params);
      if (isDebug) console.log('  ⚡ API Response: ', response);
      if (response.success) {
        if (isDebug) console.log('    ✔ Valid browser token');
        return setEndedBrowserLogic(true);
      }
      if (isDebug) console.log('    ❌ Invalid browser token');
      SetNewBrowserToken();
    }
    async function VerifyBrowserToken() {
      if (isDebug) console.log('🔍 Searching for browser token');
      const BrowserTokenCookies = await Cookie.Get(System.cookie.name.browser);
      if (BrowserTokenCookies) {
        if (isDebug) console.log('  ✔ Browser token found');
        CheckBrowserToken();
      } else {
        if (isDebug) console.log('  ❌ Browser token not found');
        SetNewBrowserToken();
      }
    }
    VerifyBrowserToken();
  }, []);

  return (
    <BrowserContext.Provider value={{ endedBrowserLogic }}>
      {children}
    </BrowserContext.Provider>
  );
};

export default BrowserContextProvider;
