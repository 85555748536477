import { format } from 'date-fns';

// let debug = false;

export function WalletFormatParser(value) {
  if (value % 1 === 0) {
    const newValue = value * 1000000;
    return newValue;
  }
  const newValue = value * 1000000;
  return newValue;

}

export const AmericanDateParser = (value) => {
  const date = value.split('/');
  const newDate = `${date[2]}-${date[1]}-${date[0]}`;
  return newDate;
  /*
    //var n = d.getTimezoneOffset();
    if(debug) console.log('date -> ', date);
    const newDate = addMinutes(new Date(date), 3);
    if(debug) console.log('newDate -> ', newDate);
    //return '1993-06-15T21:00:00.000Z';
    //return '1993-06-10';
    return '1993-06-13 23:00:00.000Z'
    */
};

export async function SetupSIGAMTimeline(data) {
  const timelineArray = [];

  async function AddMinuteEvent(item, index) {
    for (let k = 0; k < timelineArray[index].eventsOfTheDay.length; k++) {
      if (timelineArray[index].eventsOfTheDay[k].hour === format(new Date(item.created_at), 'HH:mm')) {
        for (let l = 0; l < timelineArray[index].eventsOfTheDay[k].eventsOfTheHour.length; l++) {
          if (timelineArray[index].eventsOfTheDay[k].eventsOfTheHour[l].action === item.action &&
            timelineArray[index].eventsOfTheDay[k].eventsOfTheHour[l].agent === item.agent &&
            timelineArray[index].eventsOfTheDay[k].eventsOfTheHour[l].kind === item.kind) {
            timelineArray[index].eventsOfTheDay[k].eventsOfTheHour[l].events.push(item);
          } else {
            const newMinuteObject = {
              action: item.action,
              agent: item.agent,
              kind: item.kind,
              created_by_name: item.created_by_name,
              field: item.field,
              value: item.value,
              events: []
            }
            newMinuteObject.events.push(item);
            timelineArray[index].eventsOfTheDay[k].eventsOfTheHour.push(newMinuteObject);
          }
        }
      }
    }
  }

  function AddMinuteObject(item, index) {
    const newItem = {
      hour: format(new Date(item.created_at), 'HH:mm'),
      eventsOfTheHour: [],
    };
    const newMinuteObject = {
      action: item.action,
      agent: item.agent,
      kind: item.kind,
      created_by_name: item.created_by_name,
      field: item.field,
      value: item.value,
      events: []
    }
    newMinuteObject.events.push(item);
    newItem.eventsOfTheHour.push(newMinuteObject);
    timelineArray[index].eventsOfTheDay.unshift(newItem);
  }

  async function AddEvent(item) {
    for (let j = 0; j < timelineArray.length; j++) {
      if (timelineArray[j].day === format(new Date(item.created_at), 'dd/MM/yyyy')) {
        const haveHour = await timelineArray[j].eventsOfTheDay.some(
          (data) => data.hour === format(new Date(item.created_at), 'HH:mm')
        );
        if (haveHour) {
          AddMinuteEvent(item, j);
        } else {
          AddMinuteObject(item, j);
        }
      }
    }
  }

  function AddTimelineArray(item) {
    const newItem = {
      day: format(new Date(item.created_at), 'dd/MM/yyyy'),
      eventsOfTheDay: [],
    };
    timelineArray.unshift(newItem);
    AddEvent(item);
  }

  for (let i = 0; i < data.length; i++) {
    const haveDate = await timelineArray.some(
      (item) => item.day === format(new Date(data[i].created_at), 'dd/MM/yyyy')
    );
    if (haveDate) {
      AddEvent(data[i]);
    } else {
      AddTimelineArray(data[i])
    }
  }

  return timelineArray;
}

export async function SetupSIGAMTimelineForProcessCover(data) {
  const timelineArray = [];

  async function AddMinuteEvent(item, index) {
    for (let k = 0; k < timelineArray[index].eventsOfTheDay.length; k++) {
      if (timelineArray[index].eventsOfTheDay[k].hour === format(new Date(item.created_at), 'HH:mm')) {
        for (let l = 0; l < timelineArray[index].eventsOfTheDay[k].eventsOfTheHour.length; l++) {
          if (timelineArray[index].eventsOfTheDay[k].eventsOfTheHour[l].action === item.action &&
            timelineArray[index].eventsOfTheDay[k].eventsOfTheHour[l].agent === item.agent &&
            timelineArray[index].eventsOfTheDay[k].eventsOfTheHour[l].kind === item.kind) {
            timelineArray[index].eventsOfTheDay[k].eventsOfTheHour[l].events.push(item);
          } else {
            const newMinuteObject = {
              action: item.action,
              agent: item.agent,
              kind: item.kind,
              created_by_name: item.created_by_name,
              events: []
            }
            newMinuteObject.events.push(item);
            timelineArray[index].eventsOfTheDay[k].eventsOfTheHour.push(newMinuteObject);
          }
        }
      }
    }
  }

  function AddMinuteObject(item, index) {
    const newItem = {
      hour: format(new Date(item.created_at), 'HH:mm'),
      eventsOfTheHour: [],
    };
    const newMinuteObject = {
      action: item.action,
      agent: item.agent,
      kind: item.kind,
      created_by_name: item.created_by_name,
      events: []
    }
    newMinuteObject.events.push(item);
    newItem.eventsOfTheHour.push(newMinuteObject);
    timelineArray[index].eventsOfTheDay.unshift(newItem);
  }

  async function AddEvent(data) {
    for (let j = 0; j < timelineArray.length; j++) {
      const haveHour = await timelineArray[j].eventsOfTheDay.some(
        (item) => item.hour === format(new Date(data.created_at), 'HH:mm')
      );
      if (haveHour) {
        AddMinuteEvent(data, j);
      } else {
        AddMinuteObject(data, j);
      }
    }
  }

  function AddTimelineArray(item) {
    const newItem = {
      day: format(new Date(item.created_at), 'dd/MM/yyyy'),
      eventsOfTheDay: [],
    };
    AddEvent(item);
    timelineArray.unshift(newItem);
  }

  for (let i = 0; i < data.length; i++) {
    const haveDate = await timelineArray.some(
      (item) => item.day === format(new Date(data[i].created_at), 'dd/MM/yyyy')
    );
    if (haveDate) {
      AddEvent(data[i]);
    } else {
      AddTimelineArray(data[i])
    }
  }

  return timelineArray;
}