import React, { useEffect, useState } from 'react';
// Libraries
import { SearchOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { Alert } from 'antd';
// Own Components
import PeopleList from './components/PeopleList';
// Components
import Title from '~/components/typography/title';
import Button from '~/components/button';
import Loading from '~/components/loading';
import SearchDrawer from '~/pages/SIGAM/components/SearchDrawer';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
// Pages
import SearchDrawerComponent from './components/SearchDrawerComponent';

export default function Interested({ document, propertys }) {
  const { institutionId, organizationUnitId } = useParams();
  const [people, setPeople] = useState([]);
  const [loadingContent, setLoadingContent] = useState(true);
  const [accessLevel, setAccessLevel] = useState();

  const documentInformation = document || propertys.document[0];

  async function GetPeopleAgain() {
    if (propertys && propertys.GetDataAgain) {
      propertys.GetDataAgain();
    }
    const params = {
      operation: ['SIGAM', 'Documents', 'Interested', 'Get'],
      urlParams: {
        institutionId,
        organizationUnitId,
        documentId: documentInformation.id,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      setPeople(response.body.interested.elements);
    }
  }

  useEffect(() => {
    async function GetPeople() {
      const params = {
        operation: ['SIGAM', 'Documents', 'Interested', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: documentInformation.id,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setPeople(response.body.interested.elements);
      }
      setLoadingContent(false);
    }
    async function verifyPermission() {
      const params = {
        operation: ['SIGAM', 'Documents', 'HavePermission'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: documentInformation.id,
        },
      };
      const response = await SocialMeAPI(params);
      setAccessLevel(response.body.access_level);
      GetPeople();
    }
    verifyPermission();
  }, [institutionId, organizationUnitId, documentInformation]);

  return (
    <div>
      {accessLevel && accessLevel.is_in_charge === false && (
        <Alert
          style={{ marginBottom: '10px' }}
          message="Você não tem permissão para alterar o documento"
          type="warning"
          showIcon
        />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Title level={5}>Interessados</Title>
        {accessLevel && accessLevel.is_in_charge && (
          <SearchDrawer
            Trigger={<Button icon={<SearchOutlined />}>Buscar Usuário</Button>}
            title="Novo Interessado"
            SecondStepComponent={SearchDrawerComponent}
            secondStepParameters={{
              document: documentInformation,
              people,
              GetPeopleAgain, //searchOnSocialMe: true,
              searchInSigam: true,
            }}
          />
        )}
      </div>
      {loadingContent ? (
        <Loading />
      ) : (
        <PeopleList
          people={people}
          document={documentInformation}
          GetPeopleAgain={GetPeopleAgain}
          accessLevel={accessLevel}
        />
      )}
    </div>
  );
}
