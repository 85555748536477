import React, { useState } from 'react';
// Libraries
import { PlusOutlined } from '@ant-design/icons';
import { Form, message } from 'antd';
import { useParams } from 'react-router-dom';
// Components
import Drawer from '~/components/drawer';
import Button from '~/components/button';
// Pages
import Creation from './pages/Creation';
import Finished from './pages/Finished';
// Actions
import Actions from '../../../Actions';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { AmericanDateParser } from '~/utils/Parsers'

export default function Header({ GetDataAgain }) {
  const [form] = Form.useForm();
  const { institutionId, organizationUnitId } = useParams();
  const [activePage, setActivePage] = useState('Creation');
  const [loadingCreationButton, setLoadingCreationButton] = useState(false);
  const [createdDocument, setCreatedDocument] = useState();

  async function onFinishCreation(values) {
    setLoadingCreationButton(true);
    // console.log('values ', values)
    if (values.priority) {
      values.priority = 1;
    } else {
      values.priority = 0;
    }
    if (values.kind === 'InBlank' || values.kind === 'Model') {
      values.kind = 'HTML';
    }
    values.privacy = 'PUBLIC';
    if (values.status) {
      if (values.status === true) {
        values.status = 'ACTIVE';
      } else {
        values.status = 'INACTIVE';
      }
    }
    if (values.created_externally_at) {
      values.created_externally_at = AmericanDateParser(values.created_externally_at)
    }
    const params = {
      operation: ['SIGAM', 'Documents', 'Add'],
      urlParams: {
        institutionId,
        organizationUnitId,
      },
      data: values,
    };
    // return console.log('body enviado -> ', params.data)
    const response = await SocialMeAPI(params);
    // console.log('resposta -> ', response)
    if (response.success) {
      message.success('Documento criado com sucesso!');
      setCreatedDocument(response.body.document);
      setActivePage('AccessLevel');
      form.resetFields();
      GetDataAgain();
    } else {
      message.error('Alguma coisa aconteceu...');
    }
    setLoadingCreationButton(false);
  }

  function Jump() {
    switch (activePage) {
      case 'AccessLevel':
        return setActivePage('Signatories');
      case 'Signatories':
        return setActivePage('Interested');
      case 'Interested':
        return setActivePage('Contributors');
      case 'Contributors':
        return setActivePage('Attachments');
      case 'Attachments':
        return setActivePage('Proceed');
      case 'Proceed':
        return setActivePage('Finished');
      default:

    }
  }

  function DrawerTitle() {
    switch (activePage) {
      case 'Creation':
        return 'Novo Documento';
      case 'Finished':
        return `${createdDocument.name} ${createdDocument.internal_id}`;
      default:
        return `${createdDocument.name} ${createdDocument.internal_id} - ${Actions[activePage].text}`;
    }
  }

  function DrawerWidth() {
    switch (activePage) {
      case 'Creation':
        return '100%';
      default:
        return `50%`;
    }
  }

  function MainButton() {
    switch (activePage) {
      case 'Creation':
        return (
          <Button
            type="primary"
            loading={loadingCreationButton}
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  onFinishCreation(values);
                })
                .catch((info) => {
                  // console.log('Validate Failed:', info);
                });
            }}
          >
            Criar Documento
          </Button>
        );
      default:
        return (
          <>
            <Button type="outline" style={{ marginRight: '10px' }} onClick={() => setActivePage('Finished')}>
              Terminar criação
            </Button>
            <Button type="primary" onClick={() => Jump()}>
              Próximo
            </Button>
          </>
        );
    }
  }

  function DrawerContentComponent({ CloseDrawer }) {
    switch (activePage) {
      case 'Creation':
        return <Creation form={form} />;
      case 'Finished':
        return (
          <Finished
            CloseDrawer={CloseDrawer}
            setActivePage={setActivePage}
            GetDataAgain={GetDataAgain}
          />
        );
      default:
        const Content = Actions[activePage].content;
        return <Content document={createdDocument} />;
    }
  }

  return (
    <Drawer
      Trigger={
        <Button icon={<PlusOutlined />} type="primary">
          Novo Documento
        </Button>
      }
      title={DrawerTitle()}
      width={DrawerWidth()}
      DrawerContentComponent={DrawerContentComponent}
      footer={activePage !== 'Finished'}
      footerCancelButton
      FooterMainButton={MainButton}
    />
  );
}
