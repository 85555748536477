import React, { useState } from 'react';
import {
  Spin,
  Form,
  Typography,
  Mentions,
  Input,
  Select,
  Button,
  Card,
  Divider,
} from 'antd';
import { usePageManager } from '../../contexts/PageManager/Provider';
import { useParams } from 'react-router-dom';

const { Text, Title } = Typography;
const { Option } = Mentions;

export default function Content() {
  const [form] = Form.useForm();
  const { initialValues, loadingContent, updateSettings } = usePageManager();
  const { institutionId } = useParams();
  const [loadingButton, setLoadingButton] = useState(false);

  async function onFinish(values) {
    setLoadingButton(true);
    const response = await updateSettings({ institutionId, values });
    if (response) {
      setLoadingButton(false);
    }
  }

  if (loadingContent) {
    return (
      <Card>
        <Spin />
      </Card>
    );
  }

  return (
    <Card>
      <Form
        labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        form={form}
        layout="vertical"
        scrollToFirstError
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <div>
          <Title level={5} style={{ margin: 0 }}>
            Comunicação externa
          </Title>
          <div>
            <Text strong>Whatsapp</Text>
            <div style={{ display: 'flex', gap: 8 }}>
              <Form.Item
                name={['whatsapp', 'limit']}
                label="Limite"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input placeholder="Limite" />
              </Form.Item>
              <Form.Item
                name={['whatsapp', 'period']}
                label="Período"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Select placeholder="Período" style={{ borderRadius: 0 }}>
                  <Option value="Semana">Semana</Option>
                  <Option value="Mês">Mês</Option>
                  <Option value="Ano">Ano</Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <div>
            <Text strong>Telegram</Text>
            <div style={{ display: 'flex', gap: 8 }}>
              <Form.Item
                name={['telegram', 'limit']}
                label="Limite"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input placeholder="Limite" />
              </Form.Item>
              <Form.Item
                name={['telegram', 'period']}
                label="Período"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Select placeholder="Período" style={{ borderRadius: 0 }}>
                  <Option value="Semana">Semana</Option>
                  <Option value="Mês">Mês</Option>
                  <Option value="Ano">Ano</Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <div>
            <Text strong>SMS</Text>
            <div style={{ display: 'flex', gap: 8 }}>
              <Form.Item
                name={['sms', 'limit']}
                label="Limite"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input placeholder="Limite" />
              </Form.Item>
              <Form.Item
                name={['sms', 'period']}
                label="Período"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Select placeholder="Período" style={{ borderRadius: 0 }}>
                  <Option value="Semana">Semana</Option>
                  <Option value="Mês">Mês</Option>
                  <Option value="Ano">Ano</Option>
                </Select>
              </Form.Item>
            </div>
          </div>
        </div>
        <div>
          <Divider />
          <Title level={5} style={{ margin: 0 }}>
            Lex ML
          </Title>
          <div>
            <Form.Item
              name={['lex_ml', 'jurisdiction']}
              label="Juridição Lex ML"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input placeholder="" />
            </Form.Item>
          </div>
        </div>
        <Divider />
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingButton}
            style={{
              width: '100%',
            }}
          >
            Salvar configurações
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
