import React, { useState } from 'react';
// Libraries
import { message, Form } from 'antd';
import { useParams } from 'react-router-dom';
// Own Components
import UploadFile from './components/UploadFile';
import FileButton from './components/FileButton';
import AttachmentForm from './components/AttachmentForm';
// Components
import Button from '~/components/button';
import Text from '~/components/typography/text';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function NewAttachment({ propertys, CloseDrawer }) {
  const { institutionId, organizationUnitId } = useParams();
  const [form] = Form.useForm();
  const [file, setFile] = useState();
  const [fileLink, setFileLink] = useState();
  const [loadingAttachButton, setLoadingAttachButton] = useState(false);

  async function onFinish(values) {
    setLoadingAttachButton(true);
    const processedData = values;
    processedData.kind = 'Upload';
    processedData.status = 'ACTIVE';
    processedData.url = fileLink;
    const params = {
      operation: ['SIGAM', 'Documents', 'Attachments', 'Add'],
      urlParams: {
        institutionId,
        organizationUnitId,
        documentId: propertys.document.id,
      },
      data: processedData,
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      message.success('Arquivo anexado com sucesso!');
      setFile();
      setFileLink();
      CloseDrawer();
      form.resetFields();
      if (propertys && propertys.GetDataAgain) {
        propertys.GetDataAgain();
      }
      propertys.GetAttachmentsAgain();
    } else {
      message.error('Alguma coisa aconteceu...');
    }
    return setLoadingAttachButton(false);
  }

  return (
    <div>
      {!file ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <UploadFile setFile={setFile} setFileLink={setFileLink} />
          <Text type="secondary">
            O arquivo deverá ser no formato PDF e ter no máximo 10 MB
          </Text>
        </div>
      ) : (
        <div>
          <FileButton link={fileLink} />
          <AttachmentForm form={form} file={file} />
          <div>
            <Button
              type="primary"
              loading={loadingAttachButton}
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    onFinish(values);
                  })
                  .catch((info) => {
                    // console.log('Validate Failed:', info);
                  });
              }}
            >
              Anexar documento
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
