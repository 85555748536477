import React, { useContext, memo } from 'react';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { LanguageContext } from '~/contexts/LanguageContext';
// Standalone components
import { LoadContainer, Spinner, LoadingIcon } from './style';

function AntLoading({ height, text, withoutText }) {
  const { theme } = useContext(ThemeContext);
  const { translate } = useContext(LanguageContext);
  return (
    <LoadContainer height={height || '300px'}>
      {withoutText ?
        <Spinner
          style={{ color: theme && theme.color && theme.color.primary }}
          color={theme && theme.color && theme.color.primary}
          indicator={<LoadingIcon color={theme && theme.color && theme.color.primary} spin />}
        />
        :
        <Spinner
          style={{ color: theme && theme.color && theme.color.primary }}
          color={theme && theme.color && theme.color.primary}
          indicator={<LoadingIcon color={theme && theme.color && theme.color.primary} spin />}
          tip={text || translate.messages.loading.text}
        />
      }

    </LoadContainer>
  );
}

export default memo(AntLoading);
