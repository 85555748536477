import React, { useState } from 'react';
// Libraries
import { PlusOutlined } from '@ant-design/icons';
import { Form, message } from 'antd';
import { useParams } from 'react-router-dom';
// Components
import Drawer from '~/components/drawer';
import Button from '~/components/button';
// Pages
import Creation from './pages/Creation';
import Finished from './pages/Finished';
// Actions
import Actions from '../../../Actions';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Header({ GetDataAgain }) {
    const [form] = Form.useForm();
    const { institutionId, organizationUnitId } = useParams();
    const [activePage, setActivePage] = useState('Creation');
    const [loadingCreationButton, setLoadingCreationButton] = useState(false);
    const [processCreated , setProcessCreated ] = useState();

    async function onFinishCreation(values) {
        setLoadingCreationButton(true);
        if (values.priority) {
            values.priority = 1;
        }else{
            values.priority = 0;
        }
        values.privacy = 'PUBLIC';
        values.status = 'ACTIVE';
        const params = {
            operation: ['SIGAM', 'Processes', 'Add'],
            urlParams: {
                institutionId: institutionId,
                organizationUnitId: organizationUnitId
            },
            data: values,
        };
        const response = await SocialMeAPI(params);
        console.log(response)
        if (response.success) {
            message.success("Processo criado com sucesso!");
            setProcessCreated (response.body.process);
            setActivePage('AccessLevel');
            form.resetFields();
            GetDataAgain();
        } else {
            message.error("Alguma coisa aconteceu...")
        }
        setLoadingCreationButton(false);
    }

    function Jump() {
        switch (activePage) {
            case 'AccessLevel':
                return setActivePage('Signatories');
            case 'Signatories':
                return setActivePage('Interested');
            case 'Interested':
                return setActivePage('Attachments');
            case 'Attachments':
                return setActivePage('Proceed');
            case 'Proceed':
                return setActivePage('Finished');
            default:
                return;
        }
    }

    function DrawerTitle() {
        switch (activePage) {
            case 'Creation':
                return 'Novo Processo'
            case 'Finished':
                return `${processCreated.name} ${processCreated.internal_id}`
            default:
                return `${processCreated.name} ${processCreated.internal_id} - ${Actions[activePage].text}`
        }
    }

    function DrawerWidth() {
        switch (activePage) {
            case 'Creation':
                return '100%'
            default:
                return `50%`
        }
    }

    function MainButton() {
        switch (activePage) {
            case 'Creation':
                return (
                    <Button
                        type="primary"
                        loading={loadingCreationButton}
                        onClick={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    onFinishCreation(values);
                                })
                                .catch((info) => {
                                    // console.log('Validate Failed:', info);
                                });
                        }}
                    >
                        Criar Processo
                    </Button>
                )
            default:
                return <Button type="primary" onClick={() => Jump()}>Próximo</Button>
        }

    }

    function DrawerContentComponent({ CloseDrawer }) {
        switch (activePage) {
            case 'Creation':
                return <Creation form={form} />
            case 'Finished':
                return <Finished CloseDrawer={CloseDrawer} setActivePage={setActivePage} GetDataAgain={GetDataAgain} />
            default:
                let Content = Actions[activePage].content;
                return <Content process={processCreated } />
        }
    }

    return (
        <Drawer
            Trigger={
                <Button icon={<PlusOutlined />} type="primary">
                    Novo Processo
                </Button>
            }
            title={DrawerTitle()}
            width={DrawerWidth()}
            DrawerContentComponent={DrawerContentComponent}
            footer={activePage === 'Finished' ? false : true}
            footerCancelButton
            footerMainButton={<MainButton />}
        />
    );
}
