import React, { useEffect, useContext, useState, useCallback } from 'react';
import PageManagerContext from './Context';
import { useParams } from 'react-router-dom';
import _usersGetTotal from '~/services/calls/dashboard/users/getTotals';
import _institutionGetTotal from '~/services/calls/dashboard/institution/getTotals';
import _processesGetTotal from '~/services/calls/dashboard/processes/getTotals';
import _documentsGetTotal from '~/services/calls/dashboard/documents/getTotals';
import _viewsGetTotal from '~/services/calls/dashboard/views/getTotals';
import _subjectsGetTotal from '~/services/calls/dashboard/institution/getTotalSubject';

export default function PageManagerProvider({ children }) {
  const { institutionId } = useParams();
  const [totalUsers, setTotalUsers] = useState();
  const [totalUsersLoading, setTotalUsersLoading] = useState(true);
  const [totalInstitution, setTotalInstitution] = useState();
  const [totalInstitutionLoading, setTotalInstitutionLoading] = useState(true);
  const [totalProcesses, setTotalProcesses] = useState();
  const [totalProcessesLoading, setTotalProcessesLoading] = useState(true);
  const [totalDocuments, setTotalDocuments] = useState();
  const [totalDocumentsLoading, setTotalDocumentsLoading] = useState(true);
  const [totalSubjects, setTotalSubjects] = useState();
  const [totalSubjectsLoading, setTotalSubjectsLoading] = useState(true);

  const subjectsGetTotal = useCallback(async (views) => {
    const response = await _subjectsGetTotal({ institutionId });
    if (response.success) {
      const elements = response.body.report.elements;
      let arrayData = [];
      let arrayCategories = [];
      for (let i = 0; i < elements.length; i++) {
        arrayData.push(elements[i].total);
        arrayCategories.push(elements[i].word);
        if (arrayData.length === 10) break;
      }
      setTotalSubjects({ data: arrayData, categories: arrayCategories });
      setTotalSubjectsLoading(false);
    }
  }, []);

  const documentsGetTotal = useCallback(async (views) => {
    const response = await _documentsGetTotal({ institutionId });
    if (response.success) {
      const report = response.body.report;
      setTotalDocuments({
        total: Number(report.total_doc_count),
        internal: Number(report.internal_doc_count),
        external: Number(report.external_doc_count),
        attachments: Number(report.attachment_doc_count),
        views: views,
      });
      setTotalDocumentsLoading(false);
    }
  }, []);

  const processesGetTotal = useCallback(async (views) => {
    const response = await _processesGetTotal({ institutionId });
    if (response.success) {
      const report = response.body.report;
      setTotalProcesses({
        total: Number(report.total_process_count),
        attachments: Number(report.attachment_process_count),
        views: views,
      });
      setTotalProcessesLoading(false);
    }
  }, []);

  const viewsGetTotal = useCallback(async () => {
    const response = await _viewsGetTotal({ institutionId });
    let views;
    if (response.success) {
      const report = response.body.report;
      views = {
        processes: Number(report.total_process_views),
        documents: Number(report.total_doc_views),
      };
    }
    return views;
  }, []);

  const institutionGetTotal = useCallback(async () => {
    const response = await _institutionGetTotal({ institutionId });
    if (response.success) {
      const report = response.body.report;
      setTotalInstitution({
        totalProcessesKinds: Number(report.total_process_kinds_count),
        totalUnities: Number(report.total_org_unities_count),
        totalDocModels: Number(report.total_doc_models_count),
      });
      setTotalInstitutionLoading(false);
    }
  }, []);

  const usersGetTotal = useCallback(async () => {
    const response = await _usersGetTotal({ institutionId });
    //console.log(response);
    if (response.success) {
      const report = response.body.report;

      setTotalUsers({
        total:
          Number(report.total_admins_count) +
          Number(report.total_public_agents_count),
        admins: Number(report.total_admins_count),
        agents: Number(report.total_public_agents_count),
        both: 10,
      });
      setTotalUsersLoading(false);
    }
  }, []);

  useEffect(() => {
    async function readAsyncData() {
      usersGetTotal();
      institutionGetTotal();
      subjectsGetTotal();
      const getViews = await viewsGetTotal();
      if (getViews) {
        processesGetTotal(getViews.processes);
        documentsGetTotal(getViews.documents);
      }
    }
    if (institutionId) {
      readAsyncData();
    }
  }, []);

  return (
    <PageManagerContext.Provider
      value={{
        totalUsers,
        totalUsersLoading,
        totalInstitution,
        totalInstitutionLoading,
        totalProcesses,
        totalProcessesLoading,
        totalDocuments,
        totalDocumentsLoading,
        totalSubjects,
        totalSubjectsLoading,
      }}
    >
      {children}
    </PageManagerContext.Provider>
  );
}

export const usePageManager = () => useContext(PageManagerContext);
