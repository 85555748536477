import React, { useContext, useEffect, useState } from 'react';
// Libraries
import { message } from 'antd';
// Contexts
import { SIGAMContext } from '~/contexts/SIGAMContext';
// Components
import Loading from '~/components/loading';
import Select from '~/components/select';
import Button from '~/components/button';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { CapitalizeString } from '~/utils/Transformations';

export default function Add({ record, insertedUnits, GetOrganizationalUnitsAgain }) {
    const { activeInstitution } = useContext(SIGAMContext);
    const [loadingContent, setLoadingContent] = useState(true);
    const [organizationalUnits, setOrganizationalUnits] = useState();
    const [selectedOrganizationalUnit, setSelectedOrganizationalUnit] = useState();

    async function AddUser() {
        const params = {
            operation: ['SIGAM', 'OrganizationalUnits', 'Users', 'Add'],
            urlParams: {
                institutionId: activeInstitution.id,
                organizationUnitId: selectedOrganizationalUnit,
            },
            data: {
                role: 'Programador',
                person: record.id,
            },
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            message.success(`${CapitalizeString(record.social_name || record.name)} adicionado na unidade com sucesso!`);
            GetOrganizationalUnitsAgain();
        } else {
            message.error('Usuário já se encontra na unidade selecionada');
        }
    }

    useEffect(() => {
        async function GetOrganizationalUnits() {
            const params = {
                operation: ['SIGAM', 'OrganizationalUnits', 'GetAll'],
                urlParams: {
                    institutionId: activeInstitution.id,
                },
                data: {
                    pagination: {
                        sort: {
                            by: 'id',
                            order: 'asc',
                        },
                        limit: 0
                    },
                },
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                let array = response.body.orgUnities.elements;
                let mainArray = []
                for (let i = 0; i < array.length; i++) {
                    let newItem = {}
                    newItem.label = array[i].name;
                    newItem.value = array[i].id;
                    mainArray.push(newItem);
                }
                setOrganizationalUnits(mainArray);
            }
            setLoadingContent(false);
        }
        GetOrganizationalUnits();
    }, [activeInstitution]);

    if (loadingContent) {
        return <Loading />
    }

    return (
        <div>
            <Select options={organizationalUnits} onChange={(value) => setSelectedOrganizationalUnit(value)} noDefaultValue />
            <Button type="primary" style={{ marginTop: 10 }} onClick={() => AddUser()}>Adicionar</Button>
        </div>
    )
}