import React from 'react';
import PageManagerProvider from './contexts/PageManager/Provider';
import Page from './page';

export default function Archives() {
  return (
    <PageManagerProvider>
      <Page />
    </PageManagerProvider>
  );
}
