import React, { useContext } from 'react';
// Libraries
import { DeploymentUnitOutlined } from '@ant-design/icons';
import { Form, message } from 'antd';
// Contexts
import { SIGAMContext } from '~/contexts/SIGAMContext';
// Own Components
import PermissionsForm from './components/PermissionsForm';
// Components
import Drawer from '~/components/drawer';
import Button from '~/components/button';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { CapitalizeString } from '~/utils/Transformations';

export default function Add({ setUpdatePending, record }) {
  const { activeInstitution } = useContext(SIGAMContext);
  const [form] = Form.useForm();

  const DrawerBody = () => {
    return <PermissionsForm form={form} record={record} />;
  };

  const EditButtonFooter = ({ CloseDrawer }) => {
    async function onFinish(data) {
      let processedData = [];
      for (const key in data) {
        if (data[key]) {
          processedData.push({ permission: key });
        }
      }
      const params = {
        operation: ['SIGAM', 'OrganizationalUnits', 'Permissions', 'Edit'],
        urlParams: {
          institutionId: activeInstitution.id,
          organizationUnitId: record.id,
        },
        data: {
          permissions: processedData,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        message.success('Permissões da Unidade atualizadas com sucesso!');
        CloseDrawer();
        return setTimeout(function () {
          setUpdatePending(true);
        }, 300);
      }
      return message.error('Alguma coisa aconteceu...');
    }
    return (
      <Button
        type="primary"
        onClick={() => {
          form
            .validateFields()
            .then((values) => {
              //form.resetFields();
              onFinish(values);
            })
            .catch((info) => {
              // console.log('Validate Failed:', info);
            });
        }}
      >
        Atualizar
      </Button>
    );
  };

  return (
    <Drawer
      Trigger={
        <Button icon={<DeploymentUnitOutlined />} type="dashed">
          Permissões
        </Button>
      }
      title={`Permissões de ${CapitalizeString(record.name)}`}
      width="50%"
      DrawerContentComponent={DrawerBody}
      footer
      footerCancelButton
      FooterMainButton={EditButtonFooter}
    />
  );
}
