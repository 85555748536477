import { message } from 'antd';
import SigamAPI from '~/utils/SigamAPI';

export default async function ShowArchiveFiles({
  fileId,
  archiveId,
  institutionId,
}) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/archive/${archiveId}/file/${fileId}`,
    method: 'GET',
  });

  if (!response.success) {
    message.error('Erro ao listas o arquivos');
  }

  return response;
}
