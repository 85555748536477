import React, { useContext, useEffect, useState } from 'react';
// Libraries
import { RiUserLine } from 'react-icons/ri';
import { FaPenSquare } from 'react-icons/fa';
import { Avatar, Badge } from 'antd';
import { useParams } from 'react-router-dom';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Tooltip from '~/components/tooltip';
import Loading from '~/components/loading';
import Drawer from '~/components/drawer';
import PersonDrawer from '~/pages/SIGAM/components/PersonDrawer/Public';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { CapitalizeString, DecodeURI } from '~/utils/Transformations';

export default function Signatories({ id }) {
  const { institutionId, organizationUnitId } = useParams();
  const { theme } = useContext(ThemeContext);
  const [people, setPeople] = useState([]);
  const [loadingContent, setLoadingContent] = useState(true);

  useEffect(() => {
    async function GetDocuments() {
      const params = {
        operation: ['SIGAM', 'Documents', 'Signatories', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: id,
        },
        data: {
          pagination: {
            sort: {
              by: 'id',
              order: 'asc',
            },
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        // console.log('signatories: ', response.body.signatory.elements)
        setPeople(response.body.signatory.elements);
      }
      setLoadingContent(false);
    }
    GetDocuments();
  }, [institutionId, organizationUnitId, id]);

  if (loadingContent) {
    return <Loading withoutText height="0px" />;
  }

  if (people.length === 0) {
    return (
      <Tooltip title="Sem assinantes">
        <Avatar
          style={{
            backgroundColor: theme.color.backgroundSecondaryDark,
          }}
          icon={<RiUserLine />}
        />
      </Tooltip>
    );
  }

  return (
    <Avatar.Group
      maxCount={4}
      maxStyle={{
        color: theme.color.textOnPrimaryBackground,
        backgroundColor: theme.color.primaryLight,
      }}
    >
      {people.map((item) => (
        <Drawer
          key={item.person_id}
          Trigger={
            <Tooltip
              title={CapitalizeString(
                item.person_social_name || item.person_name
              )}
              placement="top"
            >
              <div style={{ cursor: 'pointer' }}>
                <Badge
                  count={
                    <FaPenSquare
                      style={{ color: item.signature_id ? 'green' : 'red' }}
                    />
                  }
                  offset={[-5, 30]}
                >
                  <Avatar
                    src={DecodeURI(item.person_avatar)}
                    style={{
                      border: `2px solid ${
                        item.signature_id ? 'green' : 'red'
                      }`,
                    }}
                  />
                </Badge>
              </div>
            </Tooltip>
          }
          title={`${CapitalizeString(
            item.person_social_name || item.person_name
          )}`}
          DrawerContentComponent={PersonDrawer}
          DrawerContentComponentParams={{ person: item }}
        />
      ))}
    </Avatar.Group>
  );
}
