import API from '~/environments/APIs';

const Profile = {
  Get: {
    baseURL: API.socialMe,
    url: '/person/profile',
    method: 'get',
    timeout: '50000',
  },
  Edit: {
    baseURL: API.socialMe,
    url: '/person/profile',
    method: 'put',
    timeout: '50000',
  },
  GetProfilePercentage: {
    baseURL: API.socialMe,
    url: '/person/profile/percentage',
    method: 'get',
    timeout: '50000',
  },
  GetTimeline: {
    baseURL: API.socialMe,
    url: '/person/profile/timeline',
    method: 'post',
    timeout: '50000',
  },
  Password: {
    Change: {
      baseURL: API.socialMe,
      url: '/person/password/change',
      method: 'post',
      timeout: '50000',
    },
  },
  Address: {
    Get: {
      baseURL: API.socialMe,
      url: '/location/person/addresses',
      method: 'post',
      timeout: '50000',
    },
    Add: {
      baseURL: API.socialMe,
      url: '/location/person/address',
      method: 'post',
      timeout: '50000',
    },
    Edit: {
      baseURL: API.socialMe,
      url: '/location/person/address/addressId',
      method: 'put',
      timeout: '50000',
    },
    Delete: {
      baseURL: API.socialMe,
      url: '/location/person/address/addressId',
      method: 'delete',
      timeout: '50000',
    },
  },
  Avatar: {
    Change: {
      baseURL: API.socialMe,
      url: '/person/avatar',
      method: 'post',
      timeout: '50000',
    },
    GetAll: {
      baseURL: API.socialMe,
      url: '/person/avatars',
      method: 'post',
      timeout: '50000',
    },
    Delete: {
      baseURL: API.socialMe,
      url: '/person/avatar',
      method: 'delete',
      timeout: '50000',
    },
  },
  Document: {
    Upload: {
      baseURL: API.socialMe,
      url: '/person/personId/file/document/documentName',
      method: 'post',
      timeout: '50000',
    },
  },
  Contact: {
    Get: {
      baseURL: API.socialMe,
      url: '/contact/person/contacts',
      method: 'post',
      timeout: '50000',
    },
    Show: {
      baseURL: API.socialMe,
      url: '/contact/person/contact/contactId',
      method: 'post',
      timeout: '50000',
    },
    Add: {
      baseURL: API.socialMe,
      url: '/contact/person/contact',
      method: 'post',
      timeout: '50000',
    },
    Edit: {
      baseURL: API.socialMe,
      url: '/contact/person/contact/contactId',
      method: 'put',
      timeout: '50000',
    },
    Delete: {
      baseURL: API.socialMe,
      url: '/contact/person/contact/contactId',
      method: 'delete',
      timeout: '50000',
    },
  },
  Relantionship: {
    GetAll: {
      baseURL: API.socialMe,
      url: '/person/relationships',
      method: 'post',
      timeout: '50000',
    },
    Pending: {
      baseURL: API.socialMe,
      url: '/person/personId/relationship/requests',
      method: 'post',
      timeout: '50000',
    },
    Add: {
      baseURL: API.socialMe,
      url: '/person/relationship',
      method: 'post',
      timeout: '50000',
    },
    Delete: {
      baseURL: API.socialMe,
      url: '/person/relationship/relationshipId',
      method: 'delete',
      timeout: '50000',
    },
    Reject: {
      baseURL: API.socialMe,
      url: '/person/relationship/relationshipId/reject',
      method: 'put',
      timeout: '50000',
    },
    Approve: {
      baseURL: API.socialMe,
      url: '/person/relationship/relationshipId/approve',
      method: 'put',
      timeout: '50000',
    },
  },
  Roles: {
    Add: {
      Beneficiary: {
        baseURL: API.socialMe,
        url: '/role/beneficiary',
        method: 'post',
        timeout: '50000',
      },
      Giver: {
        baseURL: API.socialMe,
        url: '/role/giver',
        method: 'post',
        timeout: '50000',
      },
      Manager: {
        baseURL: API.socialMe,
        url: '/role/manager',
        method: 'post',
        timeout: '50000',
      },
      Partner: {
        baseURL: API.socialMe,
        url: '/role/partner',
        method: 'post',
        timeout: '50000',
      },
    },
  },
  Location: {
    Get: {
      baseURL: API.socialMe,
      url: '/location/places',
      method: 'post',
      timeout: '50000',
    },
    Add: {
      baseURL: API.socialMe,
      url: '/location/place',
      method: 'post',
      timeout: '50000',
    },
    Read: {
      baseURL: API.socialMe,
      url: '/location/place/placeId',
      method: 'get',
      timeout: '50000',
    },
    Edit: {
      baseURL: API.socialMe,
      url: '/location/place/placeId',
      method: 'put',
      timeout: '50000',
    },
    Delete: {
      baseURL: API.socialMe,
      url: '/location/place/placeId',
      method: 'delete',
      timeout: '50000',
    },
    LinkedAddresses: {
      List: {
        baseURL: API.socialMe,
        url: '/location/place/placeId/location/addresses',
        method: 'post',
        timeout: '50000',
      },
      Add: {
        baseURL: API.socialMe,
        url: '/location/place/placeId/location/address',
        method: 'post',
        timeout: '50000',
      },
      Edit: {
        baseURL: API.socialMe,
        url: '/location/place/placeId/location/address/addressId',
        method: 'put',
        timeout: '50000',
      },
      Delete: {
        baseURL: API.socialMe,
        url: '/location/place/placeId/location/address/addressId',
        method: 'delete',
        timeout: '50000',
      },
    },
  },
  DeviceLinking:{
    Read: {
      baseURL: API.socialMe,
      url: '/person/device/deviceId',
      method: 'post',
      timeout: '50000',
    },
    Link: {
      baseURL: API.socialMe,
      url: '/person/device',
      method: 'post',
      timeout: '50000',
    },
    List: {
      baseURL: API.socialMe,
      url: '/person/devices',
      method: 'post',
      timeout: '50000',
    },
    Edit: {
      baseURL: API.socialMe,
      url: '/person/device/deviceId',
      method: 'put',
      timeout: '50000',
    },
    Unlink: {
      baseURL: API.socialMe,
      url: '/person/device/deviceId',
      method: 'delete',
      timeout: '50000',
    },
  }
};

export default Profile;
