import API from '~/environments/APIs';

export default {
  GetPartnerBalance: {
    baseURL: API.socialMe,
    url: '/dw/account/partner/balance/detailed',
    method: 'post',
    timeout: '50000',
  },
  Accounts: {
    Login: {
      baseURL: API.socialMe,
      url: '/auth/dw/login',
      method: 'post',
      timeout: '50000',
    },
    Logout: {
      baseURL: API.socialMe,
      url: '/auth/dw/logout',
      method: 'post',
      timeout: '50000',
    },
    CheckToken: {
      baseURL: API.socialMe,
      url: '/auth/dw/is-auth',
      method: 'post',
      timeout: '50000',
    },
    Get: {
      baseURL: API.socialMe,
      url: '/dw/accounts',
      method: 'post',
      timeout: '50000',
    },
    ChangePassword: {
      baseURL: API.socialMe,
      url: '/dw/agencyNumber/accountNumber/first-setup',
      method: 'post',
      timeout: '50000',
    },
    Balance: {
      baseURL: API.socialMe,
      url: '/dw/account/balance',
      method: 'get',
      timeout: '50000',
    },
    Extract: {
      baseURL: API.socialMe,
      url: '/dw/account/balance/detailed/accountType',
      method: 'post',
      timeout: '50000',
    },
    SendManualDepositFile: {
      baseURL: API.socialMe,
      url: '/wallet/person/personId/account/accountNumber/manualDeposit/file',
      method: 'post',
      timeout: '50000',
    },
    ManualDeposit: {
      baseURL: API.socialMe,
      url: '/wallet/person/personId/account/accountNumber/manualDeposit',
      method: 'post',
      timeout: '50000',
    },
    VerifyProductService: {
      baseURL: API.socialMe,
      url: '/dw/account/balance/productServiceId',
      method: 'get',
      timeout: '50000',
    },
  },
  QRCodes: {
    Get: {
      baseURL: API.socialMe,
      url: '/qr-code/dw-account/accountType',
      method: 'post',
      timeout: '50000',
    },
    CreateForAccount: {
      baseURL: API.socialMe,
      url: '/dw/qr-code/account',
      method: 'post',
      timeout: '50000',
    },
    Add: {
      baseURL: API.socialMe,
      url: '/qr-code/dw-account-value/accountType',
      method: 'post',
      timeout: '50000',
    },
    Verify: {
      baseURL: API.socialMe,
      url: '/dw/qr-code/qrCodeId',
      method: 'get',
      timeout: '50000',
    },
  },
  Transfer: {
    CCtoCC: {
      baseURL: API.socialMe,
      url: '/dw/operation/cc-to-cc',
      method: 'post',
      timeout: '50000',
    },
  },
  Pay: {
    CCtoCC: {
      baseURL: API.socialMe,
      url: 'dw/operation/pay-cc-to-cc',
      method: 'post',
      timeout: '50000',
    },
  },
  Search: {
    Account: {
      baseURL: API.socialMe,
      url: '/dw/account',
      method: 'post',
      timeout: '50000',
    },
  },
  Admin: {
    GetPendingManualDeposits: {
      baseURL: API.socialMe,
      url: '/manage/wallet/manualDeposit/waiting',
      method: 'post',
      timeout: '50000',
    },
    ViewMangaiAccountBalance: {
      baseURL: API.socialMe,
      url: '/manage/wallet/system/balance',
      method: 'post',
      timeout: '50000',
    },
  },
  SocialMeAccount: {
    Get: {
      baseURL: API.socialMe,
      url: '/manage/wallet/socialme/accounts',
      method: 'post',
      timeout: '50000',
    },
    ChangePassword: {
      baseURL: API.socialMe,
      url: '/manage/wallet/account/accountNumber/setup',
      method: 'post',
      timeout: '50000',
    },
  },
  ManualRequests: {
    Get: {
      baseURL: API.socialMe,
      url: '/dw/account/operation/manuals',
      method: 'post',
      timeout: '50000',
    },
  },
};
