import SigamAPI from '~/utils/SigamAPI';

export default async function DeleteMessage({
  messageId,
  chatId,
  institutionId,
}) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/chat/${chatId}/message/${messageId}`,
    method: 'DELETE',
  });

  return response;
}
