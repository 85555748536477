import SigamAPI from '~/utils/SigamAPI';

export default async function UpdateTemplate({
  institutionId,
  templateId,
  data,
}) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/communication-model/${templateId}`,
    method: 'PATCH',
    data: data,
  });

  return response;
}
