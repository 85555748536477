export default {
  SM0ACEA3C: {
    message: 'The address was successfully deleted',
    //Profile.Address.Delete
  },
  SM5753FD0: {
    message: 'The contact was successfully deleted',
    //Profile.Contact.Delete
  },
  SM783805B: {
    message: "The 'file' was successfully deleted",
    //Profile.Avatar.Delete
  },
  SM7E62414: {
    message: "The 'device' was successfully deleted",
  },
  SMFB224F5: {
    message: "The 'Custom question' was successfully deleted",
  },
  SME05CB65: {
    message: "The 'document model' was successfully deleted",
  },
  SMCA9C436: {
    message: "The 'SIGAM grouped person' was successfully deleted"
  },
  SMDBE917C: {
    message: "The 'SIGAM public agent' was successfully deleted"
  },
  SMC6EED91: {
    message: "The 'assignment' was successfully deleted"
  },
  SM4F81C0E: {
    message: "The 'signature' was successfully deleted"
  },
  SM9BCF0E0: {
    message:  "The 'Password recovery token' was successfully deleted"
  }
};
