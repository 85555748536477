import React from 'react';
import { Collapse } from 'antd';
// Components
import Text from '~/components/typography/text';

import ShowStatus from './components/ShowStatus';
import StatusInformation from './status';

const { Panel } = Collapse;

export default function Status() {

    function GetProcess(data) {
        function isFinish(item) {
            if (item.finish) {
                return true;
            }
            return false;
        }
        let total = data.items;
        let totalFinish = total.filter(isFinish);
        let percentage = (100 * totalFinish.length) / total.length;

        return (
            <Text style={{ color: percentage === 100 ? 'green' : 'auto' }}>{`${percentage.toFixed(2)}%`}</Text>
        )
    }

    return (
        <div>
            <Collapse bordered={false}>
                {StatusInformation.map((status, index) => (
                    <Panel header={status.title} key={index} extra={GetProcess(status)}>
                        <ShowStatus data={status} />
                    </Panel>
                ))}
            </Collapse>
        </div>
    )
}