import React from 'react';
// Libraries
import { message, Popconfirm } from 'antd';
import { useParams } from 'react-router-dom';
import Animate, { FadeIn } from 'animate-css-styled-components';
// Components
import Button from '~/components/button';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Close({ process, propertys, CloseDrawer }) {
    const { institutionId, organizationUnitId } = useParams();

    let processInformation = process || propertys.process[0];

    async function CloseProcess() {
        const params = {
            operation: ['SIGAM', 'Processes', 'Edit'],
            urlParams: {
                institutionId: institutionId,
                organizationUnitId: organizationUnitId,
                processId: processInformation.id
            },
            data: {
                status: 'INACTIVE'
            }
        };
        console.log(params)
        const response = await SocialMeAPI(params);
        console.log(response)
        if (response.success) {
            message.success('Processo encerrado com sucesso!');
        } else {
            message.error('Alguma coisa aconteceu...');
        }
    }

    return (
        <div>
            <Animate
                Animation={[FadeIn]}
                duration={['0.5s']}
                delay={['0.2s']}
            >
                <Popconfirm
                    title="Você tem certeza que deseja encerrar o processo?"
                    onConfirm={CloseProcess}
                    okText="Sim, encerrar"
                    cancelText="Não"
                >
                    <div>
                        <Button
                            type="primary"
                            style={{ width: '100%', margin: '10px 0px' }}
                            size="large"
                        >
                            Encerrar Processo
                </Button>
                    </div>
                </Popconfirm>

            </Animate>
        </div>
    )
}