import React from 'react';
// Libraries
import { Route, Switch, Redirect } from 'react-router-dom';
// Pages
import Valid from './pages/Valid';
import Invalid from './pages/Invalid';

export default function PasswordRecovery() {

    //! Quando estiver logado
    
    return (
        <Switch>
            <Route path={`/esqueci-senha/valido`} component={Valid} />
            <Route path={`/esqueci-senha/invalido`} component={Invalid} />
            <Route path={`*`}>
                <Redirect to="/" />
            </Route>
        </Switch>
    )
}