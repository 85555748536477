import React, { useContext } from 'react';
// Libraries
import { Link, useRouteMatch } from 'react-router-dom';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Card from '~/components/card';
import Text from '~/components/typography/text';
// Utils
import { CapitalizeString } from '~/utils/Transformations';

export default function UnitCard({ item }) {
    const { theme } = useContext(ThemeContext);
    let { url } = useRouteMatch();
    return (
        <div style={{ padding: 10 }}>
            <Link to={`${url}/${item.id}`}>
                <Card
                    style={{
                        border: `2px solid ${theme.color.borderColor}`,
                        cursor: 'pointer',
                    }}
                >
                    <Text strong style={{ textAlign: 'center' }}>{CapitalizeString(item.name)}</Text>
                </Card>
            </Link>
        </div>
    )
}