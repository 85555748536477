import React, { useContext, useState } from 'react';
import PageManagerContext from './Context';

export default function PageManagerProvider({ children }) {
  const [selectedProcessId, setSelectedProcessId] = useState(null);

  return (
    <PageManagerContext.Provider
      value={{ selectedProcessId, setSelectedProcessId }}
    >
      {children}
    </PageManagerContext.Provider>
  );
}

export const usePageManager = () => useContext(PageManagerContext);
