import styled from 'styled-components';

export const Container = styled.div`
  padding: 5px;
  background-color: ${(props) => props.backgroundcolor};
`;

export const Content = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent ? props.justifyContent : 'space-between'};
  align-items: center;
  padding: 0px 5px;
  margin-top: ${(props) => props.marginTop};
`;

export const LeftItems = styled.div``;

export const RightItems = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
