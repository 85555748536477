import styled from 'styled-components';

const Flex = styled.div`
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export default Flex
