import React, { useMemo } from 'react';
import { Typography } from 'antd';

import Loading from '~/components/loading';
import ContactToChatGroupItem from '../../../../components/ContactToChatGroupItem';
import { useMessagerManager } from '../../../../../../contexts/MessagerManager/Provider';
import useChatParticipants from '../../../../hooks/useChatParticipants';
import * as S from './style';

const { Title } = Typography;

export default function Contacts({ contacts, loadingContacts }) {
  const { selectedChat } = useMessagerManager();

  const { chatParticipants, setChatParticipants } =
    useChatParticipants(selectedChat);

  const chatParticipantsIds = useMemo(() => {
    return chatParticipants.map(({ person_id }) => person_id);
  }, [chatParticipants]);

  function isContactInGroup(currentContact) {
    return chatParticipantsIds.some((participantId) => {
      return participantId === currentContact.id;
    });
  }

  if (loadingContacts) {
    return <Loading height="50%" text="Carregando contatos" />;
  }

  if (contacts.length === 0) {
    return (
      <S.Center>
        <Title level={3}>Nenhum contato encontrado</Title>
      </S.Center>
    );
  }

  return (
    <>
      {contacts.map((contact) => (
        <ContactToChatGroupItem
          key={contact.id}
          chatParticipantsIds={chatParticipantsIds}
          isContactInGroup={isContactInGroup(contact)}
          setChatParticipants={setChatParticipants}
          contact={contact}
        />
      ))}
    </>
  );
}
