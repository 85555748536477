import React, { useMemo } from 'react';
import { Drawer, Descriptions } from 'antd';

import { useTheme } from '~/contexts/ThemeContext';

export default function DetailsContainer({ archive, open, setOpen }) {
  const { theme } = useTheme();
  const onClose = () => {
    setOpen(false);
  };

  const visibility = useMemo(() => {
    return archive.visibility === 'PRIVATE' ? 'Privado' : 'Público';
  }, [archive.visibility]);

  const status = useMemo(() => {
    return archive.status === 'ACTIVE' ? 'Ativo' : 'Inativo';
  }, [archive.status]);

  return (
    <Drawer
      title={`Acervo: ${archive.name}`}
      placement="right"
      onClose={onClose}
      open={open}
    >
      <Descriptions
        size="small"
        labelStyle={{ fontWeight: 'bold', color: theme.color.primary }}
        layout="vertical"
        column={1}
      >
        <Descriptions.Item label="Nome">{archive.name}</Descriptions.Item>
        <Descriptions.Item label="Descrição">
          {archive.description}
        </Descriptions.Item>
        <Descriptions.Item label="Visibilidade">{visibility}</Descriptions.Item>
        <Descriptions.Item label="Status">{status}</Descriptions.Item>
        <Descriptions.Item label="Metatags">
          {archive.metas.join(', ')}
        </Descriptions.Item>
      </Descriptions>
    </Drawer>
  );
}
