import React, { useContext } from 'react';
//Libraries
import { UserOutlined, CloseOutlined } from '@ant-design/icons';
//Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Tooltip from '~/components/tooltip';
import Input from '~/components/input';
import FormItem from '~/components/form/FormItem';
// Standalone Components
import { Container } from './style';

export default function CPFContainer({ login, setStep }) {
  const { translate } = useContext(LanguageContext);
  const { theme } = useContext(ThemeContext);
  return (
    <Container>
      <FormItem
        item={
          <Input
            prefix={<UserOutlined />}
            label={translate.fields.label.CPF.text}
            value={login}
            disabled
            suffix={
              <Tooltip title={translate.authentication.notMyCPF.text}>
                <CloseOutlined
                  onClick={() => setStep('Verification')}
                  style={{
                    cursor: 'pointer',
                    color: theme.color.textOnSecondaryBackground,
                  }}
                />
              </Tooltip>
            }
          />
        }
      />
    </Container>
  );
}
