import React from 'react';
// Libraries
import { useParams } from 'react-router-dom';
import { CrownOutlined } from '@ant-design/icons';
// Components
import Divider from '~/components/divider';
import Button from '~/components/button';
// Utils
import { CapitalizeString } from '~/utils/Transformations';
import SocialMeAPI from '~/utils/SocialMeAPI';
import { message } from 'antd';

export default function AdminButton({ record,setUpdatePending }) {
    const { institutionId } = useParams();

    async function AddAdmin() {
        const params = {
            operation: ['SIGAM', 'Users', 'AddAdmin'],
            urlParams: {
                institutionId: institutionId,
            },
            data: {
                person: record.id
            },
        };
        const response = await SocialMeAPI(params);
        console.log(response)
        if (response.success) {
            setUpdatePending(true);
            return message.success('Permissão de administrador adicionada com sucesso!');
        }
        return message.error('Alguma coisa aconteceu...')
    }

    return (
        <div>
            <Divider />
            {record.is_admin ?
                <Button
                    type="dashed"
                    style={{ width: '100%' }}
                    disabled
                >
                    {CapitalizeString(record.name)} é um Administrador
                </Button>
                :
                <Button
                    type="dashed"
                    style={{ width: '100%' }}
                    onClick={() => AddAdmin()}
                    icon={<CrownOutlined />}
                >
                    Tornar {CapitalizeString(record.name)} Administrador
                </Button>
            }
        </div>
    )
}