import React, { useState } from 'react';
// Libraries
import { message } from 'antd';
import { useParams } from 'react-router-dom';
// Components
import Input from '~/components/input';
import Space from '~/components/space';
import Text from '~/components/typography/text';
import Button from '~/components/button';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { UserMainDocument } from '~/utils/UserInformation';

export default function ByPassword({ document, signature, GetPeopleAgain }) {
  const { institutionId, organizationUnitId } = useParams();
  const [password, setPassword] = useState();
  const [loadingButton, setLoadingButton] = useState(false);
  let mainDocument = UserMainDocument();

  async function Sign() {
    setLoadingButton(true);
    const params = {
      operation: ['SIGAM', 'Documents', 'Subscriptions', 'ByPassword'],
      urlParams: {
        institutionId: institutionId,
        organizationUnitId: organizationUnitId,
        documentId: document.id,
        subscriptionId: signature.id,
      },
      data: {
        login: mainDocument.replace(/[^\d]+/g, ''),
        password: password,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      message.success('Documento assinado com sucesso!');
      GetPeopleAgain();
    } else {
      message.error('Alguma coisa aconteceu...');
      setLoadingButton(false);
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Space direction="vertical">
        <Text>Assinatura por autenticação</Text>
        <Input value={mainDocument} disabled />
        <Input
          type="password"
          onChange={(event) => setPassword(event.target.value)}
        />
        <Button
          type="primary"
          style={{ width: '100%' }}
          onClick={() => Sign()}
          loading={loadingButton}
        >
          Assinar
        </Button>
      </Space>
    </div>
  );
}
