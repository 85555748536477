import React, { useState } from 'react';
import { Button } from 'antd';

import { useMessagerManager } from '../../../../../../../../contexts/MessagerManager/Provider';
import CreateChat from '~/services/calls/messager/chat/create';
import DeleteChat from '~/services/calls/messager/chat/delete';
import ContactInfo from '../../../../../../components/ContactContent';

import * as S from './style';

export default function AddChat({ setChats, createdChat, contact }) {
  const [loading, setLoading] = useState(false);

  const { institutionId } = useMessagerManager();

  function formatNewChat(response) {
    const recentCreateChat = response.body.chat_group;
    recentCreateChat.person_name = contact.name;
    recentCreateChat.person_avatar = contact.avatar;
    recentCreateChat.person_id = contact.id;

    return recentCreateChat;
  }

  async function HandleCreateChat() {
    setLoading(true);
    const person_id = contact.id;
    const response = await CreateChat({ person_id, institutionId });

    if (response.success) {
      let newChat = formatNewChat(response);

      setChats((currentCharts) => {
        return [...currentCharts, newChat];
      });
    }
    setLoading(false);
  }

  async function HandleDeleteChat() {
    setLoading(true);

    const chatId = createdChat.id;
    const response = await DeleteChat({ chatId, institutionId });
    if (response.success) {
      setChats((currentCharts) => {
        return currentCharts.filter((chat) => chat.id !== chatId);
      });
    }
    setLoading(false);
  }

  return (
    <S.Container>
      <ContactInfo contact={contact} />

      <S.Center>
        {createdChat ? (
          <Button
            loading={loading}
            type="primary"
            danger
            onClick={HandleDeleteChat}
          >
            Excluir
          </Button>
        ) : (
          <Button loading={loading} type="primary" onClick={HandleCreateChat}>
            Vincular
          </Button>
        )}
      </S.Center>
    </S.Container>
  );
}
