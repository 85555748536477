import React, { useContext } from 'react';
// Libraries
import { CheckOutlined } from '@ant-design/icons';
import { QrcodeOutlined } from '@ant-design/icons';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Own Components
import QRCodeReaderDocumentSimple from '~/pages/SIGAM/components/qrcode/ReaderDocumentSimple';
// Components
import Button from '~/components/button';
import Input from '~/components/input';
import Text from '~/components/typography/text';

// 43-2021/0000102806
// TNWHCZU

export default function Content({
    disableButton,
    loadingButton,
    Validate,
    code,
    number,
    HandleChangeNumber,
    HandleChangeCode,
    setNumber
}) {
    const { theme } = useContext(ThemeContext);
    return (
        <div>
            <div
                style={{
                    border: '1px solid',
                    borderColor: theme.color.borderColor,
                    borderRadius: 8
                }}
            >
                <div
                    style={{
                        padding: 20,
                        backgroundColor: theme.color.primary,
                        borderTopRightRadius: 8,
                        borderTopLeftRadius: 8,
                    }}
                >
                    <Text style={{ textAling: 'center', color: theme.color.textOnPrimaryBackground }}>AUTENTICIDADE DE DOCUMENTOS</Text>
                </div>
                <div
                    style={{
                        padding: 20,
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        backgroundColor: theme.color.secondary
                    }}
                >
                    <div>
                        <Text>Número do documento</Text>
                        <Input
                            value={number}
                            onChange={HandleChangeNumber()}
                            suffix={
                                <QRCodeReaderDocumentSimple
                                    Trigger={
                                        <Button icon={<QrcodeOutlined />} type="dashed">Ler QR Code</Button>
                                    }
                                    title="Ler QR Code do Documento"
                                    description="Use seu dispositivo para ler o QR Code do documento"
                                    setValue={setNumber}
                                />
                            }
                        />
                    </div>
                    <div style={{ margin: '20px 0px' }}>
                        <Text>Código de verificação</Text>
                        <Input value={code} onChange={HandleChangeCode()} />
                    </div>
                    <div>
                        <Button
                            style={{ width: '100%' }}
                            type="primary"
                            icon={<CheckOutlined />}
                            loading={loadingButton}
                            // disabled={disableButton}
                            onClick={() => Validate()}
                        >
                            Validar
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}