import React from 'react';
import AchieveFilesPageManager from './contexts/ArchiveFilesPageManager/Provider';
import Page from './page';

export default function Archive() {
  return (
    <AchieveFilesPageManager>
      <Page />
    </AchieveFilesPageManager>
  );
}
