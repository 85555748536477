import React from 'react';
// Own Components
import ProcessView from './components/ProcessView';
import AttachmentView from './components/AttachmentView';
import ExternalView from './components/ExternalView';
import DocumentView from './components/DocumentView';

export default function FileView({ document,  fatherProcess }) {
console.log('document: ', document)
console.log('fatherProcess: ', fatherProcess)
  return (
    <>
      {document.type === 'document' ? (
        <DocumentView document={document} />
      ) : document.type === 'attachment' ? (
        document.url ? (
          <ExternalView document={document} />
        ) : (
          <AttachmentView
            document={document}
            fatherProcess={fatherProcess}
          />
        )
      ) : (
        <ProcessView process={fatherProcess} />
      )}
    </>
  );
}

/* 

<ExternalView document={document} />
                :
                <AttachmentView document={document} />

*/
