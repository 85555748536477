import React, { useState, useEffect } from 'react';
// Libraries
import { message } from 'antd';
import { useParams } from 'react-router-dom';
import { SearchOutlined, SendOutlined } from '@ant-design/icons';
import Animate, { FadeIn } from 'animate-css-styled-components';
// Own Components
import List from './components/List';
// Components
import Input from '~/components/input';
import Loading from '~/components/loading';
import Card from '~/components/card';
import Text from '~/components/typography/text';
import Divider from '~/components/divider';
import Button from '~/components/button';
import TextArea from '~/components/textArea';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { CapitalizeString } from '~/utils/Transformations';

export default function Proceed({ process, propertys, CloseDrawer }) {
    const { institutionId, organizationUnitId } = useParams();
    const [visible, setVisible] = useState(false);
    const [inputValue, setInputValue] = useState();
    const [result, setResult] = useState();
    const [loadingResult, setLoadingResult] = useState(true);
    const [units, setUnits] = useState([]);
    const [groups, setGroups] = useState([]);
    const [people, setPeople] = useState([]);
    const [specifications, setSpecifications] = useState();

    let processInformation = process || propertys.process[0];

    async function SelectPerson(person) {
        const have = people.filter((item) => {
            return item.id === person.id;
        })
        if (have.length === 0) {
            setPeople(people => people.concat(person));
            setInputValue();
        } else {
            message.error('Essa pessoa já foi selecionada')
        }
    }
    async function SelectUnity(unity) {
        const have = units.filter((item) => {
            return item.id === unity.id;
        })
        if (have.length === 0) {
            setUnits(units => units.concat(unity));
            setInputValue();
        } else {
            message.error('Essa unidade já foi selecionada')
        }

    }
    async function SelectGroup(group) {
        const have = groups.filter((item) => {
            return item.id === group.id;
        })
        if (have.length === 0) {
            setGroups(groups => groups.concat(group));
            setInputValue();
        } else {
            message.error('Esse grupo já foi selecionado')
        }
    }

    async function ProceedingProcess() {
        let peopleArray = [];
        let groupsArray = [];
        let unitsArray = [];
        for(let i = 0; i < people.length; i++){
            let newItem = {};
            newItem.person = people[i].id;
            newItem.specifications = specifications;
            peopleArray.push(newItem);
        }
        for(let i = 0; i < groups.length; i++){
            let newItem = {};
            newItem.group = groups[i].id;
            newItem.specifications = specifications;
            groupsArray.push(newItem);
        }
        for(let i = 0; i < units.length; i++){
            let newItem = {};
            newItem.organizational_unity = units[i].id;
            newItem.specifications = specifications;
            unitsArray.push(newItem);
        }
        let data = {};
        data.people = peopleArray;
        data.groups = groupsArray;
        data.organizational_unities = unitsArray;
        const params = {
            operation: ['SIGAM', 'Processes', 'Proceed'],
            urlParams: {
                institutionId: institutionId,
                organizationUnitId: organizationUnitId,
                processId: processInformation.id
            },
            data: data
        };
        const response = await SocialMeAPI(params);
        console.log('response -> ', response)
        if(response.success){
            message.success('Processo tramitado com sucesso!');
            setUnits([]);
            setGroups([]);
            setPeople([]);
            setSpecifications();
            CloseDrawer();
        }else{
            message.error('Alguma coisa aconteceu...');
        }
    }

    function DisabledButton(){
        if(people.length > 0 || units.length > 0 || groups.length > 0){
            if(specifications && specifications.length){
                return false;
            }
            return true;
        }
        return true
    }

    useEffect(() => {
        async function Search() {
            setLoadingResult(true);
            const params = {
                operation: ['SIGAM', 'Institution', 'Search'],
                urlParams: {
                    institutionId: institutionId,
                },
                data: {
                    filter: {
                        searchString: inputValue
                    },
                    pagination: {
                        limit: 3
                    }
                }
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                setResult(response.body.institution);
                setLoadingResult(false);
            }
        }
        if (inputValue) {
            Search();
        }
    }, [inputValue, institutionId])

    return (
        <div>
            <Animate
                Animation={[FadeIn]}
                duration={['0.5s']}
                delay={['0.2s']}
            >
                <List
                    people={people}
                    setPeople={setPeople}
                    units={units}
                    setUnits={setUnits}
                    groups={groups}
                    setGroups={setGroups}
                />
                <Input
                    value={inputValue}
                    onChange={(event) => setInputValue(event.target.value)}
                    onFocus={() => setVisible(true)}
                    prefix={<SearchOutlined />}
                />
                {inputValue &&
                    <Card
                        style={{
                            width: '100%',
                            display: visible ? 'block' : 'none',
                            marginTop: 5
                        }}
                        bodyStyle={{ padding: 10, transition: 'all 0.2s' }}
                    >
                        {loadingResult ?
                            <Loading />
                            :
                            <div>
                                <div style={{ marginBottom: 10 }}>
                                    <Divider style={{ margin: 0 }}>Pessoas</Divider>
                                    {result && result.PF && result.PF.elements.map(item => (
                                        <div
                                            key={`person-${item.id}`}
                                            onClick={() => SelectPerson(item)}
                                            style={{
                                                cursor: 'pointer',
                                                height: '32px',
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Text>{CapitalizeString(item.social_name || item.name)}</Text>
                                        </div>
                                    ))}
                                </div>
                                <div style={{ marginBottom: 10 }}>
                                    <Divider style={{ margin: 0 }}>Unidades</Divider>
                                    {result && result['ORG-UNITY'] && result['ORG-UNITY'].elements.map(item => (
                                        <div
                                            key={`unity-${item.id}`}
                                            onClick={() => SelectUnity(item)}
                                            style={{
                                                cursor: 'pointer',
                                                height: '32px',
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Text>{CapitalizeString(item.name)}</Text>
                                        </div>
                                    ))}
                                </div>
                                <div style={{ marginBottom: 10 }}>
                                    <Divider style={{ margin: 0 }}>Grupos</Divider>
                                    {result && result['GROUP'] && result['GROUP'].elements.map(item => (
                                        <div
                                            key={`group-${item.id}`}
                                            onClick={() => SelectGroup(item)}
                                            style={{
                                                cursor: 'pointer',
                                                height: '32px',
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Text>{CapitalizeString(item.name)}</Text>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                    </Card>
                }
                <div style={{ margin: '10px 0px' }}>
                    <Text>Messagem:</Text>
                    <TextArea value={specifications} onChange={(event) => setSpecifications(event.target.value)} />
                </div>
                <Button
                    type="primary"
                    style={{ width: '100%', margin: '10px 0px' }}
                    icon={<SendOutlined />}
                    onClick={() => ProceedingProcess()}
                    disabled={DisabledButton()}
                >
                    Tramitar
                </Button>
            </Animate>
        </div>
    )
}