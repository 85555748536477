import React, { useEffect, useState } from 'react';
// Utils
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { Typography } from 'antd';
import Button from '~/components/button';
import { CapitalizeString } from '~/utils/Transformations';
import Loading from '~/components/loading/';
import {
  DateInBrazilianFormat,
  HourInBrazilianFormat,
} from '~/utils/DateAndTime';
import getHTML from './utils/getHTML';
import returnUrl from '~/services/calls/pdf/returnUrl';

const { Text } = Typography;

export default function PDFViewer({
  fatherDocument,
  attachments,
  timelineItems,
}) {
  const [loading, setLoading] = useState(true);
  const [pdf, setPDF] = useState();
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    // console.log('carregou')
    setLoading(false);
  }

  useEffect(() => {
    async function getPDF(HTML) {
      const response = await returnUrl({ html: HTML });
      if (response.success) {
        setPDF(response?.body?.file);
      }
      setLoading(false);
    }

    async function getPDFMakeObj() {
      const subscribers = [];

      if (timelineItems && timelineItems.length > 0) {
        for (let i = 0; i < timelineItems.length; i++) {
          if (
            timelineItems[i].action !== 'CREATE' &&
            timelineItems[i].kind !== 'DIRECT CHANGE'
          ) {
            const newItem = {
              person_name: `${CapitalizeString(timelineItems[i].person_name)}`,
              date: DateInBrazilianFormat(
                timelineItems[i].signature_created_at
              ),
              hour: HourInBrazilianFormat(
                timelineItems[i].signature_created_at
              ),
            };
            subscribers.push(newItem);
          }
        }
      }

      const HTML = await getHTML({
        fatherDocument,
        subscribers,
        attachments,
      });
      getPDF(HTML);
    }

    if (fatherDocument && timelineItems) {
      setLoading(true);
      setPDF();
      getPDFMakeObj();
    }
  }, [fatherDocument, timelineItems]);

  return (
    <div>
      {loading ? (
        <Loading text="Criando o documento. Isso pode demorar um pouco." />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ margin: '0px 0px 5px 0px' }}>
            <a href={pdf} target="_blank" rel="noopener noreferrer">
              <Button type="dashed">Imprimir Documento</Button>
            </a>
          </div>
          {pdf ? (
            <div style={{ backgroundColor: '#d9d9d9', padding: 10 }}>
              <Document
                file={{ url: pdf }}
                options={{ workerSrc: '/pdf.worker.js' }}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={<Loading text="Criando documento" />}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <div
                    key={`page_${index + 1}`}
                    style={{
                      backgroundColor: 'white',
                      borderBottom: '6px solid #d9d9d9',
                    }}
                  >
                    <Page pageNumber={index + 1} />
                  </div>
                ))}
              </Document>
            </div>
          ) : (
            <div>
              <Text
                style={{
                  padding: 10,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Alguma coisa não funcionou como deveria, tente novamente mais
                tarde
              </Text>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
