import React, { useContext, useState } from 'react';
// Libraries
import { UserOutlined, MailOutlined } from '@ant-design/icons';
import { message } from 'antd';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
// Components
import Button from '~/components/button';
import Input from '~/components/input';
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
// Environments
import System from '~/environments/System';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { cpfMaskContinuos, cnpjMask } from '~/utils/Masks';
import { validateCPF, validateCNPJ, isEmail } from '~/utils/Validations';

export default function RecoverPassword({ login, setLogin, setStep, setPerson, person }) {
    const { translate } = useContext(LanguageContext);
    const [loadingButton, setLoadingButton] = useState();
    const [email, setEmail] = useState();
    const [successfullyCreatedToken, setSuccessfullyCreatedToken] = useState(false);
    const [token, setToken] = useState();

    const HandleChangeLogin = () => (event) => {
        if (event.target.value.length > 18) {
            return;
        }
        if (event.target.value.length <= 14) {
            return setLogin(cpfMaskContinuos(event.target.value));
        } if (event.target.value.length > 14) {
            return setLogin(cnpjMask(event.target.value));
        }
    };

    async function RequestToken() {
        const params = {
            operation: ['Auth', 'RedefinePassword', 'RequestToken'],
            data: {
                login: login.replace(/[^\d]+/g, ''),
                main_contact: email,
                origin: 'SIGAM'
            },
        };
        const response = await SocialMeAPI(params);
        // console.log(response)
        if (response.success) {
            message.success('Solicitação criada com sucesso!');
            setSuccessfullyCreatedToken(true);
        } else {
            message.error('Verifique o email digitado e tente novamente')
        }
        setLoadingButton(false);
    }

    async function Verify() {
        setLoadingButton(true);
        if (!isEmail(email)) {
            message.error('O email inserido é inválido');
            setLoadingButton(false);
        }
        const params = {
            operation: ['Auth', 'Verify'],
            data: {
                login: login.replace(/[^\d]+/g, ''),
                main_contact: email
            },
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            if (response.message === 'No person with this login was found') {
                if (!validateCPF(login) && login.length === 14) {
                    message.error(translate.fields.invalid.CPF.text);
                } else if (!validateCNPJ(login) && login.length === 18) {
                    message.error(translate.fields.invalid.CNPJ.text);
                } else if (login.length === 18) {
                    setStep('Register Legal Person');
                } else {
                    setStep('Register Physical Person');
                }
                return setLoadingButton(false);
            }
            if (response.message === 'A person with this login was found') {
                setPerson(response.body.person);
                await RequestToken();
                return setLoadingButton(false);
            }
            if (response.message === 'Pre-registration found') {
                setStep('Register Physical Person');
                return setLoadingButton(false);
            }
            if (response.message === 'Login parameter not sent') {
                return null;
            }
        }
    }

    async function VerifyPasswordToken() {
        setLoadingButton(true);
        const params = {
            operation: ['Auth', 'RedefinePassword', 'VerifyToken'],
            urlParams: {
                tokenId: token,
                personId: person.id
            }
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
           return window.location.replace(`https://${System.production ? System.hostname.SIGAMApp : System.hostname.SIGAMDev}/esqueci-senha/valido/?tkn=${token}&pd=${person.id}`)
        }
        message.error('O código inserido está inválido ou expirou')
        setLoadingButton(false);
    }

    function DisabledButton() {
        if (login && email) {
            if (login.length === 14 || login.length === 18) {
                return false
            }
            return true
        }
        return true
    }

    if (successfullyCreatedToken) {
        return (
            <div>
                <Text style={{ textAlign: 'center' }}>Digite abaixo o código recebido no seu email</Text>
                <Input
                    // maxLength={7}
                    value={token}
                    onChange={(event) => setToken(event.target.value.replace(/\s/g, ""))}
                />
                <Button
                    loading={loadingButton}
                    onClick={() => VerifyPasswordToken()}
                    disabled={!(token && token.length > 6)}
                    style={{ width: '100%', marginTop: 10 }}
                    type="primary"
                    size="large"
                >
                    Validar
                </Button>
            </div>
        )
    }

    return (
        <div>
            <Title level={4} style={{ textAlign: 'center' }}>
                Redefinir senha
            </Title>
            <Text type="secondary">
                Insira seu CPF ou CNPJ no campo abaixo junto com seu email principal para dar continuidade na redefinição da sua senha.
            </Text>
            <Input
                margin="20px 0px 10px"
                label={
                    login.length === 14
                        ? translate.fields.label.CPF.text
                        : login.length > 14
                            ? translate.fields.label.CNPJ.text
                            : translate.fields.label.CPFOrCNPJ.text
                }
                outFormItem
                prefix={<UserOutlined />}
                onChange={HandleChangeLogin()}
                value={login}
                size="large"
            />
            <Input
                margin="20px 0px 10px"
                label="Email Principal"
                outFormItem
                prefix={<MailOutlined />}
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                size="large"
                type="email"
            />
            {person && person.main_contact &&
                <div style={{ margin: '10px 0px', display: 'flex', justifyContent: 'flex-start' }}>
                    <Text>Dica: </Text>
                    <Text style={{ textTransform: 'lowercase', marginLeft: 5 }}>{person.main_contact}</Text>
                </div>
            }
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button type="link" onClick={() => setStep('Verification')}>
                    Acessar minha conta
                </Button>
                <Button
                    type="primary"
                    size="large"
                    onClick={() => Verify()}
                    loading={loadingButton}
                    disabled={DisabledButton()}
                >
                    {translate.basic.next.text}
                </Button>
            </div>

        </div>
    )
}