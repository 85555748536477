import React, { useState } from 'react';
import { Dropdown, Spin, Typography } from 'antd';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';

import DeleteMessage from '~/services/calls/messager/messages/delete';
import { useMessagerManager } from '../../../../../../contexts/MessagerManager/Provider';
import { CapitalizeString } from '~/utils/Transformations';
import MessageMenu from './components/Menu';
import * as S from './style';

const { Paragraph, Text } = Typography;

export default function MessageItem({
  isLoggedUser,
  message,
  person_name,
  id,
}) {
  const [loadingMessage, setLoadingMessage] = useState(false);

  const { institutionId, selectedChat, setMessages } = useMessagerManager();

  const strongTextStyle = {
    fontWeight: 'bold',
  };

  async function HandleDeleteMessage(messageId) {
    setLoadingMessage(true);

    const chatId = selectedChat.id;

    const response = await DeleteMessage({ messageId, chatId, institutionId });

    if (response.success) {
      setMessages((currentMessages) => {
        return currentMessages.filter((item) => {
          return item.id !== messageId;
        });
      });
    }

    setLoadingMessage(false);
  }

  return (
    <S.Content key={id} className={isLoggedUser ? 'right' : 'left'}>
      <S.HStack>
        <Text style={strongTextStyle}>
          {isLoggedUser ? 'Você' : CapitalizeString(person_name)}
        </Text>

        {isLoggedUser && (
          <>
            {loadingMessage ? (
              <Spin />
            ) : (
              <Dropdown
                overlay={
                  <MessageMenu onDelete={() => HandleDeleteMessage(id)} />
                }
              >
                <MdOutlineKeyboardArrowDown
                  size={25}
                  style={{ cursor: 'pointer' }}
                />
              </Dropdown>
            )}
          </>
        )}
      </S.HStack>

      <Paragraph>{message}</Paragraph>
    </S.Content>
  );
}
