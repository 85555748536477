import React from 'react';
// Libraries
import { Form } from 'antd';
import Animate, { FadeIn } from 'animate-css-styled-components';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import TextArea from '~/components/textArea';

export default function AttachmentForm({ form, file }) {
    // console.log('file ', file)
    return (
        <div>
            <Animate
                Animation={[FadeIn]}
                duration={['0.5s']}
                delay={['0.2s']}
            >
                <Form
                    labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
                    wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
                    form={form}
                    size="small"
                    layout="vertical"
                    scrollToFirstError
                    initialValues={file}
                >
                    <FormItem
                        label="Nome do Documento"
                        name={['name']}
                        rules={[
                            {
                                required: true,
                                message: 'Insira um nome para o documento',
                            },
                        ]}
                        item={<Input />}
                    />
                    <FormItem
                        label="Assunto"
                        name={['subject']}
                        item={<Input />}
                    />
                    <FormItem
                        label="Descrição"
                        name={['description']}
                        item={<TextArea />}
                    />
                    <FormItem
                        label="Especificações"
                        name={['specifications']}
                        item={<TextArea />}
                    />
                </Form>
            </Animate>
        </div>
    )
}