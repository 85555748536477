import React from 'react';
import { Timeline } from 'antd';
import { AiOutlineBug } from 'react-icons/ai';
import { FiFeather } from 'react-icons/fi';
import { GoGear } from 'react-icons/go';
//
import Text from '~/components/typography/text';
//
import Tasks from './tasks';

export default function Developing() {

    function GetIcon(type) {
        switch (type) {
            case 'bug':
                return <AiOutlineBug />
            case 'layout':
                return <FiFeather />
            case 'feature':
                return <GoGear />
            default:
                return;
        }
    }

    function GetType(type) {
        switch (type) {
            case 'bug':
                return 'Bug'
            case 'layout':
                return 'Layout'
            case 'feature':
                return 'Recurso'
            default:
                return;
        }
    }

    return (
        <div style={{ padding: 10 }}>
            <Timeline>
                {Tasks.map(task => (
                    <Timeline.Item dot={GetIcon(task.type)}>
                        <div>
                            <div>
                                <Text strong>{GetType(task.type)}</Text>
                                <Text>{task.title}</Text>
                                {task.description &&
                                    <Text type="secondary">{task.description}</Text>
                                }
                            </div>
                        </div>
                    </Timeline.Item>
                ))}
            </Timeline>
        </div>
    )
}