import React, { useMemo, useState } from 'react';
import { FilterOutlined, CloseCircleFilled } from '@ant-design/icons';
import { Form, Button, Drawer, Space } from 'antd';

import { useArchiveFilesPageManager } from '../../../../contexts/ArchiveFilesPageManager/Provider';
import FileFilterForm from './components/FilterForm';

export default function FilterFiles() {
  const { initialFilter, filter, setFilter } = useArchiveFilesPageManager();

  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
    form.resetFields();
  };
  const onClose = () => {
    setOpen(false);
  };

  async function onFinish(values) {
    setFilter(values);
  }

  async function ResetFilter() {
    setFilter(initialFilter);
  }

  const isFilterChanged = useMemo(() => {
    return initialFilter !== filter;
  }, [filter]);

  return (
    <>
      <Space>
        {isFilterChanged && (
          <Button
            type="dashed"
            icon={<CloseCircleFilled />}
            onClick={ResetFilter}
          >
            Resetar filtros
          </Button>
        )}
        <Button type="dashed" icon={<FilterOutlined />} onClick={showDrawer}>
          Filtros
        </Button>
      </Space>

      <Drawer
        title="Filtrando arquivos"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <FileFilterForm
          form={form}
          onFinish={onFinish}
          initialFilter={filter}
        />
      </Drawer>
    </>
  );
}
