import React, { useState, useEffect } from 'react';
// Libraries
import { Timeline } from 'antd';
import { useParams } from 'react-router-dom';
// Components
import Loading from '~/components/loading';
import Text from '~/components/typography/text';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { DateInBrazilianFormat, HourInBrazilianFormat } from '~/utils/DateAndTime';

export default function Historic({ process, propertys }) {
    const { institutionId, organizationUnitId } = useParams();
    const [history, setHistory] = useState([]);
    const [loadingContent, setLoadingContent] = useState(true);

    let processInformation = process || propertys.process[0];

    function GetFieldValue(value) {
        switch (value) {
            case 'ACTIVE':
                return 'Ativo';
            case 'INACTIVE':
                return 'Inativo';
            case 'public':
                return 'Público';
            case 'restricted':
                return 'Restrito';
            case 'confidential':
                return 'Confidêncial';
            default:
                return value
        }
    }

    function GetFieldName(field) {
        switch (field) {
            case 'id':
                return 'Id';
            case 'internal_id':
                return 'Id interno';
            case 'external_id':
                return 'Id externo';
            case 'manual_id':
                return 'manual_id';
            case 'created_at':
                return 'Criado em';
            case 'reated_externally_at':
                return 'Local criado externamente';
            case 'created_by':
                return 'Criado por';
            case 'updated_by':
                return 'Atualizado por';
            case 'created_at_unity':
                return 'Criado pela unidade';
            case 'updated_at_unity':
                return 'Atualizado pela unidade';
            case 'institution':
                return 'Instituição';
            case 'dispatched_by':
                return 'Emitido por';
            case 'subject':
                return 'Assunto';
            case 'model':
                return 'Model';
            case 'nature':
                return 'Natureza';
            case 'format':
                return 'Formato';
            case 'status':
                return 'Status';
            case 'kind':
                return 'Tipo';
            case 'name':
                return 'Nome';
            case 'body':
                return 'Corpo';
            case 'url':
                return 'URL';
            case 'description':
                return 'Descrição';
            case 'specifications':
                return 'Especificações';
            case 'settings':
                return 'Configurações';
            case 'privacy':
                return 'Privacidade';
            case 'priority':
                return 'Prioridade';
            default:
                return 'CAMPO DESCONHECIDO';
        }
    }

    function GetMessage(item) {
        if (item.agent === 'PROCESS') {
            if (item.kind === 'DIRECT CHANGE') {
                if (item.action === 'CREATE') {
                    return `Na criação do processo o campo "${GetFieldName(item.field)}" recebeu o valor "${GetFieldValue(item.value)}"`;
                }
                if (item.action === 'ACCESS') {
                    return `processo atualizado, o campo "${GetFieldName(item.field)}" recebeu o valor "${GetFieldValue(item.value)}"`;
                }
                if (item.action === 'UPDATE') {
                    return `O processo foi editado.`;
                }
            }
            if (item.kind === 'ASSIGNMENT') {
                if (item.action === 'INTERESTED PART') {
                    return 'Foi inserido uma parte interessada no processo.';
                }
                if (item.action === 'SIGNATORY') {
                    return 'Foi inserido um signatário no processo.';
                }
                if (item.action === 'PRIVATE ACCESS') {
                    return 'Foi inserido um acesso privado no processo.';
                }
                if (item.action === 'CO-OWNER') {
                    return 'Foi inserido um colaborador no processo.';
                }
                if (item.action === 'LOGIN-PASSWORD') {
                    return 'Foi assinado digitalmente o processo.';
                }
            }
        }
    }

    useEffect(() => {
        async function GetHistoric() {
            const params = {
                operation: ['SIGAM', 'Processes', 'GetHistory'],
                urlParams: {
                    institutionId: institutionId,
                    organizationUnitId: organizationUnitId,
                    processId: processInformation.id
                },
                data: {
                    pagination: {
                        sort: {
                            by: 'created_at',
                            order: 'DESC'
                        },
                        limit: 0
                    }
                }
            };
            const response = await SocialMeAPI(params);
            console.log(response.body.timeline.elements)
            if (response.success) {
                setHistory(response.body.timeline.elements);
            }
            setLoadingContent(false);
        }
        GetHistoric();
    }, [institutionId, organizationUnitId, processInformation])

    if (loadingContent) {
        return <Loading text="Carregando histórico" />
    }

    return (
        <div>
            <Timeline>
                {history.map(item => (
                    <Timeline.Item key={item.id}>
                        <Text>{DateInBrazilianFormat(item.created_at)} às {HourInBrazilianFormat(item.created_at)}</Text>
                        <Text>{GetMessage(item)}</Text>
                    </Timeline.Item>
                ))}
            </Timeline>
        </div>
    )
}