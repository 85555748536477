import React from 'react';
import { Table, Card, Space } from 'antd';
import { usePageManager } from '../../../../contexts/PageManager/Provider';
import EditButton from './components/EditButton';
import DeleteButton from './components/DeleteButton';
import ViewButton from './components/ViewButton';

export default function TableContainer() {
  const { templates } = usePageManager();

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <DeleteButton record={record} />
          <EditButton record={record} />
          <ViewButton record={record} />
        </Space>
      ),
    },
  ];

  return (
    <Card bordered={false} bodyStyle={{ padding: 0 }}>
      <Table
        dataSource={templates}
        columns={columns}
        pagination={false}
        rowKey="id"
      />
    </Card>
  );
}
