import React from 'react';
import { Menu } from 'antd';

export default function ChatMenu({ onDelete }) {
  return (
    <Menu
      items={[
        {
          key: '1',
          label: 'Excluir contato',
          onClick: () => onDelete(),
        },
      ]}
    />
  );
}
