import API from '~/environments/APIs';

export default {
  CreatesTheIntent: {
    baseURL: API.socialMe,
    url: '/dw/operation/intent',
    method: 'post',
    timeout: '50000',
  },
  GetIntent: {
    baseURL: API.socialMe,
    url: '/dw/operation/intent/intentId',
    method: 'get',
    timeout: '50000',
  },
  GetOperation: {
    baseURL: API.socialMe,
    url: '/dw/operation/operation/operationId',
    method: 'get',
    timeout: '50000',
  },
  GetContract: {
    baseURL: API.socialMe,
    url: '/dw/operation/intent/intentId/contract',
    method: 'post',
    timeout: '50000',
  },
  ConfirmOperation: {
    baseURL: API.socialMe,
    url: '/dw/operation/confirm/intentId/contractId',
    method: 'post',
    timeout: '50000',
  },
  UploadFileToTheIntention: {
    baseURL: API.socialMe,
    url: '/dw/operation/intent/file',
    method: 'post',
    timeout: '50000',
  },
};
