import React, { useContext } from 'react';
// Libraries
import { SearchOutlined } from '@ant-design/icons';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Components
import Card from '~/components/card';
import Title from '~/components/typography/title';
import Search from '~/components/search';
import Button from '~/components/button';
import Space from '~/components/space';
import SearchDrawer from '~/pages/SIGAM/components/SearchDrawer';
// Pages
import SearchDrawerComponent from './components/SearchDrawerComponent';

export default function Header({ setSearchInput, setUpdatePending }) {
  const { isMobile } = useContext(ResponsiveContext);

  async function onSearch(value) {
    setSearchInput(value);
  }

  return (
    <Card bodyStyle={{ padding: 10 }} style={{ marginBottom: 10 }}>
      <div
        style={
          isMobile
            ? {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }
            : {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }
        }
      >
        <Title
          level={4}
          style={isMobile ? { margin: '0px 0px 20px 0px' } : { margin: 0 }}
        >
          Usuários
        </Title>
        <Space direction={isMobile ? 'vertical' : 'horizontal'} align={isMobile ? 'center' : 'flex-start'}>
          <Search
            allowClear
            onSearch={onSearch}
            enterButton
            style={{ marginRight: 10 }}
          />
          <SearchDrawer
            Trigger={
              <Button icon={<SearchOutlined />}>Buscar Usuário</Button>
            }
            title="Buscar Usuários"
            SecondStepComponent={SearchDrawerComponent}
            secondStepParameters={{ setUpdatePending, searchOnSocialMe: false }}
          />
        </Space>
      </div>
    </Card>
  );
}
