import React, { useMemo } from 'react';

//Contexts
import { useMessagerManager } from '../../contexts/MessagerManager/Provider';

// Own Components
import AddChat from './views/AddChat';
import EditChat from './views/EditChat';
import ListContacts from './views/ListContacts';
import ChatDetails from './views/ChatDetails';
import MyContacts from './views/MyChats';
import AddContactsToChat from './views/AddContactsToChat';

import VIEWS from '../../constants/views';

export default function MessagerSidebar() {
  const { view } = useMessagerManager();

  const RenderComponent = useMemo(() => {
    const possibleComponentsToRender = {
      [VIEWS.NEW_CHAT]: AddChat,
      [VIEWS.EDIT_CHAT]: EditChat,
      [VIEWS.MY_CHATS]: MyContacts,
      [VIEWS.LIST_CONTACTS]: ListContacts,
      [VIEWS.LIST_CONTACTS_TO_GROUP]: AddContactsToChat,
      [VIEWS.CHAT_DETAILS]: ChatDetails,
    };

    return possibleComponentsToRender[view];
  }, [view]);

  return <RenderComponent />;
}
