import SigamAPI from '~/utils/SigamAPI';

export default async function ReturnUrl({ html }) {
  const response = await SigamAPI({
    url: `/SIGAM/render-pdf-from-html`,
    method: 'POST',
    timeout: '5000000000000',
    data: {
      html,
    },
  });

  return response;
}
