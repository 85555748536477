import React, { useEffect, useState } from 'react';
// Libraries
import { Form, message, Switch, Result } from 'antd';
import { useParams } from 'react-router-dom';
// Own Components
import ModelForm from './components/ModelForm';
// Components
import Button from '~/components/button';
import Loading from '~/components/loading';
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import TextArea from '~/components/textArea';
import Checkbox from '~/components/checkbox';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Edit({ document, propertys }) {
  const { institutionId, organizationUnitId } = useParams();
  const [form] = Form.useForm();
  const [completeDocumentInformation, setCompleteDocumentInformation] =
    useState([]);
  const [loadingContent, setLoadingContent] = useState(true);
  const [initialValues, setInitialValues] = useState();
  const [templatesList, setTemplatesList] = useState();
  const [value, setValue] = useState();
  const [defaultValue, setDefaultValue] = useState();
  const [accessLevel, setAccessLevel] = useState();

  const documentInformation = document || propertys.document[0];

  function RenderNoEdit() {
    return (
      <div>
        <Result
          status="warning"
          title="O documento já se encontra anexado em um processo, portanto, não poderá ser atualizado"
        />
      </div>
    );
  }

  async function onSelect(id) {
    setLoadingContent(true);
    const params = {
      operation: ['SIGAM', 'DocumentTemplates', 'Show'],
      urlParams: {
        institutionId,
        modelId: id,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      form.setFieldsValue({ body: response.body.documentModel.body });
      setValue(response.body.documentModel.body);
      setDefaultValue(response.body.documentModel.body);
    }
    setLoadingContent(false);
  }

  async function onFinish(values) {
    //console.log(values);
    const processedData = {};
    processedData.priority = 0;
    for (const key in values) {
      if (values[key]) {
        processedData[key] = values[key];
      }
    }
    if (values.priority) {
      processedData.priority = 1;
    } else {
      processedData.priority = 0;
    }
    if (values.status === false) {
      processedData.status = 'INACTIVE';
    }
    if (values.status === true) {
      processedData.status = 'ACTIVE';
    }

    const params = {
      operation: ['SIGAM', 'Documents', 'Edit'],
      urlParams: {
        institutionId,
        organizationUnitId,
        documentId: documentInformation.id,
      },
      data: processedData,
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      if (propertys && propertys.GetDataAgain) {
        propertys.GetDataAgain();
      }
      return message.success('Documento atualizado com sucesso!');
    }
    return message.error('Alguma coisa aconteceu...');
  }

  useEffect(() => {
    async function GetDocumentTemplates() {
      const params = {
        operation: ['SIGAM', 'DocumentTemplates', 'Get'],
        urlParams: {
          institutionId,
        },
        data: {
          pagination: {
            sort: {
              by: 'name',
              order: 'asc',
            },
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        const array = response.body.documentModels.elements;
        const newArray = [];
        for (let i = 0; i < array.length; i++) {
          const newItem = {};
          newItem.label = array[i].name;
          newItem.value = array[i].id;
          newArray.push(newItem);
        }
        setTemplatesList(newArray);
      }
      return setLoadingContent(false);
    }
    async function GetDocument() {
      const params = {
        operation: ['SIGAM', 'Documents', 'Show'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: documentInformation.id,
        },
      };
      const response = await SocialMeAPI(params);
      // console.log(response)
      if (response.success) {
        const responseInformation = response.body.document;
        if (responseInformation.priority === 1) {
          responseInformation.priority = true;
        } else {
          responseInformation.priority = false;
        }
        if (responseInformation.status === 'ACTIVE') {
          responseInformation.status = true;
        } else {
          responseInformation.status = false;
        }
        setInitialValues(responseInformation);
        form.setFieldsValue(responseInformation);
        setValue(responseInformation.body);
        setDefaultValue(responseInformation.body);
        setCompleteDocumentInformation(responseInformation);
      }
      GetDocumentTemplates();
    }
    async function verifyPermission() {
      const params = {
        operation: ['SIGAM', 'Documents', 'HavePermission'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: documentInformation.id,
        },
      };
      const response = await SocialMeAPI(params);
      setAccessLevel(response.body.access_level);
      GetDocument();
    }
    setLoadingContent(true);
    setInitialValues();
    verifyPermission();
  }, [institutionId, organizationUnitId, documentInformation, form]);

  if (accessLevel) {
    if (!accessLevel.is_in_charge) {
      return (
        <Result status="error" title="Você não pode alterar o documento" />
      );
    }
  }

  if (loadingContent) {
    return <Loading />;
  }

  if (process && process.process_count) {
    if (Number(process.process_count) > 0) {
      return <RenderNoEdit />;
    }
  }

  if (
    propertys &&
    propertys.document[0] &&
    propertys.document[0].process_count
  ) {
    if (Number(propertys.document[0].process_count) > 0) {
      return <RenderNoEdit />;
    }
  }

  return (
    <div>
      <Form
        labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        form={form}
        size="small"
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={initialValues}
      >
        {completeDocumentInformation.kind === 'HTML' && (
          <ModelForm
            form={form}
            onSelect={onSelect}
            templatesList={templatesList}
            defaultValue={defaultValue}
            value={value}
            setValue={setValue}
          />
        )}

        <FormItem
          label="Nome do Documento"
          name={['name']}
          rules={[
            {
              required: true,
              message: 'Insira um nome para o documento',
            },
          ]}
          item={<Input />}
        />
        <FormItem label="Assunto" name={['subject']} item={<Input />} />
        <FormItem
          label="Descrição"
          name={['description']}
          item={<TextArea />}
        />
        <FormItem
          label="Especificações"
          name={['specifications']}
          item={<TextArea />}
        />
        <FormItem
          label="Documento Prioritário"
          name={['priority']}
          valuePropName="checked"
          item={<Switch />}
        />
        <FormItem
          label="Situação"
          name="status"
          valuePropName="checked"
          item={<Checkbox text="Documento ativo" />}
        />
        <FormItem
          label=""
          item={
            <div style={{ padding: '10px 0px' }}>
              <Button type="primary" htmlType="submit">
                Atualizar Documento
              </Button>
            </div>
          }
        />
      </Form>
    </div>
  );
}
