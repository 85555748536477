import React, { useState, useEffect } from 'react';
import {
  Card,
  Typography,
  Space,
  Image,
  Descriptions,
  Badge,
  Tag,
  Spin,
} from 'antd';
import IconAgent from '~/assets/images/icons/icone256-agente.png';
import { usePageManager } from '../../../../contexts/PageManager/Provider';
import { useParams } from 'react-router-dom';
import AddProcess from './components/AddProcess';
import SocialMeAPI from '~/utils/SocialMeAPI';
import {
  DateInBrazilianFormat,
  HourInBrazilianFormat,
} from '~/utils/DateAndTime';

const { Title, Text } = Typography;

export default function Content() {
  const { selectedProcessId } = usePageManager();
  const { institutionId } = useParams();
  const [loadingProcess, setLoadingProcess] = useState(true);
  const [process, setProcess] = useState(null);

  useEffect(() => {
    async function GetData() {
      setLoadingProcess(true);
      const params = {
        operation: ['SIGAM', 'Admin', 'Processes', 'Show'],
        urlParams: {
          institutionId: institutionId,
          processId: selectedProcessId,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setProcess(response?.body?.process);
      }
      setLoadingProcess(false);
    }
    if (selectedProcessId) {
      GetData();
    }
  }, [institutionId, selectedProcessId]);

  if (!selectedProcessId) {
    return (
      <Card style={{ display: 'flex', justifyContent: 'center' }}>
        <AddProcess />
      </Card>
    );
  }

  if (loadingProcess) {
    return <Spin />;
  }

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: 'flex',
      }}
    >
      <Descriptions
        title={
          <Title level={5} style={{ margin: 0 }}>
            <Image
              src={IconAgent}
              width={24}
              preview={false}
              style={{ margin: 0 }}
            />
            {process?.name}
          </Title>
        }
        bordered
        column={{
          xxl: 4,
          xl: 3,
          lg: 3,
          md: 3,
          sm: 2,
          xs: 1,
        }}
        extra={<AddProcess />}
      >
        <Descriptions.Item label="Código interno">
          <Text italic>{process?.internal_id}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Código externo">
          <Text italic>{process?.external_id}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Código manual">
          <Text italic>{process?.manual_id}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Unidade criadora">
          <Tag bordered="false" color="processing">
            {process?.created_at_unity_name}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Criado em">
          <Text italic>
            {DateInBrazilianFormat(process.created_at)} às{' '}
            {HourInBrazilianFormat(process.created_at)}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="Atualizado por último em">
          {process.updated_at ? (
            <Text italic>
              {DateInBrazilianFormat(process.updated_at)} às{' '}
              {HourInBrazilianFormat(process.updated_at)}
            </Text>
          ) : (
            <Text italic>Nunca atualizado</Text>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Tipo de processo">
          <Text italic>{process?.kind_name}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Assunto">
          <Text italic>{process?.subject}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Privacidade">
          {process?.privacy === 'PUBLIC' && <Text italic>Público</Text>}
        </Descriptions.Item>
        <Descriptions.Item label="Prioritário">
          <Text italic>{process?.priority === 0 ? 'Não' : 'Sim'}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          {process?.status === 'ACTIVE' && (
            <Badge status="success" text="Ativo" />
          )}
          {process?.status === 'INATICE' && (
            <Badge status="error" text="Arquivado" />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Especificações">
          <Text italic></Text>
        </Descriptions.Item>
        {/* 
        <Descriptions.Item label="Arquivado">
          <Text italic>{process?.status === 'INATICE' ? 'Sim' : 'Não'}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Tempo de vida">
          <Text italic>18 dias</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Total de tramitações">
          <Text italic>3 tramitações</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Total de unidades">
          <Text italic>12 unidades</Text>
        </Descriptions.Item>
        */}
      </Descriptions>
    </Space>
  );
}
