function getDocumentHeader() {
  return `
  <thead style="display: table-header-group;font-family: Arial;">
    <tr>
        <th style="display: flex; width: 100%; justify-content: center; align-content: center; background-color: #F2F3F5; padding: 20px">
            <p style="width: 100%; font-size: 14px; margin: 0; font-weight: 100;">Cabeçalho</p>
        </th>
    </tr>
  </thead>
  `;
}

function getDocumentFooter() {
  return `
  <tfoot style="display: table-footer-group;font-family: Arial;">
    <tr>
        <th style="display: flex; width: 100%; justify-content: center; align-content: center; background-color: #F2F3F5; padding: 20px">
            <p style="width: 100%; font-size: 14px; margin: 0; font-weight: 100;">Rodapé</p>
        </th>
    </tr>
  </tfoot>
  `;
}

export default function getTemplateModel({ content }) {
  return `
    <table style="page-break-after: always; font-family: Arial; width: 100%">
      ${getDocumentHeader()}
      ${getDocumentFooter()}
      <tbody>
      <tr>
          <td>
              <div style="padding: 10px 0px;">
                  ${content}
              </div>
          </td>
      </tr>
      </tbody>
  </table>
    `;
}
