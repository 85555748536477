import React, { useEffect, useContext, useState } from 'react';
import { Layout } from 'antd';
import { Route, Switch, Redirect } from 'react-router-dom';
import { BankOutlined } from '@ant-design/icons';
import { AuthContext } from '~/contexts/AuthContext';
import { ThemeContext } from '~/contexts/ThemeContext';
import System from '~/environments/System';
import Loader from '~/components/loading'
import Sidebar from '~/pages/SIGAM/components/sidebar';
import MainNavbar from '~/pages/SIGAM/components/navbar';
import Institutions from './pages/Institutions';

const { Content } = Layout;

export default function Admin() {
  const { user } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const [loadingContainer, setLoading] = useState(true)

  const sidebarItems = [
    {
      index: 0,
      title: 'First',
    },
    {
      index: 1,
      title: 'Instituições',
      url: '/institutions',
      icon: BankOutlined
    },
    {
      index: 2,
      title: 'Last'
    }
  ]

  useEffect(() => {
    async function isAdmin() {
      if ('GOD' in user.permission) {
        return setLoading(false)
      }
      return window.location.replace(`https://${System.production ? System.hostname.SIGAMApp : System.hostname.SIGAMDev}`);
    }
    if (user) {
      if(user.permission){
        isAdmin()
      }else{
        return window.location.replace(`https://${System.production ? System.hostname.SIGAMApp : System.hostname.SIGAMDev}`);
      }
    } else {
      return window.location.replace(`https://${System.production ? System.hostname.SIGAMApp : System.hostname.SIGAMDev}`);
    }
  }, [])

  if (loadingContainer) {
    return <Loader text="Verificando permissão" />
  }

  return (
    <div>
      <Layout
        style={{
          backgroundColor: theme.color.backgroundPrimary,
          height: '100vh',
        }}
      >
        <Sidebar items={sidebarItems} />
        <Layout
          style={{
            padding: 10,
            backgroundColor: theme.color.backgroundSecondary,
          }}
        >
          <MainNavbar />
          <Content
            style={{
              overflow: 'auto',
            }}
          >

            <Switch>
              <Route exact path="/sigam-admin/institutions" component={Institutions} />
              <Route path="*">
                <Redirect to="/sigam-admin/institutions" />
              </Route>
            </Switch>

          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
