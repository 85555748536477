import React from 'react';
import { Pagination } from 'antd';

import { useArchiveFilesPageManager } from '../../../../contexts/ArchiveFilesPageManager/Provider';

export default function CustomPagination() {
  const { totalItems, pagination, setPagination } =
    useArchiveFilesPageManager();

  const onChange = (newPage, newPageSize) => {
    setPagination({ page: newPage, limit: newPageSize });
  };

  return (
    <Pagination
      total={totalItems}
      defaultCurrent={pagination.page}
      defaultPageSize={pagination.limit}
      onChange={onChange}
      showSizeChanger
      showQuickJumper
      showTotal={(total) => `Total de ${total} arquivos`}
      style={{ marginTop: '8px' }}
    />
  );
}
