import React, { useState, useContext } from 'react';
import { Drawer, Row, Col } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
// Components
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
import Card from '~/components/card';

import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

const trainingVideos = [
  {
    label: 'O QUE É O SIGAM?',
    src: 'https://www.youtube.com/embed/hOr2lYVFeVU',
  },
  /*
    {
        label: 'TREINAMENTO 01  ADMINISTRADOR DO SISTEMA',
        src: 'https://www.youtube.com/embed/qEhWbMbGN1A'
    },
    {
        label: 'TREINAMENTO 02  CONFIGURAÇÕES DO ADMINISTRADOR PASSO A PASSO',
        src: 'https://www.youtube.com/embed/OP7Wewu9HBQ'
    },
    {
        label: 'TREINAMENTO 03 - PERMISSÃO DE ADMINISTRADOR PARA USUÁRIO COMUM',
        src: 'https://www.youtube.com/embed/ximAIo82X7A'
    },
    {
        label: 'TREINAMENTO 04: DICAS PARA CRIAÇÃO DE DOCUMENTOS',
        src: 'https://www.youtube.com/embed/atUtv6bu09k'
    },
    {
        label: 'TREINAMENTO 05- PERSONALIZANDO DOCUMETOS ATRAVÉS DO SIGAM',
        src: 'https://www.youtube.com/embed/WbGQoiBBj20'
    }
    */
];

export default function TrainingDrawer() {
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  function VideoContainer({ video }) {
    return (
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={24}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginBottom: '20px',
        }}
      >
        <Title
          level={5}
          style={{ color: theme.color.primary, textAling: 'center' }}
        >
          {video.label}
        </Title>
        <iframe
          style={{ border: '2px solid', borderColor: theme.color.primary }}
          width={isMobile ? '300' : '560'}
          height={isMobile ? '300' : '315'}
          src={video.src}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Col>
    );
  }

  return (
    <>
      <div onClick={showDrawer}>
        <Card
          onClick={showDrawer}
          style={{
            border: '2px solid',
            borderColor: theme.color.primary,
            cursor: 'pointer',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <PlayCircleOutlined style={{ fontSize: '24pt' }} />
            <Text>Treinamento SIGAM:</Text>
            <Text>Série de vídeos</Text>
          </div>
        </Card>
      </div>

      <Drawer
        title="Treinamento SIGAM"
        placement="right"
        width={isMobile ? '100%' : '50%'}
        onClose={onClose}
        visible={visible}
      >
        <Row>
          {trainingVideos.map((video, index) => (
            <VideoContainer key={index} video={video} />
          ))}
        </Row>
      </Drawer>
    </>
  );
}
