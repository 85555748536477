import React, { useContext } from 'react';
// Libraries
import ReactHtmlParser from 'react-html-parser';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';

export default function HTMLPreview({ document }) {
    const { theme } = useContext(ThemeContext);
    return (
        <div>
            <div style={{ border: `2px solid ${theme.color.borderColor}`, padding: 10 }} >
                {ReactHtmlParser(document.body)}
            </div>
        </div>
    )
}