import styled from 'styled-components';

import { Table } from 'antd';

const MainTable = styled(Table)`
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #340348;
    border-radius: 20px;
  }

  th.ant-table-cell {
    background-color: ${(props) => props.headerColor};
    padding: 20px 0;
  }

  td.ant-table-cell {
    border: none;
    padding: 20px 0;
  }
`;

export default MainTable;
