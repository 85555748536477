import React, { useState } from 'react';
// Libraries
import { message } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import Animate, { FadeIn } from 'animate-css-styled-components';
import { useParams } from 'react-router-dom';
// Components
import Avatar from '~/components/avatar';
import Button from '~/components/button';
import Search from '~/components/search';
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
import Divider from '~/components/divider';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { CapitalizeString } from '~/utils/Transformations';
import { cpfMask, cnpjMask, cpfMaskContinuos } from '~/utils/Masks';

export default function AddPerson({ propertys, CloseDrawer }) {
    const { institutionId, organizationUnitId } = useParams();
    const [fieldDocument, setFieldDocument] = useState();
    const [result, setResult] = useState();
    const [loadingSearch, setLoadingSearch] = useState(false);

    const HandleChangeFieldDocument = () => (event) => {
        if (event.target.value.length > 18) {
            return;
        }
        if (event.target.value.length <= 14) {
            return setFieldDocument(cpfMaskContinuos(event.target.value));
        } else if (event.target.value.length > 14) {
            return setFieldDocument(cnpjMask(event.target.value));
        }
    };

    async function onSearch(value) {
        setLoadingSearch(true);
        let filter = {};
        if (value.length < 14) {
            setLoadingSearch(false);
            setResult();
            return message.error('Digite um CPF ou CNPJ válido');
        }
        if (value.length > 14 && value.length > 18) {
            setLoadingSearch(false);
            setResult();
            return message.error('Digite um CPF ou CNPJ válido');
        }
        if (value.length === 14) {
            filter.cpf_number = value.replace(/[^\d]+/g, '');
        }
        if (value.length === 18) {
            filter.cnpj_number = value.replace(/[^\d]+/g, '');
        }
        const params = {
            operation: ['System', 'Person', 'Search'],
            data: {
                filter: filter,
            },
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            message.success('Busca realizada com sucesso!');
            setResult(response.body.peopleList.elements[0]);
        } else {
            setResult();
            message.error('Ninguém encontrado...');
        }
        setLoadingSearch(false);
    }

    async function AddUser(person) {
        const params = {
            operation: ['SIGAM', 'Processes', 'PrivateAccess', 'Add'],
            urlParams: {
                institutionId: institutionId,
                organizationUnitId: organizationUnitId,
                processId: propertys.process.id,
                personId: person.id
            },
        };
        const response = await SocialMeAPI(params);
        console.log(response)
        if (response.success) {
            message.success('Acesso concedido com sucesso!');
            CloseDrawer();
            setResult();
            propertys.GetPeopleAgain();
        } else {
            message.error('Alguma coisa aconteceu...')
        }
    }

    function ChecksIfItBelongs() {
        if (propertys.people.length > 0) {
            for (let i = 0; i < propertys.people.length; i++) {
                if (propertys.people[i].id === result.id) {
                    return (
                        <Button icon={<UserAddOutlined />} disabled>
                            Usuário já possui acesso
                        </Button>
                    );
                }
            }
        }
        return (
            <Button onClick={() => AddUser(result)} icon={<UserAddOutlined />} type="primary">
                Adicionar acesso
            </Button>
        );
    }

    const RenderResult = () => {
        return (
            <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
                <Divider />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: '10px 0px' }}>
                    <Avatar src={result.avatar} size={64} />
                    <Title level={4}>{CapitalizeString(result.social_name || result.name)}</Title>
                    <Text>
                        {result.cpf_number
                            ? cpfMask(result.cpf_number)
                            : cnpjMask(result.cnpj_number)
                        }
                    </Text>
                    <div style={{ marginTop: 10 }}>
                        {ChecksIfItBelongs()}
                    </div>
                </div>
            </Animate>
        );
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: 200 }}>
                    <Search
                        placeholder="CPF ou CNPJ"
                        loading={loadingSearch}
                        allowClear
                        onSearch={onSearch}
                        enterButton
                        onChange={HandleChangeFieldDocument()}
                        value={fieldDocument}
                    />
                </div>
            </div>
            {result && <RenderResult />}
        </div>
    );
}
