import React from 'react';
import ArchivesPageManagerProvider from './contexts/ArchivesPageManager/Provider';
import Page from './page';

export default function Archives() {
  return (
    <ArchivesPageManagerProvider>
      <Page />
    </ArchivesPageManagerProvider>
  );
}
