import React, { useEffect, useState, useContext } from 'react';
// Libraries
import { useParams } from 'react-router-dom';
import { Tabs, Result } from 'antd';
// Contexts
import { PermissionContext } from '~/contexts/PermissionContext';
// Own Components
import Header from './components/Header';
import Content from './components/Content';
import Options from './components/Options';
// Components
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

const { TabPane } = Tabs;

export default function Documents() {
  const { setUnitPermissions } = useContext(PermissionContext);
  const { institutionId, organizationUnitId } = useParams();
  const [loadingContent, setLoadingContent] = useState(true);
  const [searchInput, setSearchInput] = useState();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [selectedRow, setselectedRow] = useState([]);
  const [documents, setDocuments] = useState();
  const [activeTab, setActiveTab] = useState('myDocuments');
  const [filters, setFilters] = useState({
    i_am_owner: true,
    organizational_unity: undefined,
    i_am_signatory: undefined,
    i_signed: undefined,
    i_am_interested: undefined,
    i_am_editor: undefined,
    privacy: undefined,
    name: undefined,
    format: undefined,
    body: undefined,
    subject: undefined,
    year: undefined,
    sort: {
      by: 'created_at',
      order: 'DESC',
    },
  });
  const [pageParameters, setPageParameters] = useState({
    currentPage: 1,
    totalElements: 0,
    limit: 10,
  });
  const [withoutPermission, setWithoutPermission] = useState(false);

  async function GetDataAgain(newPageParameters) {
    setLoadingContent(true);
    const params = {
      operation: ['SIGAM', 'Documents', 'Get'],
      urlParams: {
        institutionId,
        organizationUnitId,
      },
      data: {
        filter: {
          searchString: searchInput,
          i_am_owner: filters.i_am_owner,
          organizational_unity: filters.organizational_unity,
          i_am_signatory: filters.i_am_signatory,
          i_signed: filters.i_signed === false ? false : undefined,
          i_am_interested: filters.i_am_interested,
          i_am_editor: filters.i_am_editor,
          privacy: filters.privacy,
          name: filters.name,
          format: filters.format,
          body: filters.body,
          subject: filters.subject,
          year: filters.year,
        },
        pagination: {
          limit: newPageParameters
            ? newPageParameters.limit
            : pageParameters.limit,
          page: newPageParameters
            ? newPageParameters.currentPage
            : pageParameters.currentPage,
          sort: {
            by: filters.sort.by,
            order: filters.sort.order,
          },
        },
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      setPageParameters({
        ...pageParameters,
        currentPage: response.body.documents.currentPage,
        totalElements: response.body.documents.totalElements,
        limit: response.body.documents.limit,
      });
      setDocuments(response.body.documents.elements);
      setLoadingContent(false);
    }
  }

  useEffect(() => {
    async function GetPermissions() {
      const params = {
        operation: ['SIGAM', 'OrganizationalUnits', 'Permissions', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setUnitPermissions(response.body.permission);
      }
    }
    GetPermissions();
  }, [institutionId, organizationUnitId, setUnitPermissions]);

  useEffect(() => {
    async function GetDocuments() {
      setLoadingContent(true);
      const params = {
        operation: ['SIGAM', 'Documents', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
        },
        data: {
          filter: {
            searchString: searchInput,
            i_am_owner: filters.i_am_owner,
            organizational_unity: filters.organizational_unity,
            i_am_signatory: filters.i_am_signatory,
            i_signed: filters.i_signed === false ? false : undefined,
            i_am_interested: filters.i_am_interested,
            i_am_editor: filters.i_am_editor,
            privacy: filters.privacy,
            name: filters.name,
            format: filters.format,
            body: filters.body,
            subject: filters.subject,
            year: filters.year,
          },
          pagination: {
            sort: {
              by: filters.sort.by,
              order: filters.sort.order,
            },
          },
        },
      };
      const response = await SocialMeAPI(params);
      // console.log(response)
      if (response.success) {
        setPageParameters({
          currentPage: response.body.documents.currentPage,
          totalElements: response.body.documents.totalElements,
          limit: response.body.documents.limit,
        });

        setDocuments(response.body.documents.elements);
        if (searchInput) {
          setLoadingSearch(false);
        }
      } else if (
        response.message ===
        "You don't have the permission needed to perform such action"
      ) {
        setWithoutPermission(true);
      }
      setLoadingContent(false);
    }
    if (searchInput) {
      setLoadingSearch(true);
    }
    GetDocuments();
  }, [searchInput, institutionId, organizationUnitId, filters]);

  function ChangeYab(key) {
    setActiveTab(key);
    switch (key) {
      case 'myDocuments':
        return setFilters({
          ...filters,
          i_am_owner: true,
          organizational_unity: undefined,
          i_am_signatory: undefined,
          i_signed: undefined,
          i_am_interested: undefined,
          privacy: undefined,
          i_am_editor: undefined,
        });
      case 'sharedWithMe':
        return setFilters({
          ...filters,
          i_am_owner: undefined,
          organizational_unity: undefined,
          i_am_signatory: undefined,
          i_signed: undefined,
          i_am_interested: undefined,
          privacy: undefined,
          i_am_editor: true,
        });
      case 'pendingSignature':
        return setFilters({
          ...filters,
          i_am_owner: undefined,
          organizational_unity: undefined,
          i_am_signatory: true,
          i_signed: false,
          i_am_interested: undefined,
          privacy: undefined,
          i_am_editor: undefined,
        });
      case 'ofMyInterest':
        return setFilters({
          ...filters,
          i_am_owner: undefined,
          organizational_unity: undefined,
          i_am_signatory: undefined,
          i_signed: undefined,
          i_am_interested: true,
          privacy: undefined,
          i_am_editor: undefined,
        });
      case 'privateDocuments':
        return setFilters({
          ...filters,
          i_am_owner: false,
          organizational_unity: organizationUnitId,
          i_am_signatory: undefined,
          i_signed: undefined,
          i_am_interested: undefined,
          i_am_editor: undefined,
          privacy: ['RESTRICTED', 'CONFIDENTIAL'],
        });
      case 'unitDocuments':
      default:
        return setFilters({
          ...filters,
          i_am_owner: false,
          organizational_unity: organizationUnitId,
          i_am_signatory: undefined,
          i_signed: undefined,
          i_am_interested: undefined,
          privacy: undefined,
          i_am_editor: undefined,
        });
      /*
    case 'allDocuments':
      return setFilters({
        ...filters,
        i_am_owner: undefined,
        organizational_unity: undefined,
        i_am_signatory: undefined,
        i_signed: undefined,
        i_am_interested: undefined,
      });
      */
    }
  }

  if (withoutPermission) {
    return (
      <div
        style={{
          height: 'calc(100vh - 80px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Result
          status="warning"
          title="Você não tem permissão para acessar os documentos da unidade"
        />
      </div>
    );
  }

  return (
    <div style={{ overflow: 'auto' }}>
      <div id="header">
        <Header
          GetDataAgain={GetDataAgain}
          setSearchInput={setSearchInput}
          loadingSearch={loadingSearch}
          filters={filters}
          setFilters={setFilters}
          activeTab={activeTab}
        />
        <Options selectedRow={selectedRow} GetDataAgain={GetDataAgain} />
      </div>
      {loadingContent ? (
        <Loading text="Carregando Documentos" />
      ) : (
        <Tabs defaultActiveKey={activeTab} onChange={ChangeYab}>
          <TabPane tab="Meus Documentos" key="myDocuments">
            <Content
              data={documents}
              pageParameters={pageParameters}
              GetDataAgain={GetDataAgain}
              setselectedRow={setselectedRow}
            />
          </TabPane>
          <TabPane tab="Compartilhados comigo" key="sharedWithMe">
            <Content
              data={documents}
              pageParameters={pageParameters}
              GetDataAgain={GetDataAgain}
              setselectedRow={setselectedRow}
            />
          </TabPane>
          <TabPane tab="Pendentes de Assinatura" key="pendingSignature">
            <Content
              data={documents}
              pageParameters={pageParameters}
              GetDataAgain={GetDataAgain}
              setselectedRow={setselectedRow}
            />
          </TabPane>
          <TabPane tab="Do meu Interesse" key="ofMyInterest">
            <Content
              data={documents}
              pageParameters={pageParameters}
              GetDataAgain={GetDataAgain}
              setselectedRow={setselectedRow}
            />
          </TabPane>
          <TabPane tab="Documentos Privados" key="privateDocuments">
            <Content
              data={documents}
              pageParameters={pageParameters}
              GetDataAgain={GetDataAgain}
              setselectedRow={setselectedRow}
            />
          </TabPane>
          <TabPane tab="Documentos da Unidade" key="unitDocuments">
            <Content
              data={documents}
              pageParameters={pageParameters}
              GetDataAgain={GetDataAgain}
              setselectedRow={setselectedRow}
            />
          </TabPane>
          {/* 
          <TabPane tab="Todos os Documentos" key="allDocuments">
            <Content
              data={documents}
              pageParameters={pageParameters}
              GetDataAgain={GetDataAgain}
              setselectedRow={setselectedRow}
            />
          </TabPane>
          */}
        </Tabs>
      )}
    </div>
  );
}
