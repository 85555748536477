import SigamAPI from '~/utils/SigamAPI';

export default async function updateDocument({
  documentId,
  institutionId,
  organizationUnitId,
  data,
}) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/organizational-unity/${organizationUnitId}/document/${documentId}`,
    method: 'PUT',
    data,
  });

  return response;
}
