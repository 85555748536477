import React from 'react';
// Libraries
import { List, message } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import Animate, { FadeIn } from 'animate-css-styled-components';
// Components
import Avatar from '~/components/avatar';
import Button from '~/components/button';
// Utils
import { CapitalizeString, DecodeURI } from '~/utils/Transformations';
import { cpfMask, cnpjMask } from '~/utils/Masks';
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function PeopleList({
  people,
  process,
  GetPeopleAgain,
  accessLevel,
}) {
  const { institutionId, organizationUnitId } = useParams();

  async function RemoveUser(item) {
    const params = {
      operation: ['SIGAM', 'Processes', 'Interested', 'Delete'],
      urlParams: {
        institutionId,
        organizationUnitId,
        processId: process.id,
        personId: item.id || item.person_id,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      message.success('Signatário removido com sucesso!');
      GetPeopleAgain();
    } else {
      message.error('Alguma coisa aconteceu...');
    }
  }

  function renderButton(item) {
    /*
        if (process.total_tramitations > 0) {
            return <></>
        }
        */
    if (accessLevel) {
      if (accessLevel.is_in_charge) {
        return [
          <Button
            onClick={() => RemoveUser(item)}
            icon={<UserDeleteOutlined />}
            danger
          >
            Remover interessado
          </Button>,
        ];
      }
    }
    return <></>;
  }

  return (
    <div style={{ marginTop: 10 }}>
      <List
        itemLayout="horizontal"
        dataSource={people}
        locale={{ emptyText: 'Nenhum usuário encontrado' }}
        renderItem={(item) => (
          <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
            <List.Item actions={renderButton(item)}>
              <List.Item.Meta
                avatar={
                  <Avatar
                    size={45}
                    src={DecodeURI(item.avatar || item.person_avatar)}
                  />
                }
                title={CapitalizeString(
                  item.social_name ||
                    item.name ||
                    item.person_social_name ||
                    item.person_name
                )}
                description={
                  item.cpf_number
                    ? cpfMask(item.cpf_number)
                    : cnpjMask(item.cnpj_number)
                }
              />
            </List.Item>
          </Animate>
        )}
      />
    </div>
  );
}
