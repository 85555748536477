import React from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { IoSettingsOutline } from 'react-icons/io5';
import { Dropdown } from 'antd';

import DeleteChat from '~/services/calls/messager/chat/delete';
import { useTheme } from '~/contexts/ThemeContext';
import { useMessagerManager } from '../../../../contexts/MessagerManager/Provider';
import ChatContent from '../../../../components/Sidebar/components/ChatContent';
import ChatMenu from './components/ChatMenu';
import ChatGroupMenu from './components/ChatGroupMenu';
import VIEWS from '../../../../constants/views';

import * as S from './style';

export default function MessagerContainerHeader() {
  const { theme } = useTheme();

  const {
    institutionId,
    chats,
    setChats,
    setView,
    selectedChat,
    setSelectedChat,
  } = useMessagerManager();

  async function RemoveChat() {
    const chatId = selectedChat.id;

    const response = await DeleteChat({ chatId, institutionId });
    if (response.success) {
      const newChats = chats.filter((item) => {
        return item.id !== selectedChat.id;
      });

      setSelectedChat();
      setChats(newChats);
    }
  }

  function EditChatGroup() {
    setSelectedChat(selectedChat);
    setView(VIEWS.EDIT_CHAT);
  }

  function SeeChatGroupDetails() {
    setSelectedChat(selectedChat);
    setView(VIEWS.CHAT_DETAILS);
  }

  function AddContactIntoGroup() {
    setSelectedChat(selectedChat);
    setView(VIEWS.LIST_CONTACTS_TO_GROUP);
  }

  return (
    <S.Header>
      <S.HStack>
        <S.Center>
          <BiArrowBack
            size={25}
            onClick={setSelectedChat}
            color={theme.color.primary}
          />
        </S.Center>

        <ChatContent chat={selectedChat} />
      </S.HStack>

      <S.HStack>
        <Dropdown
          overlay={
            selectedChat.kind === 'GROUP' ? (
              <ChatGroupMenu
                onDelete={RemoveChat}
                onEdit={EditChatGroup}
                onSeeDetails={SeeChatGroupDetails}
                onAddContactIntoGroup={AddContactIntoGroup}
              />
            ) : (
              <ChatMenu onDelete={RemoveChat} />
            )
          }
        >
          <IoSettingsOutline
            style={{ cursor: 'pointer' }}
            size={20}
            color={theme.color.black}
          />
        </Dropdown>
      </S.HStack>
    </S.Header>
  );
}
