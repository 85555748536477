import React, { useState, useEffect } from 'react';
// Libraries
import { Row, Col, message } from 'antd';
import { MdPublic } from 'react-icons/md';
import { FaUserSecret } from 'react-icons/fa';
import { GrSecure } from 'react-icons/gr';
import Animate, { FadeIn } from 'animate-css-styled-components';
import { useParams } from 'react-router-dom';
// Own Components
import SelectionItem from './components/SelectionItem';
import PeopleWithPermission from './components/PeopleWithPermission';
// Components
import Loading from '~/components/loading';
import Title from '~/components/typography/title';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function AccessLevel({ process, propertys }) {
    const { institutionId, organizationUnitId } = useParams();
    const [loadingContent, setLoadingContent] = useState(true);
    const [activePrivacy, setActivePrivacy] = useState();

    let processInformation = process || propertys.process[0];

    const options = [
        {
            icon: MdPublic,
            title: 'Público',
            description: 'Todos podem visualizar',
            value: 'public'
        },
        {
            icon: GrSecure,
            title: 'Restrito',
            description: 'Descrição do Restrito',
            value: 'restricted'
        },
        {
            icon: FaUserSecret,
            title: 'Sigiloso',
            description: 'Descrição do Sigiloso',
            value: 'confidential'
        }
    ]

    useEffect(() => {
        async function Edit() {
            const params = {
                operation: ['SIGAM', 'Processes', 'Edit'],
                urlParams: {
                    institutionId: institutionId,
                    organizationUnitId: organizationUnitId,
                    processId: processInformation.id
                },
                data: {
                    privacy: activePrivacy
                }
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                if (propertys.GetDataAgain) {
                    propertys.GetDataAgain();
                }
                return message.success('Documento atualizado com sucesso!')
            }
            return message.error('Alguma coisa aconteceu...')
        }
        if (activePrivacy) {
            if (processInformation.privacy !== activePrivacy) {
                Edit();
            }
        }
    }, [activePrivacy, processInformation, institutionId, organizationUnitId, propertys])

    useEffect(() => {
        if (processInformation) {
            if (processInformation.privacy) {
                if (processInformation.privacy === 'public' ||
                    processInformation.privacy === 'restricted' ||
                    processInformation.privacy === 'confidential') {
                    setActivePrivacy(processInformation.privacy)
                }else{
                    setActivePrivacy('public');
                }
            } else {
                setActivePrivacy('public');
            }
            return setLoadingContent(false);
        }
    }, [processInformation])

    if (loadingContent) {
        return <Loading />
    }

    function RenderPeopleWithPermission() {
        switch (activePrivacy) {
            case 'restricted':
            case 'confidential':
                return <PeopleWithPermission process={processInformation} />
            default:
                return <></>
        }
    }

    return (
        <div>
            <Animate
                Animation={[FadeIn]}
                duration={['0.5s']}
                delay={['0.2s']}
            >
                <Row>
                    <Title style={{ textAlign: 'center', width: '100%' }} level={4}>Nível de Acesso</Title>
                </Row>
                <Row>
                    {options.map(item => (
                        <Col key={item.value} span={8} style={{ padding: 5 }}>
                            <SelectionItem item={item} activePrivacy={activePrivacy} setActivePrivacy={setActivePrivacy} />
                        </Col>
                    ))}
                </Row>
                {RenderPeopleWithPermission()}
            </Animate>
        </div>
    )
}