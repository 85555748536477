import React, { useState, useEffect, useContext, useRef } from 'react';
// Libraries
import { Row, Col, Form } from 'antd';
// Contexts
import SunEditor from 'suneditor-react';
import { SIGAMContext } from '~/contexts/SIGAMContext';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import Checkbox from '~/components/checkbox';
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function EditForm({ form, record }) {
  const { activeInstitution } = useContext(SIGAMContext);
  const [html, setHTML] = useState();
  const [initialValues, setInitialValues] = useState();
  const [loadingForm, setLoadingForm] = useState(true);
  const editor = useRef();

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  async function onPaste(event, cleanData, maxCharCount, core) {
    const stripedHtml = cleanData.replace(/<(?!\s*br\s*\/?)[^>]+>/gi, '');
    return stripedHtml;
  }

  useEffect(() => {
    async function GetBody() {
      const params = {
        operation: ['SIGAM', 'DocumentTemplates', 'Show'],
        urlParams: {
          institutionId: activeInstitution.id,
          modelId: record.id,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setHTML(response.body.documentModel.body);
        const newData = record;
        newData.status = record.status === 'ACTIVE';
        newData.body = response.body.documentModel.body;
        setInitialValues(newData);
        setLoadingForm(false);
      }
    }
    if (record) {
      GetBody();
    }
  }, [record, activeInstitution, form]);

  if (loadingForm) {
    return <Loading text="Carregando formulário" />;
  }

  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      form={form}
      layout="vertical"
      size="small"
      scrollToFirstError
      initialValues={initialValues}
    >
      <Row>
        <Col span={24}>
          <FormItem
            name={['body']}
            item={
              <SunEditor
                lang="pt_br"
                name="body"
                defaultValue={html}
                autoFocus
                getSunEditorInstance={getSunEditorInstance}
                setOptions={{
                  height: 200,
                  buttonList: [
                    ['undo', 'redo'],
                    ['font', 'fontSize', 'formatBlock'],
                    ['paragraphStyle', 'blockquote'],
                    [
                      'bold',
                      'underline',
                      'italic',
                      'strike',
                      'subscript',
                      'superscript',
                    ],
                    ['fontColor', 'hiliteColor', 'textStyle'],
                    ['removeFormat'],
                    ['outdent', 'indent'],
                    ['align', 'horizontalRule', 'list', 'lineHeight'],
                    ['table', 'link', 'image'],
                    ['fullScreen', 'showBlocks', 'codeView'],
                  ],
                }}
                onPaste={(event, cleanData, maxCharCount, core) =>
                  onPaste(event, cleanData, maxCharCount, core)
                }
                //onPaste={(event, cleanData, maxCharCount, core) =>event.preventDefault()}
              />
            }
          />
        </Col>
        <Col span={24}>
          <FormItem
            label="Nome do Modelo de Documento"
            name={['name']}
            rules={[
              {
                required: true,
                message: 'Insira um nome para o modelo de documento',
              },
            ]}
            item={<Input />}
          />
        </Col>
        <Col span={24}>
          <FormItem
            label="Situação"
            name="status"
            valuePropName="checked"
            item={<Checkbox text="Modelo de Documento Ativo" />}
          />
        </Col>
      </Row>
    </Form>
  );
}
