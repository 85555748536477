import React from 'react';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Form, Button, Input, Radio } from 'antd';

const { TextArea } = Input;

export default function ArchiveForm({
  form,
  initialValues,
  onFinish,
  submitButtonText,
  loadingButton,
}) {
  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      form={form}
      layout="vertical"
      scrollToFirstError
      onFinish={onFinish}
      initialValues={initialValues}
    >
      <Form.Item
        label="Nome"
        name="name"
        rules={[
          {
            required: true,
            message: 'O nome é obrigatório',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Descrição" name="description" rules={[]}>
        <TextArea rows={4} />
      </Form.Item>

      <Form.Item
        label="Visibilidade"
        name="visibility"
        rules={[
          {
            required: true,
            message: 'Campo obrigatório',
          },
        ]}
      >
        <Radio.Group>
          <Radio.Button value="PUBLIC">Público</Radio.Button>
          <Radio.Button value="PRIVATE">Privado</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Status"
        name="status"
        rules={[
          {
            required: true,
            message: 'Campo obrigatório',
          },
        ]}
      >
        <Radio.Group>
          <Radio.Button value="ACTIVE">Ativo</Radio.Button>
          <Radio.Button value="INACTIVE">Inativo</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.List
        name="metas"
        rules={[
          {
            validator: async (_, metas) => {
              if (!metas || metas.length < 1) {
                return Promise.reject(
                  new Error('É preciso adicionar pelo menos 1 metadados')
                );
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                label={`Metadado ${index + 1}`}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Por favor, digite o nome do metadado',
                    },
                  ]}
                  noStyle
                >
                  <Input
                    style={{
                      width: '90%',
                    }}
                  />
                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    onClick={() => remove(field.name)}
                    style={{ marginLeft: '8px' }}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{
                  width: '100%',
                }}
                icon={<PlusOutlined />}
              >
                Adicionar metadado
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item>
        <Button
          loading={loadingButton}
          type="primary"
          htmlType="submit"
          style={{
            width: '100%',
          }}
        >
          {submitButtonText}
        </Button>
      </Form.Item>
    </Form>
  );
}
