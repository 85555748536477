import React, { useContext, useEffect, useState } from 'react';
// Libraries
import { Tag, message } from 'antd';
// Contexts
import { SIGAMContext } from '~/contexts/SIGAMContext';
// Own Components
import Add from './components/Add';
// Components
import Loading from '~/components/loading';
import Text from '~/components/typography/text';
import Divider from '~/components/divider';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { CapitalizeString } from '~/utils/Transformations';

export default function OrganizationalUnitsContainer({ record, setUpdatePending }) {
    const { activeInstitution } = useContext(SIGAMContext);
    const [loadingContent, setLoadingContent] = useState(true);
    const [organizationalUnits, setOrganizationalUnits] = useState([]);

    async function GetOrganizationalUnitsAgain() {
        setUpdatePending(true)
        const params = {
            operation: ['SIGAM', 'Users', 'GetOrganizationalUnits'],
            urlParams: {
                institutionId: activeInstitution.id,
                personId: record.id,
            },
            data: {
                pagination: {
                    limit: 0,
                },
            },
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            if (response.body.orgUnities.elements.length !== 0) {
                setOrganizationalUnits(response.body.orgUnities.elements);
            }
            setLoadingContent(false);
        }
    }

    async function Remove(event, item) {
        event.preventDefault();
        const params = {
            operation: ['SIGAM', 'OrganizationalUnits', 'Users', 'Delete'],
            urlParams: {
                institutionId: activeInstitution.id,
                organizationUnitId: item.id,
            },
            data: {
                person: record.id,
            },
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            message.success(`${CapitalizeString(record.social_name || record.name)} removido da unidade com sucesso!`);
            GetOrganizationalUnitsAgain()
        } else {
            message.error('Alguma coisa aconteceu...');
        }
    }

    useEffect(() => {
        async function GetOrganizationalUnits() {
            const params = {
                operation: ['SIGAM', 'Users', 'GetOrganizationalUnits'],
                urlParams: {
                    institutionId: activeInstitution.id,
                    personId: record.id,
                },
                data: {
                    pagination: {
                        limit: 0,
                    },
                },
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                if (response.body.orgUnities.elements.length !== 0) {
                    setOrganizationalUnits(response.body.orgUnities.elements);
                }
                setLoadingContent(false);
            }
        }
        GetOrganizationalUnits();
    }, [activeInstitution, record]);

    if (loadingContent) {
        return <Loading withoutText />
    }

    return (
        <div style={{ width: '100%' }}>
            <Divider />
            <Text strong>Unidades Organizacionais</Text>
            <div>
                {organizationalUnits.map(item => (
                    <Tag
                        key={item.name}
                        closable
                        onClose={(event) => Remove(event, item)}
                        color={
                            item.settings && item.settings.color
                                ? item.settings.color
                                : 'default'
                        }
                        style={{ marginBottom: 5 }}
                    >
                        {item.name}
                    </Tag>
                ))}

            </div>
            <Text strong>Adicionar em nova Unidade Organizacional</Text>
            <Add record={record} insertedUnits={organizationalUnits} GetOrganizationalUnitsAgain={GetOrganizationalUnitsAgain} />
        </div>
    )
}