import React, { useContext } from 'react';
// Own Components
import Title from '~/components/typography/title';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import AttachmentView from './components/AttachmentView';
import ExternalView from './components/ExternalView';
import DocumentView from './components/DocumentView';
// Component

export default function FileView({
  selectedFile,
  document,
  signatories,
  attachments,
}) {
  const { isMobile } = useContext(ResponsiveContext);

  return (
    <div
      style={{
        padding: isMobile ? '0px' : '0px 10px 20px 20px',
        marginTop: isMobile ? '40px' : '0px',
      }}
    >
      <Title style={{ textAlign: 'center' }} level={5}>
        Visualização
      </Title>
      {selectedFile && selectedFile.node.type === 'attachment' ? (
        selectedFile.node.data.url ? (
          <ExternalView document={selectedFile.node.data} />
        ) : (
          <AttachmentView
            document={selectedFile.node.data}
            fatherProcess={document}
          />
        )
      ) : document.kind === 'EXTERNAL' ? (
        <ExternalView document={document} />
      ) : (
        <DocumentView
          document={document}
          signatories={signatories}
          attachments={attachments}
        />
      )}
    </div>
  );
}

/* 

<ExternalView document={selectedFile} />
                :
                <AttachmentView document={selectedFile} />

*/
