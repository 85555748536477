// Actions
import AccessLevel from './AccessLevel';
import Attachments from './Attachments';
import Interested from './Interested';
import Proceed from './Proceed';
import Visualize from './Visualize';
import Edit from './Edit';
import Historic from './Historic';
import Close from './Close';

export default {
  AccessLevel: {
    text: 'Nível de Acesso',
    content: AccessLevel,
  },
  Attachments: {
    text: 'Anexos',
    content: Attachments,
  },
  Close: {
    text: 'Encerrar',
    content: Close,
  },
  Interested: {
    text: 'Interessados',
    content: Interested,
  },
  Edit: {
    text: 'Editar',
    content: Edit,
  },
  Historic: {
    text: 'Histórico',
    content: Historic,
  },
  Proceed: {
    text: 'Tramitar',
    content: Proceed,
  },
  Visualize: {
    text: 'Visualizar',
    content: Visualize,
  }
};
