import React from 'react';
// Libraries
import { FiPlusCircle, FiEye, FiSend } from 'react-icons/fi';
import { BiMessageSquareEdit } from 'react-icons/bi';
import { FaUserSecret, FaRegHandshake, FaFileSignature } from 'react-icons/fa';
import { AiOutlineApartment } from 'react-icons/ai';
import { RiBallPenLine } from 'react-icons/ri';
import { GrAttachment } from 'react-icons/gr';
import { MessageOutlined } from '@ant-design/icons';
// Components
import Text from '~/components/typography/text';
import Title from '~/components/typography/title';
// Utils
import { CapitalizeString } from '~/utils/Transformations';

export default function HistoricItem({ item }) {
  function GetFieldValue(value) {
    switch (value) {
      case 'ACTIVE':
        return 'Ativo';
      case 'INACTIVE':
        return 'Inativo';
      case 'PUBLIC':
        return 'Público';
      case 'RESTRICTED':
        return 'Restrito';
      case 'CONFIDENTIAL':
        return 'Sigiloso';
      case '0':
        return 'Não é prioridade';
      case '1':
        return 'É prioridade';
      default:
        return value;
    }
  }

  function GetFieldName(field) {
    switch (field) {
      case 'id':
        return 'Id';
      case 'internal_id':
        return 'Id interno';
      case 'external_id':
        return 'Id externo';
      case 'manual_id':
        return 'manual_id';
      case 'created_at':
        return 'Criado em';
      case 'reated_externally_at':
        return 'Local criado externamente';
      case 'created_by':
        return 'Criado por';
      case 'updated_by':
        return 'Atualizado por';
      case 'created_at_unity':
        return 'Criado pela unidade';
      case 'updated_at_unity':
        return 'Atualizado pela unidade';
      case 'institution':
        return 'Instituição';
      case 'dispatched_by':
        return 'Emitido por';
      case 'subject':
        return 'Assunto';
      case 'model':
        return 'Modelo';
      case 'nature':
        return 'Natureza';
      case 'format':
        return 'Formato';
      case 'status':
        return 'Status';
      case 'kind':
        return 'Tipo';
      case 'name':
        return 'Nome';
      case 'body':
        return 'Corpo';
      case 'url':
        return 'URL';
      case 'description':
        return 'Descrição';
      case 'specifications':
        return 'Especificações';
      case 'settings':
        return 'Configurações';
      case 'privacy':
        return 'Privacidade';
      case 'priority':
        return 'Prioridade';
      case 'verification_id':
        return 'ID de verificação';
      default:
        return 'CAMPO DESCONHECIDO';
    }
  }

  function GetMessage(item) {
    if (item.agent === 'PROCESS') {
      if (item.kind === 'DIRECT CHANGE') {
        if (item.action === 'CREATE') {
          return `O campo "${GetFieldName(
            item.field
          )}" recebeu o valor "${GetFieldValue(item.value)}"`;
        }
        if (item.action === 'ACCESS') {
          return `Visualizado por ${CapitalizeString(item.created_by_name)}`;
        }
        if (item.action === 'UPDATE') {
          if (item.field === 'model') {
            return `O modelo do processo foi atualizado por ${item.created_by_name}`;
          }
          if (item.field === 'body') {
            return `O corpo do processo foi atualizado por ${item.created_by_name}`;
          }
          if (item.field === 'status') {
            return '';
          }
          return `O campo "${GetFieldName(
            item.field
          )}" recebeu o valor "${GetFieldValue(item.value)}"`;
        }
      }
      if (item.kind === 'ASSIGNMENT') {
        if (item.action === 'INTERESTED PART') {
          return `Interessado inserido por ${
            item.created_by_name
          }: ${CapitalizeString(item.assignment_person_name)}`;
        }
        if (item.action === 'SIGNATORY') {
          return `Assinante inserido por ${
            item.created_by_name
          }: ${CapitalizeString(item.assignment_person_name)}`;
        }
        if (item.action === 'PRIVATE ACCESS') {
          return `Pessoa inserida por ${
            item.created_by_name
          }: ${CapitalizeString(item.assignment_person_name)}`;
        }
        if (item.action === 'CO-OWNER') {
          return `Editor inserido por ${
            item.created_by_name
          }: ${CapitalizeString(item.assignment_person_name)}`;
        }
        if (item.action === 'LOGIN-PASSWORD') {
          return `Assinado por: ${CapitalizeString(item.created_by_name)}`;
        }
      }
      if (item.kind === 'UNASSIGNMENT') {
        if (item.action === 'SIGNATORY') {
          return `Assinante removido por ${
            item.created_by_name
          }: ${CapitalizeString(item.assignment_person_name)}`;
        }
        if (item.action === 'PRIVATE ACCESS') {
          return `Pessoa removida por ${
            item.created_by_name
          }: ${CapitalizeString(item.assignment_person_name)}`;
        }
        if (item.action === 'CO-OWNER') {
          return `Editor removido por ${
            item.created_by_name
          }: ${CapitalizeString(item.assignment_person_name)}`;
        }
        if (item.action === 'INTERESTED PART') {
          return `Interessado removido por ${
            item.created_by_name
          }: ${CapitalizeString(item.assignment_person_name)}`;
        }
        if (item.action === 'LOGIN-PASSWORD') {
          return `Assinatura cancelada por: ${CapitalizeString(
            item.created_by_name
          )}`;
        }
      }
      if (item.kind === 'PROCESSING') {
        if (item.action === 'SEND') {
          return `Tramitado de ${CapitalizeString(
            item.created_by_name
          )} para ${CapitalizeString(
            item.receiver_person_name || item.receiver_organizational_unity_name
          )}`;
          // return `Tramitado`;
        }
      }
      if (item.kind === 'ATTACHMENT') {
        if (item.action === 'CREATE') {
          return `Anexo inserido por ${
            item.created_by_name
          }: ${CapitalizeString(item.attached_document_name)}`;
          // return `Tramitado para: ${CapitalizeString(item.receiver_person_name)}`;
        }
        if (item.action === 'UPDATE') {
          return `Anexo atualizado por ${
            item.created_by_name
          }: ${CapitalizeString(item.attached_document_name)}`;
        }
      }
    }
    if (item.agent === 'DOCUMENT ATTACHMENT') {
      if (item.kind === 'DIRECT CHANGE') {
        if (item.action === 'CREATE') {
          if (item.field === 'name') {
            return `Anexo inserido por ${item.created_by_name}: ${item.attachment_name}`;
          }
        }
        if (item.action === 'UPDATE') {
          return `Anexo inativado por ${item.created_by_name}: ${item.attachment_name}`;
        }
      }
    }
  }

  function GetTitle() {
    if (item.action === 'CREATE') {
      if (item.kind === 'ATTACHMENT') {
        return `Lista de anexos atualizada por ${item.created_by_name}:`;
      }
      if (item.agent === 'PROCESS') {
        return `Processo criado por ${item.created_by_name}:`;
      }
      return `Movimentação desconhecida`;
    }
    if (item.action === 'UPDATE') {
      if (item.field === 'body') {
        return `Processo atualizado por ${item.created_by_name}, corpo foi alterado.`;
      }
      if (item.field === 'status') {
        if (item.value === 'INACTIVE') {
          return `O processo foi arquivado por ${item.created_by_name}`;
        }
        return `O processo foi reaberto por ${item.created_by_name}`;
      }
      if (item.agent === 'DOCUMENT ATTACHMENT') {
        return `Lista de anexos atualizada por ${item.created_by_name}:`;
      }
      return `Processo atualizado por ${item.created_by_name}:`;
    }
    if (item.action === 'ACCESS') {
      return `Processo visualizado por ${item.created_by_name}:`;
    }
    if (item.action === 'PRIVATE ACCESS') {
      return `Lista restrita atualizada por ${item.created_by_name}:`;
    }
    if (item.action === 'CO-OWNER') {
      return `Lista de editores atualizada por ${item.created_by_name}:`;
    }
    if (item.action === 'INTERESTED PART') {
      return `Lista de interessados atualizada por ${item.created_by_name}:`;
    }
    if (item.action === 'SIGNATORY') {
      return `Lista de assinantes atualizada por ${item.created_by_name}:`;
    }
    if (item.action === 'LOGIN-PASSWORD') {
      return `Lista de assinaturas atualizada por ${item.created_by_name}:`;
    }
    if (item.action === 'SEND') {
      return `Processo tramitado por ${item.created_by_name}`;
    }
  }

  function GetIcon() {
    if (item.action === 'CREATE') {
      if (item.kind === 'ATTACHMENT') {
        return <GrAttachment />;
      }
      return <FiPlusCircle />;
    }
    if (item.action === 'UPDATE') {
      if (item.agent === 'DOCUMENT ATTACHMENT') {
        return <GrAttachment />;
      }
      return <BiMessageSquareEdit />;
    }
    if (item.action === 'ACCESS') {
      return <FiEye />;
    }
    if (item.action === 'PRIVATE ACCESS') {
      return <FaUserSecret />;
    }
    if (item.action === 'CO-OWNER') {
      return <FaRegHandshake />;
    }
    if (item.action === 'INTERESTED PART') {
      return <AiOutlineApartment />;
    }
    if (item.action === 'SIGNATORY') {
      return <FaFileSignature />;
    }
    if (item.action === 'LOGIN-PASSWORD') {
      return <RiBallPenLine />;
    }
    if (item.action === 'CREATE') {
      return <FiPlusCircle />;
    }
    if (item.action === 'SEND') {
      return <FiSend />;
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: 10, display: 'flex' }}>{GetIcon()}</div>
        <Text>{GetTitle()}</Text>
      </div>
      <div>
        <Title level={5} style={{ margin: 0 }}>
          "<MessageOutlined style={{ margin: '0px 4px' }} />{' '}
          {item.events[0].receiver_specifications}"
        </Title>
      </div>
      <div style={{ paddingLeft: 10 }}>
        {item.events.map((data, index) => (
          <Text
            type="secondary"
            key={`${item.action}${item.agent}${item.kind}${data.id}${index}`}
            style={{ fontSize: '8pt' }}
          >
            {GetMessage(data)}
          </Text>
        ))}
      </div>
    </div>
  );
}
