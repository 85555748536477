import React, { useCallback, useState} from 'react';

import { message } from 'antd'

import Text from '~/components/typography/text';
import Button from '~/components/button';
import Avatar from '~/components/avatar';

import Flex from './style'
import { cpfMask, cnpjMask } from '~/utils/Masks';
import { CapitalizeString, DecodeURI } from '~/utils/Transformations';

import Api from '~/utils/SocialMeAPI';

export default function PersonHeader({ institution, setTotal, onClose }) {
  const [ loading, setLoading ] = useState(false)

  const { id, avatar, name, social_name, cpf_number, cnpj_number } = institution

  const LinkInstitution = useCallback(async () => {
    setLoading(true)

    const params = {
      operation: ["SIGAM","Admin", "Institutions", "Link"],
      urlParams: {
        institutionId: id
      },
    }

    const response = await Api(params)
    if (response.success) {
      message.success("Instituição vinculada com sucesso!")
      setTotal((oldTotal) => oldTotal + 1)
      onClose()
    } else {
      message.error("Esta instituição já está vinculada")
    }

    setLoading(false)
  }, [setTotal, id])

  return (
    <Flex>
      <Avatar shape="squart" style={{ width: 150, height: 150 }} src={DecodeURI(avatar)} />

      <Text style={{ marginTop: 5 }}>{CapitalizeString(social_name || name)}</Text>

      {cnpj_number ? (
        <Text style={{ opacity: 0.4, margin: "5px 0" }}>CNPJ {cnpjMask(cnpj_number)}</Text>
      ) : (
        <Text style={{ opacity: 0.4, margin: "5px 0" }}>CPF {cpfMask(cpf_number)}</Text>
      )}
     

      <Button loading={loading} onClick={LinkInstitution} type="primary">Vincular</Button>
    </Flex>
  );
}
