import React, { useEffect, useContext, useState, useCallback } from 'react';
import PageManagerContext from './Context';
import { useParams } from 'react-router-dom';
import _showDocument from '~/services/calls/document/show';
import _updateDocument from '~/services/calls/document/update';
import _updateUrnDocument from '~/services/calls/document/updateUrn';

export default function PageManagerProvider({ document, children }) {
  const { institutionId, organizationUnitId } = useParams();
  const [loadingContent, setLoadingContent] = useState(true);
  const [lexml, setLexML] = useState('');

  async function updateDocument(values) {
    const response = await _updateDocument({
      institutionId,
      organizationUnitId,
      documentId: document.id,
      data: { lex_ml: values },
    });
    await _updateUrnDocument({
      institutionId,
      organizationUnitId,
      documentId: document.id,
    });
    return response;
  }

  const showDocument = useCallback(async () => {
    setLoadingContent(true);
    const response = await _showDocument({
      institutionId,
      organizationUnitId,
      documentId: document.id,
    });
    if (response.success) {
      console.log(response);
      setLexML(response.body.document.lex_ml);
    }
    setLoadingContent(false);
  }, []);

  useEffect(() => {
    async function readAsyncData() {
      showDocument();
    }
    if (institutionId) {
      readAsyncData();
    }
  }, []);

  return (
    <PageManagerContext.Provider
      value={{ loadingContent, lexml, document, updateDocument }}
    >
      {children}
    </PageManagerContext.Provider>
  );
}

export const usePageManager = () => useContext(PageManagerContext);
