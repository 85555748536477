import React from 'react';
// Libraries
import { Result, message } from 'antd';
import { useParams } from 'react-router-dom';
// Own Components
import ByPassword from './components/ByPassword';
// Components
import Button from '~/components/button';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { UserMainDocument } from '~/utils/UserInformation';

export default function IsSignatory({ document, signature, GetPeopleAgain }) {
    const { institutionId, organizationUnitId } = useParams();
    let mainDocument = UserMainDocument();

    async function Cancel() {
        const params = {
            operation: ['SIGAM', 'Documents', 'Subscriptions', 'Delete'],
            urlParams: {
                institutionId: institutionId,
                organizationUnitId: organizationUnitId,
                documentId: document.id,
                subscriptionId: signature.signature_id
            },
            data: {
                login: mainDocument.replace(/[^\d]+/g, '')
            }
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            message.success('Assinatura retirada com sucesso!');
            GetPeopleAgain();
        } else {
            message.error('Alguma coisa aconteceu...');
        }
    }

    if (signature.signature_id) {
        return (
            <Result
                status="success"
                title="Documento Assinado"
                extra={[
                    <Button type="dashed" key={1} onClick={() => Cancel()}>
                        Cancelar Assinatura
                    </Button>,
                ]}
            />
        )
    }

    return (
        <div>
            <ByPassword document={document} signature={signature} GetPeopleAgain={GetPeopleAgain} />
        </div>
    )
}