import React, { memo } from 'react';
// Standalone Components
import { MainSpace } from './style';

function AntSpace({ align, direction, size, split, children, wrap }) {
  return (
    <MainSpace
      align={align}
      direction={direction}
      size={size}
      split={split}
      wrap={wrap}
    >
      {children}
    </MainSpace>
  );
}

export default memo(AntSpace);
