export default {
  // A
  // B
  // C
  checkTheFieldsAndTryAgain: {
    original: 'Check the fields and try again',
    text: 'Verifique os campos e tente novamente',
  },
  // D
  // E
  enterYourPasswordToAccessTheSystem: {
    original: 'Enter your password to access the system',
    text: 'Insira sua senha para acessar o sistema',
  },
  // F
  // G
  generalTerms: {
    original: 'general Terms',
    text: 'termos gerais',
  },
  // H
  // I
  iAgreeWithTheTerms: {
    original: 'I agree with the terms',
    text: 'Concordo com os termos',
  },
  iDoNotAgreeWithTheTerms: {
    original: 'I do not agree with the terms',
    text: 'Não concordo com os termos',
  },
  invalidLogin: {
    original: 'Invalid login',
    text: 'Login inválido',
  },
  // J
  // K
  // L
  legalPersonRegister: {
    original: 'Legal person register',
    text: 'Registro de pessoa jurídica',
  },
  loginSuccessfully: {
    original: 'Login successfully',
    text: 'Login realizado com sucesso',
  },
  // M
  // N
  notMyCPF: {
    original: 'Not my CPF',
    text: 'Não é o meu CPF',
  },
  notMyCNPJ: {
    original: 'Not my CNPJ',
    text: 'Não é o meu CNPJ',
  },
  // O
  // P
  physicalPersonRegister: {
    original: 'Physical person register',
    text: 'Registro de pessoa física',
  },
  // Q
  // R
  readAndAgreeWith: {
    original: 'Read and agree to the',
    text: 'Li e concordo com os',
  },
  registrationSuccessful: {
    original: 'Registration successful',
    text: 'Registro feito com sucesso',
  },
  // S
  // T
  termsOfUse: {
    original: 'terms of use',
    text: 'termos de uso',
  },
  theCPFIsAlreadyRegistered: {
    original: 'The CPF is already registered',
    text: 'O CPF já se encontra registrado',
  },
  theCNPJIsAlreadyRegistered: {
    original: 'The CNPJ is already registered',
    text: 'O CNPJ já se encontra registrado',
  },
  // U
  // V
  verification: {
    original: 'Verification',
    text: 'Verificação',
  },
  verificationDescription: {
    original: 'VerificationDescription',
    text:
      'Insira seu CPF ou CNPJ no campo abaixo para identificarmos se você ou a sua instituição já se encontra cadastrada no nosso banco de dados.',
  },
  // W
  welcome: {
    original: 'Welcome',
    text: 'Bem vindo!',
  },
  // Y
  youMustAcceptTheTerms: {
    original: 'You must accept the terms',
    text: 'Você deve aceitar os termos',
  },
  // X
  // Z
};
