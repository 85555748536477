import React, { useEffect, useState } from 'react';
// Libraries
import { useParams } from 'react-router-dom';
// Own Components
import HTMLPreview from './components/HTMLPreview';
import FilePreview from './components/FilePreview';
// Components
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Visualize({ document, propertys }) {
    const { institutionId, organizationUnitId } = useParams();
    const [completeDocumentInformation, setCompleteDocumentInformation] = useState([]);
    const [loadingContent, setLoadingContent] = useState(true);

    let documentInformation = document || propertys.document[0];

    useEffect(() => {
        async function GetDocument() {
            const params = {
                operation: ['SIGAM', 'Documents', 'Show'],
                urlParams: {
                    institutionId: institutionId,
                    organizationUnitId: organizationUnitId,
                    documentId: documentInformation.id
                }
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                console.log(response.body.document)
                setCompleteDocumentInformation(response.body.document);
            }
            setLoadingContent(false);
        }
        GetDocument();
    }, [institutionId, organizationUnitId, documentInformation])

    if (loadingContent) {
        return <Loading text="Carregando Documento" />
    }

    function RenderContent(){
        if(documentInformation.kind === 'HTML' || documentInformation.kind === 'Manual' || documentInformation.kind === 'Model'){
            return <HTMLPreview document={completeDocumentInformation} />
        }
        if(documentInformation.kind === 'Upload' || documentInformation.kind === 'External'){
            return  <FilePreview document={completeDocumentInformation} />
        }
        return <></>
    }

    return (
        <div>
            {RenderContent()}
        </div>
    )
}