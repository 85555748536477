import React, { useState } from 'react';
import { Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { useArchivesPageManager } from '../../../../../../../../contexts/ArchivesPageManager/Provider';

export default function DeleteIconButton({ archive }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { DeleteArchive } = useArchivesPageManager();

  async function HandleDeleteArchieve() {
    setLoading(true);
    await DeleteArchive(archive.id);
    setLoading(false);
  }

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Popconfirm
      title="Você tem certeza que desejar deletar este acervo?"
      open={open}
      onConfirm={HandleDeleteArchieve}
      onCancel={onClose}
      okText="Sim, tenho certeza"
      okButtonProps={{
        loading,
      }}
      cancelText="Não, cancelar"
    >
      <DeleteOutlined onClick={onOpen} key="setting" />
    </Popconfirm>
  );
}
