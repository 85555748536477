import styled from 'styled-components';

import { Typography } from 'antd';

const { Text } = Typography;

export const MainText = styled(Text)`
  display: block;
  color: ${(props) => props.color};
  width: ${(style) => style.width};
  height: ${(style) => style.height};
  margin: ${(style) => style.margin};
  margin-top: ${(style) => style.marginTop};
  margin-right: ${(style) => style.marginRight};
  margin-bottom: ${(style) => style.marginBottom};
  margin-left: ${(style) => style.marginLeft};
  background-color: ${(style) => style.backgroundcolor};
  border: ${(style) => style.border};
  text-align: ${(style) => style.textAlign || 'justify'};
  font-size: ${(style) => style.fontSize || '14px'};
  transition: all 0.2s;
`;
