import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  overflow-y: auto;
  background-color: white;
  border-right: 1px solid;
  border-color: #c3c3c3;
`;

export const Header = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px;
`;
