import React, { useState, useEffect } from 'react';
// Libraries
import { Row, Alert, Result, Button } from 'antd';
import Animate, { FadeIn } from 'animate-css-styled-components';
import { useParams } from 'react-router-dom';
// Components
import Loading from '~/components/loading';
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
import Input from '~/components/input';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { UserMainDocument } from '~/utils/UserInformation';

export default function Delete({ process, propertys, CloseDrawer }) {
  const { institutionId, organizationUnitId } = useParams();
  const [loadingContent, setLoadingContent] = useState(true);
  const [accessLevel, setAccessLevel] = useState();
  const [loadingButton, setLoadingButton] = useState(false);
  const [password, setPassword] = useState();
  const mainDocument = UserMainDocument();

  const processInformation = process || propertys.process[0];

  async function onDelete() {
    setLoadingButton(true);
    const params = {
      operation: ['SIGAM', 'Processes', 'Delete'],
      urlParams: {
        institutionId,
        organizationUnitId,
        processId: processInformation.id,
      },
      data: {
        login: mainDocument.replace(/[^\d]+/g, ''),
        password,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      if (propertys && propertys.GetDataAgain) {
        propertys.GetDataAgain();
      }
      if (CloseDrawer) {
        CloseDrawer();
      }
    }
    setLoadingButton(false);
  }

  useEffect(() => {
    async function verifyPermission() {
      const params = {
        operation: ['SIGAM', 'Processes', 'HavePermission'],
        urlParams: {
          institutionId,
          organizationUnitId,
          processId: processInformation.id,
        },
      };
      const response = await SocialMeAPI(params);
      setAccessLevel(response.body.access_level);
      setLoadingContent(false);
    }
    verifyPermission();
  }, [institutionId, organizationUnitId, processInformation]);

  if (loadingContent) {
    return <Loading />;
  }

  if (accessLevel?.is_in_charge === false) {
    return (
      <Result
        status="error"
        title="Você não tem permissão para deletar o processo"
      />
    );
  }

  if (processInformation.total_tramitations > 0) {
    return (
      <Result
        status="error"
        title="Não pode deletar um processo que já foi tramitado"
      />
    );
  }

  return (
    <div>
      <Alert
        style={{ marginBottom: '10px' }}
        message="Uma vez que o processo seja deletado, você não terá mais acesso ao mesmo"
        type="warning"
        showIcon
      />
      <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
        <Row>
          <Title style={{ textAlign: 'center', width: '100%' }} level={4}>
            Deletar processo
          </Title>
          <Text style={{ textAlign: 'center', width: '100%' }}>
            Uma vez que o processo seja deletado, você não terá mais acesso ao
            mesmo. Você tem certeza disso?
          </Text>
        </Row>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '200px',
              gap: 10,
            }}
          >
            <Text style={{ textAlign: 'center' }}>
              Insira seus dados de acesso para confirmar
            </Text>
            <Input value={mainDocument} />
            <Input
              type="password"
              onChange={(event) => setPassword(event.target.value)}
            />
            <Button
              danger
              style={{
                backgroundColor: '#ff4d4f',
                color: 'white',
              }}
              onClick={() => onDelete()}
              loading={loadingButton}
            >
              Sim, deletar processo
            </Button>
          </div>
        </Row>
      </Animate>
    </div>
  );
}
