import React, { useEffect, useState } from 'react';
// Libraries
import { Tree, Badge } from 'antd';
import { useParams } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
// Components
import Loading from '~/components/loading';
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function FilesList({
  document,
  setSelectedFile,
  setAttachments,
}) {
  const { institutionId, organizationUnitId } = useParams();
  const [treeData, setTreeData] = useState();
  const [loadingContent, setLoadingContent] = useState(true);

  useEffect(() => {
    async function GetAttachments() {
      const params = {
        operation: ['SIGAM', 'Documents', 'Attachments', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
          documentId: document.id,
        },
        data: {
          pagination: {
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      // console.log(response)
      if (response.success) {
        const tree = [];
        const father = {
          title: document.name,
          key: '1',
          type: 'document',
          data: document,
          children: [],
        };
        const array = response.body.attachments.elements;
        for (let i = 0; i < array.length; i++) {
          const newItem = {
            title: (
              <Text>
                <Badge
                  color={array[i].status === 'INACTIVE' ? 'red' : 'green'}
                  style={{ marginRight: '2px' }}
                />
                {array[i].name}
              </Text>
            ),
            key: `1-${i + 1}`,
            type: 'attachment',
            data: array[i],
          };
          father.children.push(newItem);
        }
        tree.push(father);
        // console.log(tree)
        setTreeData(tree);
      }
      setLoadingContent(false);
    }
    GetAttachments();
  }, [institutionId, organizationUnitId, setAttachments]);

  return (
    <div>
      <Title style={{ textAlign: 'center' }} level={5}>
        Anexos
      </Title>
      {loadingContent ? (
        <Loading />
      ) : (
        <Tree
          onSelect={(key, info) => setSelectedFile(info)}
          showIcon
          defaultExpandAll
          defaultSelectedKeys={['1']}
          switcherIcon={<DownOutlined />}
          treeData={treeData}
        />
      )}
    </div>
  );
}
