import React, { useEffect, useState } from 'react';
// Libraries
import { Alert } from 'antd';
import { GrDocumentPdf } from 'react-icons/gr';
// Own Components
import UploadFile from './components/UploadFile';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import Select from '~/components/select';
import Text from '~/components/typography/text';
import Button from '~/components/button';
import { dataBrasileiraMask } from '~/utils/Masks';

export default function ExternalForm({ form }) {
  const [file, setFile] = useState();

  const selectFormato = [
    {
      value: 'Nato Digital Externo',
      label: 'Nato Digital Externo',
    },
    {
      value: 'Digitalizado',
      label: 'Digitalizado',
    },
  ];

  const HandleChange = () => (event) => {
    return form.setFieldsValue({
      created_externally_at: dataBrasileiraMask(event.target.value),
    });
  };

  useEffect(() => {
    if (file) {
      form.setFieldsValue({ url: file.url });
    }
  }, [file, form]);

  return (
    <div>
      <FormItem
        label="Anexar arquivo"
        name={['url']}
        item={
          <>
            <Text type="secondary">
              O arquivo deverá ser no formato PDF e ter no máximo 10 MB
            </Text>
            <UploadFile setFile={setFile} />
            {file && (
              <Alert
                message="Ao anexar um novo arquivo, você estará atualizando o arquivo desse documento"
                type="info"
                showIcon
              />
            )}
          </>
        }
      />

      {file && (
        <div
          style={{
            margin: '0px 0px 30px',
          }}
        >
          <Text type="secondary">Arquivo anexado</Text>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              margin: '10px 0px',
            }}
          >
            <GrDocumentPdf style={{ fontSize: '18pt', marginRight: 10 }} />
            <Text style={{ marginRight: 10 }}>{file.name}</Text>
            <a href={file.url} target="_blank" rel="noopener noreferrer">
              <Button type="dashed" size="small">
                Visualizar
              </Button>
            </a>
          </div>
        </div>
      )}

      <FormItem
        label="ID externo do documento"
        name={['external_id']}
        item={<Input />}
      />
      <FormItem
        label="Data de criação"
        name={['created_externally_at']}
        item={<Input onChange={HandleChange()} />}
      />
      <FormItem
        label="Formato"
        name={['format']}
        item={<Select options={selectFormato} noDefaultValue />}
      />
      <FormItem
        label="Órgão Emissor"
        name={['dispatched_by']}
        item={<Input />}
      />
    </div>
  );
}
