import { message } from 'antd';
import SigamAPI from '~/utils/SigamAPI';

export default async function UploadArchiveFile({
  file,
  archiveId,
  institutionId,
  onUploadProgress,
}) {
  const data = new FormData();

  data.append('file', file);

  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/archive/${archiveId}/upload`,
    method: 'POST',
    data,
    onUploadProgress,
  });

  if (response.success) {
    message.success('Upload do arquivo feito com sucesso');
  } else {
    message.error('Erro ao subir o arquivo');
  }

  return response;
}
