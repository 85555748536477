import styled from "styled-components";

import { Collapse } from 'antd';

const { Panel } = Collapse;

export const Cell = styled.div`
  width: 100%;
  padding: 10px 5px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Space = styled.div`
  margin-bottom: 10px;
`;

export const MainCollapse = styled(Collapse)`

`;

export const MainPanel = styled(Panel)`
  background-color: ${props => props.backgroundColor};
  width: 100%;

  & div.ant-collapse-header {
    color: ${props => `${props.color} !important`};
    text-align: left;
    font-size: 16px;
    font-weight: 700;

    & span {
      font-size: 16px;
    }
  }
`;

