import React, { useState} from 'react';
// Libraries
import { Form, message } from 'antd';
// Components
import Button from '~/components/button';
import Divider from '~/components/divider';
// Questions
import BasicInformation from './components/BasicInformation';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Password({ person, GetProfileAgain }) {
  const [form] = Form.useForm();
  const [callingAPI, setCallingAPI] = useState(false);

  async function EditProfile(data) {
    setCallingAPI(true);
    const params = {
      operation: ['Profile', 'Password', 'Change'],
      data,
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      message.success('Senha atualizada com sucesso!');
      form.resetFields()
      // GetProfileAgain();
    } else {
      message.error('Alguma coisa aconteceu...');
    }
    return setCallingAPI(false);
  }

  async function onFinish(data) {
    const newData = {
      password: data.auth_password,
      reset_login: false
    }
    EditProfile(newData);
  }

  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      form={form}
      size="small"
      layout="vertical"
      onFinish={onFinish}
      scrollToFirstError
      initialValues={person}
      style={{ marginTop: 10 }}
    >
      <BasicInformation />

      <Divider style={{ margin: '10px 0px' }} />
      <Form.Item wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}>
        <Button
          loading={callingAPI}
          style={{ float: 'right' }}
          size="default"
          type="primary"
          htmlType="submit"
        >
          Atualizar Senha
        </Button>
      </Form.Item>
    </Form>
  );
}
