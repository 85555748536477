import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import {
  Form,
  Button,
  Drawer,
  Select,
  Segmented,
  Mentions,
  Alert,
  Collapse,
  Input,
  message,
  Typography,
} from 'antd';
import _readTemplates from '~/services/calls/communication/templates/read';
import _showTemplates from '~/services/calls/communication/templates/show';
import { usePageManager } from '../../../../contexts/PageManager/Provider';
import SearchDocument from './components/SearchDocument';
import SearchProcess from './components/SearchProcess';

const { Option } = Mentions;
const { Panel } = Collapse;
const { Text } = Typography;

const INITIAL_VALUES = {
  api: 'Whatsapp',
  method: 'Mensagem em branco',
};

export default function NewMessage() {
  const [form] = Form.useForm();
  const { createMessage } = usePageManager();
  const { institutionId } = useParams();
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState(INITIAL_VALUES);
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [selectedDocument, setSelectedDocument] = useState();
  const [selectedProcess, setSelectedProcess] = useState();

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onFinish = async (values) => {
    if (selectedDocument) {
      values.documentId = selectedDocument.id;
    }
    if (selectedProcess) {
      values.processId = selectedProcess.id;
    }
    const response = await createMessage(values, values.api);
    if (response) {
      form.resetFields();
      message.success('Mensagem criada com sucesso!');
    }
  };

  useEffect(() => {
    async function showTemplate() {
      const response = await _showTemplates({
        institutionId,
        templateId: selectedTemplate,
      });
      if (response.success) {
        form.setFieldValue('body', response.body.communication_model.body);
      }
    }
    if (selectedTemplate) {
      showTemplate();
    }
  }, [selectedTemplate]);

  useEffect(() => {
    async function getTemplates() {
      const response = await _readTemplates({
        institutionId,
        data: {
          pagination: {
            limit: 0,
          },
        },
      });
      if (response.success) {
        let array = response.body.communication_models.elements;
        let options = [];
        array.map((item) => {
          options.push({ label: item.name, value: item.id });
        });
        setTemplates(options);
        setLoadingTemplates(false);
      }
    }
    getTemplates();
    if (values && values.method === 'Baseada em modelo') {
      getTemplates();
    }
  }, []);

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={showDrawer}>
        Nova mensagem
      </Button>
      <Drawer
        title="Nova mensagem"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <Form
          labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
          wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
          form={form}
          layout="vertical"
          scrollToFirstError
          onFinish={onFinish}
          onChange={() => setValues(form.getFieldsValue())}
          initialValues={INITIAL_VALUES}
        >
          <Form.Item
            label="Selecione a API"
            name="api"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <Segmented options={['Whatsapp', 'Telegram', 'SMS']} />
          </Form.Item>
          <Form.Item
            label="Selecione a forma de criar a mensagem"
            name="method"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <Segmented options={['Mensagem em branco', 'Baseada em modelo']} />
          </Form.Item>

          <div style={{ margin: '10px 0px 20px' }}>
            <Text>Campos dinâmicos</Text>
            <div>
              <SearchDocument setSelectedDocument={setSelectedDocument} />
              {selectedDocument && (
                <div style={{ display: 'flex', gap: 4 }}>
                  <Text>Selecionado: </Text>
                  <Text type="success">{selectedDocument.name}</Text>
                </div>
              )}
            </div>
            <div>
              <SearchProcess setSelectedProcess={setSelectedProcess} />
              {selectedProcess && (
                <div style={{ display: 'flex', gap: 4 }}>
                  <Text>Selecionado: </Text>
                  <Text type="success">{selectedProcess.name}</Text>
                </div>
              )}
            </div>
          </div>

          {values.method !== 'Mensagem em branco' && (
            <>
              <Form.Item
                label="Selecione o modelo"
                name="template"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]}
              >
                <Select
                  loading={loadingTemplates}
                  disabled={loadingTemplates}
                  onChange={(value) => setSelectedTemplate(value)}
                  options={templates}
                />
              </Form.Item>
            </>
          )}

          <Form.Item
            label="Digite a mensagem"
            name="body"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <Mentions rows={4}>
              <Option value="DOCUMENT_NAME@">DOCUMENT_NAME</Option>
              <Option value="DOCUMENT_INTERNAL_ID@">
                DOCUMENT_INTERNAL_ID
              </Option>
              <Option value="PROCESS_NAME@">PROCESS_NAME</Option>
              <Option value="PROCESS_INTERNAL_ID@">PROCESS_INTERNAL_ID</Option>
            </Mentions>
          </Form.Item>
          <Collapse bordered={false} style={{ marginBottom: '10px' }}>
            <Panel header="Precisa de ajuda?" key="1">
              <Alert
                description="Você pode inserir dados dinâmicos na mensagem digitando o símbolo '@'. Os campos dinâmico serão substituidos pelos seus respectivos valores antes da mensagem ser enviada"
                type="info"
              />
            </Panel>
          </Collapse>

          <Text>Contatos</Text>
          <Form.List
            name="contacts"
            rules={[
              {
                validator: async (_, contacts) => {
                  if (!contacts || contacts.length < 1) {
                    return Promise.reject(
                      new Error('É preciso adicionar pelo menos 1 contato')
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    label={`Contato ${index + 1}`}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Por favor, digite o contato',
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        style={{
                          width: '90%',
                        }}
                      />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        onClick={() => remove(field.name)}
                        style={{ marginLeft: '8px' }}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{
                      width: '100%',
                    }}
                    icon={<PlusOutlined />}
                  >
                    Adicionar contato
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: '100%',
              }}
            >
              Enviar mensagem
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
