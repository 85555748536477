import { message } from 'antd';
import SigamAPI from '~/utils/SigamAPI';

export default async function GetArchiveFiles({
  filters,
  archiveId,
  institutionId,
}) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/archive/${archiveId}/files`,
    method: 'POST',
    data: filters,
  });

  if (!response.success) {
    message.error('Erro ao listas o arquivos');
  }

  return response;
}
