import React, { useContext, useEffect, useState } from 'react';
// Contexts
import { SIGAMContext } from '~/contexts/SIGAMContext';
// Own Components
import Header from './components/Header';
import Content from './components/Content';
// Components
import Loading from '~/components/loading';
import UpdatePendingCard from '~/pages/SIGAM/components/Cards/UpdatePendingCard';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function DocumentTemplates() {
  const { activeInstitution } = useContext(SIGAMContext);
  const [loadingContent, setLoadingContent] = useState(true);
  const [searchInput, setSearchInput] = useState();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Buscando Modelos de Documentos');
  const [documentTemplates, setDocumentTemplates] = useState();
  const [pageParameters, setPageParameters] = useState({
    currentPage: 1,
    totalElements: 0,
    limit: 10,
  });
  const [updatePending, setUpdatePending] = useState(false);

  async function GetDataAgain(newPageParameters) {
    setLoadingMessage('Atualizando Modelos de Documentos');
    // setUpdatePending(false);
    setLoadingContent(true);
    const params = {
      operation: ['SIGAM', 'DocumentTemplates', 'Get'],
      urlParams: {
        institutionId: activeInstitution.id,
      },
      data: {
        filter: {
          searchString: searchInput,
        },
        pagination: {
          limit: newPageParameters
            ? newPageParameters.limit
            : pageParameters.limit,
          page: newPageParameters
            ? newPageParameters.currentPage
            : pageParameters.currentPage,
          sort: {
            by: 'id',
            order: 'asc',
          },
        },
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      setPageParameters({
        ...pageParameters,
        currentPage: response.body.documentModels.currentPage,
        totalElements: response.body.documentModels.totalElements,
        limit: response.body.documentModels.limit,
      });
      setDocumentTemplates(response.body.documentModels.elements);
      setLoadingContent(false);
    }
  }

  useEffect(() => {
    async function GetDocumentTemplates() {
      const params = {
        operation: ['SIGAM', 'DocumentTemplates', 'Get'],
        urlParams: {
          institutionId: activeInstitution.id,
        },
        data: {
          filter: {
            searchString: searchInput,
          },
          pagination: {
            sort: {
              by: 'id',
              order: 'asc',
            },
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setPageParameters({
          currentPage: response.body.documentModels.currentPage,
          totalElements: response.body.documentModels.totalElements,
          limit: response.body.documentModels.limit,
        });
        setDocumentTemplates(response.body.documentModels.elements);
        if (searchInput) {
          setLoadingSearch(false)
        }
      }
      setLoadingContent(false);
    }
    if (searchInput) {
      setLoadingSearch(true)
    }
    GetDocumentTemplates();
  }, [activeInstitution, searchInput]);

  return (
    <div>
      <Header
        GetDataAgain={GetDataAgain}
        setSearchInput={setSearchInput}
        loadingSearch={loadingSearch}
      />
      {loadingContent ? (
        <Loading text={loadingMessage} />
      ) : (
        <>
          <UpdatePendingCard
            GetDataAgain={GetDataAgain}
            updatePending={updatePending}
          />
          <Content
            data={documentTemplates}
            pageParameters={pageParameters}
            GetDataAgain={GetDataAgain}
            setUpdatePending={setUpdatePending}
          />
        </>
      )}
    </div>
  );
}
