import React, { useContext } from 'react';
// Libraries
import {
  CloseCircleOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Components
import FormItem from '~/components/form/FormItem';
import Checkbox from '~/components/checkbox';
import Button from '~/components/button';
import Divider from '~/components/divider';

export default function CheckboxGroup({ permissions, form }) {
  const { isMobile } = useContext(ResponsiveContext);

  // console.log(permissions)

  function CheckAll() {
    for (const key in permissions) {
      for (let i = 0; i < permissions[key].length; i++) {
        form.setFieldsValue({ [permissions[key][i].name]: true })
      }
    }
  }

  function RemoveAll() {
    for (const key in permissions) {
      for (let i = 0; i < permissions[key].length; i++) {
        form.setFieldsValue({ [permissions[key][i].name]: false })
      }
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: isMobile ? 'column' : 'row' }}>
        <Button
          onClick={() => CheckAll()}
          type="dashed"
          icon={<CheckCircleOutlined />}
          style={{ margin: '0px 10px 10px 0px' }}
        >
          Selecionar todas
          </Button>
        <Button
          onClick={() => RemoveAll()}
          type="dashed"
          icon={<CloseCircleOutlined />}
          style={{ marginBottom: 10 }}
        >
          Desmarcar todas
          </Button>
      </div>
      <div>
        <Divider>Processos</Divider>
        {permissions.Processos.map((item) => (
          <FormItem
            key={item.name}
            name={item.name}
            valuePropName="checked"
            item={<Checkbox text={item.title} helpText={item.desc} />}
          />
        ))}
      </div>
      <div>
        <Divider>Documentos</Divider>
        {permissions.Documentos.map((item) => (
          <FormItem
            key={item.name}
            name={item.name}
            valuePropName="checked"
            item={<Checkbox text={item.title} helpText={item.desc} />}
          />
        ))}
      </div>
      <div>
        <Divider>Pessoas</Divider>
        {permissions.Pessoas.map((item) => (
          <FormItem
            key={item.name}
            name={item.name}
            valuePropName="checked"
            item={<Checkbox text={item.title} helpText={item.desc} />}
          />
        ))}
      </div>
      <div>
        <Divider>Unidades</Divider>
        {permissions['Unidades Organizacionais'].map((item) => (
          <FormItem
            key={item.name}
            name={item.name}
            valuePropName="checked"
            item={<Checkbox text={item.title} helpText={item.desc} />}
          />
        ))}
      </div>
      {/*
      <div>
        <Divider>Grupos</Divider>
        {permissions.Grupos.map((item) => (
          <FormItem
            key={item.name}
            name={item.name}
            valuePropName="checked"
            item={<Checkbox text={item.title} helpText={item.desc} />}
          />
        ))}
      </div>
      */}
    </div>
  );
}


