import React, { useEffect, useState } from 'react';
import { Button, Drawer, Card, Typography, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { MdScanner } from 'react-icons/md';

const { Text } = Typography;

export default function ScannerButton() {
  const [open, setOpen] = useState(false);
  const [searching, setSearching] = useState(true);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  function fakeSearch() {
    setSearching(true);
    setTimeout(function () {
      setSearching(false);
    }, 15000);
  }

  useEffect(() => {
    setTimeout(function () {
      setSearching(false);
    }, 15000);
  }, []);

  return (
    <>
      <div
        onClick={showDrawer}
        className="option-container"
        style={{
          border: '1px solid #d9d9d9',
          borderStyle: 'dashed',
          padding: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          borderRadius: 10,
          cursor: 'pointer',
          backgroundColor: '#fafafa',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          <MdScanner />
          <Text style={{ fontWeight: 'bold' }}>Escanear</Text>
        </div>
        <Text>Utilize um scanner</Text>
      </div>

      <Drawer title="Escanear" placement="right" onClose={onClose} open={open}>
        {searching ? (
          <div
            style={{ display: 'flex', justifyContent: 'center', padding: 10 }}
          >
            <Spin size="large" tip="Procurando dispositivos..." />
          </div>
        ) : (
          <div
            style={{ display: 'flex', justifyContent: 'center', padding: 10 }}
          >
            <Button
              icon={<SearchOutlined />}
              type="primary"
              onClick={() => fakeSearch()}
            >
              Procurar dispositivos
            </Button>
          </div>
        )}
      </Drawer>
    </>
  );
}
