export default {
  production: true,
  ResponsiveBreakpoint: 680,
  hostname: {
    administration: 'admin.socialme.com.br',
    authentication: 'conta.socialme.com.br',
    accountManagement: 'perfil.socialme.com.br',
    beneficiary: 'beneficiario.socialme.com.br',
    citizen: '',
    digitalWallet: 'carteira.socialme.com.br',
    donationsPortal: 'doacoes.me',
    donor: 'doador.socialme.com.br',
    ecoop: 'ropetec.com.br',
    main: 'socialme.com.br',
    manager: 'gestor.socialme.com.br',
    marketplace: 'feiralivre.me',
    medicalRecord: '',
    operationsFinder: 'achei.me',
    partner: 'parceiro.socialme.com.br',
    paymentGateway: 'pagamentos.socialme.com.br',
    telephoneOperator: '',
    SIGAM: 'www.sigam.net.br',
    SIGAMDev: 'dev.sigam.net.br',
    SIGAMApp: 'app.sigam.net.br',
  },
  cookie: {
    expire: 'Thu, 30 Dec 2060 23:59:59 UTC',
    name: {
      browser: 'brwsrtkn',
      user: 'usrtkn',
      theme: 'usrthm',
      language: 'usrlngg',
      wallet: 'wllttkn',
      acceptedCookies: 'accptdcks',
    },
    domain: {
      administration: '.socialme.com.br',
      authentication: '.socialme.com.br',
      accountManagement: '.socialme.com.br',
      beneficiary: '.socialme.com.br',
      citizen: '',
      digitalWallet: '.socialme.com.br',
      donationsPortal: '.doacoes.me',
      donor: '.socialme.com.br',
      ecoop: '',
      manager: '.socialme.com.br',
      marketplace: '.feiralivre.me',
      medicalRecord: '',
      operationsFinder: '.achei.me',
      partner: '.socialme.com.br',
      paymentGateway: '.socialme.com.br',
      telephoneOperator: '',
      SIGAM: 'app.sigam.net.br',
      SIGAMDev: 'dev.sigam.net.br',
      SIGAMApp: 'app.sigam.net.br',
    },
  },
  gateway: {
    origins: {
      digitalWallet: 'SME-WALLET',
      donationsPortal: 'SME-DONATIONS',
      partner: 'SME-PDV',
      localhost: 'SME-WALLET',
    },
    agents: {
      donationProductService: 'SME-DONATIONS-SINGLE',
      localhost: 'SME-PERSON-MANUAL-CASHIN',
      partnerProductService: 'SME-OFFERING-SINGLE',
      person: 'SME-PERSON',
      personManualCashIn: 'SME-PERSON-MANUAL-CASHIN',
      personManualCashOut: 'SME-PERSON-MANUAL-CASHOUT',
      sellSingleDonation: 'SME-SELL-OFFERING-SINGLE',
    },
    operations: {
      internalDonationCCtoCS: 'SME-INTERNAL-DONATION-CC-CS',
    },
  },
};

// SIGAM 102384
