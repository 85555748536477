import React from 'react';
//Libraries
import { Row, Col, Form } from 'antd';
//Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import TextArea from '~/components/textArea';
import Checkbox from '~/components/checkbox';

export default function AddForm({ form }) {
  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      form={form}
      layout="vertical"
      size="small"
      scrollToFirstError
    >
      <Row>
        <Col span={24}>
          <FormItem
            label="Nome do tipo de processo"
            name={['name']}
            rules={[
              {
                required: true,
                message: 'Insira um nome para o tipo de processo',
              },
            ]}
            item={<Input />}
          />
        </Col>
        <Col span={24}>
          <FormItem
            label="Situação"
            name="status"
            valuePropName="checked"
            item={<Checkbox text={'Tipo de processo ativo'} />}
          />
        </Col>
        <Col span={24}>
          <FormItem
            label={'Descrição'}
            name={['description']}
            item={<TextArea />}
          />
        </Col>
        <Col span={24}>
          <FormItem
            label={'Especificações'}
            name={['specifications']}
            item={<TextArea />}
          />
        </Col>
      </Row>
    </Form>
  );
}