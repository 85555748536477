import React, { useEffect, useState } from 'react';
// Libraries
import { Result } from 'antd';
import { useParams } from 'react-router-dom';
import Loading from '~/components/loading';
// Own Components
import IsSignatory from './components/IsSignatory';
import NotSignatory from './components/NotSignatory';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { UserMainDocument, UserId } from '~/utils/UserInformation';

export default function Subscribe({ process, propertys }) {
  const { institutionId, organizationUnitId } = useParams();
  const [signature, setSignature] = useState([]);
  const [isSignatory, setIsSignatory] = useState(false);
  const [loadingContent, setLoadingContent] = useState(true);
  const mainDocument = UserMainDocument();
  const id = UserId();

  const processInformation = process || propertys.process[0];

  async function GetPeopleAgain() {
    if (propertys && propertys.GetDataAgain()) {
      propertys.GetDataAgain();
    }
    const params = {
      operation: ['SIGAM', 'Processes', 'Signatories', 'Get'],
      urlParams: {
        institutionId,
        organizationUnitId,
        processId: processInformation.id,
      },
      data: {
        filter: {
          searchString: mainDocument.replace(/[^\d]+/g, ''),
        },
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      if (response.body.signatory.elements.length > 0) {
        if (id === response.body.signatory.elements[0].person_id) {
          setIsSignatory(true);
          setSignature(response.body.signatory.elements[0]);
        }
      }
    }
  }

  useEffect(() => {
    async function GetPeople() {
      const params = {
        operation: ['SIGAM', 'Processes', 'Signatories', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
          processId: processInformation.id,
        },
        data: {
          filter: {
            searchString: mainDocument.replace(/[^\d]+/g, ''),
          },
        },
      };
      const response = await SocialMeAPI(params);
      // console.log(response)
      if (response.success) {
        if (response.body.signatory.elements.length > 0) {
          if (id === response.body.signatory.elements[0].person_id) {
            setIsSignatory(true);
            setSignature(response.body.signatory.elements[0]);
          }
        }
      }
      setLoadingContent(false);
    }
    GetPeople();
  }, [processInformation, institutionId, organizationUnitId, id, mainDocument]);

  if (processInformation.status === 'INACTIVE') {
    return <Result status="error" title="Processo já se encontra arquivado" />;
  }

  if (loadingContent) {
    return <Loading text="Verificando Assinaturas" />;
  }

  function RenderContent() {
    if (isSignatory) {
      return (
        <IsSignatory
          process={processInformation}
          signature={signature}
          GetPeopleAgain={GetPeopleAgain}
        />
      );
    }
    return <NotSignatory />;
  }

  return <div>{RenderContent()}</div>;
}
