import styled from 'styled-components';

import { Input } from 'antd';

export const SearchInput = styled(Input.Search)`
  width: ${(props) => props.width || '100%'};
  margin: ${(props) => props.margin || '0px'};
  background-color: ${(props) => props.backgroundcolor};
  color: ${(props) => props.color};
  border-radius: 10;
`;
