import React, { memo } from "react";

import Badge from "~/components/badge";

import { Flex } from "./style";

function BadgeCell({ text, status }) {
  return (
    <Flex>
      <Badge status={status} text={text} />
    </Flex>
  );
}
export default memo(BadgeCell);
