import React from 'react';
import { Pagination } from 'antd';

import { usePageManager } from '../../../../contexts/PageManager/Provider';

export default function CustomPagination() {
  const { totalItems, pagination, setPagination } = usePageManager();

  const onChange = (current, pageSize) => {
    setPagination({ ...pagination, page: current, limit: pageSize });
  };

  return (
    <Pagination
      total={totalItems}
      defaultCurrent={pagination.page}
      defaultPageSize={pagination.limit}
      onChange={onChange}
      showSizeChanger
      showQuickJumper
      showTotal={(total) => `${total} registros`}
      style={{ marginTop: '8px' }}
    />
  );
}
