import React from 'react';
// Libraries
import { List, message, Alert } from 'antd';
import { useParams } from 'react-router-dom';
import Animate, { FadeIn } from 'animate-css-styled-components';
// Own Components
import Visualize from '../../../../../../Documents/components/Actions/Visualize';
// Components
import Button from '~/components/button';
import Drawer from '~/components/drawer';
import Text from '~/components/typography/text';
// Utils
import { CapitalizeString } from '~/utils/Transformations';
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function AttachmentsList({
  attachments,
  process,
  GetAttachmentsAgain,
  accessLevel,
}) {
  const { institutionId, organizationUnitId } = useParams();

  async function Inactivate(item) {
    const params = {
      operation:
        process.total_tramitations == 0
          ? ['SIGAM', 'Processes', 'Attachments', 'RealDelete']
          : ['SIGAM', 'Processes', 'Attachments', 'Delete'],
      urlParams: {
        institutionId,
        organizationUnitId,
        processId: process.id,
        documentId: item.id,
      },
    };
    const response = await SocialMeAPI(params);
    // console.log(response)
    if (response.success) {
      message.success('Anexo inativado com sucesso!');
      GetAttachmentsAgain();
    } else {
      message.error('Alguma coisa aconteceu...');
    }
  }

  return (
    <div style={{ marginTop: 10 }}>
      <List
        itemLayout="horizontal"
        dataSource={attachments}
        locale={{ emptyText: 'O processo não possui anexos' }}
        renderItem={(item) => (
          <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
            <List.Item
              actions={
                accessLevel && accessLevel.is_in_charge
                  ? [
                      <Drawer
                        Trigger={
                          <Button type="dashed" size="small">
                            Visualizar
                          </Button>
                        }
                        DrawerContentComponent={Visualize}
                        DrawerContentComponentParams={{ document: [item] }}
                        title={`${item.name}`}
                        width="100%"
                      />,
                      item.status === 'INACTIVE' ? (
                        <Alert message="Inativo" type="error" showIcon />
                      ) : (
                        <Button
                          size="small"
                          onClick={() => Inactivate(item)}
                          danger
                        >
                          {process.total_tramitations == 0
                            ? 'Apagar anexo'
                            : 'Inativar anexo'}
                        </Button>
                      ),
                    ]
                  : [
                      <Drawer
                        Trigger={
                          <Button type="dashed" size="small">
                            Visualizar
                          </Button>
                        }
                        DrawerContentComponent={Visualize}
                        DrawerContentComponentParams={{ document: [item] }}
                        title={`${item.name}`}
                        width="100%"
                      />,
                    ]
              }
            >
              <List.Item.Meta
                title={
                  <Drawer
                    Trigger={
                      <Text style={{ cursor: 'pointer' }}>
                        {CapitalizeString(item.name)}
                      </Text>
                    }
                    DrawerContentComponent={Visualize}
                    DrawerContentComponentParams={{ document: [item] }}
                    title={`${item.name}`}
                    width="100%"
                  />
                }
              />
            </List.Item>
          </Animate>
        )}
      />
    </div>
  );
}
