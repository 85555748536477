import React, { useEffect, useState, useContext } from 'react';
// Libraries
import Animate, { FadeIn } from 'animate-css-styled-components';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Components
import Avatar from '~/components/avatar';
import Loading from '~/components/loading';
import Button from '~/components/button';
import Title from '~/components/typography/title';
import Space from '~/components/space';
// Utils
import { CapitalizeString, DecodeURI } from '~/utils/Transformations';
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function SearchDrawerComponent({
  selectedPerson,
  setSelectedPerson,
  setPeopleList,
  secondStepParameters,
  CloseSearchDrawer,
}) {
  const { isMobile } = useContext(ResponsiveContext);
  const { institutionId, organizationUnitId } = useParams();
  const [selectId, setSelectId] = useState();
  const [loadingContent, setLoadingContent] = useState(true);

  useEffect(() => {
    if (!selectId) {
      return;
    }
    if (loadingContent) {
      setLoadingContent(false);
    }
  }, [loadingContent, selectId]);

  useEffect(() => {
    if (selectedPerson) {
      if (selectedPerson.id !== selectId) {
        if (!selectId) {
          return setSelectId(selectedPerson.id);
        }
        setLoadingContent(true);
      } else {
        setSelectId(selectedPerson.id);
      }
    }
  }, [selectedPerson, selectId]);

  function NewSearch() {
    setPeopleList();
    setSelectedPerson();
  }

  async function AddUser(person) {
    const params = {
      operation: ['SIGAM', 'Processes', 'Interested', 'Add'],
      urlParams: {
        institutionId: institutionId,
        organizationUnitId: organizationUnitId,
        processId: secondStepParameters.process.id,
        personId: person.id,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      message.success('Interessado adicionado com sucesso!');
      CloseSearchDrawer();
      secondStepParameters.GetPeopleAgain();
      NewSearch();
    } else {
      message.error('Alguma coisa aconteceu...');
    }
  }

  function ChecksIfItBelongs() {
    if (secondStepParameters.people.length > 0) {
      for (let i = 0; i < secondStepParameters.people.length; i++) {
        if (secondStepParameters.people[i].id === selectedPerson.id) {
          return (
            <Button icon={<UserAddOutlined />} disabled>
              Usuário já é um interessado
            </Button>
          );
        }
      }
    }
    return (
      <Button
        onClick={() => AddUser(selectedPerson)}
        icon={<UserAddOutlined />}
        type="primary"
      >
        Adicionar interessado
      </Button>
    );
  }

  if (loadingContent) {
    return <Loading />;
  }

  return (
    <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '20px',
        }}
      >
        <div style={{ marginBottom: 10 }}>
          <Space
            direction={isMobile ? 'vertical' : 'horizontal'}
            align={isMobile ? 'center' : 'flex-start'}
          >
            <Button onClick={() => NewSearch()}>Fazer outra busca</Button>
            <Button onClick={() => setSelectedPerson()}>
              Selecionar outra pessoa
            </Button>
          </Space>
        </div>
        <Avatar src={DecodeURI(selectedPerson.avatar)} size={120} />
        <Title level={4}>
          {CapitalizeString(selectedPerson.social_name || selectedPerson.name)}
        </Title>
        <div style={{ marginTop: 10 }}>{ChecksIfItBelongs()}</div>
      </div>
    </Animate>
  );
}
