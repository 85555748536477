import React, { useContext } from 'react';
// Libraries
import { List, message } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons';
import { BsPen } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import Animate, { FadeIn } from 'animate-css-styled-components';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Avatar from '~/components/avatar';
import Button from '~/components/button';
import Text from '~/components/typography/text';
// Utils
import { CapitalizeString, DecodeURI } from '~/utils/Transformations';
import { cpfMask, cnpjMask } from '~/utils/Masks';
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function PeopleList({
  people,
  process,
  GetPeopleAgain,
  accessLevel,
}) {
  const { theme } = useContext(ThemeContext);
  const { institutionId, organizationUnitId } = useParams();

  async function RemoveUser(item) {
    const params = {
      operation: ['SIGAM', 'Processes', 'Signatories', 'Delete'],
      urlParams: {
        institutionId,
        organizationUnitId,
        processId: process.id,
        personId: item.id || item.person_id,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      message.success('Assinante removido com sucesso!');
      GetPeopleAgain();
    } else {
      message.error('Alguma coisa aconteceu...');
    }
  }

  function Feedback({ color, text }) {
    return (
      <Text
        style={{
          marginLeft: 10,
          color,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <BsPen style={{ color }} />
        {text}
      </Text>
    );
  }

  function renderButton(item) {
    /*
    if (process.total_tramitations > 0) {
      return <></>;
    }
    */
    if (accessLevel) {
      if (accessLevel.is_in_charge) {
        return [
          <Button
            onClick={() => RemoveUser(item)}
            icon={<UserDeleteOutlined />}
            danger
          >
            Remover assinante
          </Button>,
        ];
      }
    }
    return <></>;
  }

  return (
    <div style={{ marginTop: 10 }}>
      <List
        itemLayout="horizontal"
        dataSource={people}
        locale={{ emptyText: 'Nenhum usuário encontrado' }}
        renderItem={(item) => (
          <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
            <List.Item actions={renderButton(item)}>
              <List.Item.Meta
                avatar={
                  <Avatar size={45} src={DecodeURI(item.person_avatar)} />
                }
                title={
                  <div style={{ display: 'flex' }}>
                    {CapitalizeString(
                      item.person_social_name || item.person_name
                    )}
                    {item.signature_id ? (
                      <Feedback color={theme.color.success} text="Assinado" />
                    ) : (
                      <Feedback color={theme.color.danger} text="Pendente" />
                    )}
                  </div>
                }
                description={
                  item.person_cpf_number
                    ? cpfMask(item.person_cpf_number)
                    : cnpjMask(item.person_cnpj_number)
                }
              />
            </List.Item>
          </Animate>
        )}
      />
    </div>
  );
}
