import SigamAPI from '~/utils/SigamAPI';

export default async function getTotal({ institutionId }) {
  const response = await SigamAPI({
    url: `/SIGAM/${institutionId}/dashboard/views/totals`,
    method: 'POST',
  });

  return response;
}
