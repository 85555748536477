import React, { useContext } from 'react';
// Libraries
import Animate, { FadeIn } from 'animate-css-styled-components';
import { UserAddOutlined } from '@ant-design/icons';
import { message } from 'antd';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { SIGAMContext } from '~/contexts/SIGAMContext';
// Components
import Avatar from '~/components/avatar';
import Button from '~/components/button';
import Title from '~/components/typography/title';
import Space from '~/components/space';
// Utils
import { CapitalizeString, DecodeURI } from '~/utils/Transformations';
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function SearchDrawerComponent({ selectedPerson, setSelectedPerson, setPeopleList, secondStepParameters }) {
    const { isMobile } = useContext(ResponsiveContext);
    const { activeInstitution } = useContext(SIGAMContext);

    async function AddUser(person) {
        const params = {
          operation: ['SIGAM', 'OrganizationalUnits', 'Users', 'Add'],
          urlParams: {
            institutionId: activeInstitution.id,
            organizationUnitId: secondStepParameters.record.id,
          },
          data: {
            role: 'Programador',
            person: person.id,
          },
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
          //Apagar do result
          //Acrescentar
          message.success('Usuário adicionado na unidade com sucesso');
          secondStepParameters.CloseDrawerFather();
          secondStepParameters.setUpdatePending(true);
        } else {
          //message.error('Problema com a rota da API');
          message.error('Alguma coisa aconteceu...')
        }
      }

    function ChecksIfItBelongs() {
        if (secondStepParameters.users.length > 0) {
            for (let i = 0; i < secondStepParameters.users.length; i++) {
                if (secondStepParameters.users[i].person_id === selectedPerson.id) {
                    return (
                        <Button icon={<UserAddOutlined />} disabled>
                            Usuário já pertece à unidade
                        </Button>
                    );
                }
            }
        }
        return (
            <Button onClick={() => AddUser(selectedPerson)} icon={<UserAddOutlined />}>
                Adicionar usuário da unidade
            </Button>
        );
    }

    function NewSearch() {
        setPeopleList();
        setSelectedPerson();
    }

    return (
        <Animate
            Animation={[FadeIn]}
            duration={['0.5s']}
            delay={['0.2s']}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: '20px'
                }}
            >
                <div style={{ marginBottom: 10 }}>
                    <Space direction={isMobile ? 'vertical' : 'horizontal'} align={isMobile ? 'center' : 'flex-start'}>
                        <Button onClick={() => NewSearch()}>Fazer outra busca</Button>
                        <Button onClick={() => setSelectedPerson()}>Selecionar outra pessoa</Button>
                    </Space>
                </div>
                <Avatar src={DecodeURI(selectedPerson.avatar)} size={120} />
                <Title level={4}>{CapitalizeString(selectedPerson.social_name || selectedPerson.name)}</Title>
                {ChecksIfItBelongs()}
            </div>
        </Animate>
    )
}