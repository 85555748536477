import React, { useEffect, useState } from 'react';
// Libraries
import { SearchOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { Result } from 'antd';
// Own Components
import PeopleList from './components/PeopleList';
// Components
import Title from '~/components/typography/title';
import Button from '~/components/button';
import Loading from '~/components/loading';
import SearchDrawer from '~/pages/SIGAM/components/SearchDrawer';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
// Pages
import SearchDrawerComponent from './components/SearchDrawerComponent';

export default function Interested({ process, propertys }) {
  const { institutionId, organizationUnitId } = useParams();
  const [people, setPeople] = useState([]);
  const [loadingContent, setLoadingContent] = useState(true);
  const [accessLevel, setAccessLevel] = useState();

  const processInformation = process || propertys.process[0];

  async function GetPeopleAgain() {
    if (propertys && propertys.GetDataAgain) {
      propertys.GetDataAgain();
    }
    const params = {
      operation: ['SIGAM', 'Processes', 'Interested', 'Get'],
      urlParams: {
        institutionId,
        organizationUnitId,
        processId: processInformation.id,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      setPeople(response.body.interested.elements);
    }
  }

  useEffect(() => {
    async function GetPeople() {
      const params = {
        operation: ['SIGAM', 'Processes', 'Interested', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
          processId: processInformation.id,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setPeople(response.body.interested.elements);
      }
      setLoadingContent(false);
    }
    async function verifyPermission() {
      const params = {
        operation: ['SIGAM', 'Processes', 'HavePermission'],
        urlParams: {
          institutionId,
          organizationUnitId,
          processId: processInformation.id,
        },
      };
      const response = await SocialMeAPI(params);
      setAccessLevel(response.body.access_level);
      GetPeople();
    }
    verifyPermission();
  }, [institutionId, organizationUnitId, processInformation]);

  /*
  function renderDrawer() {
    //if (processInformation.total_tramitations > 0) return <></>;
    if (accessLevel) {
      if (accessLevel.is_in_charge) {
        return (
          <SearchDrawer
            Trigger={<Button icon={<SearchOutlined />}>Buscar Usuário</Button>}
            title="Novo Interessado"
            SecondStepComponent={SearchDrawerComponent}
            secondStepParameters={{
              process: processInformation,
              people,
              GetPeopleAgain,
              //searchOnSocialMe: true,
              searchInSigam: true,
            }}
          />
        );
      }
    }
  }
  */

  if (processInformation?.status === 'INACTIVE') {
    return (
      <Result
        status="error"
        title="Você não pode atualizar um processo arquivado"
      />
    );
  }

  if (accessLevel?.is_in_charge === false) {
    return (
      <Result
        status="error"
        title="Você não tem permissão para alterar o processo"
      />
    );
  }

  return (
    <div>
      {/* 
      {accessLevel && accessLevel.is_in_charge === false && (
        <Alert
          style={{ marginBottom: '10px' }}
          message="Você não tem permissão para alterar o processo"
          type="warning"
          showIcon
        />
      )}
    
      {accessLevel &&
        accessLevel.is_in_charge &&
        processInformation.total_tramitations > 0 && (
          <Alert
            style={{ marginBottom: '10px' }}
            message="Você não pode alterar um processo que já foi tramitado"
            type="warning"
            showIcon
          />
        )}
        */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Title level={5}>Interessados</Title>
        <SearchDrawer
          Trigger={<Button icon={<SearchOutlined />}>Buscar Usuário</Button>}
          title="Novo Interessado"
          SecondStepComponent={SearchDrawerComponent}
          secondStepParameters={{
            process: processInformation,
            people,
            GetPeopleAgain,
            //searchOnSocialMe: true,
            searchInSigam: true,
          }}
        />
      </div>
      {loadingContent ? (
        <Loading />
      ) : (
        <PeopleList
          accessLevel={accessLevel}
          people={people}
          process={processInformation}
          GetPeopleAgain={GetPeopleAgain}
        />
      )}
    </div>
  );
}
