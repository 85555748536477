import React, { useState, useEffect } from 'react';
// Libraries
import { useParams } from 'react-router-dom';
import { LinkOutlined } from '@ant-design/icons';
// Components
import Loading from '~/components/loading';
import Select from '~/components/select';
import Button from '~/components/button';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { message } from 'antd';

export default function AddDocument({ process, attachments, GetAttachmentsAgain }) {
    const { institutionId, organizationUnitId } = useParams();
    const [documents, setDocuments] = useState();
    const [selectValue, setSelectValue] = useState();
    const [loadingContent, setLoadingContent] = useState(true);

    async function onSelect() {
        let name;
        for (let i = 0; i < documents.length; i++) {
            if(documents[i].value === selectValue){
                name = documents[i].label
            }
        }
        const params = {
            operation: ['SIGAM', 'Processes', 'Attachments', 'Add'],
            urlParams: {
                institutionId: institutionId,
                organizationUnitId: organizationUnitId,
                processId: process.id,
                documentId: selectValue
            },
            data: {
                name: name
            }
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            message.success('Documento anexado com sucesso!');
            setSelectValue();
            return GetAttachmentsAgain();
        }
        message.error('Alguma coisa aconteceu...');
    }

    useEffect(() => {
        function Verify(id){
            for (let i = 0; i < attachments.length; i++) {
                if(attachments[i].id === id){
                    return false;
                }
            }
            return true;
        }
        async function GetDocuments() {
            const params = {
                operation: ['SIGAM', 'Documents', 'Get'],
                urlParams: {
                    institutionId: institutionId,
                    organizationUnitId: organizationUnitId,
                },
                data: {
                    filter: {
                        status: 'ACTIVE'
                    },
                    pagination: {
                        sort: {
                            by: 'name',
                            order: 'ASC',
                        },
                    },
                },
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                let array = response.body.documents.elements;
                let newArray = [];
                for (let i = 0; i < array.length; i++) {
                    const verify = await Verify(array[i].id);
                    if(verify){
                        let newItem = {}
                        newItem.label = array[i].name;
                        newItem.value = array[i].id;
                        newArray.push(newItem)
                    }
                }
                setDocuments(newArray);
            }
            setLoadingContent(false);
        }
        if(attachments){
            GetDocuments();
        }
    }, [institutionId, organizationUnitId, attachments])

    if (loadingContent) {
        return <Loading height="0px" withoutText />
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Select
                options={documents}
                noDefaultValue
                style={{ width: '300px', marginRight: 10 }}
                value={selectValue}
                onChange={(value) => setSelectValue(value)}
            />
            <Button icon={<LinkOutlined />} type="dashed" onClick={() => onSelect()}>Anexar</Button>
        </div>
    )
}