import API from '~/environments/APIs';

export default {
  GetQRCode: {
    baseURL: API.socialMe,
    url: '/qr-code/partner',
    method: 'post',
    timeout: '50000',
  },
  GetMoves: {
    baseURL: API.socialMe,
    url: '/dw/account/partner/balance/detailed',
    method: 'post',
    timeout: '50000',
  },
  Activity: {
    List: {
      baseURL: API.socialMe,
      url: '/activity/activities',
      method: 'post',
      timeout: '50000',
    },
    Add: {
      baseURL: API.socialMe,
      url: '/activity/activity',
      method: 'post',
      timeout: '50000',
    },
    Publish: {
      baseURL: API.socialMe,
      url: '/activity/activity/activityId',
      method: 'post',
      timeout: '50000',
    },
    Show: {
      baseURL: API.socialMe,
      url: '/activity/activity/activityId',
      method: 'get',
      timeout: '50000',
    },
    Edit: {
      baseURL: API.socialMe,
      url: '/activity/activity/activityId',
      method: 'put',
      timeout: '50000',
    },
    Delete: {
      baseURL: API.socialMe,
      url: '/activity/activity/activityId',
      method: 'delete',
      timeout: '50000',
    },
    CustomFields: {
      Add: {
        baseURL: API.socialMe,
        url: '/activity/activity/activityId/custom-question',
        method: 'post',
        timeout: '50000',
      },
      Get: {
        baseURL: API.socialMe,
        url: '/activity/activityId/activity-frequency',
        method: 'get',
        timeout: '50000',
      },
      Edit: {
        baseURL: API.socialMe,
        url: '/activity/activity/activityId/custom-question/customQuestionId',
        method: 'put',
        timeout: '50000',
      },
      Delete: {
        baseURL: API.socialMe,
        url: '/activity/activity/activityId/custom-question/customQuestionId',
        method: 'delete',
        timeout: '50000',
      }
    },
    Frequencies: {
      List: {
        baseURL: API.socialMe,
        url: '/activity/activity-frequencies',
        method: 'post',
        timeout: '50000',
      },
      Add: {
        baseURL: API.socialMe,
        url: '/activity/activityId/activity-frequency',
        method: 'post',
        timeout: '50000',
      },
    },
    Location: {
      List: {
        baseURL: API.socialMe,
        url: '/location/places/activity/activityId',
        method: 'post',
        timeout: '50000',
      },
      Add: {
        baseURL: API.socialMe,
        url: '/location/place/placeId/activity/activityId',
        method: 'post',
        timeout: '50000',
      },
      Delete: {
        baseURL: API.socialMe,
        url: '/location/place/placeId/activity/activityId',
        method: 'delete',
        timeout: '50000',
      },
      CreateQRCode: {
        baseURL: API.socialMe,
        url: '/qr-code/activity-place',
        method: 'post',
        timeout: '50000',
      },
    },
  },
  Product: {
    Get: {
      baseURL: API.socialMe,
      url: '/prod-serv/ps-offerings',
      method: 'post',
      timeout: '50000',
    },
    Search: {
      baseURL: API.socialMe,
      url: '/prod-serv/ps-models',
      method: 'post',
      timeout: '50000',
    },
    Add: {
      baseURL: API.socialMe,
      url: '/prod-serv/ps-offering',
      method: 'post',
      timeout: '50000',
    },
    Show: {
      baseURL: API.socialMe,
      url: '/prod-serv/ps-offering/offeringId',
      method: 'get',
      timeout: '50000',
    },
    Edit: {
      baseURL: API.socialMe,
      url: '/prod-serv/ps-offering/offeringId',
      method: 'put',
      timeout: '50000',
    },
  }
};
