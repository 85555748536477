import React, { useContext} from 'react';
// Libraries
import { Divider, Row, Col } from 'antd';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import { LanguageContext } from '~/contexts/LanguageContext';

export default function BasicInformation() {
  const { translate } = useContext(LanguageContext);
  return (
    <div>
      <Divider style={{ margin: '0px 0px 10px' }}>Senha</Divider>
      <Row>
          <Col span={24}>
            <FormItem
              style={{ margin: '0px 0px 10px 0px' }}
              name="auth_password"
              label="Nova senha"
              item={
                <Input
                  type="password"
                />
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem
              style={{ margin: '0px 0px 10px 0px' }}
              name="confirm"
              label="Confirme a nova senha"
              rules={[
                {
                  required: true,
                  message: translate.fields.required.passwordConfirm.text,
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('auth_password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      translate.fields.invalid.passwordConfirm.text
                    );
                  },
                }),
              ]}
              item={
                <Input
                  type="password"
                />
              }
            />
          </Col>
        </Row>
    </div>
  );
}
