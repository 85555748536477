import React, { useContext } from 'react';
// Libraries
import { Popconfirm, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { SIGAMContext } from '~/contexts/SIGAMContext';
// Components
import Button from '~/components/button';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function DeleteButton({ record, setUpdatePending, GetDataAgain }) {
  const { activeInstitution } = useContext(SIGAMContext);
  const { theme } = useContext(ThemeContext);

  async function DeleteDocumentTemplate() {
    const params = {
      operation: ['SIGAM', 'DocumentTemplates', 'Delete'],
      urlParams: {
        institutionId: activeInstitution.id,
        modelId: record.id,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      message.success('Modelo de Documento deletado com sucesso!');
      return GetDataAgain();
    }
    message.error('Alguma coisa aconteceu...');
  }

  return (
    <div>
      <Popconfirm
        title="Você tem certeza que deseja deletar esse modelo de documento?"
        onConfirm={DeleteDocumentTemplate}
        okText="Sim, tenho certeza"
        cancelText="Não"
      >
        <div>
          <Button
            icon={<DeleteOutlined style={{ color: theme.color.danger }} />}
          />
        </div>
      </Popconfirm>
    </div>
  );
}
