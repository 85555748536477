import React, { useState } from 'react';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { useArchiveFilesPageManager } from '../../../../../../contexts/ArchiveFilesPageManager/Provider';
import UpdateFileForm from './components/Form';

export default function UpdateFileButton({ file }) {
  const [loadingFile, setLoadingFile] = useState(false);
  const [completeFile, setCompleteFile] = useState();
  const [open, setOpen] = useState(false);

  const { ShowFile } = useArchiveFilesPageManager();

  function setMetatagsOnFile(currentFile) {
    let metas = [];

    for (const key in currentFile) {
      const isMetadata = key.includes('meta');
      if (isMetadata) metas.push(currentFile[key]);
    }

    currentFile.metas = metas;

    return currentFile;
  }

  const LoadFileToUpdate = async () => {
    setLoadingFile(true);

    const response = await ShowFile(file.id);

    if (response.success) {
      const completeFile = setMetatagsOnFile(response.body.archive_file);

      setCompleteFile(completeFile);
      setOpen(true);
    }

    setLoadingFile(false);
  };

  return (
    <>
      <Button
        onClick={LoadFileToUpdate}
        loading={loadingFile}
        icon={<EditOutlined />}
      >
        Editar
      </Button>

      {completeFile && (
        <UpdateFileForm file={completeFile} open={open} setOpen={setOpen} />
      )}
    </>
  );
}
