// Libraries
import React, { useState } from 'react';
import { Form, Input, Button, Typography } from 'antd';

import { useMessagerManager } from '../../../../contexts/MessagerManager/Provider';
import { useTheme } from '~/contexts/ThemeContext';
import CreateChat from '~/services/calls/messager/chat/group/create';
import DropdownIcon from '../../components/DropdownIcon';
import VIEWS from '../../../../constants/views';

import * as S from './style';
import { useAuth } from '~/contexts/AuthContext';

const { Text } = Typography;

export default function AddChat() {
  const { user } = useAuth();
  const { theme } = useTheme();

  const { setView, setChats, institutionId, setSelectedChat } =
    useMessagerManager();

  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({});

  async function onFinish() {
    setLoading(true);

    const group = { ...formValues, participants: [user.id] };

    const response = await CreateChat({ group, institutionId });

    if (response.success) {
      const newChat = response.body.chat_group;
      setChats((currentChats) => {
        return [...currentChats, newChat];
      });

      setSelectedChat(newChat);
      setView(VIEWS.LIST_CONTACTS_TO_GROUP);
    }

    setLoading(false);
  }

  const onChangeInput = (event) => {
    const { name, value } = event.target;

    setFormValues({ ...formValues, [name]: value });
  };

  const formItemStyle = {
    marginBottom: 12,
  };

  const textStyle = {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: theme.color.white,
  };

  return (
    <S.Container>
      <S.Header style={{ backgroundColor: theme.color.primary }}>
        <Text style={textStyle}>Criando grupo de conversa</Text>

        <DropdownIcon />
      </S.Header>

      <Form style={{ padding: '16px' }} layout="vertical" autoComplete="off">
        <Form.Item
          style={formItemStyle}
          label="Nome do grupo"
          rules={[{ required: true, message: 'Coloque o nome do grupo' }]}
        >
          <Input
            size="default"
            name="name"
            value={formValues.value}
            onChange={onChangeInput}
          />
        </Form.Item>

        <Form.Item style={{ marginTop: 12 }}>
          <Button
            loading={loading}
            onClick={onFinish}
            size="default"
            type="primary"
          >
            Registrar
          </Button>
        </Form.Item>
      </Form>
    </S.Container>
  );
}
