import React from 'react';
// Libraries
import { Divider, Row, Col } from 'antd';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';

export default function BasicInformation() {
  return (
    <div>
      <Divider style={{ margin: '0px 0px 10px' }}>Informações Básicas</Divider>
      <Row>
        <Col span={24}>
          <FormItem
            label="Nome da Instituição"
            name={['name']}
            rules={[
              {
                required: true,
                message: 'Um nome é obrigatório',
              },
            ]}
            item={<Input />}
          />
        </Col>
      </Row>
    </div>
  );
}
