import React, { useState } from 'react';
import { Button, Input } from 'antd';

import CreateMessage from '~/services/calls/messager/messages/create';
import { useMessagerManager } from '../../../../contexts/MessagerManager/Provider';

import * as S from './style';

export default function MessagerContainerFooter() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const { institutionId, selectedChat, setMessages } = useMessagerManager();

  async function onSendMessage() {
    setLoading(true);

    const chatId = selectedChat.id;

    const response = await CreateMessage({ message, chatId, institutionId });

    if (response.success) {
      setMessages((currentMessages) => {
        return [...currentMessages, response.body.chat_group_message];
      });
    }

    setLoading(false);

    setMessage('');
  }

  return (
    <S.Footer>
      <Input
        style={{ marginRight: 10 }}
        value={message}
        onChange={(event) => setMessage(event.target.value)}
        placeholder="Digite uma mensagem"
      />

      <Button
        loading={loading}
        size="default"
        onClick={() => onSendMessage(message)}
        type="primary"
      >
        Enviar mensagem
      </Button>
    </S.Footer>
  );
}
