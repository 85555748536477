export default {
  BasicInformation: {
    text: 'Informaciones básicas',
  },
  Documents: {
    text: 'Documentos',
  },
  Contacts: {
    text: 'Contactos',
  },
  Adresses: {
    text: 'Direcciones',
  },
  Timeline: {
    text: 'Linea del tiempo',
  },
  Relationships: {
    text: 'Relaciones',
  },
  Family: {
    text: 'Familia',
  },
  WeaknessesAndDeficiencies: {
    text: 'Debilidades y deficiencias',
  },
  WorkAndRemuneration: {
    text: 'Trabajo y remuneración',
  },
  Bindings: {
    text: 'Fijaciones',
  },
  StreetSituation: {
    text: 'Situación callejera',
  },
};
