import axios from 'axios';
import Cookie from '~/utils/Cookie';
import API from '~/environments/APIs';
import System from '~/environments/System';

const api = axios.create({
  baseURL: API.socialMe,
  timeout: 50000,
});

async function formatServerErrorResponse(error) {
  const defaultErrorMessage = 'Houve um erro no servidor';

  const handleAxiosErrors = (error) => {
    const possibleErros = {
      404: 'Rota não encontrada',
    };

    return {
      header: {
        title: possibleErros[error?.response?.status] || defaultErrorMessage,
        success: false,
      },
    };
  };

  const handleNetworkError = (error) => {
    let errorMessage = defaultErrorMessage;

    if (error.message.includes('timeout')) {
      errorMessage = 'Tempo de resposta so servidor expirou';
    }

    return {
      header: {
        title: errorMessage,
        success: false,
      },
    };
  };

  if (error?.response?.data) return error.response.data;

  if (error.name === 'AxiosError') return handleAxiosErrors(error);

  if (error?.message) return handleNetworkError(error);
}

async function getDefaultAPIHeader() {
  const UserTokenCookie = await Cookie.Get(System.cookie.name.user);
  const BrowserTokenCookie = await Cookie.Get(System.cookie.name.browser);

  const headers = {
    brwsrtkn: BrowserTokenCookie || undefined,
    usrtkn: UserTokenCookie || undefined,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':
      'Origin, X-Requested-With, Content-Type, Accept',
  };

  return headers;
}

export default async function adminAPI(axiosConfig) {
  const headers = await getDefaultAPIHeader();

  return api({ headers, ...axiosConfig })
    .then((response) => {
      if (response && response.data) response.data.success = true;

      return response.data;
    })
    .catch((error) => {
      return formatServerErrorResponse(error);
    });
}
