import React, { useEffect, useState } from 'react';
// Libraries
import {
  RiErrorWarningLine,
  RiShieldKeyholeLine,
  RiBallPenFill,
} from 'react-icons/ri';
// Components
import Loading from '~/components/loading';
import { TagsCell } from '~/components/table/cells';

export default function Tags({ item }) {
  const [loadingContent, setLoadingContent] = useState(true);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    async function Prepare() {
      const array = [];
      if (item.priority > 0) {
        const newItem = {};
        newItem.text = 'Prioritário';
        newItem.icon = <RiErrorWarningLine style={{ marginRight: 5 }} />;
        newItem.color = 'red';
        array.push(newItem);
      }
      if (item.privacy === 'RESTRICTED') {
        const newItem = {};
        newItem.text = 'Restrito';
        newItem.icon = <RiShieldKeyholeLine style={{ marginRight: 5 }} />;
        newItem.color = 'orange';
        array.push(newItem);
      }
      if (item.privacy === 'CONFIDENTIAL') {
        const newItem = {};
        newItem.text = 'Sigiloso';
        newItem.icon = <RiShieldKeyholeLine style={{ marginRight: 5 }} />;
        newItem.color = 'gold';
        array.push(newItem);
      }
      if (item.signatory_count > 0) {
        const newItem = {};
        newItem.text = `${item.signatory_count} ${
          Number(item.signatory_count) === 1 ? 'assinante' : 'assinantes'
        }`;
        newItem.icon = <RiBallPenFill style={{ marginRight: 5 }} />;
        array.push(newItem);
      }
      if (item.signature_count < item.signatory_count) {
        const newItem = {};
        newItem.text = `${item.signatory_count - item.signature_count} ${
          Number(item.signatory_count) - Number(item.signature_count) === 1
            ? 'assinatura pendente'
            : 'assinaturas pendentes'
        }`;
        newItem.icon = <RiBallPenFill style={{ marginRight: 5 }} />;
        newItem.color = 'volcano';
        array.push(newItem);
      }
      if (item.total_tramitations > 0) {
        const newItem = {};
        newItem.text = `Processo tramitado ${item.total_tramitations} ${item.total_tramitations === 1 ? 'vez' : 'vezes'}`;
        newItem.color = 'green';
        array.push(newItem);
      }
      if (item.status === 'INACTIVE') {
        const newItem = {};
        newItem.text = `Processo arquivado`;
        newItem.color = 'geekblue';
        array.push(newItem);
      }
      // console.log(array);
      setTags(array);
      setLoadingContent(false);
    }
    Prepare();
  }, [item]);

  if (loadingContent) {
    return <Loading withoutText height="0px" />;
  }

  return (
    <TagsCell
      id={`documentsCellPublicAgent-${item.id}`}
      tags={tags}
      maxWidth="250px"
    />
  );
}
