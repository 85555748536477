import React from 'react';
import { message } from 'antd'
import Button from '~/components/button';
import Api from '~/utils/SocialMeAPI';

export default function StatusButton({ institutionId, status, getDataAgain }) {

  async function changeStatus() {
    const params = {
      operation: ["SIGAM", "Institution", "Update"],
      urlParams: {
        institutionId
      },
      data: {
        status: status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
      }
    }
    const response = await Api(params);
    if (response.success) {
      message.success(status === 'ACTIVE' ? 'Instituição desvinculada com sucesso' : 'Instituição vinculada com sucesso')
      getDataAgain()
    }
  }

  return status === 'ACTIVE' ? (
    <Button type="danger" onClick={() => changeStatus()}>Inativar Instituição</Button>
  ) : (
    <Button type="dashed" onClick={() => changeStatus()}>Ativar Instituição</Button>
  );
}
