import React, { useContext } from 'react';
// Libraries
import { Row, Col } from 'antd';
import { FaRegEdit, FaRegEye, FaSignature, FaStamp } from 'react-icons/fa';
import {
  RiFileAddLine,
  RiUserStarLine,
  RiUserSharedLine,
  RiMailSendLine,
} from 'react-icons/ri';
import { FiUsers } from 'react-icons/fi';
import { BiLockAlt } from 'react-icons/bi';
import { TiCalendarOutline } from 'react-icons/ti';
import { PrinterOutlined, DeleteFilled } from '@ant-design/icons';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { PermissionContext } from '~/contexts/PermissionContext';
// Components
import Drawer from '~/components/drawer';
import Button from '~/components/button';
// Actions
import Actions from '../Actions';

export default function Options({ selectedRow, GetDataAgain, mobileColumn }) {
  const { isMobile } = useContext(ResponsiveContext);
  const { CheckPermission } = useContext(PermissionContext);

  if (mobileColumn) {
    return (
      <div>
        <Row>
          <Col span={12}>
            <Drawer
              Trigger={
                <div>
                  <Button
                    icon={<RiMailSendLine />}
                    type={selectedRow.length > 0 ? 'default' : 'dashed'}
                    style={{ margin: '0px 5px 10px 0px' }}
                    anotherLibraryIcon
                  >
                    {Actions.Proceed.text}
                  </Button>
                </div>
              }
              title="Tramitar"
              width="50%"
              DrawerContentComponent={Actions.Proceed.content}
              DrawerContentComponentParams={{
                document: selectedRow,
                GetDataAgain,
              }}
            />
          </Col>
          <Col span={12}>
            <Drawer
              Trigger={
                <div>
                  <Button
                    icon={<RiUserStarLine />}
                    type={selectedRow.length > 0 ? 'default' : 'dashed'}
                    style={{ margin: '0px 5px 10px 0px' }}
                    anotherLibraryIcon
                  >
                    {Actions.Interested.text}
                  </Button>
                </div>
              }
              title="Gerenciar Interessados"
              width="50%"
              DrawerContentComponent={Actions.Interested.content}
              DrawerContentComponentParams={{
                document: selectedRow,
                GetDataAgain,
              }}
            />
          </Col>
          <Col span={12}>
            <Drawer
              Trigger={
                <div>
                  <Button
                    icon={<RiUserSharedLine />}
                    type={selectedRow.length > 0 ? 'default' : 'dashed'}
                    style={{ margin: '0px 5px 10px 0px' }}
                    anotherLibraryIcon
                  >
                    {Actions.Signatories.text}
                  </Button>
                </div>
              }
              title="Gerenciar Assinantes"
              width="50%"
              DrawerContentComponent={Actions.Signatories.content}
              DrawerContentComponentParams={{
                document: selectedRow,
                GetDataAgain,
              }}
            />
          </Col>
          {CheckPermission('UPDATE-DOCUMENT') && (
            <Col span={12}>
              <Drawer
                Trigger={
                  <div>
                    <Button
                      icon={<FaRegEdit />}
                      type={selectedRow.length > 0 ? 'default' : 'dashed'}
                      style={{ margin: '0px 5px 10px 0px' }}
                      anotherLibraryIcon
                    >
                      {Actions.Edit.text}
                    </Button>
                  </div>
                }
                title="Editar"
                width="50%"
                DrawerContentComponent={Actions.Edit.content}
                DrawerContentComponentParams={{
                  document: selectedRow,
                  GetDataAgain,
                }}
              />
            </Col>
          )}
          <Col span={12}>
            <Drawer
              Trigger={
                <div>
                  <Button
                    icon={<RiFileAddLine />}
                    type={selectedRow.length > 0 ? 'default' : 'dashed'}
                    style={{ margin: '0px 5px 10px 0px' }}
                    anotherLibraryIcon
                  >
                    {Actions.Attachments.text}
                  </Button>
                </div>
              }
              title="Anexos"
              width="50%"
              DrawerContentComponent={Actions.Attachments.content}
              DrawerContentComponentParams={{
                document: selectedRow,
                GetDataAgain,
              }}
            />
          </Col>
          {CheckPermission('READ-DOCUMENT') && (
            <Col span={12}>
              <Drawer
                Trigger={
                  <div>
                    <Button
                      icon={<FaRegEye />}
                      type={selectedRow.length > 0 ? 'default' : 'dashed'}
                      style={{ margin: '0px 5px 10px 0px' }}
                      anotherLibraryIcon
                    >
                      {Actions.Visualize.text}
                    </Button>
                  </div>
                }
                title="Visualizar"
                width="100%"
                DrawerContentComponent={Actions.Visualize.content}
                DrawerContentComponentParams={{
                  document: selectedRow,
                  GetDataAgain,
                }}
              />
            </Col>
          )}
          {CheckPermission('SIGN-DOCUMENT-LOGIN-PASSWORD') && (
            <Col span={12}>
              <Drawer
                Trigger={
                  <div>
                    <Button
                      icon={<FaSignature />}
                      type={selectedRow.length > 0 ? 'default' : 'dashed'}
                      style={{ margin: '0px 5px 10px 0px' }}
                      anotherLibraryIcon
                    >
                      {Actions.Subscribe.text}
                    </Button>
                  </div>
                }
                title="Assinar"
                width="50%"
                DrawerContentComponent={Actions.Subscribe.content}
                DrawerContentComponentParams={{
                  document: selectedRow,
                  GetDataAgain,
                }}
              />
            </Col>
          )}
          {CheckPermission('UPDATE-DOCUMENT') && (
            <Col span={12}>
              <Drawer
                Trigger={
                  <div>
                    <Button
                      icon={<BiLockAlt />}
                      type={selectedRow.length > 0 ? 'default' : 'dashed'}
                      style={{ margin: '0px 5px 10px 0px' }}
                      anotherLibraryIcon
                    >
                      Acesso
                    </Button>
                  </div>
                }
                title="Nível de acesso"
                width="50%"
                DrawerContentComponent={Actions.AccessLevel.content}
                DrawerContentComponentParams={{
                  document: selectedRow,
                  GetDataAgain,
                }}
              />
            </Col>
          )}
          <Col span={12}>
            <Drawer
              Trigger={
                <div>
                  <Button
                    icon={<FiUsers />}
                    type={selectedRow.length > 0 ? 'default' : 'dashed'}
                    style={{ margin: '0px 5px 10px 0px' }}
                    anotherLibraryIcon
                  >
                    {Actions.Contributors.text}
                  </Button>
                </div>
              }
              title="Colaboradores"
              width="50%"
              DrawerContentComponent={Actions.Contributors.content}
              DrawerContentComponentParams={{
                document: selectedRow,
                GetDataAgain,
              }}
            />
          </Col>
          <Col span={12}>
            <Drawer
              Trigger={
                <div>
                  <Button
                    icon={<TiCalendarOutline />}
                    type={selectedRow.length > 0 ? 'default' : 'dashed'}
                    style={{ margin: '0px 5px 10px 0px' }}
                    anotherLibraryIcon
                  >
                    {Actions.Historic.text}
                  </Button>
                </div>
              }
              title="Histórico"
              width="50%"
              DrawerContentComponent={Actions.Historic.content}
              DrawerContentComponentParams={{
                document: selectedRow,
                GetDataAgain,
              }}
            />
          </Col>
          <Col span={12}>
            <Drawer
              Trigger={
                <div>
                  <Button
                    icon={<DeleteFilled />}
                    type={selectedRow.length > 0 ? 'default' : 'dashed'}
                    style={{ margin: '0px 5px 10px 0px' }}
                    anotherLibraryIcon
                  >
                    {Actions.Delete.text}
                  </Button>
                </div>
              }
              title="Deletar documento"
              width="50%"
              DrawerContentComponent={Actions.Delete.content}
              DrawerContentComponentParams={{
                document: selectedRow,
                GetDataAgain,
              }}
            />
          </Col>
          {/* 
          <Col span={12}>
            <Drawer
              Trigger={
                <div>
                  <Button
                    icon={<FaStamp />}
                    type={selectedRow.length > 0 ? 'default' : 'dashed'}
                    style={{ margin: '0px 5px 10px 0px' }}
                    anotherLibraryIcon
                  >
                    {Actions.LexML.text}
                  </Button>
                </div>
              }
              title="LexML"
              //width="30%"
              DrawerContentComponent={Actions.LexML.content}
              DrawerContentComponentParams={{
                document: selectedRow,
                GetDataAgain,
              }}
            />
          </Col>
          */}
        </Row>
      </div>
    );
  }

  if (isMobile) {
    return <div />;
  }

  return (
    <div
      style={{
        margin: '0px 0px 10px 0px',
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
      }}
    >
      {/* Proceed */}
      <Drawer
        Trigger={
          <div>
            <Button
              icon={<RiMailSendLine />}
              type={selectedRow.length > 0 ? 'default' : 'dashed'}
              style={{ margin: '0px 5px 10px 0px' }}
              disabled={!(selectedRow.length > 0)}
              anotherLibraryIcon
            >
              {Actions.Proceed.text}
            </Button>
          </div>
        }
        title="Tramitar"
        width="50%"
        DrawerContentComponent={Actions.Proceed.content}
        DrawerContentComponentParams={{ document: selectedRow, GetDataAgain }}
      />
      {/* Interested */}
      <Drawer
        Trigger={
          <div>
            <Button
              icon={<RiUserStarLine />}
              type={selectedRow.length > 0 ? 'default' : 'dashed'}
              style={{ margin: '0px 5px 10px 0px' }}
              disabled={!(selectedRow.length > 0)}
              anotherLibraryIcon
            >
              {Actions.Interested.text}
            </Button>
          </div>
        }
        title="Gerenciar Interessados"
        width="50%"
        DrawerContentComponent={Actions.Interested.content}
        DrawerContentComponentParams={{ document: selectedRow, GetDataAgain }}
      />
      {/* Signatories */}
      <Drawer
        Trigger={
          <div>
            <Button
              icon={<RiUserSharedLine />}
              type={selectedRow.length > 0 ? 'default' : 'dashed'}
              style={{ margin: '0px 5px 10px 0px' }}
              disabled={!(selectedRow.length > 0)}
              anotherLibraryIcon
            >
              {Actions.Signatories.text}
            </Button>
          </div>
        }
        title="Gerenciar Assinantes"
        width="50%"
        DrawerContentComponent={Actions.Signatories.content}
        DrawerContentComponentParams={{ document: selectedRow, GetDataAgain }}
      />
      {CheckPermission('UPDATE-DOCUMENT') && (
        <Drawer
          Trigger={
            <div>
              <Button
                icon={<FaRegEdit />}
                type={selectedRow.length > 0 ? 'default' : 'dashed'}
                style={{ margin: '0px 5px 10px 0px' }}
                disabled={!(selectedRow.length > 0)}
                anotherLibraryIcon
              >
                {Actions.Edit.text}
              </Button>
            </div>
          }
          title="Editar"
          width="50%"
          DrawerContentComponent={Actions.Edit.content}
          DrawerContentComponentParams={{ document: selectedRow, GetDataAgain }}
        />
      )}
      {/* Attachments */}
      <Drawer
        Trigger={
          <div>
            <Button
              icon={<RiFileAddLine />}
              type={selectedRow.length > 0 ? 'default' : 'dashed'}
              style={{ margin: '0px 5px 10px 0px' }}
              disabled={!(selectedRow.length > 0)}
              anotherLibraryIcon
            >
              {Actions.Attachments.text}
            </Button>
          </div>
        }
        title="Anexos"
        width="50%"
        DrawerContentComponent={Actions.Attachments.content}
        DrawerContentComponentParams={{ document: selectedRow, GetDataAgain }}
      />
      {/* Visualize */}
      {CheckPermission('READ-DOCUMENT') && (
        <Drawer
          Trigger={
            <div>
              <Button
                icon={<FaRegEye />}
                type={selectedRow.length > 0 ? 'default' : 'dashed'}
                style={{ margin: '0px 5px 10px 0px' }}
                disabled={!(selectedRow.length > 0)}
                anotherLibraryIcon
              >
                {Actions.Visualize.text}
              </Button>
            </div>
          }
          title="Visualizar"
          width="100%"
          DrawerContentComponent={Actions.Visualize.content}
          DrawerContentComponentParams={{ document: selectedRow, GetDataAgain }}
        />
      )}
      {/* Subscribe */}
      {CheckPermission('SIGN-DOCUMENT-LOGIN-PASSWORD') && (
        <Drawer
          Trigger={
            <div>
              <Button
                icon={<FaSignature />}
                type={selectedRow.length > 0 ? 'default' : 'dashed'}
                style={{ margin: '0px 5px 10px 0px' }}
                disabled={!(selectedRow.length > 0)}
                anotherLibraryIcon
              >
                {Actions.Subscribe.text}
              </Button>
            </div>
          }
          title="Assinar"
          width="50%"
          DrawerContentComponent={Actions.Subscribe.content}
          DrawerContentComponentParams={{ document: selectedRow, GetDataAgain }}
        />
      )}
      {/* AccessLevel */}
      {CheckPermission('UPDATE-DOCUMENT') && (
        <Drawer
          Trigger={
            <div>
              <Button
                icon={<BiLockAlt />}
                type={selectedRow.length > 0 ? 'default' : 'dashed'}
                style={{ margin: '0px 5px 10px 0px' }}
                disabled={!(selectedRow.length > 0)}
                anotherLibraryIcon
              >
                Acesso
              </Button>
            </div>
          }
          title="Nível de acesso"
          width="50%"
          DrawerContentComponent={Actions.AccessLevel.content}
          DrawerContentComponentParams={{ document: selectedRow, GetDataAgain }}
        />
      )}

      {/* Contributors */}
      <Drawer
        Trigger={
          <div>
            <Button
              icon={<FiUsers />}
              type={selectedRow.length > 0 ? 'default' : 'dashed'}
              style={{ margin: '0px 5px 10px 0px' }}
              disabled={!(selectedRow.length > 0)}
              anotherLibraryIcon
            >
              {Actions.Contributors.text}
            </Button>
          </div>
        }
        title="Editores"
        width="50%"
        DrawerContentComponent={Actions.Contributors.content}
        DrawerContentComponentParams={{ document: selectedRow, GetDataAgain }}
      />
      {/* Historic */}
      <Drawer
        Trigger={
          <div>
            <Button
              icon={<TiCalendarOutline />}
              type={selectedRow.length > 0 ? 'default' : 'dashed'}
              style={{ margin: '0px 5px 10px 0px' }}
              disabled={!(selectedRow.length > 0)}
              anotherLibraryIcon
            >
              {Actions.Historic.text}
            </Button>
          </div>
        }
        title="Histórico"
        width="50%"
        DrawerContentComponent={Actions.Historic.content}
        DrawerContentComponentParams={{ document: selectedRow, GetDataAgain }}
      />
      {/* CentralPrint */}
      <Drawer
        Trigger={
          <div>
            <Button
              icon={<PrinterOutlined />}
              type={selectedRow.length > 0 ? 'default' : 'dashed'}
              style={{ margin: '0px 5px 10px 0px' }}
              disabled={!(selectedRow.length > 0)}
              anotherLibraryIcon
            >
              {Actions.CentralPrint.text}
            </Button>
          </div>
        }
        title="Central de Impressão"
        width="100%"
        DrawerContentComponent={Actions.CentralPrint.content}
        DrawerContentComponentParams={{ document: selectedRow, GetDataAgain }}
      />
      {/* Delete */}
      <Drawer
        Trigger={
          <div>
            <Button
              icon={<DeleteFilled />}
              type={selectedRow.length > 0 ? 'default' : 'dashed'}
              style={{ margin: '0px 5px 10px 0px' }}
              disabled={!(selectedRow.length > 0)}
              anotherLibraryIcon
            >
              {Actions.Delete.text}
            </Button>
          </div>
        }
        title="Deletar documento"
        width="100%"
        DrawerContentComponent={Actions.Delete.content}
        DrawerContentComponentParams={{ document: selectedRow, GetDataAgain }}
      />
      {/* LexML */}
      {/* 
      <Drawer
        Trigger={
          <div>
            <Button
              icon={<FaStamp />}
              type={selectedRow.length > 0 ? 'default' : 'dashed'}
              style={{ margin: '0px 5px 10px 0px' }}
              disabled={!(selectedRow.length > 0)}
              anotherLibraryIcon
            >
              {Actions.LexML.text}
            </Button>
          </div>
        }
        title="LexML"
        //width="50%"
        DrawerContentComponent={Actions.LexML.content}
        DrawerContentComponentParams={{
          document: selectedRow,
          GetDataAgain,
        }}
      />
      */}
    </div>
  );
}
