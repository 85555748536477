import React from 'react';
import { Typography } from 'antd';

import { useMessagerManager } from '../../contexts/MessagerManager/Provider';
import Header from './components/Header';
import Messages from './components/Messages';
import Footer from './components/Footer';
import * as S from './style';

const { Title } = Typography;

export default function MessagerContainer() {
  const { selectedChat } = useMessagerManager();

  if (!selectedChat) {
    return (
      <S.EmptyChatContainer>
        <Title level={2}>Selecione uma conversa</Title>
      </S.EmptyChatContainer>
    );
  }

  return (
    <S.Container>
      <Header />

      <Messages />

      <Footer />
    </S.Container>
  );
}
