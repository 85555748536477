import React from 'react';
// Own Components
import AttachmentView from './components/AttachmentView';
import ExternalView from './components/ExternalView';
import DocumentView from './components/DocumentView'


export default function FileView({ document, fatherProcess }) {

    return (
        <>
            {
                document.type === 'attachment' ?
                    document.url ?
                        <ExternalView document={document} />
                        :
                        <AttachmentView document={document} fatherProcess={fatherProcess} />
                    :
                    document.kind === "EXTERNAL" ?
                        <ExternalView document={document} />
                        :
                        <DocumentView document={document} />
            }
        </>
    )
}

/* 

<ExternalView document={selectedFile} />
                :
                <AttachmentView document={selectedFile} />

*/