import React, { useContext, useEffect, useState } from 'react';
// Libraries
import { FiUsers } from 'react-icons/fi';
import {
  // BiArchive,
  BiBriefcaseAlt,
} from 'react-icons/bi';
import { BsFiles } from 'react-icons/bs';
import { RiFileSettingsLine } from 'react-icons/ri';
import { SettingOutlined } from '@ant-design/icons';
import {
  BiMessageSquareEdit,
  BiMessageSquareCheck,
  BiBarChartAlt,
  BiLineChart,
} from 'react-icons/bi';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { Layout } from 'antd';
import Animate, {
  FadeIn,
  FadeInLeft,
  FadeInRight,
  FadeInDown,
} from 'animate-css-styled-components';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { SIGAMContext } from '~/contexts/SIGAMContext';
// Components
import Sidebar from '~/pages/SIGAM/components/sidebar';
import MainNavbar from '~/pages/SIGAM/components/navbar';
import Loading from '~/components/loading';
// Pages
// import Groups from './pages/Groups';
import Users from './pages/Users';
import OrganizationalUnits from './pages/OrganizationalUnits';
import DocumentTemplates from './pages/DocumentTemplates';
import TypesOfProcesses from './pages/TypesOfProcesses';
import MessageTemplates from './pages/MessageTemplates';
import Messenger from './pages/Messenger';
import Settings from './pages/Settings';
import Dashboard from './pages/Dashboard';
import ProcessFlowchart from './pages/ProcessFlowchart';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

const { Content } = Layout;

export default function AdminDashboard() {
  const { theme } = useContext(ThemeContext);
  const { setActiveInstitution } = useContext(SIGAMContext);
  const { isMobile } = useContext(ResponsiveContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loadingContent, setLoadingContent] = useState(true);
  const { path, url, params } = useRouteMatch();
  const sidebarItems = [
    {
      index: 0,
      title: 'First',
    },
    {
      index: 1,
      title: 'Usuários',
      url: `${url}/usuarios`,
      icon: FiUsers,
    },
    {
      index: 2,
      title: 'Unidades Organizacionais',
      url: `${url}/unidades-organizacionais`,
      icon: BiBriefcaseAlt,
    },
    /*
    {
      index: 3,
      title: 'Grupos',
      url: `${url}/grupos`,
      icon: BiArchive,
    },
    */
    {
      index: 3,
      title: 'Modelos de documentos',
      url: `${url}/modelos-de-documentos`,
      icon: BsFiles,
    },
    {
      index: 4,
      title: 'Tipos de processos',
      url: `${url}/tipos-de-processos`,
      icon: RiFileSettingsLine,
    },
    /*
    {
      index: 5,
      title: 'Modelos de mensagens',
      url: `${url}/modelos-de-mensagens`,
      icon: BiMessageSquareEdit,
    },
    */
    {
      index: 5,
      title: 'Dashboard',
      url: `${url}/dashboard`,
      icon: BiBarChartAlt,
    },
    /*
    {
      index: 7,
      title: 'Comunicação externa',
      url: `${url}/comunicacao-externa`,
      icon: BiMessageSquareCheck,
    },
    {
      index: 8,
      title: 'Configurações',
      url: `${url}/configuracoes`,
      icon: SettingOutlined,
    },
    */
    {
      index: 6,
      title: 'Fluxograma do processo',
      url: `${url}/fluxograma-do-processo`,
      icon: BiLineChart,
    },
    {
      index: 7,
      title: 'Last',
    },
  ];

  useEffect(() => {
    async function CheckIfItIsAdmin(id) {
      const params = {
        operation: ['SIGAM', 'GetPermissions'],
        urlParams: {
          institutionId: id,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        if (response.body.permission.ADMIN) {
          setIsAdmin(true);
        }
        return setLoadingContent(false);
      }
    }
    if (params) {
      const id = params.institutionId;
      CheckIfItIsAdmin(id);
    }
  }, [params]);

  if (loadingContent) {
    return <Loading text="Verificando permissão" height="100vh" />;
  }

  if (!isAdmin) {
    setActiveInstitution();
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Layout
        style={{
          backgroundColor: theme.color.backgroundSecondary,
          height: '100vh',
        }}
      >
        <Animate Animation={[FadeInLeft]} duration={['0.5s']} delay={['0s']}>
          <Sidebar items={sidebarItems} />
        </Animate>
        <Layout
          style={{
            padding: isMobile ? 0 : 10,
            backgroundColor: theme.color.backgroundSecondary,
            overflowX: 'hidden',
          }}
        >
          <Animate
            Animation={[isMobile ? FadeInDown : FadeInRight]}
            duration={['0.5s']}
            delay={['0s']}
          >
            <MainNavbar />
          </Animate>
          <Content
            style={{
              padding: '10px 5px',
              overflow: 'auto',
            }}
          >
            <Animate Animation={[FadeIn]} duration={['1s']} delay={['0.5s']}>
              <Switch>
                <Route exact path={`${path}`}>
                  <Redirect to={`${url}/usuarios`} />
                </Route>
                <Route path={`${path}/usuarios`} component={Users} />
                <Route
                  path={`${path}/unidades-organizacionais`}
                  component={OrganizationalUnits}
                />
                {/* 
                <Route path={`${path}/grupos`} component={Groups} />
                */}
                <Route
                  path={`${path}/modelos-de-documentos`}
                  component={DocumentTemplates}
                />
                <Route
                  path={`${path}/tipos-de-processos`}
                  component={TypesOfProcesses}
                />
                <Route
                  path={`${path}/modelos-de-mensagens`}
                  component={MessageTemplates}
                />
                <Route path={`${path}/dashboard`} component={Dashboard} />
                <Route
                  path={`${path}/comunicacao-externa`}
                  component={Messenger}
                />
                <Route path={`${path}/configuracoes`} component={Settings} />
                <Route
                  path={`${path}/fluxograma-do-processo`}
                  component={ProcessFlowchart}
                />
                <Route path={`${path}/*`}>
                  <Redirect to={`${url}/usuarios`} />
                </Route>
              </Switch>
            </Animate>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
