import React, { useContext } from 'react';
//
import { Space } from 'antd';
import {
    CheckCircleOutlined,
    CloseCircleOutlined
} from '@ant-design/icons';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
//
import Text from '~/components/typography/text';

export default function ShowItems({ items }) {
    const { theme } = useContext(ThemeContext);
    return (
        <Space direction="vertical">
            {items.map(item => (
                <Text key={item.text}>
                    {item.finish ?
                    <CheckCircleOutlined style={{ marginRight: 5, color: theme.color.green }} />
                    :
                    <CloseCircleOutlined style={{ marginRight: 5, color: theme.color.danger }} />
                    }
                    {item.text}
                </Text>
            ))}
        </Space>
    )
}