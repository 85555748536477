import React, { useEffect, useState } from 'react';
import { Form, Spin } from 'antd';
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import TextArea from '~/components/textArea';
import Select from '~/components/select';
import { useParams } from 'react-router-dom';
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function AddForm({ form }) {
  const { institutionId } = useParams();
  const [loadingTypes, setLoadingTypes] = useState(true);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    async function GetData() {
      const params = {
        operation: ['SIGAM', 'TypesOfProcesses', 'Get'],
        urlParams: {
          institutionId: institutionId,
        },
        data: {
          pagination: {
            sort: {
              by: 'name',
              order: 'asc',
            },
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        let array = response.body.processKinds.elements;
        let newArray = [];
        for (let i = 0; i < array.length; i++) {
          if (array[i].status === 'ACTIVE') {
            let newItem = {};
            newItem.label = array[i].name;
            newItem.value = array[i].id;
            newArray.push(newItem);
          }
        }
        setTypes(newArray);
      }
      setLoadingTypes(false);
    }
    GetData();
  }, [institutionId]);

  return (
    <>
      <Form
        labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        form={form}
        size="small"
        layout="vertical"
        scrollToFirstError
      >
        <FormItem label={'Nome do Processo'} name={['name']} item={<Input />} />
        <FormItem
          label={'ID interno'}
          name={['internal_id']}
          item={<Input />}
        />
        <FormItem
          label={'ID externo'}
          name={['external_id']}
          item={<Input />}
        />
        <FormItem label={'ID manual'} name={['manual_id']} item={<Input />} />
        <FormItem
          label={'Unidade de origem'}
          name={['created_at_unity_name']}
          item={<Input />}
        />
        <FormItem
          label={'Tipo de Processo'}
          name={['kind']}
          item={
            loadingTypes ? <Spin /> : <Select options={types} noDefaultValue />
          }
        />
        <FormItem label={'Assunto'} name={['subject']} item={<Input />} />
        <FormItem
          label={'Especificações'}
          name={['specifications']}
          item={<TextArea />}
        />
      </Form>
    </>
  );
}
