import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Drawer, Input } from 'antd';
import _readData from 'services/calls/public/process/read';

export default function SearchDocument({ setSelectedProcess }) {
  const { institutionId } = useParams();
  const [open, setOpen] = useState(false);
  const [dataList, setDataList] = useState([]);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  function clickData(item) {
    setSelectedProcess(item);
    onClose();
    setDataList([]);
  }

  async function searchData(value) {
    const filters = {
      filter: {
        name: value,
      },
    };
    const response = await _readData({ institutionId, filters });
    if (response.success) {
      setDataList(response.body.processes.elements);
    }
  }

  return (
    <>
      <Button type="dashed" style={{ width: '100%' }} onClick={showDrawer}>
        Selecionar processo
      </Button>
      <Drawer
        title="Selecionando processo"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <div>
          <div>
            <Input onChange={(event) => searchData(event.target.value)} />
          </div>
          {dataList && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                marginTop: '10px',
              }}
            >
              {dataList.map((item, index) => (
                <div
                  key={index}
                  style={{
                    padding: 4,
                    border: '1px solid #f1f3f4',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                  onClick={() => clickData(item)}
                >
                  {item.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
}
