import { DecodeURI } from '~/utils/Transformations';
import { DateInBrazilianFormat } from '~/utils/DateAndTime';
import System from '~/environments/System';

function getDocumentHeader(fatherDocument) {
  return `
  <thead style="display: table-header-group;font-family: Arial; width: 100%; margin-bottom: 10px;">
    <th style="display: flex; width: 100%; justify-content: space-between; align-content: center;">
      <div style="width: 100%; display: flex; justify-content: left;">
          <img src="${DecodeURI(
            fatherDocument.institution_avatar || ''
          )}" style="width: 50px; heigth: 50px;" />
      </div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-content: center; width: 100%;">
          <h5 style="margin: 0; font-weight: bold; font-size: 14px;">${
            fatherDocument.institution_name
          }</h5>
          <p style="font-size: 12px; margin: 0; font-weight: 100;">${
            fatherDocument.created_at_unity_name
          }</p>
      </div>
      <div style="width: 100%; display: flex; justify-content: right;">
        <p style="font-size: 12px; margin: 0; font-weight: 100;">NDoc: ${
          fatherDocument.internal_id
        }</p>
      </div>
    </th>
  </thead>
  `;
}

function getDocumentFooter(fatherDocument) {
  return `
  <tfoot style="display: table-footer-group;font-family: Arial; margin-top: 10px;">
    <tr>
      <th style="width: 100%; display: flex; justify-content: end; align-items: end;">
      <div>
        <img src="https://chart.googleapis.com/chart?cht=qr&chl=https://${System.hostname.SIGAMApp}/verificacao?documento=${fatherDocument.internal_id}&chs=80x80&chld=L|0" style="width: 80px; heigth: 80px;" />
      </div>
        <div style="margin-left: 10px">
            <p style="font-size: 10px; margin: 0; font-weight: 100; text-align: left;">Conforme Lei nº 14.063, de 23 de setembro de 2020</p>
            <p style="font-size: 10px; margin: 0; font-weight: 100; text-align: left;">Verifique a autenticidade deste documento em https://app.sigam.net.br/verificacao informando o código: <span style="font-weight: 500">${fatherDocument.verification_id}</span></p>
        </div>
        <div style="margin-left: auto; display: flex; justify-content: end; align-items: end;">
          <img src="https://media.milanote.com/p/images/1L5DiR1bzJOVfr/R9B/SIGAM-02.jpg" style="width: 80px; heigth: 40px;" />
        </div>
      </th>
    </tr>
  </tfoot>
  `;
}

const getSubscriber = (subscriber) => {
  return `
  <tr style="width: 100%;" key={index}>
    <td style="padding-left: 20px;">
      <img
        src="https://media.milanote.com/p/images/1NkmEW1LMOSt27/kJQ/assinatura.png"
        style="width: 80px; heigth: 40px;"
      />
    </td>
    <td style="padding-left: 20px;">
      <p>Documento assinado eletronicamente por:<span style="font-weight: bold; margin-left: 2px;">${subscriber.person_name}</span>, em ${subscriber.date}, às ${subscriber.hour}, conforme horário oficial de Brasília.</p>
    </td>
  </tr>
  `;
};

const getSubscribers = (subscribers) => {
  if (!subscribers) return '';
  if (subscribers.length === 0) return '';

  let pages = '';
  for (let i = 0; i < subscribers.length; i++) {
    const htmlValue = getSubscriber(subscribers[i]);
    pages = pages.concat(htmlValue);
  }

  return `
  <div style="width: 100%; margin: 40px 0px;">
    <table style="border: 1px solid #F2F3F5; width: 100%;">
    ${pages}
    </table>
    <p style="font-size: 12px; margin: 20px 0px 0px; font-weight: 100; text-align: center;">As assinaturas acima estão de acordo com a lei federal de nº 14.063 de 23 de Setembro de 2020</p>
  </div>
  `;
};

const getAttachment = (attachment, fatherDocument, index) => {
  return `
  <table style="page-break-after: always; font-family: Arial; width: 100%;">
  ${getDocumentHeader(fatherDocument)}
    <tbody>
      <tr>
          <td>
            <h5 style="margin: 0; font-weight: bold; font-size: 20px; margin-bottom: 20px;">ANEXO ${
              index + 1
            } do documento ${fatherDocument.internal_id}</h5>
            <h5 style="margin: 0; font-weight: bold; font-size: 16px; margin-bottom: 6px;">Sobre o documento de origem</h5>
            <div style="display: flex; gap: 6px; margin-bottom: 6px;">
              <p style="margin: 0; font-size: 14px;">Nome: </p>
              <p style="margin: 0; font-size: 14px;">${fatherDocument.name}</p>
            </div>
            <div style="display: flex; gap: 6px; margin-bottom: 6px;">
              <p style="margin: 0; font-size: 14px;">Criação: </p>
              <p style="margin: 0; font-size: 14px;">${DateInBrazilianFormat(
                fatherDocument.created_at
              )}</p>
            </div>
            <div style="display: flex; gap: 6px; margin-bottom: 6px;">
              <p style="margin: 0; font-size: 14px;">Instituição: </p>
              <p style="margin: 0; font-size: 14px;">${
                fatherDocument.institution_name
              }</p>
            </div>
            <div style="display: flex; gap: 6px; margin-bottom: 6px;">
              <p style="margin: 0; font-size: 14px;">Unidade: </p>
              <p style="margin: 0; font-size: 14px;">${
                fatherDocument.created_at_unity_name
              }</p>
            </div>
            <div style="display: flex; gap: 6px; margin-bottom: 6px;">
              <p style="margin: 0; font-size: 14px;">Id interno: </p>
              <p style="margin: 0; font-size: 14px;">${
                fatherDocument.internal_id
              }</p>
            </div>
            <div style="display: flex; gap: 6px; margin-bottom: 6px;">
              <p style="margin: 0; font-size: 14px;">Código de verificação: </p>
              <p style="margin: 0; font-size: 14px;">${
                fatherDocument.verification_id
              }</p>
            </div>
            <h5 style="margin: 0; font-weight: bold; font-size: 16px; margin-bottom: 6px; margin-top: 20px;">Sobre o anexo</h5>
            <div style="display: flex; gap: 6px; margin-bottom: 6px;">
              <p style="margin: 0; font-size: 14px;">Nome: </p>
              <p style="margin: 0; font-size: 14px;">${attachment.name}</p>
            </div>
            <div style="display: flex; gap: 6px; margin-bottom: 6px;">
            <p style="margin: 0; font-size: 14px;">Anexado em: </p>
            <p style="margin: 0; font-size: 14px;">${DateInBrazilianFormat(
              attachment.created_at
            )}</p>
          </div>
          <div style="display: flex; gap: 6px; margin-bottom: 6px;">
              <p style="margin: 0; font-size: 14px;">Id interno: </p>
              <p style="margin: 0; font-size: 14px;">${
                attachment.internal_id
              }</p>
            </div>
          </td>
      </tr>
    </tbody>
  </table>
  `;
};

const getAttachments = (attachments, fatherDocument) => {
  if (!attachments) return '';
  if (attachments.length === 0) return '';
  let pages = '';
  for (let i = 0; i < attachments.length; i++) {
    const htmlValue = getAttachment(attachments[i], fatherDocument, i);
    pages = pages.concat(htmlValue);
  }

  return `
  <div style="width: 100%;">
    ${pages}
  </div>
  `;
};

const getWater = (status) => {
  if (!status) return '';
  if (status === 'ACTIVE') return '';
  return `
  <div class="water" style="content: inherit; position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: -1; font-family: Arial; color: #ff0000; font-size: 100px; font-weight: 1000px; display: grid; justify-content: center; align-content: center; opacity: 0.3;">
      <svg width="700" height="700">
          <image href="https://app.milanote.com/media/p/images/1Qe5JL1Cwhcl3j/jvx/image.png?v=2&elementId=1Qe5JL1Cwhcl3j" width="700" height="700"/>
      </svg>
    </div>
  `;
};

export default function getTemplateModel({
  fatherDocument,
  subscribers,
  attachments,
  status,
}) {
  return `
  <div>
    ${getWater(status)}
    <table style="page-break-after: always; font-family: Arial; width: 100%;">
      ${getDocumentHeader(fatherDocument)}
      ${getDocumentFooter(fatherDocument)}
      <tbody>
        <tr>
            <td>
                <div style="padding: 10px 0px;">
                    ${fatherDocument?.body}
                    ${getSubscribers(subscribers)}
                </div>
            </td>
        </tr>
      </tbody>
    </table>
    ${getAttachments(attachments, fatherDocument)}
    </div>
    `;
}
