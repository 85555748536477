import React, { memo, useEffect, useState, useContext } from 'react';

import { Tag } from 'antd';

import { ThemeContext } from '~/contexts/ThemeContext';

import PopupTags from './components/PopupTags';

import MaximunWidth from './style';

function TagsCell({ id, tags, maxWidth }) {
  const [divTags, setDivTags] = useState(tags);
  const [popupTags, setPopupTags] = useState([]);

  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    function TreateTagsIfTheyPassMaxWidth() {
      const div = document.getElementById(id);

      let sequelizedTags = tags;
      let tagsWidth = 0;

      div.childNodes.forEach((tag, index) => {
        if (div.id === 'Situação da criança') console.log('tag', tag);
        if (index + 1 !== div.childElementCount) {
          tagsWidth += tag.offsetWidth;

          if (div.offsetWidth < tagsWidth) {
            sequelizedTags = sequelizedTags.filter(
              (oldTag) => oldTag.text !== tag.textContent
            );
          }
        }
      });

      const newPopupTags = tags.filter((tag, index) => {
        return index >= sequelizedTags.length;
      });

      setPopupTags(newPopupTags);
      setDivTags(sequelizedTags);
    }

    if (divTags === tags) {
      TreateTagsIfTheyPassMaxWidth();
    }
  }, [tags, id, divTags]);

  return (
    <MaximunWidth id={id} maxWidth={maxWidth || '350px'}>
      {divTags.map((tag) => {
        return (
          <Tag key={tag.text} id={tag.text} icon={tag.icon} color={tag.color} style={{ marginBottom: 5 }}>
            {tag.text}
          </Tag>
        );
      })}

      <PopupTags theme={theme} popupTags={popupTags} />
    </MaximunWidth>
  );
}

export default memo(TagsCell);