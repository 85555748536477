import React, { useState, createContext, useEffect } from 'react';
// Libraries
import { useLocation } from 'react-router-dom';
// Components
import Loading from '~/components/loading';

export const SIGAMContext = createContext();

const SIGAMContextProvider = ({ children }) => {
  const [activeInstitution, setActiveInstitution] = useState();
  const [loadingContent, setLoadingContent] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    if(pathname === '/'){
      setActiveInstitution();
    }
  },[pathname])

  useEffect(() => {
    async function SetInstitution() {
      let URLSplit = window.location.pathname.split('/')
      if (URLSplit.length > 3) {
        setActiveInstitution({ id: URLSplit[1] });
      }
      setLoadingContent(false)
    }
    if (!activeInstitution) {
      SetInstitution();
    } else {
      setLoadingContent(false)
    }
  }, [activeInstitution])


  return (
    <SIGAMContext.Provider value={{
      activeInstitution,
      setActiveInstitution
    }}>
      {loadingContent ?
        <Loading />
        :
        children
      }
    </SIGAMContext.Provider>
  );
};

export default SIGAMContextProvider;
