import React from 'react';
import { Typography } from 'antd';

const { Paragraph, Text } = Typography;

export default function ArchiveBody({ archive }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        {archive.description ? (
          <Paragraph
            ellipsis={{
              rows: 2,
              expandable: true,
            }}
          >
            {`${archive.description}`}
          </Paragraph>
        ) : (
          <Text>Sem descrição</Text>
        )}
      </div>
    </div>
  );
}
