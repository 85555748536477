import React, { useState, useContext, useEffect } from 'react';
// Libraries
import { useLocation, Redirect } from 'react-router-dom';
import { Form, message, Row, Col, Result } from 'antd';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
// Components
import Loading from '~/components/loading';
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import Button from '~/components/button';
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
import Card from '~/components/card';
// Environments
import System from '~/environments/System';
import UrlParameters from '~/environments/UrlParameters';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Valid() {
    const { translate } = useContext(LanguageContext);
    const [loadingContent, setLoadingContent] = useState(true);
    const [tokenInvalid, setTokenInvalid] = useState(false);
    const [form] = Form.useForm();
    const [successfullyCreatedPassword, setSuccessfullyCreatedPassword] = useState(false);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const token = query.get(UrlParameters.recoveryPasswordToken);
    const personId = query.get(UrlParameters.personId);

    async function onFinish(data) {
        const params = {
            operation: ['Auth', 'RedefinePassword', 'CreateNewPassword'],
            urlParams: {
                tokenId: token,
                personId
            },
            data: {
                password: data.password,
                reset_login: true
            }
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            message.success('Senha redefinida com sucesso!');
            setSuccessfullyCreatedPassword(true);
        } else {
            message.error('Alguma coisa aconteceu...')
        }
    }


    useEffect(() => {
        async function VerifyPasswordToken() {
            const params = {
                operation: ['Auth', 'RedefinePassword', 'VerifyToken'],
                urlParams: {
                    tokenId: token,
                    personId
                }
            };
            const response = await SocialMeAPI(params);
            if (!response.success) {
                setTokenInvalid(true);
            }
            setLoadingContent(false);
        }
        VerifyPasswordToken();
    }, [personId, token])

    if (loadingContent) {
        return (
            <div
                style={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Loading text="Verificando token" />
            </div>
        )
    }

    if (tokenInvalid) {
        return (
            <Redirect to="/" />
        )
    }

    return (
        <div
            style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Card>
                {successfullyCreatedPassword ?
                    <div>
                        <Result
                            status="success"
                            title={<Text strong style={{ textAlign: 'center' }}>Sua senha foi redefinida com sucesso!</Text>}
                            extra={[
                                <a href={`https://${System.production ? System.hostname.SIGAMApp : System.hostname.SIGAMDev}`} key="link">
                                    <Button style={{ width: '100%', marginTop: 10 }} type="primary" size="large">Acessar o sistema</Button>
                                </a>
                            ]}
                        />
                    </div>
                    :
                    <div>
                        <Title level={4} style={{ textAlign: 'center' }}>Redefinição de senha</Title>
                        <Form
                            labelCol={{
                                xs: { span: 24 },
                                sm: { span: 24 },
                            }}
                            wrapperCol={{
                                xs: { span: 24 },
                                sm: { span: 24 },
                            }}
                            layout="vertical"
                            form={form}
                            onFinish={onFinish}
                            scrollToFirstError
                        >
                            <Row>
                                <Col span={24}>
                                    <FormItem
                                        style={{ margin: '0px 0px 10px 0px' }}
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: translate.fields.required.password.text,
                                            },
                                        ]}
                                        item={
                                            <Input
                                                type="password"
                                                label={translate.fields.label.password.text}
                                                size="large"
                                            />
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <FormItem
                                        style={{ margin: '0px 0px 10px 0px' }}
                                        name="confirm"
                                        rules={[
                                            {
                                                required: true,
                                                message: translate.fields.required.passwordConfirm.text,
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        translate.fields.invalid.passwordConfirm.text
                                                    );
                                                },
                                            }),
                                        ]}
                                        item={
                                            <Input
                                                type="password"
                                                label={translate.fields.label.passwordConfirm.text}
                                                size="large"
                                            />
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <FormItem
                                        style={{ margin: '0px 0px 10px 0px' }}
                                        name="confirm"
                                        item={
                                            <Button
                                                style={{ width: '100%' }}
                                                type="primary"
                                                htmlType="submit"
                                                size="large"
                                            >
                                                Redefinir a senha
                                    </Button>
                                        }
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </div>
                }
            </Card>
        </div>
    )
}