import styled from 'styled-components';

export const IconGroup = styled.div`
  padding: 6px;
  border-radius: 100%;
  cursor: pointer;
  transition: all 250ms;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${(style) => style.backgroundColor};
  }
`;
