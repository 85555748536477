import React from 'react';
// Libraries
import { List, message } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import Animate, { FadeIn } from 'animate-css-styled-components';
// Components
import Avatar from '~/components/avatar';
import Button from '~/components/button';
// Utils
import { CapitalizeString } from '~/utils/Transformations';
import { cpfMask, cnpjMask } from '~/utils/Masks';
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function PeopleList({ people, process, GetPeopleAgain }) {
    const { institutionId, organizationUnitId } = useParams();

    async function RemoveUser(item) {
        const params = {
            operation: ['SIGAM', 'Processes', 'PrivateAccess', 'Delete'],
            urlParams: {
                institutionId: institutionId,
                organizationUnitId: organizationUnitId,
                processId: process.id,
                personId: item.id
            },
        };
        const response = await SocialMeAPI(params);
        if (response.success) {
            message.success('Acesso removido com sucesso!');
            GetPeopleAgain();
        } else {
            message.error('Alguma coisa aconteceu...')
        }
    }

    return (
        <div style={{ marginTop: 10 }}>

            <List
                itemLayout="horizontal"
                dataSource={people}
                locale={{ emptyText: 'Nenhum usuário encontrado' }}
                renderItem={(item) => (
                    <Animate
                        Animation={[FadeIn]}
                        duration={['0.5s']}
                        delay={['0.2s']}
                    >
                        <List.Item actions={[
                            <Button onClick={() => RemoveUser(item)} icon={<UserDeleteOutlined />} danger>
                                Remover acesso
                        </Button>
                        ]}>
                            <List.Item.Meta
                                avatar={<Avatar size={45}  src={item.avatar} />}
                                title={CapitalizeString(item.social_name || item.name)}
                                description={
                                    item.cpf_number
                                        ? cpfMask(item.cpf_number)
                                        : cnpjMask(item.cnpj_number)
                                }
                            />
                        </List.Item>
                    </Animate>
                )}
            />
        </div>
    )
}