import React, { useState } from 'react';
// Libraries
import { Form, message } from 'antd';
import Animate, { FadeIn } from 'animate-css-styled-components';
import { useParams } from 'react-router-dom';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import TextArea from '~/components/textArea';
import Button from '~/components/button';
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function AttachmentForm({
  file,
  fileLink,
  setStatus,
  setMessage,
  propertys,
}) {
  const { institutionId, organizationUnitId } = useParams();
  const [form] = Form.useForm();
  const [loadingButton, setLoadingButton] = useState(false);

  async function onFinish(values) {
    setLoadingButton(true);
    const processedData = values;
    processedData.kind = 'Upload';
    processedData.status = 'ACTIVE';
    processedData.url = fileLink;
    const params = {
      operation: ['SIGAM', 'Documents', 'Attachments', 'Add'],
      urlParams: {
        institutionId,
        organizationUnitId,
        documentId: propertys.document.id,
      },
      data: processedData,
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      message.success('Arquivo anexado com sucesso!');
      setStatus('completed');
      setMessage('Arquivo anexado com sucesso');
      if (propertys && propertys.GetDataAgain) {
        propertys.GetDataAgain();
      }
      propertys.GetAttachmentsAgain();
    } else {
      message.error('Alguma coisa aconteceu...');
    }
    return setLoadingButton(false);
  }

  return (
    <div style={{ padding: '5px 10px' }}>
      <Animate Animation={[FadeIn]} duration={['0.5s']} delay={['0.2s']}>
        <Form
          labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
          wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
          form={form}
          size="small"
          layout="vertical"
          scrollToFirstError
          initialValues={{ name: file.name }}
          onFinish={onFinish}
        >
          <FormItem
            label="Nome do Documento"
            name={['name']}
            rules={[
              {
                required: true,
                message: 'Insira um nome para o documento',
              },
            ]}
            item={<Input />}
          />
          <FormItem label="Assunto" name={['subject']} item={<Input />} />
          <FormItem
            label="Descrição"
            name={['description']}
            item={<TextArea />}
          />
          <FormItem
            label="Especificações"
            name={['specifications']}
            item={<TextArea />}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="primary" htmlType="submit" loading={loadingButton}>
              Anexar
            </Button>
          </div>
        </Form>
      </Animate>
    </div>
  );
}
